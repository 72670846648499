import './DeviceStatistics.scss';
import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStatistics } from 'src/module/device/action';
import { getStatistics } from 'src/module/device/selector';
import CustomStatistics from 'src/component/UI/Statistics/CustomStatistics';

import { celsiusToFahrenheitConvertor } from 'src/utils/utils';

export default function DeviceStatistics (props) {
  const { deviceId, numTemperatureSensors, temperatureUnit } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (deviceId) {
      dispatch(fetchStatistics(deviceId));
    }
  }, [deviceId]);

  const id = deviceId ? deviceId : null;
  const statistics = useSelector(getStatistics(id));

  const variables = {
    totalKwh: statistics?.get('totalKwh'),
    rtd1Temp: statistics?.get('avgRtd1Temp'),
    rtd2Temp: statistics?.get('avgRtd2Temp'),
    totalKwhForcedOn: statistics?.get('kwhForcedOn'),
  };

  if (temperatureUnit === 'F') {
    if (!isNaN(parseInt(variables.rtd1Temp))) {
      variables.rtd1Temp = celsiusToFahrenheitConvertor(variables.rtd1Temp);
    }
    if (!isNaN(parseInt(variables.rtd2Temp))) {
      variables.rtd2Temp = celsiusToFahrenheitConvertor(variables.rtd2Temp);
    }
  }

  const statisticsArray = [
    { type: 'major', title: 'Total kWh', helpText: 'Approximate kWh over the past 12 months', value: 'totalKwh' },
    { type: 'major', title: 'Total Wasted kWh ', helpText: 'Sum of kWh used when forced on over the past 12 months', value: 'totalKwhForcedOn' }
  ];

  for (let i=1; i <= numTemperatureSensors; i++) {
    statisticsArray.push({ type: 'major', title: `Average RTD${i} Temperature`, helpText: `Approximate Average RTD${i} Temperature over the past 12 months`, value: `rtd${i}Temp` });
  }

  const style = {  title: { fontSize: '1.17em', fontWeight: '600' }  };
  const title = <span className='title'>Statistics</span>;
  const defaultLayout = [
    { type: 'grid', container: true, spacing: 3, children: [
      { type: 'grid', container: false, xs: 12, md: 12, children: [
        { type: 'block', title: title, children: statisticsArray }
      ] },
    ] }
  ];

  return (
    <Grid item xs={12} md={12} className='device-statistics'>
      <CustomStatistics
        variables={variables}
        defaultLayout={defaultLayout}
        styleOverrides={style}
      />
    </Grid>
  );
}

DeviceStatistics.propTypes = {
  deviceId: PropTypes.string,
  numTemperatureSensors: PropTypes.number,
  temperatureUnit: PropTypes.string
};

DeviceStatistics.defaultProps = {
  deviceId: null,
  numTemperatureSensors: 0,
  temperatureUnit: 'C'
};
