import yup from 'src/utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchWeather, FETCH_WEATHER }: any = createTypedAction(
  `${PREFIX}FETCH_WEATHER`
);

export const { fetchWeatherSuccess, FETCH_WEATHER_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_WEATHER_SUCCESS`,
  ['weather', yup.immutable().map().required()]
);

export const { fetchWeatherFailed, FETCH_WEATHER_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_WEATHER_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
