import { all, spawn } from 'redux-saga/effects';

import { authenticationRoot } from './authentication/saga';
import { systemRoot } from './system/saga';
import { autobahnRoot } from './autobahn/saga';
import { navigationRoot } from './navigation/saga';
import { prefetchRoot } from './prefetch/saga';
import { circuitRoot } from './circuit/saga';
import { alarmRoot } from './alarm/saga';
import { importRoot } from './import/saga';
import { userRoot } from './user/saga';
import { noteRoot } from './note/saga';
import { devicesRoot } from './device/saga';
import { lookupRoot } from './lookup/saga';
import { ruleRoot } from './rule/saga';
import { upgradeRoot } from './upgrade/saga';
import { integrationRoot } from './integration/saga';
import { controllerRoot } from './controller/saga';
import { weatherForecastRoot } from './weather/saga';
import { workTicketRoot } from './workTicket/saga';
import { filterRoot } from './filter/saga';
import { facilityRoot } from './facility/saga';
import { freezeLogRoot } from './freezeLog/saga';
import { permissionRoot } from './permission/saga';
import { recipeRoot } from './recipe/saga';
import { batchScheduleRoot } from './batchSchedule/saga';
import { searchRoot } from './search/saga';
import { checkSheetRoot } from './checkSheet/saga';
import { groupDevicesRoot } from './groupDevices/saga';
import { adminRoot } from './admin/saga';


export const sagas = [
  spawn(authenticationRoot),
  spawn(systemRoot),
  spawn(autobahnRoot),
  spawn(prefetchRoot),
  spawn(navigationRoot),
  spawn(circuitRoot),
  spawn(controllerRoot),
  spawn(alarmRoot),
  spawn(importRoot),
  spawn(userRoot),
  spawn(noteRoot),
  spawn(devicesRoot),
  spawn(lookupRoot),
  spawn(ruleRoot),
  spawn(upgradeRoot),
  spawn(integrationRoot),
  spawn(weatherForecastRoot),
  spawn(workTicketRoot),
  spawn(filterRoot),
  spawn(facilityRoot),
  spawn(freezeLogRoot),
  spawn(permissionRoot),
  spawn(recipeRoot),
  spawn(batchScheduleRoot),
  spawn(searchRoot),
  spawn(checkSheetRoot),
  spawn(groupDevicesRoot),
  spawn(adminRoot)
];

export function * root () {
  yield all(sagas);
}

export default root;
