import './ComparisonSelector.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import EqualsSvg from '../../assets/icons/equals.svg';
import GreaterThanSvg from '../../assets/icons/greater-than.svg';
import GreaterThanEqualSvg from '../../assets/icons/greater-than-equal.svg';
import LessThanSvg from '../../assets/icons/less-than.svg';
import LessThanEqualSvg from '../../assets/icons/less-than-equal.svg';
import NotEqualSvg from '../../assets/icons/not-equal.svg';
import PlusSvg from '../../assets/icons/plus.svg';
import MinusSvg from '../../assets/icons/minus.svg';

export default function ComparisonSelector (props) {
  const {
    comparison,
    handleChange,
    options,
    showOrNullOption
  } = props;

  const [popupVisible, setPopupVisible] = useState(false);

  let icon;

  switch (comparison) {
    case '+':
      icon = PlusSvg;
      break;
    case '-':
      icon = MinusSvg;
      break;
    case '=':
      icon = EqualsSvg;
      break;
    case '!=':
      icon = NotEqualSvg;
      break;
    case '>':
      icon = GreaterThanSvg;
      break;
    case '>=':
      icon = GreaterThanEqualSvg;
      break;
    case '<':
      icon = LessThanSvg;
      break;
    case '<=':
      icon = LessThanEqualSvg;
      break;
  }

  const handleClick = () => {
    setPopupVisible(!popupVisible);
  };

  const makeHandleSelect = (comparison) => {
    return () => {
      setPopupVisible(!popupVisible);
      handleChange(comparison);
    };
  };

  return (
    <div data-testid='comparison-selector' className='comparison-selector'>
      {popupVisible ? (
        <>
          <div className='comparison-selector-popup-overlay' onClick={handleClick}></div>
          <div className='comparison-selector-popup'>
            <div>
              {(options.indexOf('+') !== -1) ? (<Button onClick={makeHandleSelect('+')} icon={<img src={PlusSvg} />} />) : null}
              {(options.indexOf('-') !== -1) ? (<Button onClick={makeHandleSelect('-')} icon={<img src={MinusSvg} />} />) : null}
            </div>
            <div>
              {(options.indexOf('=') !== -1) ? (<Button onClick={makeHandleSelect('=')} icon={<img src={EqualsSvg} />} />) : null}
              {(options.indexOf('<') !== -1) ? (<Button onClick={makeHandleSelect('<')} icon={<img src={LessThanSvg} />} />) : null}
              {(options.indexOf('>') !== -1) ? (<Button onClick={makeHandleSelect('>')} icon={<img src={GreaterThanSvg} />} />) : null}
            </div>
            <div>
              {(options.indexOf('!=') !== -1) ? (<Button onClick={makeHandleSelect('!=')} icon={<img src={NotEqualSvg} />} />) : null}
              {(options.indexOf('<=') !== -1) ? (<Button onClick={makeHandleSelect('<=')} icon={<img src={LessThanEqualSvg} />} />) : null}
              {(options.indexOf('>=') !== -1) ? (<Button onClick={makeHandleSelect('>=')} icon={<img src={GreaterThanEqualSvg} />} />) : null}
            </div>


            <div>
              {showOrNullOption ? <FormControlLabel control={<Checkbox checked={props.orNull} onClick={props.handleOrNullToggle} />} label='Or N/A' /> : null }
            </div>

          </div>
        </>
      ) : <div style={{ display: 'flex' }}>
        <Button onClick={handleClick} icon={<img src={icon} />} />
        {(showOrNullOption && props.orNull) ? <span style={{ whiteSpace: 'nowrap' }}>*or N/A</span> : null}
      </div>}
    </div>
  );
}

ComparisonSelector.propTypes = {
  comparison: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  orNull: PropTypes.bool,
  showOrNullOption: PropTypes.bool,
  handleOrNullToggle: PropTypes.func
};

ComparisonSelector.defaultProps = {
  showOrNullOption: true,
  options: ['=', '!=', '<=', '<', '>', '>=']
};
