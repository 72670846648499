import './DefineUpgradePath.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormControl, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import variables from 'src/style/variable/variables.module.scss';
import { isWhitelabel } from 'src/utils/utils';
import UpgradeSettingsPanel from './UpgradeSettingsPanel';
import SettingsMapPane from './SettingsMapPane';
import lookup from 'src/utils/langLookUp';
import { fetchDeviceConfigFieldsGeneric, fetchEnumeratedDeviceSettings } from 'src/module/device/action';
import { clearDeviceUpgradeLoading, createDeviceUpgrade, fetchDeviceUpgrades } from 'src/module/upgrade/action';
import { getUpgradePaths } from 'src/module/upgrade/selector';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { debounce } from 'lodash';
import { langLookUpText } from 'src/utils/langLookUp';

// convert string to lowercase and remove spaces
const normalizeString = (str) => {
  return str.toLowerCase().replace(/\s/g, '');
};

export default function DefineUpgradePathScreen () {
  const dispatch: any = useDispatch();
  const [baseDevice, setBaseDevice] = useState('');
  const [upgradeDevice, setUpgradeDevice] = useState('');
  const [settingSearchValue, setSettingsSearchValue] = useState('');
  const [settingFilter, setSettingFilter] = useState('');
  const [upgradePath, setUpgradePath]: any = useState({});
  const settingsPaths = useSelector(getUpgradePaths(baseDevice, upgradeDevice));
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);

  useEffect(() => {
    dispatch(fetchEnumeratedDeviceSettings());
    dispatch(fetchDeviceUpgrades());
    dispatch(clearDeviceUpgradeLoading());
  }, []);

  const onUpdateBaseDevice = (event) => {
    setBaseDevice(event?.target?.value ?? '');
    upgradePath.baseDevice = event?.target?.value;
    setUpgradePath(upgradePath);
    if (event?.target.value) {
      dispatch(fetchDeviceConfigFieldsGeneric([event?.target?.value]));
    }
    return;
  };

  const onUpdateUpgradeDevice = (event) => {
    setUpgradeDevice(event?.target?.value ?? '');
    upgradePath.upgradeDevice = event?.target?.value;
    setUpgradePath(upgradePath);
    if (event?.target.value) {
      dispatch(fetchDeviceConfigFieldsGeneric([event?.target?.value]));
    }
    return;
  };

  const handleAddSettingsPath = (path) => {
    path['base_device_factory'] = baseDevice;
    dispatch(createDeviceUpgrade(path));
  };

  const filteredSettingsPaths = useMemo(() => {
    if (settingFilter) {
      const normalizedFilter = normalizeString(settingFilter);
      return settingsPaths.filter((item) => {
        const settingPath = normalizeString(item.get('setting_path', ''));
        const langSettingPath = normalizeString(langLookUpText(item.get('setting_path', '')));
        return settingPath.includes(normalizedFilter) || langSettingPath.includes(normalizedFilter);
      });
    }
    return settingsPaths;
  }, [settingFilter, settingsPaths]);

  const onSettingFilterUpdated = useMemo(
    () => debounce((value) => setSettingFilter(value), 300),
    []
  );

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';
  return (
    <div id='define-upgrade' className='page' data-testid='define-upgrade-path-screen'>
      <Helmet>
        <title>{title} - Define Upgrade Path</title>
      </Helmet>

      <h2>Define Upgrade Path</h2>
      <div className={classNames(['define-upgrade-path-screen'])}>
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '100%', overflow: 'hidden', width: '100%' }}>
          <div style={{ marginRight: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id="base-device-label">Base Device</InputLabel>
              <Select
                id={'base-device'}
                labelId={'base-device-label'}
                name={'baseDevice'}
                label="BaseDevice"
                value={baseDevice}
                onChange={onUpdateBaseDevice}
                style={{ margin: '0px', maxWidth: '300px' }}
              >
                {Object.entries(lookup.deviceModels.circuits).map(([key, opts], idx) => (
                  <MenuItem key={`${key}-${idx}`} value={key}>
                    {opts.en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl fullWidth>
              <InputLabel id="upgrade-device-label">Upgrade Device</InputLabel>
              <Select
                id={'upgrade-device'}
                labelId={'upgrade-device-label'}
                name={'upgradeDevice'}
                label="UpgradeDevice"
                value={upgradeDevice}
                onChange={onUpdateUpgradeDevice}
                style={{ margin: '0px', maxWidth: '300px' }}
              >
                {Object.entries(lookup.deviceModels.circuits).map(([key, opts], idx) => (
                  <MenuItem key={`${key}-${idx}`} value={key}>
                    {opts.en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={{ marginLeft: 'auto', maxWidth: '250px', alignSelf: 'flex-end', marginRight: '20px' }}>
            <form onSubmit={(e) => { e.preventDefault(); }}>
              <TextField
                placeholder={'Search Settings'}
                variant='standard'
                margin='dense'
                value={settingSearchValue}
                className={'search-field'}
                sx={{ width: '30vw' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => {
                  setSettingsSearchValue(e.target.value);
                  onSettingFilterUpdated(e.target.value);
                }}
              />
            </form>
          </div>
        </div>

        <div className='wrapper'>
          <Paper className='settings-panel-paper'>
            <div className='left'>
              {!isMobile ?
                <UpgradeSettingsPanel settingsPaths={settingsPaths} upgradeDevice={upgradeDevice} onUpdateSettingPath={handleAddSettingsPath} />
                : null}
            </div>
          </Paper>
          <div className='center'>
            {
              baseDevice && upgradeDevice ?
                <SettingsMapPane upgradePath={upgradePath} baseDevice={baseDevice} upgradeDevice={upgradeDevice} settingsPaths={filteredSettingsPaths} />
                : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}
