// eslint-disable-next-line
import React, { createContext } from 'react'

export const StoreContext = createContext({});
StoreContext.displayName = 'StoreContext';

// // helper HOC to provide storeContext to props
// export const withStoreContext = (WrappedComponent) => {
//   return class extends React.Component {
//     static contextType = StoreContext
//     render () {
//       return <WrappedComponent {...this.props} storeContext={this.context} />
//     }
//   }
// }

export default StoreContext;
