import './AdministratorClientScreen.scss';
import React from 'react';
import { Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { getClient } from 'src/module/admin/selector';
import { updateClient } from 'src/module/admin/action';
import ClientDetails from 'src/component/ClientDetails';

export default function AdministratorClientScreen (props) {
  const dispatch = useDispatch();
  const clientId = props.match.params.client_id;
  const clientRaw: any = useSelector(getClient(clientId));
  const client: any = clientRaw.toJS();

  const handleCellChange = (rec) => {
    dispatch(updateClient(client.id, Map(rec)));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <ClientDetails client={client} handleCellChange={handleCellChange} />
      </Grid>
    </Box>
  );
}


AdministratorClientScreen.propTypes = {
  match: PropTypes.object
};

AdministratorClientScreen.defaultProps = {};
