import { addOptionalFilters, addSelectedFiltersToQuery } from 'src/utils/utils';
import { request } from '../api';
import { Map } from 'immutable';

export const validateAlarms = (action, notificationNumber, alarmIds, notificationDescription, deviceId) => {
  return request(action, {
    payload: {
      url: `/eht-alarms/validate`,
      options: {
        method: 'POST',
        data: {
          notificationNumber,
          alarmIds,
          notificationDescription,
          deviceId
        }
      },
    }
  });
};

export const undo = (action) => {
  return request(action, {
    payload: {
      url: `/eht-alarms/undo`,
      options: {
        method: 'POST',
        data: {
          controllerId: action.controllerId,
          circuitId: action.circuitId,
          alarmIds: action.alarmIds,
        }
      },
    }
  });
};

export const dismissAlarms = (action, note, controllerId, circuitId, alarmIds, expiredAt, subject, deviceId) => {
  return request(action, {
    payload: {
      url: `/eht-alarms/dismiss`,
      options: {
        method: 'POST',
        data: {
          note,
          controllerId,
          circuitId,
          alarmIds,
          expiredAt,
          subject,
          deviceId
        }
      },
    }
  });
};

export const acknowledgeAlarms = (action, alarmIds) => {
  return request(action, {
    payload: {
      url: `/eht-alarms/acknowledge`,
      options: {
        method: 'POST',
        data: {
          alarmIds
        }
      },
    }
  });
};

export const getAlarmByDevice = (action, controllerId, circuitId, subAlarmsOnly = false, minimize = false) => {
  const query: any = { includeArchived: true };

  query.subAlarmsOnly = subAlarmsOnly;
  query.minimize = minimize;

  if (circuitId || circuitId === null) {
    query.circuit_id = circuitId;
  }

  if (controllerId || controllerId === null) {
    query.controller_id = controllerId;
  }

  return request(action, {
    payload: {
      url: `/eht-alarms`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getAlarms = (action, showActive = false) => {
  const {
    selectedFilters,
    optionalFilters,
    page,
    pageSize,
  } = action;
  const url = (showActive) ? `/eht-alarms/active` : `/eht-alarms/archived`;

  let query: any = {};
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  query = addSelectedFiltersToQuery(selectedFilters, query);
  query = addOptionalFilters(optionalFilters, query);

  return request(action, {
    payload: {
      url,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getAlarmStats = (action) => {
  const {
    selectedFilters,
    optionalFilters
  } = action;
  let query = {};
  query = addSelectedFiltersToQuery(Map(selectedFilters), query);
  query = addOptionalFilters(optionalFilters, query);

  return request(action, { payload: {
    url: `/eht-alarms/stats`,
    query,
    options: {
      method: 'GET'
    }
  } });
};

export const getAlarmHistory = (action) => {
  const {
    selectedFilters,
    optionalFilters,
    start,
    end
  } = action;
  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);
  query = addOptionalFilters(optionalFilters, query);
  if (start) {
    query.start = start;
  }

  if (end) {
    query.end = end;
  }

  return request(action, {
    payload: {
      url: `/eht-alarms/history`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDevicesInAlarmWithoutNotification = (action) => {
  const payload: any = {
    url: `/eht-alarms/stats/devices-in-alarm-without-notification`,
    options: {
      method: 'GET'
    }
  };
  const {
    selectedFilters,
    optionalFilters
  } = action;
  let query = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);
  query = addOptionalFilters(optionalFilters, query);
  payload.query = query;

  return request(action, {
    payload,
    normalize: (data) => data.map((rec, index) => rec.set('index', index))
  });
};

export const getAlarmsExport = (action) => {
  const {
    selectedFilters,
    panelId
  } = action;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);
  if (panelId) {
    query.panelId = panelId;
  }

  return request(action, {
    payload: {
      url: `/eht-alarms/export`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const dismissAlarmsByGroup = (action) => {

  const {
    alarmGroup,
    deviceCriticality,
    selectedFilters
  } = action;

  return request(action, {
    payload: {
      url: `/eht-alarms/dismiss-alarms-by-group`,
      options: {
        method: 'POST',
        data: {
          alarmGroup: alarmGroup,
          deviceCriticality: deviceCriticality,
          selectedFilters: selectedFilters
        }
      },
    }
  });
};
