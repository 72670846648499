export const functionMapping ={
  "formatkWh": formatkWh,
  "dynamickWhTitle": dynamickWhTitle,
  "dynamickWhHelpText": dynamickWhHelpText,
  'roundAverageMessageTime': roundAverageMessageTime,
  'dynamickWhForcedOnTitle': dynamickWhForcedOnTitle,
  'dynamicForcedOnkWhHelpText': dynamicForcedOnkWhHelpText
};
/// kWh functions
export function formatkWh  (stat)  {
  let newValue = stat; // kWh
  if (stat === '-') {
    return '-';
  }
  if (stat >= 1000000) { // gWh
    newValue = stat / 1000000;
  }
  else if (stat >= 1000) { // mWh
    newValue = stat / 1000;
  }
  newValue = Math.round(newValue);

  return newValue;
}

export function dynamickWhTitle  (value)  {
  if (value>=1000000) {
    return 'Total GWh';
  }
  if (value>=1000) {
    return 'Total MWh';
  }
  return 'Total kWh';
}
export function dynamickWhForcedOnTitle  (value)  {
  let scale = 'kWh';
  if (value>=1000000) {
    scale = 'GWh';
  }
  if (value>=1000) {
    scale = 'MWh';
  }

  return `Total Wasted ${scale}`;
}

export function dynamickWhHelpText (value)  {
  if (value>=1000000) {
    return 'Sum of the past year GWh for all devices or devices that match the filter applied';
  }
  if (value>=1000) {
    return 'Sum of the past year MWh for all devices or devices that match the filter applied';
  }

  return 'Sum of the past year kWh for all devices or devices that match the filter applied';
}

export function dynamicForcedOnkWhHelpText (value)  {
  let scale = 'kWh';
  if (value>=1000000) {
    scale = 'GWh';
  }
  if (value>=1000) {
    scale = 'MWh';
  }

  return `Sum of ${scale} used by devices they were forced on`;
}

export function roundAverageMessageTime (value: string)  {
  const number = parseFloat(value);
  if (number === -1) {
    return 'N/A';
  }
  return `${number?.toFixed(0)} ms`;
}

const gwhCheck = 1000000;
const mwhCheck = 10000; // is more then 1000 so that more can be in the kwh

export function getKWHTitle (maxKWhInGraph) {
  let powerTitle = 'kWh';
  if (maxKWhInGraph > gwhCheck) powerTitle = 'GWh';
  else if (maxKWhInGraph > mwhCheck) powerTitle = 'MWh';

  return powerTitle;
}

export function getConvertAllGraphValuesBasedOnMaxKwh (maxKWhInGraph) {
  let convert =  (value) => (value);
  if (maxKWhInGraph > gwhCheck) convert = (value) => (Math.round(value/1000000));
  else if (maxKWhInGraph > mwhCheck) convert = (value) => (Math.round(value/1000));

  return convert;
}
