import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/import/';

export const { importData, IMPORT_DATA }: any = createTypedAction(
  `${PREFIX}IMPORT_DATA`,
  ['importFile', yup.object().nullable()], // javascript file object is coming in as null but is actually not null
  ['importType', yup.string().nullable()]
);

export const { importDataSuccess, IMPORT_DATA_SUCCESS }: any = createTypedAction(
  `${PREFIX}IMPORT_DATA_SUCCESS`,
  ['importFile', yup.object()],
  ['importType', yup.string()],
  ['subType', yup.string()]
);

export const { importDataFailed, IMPORT_DATA_FAILED }: any = createTypedAction(
  `${PREFIX}IMPORT_DATA_FAILED`,
  ['importFile', yup.object()],
  ['err', yup.object().nullable()],
  ['status', yup.string()]
);

export const { clearUploads, CLEAR_UPLOADS }: any = createTypedAction(
  `${PREFIX}CLEAR_UPLOADS`
);
