import './WorkCompletedGraph.scss';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getAlarmStats } from 'src/module/alarm/selector';
import { getPluralWorkTicketLabel } from 'src/module/authentication/selector';
import { getClient } from 'src/module/authentication/selector';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';
import PlaceholderChart from 'src/screen/Protected/DashboardScreen/assets/placeholder-chart.png';

export default function WorkCompletedGraph () {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const [ehtHistoryLegendLabels, setEhtHistoryLegendLabels] = useState(Map({
    'wo': { label: 'Work Order', stroke: variables.primary },
    'mnra': { label: 'MNRA', stroke: variables.secondary },
    'eng_required': { label: `Eng Req'd`, stroke: variables.tertiary },
    'on_hold': { label: `On Hold`, stroke: variables.quaternary },
    'rejected': { label: 'Rejected', stroke: variables.quinary },
    'cancelled': { label: `Cancelled`, stroke: variables.greyDark }
  }));

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);
  const client = useSelector(getClient);
  const alarmStats = useSelector(getAlarmStats);

  useEffect(() => {
    let changed = false;

    const labels: any = ehtHistoryLegendLabels.toJS();

    Object.keys(labels).forEach((key) => {
      const override = client.getIn(['preferences', 'notificationLabels', key]);
      if (override && labels[key].label !== override) {
        changed = true;
        labels[key].label = override;
      }
    });

    if (changed) {
      setEhtHistoryLegendLabels(Map(labels));
    }
  }, [client]);

  const weekLabels = [];
  const notificationHistory = alarmStats.get('notificationHistory', Map());
  const notificationHistoryData: any = {};
  let statusWeeks: any = [];
  notificationHistory.keySeq().forEach((status) => {
    statusWeeks = [];
    notificationHistory.get(status).forEach((val) => {
      const total = val.get('total');
      statusWeeks.push({ x: `${val.get('week')}`, y: parseInt(total), label: total });
    });
    notificationHistoryData[status] = statusWeeks;
  });

  const notificationHistoryLegendLabels: any = [];
  Object.keys(notificationHistoryData).forEach((status) => {
    if (status !== 'manual' && status !== '') {
      notificationHistoryLegendLabels.push({ name: ehtHistoryLegendLabels.getIn([status, 'label'], '') });
    }
  });

  statusWeeks.map(week => weekLabels.push(week.x));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.2,
    lineTension: 0.5,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    elements: {
      point: {
        radius: 0
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            family: 'Montserrat',
          },
          padding: 10
        }
      },
    },
    scales: {
      x:
        {
          display: true,
          title: {
            display: true,
            text: 'Week Number',
            font: {
              family: 'Montserrat',
              size: 12,
            }
          },
          grid: {
            drawOnChartArea: false,
            display: false
          },
        },
      y: {
        display: true,
        title: {
          display: true,
          text: `Total ${pluralWorkTicketLabel}`,
          font: {
            family: 'Montserrat',
            size: 12,
          }
        },
        grid: {
          drawOnChartArea: false,
          display: false
        },
        ticks: {
          count: 6
        }
      },
    },
  };

  const dataValues: any = [];

  const buildDataset = (data) => {
    const tempData = [];
    data.map(rec => {
      tempData.push(rec.y);
    });
    dataValues.push(tempData);
  };

  Object.keys(notificationHistoryData).map((status) => {
    if (status !== 'manual') {
      buildDataset(notificationHistoryData[status]);
    }
  });

  const chartColors = [
    variables.primary,
    variables.secondary,
    variables.quaternary,
    variables.tertiary,
    variables.quinary,
    variables.violet,
    variables.secondaryDark,
    variables.primaryLight,
    variables.tertiaryDark,
    variables.quinaryLight
  ];

  const dataSets = [];
  for (let i=0; i < Object.keys(notificationHistoryLegendLabels).length; i++) {
    dataSets.push({
      label: notificationHistoryLegendLabels[i]?.name,
      data: dataValues[i].map(x => x),
      borderColor: chartColors[i],
      backgroundColor: chartColors[i]
    });
  }
  const data = {
    labels: weekLabels,
    datasets: dataSets
  };

  return showRotateMessage ? <DeviceRotate /> : Object.keys(notificationHistoryData).length ? (
    <div className='work-completed-graph-block'>
      <Line options={options} data={data} />
    </div>
  ) : (
    <div className="placeholder-graph">
      <div className='outer placeholder-graph-overlay'>
        <div className="middle">
          <div className="inner">No History for the Current Selection</div>
        </div>
      </div>
      <img width='100%' src={PlaceholderChart} alt="Placeholder Graph" />
    </div>
  );
}

WorkCompletedGraph.propTypes = {
};

WorkCompletedGraph.defaultProps = {
};
