import React, { useEffect, useState } from 'react';
import DataTable from 'src/component/UI/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import DeviceLinkCombo from 'src/component/UI/DeviceLinkCombo';
import PropTypes from 'prop-types';

import { deleteDeviceGroup } from 'src/module/groupDevices/action';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import variables from 'src/style/variable/variables.module.scss';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { getTablePagePreferences } from 'src/module/authentication/selector';
import { fetchDeviceGroups } from 'src/module/groupDevices/action';
import { getDeviceGroups, getLoadingDeviceGroups, getNumDeviceGroupsPages, getTotalDeviceGroups } from 'src/module/groupDevices/selector';
import PermissionFence from 'src/component/PermissionFence';

export default function DeviceGroupsList ({ showMassProgramButton }) {
  const dispatch: any = useDispatch();
  const userPagePreference = useSelector(getTablePagePreferences('batch-device-groups'));

  const groups: any = useSelector(getDeviceGroups);

  const numPages: any = useSelector(getNumDeviceGroupsPages);
  const totalGroups: any = useSelector(getTotalDeviceGroups);
  const loading: any = useSelector(getLoadingDeviceGroups);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(userPagePreference);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const [rowToShowAllDevices, setRowToShowAllDevices] = useState(null);


  const devicesCol = (devices, id) => {
    if (!devices || !devices.size) {
      return [];
    }
    if (devices.size > 10 && id !== rowToShowAllDevices) {
      const first10 = devices.slice(0, 10);
      return <>
        {first10.map((device, index) => (<div key={index} className='alarm-list'><DeviceLinkCombo device={device} /></div>))}
        <>... plus {devices.size-10} more</>
      </>;
    }
    return devices.map((device, index) => (<div key={index} className='alarm-list'><DeviceLinkCombo device={device} /></div>));
  };
  const deleteRec = (e, id) => {
    e.stopPropagation();
    dispatch(deleteDeviceGroup(id));
  };
  const columnOptions: any = [
    {
      id: 'name',
      Header: 'Group Name',
      accessor: (row: any) => row?.getIn(['group_name']) || '',
      sortable: true
    },
    {
      id: 'devices',
      Header: 'Devices',
      accessor: (row: any) => row.get('devices'),
      Cell: (row: any) => devicesCol(row.cell.value, row.row.original.get('id')),
      disableSortBy: true
    },
    {
      id: 'actions',
      Header: '',
      accessor: (row: any) => row.id,
      disableSortBy: true,
      Cell: (row: any) => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <PermissionFence can={['edit-device']} >
          <>
            {showMassProgramButton && <Tooltip title="Run a template with this device group" placement="top">
              <NavLink to={`/devices/list/program/byGroup/${row.row.original.get('id')}`}>
                <div><SettingsSuggestIcon  color="primary"   style={{ fontSize: 20 }} /></div>
              </NavLink>
            </Tooltip>}

            <Tooltip title="Delete device group" placement="top">
              <div onClick={(e) => deleteRec(e, row.row.original.get('id'))}>
                <DeleteIcon         style={{ color: variables.gaugeDanger, fontSize: 20, cursor: 'pointer' }}/>

              </div>
            </Tooltip>
          </>
        </PermissionFence>

        {/* hiding for now will use soon */}
        {/* <Tooltip title="Edit device group" placement="top">
          <EditIcon            style={{ color: variables.greyLight, fontSize: 25 }}/>
        </Tooltip> */}

      </div>
    },
  ];
  useEffect(() => {
    dispatch(fetchDeviceGroups({ page, pageSize, filter, sort }));
  }, [page, pageSize, filter, sort]);



  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setFilter(_filter);
    setSort(_sort);
  };
  const handleCellClick = (row) => {
    const record = row.original;
    setRowToShowAllDevices(rowToShowAllDevices === record.get('id')  ? null : record.get('id'));
  };
  return (
    <div className='device-groups' >
      <DataTable
        handleCellClick={handleCellClick} // show all devices
        columns={columnOptions}
        data={groups}
        pagination={true}
        numPages={numPages}
        totalRecords={totalGroups}
        loading={loading}
        fetchPage={fetchPage}
        tableId='batch-device-groups'
        columnWidths={['20%', '70%', '10%']}
      />
    </div>
  );
}

DeviceGroupsList.propTypes = {
  showMassProgramButton: PropTypes.bool
};

DeviceGroupsList.defaultProps = {
  showMassProgramButton: false
};
