import React from 'react';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NavBarItem from 'src/component/UI/NavBar/NavBarItem';
import PermissionFence from 'src/component/PermissionFence';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import CircuitIcon from '@mui/icons-material/AccountTreeOutlined';
import DashboardIcon from '@mui/icons-material/PollOutlined';
import AlarmsIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ContactIcon from '@mui/icons-material/PhoneOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SubNavBarItem from 'src/component/UI/NavBar/SubNavBarItem';
import FactoryIcon from '@mui/icons-material/Factory';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { getTrainingURL, getTrainingPassword } from 'src/module/authentication/selector';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function BottomNavBar (props) {
  const {
    adminBar
  } = props;
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');

  const trainingURL = useSelector(getTrainingURL);
  const trainingPassword = useSelector(getTrainingPassword);

  if (!isMobile || !isPortrait) {
    return null;
  }

  const currentUrl = window.location.href;
  const helpUrl = currentUrl.endsWith("/about");

  const bottomNavBarContent = () => (<>
    <NavBarItem to='/' className={'bottom-nav-item'} icon={<DashboardIcon style={{ fontSize: 30, color: variables.greyFaint }} />} />
    <PermissionFence can='read-eht-alarm'>
      <NavBarItem to='/alarms/active' className={'bottom-nav-item'} icon={<AlarmsIcon style={{ fontSize: 30, color: variables.greyFaint }} />} />
    </PermissionFence>
    <PermissionFence can='read-device'>
      <NavBarItem to='/devices' className={'bottom-nav-item'} icon={<CircuitIcon style={{ fontSize: 30, color: variables.greyFaint }} />} />
    </PermissionFence>
    <NavBarItem subnavbar className={helpUrl ? 'bottom-nav-item' : ''} icon={<HelpIcon style={{ fontSize: 30, color: helpUrl ? variables.white : variables.greyFaint }} />} menuLabel="We're Here to Help!">
      <SubNavBarItem label='Contact Us' to='https://ehtgroup.com/schedule-a-call/' icon={<ContactIcon style={{ fontSize: 20 }} />} />
      {trainingURL && trainingPassword ? <SubNavBarItem label='Training' to='/training' icon={<LightbulbIcon style={{ fontSize: 20 }} />} /> : null}
      <SubNavBarItem label='About EHT Group' to='/about' icon={<InfoIcon style={{ fontSize: 20 }} />} />
    </NavBarItem>
  </>);

  const adminBottomNavBarContent = () => (<>
    <PermissionFence can='smart-trace-admin'>
      <NavBarItem to='/admin' className={'bottom-nav-item'} icon={<FactoryIcon style={{ fontSize: 30, color: variables.greyFaint }} />} />
    </PermissionFence>
  </>);

  return (
    <div data-testid='bottom-navbar' className='bottom-navbar'>
      {
        adminBar ? adminBottomNavBarContent() : bottomNavBarContent()
      }
    </div>
  );
}

BottomNavBar.propTypes = {
  adminBar: PropTypes.bool
};

BottomNavBar.defaultProps = {
  adminBar: false
};
