import _ from 'lodash';

const lookUp = {
  deviceOperatingStatus: { en: 'Device Operating Status', isValueAOption: true },
  controlTemperatureSetpoint: { en: 'Control Temperature Setpoint', isTemp: true },
  lowControlTemperatureAlarmSetpoint: { en: 'Low Control Temperature Alarm Setpoint', isTemp: true },
  highControlTemperatureAlarmSetpoint: { en: 'High Control Temperature Alarm Setpoint', isTemp: true },
  highLoadCurrentAlarmSetpoint: { en: 'High Load Current Alarm Setpoint' },
  controlBand: { en: 'Control Band', isLangBand: true },
  groundFaultTripCurrentSetpoint: { en: 'Ground Fault Trip Current Setpoint' },
  highGroundCurrentAlarmSetpoint: { en: 'High Ground Current Alarm Setpoint' },
  highLoadCurrentTripSetpoint: { en: 'High Load Current Trip Setpoint' },
  highTemperatureTripSetpoint: { en: 'High Temperature Trip Setpoint', isTemp: true },
  lowLoadCurrentAlarmSetpoint: { en: 'Low Load Current Alarm Setpoint' },
  lowTemperatureAlarmSetpoint: { en: 'Low Temperature Alarm Setpoint', isTemp: true },
  controllerRtdConfig: { en: 'Controller Rtd Config' },
  powerClamp: { en: 'Power Clamp' },
  tsFailModeCoil: { en: 'TS Fail Mode', isSwitch: true },
  rtdConfig: { en: 'RTD Configuration', isValueAOption: true },
  highControlAlarmMask: { en: 'High Control Alarm Mask', isSwitch: true },
  lowControlAlarmMask: { en: 'Low Control Alarm Mask', isSwitch: true },
  forcedOn: { en: 'Forced On', isSwitch: true },
  circuitBreakerLimitingAlarmMask: { en: 'Circuit Breaker Limiting Alarm Mask', isSwitch: true },
  switchLimitingAlarmMask: { en: 'Switch Limiting Alarm Mask', isSwitch: true },
  highLimitCutoutAlarmMask: { en: 'High Limit Cutout Alarm Mask', isSwitch: true },
  eEROMFailureAlarmMask: { en: 'EEROM Failure Alarm Mask', isSwitch: true },
  commFailAlarmMask: { en: 'COMM Failure Alarm Mask', isSwitch: true },
  protectionTripAlarmMask: { en: 'Protection Trip Alarm Mask', isSwitch: true },
  controlTSFailureAlarmMask: { en: 'Control TS Failure Alarm Mask', isSwitch: true },
  switchControlMode: { en: 'Switch Control Mode', isValueAOption: true },
  remoteOverrideStatus: { en: 'Remote Override Status', isValueAOption: true },
  voltageSource: { en: 'Voltage Source', isValueAOption: true },
  rtdConfigLabel: { en: 'RTD Configuration Label' },
  enabledTrips: { en: 'Enabled Trips' },
  deadband: { en: 'Deadband', isLangBand: true },
  fixedVoltage: { en: 'Fixed Voltage' },
  proportionalBand: { en: 'Proportional Band', isLangBand: true },
  currentTurnsRatio: { en: 'Current Turns Ratio' },
  voltageTurnsRatio: { en: 'Voltage Turns Ratio' },
  overrideSourceCoil: { en: 'Override Source', isValueAOption: true },
  temperatureSensors: { en: 'Temperature Sensors' },
  externalInputConfig: { en: 'External Input Config', isValueAOption: true },
  htcConsoleUnitsCoil: { en: 'Htc Console Units Coil' },
  lowVoltageAlarmMask: { en: 'Low Voltage Alarm Mask', isSwitch: true },
  autocycleEnabledCoil: { en: 'Autocycle Enabled Coil' },
  externalOutputConfig: { en: 'External Output Config', isValueAOption: true },
  highVoltageAlarmMask: { en: 'High Voltage Alarm Mask', isSwitch: true },
  maximumPowerSetpoint: { en: 'Maximum Power Setpoint' },
  autocycleIntervalTime: { en: 'Autocycle Interval Time' },
  lowVoltageAlarmFilter: { en: 'Low Voltage Alarm Filter' },
  numTemperatureSensors: { en: 'Num Temperature Sensors' },
  highVoltageAlarmFilter: { en: 'High Voltage Alarm Filter' },
  powerLimitingAlarmMask: { en: 'Power Limiting Alarm Mask', isSwitch: true },
  switchFailureAlarmMask: { en: 'Switch Failure Alarm Mask', isSwitch: true },
  controllerCurrentRating: { en: 'Controller Current Rating' },
  lowLoadCurrentAlarmMask: { en: 'Low Load Current Alarm Mask', isSwitch: true },
  lowVoltageAlarmSetpoint: { en: 'Low Voltage Alarm Setpoint' },
  allowDisableAllSetpoints: { en: 'Allow Disable All Setpoints' },
  controllerResetAlarmMask: { en: 'Controller Reset Alarm Mask', isSwitch: true },
  groundFaultTripAlarmMask: { en: 'Gf Trip Enabled', isSwitch: true },
  highLoadCurrentAlarmMask: { en: 'High Load Current Alarm Mask', isSwitch: true },
  highVoltageAlarmSetpoint: { en: 'High Voltage Alarm Setpoint' },
  nominalTracingResistance: { en: 'Nominal Tracing Resistance' },
  overcurrentTripAlarmMask: { en: 'Overcurrent Trip Alarm Mask', isSwitch: true },
  temperatureAlarmModeCoil: { en: 'Temperature Alarm Mode', isValueAOption: true },
  controlTemperatureToolTip: { en: 'Control Temperature Tool Tip' },
  loadSheddingControlStatus: { en: 'Load Shedding Control Status' },
  lowLoadCurrentAlarmFilter: { en: 'Low Load Current Alarm Filter' },
  highLoadCurrentAlarmFilter: { en: 'High Load Current Alarm Filter' },
  autocycleIntervalFormatCoil: { en: 'Autocycle Interval Format', isValueAOption: true },
  circuitBreakerCurrentRating: { en: 'Circuit Breaker Current Rating' },
  contactorCycleCountAlarmMask: { en: 'Contactor Cycle Count Alarm Mask', isSwitch: true },
  highGroundCurrentAlarmFilter: { en: 'High Ground Current Alarm Filter' },
  proportionalAmbientCycleTime: { en: 'Proportional Ambient Cycle Time' },
  lowTracingResistanceAlarmMask: { en: 'Low Tracing Resistance Alarm Mask', isSwitch: true },
  lowTracingResistanceDeviation: { en: 'Low Tracing Resistance Deviation' },
  highTracingResistanceAlarmMask: { en: 'High Tracing Resistance Alarm Mask', isSwitch: true },
  highTracingResistanceDeviation: { en: 'High Tracing Resistance Deviation' },
  highGroundFaultCurrentAlarmMask: { en: 'High Ground Fault Current Alarm Mask', isSwitch: true },
  lowTracingResistanceAlarmFilter: { en: 'Low Tracing Resistance Alarm Filter' },
  highTracingResistanceAlarmFilter: { en: 'High Tracing Resistance Alarm Filter' },
  switchProtectionLimitingAlarmMask: { en: 'Switch Protection Limiting Alarm Mask', isSwitch: true },
  TSFailMode: { en: 'TS Fail Mode' },
  highTSAlarmFilter: { en: 'High TS Alarm Filter' },
  alarmContacts: { en: 'Alarm Contacts' },
  alarmLightMode: { en: 'Alarm Light Mode' },
  alarmOutputFlash: { en: 'Alarm Output Flash' },
  alarmOutputNormalState: { en: 'Alarm Output Normal State' },
  alarmRelayMasking: { en: 'Alarm Relay Masking' },
  alarmRelayType: { en: 'Alarm Relay Type' },
  ambientMasterTemp: { en: 'Ambient Master Temp' },
  ambientSenseHeaterConfig: { en: 'Ambient Sense Heater Config' },
  ambientTemperature: { en: 'Ambient Temperature' },
  autoCycle: { en: 'Auto Cycle' },
  autocycleInterval: { en: 'Auto Cycle Interval' },
  baudRate: { en: 'Baud Rate' },
  baudRateSerialPort1: { en: 'Baud Rate Serial Port 1' },
  baudRateSerialPort2: { en: 'Baud Rate Serial Port 2' },
  circuitBreakerLimitingAlarm: { en: 'Circuit Breaker Limiting Alarm' },
  circuitBreakerLimitingAlarmPriority: { en: 'Circuit Breaker Limiting Alarm Priority' },
  circuitOffset: { en: 'Circuit Offset' },
  commFailAlarm: { en: 'Comm Fail Alarm' },
  commFailAlarmPriority: { en: 'Comm Fail Alarm Priority' },
  commPortSetup: { en: 'Comm Port Setup' },
  communicationProtocol: { en: 'Communication Protocol' },
  consoleUnitsInDegreesF: { en: 'Console Units In Degrees F' },
  contactorCycleCount: { en: 'Contactor Cycle Count' },
  contactorCycleCountAlarm: { en: 'Contactor Cycle Count Alarm' },
  contactorCycleCountAlarmPriority: { en: 'Contactor Cycle Count Alarm Priority' },
  controlTSFailureAlarm: { en: 'Control TS Failure Alarm' },
  controlTSFailureAlarmPriority: { en: 'Control TSFailure Alarm Priority' },
  controlTSFaliure: { en: 'Control TS Failure' },
  controlTSFaliureEnabled: { en: 'Control TS Failure Enabled' },
  controllerIDTag: { en: 'Controller ID Tag' },
  controllerResetAlarm: { en: 'Controller Reset Alarm' },
  controllerResetAlarmPriority: { en: 'Controller Reset Alarm Priority' },
  ctRange: { en: 'Ct Range' },
  deadbandSwitchControlMode: { en: 'Deadband Switch Control Mode' },
  deviceTag: { en: 'Device Tag' },
  eEROMFailureAlarm: { en: 'EEROM Failure Alarm' },
  eEROMFailureAlarmPriority: { en: 'EEROM Failure Alarm Priority' },
  energyCostPerKWh: { en: 'Energy Cost Per KWh' },
  factoryParametersLock: { en: 'Factory Parameters Lock' },
  forceOnCircuit: { en: 'Force On Circuit' },
  groundFaultCalibrationScale: { en: 'Ground Fault Calibration Scale' },
  groundFaultSensitivity: { en: 'Ground Fault Sensitivity' },
  groundFaultTest: { en: 'Ground Fault Test' },
  groundFaultTrip: { en: 'Ground Fault Trip' },
  groundFaultTripAlarm: { en: 'Ground Fault Trip Alarm' },
  groundFaultTripAlarmPriority: { en: 'Ground Fault Trip Alarm Priority' },
  groundFaultTripEnabled: { en: 'Ground Fault Trip Enabled' },
  heatTraceCurveOffset: { en: 'Heat Trace Curve Offset' },
  heatTraceCurveSlope: { en: 'Heat Trace Curve Slope' },
  heaterHighVoltageLevel: { en: 'Heater High Voltage Level' },
  heaterThermostatOverride: { en: 'Heater Thermostat Override' },
  heaterTimeAlarm: { en: 'Heater Time Alarm' },
  highCurrentTrip: { en: 'High Current Trip' },
  highCurrentTripMode: { en: 'High Current Trip Mode' },
  highGroundFaultCurrent: { en: 'High Ground Fault Current' },
  highGroundFaultCurrentAlarm: { en: 'High Ground Fault Current Alarm' },
  highGroundFaultCurrentAlarmPriority: { en: 'High Ground Fault Current Alarm Priority' },
  highGroundFaultCurrentEnabled: { en: 'High Ground Fault Current Enabled' },
  highLimitCutoutSetpoint: { en: 'High Limit Cutout Setpoint' },
  highLimitTempCutout: { en: 'High Limit Temp Cutout' },
  highLimitTempEnable: { en: 'High Limit Temp Enable' },
  highLoadCurrent: { en: 'High Load Current' },
  highLoadCurrentAlarm: { en: 'High Load Current Alarm' },
  highLoadCurrentAlarmPriority: { en: 'High Load Current Alarm Priority' },
  highLoadCurrentAlarmSetpointA: { en: 'High Load Current Alarm Setpoint A' },
  highLoadCurrentAlarmSetpointB: { en: 'High Load Current Alarm Setpoint B' },
  highLoadCurrentAlarmSetpointC: { en: 'High Load Current Alarm Setpoint C' },
  highLoadCurrentEnabled: { en: 'High Load Current Enabled' },
  highLoadCurrentTripSetpointA: { en: 'High Load Current Trip Setpoint A' },
  highLoadCurrentTripSetpointB: { en: 'High Load Current Trip Setpoint B' },
  highLoadCurrentTripSetpointC: { en: 'High Load Current Trip Setpoint C' },
  highTempAlarmDelay: { en: 'High Temp Alarm Delay' },
  highTempTrip: { en: 'High Temp Trip' },
  highTemperatureTripMode: { en: 'High Temperature Trip Mode' },
  highTracingResistanceAlarm: { en: 'High Tracing Resistance Alarm' },
  highTracingResistanceAlarmPriority: { en: 'High Tracing Resistance Alarm Priority' },
  highVoltage: { en: 'High Voltage' },
  highVoltageAlarm: { en: 'High Voltage Alarm' },
  highVoltageAlarmPriority: { en: 'High Voltage Alarm Priority' },
  highVoltageEnabled: { en: 'High Voltage Enabled' },
  highestControlTemperatureRecorded: { en: 'Highest Control Temperature Recorded' },
  ipAddr0: { en: 'IP Address 0' },
  ipAddr1: { en: 'IP Address 1' },
  ipAddr2: { en: 'IP Address 2' },
  ipAddr3: { en: 'IP Address 3' },
  ipPort: { en: 'IP Port' },
  isCelsius: { en: 'Is Celsius' },
  language: { en: 'Language' },
  loadCurrentCalibrationScale: { en: 'Load Current Calibration Scale' },
  localAlarmPriorities: { en: 'Local Alarm Priorities' },
  lowBattery: { en: 'Low Battery' },
  lowLoadCurent: { en: 'Low Load Current' },
  lowLoadCurentEnabled: { en: 'Low Load Current Enabled' },
  lowLoadCurrentAlarm: { en: 'Low Load Current Alarm' },
  lowLoadCurrentAlarmPriority: { en: 'Low Load Current Alarm Priority' },
  lowLoadCurrentAlarmSetpointA: { en: 'Low Load Current Alarm Setpoint A' },
  lowLoadCurrentAlarmSetpointB: { en: 'Low Load Current Alarm Setpoint B' },
  lowLoadCurrentAlarmSetpointC: { en: 'Low Load Current Alarm Setpoint C' },
  lowTSAlarmFilter: { en: 'Low TSAlarm Filter' },
  lowTemperatureSensorAlarmFilter: { en: 'Low Temperature Sensor Alarm Filter' },
  lowTracingResistanceAlarm: { en: 'Low Tracing Resistance Alarm' },
  lowTracingResistanceAlarmPriority: { en: 'Low Tracing Resistance Alarm Priority' },
  loadCurrentTrip: { en: 'Load Current Trip', isSwitch: true },
  highTemperatureTrip: { en: 'High Temperature Trip', isSwitch: true },
  highTemperatureRTD1Trip: { en: 'High Temperature RTD1 Trip', isSwitch: true },
  highTemperatureRTD2Trip: { en: 'High Temperature RTD2 Trip', isSwitch: true },
  lowVoltage: { en: 'Low Voltage' },
  lowVoltageAlarm: { en: 'Low Voltage Alarm' },
  lowVoltageAlarmPriority: { en: 'Low Voltage Alarm Priority' },
  lowVoltageEnabled: { en: 'Low Voltage Enabled' },
  lowestControlTemperatureRecorded: { en: 'Lowest Control Temperature Recorded' },
  manualAlarm: { en: 'Manual Alarm' },
  manualHeater: { en: 'Manual Heater' },
  manufactureDay: { en: 'Manufacture Day' },
  manufactureMonth: { en: 'Manufacture Month' },
  manufactureYear: { en: 'Manufacture Year' },
  maxCurrentRateConfig: { en: 'Max Current Rate Config' },
  maxGroundFaultCurrentRate: { en: 'Max Ground Fault Current Rate' },
  maxOffCurrent: { en: 'Max Off Current' },
  maximumControlTempSetpointAllowed: { en: 'Maximum Control Temp Setpoint Allowed' },
  minimumControlTempSetpointAllowed: { en: 'Minimum Control Temp Setpoint Allowed' },
  moduleCalibrarionDate: { en: 'Module Calibration Date' },
  moduleStatus: { en: 'Module Status' },
  moduleTotalEnergyCost: { en: 'Module Total Energy Cost' },
  moduleTotalEnergyCostFloat: { en: 'Module Total Energy Cost Float' },
  moduleTotalEnergyUsed: { en: 'Module Total Energy Used' },
  moduleTotalEnergyUsedFloat: { en: 'Module Total Energy Used Float' },
  moduleTotalRunningDays: { en: 'Module Total Running Days' },
  moduleTotalRunningDaysFloat: { en: 'Module Total Running Days Float' },
  moduleTotalRunningDaysSinceReset: { en: 'Module Total Running Days Since Reset' },
  moduleTotalRunningDaysSinceResetFloat: { en: 'Module Total Running Days Since Reset Float' },
  moduleTotalRunningHours: { en: 'Module Total Running Hours' },
  moduleTotalRunningHoursFloat: { en: 'Module Total Running Hours Float' },
  newControllerAddress: { en: 'New Controller Address' },
  newControllerPort: { en: 'New Controller Port' },
  newControllerTag: { en: 'New Controller Tag' },
  nonLatchingTemperatureMode: { en: 'Non Latching Temperature Mode' },
  outputSwitchTypeConfig: { en: 'Output Switch Type Config' },
  overcurrentTripAlarm: { en: 'Overcurrent Trip Alarm' },
  overcurrentTripAlarmPriority: { en: 'Overcurrent Trip Alarm Priority' },
  password: { en: 'Password' },
  passwordEnabled: { en: 'Password Enabled' },
  performNetworkScan: { en: 'Perform Network Scan' },
  phaseConfig: { en: 'Phase Config' },
  pointUsed: { en: 'Point Used' },
  powerCycleInterval: { en: 'Power Cycle Interval' },
  powerCycleStart: { en: 'Power Cycle Start' },
  powerLimit: { en: 'Power Limit' },
  powerLimitCurrent: { en: 'Power Limit Current' },
  powerLimitingAlarm: { en: 'Power Limiting Alarm' },
  powerLimitingAlarmPriority: { en: 'Power Limiting Alarm Priority' },
  productCode: { en: 'Product Code' },
  programMode: { en: 'Program Mode' },
  programVersion: { en: 'Program Version' },
  protectionTripAlarm: { en: 'Protection Trip Alarm' },
  protectionTripAlarmPriority: { en: 'Protection Trip Alarm Priority' },
  relayAddress: { en: 'Relay Address' },
  relayCycleAlarm: { en: 'Relay Cycle Alarm' },
  relayNumber: { en: 'Relay Number' },
  relayOutputVoltage: { en: 'Relay Output Voltage' },
  resetContactorCycleCount: { en: 'Reset Contactor Cycle Count' },
  resetHighestGroundCurrentRecorded: { en: 'Reset Highest Ground Current Recorded' },
  resetHighestLoadCurrentRecorded: { en: 'Reset Highest Load Current Recorded' },
  resetInUseAccumulator: { en: 'Reset In Use Accumulator' },
  resetLatchedAlarms: { en: 'Reset Latched Alarms' },
  resetMaxMinTemps: { en: 'Reset Max Min Temps' },
  resetPowerAccumulator: { en: 'Reset Power Accumulator' },
  rtdFailMode: { en: 'Rtd Fail Mode' },
  rtdFaultTripEnabled: { en: 'Rtd Fault Trip Enabled' },
  rtdSelection: { en: 'Rtd Selection' },
  screenDisplay: { en: 'Screen Display' },
  screensaver: { en: 'Screensaver' },
  selfTestHours: { en: 'Self Test Hours' },
  singleTempAlarms: { en: 'Single Temp Alarms' },
  softStart: { en: 'Soft Start' },
  staggarStart: { en: 'Stagger Start' },
  startUpDelay: { en: 'Start Up Delay' },
  switchCurrentRating: { en: 'Switch Current Rating' },
  switchFailureAlarm: { en: 'Switch Failure Alarm' },
  switchFailureAlarmPriority: { en: 'Switch Failure Alarm Priority' },
  switchProtectionLimitingAlarm: { en: 'Switch Protection Limiting Alarm' },
  switchProtectionLimitingAlarmPriority: { en: 'Switch Protection Limiting Alarm Priority' },
  switchType: { en: 'Switch Type' },
  tcm2Type: { en: 'Tcm2Type' },
  tempAlarmFilter: { en: 'Temp Alarm Filter' },
  temperatureSensorFaultClamp: { en: 'Temperature Sensor Fault Clamp' },
  temperatureUnits: { en: 'Temperature Units' },
  testTracing: { en: 'Test Tracing' },
  testingCycle: { en: 'Testing Cycle' },
  thermocoupleType: { en: 'Thermocouple Type' },
  threePhasePowerCalculationEnabledCoil: { en: 'Three Phase Power Calculation Enabled Coil' },
  tripAlarmConfig: { en: 'Trip Alarm Config' },
  unknown: { en: 'Unknown' },
  unknown1: { en: 'Unknown 1' },
  unknown2: { en: 'Unknown 2' },
  updateRawData: { en: 'Update Raw Data' },
  voltageCalibrationScale: { en: 'Voltage Calibration Scale' },
  circuitTag: { en: 'Circuit Tag' },
  controllerTag: { en: 'Controller Tag' },
  controllerType: { en: 'Controller Type' },
  model: { en: 'Model' },
  description: { en: 'Description' },
  heatTraceCircuitNumber: { en: 'Heat Trace Circuit Number' },
  distributionPanel: { en: 'Distribution Panel' },
  distribution_panel: { en: 'Distribution Panel' },
  circuitBreaker: { en: 'Circuit Breaker' },
  circuit_breaker: { en: 'Circuit Breaker' },
  htcAddress: { en: 'HTC Address' },
  RTDTag: { en: 'RTD Tag' },
  rtd_tag: { en: 'RTD Tag' },
  device_address: { en: 'HTC Address' },
  EHTInformation: { en: 'EHT Information' },
  controlMode: { en: 'Control Mode', isValueAOption: true },
  maintainTemperature: { en: 'Maintain Temperature' },
  lowTemperatureAlarm: { en: 'Low Temperature Alarm' },
  lowLoadAlarm: { en: 'Low Load Alarm' },
  highLoadAlarm: { en: 'High Load Alarm' },
  gfTrip: { en: 'Gf Trip' },
  designedSettings: { en: 'Designed Settings' },
  unit: { en: 'Unit' },
  system: { en: 'System' },
  lineDescription: { en: 'Line Description' },
  line_description: { en: 'Line Description' },
  lineSize: { en: 'Line Size' },
  line_size: { en: 'Line Size' },
  lineNumber: { en: 'Line Number' },
  commodity: { en: 'Commodity' },
  location: { en: 'Location' },
  locationUrl: { en: 'Geo Location' },
  pID: { en: 'P&ID' },
  heaterType: { en: 'Heater Type' },
  heater_type: { en: 'Heater Type' },
  'smarttrace-monitoring': { en: 'SmartTrace Monitoring' },
  'smarttrace-monitoring-plus': { en: 'SmartTrace Monitoring Plus' },
  'smarttrace-management': { en: 'SmartTrace Management' },
  'smarttrace-enterprise': { en: 'SmartTrace  Enterprise' },
  'atcom-monitoring': { en: 'Atcom Plus Monitoring' },
  'atcom-monitoring-plus': { en: 'Atcom Plus Monitoring Plus' },
  'atcom-management': { en: 'Atcom Plus Management' },
  'atcom-enterprise': { en: 'Atcom Plus Enterprise' },

  pnid: { en: 'P&ID' },
  deadLeg: { en: 'Dead Legs' },
  status: { en: 'Status' },
  priority: { en: 'Priority' },
  voltage: { en: 'Voltage' },
  additionalData: { en: 'Additional Data' },
  ipAddress: { en: 'IP Address' },
  port: { en: 'Port' },
  modbusAddress: { en: 'Modbus Address' },
  subaddressCircuitNumber: { en: 'Subaddress / Circuit Number' },
  commLoop: { en: 'CommLoop' },
  comm_Loop: { en: 'Comm Loop' },
  firmwareVersion: { en: 'Firmware Version' },
  properties: { en: 'Properties' },
  type: { en: 'Type' },
  controller_tag: { en: 'Controller Tag' },
  commLoopTag: { en: 'Comm Loop Tag' },
  comm_loop_tag: { en: 'Comm Loop Tag' },
  assignedSupervisorClient: { en: 'Assigned Supervisor Client' },
  controllerSettings: { en: 'Controller Settings' },
  controlSettings: { en: 'Control Settings' },
  circuitSettings: { en: 'Circuit Settings' },
  tripSettings: { en: 'Trip Settings' },
  electricalSettings: { en: 'Electrical Settings' },
  externalInputOutput: { en: 'External Input/Output' },
  temperatureControlMode: { en: 'Temperature Fail Mode' },
  failSafeState: { en: 'Fail Safe State', isValueAOption: true },
  temperatureFailMode: { en: 'Temperature Fail Mode', isValueAOption: true },
  temperatureFailModePercent: { en: 'Temperature Fail Mode %' },
  update_designed_settings: { en: 'Update Designed Settings' },
  update_designed_settings_confirm: { en: 'This will update designed settings to match the current device settings. Nothing will change on the physical device.' },
  reprogram_device_settings: { en: 'Reprogram Device Settings' },
  reprogram_device_settings_confirm: { en: 'This will re-program the device settings to match the designed values!' },
  priority_filter_label: { en: 'Priority' },
  commodity_filter_label: { en: 'Commodity' },
  location_filter_label: { en: 'Location' },
  unit_filter_label: { en: 'Unit' },
  system_filter_label: { en: 'System' },
  controller_filter_label: { en: 'Controller' },
  settings_filter_label: { en: 'Settings' },
  dead_leg_filter_label: { en: 'Dead Leg' },
  device_group_filter_label: { en: 'Device Group' },
  trial: { en: 'Trial' },
  paid: { en: 'Paid' },
  free: { en: 'Free' },

  highTemperatureSensorAlarmFilter: { en: 'High Temperature Alarm Filter' },
  rtdFaultPower: { en: 'RTD Fault Power' },
  'physical-damage': { en: 'Does the panel have any evidence of physical damage?' },
  'external-missing-components': { en: 'Does the panel have any missing external components (ex. door latches, hinge pins, ext.)?' },
  'lamacoids-installed': { en: 'are all lamacoids installed?' },
  'internal-physical-damage': { en: 'Is there any evidence of physical damage on any of the panel components?' },
  'seal-weather-intact': { en: 'Are all seals and weather stripping intact and in good physical condition?' },
  'water-ingress': { en: 'Does the panel have any evidence of water ingress?' },
  'internal-missing-components': { en: 'Does the panel have any missisng components (ex.cover plates, screws, bolts, ect.)?' },
  'internal-lamacoids-installed': { en: 'Are all lamacoids installed?' },
  'shrinks-installed': { en: 'Are all shrinks installed and legible?' },
  'tags-installed': { en: 'Are all cable tags installed and legible?' },
  'wiring-terminated': { en: 'Are all wiring securely terminated?' },
  'breakers-installed': { en: 'Are all breakers installed and open?' },
  'wiring-terminated-correctly': { en: 'Does all wiring appear to be terminated correctly?' },
  'lugs-grounded': { en: 'Are all cable lugs grounded correctly?' },
  'tight-lugs-bolts-nuts': { en: 'Do all lugs, bolts, nuts, and screws appear tight?' },
  'circuits-enabled': { en: 'Are all active circuits enabled?' },
  'switch-mode-match': { en: 'Does the switch control mode match the control device (ie. SSr or EMR)' },
  'control-mode-match': { en: 'Does the control mode match the circuit monitoring device (ex. 1 RTD, 2 RTD, AMD, etc.)' },
  'latest-revision-match': { en: 'Do all programmed settings match the latest revision of the set point schedule?' },
  'alarm-trips-enabled': { en: 'Are all GF alarms and trip settings programmed and enabled?' },
  'com-programed': { en: 'Are all communications settings programmed as per client requirements?' },
  'function-test-complete': { en: 'Function Test Completed' },
  'critical-program-deficiencies': { en: 'No Critical Programming Deficiencies Exist' },
  'measured-voltage': { en: 'Measured Voltage' },
  'measured-current': { en: 'Measured Current' },
  'control-mode': { en: 'Current Mode' },
  'outputting-per-design': { en: 'Outputting Per Design' },
  'panel-inspection': { en: 'Panel Inspection' },
  'panel-terminations': { en: 'Panel / Component Termination' },
  'trace-installed-correctly': { en: 'Trace Installed Correctly' },
  'insulation-type': { en: 'Insulation Type' },
  'insulation-deficiencies': { en: 'Insulation Deficiencies' },
  'blanket-installed-correctly': { en: 'Blankets Installed Correctly' },

  deviceModels:
  {
    circuits: {
      NVent910: { en: 'Nvent 910' },
      NVent920: { en: 'Nvent 920' },
      NVentNGC30: { en: 'NVent NGC30' },
      NVentNGC40: { en: 'NVent NGC40' },
      NVentCM2000P: { en: 'NVent AC2000+' },
      ThermonTCM2: { en: 'Thermon TCM2' },
      ThermonTCM18: { en: 'Thermon TCM18' },
      ThermonTC101: { en: 'Thermon TC101' },
      ThermonTC201: { en: 'Thermon TC201' },
      ThermonTC202: { en: 'Thermon TC202' },
      ThermonTC1818: { en: 'Thermon TC1818' },
      ThermonGenesis: { en: 'Thermon Genesis' },
      MasterTraceMR100: { en: 'MasterTrace MR100' },
      SmartTraceMP: { en: 'SmartTrace Monitored Panel' },
    },
    controllers: {
      NVentNGC30Controller: { en: 'NVent NGC30 Controller' },
      NVentNGC40Controller: { en: 'NVent NGC40 Controller' },
      NVentCM2000PController: { en: 'NVent AC2000+ Controller' },
      ThermonTCM2Controller: { en: 'Thermon TCM2 Controller' },
      ThermonTCM18Controller: { en: 'Thermon TCM18 Controller' },
      ThermonTC101Controller: { en: 'Thermon TC101 Controller' },
      ThermonTC201Controller: { en: 'Thermon TC201 Controller' },
      ThermonTC202Controller: { en: 'Thermon TC202 Controller' },
      ThermonTC1818Controller: { en: 'Thermon TC1818 Controller' },
      ThermonGenesisController: { en: 'Thermon Genesis Controller' },
      MasterTraceMR100Controller: { en: 'MasterTrace MR100 Controller' },
      SmartTraceMPController: { en: 'SmartTrace Monitored Panel' },
    }
  },

  deviceValidationErrors: {
    VOLTAGE_RANGE_ERROR: {
      en: 'Low Voltage Alarm Setpoint must be set lower than High Voltage Alarm Setpoint'
    },
    GROUND_RANGE_NEGATIVE_ERROR: {
      en: 'Ground Current Alarm Setpoint must be set greater than zero'
    },
    LOAD_RANGE_NEGATIVE_ERROR: {
      en: 'Load Current Alarm Setpoint must be set greater than zero'
    },
    LOAD_RANGE_ERROR: {
      en: 'Low Load Current Alarm Setpoint must be set lower than High Load Current Trip Alarm Setpoint'
    },
    LOAD_RANGE_TRIP_ERROR: {
      en: 'High Load Current Alarm Setpoint must be set lower than High Load Current Trip Alarm Setpoint'
    },
    HIGH_TEMP_TRIP_RANGE_ERROR: {
      en: 'High Temperature Trip Alarm Setpoint must be set higher than High Temperature Alarm Setpoint'
    },
    GF_RANGE_ERROR: {
      en: 'High Ground Current Alarm Setpoint must be set lower than Ground Fault Trip Current Setpoint'
    },
    CONTROL_TEMP_RANGE_ERROR: {
      en: 'Low Control Temperature Alarm Setpoint must be set lower than High Control Temperature Alarm Setpoint'
    },
    MAINTAIN_TEMP_HIGH_RANGE_ERROR: {
      en: 'Maintain Temperature + Deadband must be set lower than High Control Temperature Alarm Setpoint'
    },
    MAINTAIN_TEMP_LOW_RANGE_ERROR: {
      en: 'Maintain Temperature must be set higher than Low Control Temperature Alarm Setpoint'
    },
    LOW_TS_RANGE_ERROR: {
      en: 'Low Temperature Alarm Setpoint must be set lower than High Temperature Alarm Setpoint'
    },
    INVALID_DATA_POINT: {
      en: 'This data point does not exist for this type of device'
    },
    NO_CHANGES_WARNING: {
      en: `Device is already configured to these values, no changes will occur`
    },
  },

  // functionality groups
  administrative: { en: 'Administrative' },
  alarms: { en: 'Alarms' },
  devices: { en: 'Devices' },
  notes: { en: 'Notes' },
  forcedOnCircuits: { en: 'Forced On Circuits' },
  freezeLogs: { en: 'Freeze Logs' },
  workTickets: { en: 'Work Tickets' },
  reports: { en: 'Reports' },
  NVent920: { en: 'NVent 920' },
  NVent910: { en: 'NVent 910' },
  NVentNGC30Controller: { en: 'NVent NGC30' },
  NVentNGC40Controller: { en: 'NVent NGC40' },
  NVentAC2000P: { en: 'NVent AC2000+' },
  ThermonTCM2Controller: { en: 'Thermon TCM2' },
  ThermonTCM18Controller: { en: 'Thermon TCM18' },
  ThermonTC101Controller: { en: 'Thermon TC101' },
  ThermonTC201Controller: { en: 'Thermon TC201' },
  ThermonTC202Controller: { en: 'Thermon TC202' },
  ThermonTC1818Controller: { en: 'Thermon TC1818' },
  MasterTraceMR100Controller: { en: 'MasterTrace MR100' },
  MSCPController: { en: 'MSCP Modular EHT Controller' },
  SmartTraceController: { en: 'SmartTrace Controller' },
  SmartTraceMPController: { en: 'SmartTrace Monitored Panel' },
  'all-labeling': { en: 'All labelling and tagging is correct and intact as per isometric drawing. EHT cable tag matches isometric.' },
  'damage-check': { en: 'Check for damage, tightness and signs of excessive wear on Controller, SSR, Terminals, Cables, Wires, RTD, Boxes and all other components associated with the EHT.' },
  'walk-line': { en: 'Walk each heat trace line for deficiencies to: Insulation, RTD placement, blocked in valves, etc.' },
  'sr-tested': { en: 'All SR trace tested and operational.*if any SR box cannot be tested due to access a capacitance test must be completed and verified' },
  'rtd-fail-test': { en: 'RTD fail test completed.' },
};

for (let i = 1; i <= 2; i++) {
  lookUp[`ts${i}FailureAlarm`] = { en: `TS ${i} Failure Alarm` };
  lookUp[`ts${i}FailureAlarmPriority`] = { en: `TS ${i} Failure Alarm Priority` };
  lookUp[`TS${i}Faliure`] = { en: `TS ${i} Failure` };
  lookUp[`TS${i}FaliureEnabled`] = { en: `TS ${i} Failure Enabled` };
  lookUp[`highTS${i}`] = { en: `High TS ${i}` };
  lookUp[`highTS${i}Alarm`] = { en: `High TS ${i} Alarm` };
  lookUp[`highTS${i}AlarmPriority`] = { en: `High TS ${i} Alarm Priority` };
  lookUp[`highTS${i}Enabled`] = { en: `High TS ${i} Enabled` };
  lookUp[`lowTS${i}`] = { en: `Low TS ${i}` };
  lookUp[`lowTS${i}Alarm`] = { en: `Low TS ${i} Alarm` };
  lookUp[`lowTS${i}AlarmPriority`] = { en: `Low TS ${i} Alarm Priority` };
  lookUp[`lowTS${i}Enabled`] = { en: `Low TS ${i} Enabled` };
  lookUp[`temperatureCalibrationScaleRtd${i}`] = { en: `Temperature Calibration Scale RTD ${i}` };
  lookUp[`temperatureCalibrationOffsetRtd${i}`] = { en: `Temperature Calibration Offset RTD ${i}` };
}

for (let i = 1; i <= 8; i++) {
  lookUp[`temperatureSensors.${i}.lowTemperatureAlarmSetpoint`] = { en: `Temperature Sensor ${i} Low Temperature Alarm Setpoint`, isTemp: true };
  lookUp[`temperatureSensors.${i}.highTemperatureAlarmSetpoint`] = { en: `Temperature Sensor ${i} High Temperature Alarm Setpoint`, isTemp: true };
  lookUp[`temperatureSensors.${i}.rtdType`] = { en: `Temperature Sensor ${i} High RTD Type` };
  lookUp[`temperatureSensors.${i}.rtdLeadResistance`] = { en: `Temperature Sensor ${i} RTD Lead Resistance` };
  lookUp[`temperatureSensors.${i}.mode`] = { en: `TS ${i} Mode`, isValueAOption: true };
  lookUp[`temperatureSensors.${i}.rtdAddress`] = { en: `TS ${i} RTD Address` };
  lookUp[`temperatureSensors.${i}.canTempInputNumber`] = { en: `TS ${i} Can Temp Input` };
  lookUp[`temperatureSensors.${i}.rmmInputNumber`] = { en: `TS ${i} RMM Input Number` };
  lookUp[`temperatureSensors.${i}.remoteRMMModbus`] = { en: `TS ${i} RMM Modbus` };
  lookUp[`temperatureSensors.${i}.canAddress`] = { en: `TS ${i} CAN Address`, isValueAOption: true };
  lookUp[`temperatureSensors.${i}.localTempInputNumber`] = { en: `TS ${i} Local Temp Input Number` };
  lookUp[`temperatureSensors.${i}.remoteGatewayModbus`] = { en: `TS ${i} Remote Modbus` };
  lookUp[`temperatureSensors.${i}.remoteGatewayPort`] = { en: `TS ${i} Remote Port` };
  lookUp[`temperatureSensors.${i}.config`] = { en: `TS ${i} Config`, isValueAOption: true };
  lookUp[`temperatureSensors.${i}.highAlarmFilter`] = { en: `Temperature Sensor ${i} High Alarm Filter`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.highLimitCutoutEnabled`] = { en: `Temperature Sensor ${i} High Limit Cutout Enabled`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.highTemperatureSensorAlarmFilter`] = { en: `Temperature Sensor ${i} High Temperature Sensor Alarm Filter`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.highTemperatureTripSetpoint`] = { en: `Temperature Sensor ${i} High Temperature Trip Setpoint`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.leadResistance`] = { en: `Temperature Sensor ${i} Lead Resistance`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.lowAlarmFilter`] = { en: `Temperature Sensor ${i} Low Alarm Filter`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.lowTemperatureSensorAlarmFilter`] = { en: `Temperature Sensor ${i} Low Temperature Sensor Alarm Filter`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.rtdNumber`] = { en: `Temperature Sensor ${i} RTD Number`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.rtdTag`] = { en: `Temperature Sensor ${i} RTD Tag`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.type`] = { en: `Temperature Sensor ${i} Type`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.unknown`] = { en: `Temperature Sensor ${i} Unknown`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.unknown2`] = { en: `Temperature Sensor ${i} Unknown 2`, isSwitch: true };
  lookUp[`temperatureSensors.${i}.numCtWraps`] = { en: `Number of CT Wraps` };
  lookUp[`rtd${i}Settings`] = { en: `Temperature Sensor ${i} Settings` };
  lookUp[`lowTS${i}AlarmMask`] = { en: `Low TS ${i} Alarm Mask`, isSwitch: true };
  lookUp[`highTS${i}AlarmMask`] = { en: `High TS ${i} Alarm Mask`, isSwitch: true };
  lookUp[`ts${i}FailureAlarmMask`] = { en: `TS ${i} Failure Alarm Mask`, isSwitch: true };
}

const camelToFlat = (camel) => {

  if (!camel) {
    return '';
  }

  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ");

  const flat = camelCase.map(word =>
    word.charAt(0).toUpperCase() + word.slice(1)
  );

  return flat.join(" ");
};
export default lookUp;

export const langLookUpText = (text) => {
  return lookUp[text] ? lookUp[text].en : camelToFlat(text);
};
export const langLookUpValidationError = (err) => {
  return _.get(lookUp, `deviceValidationErrors.${err}.en`, err);
};
export const isTextValueAOption = (text) => {
  return lookUp[text] ? Boolean(lookUp[text].isValueAOption) : false;
};
export const isTextValueASwitch = (text) => {
  return lookUp[text] ? Boolean(lookUp[text].isSwitch) : false;
};
export const isLangATemperature = (text) => {
  return lookUp[text] ? Boolean(lookUp[text].isTemp) : false;
};
export const isLangBand = (text) => {
  return lookUp[text] ? Boolean(lookUp[text].isLangBand) : false;
};
export const getLabelForDeviceModel = (model) => {

  if (lookUp['deviceModels'].circuits[model]) {
    return lookUp['deviceModels'].circuits[model].en;
  }

  if (lookUp['deviceModels'].controllers[model]) {
    return lookUp['deviceModels'].controllers[model].en;
  }

  return '';
};
