import { addSelectedFiltersToQuery } from 'src/utils/utils';
import { request } from '../api';



export const getWorkTickets = (action, start: string=null, end: string=null, includeCompleted: boolean=false) => {
  const {
    filter,
    sort,
    includeAlarms,
    selectedFilters
  } = action;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);

  const page = (isNaN(action.page)) ? null : action.page;
  const pageSize = (isNaN(action.pageSize)) ? null : action.pageSize;

  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }


  if (filter) {
    query.filter = filter;
  }

  if (start) {
    query.start = start;
  }

  if (end) {
    query.end = end;
  }
  query.onlyActive = true;
  query.onlyArchived = false;

  if (includeCompleted) {
    query.onlyActive = false;
  }

  if (includeAlarms) {
    query.includeAlarms = true;
  }

  return request(action, {
    payload: {
      url: `/sap-notifications`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};
export const updateWorkTicket = (action, id, notification) => {
  return request(action, {
    payload: {
      url: `/sap-notifications/${id}`,
      options: {
        method: 'PATCH',
        data: notification
      },
    }
  });
};
