import { takeLatest, put, call } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as api from '../../service/api';
import * as lookupActions from './action';
import toast from '../../utils/toast';

export function * lookupRoot () {
  yield takeLatest(lookupActions.FETCH_LOOKUP_ITEMS, fetchLookupItems);
  yield takeLatest(lookupActions.UPDATE_LOOKUP_ITEM, updateLookupItem);
  yield takeLatest(lookupActions.DELETE_LOOKUP_ITEM, deleteLookupItem);
  yield takeLatest(lookupActions.ADD_LOOKUP_ITEM, addLookupItem);
}

function * fetchLookupItems (action) {
  try {
    const lookupItemsResponse = yield call(api.getLookupItems, action, action.lookupType);
    yield put(lookupActions.fetchLookupItemsSuccess(action.lookupType, lookupItemsResponse.get('data', List())));
  } catch (err: any) {
    toast.error(`Unable to fetch the ${action.lookupType} configuration`, err.response.status);
    yield put(lookupActions.fetchLookupItemsFailed(Map(err)));
  }
}

function * updateLookupItem (action) {
  try {
    const lookupItem = yield call(api.updateLookupItem, action, action.lookupType, action.key, action.value);
    yield put(lookupActions.updateLookupItemSuccess(action.lookupType, lookupItem));
  } catch (err: any) {
    toast.error(`Unable to update the ${action.lookupType} configuration`, err.response.status);
    yield put(lookupActions.updateLookupItemFailed(Map(err)));
  }
}

function * deleteLookupItem (action) {
  try {
    yield call(api.deleteLookupItem, action, action.lookupType, action.key);
    yield put(lookupActions.deleteLookupItemSuccess(action.lookupType, action.key));
  } catch (err: any) {
    toast.error(`Unable to delete the ${action.key} configuration`, err.response.status);
    yield put(lookupActions.deleteLookupItemFailed(Map(err)));
  }
}

function * addLookupItem (action) {
  try {
    const lookupItem = yield call(api.addLookupItem, action, action.lookupType, action.key, action.value);
    yield put(lookupActions.addLookupItemSuccess(action.lookupType, lookupItem));
  } catch (err: any) {
    toast.error(`Unable to add the ${action.key} configuration`, err.response.status);
    yield put(lookupActions.addLookupItemFailed(Map(err)));
  }
}

export default lookupRoot;
