import './EditableCell.scss';

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TextField, Autocomplete, Select, MenuItem, Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import variables from 'src/style/variable/variables.module.scss';
import Button from './Button';
import PermissionFence from 'src/component/PermissionFence';

export default function EditableCell (props) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(props.value || '');
  const [accepting, setAccepting] = useState(false);


  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const toggleCellEditMode = () => {
    if (!isEditing) {
      setTimeout(() => {
        const cellInput = document.getElementById(props.id);

        if (cellInput) {
          cellInput.focus();
        }
      }, 10);
    }

    if (accepting) {
      setAccepting(false);
    } else {
      setIsEditing(!isEditing);
    }
  };

  const handleAccept = () => {
    return () => {
      setIsEditing(false);
      setAccepting(true);
      let val = inputValue;
      if (type === 'date') {
        val = moment(inputValue, 'YYYY-MM-DD').toDate();
      }
      props.onChange(val);
    };
  };

  const handleChange = () => {
    return (e, value: any = null) => {
      let setValue = value;
      if (!setValue && e) {
        setValue = e.target.value;
      } else {
        setValue = '';
      }
      setInputValue(setValue);
    };
  };

  const selectChange = () => {
    return (e: any = null) => {
      e.stopPropagation();

      setInputValue(e.target.value);
    };
  };

  const handleKeyUp = () => {
    return (e) => {
      if (multiLine === false && (e.key === 'Enter' || e.keyCode === 13)) {
        (handleAccept())();
      }
    };
  };

  const {
    value,
    options,
    emptyCellLabel,
    className,
    autocomplete,
    children,
    permission,
    type,
    multiLine
  } = props;

  const labelClassNames = ['editable-cell-label'];
  const mainClassNames = ['editable-cell'];

  if (className) {
    mainClassNames.push(className);
  }

  if (!value && value !== 0) {
    labelClassNames.push('editable-cell-empty');
  }

  let displayValue = emptyCellLabel;

  if (children) {
    displayValue = children;
  } else if (typeof value !== 'undefined' && value !== null && value !== '') {
    if (options) {
      if (props.multiple) {
        const matchingOptions = options.filter(option => value.includes(option.value || option));
        if (matchingOptions.length > 0) {
          displayValue = matchingOptions.map(option => option.label || option).join(', ');
        }
      }
      const option = options.find(option => (value === option || value == option.value));
      if (option) {
        displayValue = option.label || option;
      }
    }
    else if (type === 'date') {
      displayValue = moment(value).format('YYYY-MM-DD');
    }
    else {
      displayValue = value;
    }
  }

  return isEditing ? (


    <div data-testid='editable-cell' className={classNames(mainClassNames)} style={{ display: 'flex' }} >
      <Backdrop
        sx={{ background: 'unset' }}
        open={isEditing}
        onClick={() => setIsEditing(false)}
      >
      </Backdrop>
      {options ?
        autocomplete ? (
          <Autocomplete
            id={props.id}
            freeSolo
            onInputChange={handleChange()}
            onKeyUp={handleKeyUp()}
            value={value}
            inputValue={inputValue}
            options={options}
            disableClearable
            renderInput={(params) => <TextField {...params} variant='outlined' margin='dense' />}
            forcePopupIcon={false}
          />
        ) : (
          <Select
            id={props.id}
            variant="outlined"
            multiple={props.multiple}
            onChange={selectChange()}
            value={props.multiple ? inputValue : (inputValue != null && options.find(o => o.value == inputValue)) ? inputValue : ''}
          >
            {options.map((option, index) => {
              if (option && (option.value || option.value === 0 || option.value === null)) {
                return (<MenuItem key={index} value={option?.value === null ? '' : option.value}>{option.label || ''}</MenuItem>);
              } else {
                return (<MenuItem key={index} value={option}>{option}</MenuItem>);
              }
            })};
          </Select>
        ) : (
          <TextField
            id={props.id}
            onChange={handleChange()}
            onKeyUp={handleKeyUp()}
            value={inputValue === null ? '' : inputValue}
            variant='outlined'
            margin='dense'
            type={type}
            multiline={multiLine}
            fullWidth
            minRows={3}
            autoComplete="off"
          />
        )
      }
      <Button onClick={handleAccept()} className="check-button-save" icon={<CheckCircleIcon style={{ color: variables.success, fontSize: 14  }} />} />
    </div>
  ) : permission ? (
    <PermissionFence can={permission} noPermissionComponent={(<div data-testid='editable-cell' className={classNames(labelClassNames)} style={{ display: isEditing ? 'none' : 'table-cell' }} >{displayValue}</div>)}>
      <div data-testid='editable-cell' className={classNames(labelClassNames)} style={{ display: isEditing ? 'none' : 'table-cell' }} onClick={toggleCellEditMode}>
        <p>{displayValue}</p>
        {<EditIcon style={{ color: variables.greyLight }} />} </div>
    </PermissionFence>
  ) : (

    <div data-testid='editable-cell' className={classNames(labelClassNames)} style={{ display: isEditing ? 'none' : 'flex' }} onClick={toggleCellEditMode}>
      <p >{displayValue}</p>
      {<EditIcon style={{ color: variables.greyLight }} />} </div>
  );
}

EditableCell.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  handleEdit: PropTypes.func,
  options: PropTypes.array,
  handleClick: PropTypes.func,
  emptyCellLabel: PropTypes.string,
  className: PropTypes.string,
  autocomplete: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  permission: PropTypes.string,
  type: PropTypes.string,
  multiLine: PropTypes.bool,
  multiple: PropTypes.bool,
};

EditableCell.defaultProps = {
  emptyCellLabel: '',
  autocomplete: false,
  children: null,
  permission: null,
  multiLine: false,
  multiple: false,
  type: "text"
};
