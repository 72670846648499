import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckOutlined';

import Button from 'src/component/UI/Button';
import { getSingularWorkTicketLabel } from 'src/module/authentication/selector';
import { getIntegrations } from 'src/module/integration/selector';
import { fetchIntegrations } from 'src/module/integration/action';

export default function NotificationNumberModal (props) {
  const dispatch = useDispatch();
  const [notificationNumber, setNotificationNumber] = React.useState('');
  const [notificationDescription, setNotificationDescription] = React.useState(null);
  const [showDescription, setShowDescription] = React.useState(false);

  const integrations = useSelector(getIntegrations);

  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);
  useEffect(() => {
    dispatch(fetchIntegrations());
  }, []);
  useEffect(() => {
    setShowDescription(!integrations.some(integration => integration.get('type') === 'sap'));
  }, [integrations]);
  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(notificationNumber, notificationDescription);
    setNotificationNumber('');
    setNotificationDescription('');
  };

  return (
    <Modal open={props.open} onClose={props.handleClose} aria-labelledby='notification-number-modal-title' >
      <div className='modal settings-modal-content'>
        <h2 id='notification-number-modal-title'>Valid Alarm(s)</h2>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <TextField value={notificationNumber} onChange={(e) => { setNotificationNumber(e.target.value); }} label={`${singularWorkTicketLabel} Number`} InputLabelProps={{ shrink: false }} fullWidth />
          {showDescription ? <TextField value={notificationDescription} onChange={(e) => { setNotificationDescription(e.target.value); }} label={`${singularWorkTicketLabel} Description`} InputLabelProps={{ shrink: false }} fullWidth /> : null}
          <div className='button-bar'>
            <Button type='submit' icon={<CheckCircleIcon style={{ fontSize: 14 }} />} cta>Validate</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

NotificationNumberModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
