import {  Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as progressActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  progress: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('progress', Map())
  );
}

export const progressReducer = createTypedReducer(initialState, {
  [progressActions.SET_IN_PROGRESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState
        .setIn(['progress', 'isInProgress'], action.isInProgress)
        .setIn(['progress', 'progress'], action.progress)
        .setIn(['progress', 'label'], action.label)
        .setIn(['progress', 'timeout'], action.timeout)
        .setIn(['progress', 'failed'], true)
    );
  },
  [progressActions.UPDATE_PROGRESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.label) {
        nextState = nextState.setIn(['progress', 'label'], action.label);
      }
      return nextState.setIn (['progress', 'progress'], action.progress);
    }
    );
  },
  [progressActions.SET_PROGRESS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.label) {
        nextState = nextState.setIn(['progress', 'label'], action.label);
      }
      if  (action.route) {
        nextState = nextState.setIn(['progress', 'route'], action.route);
      }
      if  (action.confirmString) {
        nextState = nextState.setIn(['progress', 'confirmString'], action.confirmString);
      }
      return nextState.setIn (['progress', 'progress'], action.progress)
        .setIn(['progress', 'isInProgress'], false)
        .setIn(['progress', 'failed'], true);
    }
    );
  },
  [progressActions.SET_PROGRESS_COMPLETE] (state, action) {
    return state.withMutations((nextState) => {
      if (action.label) {
        nextState = nextState.setIn(['progress', 'label'], action.label);
      }
      if  (action.route) {
        nextState = nextState.setIn(['progress', 'route'], action.route);
      }
      if  (action.confirmString) {
        nextState = nextState.setIn(['progress', 'confirmString'], action.confirmString);
      }
      return nextState.setIn (['progress', 'progress'], 100)
        .setIn(['progress', 'isInProgress'], false)
        .setIn(['progress', 'failed'], false);
    }
    );
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default progressReducer;
