import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchNewWorkTickets, FETCH_NEW_WORK_TICKETS }: any = createTypedAction(
  `${PREFIX}FETCH_NEW_WORK_TICKETS`,
  ['since', yup.string().nullable()],
  ['selectedFilters', yup.immutable().map()],
  ['includeAlarms', yup.boolean().default(false)]
);
export const { fetchNewWorkTicketsSuccess, FETCH_NEW_WORK_TICKETS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_NEW_WORK_TICKETS_SUCCESS`,
  ['newWorkTickets', yup.immutable().list().required()]
);

export const { fetchNewWorkTicketsFailed, FETCH_NEW_WORK_TICKETS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_NEW_WORK_TICKETS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchWorkTickets, FETCH_WORK_TICKETS }: any = createTypedAction(
  `${PREFIX}FETCH_WORK_TICKETS`,
  ['includeCompleted', yup.boolean()],
  ['selectedFilters', yup.immutable().map()],
  ['page', yup.number()],
  ['pageSize', yup.number()],
  ['filter', yup.string().nullable()],
  ['sort', yup.string().nullable()]
);

export const { fetchWorkTicketsSuccess, FETCH_WORK_TICKETS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_WORK_TICKETS_SUCCESS`,
  ['workTickets', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['totalPages', yup.number().required()]
);

export const { fetchWorkTicketsFailed, FETCH_WORK_TICKETS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_WORK_TICKETS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { updateWorkTicket, UPDATE_WORK_TICKET, updateWorkTicketQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_WORK_TICKET`,
  (state) => {
    return `update work ticket ${JSON.stringify(state.workTicket.toJS())}`;
  },
  ['id', yup.string().required()],
  ['workTicket', yup.immutable().map().required()],
  ['workTicketFetchObj', yup.object().nullable()]  // fetch work tickets after deleting work ticket
);

export const { updateWorkTicketSuccess, UPDATE_WORK_TICKET_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_WORK_TICKET_SUCCESS`,
  ['workTicket', yup.immutable().map().required()]
);

export const { updateWorkTicketFailed, UPDATE_WORK_TICKET_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_WORK_TICKET_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { loadWorkTicketsFromDevice, LOAD_WORK_TICKETS_FROM_DEVICE }: any = createTypedAction(
  `${PREFIX}LOAD_WORK_TICKETS_FROM_DEVICE`,
  ['workTickets', yup.immutable().list().required()]
);
