export const createTypedReducer = (initialState, handlers: any) => {
  if (process.env.NODE_ENV !== 'production' && handlers['undefined']) {
    console.warn(`Reducer contains an 'undefined' action type. Have you misspelled a constant?`); // eslint-disable-line no-console
  }

  const typedReducer = function (state, action) {
    if (state === undefined) {
      state = initialState;
    }

    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      if (process.env.NODE_ENV === 'test') {
        if (!Object.prototype.hasOwnProperty.call(window.__REDUX_ACTIONS_TYPED__, action.type)) {
          throw new TypeError(`Refusing to dispatch untyped redux action ${action.type}, please define it using createTypedAction!`);
        }

        window.__REDUX_ACTIONS_TYPED__[action.type].validateSync(action);
      }

      return handlers[action.type](state, action);
    }

    return state;
  };

  Object.defineProperty(typedReducer, 'name', {
    value: handlers.name,
    configurable: true
  });

  Object.defineProperty(typedReducer, 'typedReducer', {
    value: true,
    enumerable: true,
    writable: false,
    configurable: false
  });

  return typedReducer;
};

export default createTypedReducer;
