import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import 'src/screen/Protected/DeviceScreen/TestingReport/TestingReports.scss';
import Modal from '@mui/material/Modal';

import { Box, Tabs, Tab } from '@mui/material';
import CheckSheet from 'src/screen/Protected/DeviceScreen/TestingReport/CheckSheet';
import Button from 'src/component/UI/Button';
import { getDeviceById } from 'src/module/device/selector';
import FinalizeAndSignOff from 'src/screen/Protected/DeviceScreen/TestingReport/FinalizeAndSignOff';
import SpliceInfo from 'src/screen/Protected/DeviceScreen/TestingReport/AtControlPanel';
import { Map, fromJS } from 'immutable';
import { getAbilities } from 'src/module/authentication/selector';
import { fetchCheckSheet, saveCheckSheet, updateCheckSheet } from 'src/module/checkSheet/action';
import { getCheckSheetReport, getCheckSheetTasks, getCheckSheetTasksLoading, getSaveCheckSheetReportLoading } from 'src/module/checkSheet/selector';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';

export default function SpliceReports (props) {
  if (!props.open) {
    return null;
  }
  const dispatch = useDispatch();
  const {
    deviceId,
    reportId
  } = props;
  const [tabValue, setTabValue] = useState("1");
  const externalTasks = useSelector(getCheckSheetTasks('splice-repair-external-inspection'));
  const internalTasks = useSelector(getCheckSheetTasks('splice-repair-internal-inspection'));
  const generalTasks = useSelector(getCheckSheetTasks('splice-repair-general'));
  const report = useSelector(getCheckSheetReport(reportId));
  const device = useSelector(getDeviceById(deviceId));
  const isSaveLoading = useSelector(getSaveCheckSheetReportLoading('splice-repair'));
  const isLoadingTasks = useSelector(getCheckSheetTasksLoading);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const abilities: any = useSelector(getAbilities);
  const isPhoneView = useMediaQuery(`(max-width: ${variables.phoneWidth})`);
  const canEdit = abilities.includes('edit-check-sheet-report');
  const readOnly = Boolean(reportId) && !canEdit;
  const externalTasksList: any = [];
  externalTasks.forEach(task => {
    externalTasksList.push({
      task_id: task.key,
      status: null,
      comment: ''
    });
  });
  const internalTasksList: any = [];
  internalTasks.forEach(task => {
    internalTasksList.push({
      task: task.key,
      status: null,
      comment: ''
    });
  });
  const generalTasksList: any = [];
  generalTasks.forEach(task => {
    generalTasksList.push({
      task: task.key,
      status: null,
      comment: ''
    });
  });
  const projectInfo = [
    {
      title: 'Info',
      columns: ['Project No.', 'Area']
    },

  ];
  const projectInfoOutput = {};
  projectInfo.forEach((section) => {
    projectInfoOutput[section.title] = {};
    section.columns.forEach(test => {
      projectInfoOutput[section.title][test] = '';
    });
  });
  const emptyResults = Map({
    info: {
      type: 'splice-repair',
      deviceId,
    },
    'splice-repair-external-inspection': fromJS(externalTasksList),
    'splice-repair-internal-inspection': fromJS(internalTasksList),
    'splice-repair-general': fromJS(generalTasksList),
    extras: Map({
      projectInfo: projectInfoOutput,
    }),
    signOff: Map({
      recommendations: '',
    })
  });
  const [allResults, setAllResults] = useState(reportId === null? emptyResults: null);

  useEffect(() => {
    if (reportId) {
      dispatch(fetchCheckSheet(reportId, 'splice-repair-external-inspection'));
    } else {
      setAllResults(emptyResults);
    }
  }, [reportId]);

  useEffect(() => {
    if (reportId && report.get('checkSheet')) {
      setAllResults(Map(report));
    }
  }, [report, reportId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const next = () => {
    const next = parseInt(tabValue) + 1;
    setTabValue(next.toString());
  };
  const previous = () => {
    const next = parseInt(tabValue) - 1;
    setTabValue(next.toString());
  };
  const submit = () => {
    const values: any = allResults.toJS();
    const c = [...values['splice-repair-external-inspection'], ...values['splice-repair-internal-inspection'], ...values['splice-repair-general']];
    if (reportId) {
      dispatch(updateCheckSheet( allResults.set('checkSheet', fromJS(c)), deviceId, 'splice-repair'));
    }
    else {
      dispatch(saveCheckSheet('splice-repair',  allResults.set('checkSheet', fromJS(c)), deviceId));
    }
  };
  const change = (tab, value) => {
    setAllResults(prevAllResults => prevAllResults.setIn(tab, value));
  };


  const nextButton = () => {
    return <Button className='next-button' onClick={next} cta>Next</Button>;
  };

  const submitButton = () => {
    return <Button onClick={submit} disabled={readOnly} loading={isSaveLoading} cta>{`${ reportId ? 'Save' : 'Submit' }`}</Button>;
  };
  if (reportId && !report.get('checkSheet')) {
    return;
  }
  return (
    <Modal open={props.open} onClose={props.handleClose} style={{ height: '90vh' }} >
      <div className={`modal testing-report-modal${isPhoneView ? ' modal-no-padding-sides' : ''}`}>
        {!isMobile ? <h2>EHT Controller Audit Form<b>({device.get('tag')})</b></h2> : null}
        {isLoadingTasks? <div style={{ margin: 'auto', marginTop: '200px', width: '100px' }}><LogoSpinner size={100} /></div> :
          <>
            <Box sx={{ width: '100%', marginBottom: '20px' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor='primary'
                indicatorColor='primary'
                aria-label="testing-report-tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab value="1" label={<span className='tab-label-size'>Info</span>} />
                <Tab value="2" label={<span className='tab-label-size'>External Inspection</span>} />
                <Tab value="3" label={<span className='tab-label-size'>Internal Inspection</span>} />
                <Tab value="4" label={<span className='tab-label-size'>General Programming Inspection</span>} />
                <Tab value="5" label={<span className='tab-label-size'>Sign Off and Comments</span>} />
              </Tabs>
            </Box>
            {tabValue === '1' ? <SpliceInfo readOnly={readOnly} id='projectInfo' title='' subTitle='' data={allResults?.get('extras')} onChange={change} tableInfo={projectInfo} /> : null}
            {tabValue === '2' ? <CheckSheet readOnly={readOnly} onChange={change} id='splice-repair-external-inspection' title='External Inspection' data={allResults?.get('splice-repair-external-inspection')} checkSheetType="splice-repair-external-inspection" /> : null}
            {tabValue === '3' ? <CheckSheet readOnly={readOnly} onChange={change} id='splice-repair-internal-inspection' title='Internal Inspection' data={allResults?.get('splice-repair-internal-inspection')} checkSheetType="splice-repair-internal-inspection" /> : null}
            {tabValue === '4' ? <CheckSheet readOnly={readOnly} onChange={change} id='splice-repair-general' title='General Programming Inspection' data={allResults?.get('splice-repair-general')} checkSheetType="splice-repair-general" /> : null}
            {tabValue === '5' ? <FinalizeAndSignOff readOnly={readOnly} data={allResults?.get('signOff')} onChange={change} /> : null}

            <div className='button-bar stay-at-bottom'>
              <Button onClick={() => props.handleClose()}>Cancel</Button>


              {tabValue !== '1' ? <Button onClick={previous} >Previous</Button> : null}
              {tabValue === '5' ?
                submitButton()
                :
                nextButton()
              }
            </div>
          </>
        }

      </div>
    </Modal>
  );
}

SpliceReports.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  deviceId: PropTypes.string,
  reportId: PropTypes.string,
};
