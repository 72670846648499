import './ForcedOnModal.scss';

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import ForcedOnIcon from '@mui/icons-material/OfflineBolt';
import { Map } from 'immutable';
import humps from 'humps';
import { useFormik } from 'formik';
import yup from 'src/utils/yup';

import Button from 'src/component/UI/Button';
import DeviceSelectorField from 'src/component/UI/DeviceSelectorField';
import PermissionFence from 'src/component/PermissionFence';
import { getSingularWorkTicketLabel } from 'src/module/authentication/selector';

export default function ForcedOnModal (props) {
  const {
    handleSubmit,
    handleClose,
    open,
    modalTitle,
    circuitId,
    forcedOnCircuit,
    readOnlyCircuit,
    readonly
  } = props;

  const [archived, setArchived] = useState(false);
  const [pnid, setPnid] = useState('---');
  const [lineNumber, setLineNumber] = useState('---');
  const [_circuitId, setCircuitId] = useState(`${circuitId === null ? forcedOnCircuit?.get('circuit_id') : circuitId}`);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);

  const formik = useFormik({
    initialValues: {
      circuitId: _circuitId,
      personRequestingChange: '',
      reasonForRequest: '',
      rtdReading: '',
      note: '',
      notificationNumber: '',
      originalSetPoint: '',
      originalControlMode: '',
      forcedOnSetPoint: '',
      forcedOnControlMode: '',
      forcedOnAt: moment().format('YYYY-MM-DD'),
      expectedRemovalAt: moment().format('YYYY-MM-DD')
    },
    onSubmit: values => {
      const forcedOnCircuit = {
        circuitId: values.circuitId,
        personRequestingChange: values.personRequestingChange,
        reasonForRequest: values.reasonForRequest,
        rtdReading: values.rtdReading,
        note: values.note,
        notificationNumber: values.notificationNumber,
        originalSetPoint: values.originalSetPoint,
        originalControlMode: values.originalControlMode,
        forcedOnSetPoint: values.forcedOnSetPoint,
        forcedOnControlMode: values.forcedOnControlMode,
        forcedOnAt: moment(values.forcedOnAt, 'YYYY-MM-DD').toDate(),
        expectedRemovalAt: moment(values.expectedRemovalAt, 'YYYY-MM-DD').toDate(),
      };
      handleSubmit(Map(humps.decamelizeKeys(forcedOnCircuit)));
    },
    validationSchema: () => {
      return yup.object().shape({
        circuitId: yup
          .string('Circuit')
          .required('Circuit is Required'),
        personRequestingChange: yup
          .string('Person Requesting Change'),
        reasonForRequest: yup
          .string('Reason for Request'),
        rtdReading: yup
          .string('RTD Reading'),
        originalSetPoint: yup
          .string('Original Set Point'),
        originalControlMode: yup
          .string('Original Control Mode'),
        forcedOnSetPoint: yup
          .string('Forced On Set Point'),
        forcedOnControlMode: yup
          .string('Force On Control Mode'),
        forcedOnAt: yup
          .string('Forced On At'),
        expectedRemovalAt: yup
          .string('Expected Removal At')
          .required('Expected Removal Date is Required'),
        note: yup
          .string('Note'),
        notificationNumber: yup
          .string(`${singularWorkTicketLabel} Number`),
      });
    },
  });

  const handleChangeCircuitId = (value) => {
    if ( value?.id && value?.id.trim() !== '' ) {
      formik.setFieldValue('circuitId', value.id, false);
      setCircuitId(value.id);
    }
  };

  const clearValues = () => {
    formik.resetForm();
    setCircuitId(null);
  };

  // this use effect watches to see if a forced on circuit is passed and updates form
  useEffect (() => {
    if (forcedOnCircuit) {
      const foCircuit = forcedOnCircuit;
      const values: any = {};
      //update circuit id
      handleChangeCircuitId({ id: foCircuit.getIn(['circuit_id']) });
      //update formik values
      values.circuitId = foCircuit.getIn(['circuit_id']);
      values.expectedRemovalAt = moment(foCircuit.get('expected_removal_at')).format('YYYY-MM-DD');
      values.forcedOnAt = moment(foCircuit.get('forced_on_at')).format('YYYY-MM-DD');
      values.forcedOnControlMode = foCircuit.get('forced_on_control_mode');
      values.forcedOnSetPoint = foCircuit.get('forced_on_set_point');
      values.originalControlMode = foCircuit.get('original_control_mode');
      values.originalSetPoint = foCircuit.get('original_set_point');
      values.rtdReading = foCircuit.get('rtd_reading');
      values.personRequestingChange = foCircuit.get('person_requesting_change');
      values.reasonForRequest = foCircuit.get('reason_for_request');
      values.note = foCircuit.get('note') !== null ? foCircuit.get('note') : '';
      values.notificationNumber = foCircuit.get('notification_number') !== null ? foCircuit.get('notification_number') :  '';
      formik.setValues(values);

      //update values pulled from circuit (not used by formik)
      setLineNumber(foCircuit.getIn(['circuit', 'line']) ? foCircuit.getIn(['circuit', 'line']) : '---');
      setPnid(foCircuit.getIn(['circuit', 'pnid']) ? foCircuit.getIn(['circuit', 'pnid']) : '---');
      setArchived(foCircuit.get('archived_at') ? true : false);
    }
    // don't clear values if circuit is readonly
    if (!forcedOnCircuit && !readOnlyCircuit) {
      clearValues();
    }
  }, [forcedOnCircuit]);

  useEffect(() => {
    if (circuitId) {
      handleChangeCircuitId({ id: circuitId });
    }
    else {
      handleChangeCircuitId({ id: _circuitId });
    }
  }, [_circuitId, circuitId]);

  // this will clear the formik values when the component is unmounted
  // to make sure we're not persisting the state of the last forcedOnCircuit
  useEffect(() => {
    return clearValues;
  }, []);

  const circuitInformationHeaderTable = <table className='circuit-information-subheading'>
    <tr>
      {modalTitle.includes('Edit') && forcedOnCircuit.get('circuit_deleted_at') !== null ? <td className='deleted-info'>Circuit has been deleted</td> : null}
      <td>PNID: {pnid}</td>
      <td>LINE NUMBER: {lineNumber}</td>
    </tr>
  </table>;
  const circuitInformationHeader = forcedOnCircuit ? circuitInformationHeaderTable : null;

  return (
    <Modal
      data-testid='forced-on-modal'
      open={open}
      onClose={handleClose}
      aria-labelledby='forced-on-modal-title'
    >
      <div className='modal forced-on-modal'>
        <h2 id='forced-on-modal-title'>{modalTitle}</h2>
        <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {circuitInformationHeader}

            <Grid item container direction="column" spacing={3} md={6}>
              <DeviceSelectorField deviceId={_circuitId} value={forcedOnCircuit ? { label: `${forcedOnCircuit.get('controller_tag')} / ${forcedOnCircuit.get('circuit_tag')}`, value: _circuitId } : null} readonly={readonly || readOnlyCircuit} required
                onChange={handleChangeCircuitId} error={Boolean(formik.errors.circuitId)} helperText={formik.errors.circuitId} type='circuit' />
              <TextField disabled={readonly} id='notificationNumber' name='notificationNumber' value={formik.values.notificationNumber} label={`${singularWorkTicketLabel} Number`}
                onChange={formik.handleChange} error={Boolean(formik.errors.notificationNumber)} helperText={formik.errors.notificationNumber} inputProps={{ maxLength: 255 }} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='personRequestingChange' name='personRequestingChange' value={formik.values.personRequestingChange} label='Person Requesting Change'
                onChange={formik.handleChange} error={Boolean(formik.errors.personRequestingChange)} helperText={formik.errors.personRequestingChange} inputProps={{ maxLength: 64 }} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='reasonForRequest' name='reasonForRequest' value={formik.values.reasonForRequest} label='Reason For Request'
                onChange={formik.handleChange} error={Boolean(formik.errors.reasonForRequest)} helperText={formik.errors.reasonForRequest} inputProps={{ maxLength: 255 }} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='rtdReading' name='rtdReading' value={formik.values.rtdReading} label='RTD Reading'
                onChange={formik.handleChange} error={Boolean(formik.errors.rtdReading)} helperText={formik.errors.rtdReading} inputProps={{ maxLength: 16 }} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='note' name='note' value={formik.values.note} label='Note'
                onChange={formik.handleChange} error={Boolean(formik.errors.note)} helperText={formik.errors.note} inputProps={{ maxLength: 255 }} InputLabelProps={{ shrink: false }} />
            </Grid>
            <Grid item container direction="column" spacing={3} md={6}>
              <TextField disabled={readonly} id='originalSetPoint' name='originalSetPoint' value={formik.values.originalSetPoint} label='Original Set Point'
                onChange={formik.handleChange} error={Boolean(formik.errors.originalSetPoint)} helperText={formik.errors.originalSetPoint} inputProps={{ maxLength: 16 }} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='originalControlMode' name='originalControlMode' value={formik.values.originalControlMode} label='Original Control Mode'
                onChange={formik.handleChange} error={Boolean(formik.errors.originalControlMode)} helperText={formik.errors.originalControlMode} inputProps={{ maxLength: 32 }} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='forcedOnSetPoint' name='forcedOnSetPoint' value={formik.values.forcedOnSetPoint} label='Forced On Set Point'
                onChange={formik.handleChange} error={Boolean(formik.errors.forcedOnSetPoint)} helperText={formik.errors.forcedOnSetPoint} inputProps={{ maxLength: 16 }} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='forcedOnControlMode' name='forcedOnControlMode' value={formik.values.forcedOnControlMode} label='Force On Control Mode'
                onChange={formik.handleChange} error={Boolean(formik.errors.forcedOnControlMode)} helperText={formik.errors.forcedOnControlMode} inputProps={{ maxLength: 32 }} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='forcedOnAt' name='forcedOnAt' value={formik.values.forcedOnAt} label='Date Change Made' type='date'
                onChange={formik.handleChange} error={Boolean(formik.errors.forcedOnAt)} helperText={formik.errors.forcedOnAt} InputLabelProps={{ shrink: false }} />
              <TextField disabled={readonly} id='expectedRemovalAt' name='expectedRemovalAt' value={formik.values.expectedRemovalAt} label='Expected Removal Date' type='date'
                onChange={formik.handleChange} error={Boolean(formik.errors.expectedRemovalAt)} helperText={formik.errors.expectedRemovalAt} required InputLabelProps={{ shrink: false }} />
            </Grid>
          </Grid>
          <div className='button-bar'>
            <PermissionFence can={['edit-forced-on-circuit', 'edit-device']} noPermissionComponent={<Button onClick={handleClose}>Close</Button>}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type='submit' disabled={archived} icon={<ForcedOnIcon style={{ fontSize: 14 }} />} cta>Submit</Button>
            </PermissionFence>
          </div>
        </form>
      </div>
    </Modal>
  );
}

ForcedOnModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  circuitId: PropTypes.string,
  forcedOnCircuit: PropTypes.object,
  readOnlyCircuit: PropTypes.bool,
  readonly: PropTypes.bool
};

ForcedOnModal.defaultProps = {
  circuitId: null,
  forcedOnCircuit: null,
  readOnlyCircuit: false,
  readonly: false
};
