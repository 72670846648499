import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Map } from 'immutable';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import toast from 'src/utils/toast';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import DeviceRotate from 'src/component/UI/DeviceRotate';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from 'src/component/UI/Button';

import CheckIcon from '@mui/icons-material/CheckOutlined';
import ConfirmModal from 'src/component/UI/ConfirmModal';
import EditableCell from 'src/component/UI/EditableCell';
import DataTable from 'src/component/UI/DataTable';
import { updateWorkTicket } from 'src/module/workTicket/action';
import { getDevicesSelectedFilters, getSelectedUnit } from 'src/module/filter/selector';
import { getPluralWorkTicketLabel } from 'src/module/authentication/selector';
import { getSingularWorkTicketLabel } from 'src/module/authentication/selector';

import PermissionFence from '../PermissionFence';
import DeviceLinkCombo from 'src/component/UI/DeviceLinkCombo';

export default function WorkTicketsList (props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);

  const {
    deviceId,
    showSearchBar,
    suppressHeader,
    hideDeviceTag,
    showCompletedAt,
    showDeleteIcon,
    showIncludeCompletedOption,
    selectors,
    fetch,
    tableId
  } = props;
  const [pageSize, setPageSize] = useState(10);
  const workTickets: any = useSelector(selectors.getWorkTickets);
  const loading: any = useSelector(selectors.getLoadingWorkTickets);
  const numPages: any = useSelector(selectors.getNumWorkTicketsPages);
  const totalWorkTickets: any = useSelector(selectors.getTotalWorkTickets);
  const selectedUnit: any = useSelector(getSelectedUnit);

  const [includeCompleted, setIncludeCompleted] = useState(false);
  const [deleteWorkTicketModalOpen, setDeleteWorkTicketModalOpen] = useState(false);
  const [workTicketUUID, setWorkTicketUUID] = useState('');
  const [workTicketId, setWorkTicketId] = useState('');

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  const headerFilters = useSelector(getDevicesSelectedFilters);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const handleUpdateFilter = () => {
    dispatch(fetch(!includeCompleted, selectedUnit, page, pageSize, filter, sort, deviceId));
    setIncludeCompleted(!includeCompleted);
  };

  const handleUpdateRow = (field: string, id: string) => {
    return (val) => {
      if (field === 'sap_notification_id' && val.trim() === '') {
        return toast.error(`Invalid ${singularWorkTicketLabel} ID`);
      }
      const rec: any = {};
      rec[field] = val.trim();
      dispatch(updateWorkTicket(id, Map(rec), null));
    };
  };

  const columns: any = [
    {
      id: 'sap_notifications.sap_notification_id',
      Header: 'ID',
      accessor: (row: any) => row.get('sap_notification_id'),
      Cell: (row: any) => (<EditableCell permission='edit-sap-notification' onChange={handleUpdateRow('sap_notification_id', row.cell.row.original.get('id'))} value={row.cell.value || ''} emptyCellLabel='N/A' />)
    }
  ];

  if (!hideDeviceTag) {
    columns.push({
      id: 'device_tag',
      Header: 'Device Tag',
      accessor: (row: any) => row.get('device_tag'),
      Cell: (row: any) => {
        return (
          <DeviceLinkCombo device={row.cell.row.original} />
        );
      },
      maxWidth: '300px',
    });
  }


  columns.push(
    {
      id: 'sap_notifications.description',
      Header: 'Description',
      accessor: (row: any) => row.get('description'),
      maxWidth: '300px',
      Cell: (row: any) => (<EditableCell permission='edit-sap-notification' onChange={handleUpdateRow('description', row.cell.row.original.get('id'))} value={row.cell.value || ''} emptyCellLabel='Add description' />)
    },
    {
      id: 'sap_notifications.revision',
      Header: 'Revision',
      accessor: (row: any) => row.get('revision'),
      Cell: (row: any) => (<EditableCell permission='edit-sap-notification' onChange={handleUpdateRow('revision', row.cell.row.original.get('id'))} value={row.cell.value || ''} emptyCellLabel='Add revision' />)
    },
    {
      id: 'sap_notifications.note',
      Header: 'Note',
      accessor: (row: any) => row.get('note'),
      Cell: (row: any) => (<EditableCell permission='edit-sap-notification' onChange={handleUpdateRow('note', row.cell.row.original.get('id'))} value={row.cell.value || ''} emptyCellLabel='Add note' />)
    },
    {
      id: 'sap_notifications.created_at',
      Header: 'Created At',
      accessor: (row: any) => row.get('notification_created_at'),
      Cell: (row: any) => (row.cell.value ? (<>{moment(row.cell.value).format('YYYY-MM-DD HH:mm')}</>) : '')
    }
  );
  if (showCompletedAt) {
    columns.push({
      id: 'sap_notifications.completed_at',
      Header: 'Completed At',
      accessor: (row: any) => row.get('completed_at'),
      Cell: (row: any) => (row.cell.value ? (<>{moment(row.cell.value).format('YYYY-MM-DD HH:mm')}</>) : '')
    });
  }
  if (showDeleteIcon) {
    columns.push({
      Header: ' ',
      accessor: (row: any) => row.get('id'),
      Cell: (row: any) => row.cell.row.original?.get('completed_at') != null ? "Completed" : (row.invalidated ? null : (
        <PermissionFence can='edit-sap-notification'>
          <Button onClick={() => { handleComplete(row.cell.value, row.cell.row.original.get('sap_notification_id')); }} icon={<CheckIcon style={{ fontSize: 14 }} />} />
        </PermissionFence>
      ))
    });
  }

  const columnWidths = ['15%', '20%', '25%', '15%', '15%'];

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setSort(_sort);
    setFilter(_filter);
  };
  const ref_filters = useRef(null);
  const ref_others = useRef(null);
  useEffect(() => {
    const others = `${page} ${pageSize} ${filter} ${sort} ${deviceId} ${includeCompleted}`;
    if (!_.isEqual(ref_filters.current, headerFilters.toJS()) || others !== ref_others.current) {
      ref_filters.current = headerFilters.toJS();
      ref_others.current = others;


      dispatch(fetch(includeCompleted, headerFilters, page, pageSize, filter, sort, deviceId));
    }
  }, [dispatch, headerFilters, page, pageSize, filter, sort, deviceId, includeCompleted]);

  const workTicketFetchObj = { includeCompleted, selectedUnit, page, pageSize, filter, sort, deviceId };

  const handleComplete = (uuid, workTicketStringId) => {
    toggleDeleteWorkTicketModal();
    assignWorkTicket(uuid, workTicketStringId);
  };

  // i.e. UUID is work ticket id in db and workTicketStringId = '9326781' (shown on work ticket list)
  const assignWorkTicket = (uuid, workTicketStringId) => {
    setWorkTicketUUID(uuid);
    setWorkTicketId(workTicketStringId);
  };

  const toggleDeleteWorkTicketModal = () => {
    setDeleteWorkTicketModalOpen(!deleteWorkTicketModalOpen);
  };
  const deleteWorkTicketModalSubmit = () => {
    dispatch(updateWorkTicket(workTicketUUID, Map({ 'completed_at': new Date() }), workTicketFetchObj));
    toggleDeleteWorkTicketModal();
  };

  const deleteMessage = `Would you like to close work ticket with ID ${workTicketId}`;
  return (
    <div data-testid='note-list' className='alarms-list'>
      <ConfirmModal
        title='Are you sure?'
        message={deleteMessage}
        open={deleteWorkTicketModalOpen}
        handleClose={() => toggleDeleteWorkTicketModal()}
        handleReject={() => toggleDeleteWorkTicketModal()}
        handleConfirm={deleteWorkTicketModalSubmit}
      />
      <PermissionFence can='edit-eht-note'></PermissionFence>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!suppressHeader && (<h2>{props.title}</h2>)}
          {showRotateMessage ? <DeviceRotate/> :
            <DataTable
              pagination
              filterable={showSearchBar}
              filterLabel={`Search ${pluralWorkTicketLabel}`}
              columns={columns}
              data={workTickets}
              numPages={numPages}
              totalRecords={totalWorkTickets}
              loading={loading}
              fetchPage={fetchPage}
              columnWidths={columnWidths}
              tableId={tableId}
            />
          }
          {showIncludeCompletedOption && !showRotateMessage ?
            (<FormControlLabel
              control={
                <Checkbox
                  checked={includeCompleted}
                  onChange={handleUpdateFilter}
                  name='includeCompleted'
                  color='primary'
                />
              }
              label={`Show Completed ${pluralWorkTicketLabel}`}
            />) : null
          }
        </Grid>
      </Grid>
    </div>
  );
}

WorkTicketsList.propTypes = {
  title: PropTypes.string,
  deviceId: PropTypes.string,
  hideDeviceTag: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  suppressHeader: PropTypes.bool,
  pagination: PropTypes.bool,
  showIncludeCompletedOption: PropTypes.bool,
  showCompletedAt: PropTypes.bool,
  showDeleteIcon: PropTypes.bool,
  selectors: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  tableId: PropTypes.string
};
WorkTicketsList.defaultProps = {
  title: null,
  deviceId: null,
  hideDeviceTag: false,
  showSearchBar: false,
  suppressHeader: false,
  showCompletedAt: false,
  showDeleteIcon: false,
  pagination: true,
  showIncludeCompletedOption: false,
  tableId: 'workTicketsTable'
};
