import React from 'react';
import { Select, MenuItem, Input } from '@mui/material';

import PropTypes from 'prop-types';
import { getLabelForDeviceModel, isLangATemperature, isLangBand, langLookUpText } from 'src/utils/langLookUp';
import { getTemperatureUnit } from 'src/module/authentication/selector';
import { useSelector } from 'react-redux';
import { celsiusToFahrenheitConvertor, convertBand, convertBandToCelsius, fahrenheitToCelsiusConvertor } from 'src/utils/utils';

export default function Assignment (props) {
  const {
    baseSettings,
    setting,
    inputType,
    disabled,
    onValueUpdated,
    initialValue
  } = props;
  const [inputValue, setInputValue] = React.useState(initialValue ?? '');
  const [selectValue, setSelectValue] = React.useState(initialValue ?? 'null');
  const temperatureUnit = useSelector(getTemperatureUnit);
  const updateInputValue = (event) => {
    let val = event.target.value;
    if (temperatureUnit === 'F') {

      if (isLangATemperature(setting)) {
        val = fahrenheitToCelsiusConvertor(val);
      }
      if (isLangBand(setting)) {
        val = convertBandToCelsius(val);
      }
    }
    // update path value here
    setInputValue(val);
    onValueUpdated(val);
  };

  const updateSelectValue = (event) => {
    // update path value here
    setSelectValue(event.target.value);
    onValueUpdated(event.target.value);
  };
  const adjustValue = (val) => {
    if (temperatureUnit === 'F') {
      if (isLangATemperature(setting)) {
        return celsiusToFahrenheitConvertor(val);
      }
      if (isLangBand(setting)) {
        return (convertBand(val));
      }
    }
    return val;
  };
  return (
    <div style={{ display: 'flex' }}>
      {inputType === 'input' ?
        <Input
          id='input'
          type='text'
          onChange={updateInputValue}
          value={adjustValue(inputValue)}
          fullWidth
          size="small"
          margin="none"
          style={{ width: '500px', marginTop: '22px', height: '20px' }}
          disabled={disabled}
        />
        : null
      }
      {inputType === 'select' ?
        <Select
          value={selectValue}
          variant="standard"
          disabled={disabled}
          onChange={updateSelectValue}
          style={{ width: '500px', height: 'fit-content', marginTop: '15px' }} >
          <MenuItem key={'test'} value={'null'}>{'Select a Setting'}</MenuItem>
          {
            baseSettings.map((setting) =>
              <MenuItem key={`${setting.deviceModel}-${setting.setting}`} value={`${setting.deviceModel}.${setting.setting}`}>{`${getLabelForDeviceModel(setting.deviceModel)} ${langLookUpText(setting.setting)}`}</MenuItem>
            )
          }
        </Select>
        : null
      }
    </div>
  );
}

Assignment.propTypes = {
  baseDeviceModel: PropTypes.string,
  upgradeDeviceModel: PropTypes.string,
  upgradePathId: PropTypes.string,
  inputType: PropTypes.string,
  baseSettings: PropTypes.any,
  disabled: PropTypes.bool,
  onValueUpdated: PropTypes.func.isRequired,
  initialValue: PropTypes.any,
  setting: PropTypes.string
};

Assignment.defaultProps = {
  inputType: 'input',
};
