import './AuthenticationScreen.scss';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Switch, Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ErrorMessage from 'src/component/UI/ErrorMessage';
import AuthenticationForm from 'src/screen/Authentication/AuthenticationForm';
import PasswordResetForm from 'src/screen/Authentication/PasswordResetForm';
import PasswordSetForm from 'src/screen/Authentication/PasswordSetForm';
import Logo from 'src/component/UI/Logo';
import AtcomLogo from 'src/component/UI/AtcomLogo';
import LoadingSpinner from 'src/component/UI/LoadingSpinner';

import * as authenticationActions from 'src/module/authentication/action';
import { getErrors, getMessages, getIsAuthenticated, getIsAuthenticating, getIsInitialized, getIsMfaRequired, getJwtUser } from 'src/module/authentication/selector';
import MFAForm from './MFAForm';
import { isWhitelabel } from 'src/utils/utils';

export default function AuthenticationScreen (props) {
  const {
    location
  } = props;

  const dispatch = useDispatch();

  const [inputEmail, setInputEmail] = useState(null);
  const errors = useSelector(getErrors);
  const messages = useSelector(getMessages);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAuthenticating = useSelector(getIsAuthenticating);
  const isInitialized = useSelector(getIsInitialized);
  const isMFARequired = useSelector(getIsMfaRequired);

  const user = useSelector(getJwtUser);

  const redirectTo = location ? _.get(location, 'state.from.pathname', '/') : '/';
  useEffect(() => {
    const {
      jwt,
      clientId,
      error
    } = queryString.parse(location.search || '');

    switch (location.pathname) {
      case '/logout':
        dispatch(authenticationActions.clear());
        break;
      case '/login':
        dispatch(authenticationActions.clear());

        if (error) {
          dispatch(authenticationActions.receiveExternalError(error));
        }

        if (jwt) {
          dispatch(authenticationActions.authenticateWithToken('jwt', { jwt }));
        }
        break;
      case '/login/oauth':
        if (error) {
          dispatch(authenticationActions.receiveExternalError(error));
        }
        if (jwt && clientId) {
          dispatch(authenticationActions.oauthStage2(jwt, clientId));
        }
        break;
      case '/login/set':
        dispatch(authenticationActions.validatePasswordJwt(jwt));
        break;
    }

    dispatch(authenticationActions.initialize());
  }, [dispatch, location]);

  useEffect(() => {
    if (user) {
      setInputEmail(user.get('email'));
    }
  }, [user]);

  const handleAuthentication = (email, password) => {
    dispatch(authenticationActions.authenticate('basic', { email, password }));
    setInputEmail(email);
  };

  const handleMFAuthentication = (code, isOTP) => {
    dispatch(authenticationActions.multiFactorAuthenticate(code, isOTP));
  };

  const handlePasswordResetRequest = (email) => {
    dispatch(authenticationActions.requestPasswordReset(email));
  };

  const handlePasswordReset = (jwt, password) => {
    dispatch(authenticationActions.resetPassword(jwt, password));
  };

  const { jwt } = queryString.parse((location.search || ''));

  const renderErrors = (errors = List()) => {
    if (errors.isEmpty()) {
      return null;
    }

    return (
      <div className='auth-screen errors'>
        <ul>
          {errors.map((err: any, index) => (
            <li key={index}>
              <ErrorMessage
                message={err.get('message')}
                handleRetry={err.get('handleRetry')}
                isRetriable={err.get('isRetriable')}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderMessages = (messages = List()) => {
    if (messages.isEmpty()) {
      return null;
    }

    return (
      <div className='messages'>
        <ul>
          {messages.map((message: any, index) => (
            <li key={index}>{message.get('message')}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderAuthenticationForm = () => {
    if (jwt && errors.isEmpty()) {
      return (
        <div className='login-message'>
          <LoadingSpinner /><span>Logging you in...</span>
        </div>
      );
    }

    return (
      <>
        <AuthenticationForm
          handleAuthentication={handleAuthentication}
          redirectTo={redirectTo}
          isAuthenticating={isAuthenticating}
        />
      </>
    );
  };
  const renderMFAAuthenticationForm = () => {
    if (jwt && errors.isEmpty()) {
      return (
        <div className='login-message'>
          <LoadingSpinner /><span>Authenticating...</span>
        </div>
      );
    }
    if (!isMFARequired) {
      return (
        <Redirect to={'/login'} />
      );
    }

    return (
      <>
        <MFAForm
          handleAuthentication={handleMFAuthentication}
          redirectTo={redirectTo}
          isAuthenticating={isAuthenticating}
          errors={errors}
          email={inputEmail}
        />
      </>
    );
  };
  const renderPasswordResetForm = () => {
    return (<PasswordResetForm handlePasswordResetRequest={handlePasswordResetRequest} />);
  };

  const renderPasswordSetForm = () => {
    return (<PasswordSetForm handlePasswordReset={handlePasswordReset} />);
  };

  if (isAuthenticated && isInitialized) {
    return (
      <Redirect to={redirectTo} />
    );
  }
  const whiteLabel = isWhitelabel();

  return (
    <div id='LoginPage' className="centered-outer">
      <div className="centered-middle">
        <div className='authenticationPanel centered-inner'>
          <div className='logoBox'>
            {whiteLabel ? <AtcomLogo size={360} /> : <a className='logo' href='https://ehtgroup.com'><Logo size={256} /></a>}
          </div>

          {renderErrors(errors)}
          {renderMessages(messages)}

          <Switch>
            <Route exact path='/login/reset' render={renderPasswordResetForm} />
            <Route exact path='/login/set' render={renderPasswordSetForm} />
            <Route exact path='/mfa' render={renderMFAAuthenticationForm} />
            <Route path='/' render={renderAuthenticationForm} />
          </Switch>

          <div className='legal'>
            &copy; 2021-{(new Date().getFullYear())} EHT Group Ltd. All rights reserved.
            <br />Patent Pending.
            <a href='https://ehtgroup.com/legal/privacy' target='_blank' rel='noopener noreferrer'>Privacy</a>
            <a href='https://ehtgroup.com/legal/terms' target='_blank' rel='noopener noreferrer'>Terms of Use</a>
          </div>
        </div>
      </div>
    </div>
  );
}

AuthenticationScreen.propTypes = {
  redirectTo: PropTypes.string,
  location: PropTypes.object
};

AuthenticationScreen.defaultProps = {
  redirectTo: '/',
};
