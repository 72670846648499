import { NavLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'src/component/UI/Button';
import { ExpandLess } from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';
import { Popover, Tooltip } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockIcon from '@mui/icons-material/Lock';
const NavBarItem = (props) => {
  const {
    to,
    icon,
    subnavbar,
    isCategory,
    isCategoryOpen,
    isActive,
    label,
    className,
    children,
    onClick,
    isFlyOut,
    onClose,
    menuLabel,
    locked,
    ...rest
  }: any = props;

  const disabled = props.disabled ? props.disabled : locked;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (evnt) => {
    if (onClose) {
      onClose();
    }
    if (disabled) {
      evnt.preventDefault();
      return false;
    }

    return true;
  };

  const handleSubmenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const componentLabel = (<div style={locked && { display: 'flex', justifyContent: 'space-between' }}>
    {label}
    {locked && <Tooltip title={'Talk to your system administrator to gain access' }><span style={{ marginLeft: '5px', marginTop: '1px'  }}><LockIcon fontSize='medium' /></span></Tooltip>}
  </div>);
  return subnavbar ? (<>
    <div>
      <Button onClick={handleSubmenuOpen} className={classNames('navbar-item', className)}>
        <ListItem button key={label}>
          <div className='navbar-item-icon-wrapper'>{icon}</div>
          {label !== '' ? <div className='navbar-item-text'>{componentLabel}</div> : null}
        </ListItem>
      </Button>
      <Popover classes={{ paper: 'subnavbar' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} onClick={handleClose}>
        {menuLabel && (<div className='subnavbar-label'>{menuLabel}</div>)}
        {children}
      </Popover>
    </div>
  </>
  ) : onClick ? (
    <Button className={classNames('navbar-item', props.className)} onClick={onClick}>
      <ListItem button key={label}>
        <div className='navbar-item-icon-wrapper'>{icon}</div>
        {label !== '' ? <div className='navbar-item-text'>{componentLabel}</div> : null}
        {isFlyOut ? <KeyboardArrowRightIcon className='navbar-item-flyout'/> : <></>}
      </ListItem>
    </Button>
  ) : isCategory ? (
    <NavLink className={classNames('navbar-item', isActive ? "active": "", props.className, 'navbar-catagory')} to={to} exact={to === '/'} onClick={handleClick} {...rest}>
      <ListItem button key={label} >
        <div className='navbar-item-icon-wrapper'>{icon}</div>
        {label !== '' ? <div className='navbar-item-text'>{componentLabel}</div> : null}
        {isCategoryOpen ? <ExpandLess  style={{ color: 'white' }}/> : <ExpandMore style={{ color: 'white' }}/>}
      </ListItem>
    </NavLink>
  ) : (
    <NavLink className={classNames('navbar-item', props.className)} to={to} exact={to === '/'} onClick={handleClick} {...rest}>
      <ListItem button key={label}>
        <div className='navbar-item-icon-wrapper'>{icon}</div>
        {label !== '' ? <div className='navbar-item-text'>{componentLabel}</div> : null}
      </ListItem>
    </NavLink>
  );
};

NavBarItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.element,
  subnavbar: PropTypes.bool,
  isCategory: PropTypes.bool,
  isCategoryOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  className: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.array,
  onClick: PropTypes.func,
  menuLabel: PropTypes.string,
  isFlyOut: PropTypes.bool,
  onClose: PropTypes.func,
  locked: PropTypes.bool
};

NavBarItem.defaultProps = {
  to: '',
  icon: null,
  subnavbar: false,
  isCategory: false,
  isCategoryOpen: false,
  disabled: false,
  label: '',
  className: null,
  isActive: false,
  children: null,
  onClick: null,
  menuLabel: '',
  isFlyOut: false,
  onClose: null
};

NavBarItem.whyDidYouRender = true;

export default React.memo(NavBarItem);
