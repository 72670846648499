import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as freezeLogsApi from 'src/service/api/freezeLogs';
import * as freezeLogsActions from './action';
import * as devicesActions from '../device/action';

import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * freezeLogRoot () {
  yield takeLatest(freezeLogsActions.FETCH_FREEZE_LOGS, fetchFreezeLogs);
  yield takeLatest(freezeLogsActions.ADD_FREEZE_LOG, addFreezeLog);
  yield takeLatest(freezeLogsActions.UPDATE_FREEZE_LOG, updateFreezeLog);
}

function * fetchFreezeLogs (action) {
  try {
    const freezeLogs = yield call(freezeLogsApi.getFreezeLogs, action);
    yield put(freezeLogsActions.fetchFreezeLogsSuccess(freezeLogs.get('data'), freezeLogs.get('total'), freezeLogs.get('totalPages')));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error fetching freeze logs!'), err.response.status);
    yield put(freezeLogsActions.fetchFreezeLogsFailed(Map(err)));
  }
}

function * addFreezeLog (action) {
  try {
    yield put(devicesActions.loadingSettingsModal(null));
    const freezeLog = yield call(freezeLogsApi.addFreezeLog, action, action.freezeLog);

    yield put(freezeLogsActions.addFreezeLogSuccess(freezeLog));
    yield put(devicesActions.setSettingsModalClose(null));

  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error adding freeze log!'), err.response.status);
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), null));
  }
}

function * updateFreezeLog (action) {
  try {
    const freezeLog = yield call(freezeLogsApi.updateFreezeLog, action, action.freezeLogId, action.freezeLog);
    yield put(freezeLogsActions.updateFreezeLogSuccess(freezeLog));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating Freeze Log!'), err.response.status);
    yield put(freezeLogsActions.updateFreezeLogFailed(Map(err)));
  }
}

export default freezeLogRoot;
