import { createSelector } from 'reselect';
import { List } from 'immutable';

export const getIntegrationState = (state) =>
  state.integration;

export const getIntegrations = createSelector(
  [getIntegrationState],
  (rule) => {
    return rule.getIn(['integrations', 'data']) || List();
  }
);

export const getLoadingIntegrations = createSelector(
  [getIntegrationState],
  (rule) => {
    return rule.getIn(['integrations', 'loading']) || false;
  }
);

export const getIntegrationFields = createSelector(
  [getIntegrationState],
  (rule) => {
    return rule.getIn(['fields', 'data']) || List();
  }
);

export const getLoadingIntegrationFields = createSelector(
  [getIntegrationState],
  (rule) => {
    return rule.getIn(['fields', 'loading']) || false;
  }
);
