import { request } from '../api';
import * as authService from 'src/service/auth';

export const me = (action) => {
  return request(action, {
    payload: {
      url: `/me`,
      options: {
        method: 'get'
      }
    }
  });
};

export const authenticate = (action, service, params = {}) =>
  request(action, {
    payload: {
      url: '/auth',
      options: {
        method: 'POST',
        data: {
          service,
          ...params
        },
        skipAuthRefresh: true
      }
    }
  });
export const authenticateMFA = async (action, code) => {
  const tempToken = await authService.getTempToken();
  return request(action, {
    payload: {
      url: '/auth/MFA',
      options: {
        method: 'POST',
        data: {
          code,
          tempToken,
          isOTP: action.isOTP
        },
        skipAuthRefresh: true
      }
    }
  });
};
export const resendMFA = async (action) => {
  const tempToken = await authService.getTempToken();

  return request(action, {
    payload: {
      url: '/auth/resendCode',
      options: {
        method: 'POST',
        data: {
          tempToken,
          sendOption: action.option

        },
        skipAuthRefresh: true
      }
    }
  });
};
export const refreshAuthToken = async (action) => {
  const refreshToken = await authService.getRefreshToken();
  const sessionId = await authService.getSessionId();

  return request(action, {
    payload: {
      url: '/auth/refresh',
      options: {
        method: 'POST',
        data: {
          refreshToken,
          sessionId
        }
      }
    }
  });
};

export const authenticateWithToken = (action, service, params = {}) =>
  request(action, {
    payload: {
      url: '/auth/token',
      options: {
        method: 'POST',
        data: {
          service,
          ...params
        },
        skipAuthRefresh: true
      }
    }
  });

export const changePassword = (action, oldPassword, newPassword) =>
  request(action, {
    payload: {
      url: '/auth/password',
      options: {
        method: 'POST',
        data: {
          oldPassword,
          newPassword
        }
      }
    }
  });

export const sendPasswordResetEmail = (action, email) =>
  request(action, {
    payload: {
      url: '/auth/reset',
      options: {
        method: 'POST',
        data: {
          email
        }
      }
    }
  });

export const validatePasswordResetJWT = (action, jwt) =>
  request(action, {
    payload: {
      url: '/auth/reset',
      options: {
        method: 'PUT',
        data: {
          jwt
        }
      }
    }
  });

export const resetPassword = (action, jwt, password) => {
  return request(action, {
    payload: {
      url: '/auth/new',
      options: {
        method: 'PUT',
        data: {
          jwt,
          password
        }
      }
    }
  });
};

export const fetchUserNotificationPrefs = (action) => {
  return request(action, {
    payload: {
      url: '/notifications/unsubscribed',
      options: {
        method: 'GET',
      }
    }
  });
};

export const updateUserNotificationPrefs = (action) => {
  return request(action, {
    payload: {
      url: `/notifications/unsubscribe/`,
      options: {
        method: 'POST',
        data: {
          notification_key: action.notificationKey,
        }
      }
    }
  });
};
export const deleteUserNotificationPrefs = (action) => {
  return request(action, {
    payload: {
      url: `/notifications/unsubscribe/${action.notificationKey}`,
      options: {
        method: 'DELETE'
      }
    }
  });
};

export const fetchUserNotifications = (action) => {
  return request(action, {
    payload: {
      url: '/notifications',
      options: {
        method: 'GET',
      }
    }
  });
};

export const getUserByEmail = async (action) => {

  const { email } = action;
  return request(action, {
    payload: {
      url: `/auth/${email}`,
      options: {
        method: 'GET',
      }
    }
  });
};

export const setUnverifiedContactNumber = async (action) => {
  const { contactNumber, userId } = action;

  return request(action, {
    payload: {
      url: `/auth/${userId}`,
      options: {
        method: 'PATCH',
        data: { contactNumber }
      }
    }
  });
};
