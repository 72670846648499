import configureStore, { history } from './configureStore';

class ConfiguredStore {
  public store;
  public persistor;
  public history;

  constructor () {
    const { store, persistor } = configureStore();

    this.store = store;
    this.persistor = persistor;
    this.history = history;
  }

  static async init () {
    return new ConfiguredStore();
  }
}

let configuredStore;
export async function getConfiguredStore () {
  if (!configuredStore) {
    configuredStore = await ConfiguredStore.init();
  }

  return configuredStore;
}

// Effect function

export async function dispatch (action, store) {
  try {
    if (!store) {
      store = (await getConfiguredStore()).store;
    }
    store.dispatch(action);
  } catch (error) {
    console.log(`Failed to dispatch action ${action.type}`, error); // eslint-disable-line no-console
  }
}

export async function selectFromStore (selector, _store = null) {
  let store;
  if (!store) {
    store = (await getConfiguredStore()).store;
  } else {
    store = _store;
  }

  return selector(store.getState());
}

export { history };
