import './AlarmNoteModal.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LockIcon from '@mui/icons-material/Lock';

import Modal from '@mui/material/Modal';
import NuisanceIcon from '@mui/icons-material/BugReport';
import LotoIcon from '@mui/icons-material/LocalOffer';
import ResetIcon from '@mui/icons-material/CallMissedOutlined';
import FeatureFence from 'src/component/FeatureFence';
import Button from 'src/component/UI/Button';
import { TextField, Tooltip } from '@mui/material';
import PermissionFence from 'src/component/PermissionFence';
export default function AlarmNoteModal (props) {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [expiry, setExpiry] = useState('');
  const [subject, setSubject] = useState('');

  useEffect(() => {
    setSelectedStatus('');
  }, [props.open]);

  const handleClick = (status) => () => {
    setSelectedStatus(status);
  };

  const handleSubmit = (status, expiry=null, subject=null) => () => {
    setSelectedStatus('');
    props.handleSubmit(status, expiry, subject);
  };

  const alarmsMap = {};

  if (props.alarms) {
    props.alarms.forEach((alarm) => {
      alarmsMap[alarm.get('alarm')] = 1;
    });
  }

  const alarms = Object.keys(alarmsMap);

  let canNuisance = false;
  if (alarms.length === 1 && (alarms[0] === 'low temp' || alarms[0] === 'low load')) {
    canNuisance = true;
  }

  let canNote = true;
  if (!props.circuitId && !props.controllerId && !props.deviceId) {
    canNote = false;
    canNuisance = false;
  }

  const statusLabels = {
    loto: 'LOTO',
    nuisance: 'Nuisance'
  };
  const restAlarmButton = (locked) => {
    return <Button cta className='large-btn centered' disabled={locked} backgroundColor='error' onClick={handleSubmit('dismiss')} icon={<ResetIcon style={{ fontSize: 14 }} />} >Reset Alarm</Button>;
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='alarm-note-modal-title'
    >
      <div className='modal alarm-note-modal'>
        <h2 id='alarm-note-modal-title'>Add {statusLabels[selectedStatus]} Note</h2>
        {(selectedStatus === '') ? (
          <div className='dismiss-form'>
            <Button cta className='large-btn' disabled={!canNote} backgroundColor='primary' onClick={handleClick('loto')} icon={<LotoIcon style={{ fontSize: 14 }} />} >LOTO</Button>
            <Button cta className='large-btn' disabled={!canNuisance} backgroundColor='primary' onClick={handleClick('nuisance')} icon={<NuisanceIcon style={{ fontSize: 14 }} />} >Nuisance</Button>
            <div>
              <PermissionFence can='reset-alarms'>
                <FeatureFence can={['program-device']}
                // !feature & permission = show lock
                  noPermissionComponent={
                    <Tooltip title={'Talk to your system administrator to gain access to reset alarms' } placement='right'>
                      <div style={{ position: 'relative' }}>
                        {restAlarmButton(true)}
                        <span style={{ position: 'absolute', top: '15px', right: '5px' }}><LockIcon fontSize='medium' /></span>
                      </div>
                    </Tooltip>
                  }
                >
                  {/* Feature & permission = show normal */}
                  {restAlarmButton(false)}
                </FeatureFence>
              </PermissionFence>
            </div>
          </div>
        ) : (
          <div className='expiry-form'>
            <div className="modal-field">
              <span className='modal-label'>Subject:</span>
              <TextField className='modal-input' id="note-subject" type='text' onChange={ e => setSubject(e.target.value) } />
            </div>
            <div className="modal-field">
              <span className='modal-label'>Expire Note On:</span>
              <TextField className='modal-input' id="note-expiry" type='date' onChange={ e => setExpiry(e.target.value) } />
            </div>
            <div className='button-bar'>
              <Button onClick={() => setSelectedStatus('')} >Back</Button>
              <Button cta backgroundColor='primary' onClick={handleSubmit(selectedStatus, expiry, subject)} disabled={!expiry}>Add Note</Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

AlarmNoteModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  circuitId: PropTypes.string,
  controllerId: PropTypes.string,
  alarms: ImmutablePropTypes.list,
  deviceId: PropTypes.string,
};

AlarmNoteModal.defaultProps = {
  circuitId: null,
  controllerId: null,
  deviceId: null
};
