import "./OfflineQueue.scss";

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Button from 'src/component/UI/Button';
import DataTable from 'src/component/UI/DataTable';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { getIsQueueRunning, getOfflineQueue } from 'src/module/offline/selector';
import { getAllDevices, getAllPanels } from 'src/module/device/selector';
import { getAllAlarms } from 'src/module/alarm/selector';
import { getIsOffline } from 'src/module/offline/selector';
import { clearQueue, removeFromQueue, triggerQueue } from 'src/module/offline/action';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Tooltip from '@mui/material/Tooltip';
import HelpCircleIcon from '@mui/icons-material/HelpOutline';
import moment from 'moment';
import LogoSpinner from './UI/LogoSpinner';
import { actionsFormatterMap } from 'src/utils/offlineHelperFunctions';
import { getUsers } from 'src/module/user/selector';

export default function OfflineQueue (props) {
  const {
    open,
    handleClose
  } = props;
  const dispatch = useDispatch();
  const devices = useSelector(getAllDevices);
  const alarms = useSelector(getAllAlarms);
  const panels = useSelector(getAllPanels);
  const isOffline = useSelector(getIsOffline);
  const isQueueRunning = useSelector(getIsQueueRunning);
  const queue = useSelector(getOfflineQueue);
  const users = useSelector(getUsers);
  const [filteredQueue, setFilteredQueue] = useState(List([]));

  const formatAction = (action) => {
    if (!action) {
      return;
    }

    const actionFormatter = actionsFormatterMap[action.type];
    return {
      info: actionFormatter ? actionFormatter(action, devices, alarms, panels, users) : '',
      queueId: action.queueId,
      performed_at: action.performed_at
    };
  };

  useEffect(() => {
    const onlyUpdates = queue.filter(item => { return actionsFormatterMap[item.type]; });

    const filteredAction = onlyUpdates.map(item =>
      formatAction(item)
    );

    setFilteredQueue(filteredAction);
  }, [queue, open]);

  const columns: any = [
    {
      id: 'details',
      Header: 'Details',
      accessor: (row: any) => <p style={{ wordBreak: 'break-word' }}>{row.info}</p>,
    },
    {
      id: 'performed_at',
      Header: 'Performed At',
      accessor: (row: any) => moment(row.performed_at).format('YYYY-MM-DD HH:mm'),
    },
    {
      Header: ' ',
      accessor: (row: any) => row.queueId,
      Cell: (row: any) => (
        <Button onClick={() => deleteItemFromQueue(row.row.original.queueId)} icon={<DeleteIcon style={{ fontSize: 14 }} />} />
      )
    }
  ];

  const numPages: any = 1;
  const numActions: any = filteredQueue.size;

  const deleteItemFromQueue = (id) => {
    dispatch(removeFromQueue(id));
  };
  const clearQueueClick = () => {
    dispatch(clearQueue());
  };
  const runQueue = () => {
    dispatch(triggerQueue(true));
    handleClose();
  };
  return (
    <Modal
      data-testid='offline-queue-modal'
      open={open}
      onClose={handleClose}
      aria-labelledby='offline-queue-modal-title'
    >
      <div className='modal offline-queue'>
        <div className='settings-modal-top-bar'>
          <h2 className='settings-modal-title'>Actions Queued <Tooltip title='Actions you preformed while offline'><HelpCircleIcon className='help-icon' style={{ fontSize: 16 }} /></Tooltip></h2>
        </div>

        {isQueueRunning ? <div style={{ width: '300px', height: '500px', margin: 'auto', paddingTop: '200px' }}>
          <LogoSpinner size={200} />
        </div> : null}
        {filteredQueue.size > 0 ? <div className="buttonWrap"><Button onClick={() => clearQueueClick()} className='clearButton' icon={<ClearAllIcon style={{ fontSize: 14 }} />}>Clear Queue</Button> </div> : ''}
        <div className='tableWrapper'>
          <DataTable
            pagination
            columns={columns}
            data={filteredQueue}
            numPages={numPages}
            totalRecords={numActions}
            loading={false}
            tableId="offlineQueueTable"
          />
        </div>



        <div className='button-bar'>
          <Button onClick={handleClose} disabled={isOffline ? false : filteredQueue.size !== 0} >Cancel</Button>
          <Button cta disabled={isOffline ? true : filteredQueue.size === 0} onClick={runQueue}>Run Queue</Button>
        </div>
      </div>
    </Modal>
  );
}

OfflineQueue.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

OfflineQueue.defaultProps = {
};
