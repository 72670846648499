import './AuthenticationForm.scss';
import './MFAForm.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as authenticationActions from 'src/module/authentication/action';

import ImmutablePropTypes from 'react-immutable-proptypes';
import Button from 'src/component/UI/Button';
import yup from 'src/utils/yup';
import { NavLink } from 'react-router-dom';

import { getIsAuthenticating, getMfaOptions, getOTP_URL } from 'src/module/authentication/selector';
import { getUserByEmail } from 'src/module/user/selector';
import ContactNumberForm from './ContactNumberForm';
import TOTPQRCode from './TOTPAuthForm';
import CodeAuth from './CodeAuth';
import VerificationInput from 'react-verification-input';
export default function MFAForm (props) {
  const dispatch = useDispatch();
  const [code, setCode] = useState(null);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: () => {
      props.handleAuthentication(code, false);
    },
    validationSchema: () => {
      return yup.object().shape({
        code: yup.string()
          .required('Code can\'t be blank')
      });
    },
  });

  const isAuthenticating = useSelector(getIsAuthenticating);
  const user = useSelector(getUserByEmail(props.email));

  const mfaOptions = useSelector(getMfaOptions);
  const OTP_URL = useSelector(getOTP_URL);
  const resendCode = (option) => {
    const loginMethod = authOption ?? option;
    if ((user?.get('contact_number') && loginMethod === 'text') || loginMethod === 'email') {
      dispatch(authenticationActions.resendMultiFactorAuthenticate(loginMethod));
    }
  };
  // screen steps
  // 1 - multiple MFA option , user needs to pick one
  // 2 - basic code input (email or text)
  // 3 - azure
  // 4 - OTP app init
  // 5 - OTP app code input

  let step = 2;
  if (mfaOptions.size > 1) {
    step = 1;
  } else if (mfaOptions.includes('azure')) {
    step = 3;
  } else if (mfaOptions.includes('OTPMFA') && OTP_URL) {
    step = 4;
  } else if (mfaOptions.includes('OTPMFA') && !OTP_URL) {
    step = 5;
  }
  const [authStep, setAuthStep] = useState(step);

  const [authOption, setAuthOption] = useState(mfaOptions.size > 1 ? null : mfaOptions.first());
  const [enteredContact, setEnteredContact] = useState('');
  const [hasErrors, setHasErrors] = useState(false);
  const authOptionSelected = (option) => {
    setAuthStep(2);
    setAuthOption(option);
    resendCode(option);
  };
  const authOptionSelectedOTP = (option) => {
    if (OTP_URL) {
      setAuthStep(4);
    } else {
      setAuthStep(5);
    }
    setAuthOption(option);
  };


  useEffect(() => {
    dispatch(authenticationActions.fetchUserByEmail(props.email));
  }, []);

  const handleContactNumber = (number) => {
    setEnteredContact(number);
  };

  props.errors?.map((err) => {
    if (err.get('message').includes('Error sending code') && !hasErrors) {
      setHasErrors(true);
    }
  });

  const descriptionText = () => {
    if (authOption === 'text') {
      return 'An authentication code has been sent to your phone.';
    }
    return 'An authentication code has been sent to your email.';
  };

  const verifyAuthCodeForm = () => {
    return ((authStep === 2 && !hasErrors ?
      <form className='auth-form' onSubmit={formik.handleSubmit}>
        <p style={{ textAlign: 'center' }}>{descriptionText()}</p>
        <div className='codeArea'>
          <VerificationInput autoFocus={true} validChars="0-9" placeholder=" " onChange={(e) => setCode(e)} classNames={{ container: "character" }} inputProps={{ inputMode: "numeric", autoComplete: "one-time-code" }} onComplete={(e) => { props.handleAuthentication(e, false); }} />

        </div>

        <NavLink className='resend-link' to={'/mfa'} exact onClick={resendCode}>Resend Code</NavLink>
        <div className='textSubmitButton'>
          <Button cta type='submit' loading={isAuthenticating}>{isAuthenticating ? 'Submitting...' : 'Submit'}</Button>
        </div>
      </form>
      : authStep !== 1 ? <div className='float-center'>
        <NavLink exact to='/login'>Back</NavLink>
      </div> : null));
  };

  const submitCode = (e) => {
    props.handleAuthentication(e, true);
  };

  const OTP_Continue = () => {
    setAuthStep(5);
    setAuthOption('OTPMFA');
  };

  const getFormToRender = (user, enteredContact, authStep, authOption) => {
    const searchParams = new URLSearchParams(OTP_URL);
    if (!user?.get('contact_number') && !enteredContact && authStep == 2 && authOption === 'text') {
      // user needs to add their phone number
      return <ContactNumberForm handleInputContactNumber={handleContactNumber} email={props.email} />;
    } else if (authOption === 'email' || authOption === 'text') {
      // user needs to verify code that was sent to either email or text
      return verifyAuthCodeForm();
    } else {
      // user is using auth app
      return <div style={{ textAlign: 'center' }}>
        {OTP_URL && authStep === 4 ?
          <TOTPQRCode OTP_URL={OTP_URL} secret={searchParams.get('secret')} continueButton={OTP_Continue} />
          : null
        }
        {authStep === 5 && authOption === 'OTPMFA' ?
          <CodeAuth submitCode={(code) => submitCode(code)} />
          : null}
      </div >;
    }
  };

  return (
    <>
      {/* step one */}
      {authStep === 1 ?
        <form className='auth-form' onSubmit={formik.handleSubmit}>
          <p className='mfa-label-login'>Please select your login method.</p>
          <div className='mfa-option-buttons'>
            {mfaOptions.includes('text') ?
              <div className='mfa-button'><Button backgroundColor='white' border onClick={() => { authOptionSelected('text'); }}>Text Message</Button> </div>

              : null}
            {mfaOptions.includes('email') ?
              <div className='mfa-button'><Button backgroundColor='white' border onClick={() => { authOptionSelected('email'); }}>Email</Button> </div>
              : null}


            {mfaOptions.includes('azure') ?
              <a className='mfa-button-azure' href={`${process.env.REACT_APP_FOX_API_ADDR}/v1/auth/azure`}>Azure</a>
              : null}
            {mfaOptions.includes('OTPMFA') ?
              <div className='mfa-button'><Button backgroundColor='white' border onClick={() => { authOptionSelectedOTP('OTPMFA'); }}>Authentication App</Button> </div>
              : null}

          </div>
        </form>
        : null}
      {getFormToRender(user, enteredContact, authStep, authOption)}

    </>

  );
}

MFAForm.propTypes = {
  handleAuthentication: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  isAuthenticating: PropTypes.bool,
  errors: ImmutablePropTypes.map,
  email: PropTypes.string
};

MFAForm.defaultProps = {
};
