import { List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as recipeActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  recipes: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('recipes', Map())
  );
}

export const recipeReducer = createTypedReducer(initialState, {
  [recipeActions.FETCH_RECIPES] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['recipes', 'loading'], true)
    );
  },

  [recipeActions.FETCH_RECIPES_SUCCESS] (state, action) {

    return state.withMutations((nextState) => {
      let newDataIds = List();
      const fetchDate = new Date();
      action.recipes.get('data').map((recipe) => {
        newDataIds = newDataIds.push(recipe.get('id'));
        nextState.setIn(['recipes', 'data', recipe.get('id')], {
          record: recipe,
          lastFetch: fetchDate,
        });
      });
      nextState.setIn(['recipes', 'records'], newDataIds);
      nextState.setIn(['recipes', 'loading'], false);
    }
    );
  },

  [recipeActions.FETCH_RECIPES_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['recipes', 'loading'], false)
    );
  },

  [recipeActions.FETCH_DEVICE_SETTING_MAP_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['recipes', 'settingsMap'], action.settingDeviceMap);
    }
    );
  },

  [recipeActions.FETCH_RECIPE_BY_ID_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      return nextState.setIn(['recipes', 'data', action.recipe.get('id'), 'record'], action.recipe);
    });
  },

  [recipeActions.ADD_RECIPE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const recipesList = state.getIn(['recipes', 'records'], List()).push(action.recipe.get('id'));
      nextState.setIn(['recipes', 'records'], recipesList);
      nextState.setIn(['recipes', 'data', action.recipe.get('id'), 'record'], action.recipe);
    });
  },

  [recipeActions.DELETE_RECIPE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['recipes', 'records'], nextState.getIn(['recipes', 'records'])?.filter(x => x !== action.recipeId));
      return nextState.deleteIn(['recipes', 'data', action.recipeId]);
    });
  },

  [recipeActions.UPDATE_RECIPE_CONDITION_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const fetchDate = new Date();
      nextState.setIn(['recipes', 'data', action.recipe.get('id'), 'lastFetch'], fetchDate);
      return nextState.setIn(['recipes', 'data', action.recipe.get('id'), 'record'], action.recipe);
    });
  },

  [recipeActions.ADD_RECIPE_CONDITION_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const fetchDate = new Date();
      nextState.setIn(['recipes', 'data', action.recipe.get('id'), 'lastFetch'], fetchDate);
      return nextState.setIn(['recipes', 'data', action.recipe.get('id'), 'record'], action.recipe);
    });
  },
  [recipeActions.UPDATE_RECIPE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const fetchDate = new Date();
      nextState.setIn(['recipes', 'data', action.recipe.get('id'), 'lastFetch'], fetchDate);
      return nextState.setIn(['recipes', 'data', action.recipe.get('id'), 'record'], action.recipe);
    });
  },



  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default recipeReducer;
