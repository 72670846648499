import { Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  controllers: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('controllers', Map())
  );
}

export const controllerReducer = createTypedReducer(initialState, {

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default controllerReducer;
