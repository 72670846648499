import React from 'react';
import PropTypes from 'prop-types';
import './TestingReport.scss';
import { Map } from 'immutable';
import useMediaQuery from '@mui/material/useMediaQuery';

import ImmutablePropTypes from 'react-immutable-proptypes';

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TextField, Divider } from '@mui/material';
import Button from 'src/component/UI/Button';
import variables from 'src/style/variable/variables.module.scss';

export default function AtJunction (props) {
  const {
    onChange,
    data,
    tableInfo,
    readOnly
  } = props;
  const isMobile = useMediaQuery(`(max-width: ${variables.phoneWidth})`);

  const rowObject = {
  };
  const colOrder = [];
  tableInfo.map(x => x.columns.map(col => {
    const title = x.key === 'main' ? col : `${x.key}-${col}`;
    rowObject[title] = '';
    colOrder.push(title);
    return;
  }));
  const onComment = (index, test, val) => {
    onChange(['extras', 'atJunction'], data.setIn([index, test], val));
  };

  const row = (rowData, outputIndex) => {
    const data = colOrder.map(x => {
      return rowData.get(x);
    });
    return (
      <TableRow key={outputIndex}>
        {data?.map((colName, index) => {
          const cellData = rowData.get(colOrder[index]);
          return (<TableCell key={`${outputIndex}-${index}`} className='body-cell' >

            <TextField
              fullWidth
              disabled={readOnly}
              value={cellData}
              onChange={(e) => { onComment(outputIndex, colOrder[index], e.target.value); }}
            />
          </TableCell>);
        })}
      </TableRow>
    );
  };

  const body = () => {
    const rows = data.reduce((acc, rowData, index) => {
      acc.push(row(rowData, index));
      return acc;
    }, []);

    return rows;
  };

  const table = (rowData, outputIndex) => {
    const tableInfoTransform = tableInfo.reduce((acc, item) => {
      const columnsWithTitle = item?.columns?.map(column => ({
        title: item?.title ?? '',
        column: column
      })) ?? [];
      return acc.concat(columnsWithTitle);
    }, []);

    return (
      <React.Fragment>
        <TableContainer className='mobile-junction-table' key={`container-${outputIndex}`}>
          <TableBody key={`table-body-${outputIndex}`}>
            {tableInfoTransform.map((element, idx) => (
              <TableRow key={`${element.column}-${element.column}-row`}>
                <TableCell
                  align="center"
                  key={element.title} rotated-text
                  className={element.title === "" ? 'no-border center-items remove-padding-for-junction-table2 ' : 'remove-padding-for-junction-table2  center-items small-border sub-headers min-sub-headers'}
                >
                  <div className='rotated-text-junction2'>{element.title}</div>
                </TableCell>
                <TableCell className='main-header-cell rotated-text remove-padding-for-junction-table ' key={`${element.key}-${element.column}`}>
                  <div >{element.column}</div>
                </TableCell>
                <TableCell key={`${element.column}-mobile-body-cell`} className='body-cell'>
                  <TextField
                    fullWidth
                    disabled={readOnly}
                    value={rowData.get(colOrder[idx])}
                    onChange={(e) => { onComment(outputIndex, colOrder[idx], e.target.value); }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
        <Divider style={{ margin: '10,10,10,10', marginTop: '20px', marginBottom: '20px' }} />
      </React.Fragment>
    );
  };

  const mobileTables = () => {
    const tables = data.reduce((acc, rowData, index) => {
      acc.push(table(rowData, index));
      return acc;
    }, []);
    return tables;
  };

  const addRow = () => {
    data.push(rowObject);
    onChange(['extras', 'atJunction'], data.push(Map(rowObject)));
  };

  return (
    <div>
      <h3>At Power/Junction Box:</h3>
      <p><i>Record measured values and compare them to the EHT isometric</i></p>

      {isMobile ? mobileTables() : (
        <TableContainer >

          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableInfo.map(element => {
                  return (<TableCell align="center" key={element.title} colSpan={element.columns.length} className={element.title === "" ? 'no-border' : 'small-border sub-headers'}>{element.title}</TableCell>);
                })}
              </TableRow>
              <TableRow >
                {tableInfo.map(element => {
                  return element.columns.map(test => {
                    return (<TableCell className='main-headers' key={`${element.key}-${test}`} >{test}</TableCell>);
                  });
                })}

              </TableRow>

            </TableHead>
            <TableBody>
              {body()}
            </TableBody>
          </Table>
        </TableContainer >
      )
      }

      <div className='button-bar at-Junction'>
        <Button onClick={addRow} disabled={readOnly} border background backgroundColor={'white'} className='add-row'>Add Row</Button>
      </div>
    </div>
  );
}

AtJunction.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: ImmutablePropTypes.list.isRequired,
  tableInfo: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
};
