import * as yup from 'yup';
import { default as isUUID } from './isUUID';
import { List, Map } from 'immutable';

class ImmutableMapSchema extends yup.mixed {
  constructor () {
    super({ type: 'Immutable.Map' });
  }

  _typeCheck (value) {
    return Map.isMap(value);
  }
}

class ImmutableListSchema extends yup.mixed {
  constructor () {
    super({ type: 'Immutable.List' });
  }

  _typeCheck (value) {
    return List.isList(value);
  }
}

yup.addMethod(yup.string, 'uuid', function parseUUIDFormat (this: any) { // eslint-disable-line no-unused-vars
  return this.transform(function (value, originalValue) {
    return isUUID.anyNonNil(originalValue) ? value : null;
  });
});

const foxYup: yup.mixed & { immutable: any; } = Object.assign({}, yup, { immutable: () => {
  return {
    map: function () {
      return new ImmutableMapSchema();
    },
    list: function () {
      return new ImmutableListSchema();
    }
  };
} });

export default foxYup;
