import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './TestingReport.scss';

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TextField, Paper } from '@mui/material';
import AtJunction from './AtJunction';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';


export default function AtControlPanel (props) {
  const {
    onChange,
    tableInfo,
    data,
    atJunctionTable,
    readOnly,
    title,
    subTitle,
    id
  } = props;

  const [output, setOutput] = useState(Map(data?.get(id)));
  useEffect(() => {
    setOutput(Map(data?.get(id)));
  }, [data]);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const onComment = (section, test, val) => {
    setOutput(prevOutput => {
      const updatedMap = prevOutput.setIn([section, test], val);
      onChange(['extras', id], updatedMap);
      return updatedMap;
    });
  };
  return (
    <div className="atDevice-alarm-check-sheet">
      <Paper sx={{ marginBottom: '30px' }}>
        <h3>{title}</h3>
        <p><i>{subTitle}</i></p>

        <TableContainer className={`default-control-panel-table${isMobile ? ' mobile-table' : ''}`}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableInfo.map(element => {
                  return (
                    <TableCell align="center" style={{ minHeight: `${element.columns.length * 62}px` }} key={element.title} colSpan={element.columns.length} className='main-headers remove-padding'>
                      <div className="rotated-text">{element.title}</div>
                    </TableCell>);
                })}
              </TableRow>
              <TableRow>
                {tableInfo.map(element => {
                  return element.columns.map((test, index) => {
                    return (<TableCell key={`${element.title}-${test}-${index}`} className='sub-headers fixed-height'>{test}</TableCell>);
                  });
                })}
              </TableRow>

            </TableHead>
            <TableBody>
              <TableRow>
                {tableInfo.map(section => {
                  return section.columns.map((test, index) => {
                    return (<TableCell key={`body-${section.title}-${test}-${index}`} className='body-cell' >
                      <TextField fullWidth value={output.getIn([section.title, test])} disabled={readOnly} onChange={(e) => { onComment(section.title, test, e.target.value); }} />
                    </TableCell>);
                  });

                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer >
      </Paper>
      {atJunctionTable &&
       <Paper>
         <AtJunction data={data?.get('atJunction')} readOnly={readOnly} tableInfo={atJunctionTable} onChange={onChange} />
       </Paper>}

    </div>
  );
}

AtControlPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: ImmutablePropTypes.map.isRequired,
  tableInfo: PropTypes.array.isRequired,
  atJunctionTable: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  id: PropTypes.string


};
AtControlPanel.defaultProps = {
  title: 'At Control Panel',
  subTitle: 'Record measured values and compare them to the EHT isometric',
  id: 'atControlPanel'
};
