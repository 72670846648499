import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import DeviceLink from './DeviceLink';
import { useSelector } from 'react-redux';
import { getDeviceById } from 'src/module/device/selector';

export default function DeviceLinkCombo ({ device, deviceId }) {

  if (deviceId) {
    device = useSelector(getDeviceById(deviceId));
  }
  const tag = device.get('device_tag') ? device.get('device_tag') : device.get('tag');
  const type = device.get('device_type') ? device.get('device_type') : device.get('type');
  // for circuits
  if (device.get('parent_type') === 'controller') {
    const controllerTag = device.get('parent_type') === 'commLoop' ?  null : device.get('parent_tag');
    return <>
      <DeviceLink label={device.get('parent_tag')} controllerTag={controllerTag} /> /
      {device ? (<DeviceLink label={tag} circuitTag={tag} controllerTag={controllerTag} rawTag={device.get('raw_tag')} />) : 'N/A'}
    </>;
  }

  // for controllers
  if (device.get('parent_type') === 'commLoop' && type === 'controller') {
    return <>
      {device ? (<DeviceLink label={tag}  controllerTag={tag} rawTag={device.get('raw_tag')} />) : 'N/A'}
    </>;
  }
  // for commLoops
  if (type === 'commLoop' ) {
    return <>
      {device ? (<DeviceLink label={tag} controllerTag={tag} rawTag={device.get('raw_tag')} />) : 'N/A'}
    </>;
  }

  // 920
  return <>
    {device ? (<DeviceLink label={tag} circuitTag={tag} rawTag={device.get('raw_tag')} />) : 'N/A'}
  </>;
}

DeviceLinkCombo.propTypes = {
  device: ImmutablePropTypes.map,
  deviceId: PropTypes.string

};

DeviceLinkCombo.defaultProps = {
};
