import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { setInProgress, SET_IN_PROGRESS }: any = createTypedAction(
  `${PREFIX}SET_IN_PROGRESS`,
  ['isInProgress', yup.boolean().required()],
  ['progress', yup.number()],
  ['label', yup.string()],
  ['timeout', yup.number()],
);

export const { updateProgress, UPDATE_PROGRESS }: any = createTypedAction(
  `${PREFIX}UPDATE_PROGRESS`,
  ['progress', yup.number().required()],
  ['label', yup.string()]
);

export const { setProgressFailed, SET_PROGRESS_FAILED }: any = createTypedAction(
  `${PREFIX}SET_PROGRESS_FAILED`,
  ['progress', yup.number()],
  ['label', yup.string()],
  ['route', yup.string()],
  ['confirmString', yup.string().required()]
);

export const { setProgressComplete, SET_PROGRESS_COMPLETE }: any = createTypedAction(
  `${PREFIX}SET_PROGRESS_COMPLETE`,
  ['progress', yup.number()],
  ['label', yup.string()],
  ['route', yup.string()],
  ['confirmString', yup.string().required()],
);
