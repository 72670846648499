import React from 'react';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import LookupTable from 'src/component/LookupTable';
import { isWhitelabel } from 'src/utils/utils';

export default function AlarmSimplificationScreen () {
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div id='critical-alarm-settings' className='page'>
      <Helmet>
        <title>{title} - Alarm Simplification Settings</title>
      </Helmet>

      <Grid container spacing={3}>

        <Grid item xs={12} md={12}>
          <Paper>
            <LookupTable type='alarm_simplification' />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
