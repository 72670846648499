import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchCheckSheetTasks, FETCH_CHECK_SHEET_TASKS }: any = createTypedAction(
  `${PREFIX}FETCH_CHECK_SHEET_TASKS`,
  ['checkSheetType', yup.string()],
);
export const { fetchCheckSheetTasksSuccess, FETCH_CHECK_SHEET_TASKS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CHECK_SHEET_TASKS_SUCCESS`,
  ['tasks', yup.immutable().map()],
  ['checkSheetType', yup.string()],
);
export const { fetchCheckSheetTasksFailed, FETCH_CHECK_SHEET_TASKS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CHECK_SHEET_TASKS_FAILED`,
  ['checkSheetType', yup.string()],
);


export const { saveCheckSheet, SAVE_CHECK_SHEET }: any = createTypedAction(
  `${PREFIX}SAVE_CHECK_SHEET`,
  ['checkSheetType', yup.string()],
  ['data', yup.immutable().map()],
  ['deviceId', yup.immutable().map()],

);
export const { saveCheckSheetSuccess, SAVE_CHECK_SHEET_SUCCESS }: any = createTypedAction(
  `${PREFIX}SAVE_CHECK_SHEET_SUCCESS`,
  ['deviceId', yup.immutable().map()],
  ['checkSheetType', yup.string()],
  ['data', yup.immutable().map()],
);
export const { saveCheckSheetFailed, SAVE_CHECK_SHEET_FAILED }: any = createTypedAction(
  `${PREFIX}SAVE_CHECK_SHEET_FAILED`,
  ['deviceId', yup.immutable().map()],
  ['checkSheetType', yup.string()],
);

export const { updateCheckSheet, UPDATE_CHECK_SHEET }: any = createTypedAction(
  `${PREFIX}UPDATE_CHECK_SHEET`,
  ['data', yup.immutable().map()],
  ['deviceId', yup.immutable().map()],
  ['checkSheetType', yup.string()],


);
export const { updateCheckSheetSuccess, UPDATE_CHECK_SHEET_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_CHECK_SHEET_SUCCESS`,
  ['deviceId', yup.immutable().map()],
  ['data', yup.immutable().map()],
  ['checkSheetType', yup.string()],

);
export const { updateCheckSheetFailed, UPDATE_CHECK_SHEET_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_CHECK_SHEET_FAILED`,
  ['deviceId', yup.immutable().map()],
  ['checkSheetType', yup.string()],
);

export const { fetchCheckSheets, FETCH_CHECK_SHEETS }: any = createTypedAction(
  `${PREFIX}FETCH_CHECK_SHEETS`,
  ['checkSheetType', yup.string()],

);
export const { fetchCheckSheetsSuccess, FETCH_CHECK_SHEETS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CHECK_SHEETS_SUCCESS`,
  ['checkSheetType', yup.string()],
  ['data', yup.immutable().map()],
);
export const { fetchDeviceCheckSheetsFailed, FETCH_DEVICE_CHECK_SHEETS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CHECK_SHEETS_FAILED`,
);

export const { fetchCheckSheet, FETCH_CHECK_SHEET }: any = createTypedAction(
  `${PREFIX}FETCH_CHECK_SHEET`,
  ['checkSheetId', yup.string()],
  ['checkSheetType', yup.string()],
);
export const { fetchCheckSheetSuccess, FETCH_CHECK_SHEET_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CHECK_SHEET_SUCCESS`,
  ['checkSheetType', yup.string()],
  ['data', yup.immutable().map()],
  ['checkSheetId', yup.immutable().map()],
);

export const { updateCheckSheetTypeSuccess, UPDATE_CHECK_SHEET_TYPE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_CHECK_SHEET_TYPE_SUCCESS`,
  ['checkSheetType', yup.string()],
  ['checkSheetId', yup.immutable().map()],
);
export const { fetchCheckSheetFailed, FETCH_CHECK_SHEET_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CHECK_SHEET_FAILED`,
);

export const { openCheckSheet, OPEN_CHECK_SHEET }: any = createTypedAction(
  `${PREFIX}OPEN_CHECK_SHEET`,
  ['checkSheetType', yup.string()],
);

export const { closeCheckSheet, CLOSE_CHECK_SHEET }: any = createTypedAction(
  `${PREFIX}CLOSE_CHECK_SHEET`,
  ['checkSheetType', yup.string()],
);
