import './Banner.scss';
import React from 'react';
import PropTypes from 'prop-types';

export default function Banner (props) {
  const { type, message, button } = props;

  return (
    <div className={`banner banner-${type}`} data-testid='banner'>
      <div className='banner-text'>
        {message}
        <>{button}</>
      </div>
    </div>
  );
}
Banner.propTypes = {
  message: PropTypes.string.isRequired,
  button: PropTypes.element,
  type: PropTypes.oneOf(['info', 'warning', 'error'])
};
Banner.defaultProps = {
  type: 'info',
  button: null
};
