import React, { useState } from "react";
import PropTypes from 'prop-types';
import RadialGauge from "./RadialGauge";
import SettingsModal from "src/component/UI/SettingsModal/SettingsModal";
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import { getSettingsDiffMap, getSettingsModal } from "src/module/device/selector";
import { useDispatch, useSelector } from "react-redux";
import { setSettingsModalClose, setSettingsModalOpen } from "src/module/device/action";
import { fromJS, Map } from "immutable";
import PermissionFence from "src/component/PermissionFence";
import LinearGauge from "./LinearGauge";
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';
import { langLookUpText } from "src/utils/langLookUp";
import { getTemperatureUnit } from 'src/module/authentication/selector';
import { celsiusToFahrenheitConvertor } from "src/utils/utils";
import FeatureFence from "src/component/FeatureFence";

export default function GaugeContent (props) {
  const {
    deviceId,
    title,
    rangeMin,
    setting,
    rangeMax,
    rangeTrip,
    handleSettingChange,
    rangeMinEnableLabel,
    rangeMaxEnableLabel,
    rangeMinLabel,
    rangeMaxLabel,
    rangeTripLabel,
    settingLabel,
    currentVal,
    type,
    unit,
    theme,
    gaugeStyle,
    canDisableAllAlarms,
    enableLowAlarm,
    enableHighAlarm,
    intialHighSetpointEnabled,
    intialLowSetpointEnabled,
    rangeMinDisabledLabel,
    rangeMaxDisabledLabel,
    toolTip,
    settingPrefix,
    disableHamburger,
    readOnly,
    settingsToolTips
  } = props;
  const dispatch = useDispatch();
  const [localSettingsModal, setLocalSettingsModal] = useState(null);
  const settingsDiff = useSelector(getSettingsDiffMap(deviceId));
  const temperatureUnit = useSelector(getTemperatureUnit);
  const settingsConfig = {
    rangeMinEnableLabel,
    rangeMaxEnableLabel,
    rangeMinLabel,
    rangeMaxLabel,
    rangeTripLabel,
    settingLabel,
    handleSettingChange,
    type,
    canDisableAllAlarms,
    intialHighSetpointEnabled,
    intialLowSetpointEnabled,
    rangeMinDisabledLabel,
    rangeMaxDisabledLabel,
  };

  const numDiffs = settingsDiff?.filter((x: any) => {
    return (langLookUpText(x.get('setting')) === settingPrefix + settingsConfig.rangeMaxLabel ||
      (Object.prototype.hasOwnProperty.call(settingsConfig, 'settingLabel') ? langLookUpText( x.get('setting')) === settingPrefix + settingsConfig.settingLabel : false) ||
      langLookUpText(x.get('setting')) === settingPrefix + settingsConfig.rangeMinLabel);
  }
  )?.size;

  const openSetting = (id) => {
    dispatch(setSettingsModalOpen(deviceId, id));
  };

  const closeSetting = () => {
    setLocalSettingsModal(null);
    dispatch(setSettingsModalClose(deviceId));
  };
  function onSetPointClick (label, value, type) {
    if (disableHamburger) {
      return;
    }
    setLocalSettingsModal({
      title: label,
      settings: Map({ value: value }),
      type: type
    });
    openSetting(SettingsModalTypes[`${settingsConfig.type}_SINGLE`]);
  }
  const settingsModal = useSelector(getSettingsModal(deviceId));
  const settingsModalIsLoading = settingsModal?.get('loading');
  const settingsModalIsOpen = settingsModal?.get('isOpen');
  const settingsModalId = settingsModal?.get('id');


  const singleSettingModalSubmit = (rec: any) => {
    let type = localSettingsModal.type;
    if (type.includes("displayRangeMax")) {
      type = 'rangeMax';
    } else if (type.includes("displayRangeMin")) {
      type = 'rangeMin';
    }
    else if (type.includes("displayRangeTrip")) {
      type = 'rangeTrip';
    }

    const values: any = {};
    if (type.includes('low')) {
      values.rangeMin = Math.round(parseFloat(rec.get('value')) * 10) / 10;
      settingsConfig.handleSettingChange(values);
    } else if (type.includes('high')) {
      values.rangeMax = Math.round(parseFloat(rec.get('value')) * 10) / 10;
      settingsConfig.handleSettingChange(values);
    } else if (type.includes('trip')) {
      values.rangeTrip = Math.round(parseFloat(rec.get('value')) * 10) / 10;
      settingsConfig.handleSettingChange(values);
    } else {
      values[type] = rec.get('value');
      settingsConfig.handleSettingChange(values);
    }
  };

  const multipleSettingsModalSubmit = (rec: any) => {
    const values = {
      rangeMin: rec.get('rangeMin'),
      rangeMax: rec.get('rangeMax'),
      rangeMinSwitch: rec.get('rangeMinSwitch'),
      rangeMaxSwitch: rec.get('rangeMaxSwitch'),
      setting: rec.get('setting'),
      canDisableAllAlarms: settingsConfig.canDisableAllAlarms
    };
    if (values.setting !== null) {
      values.setting = Math.round(parseFloat(values.setting) * 10) / 10;
    }

    if (values.rangeMin !== null) {
      values.rangeMin = Math.round(parseFloat(values.rangeMin) * 10) / 10;
    }

    if (values.rangeMax !== null) {
      values.rangeMax = Math.round(parseFloat(values.rangeMax) * 10) / 10;
    }
    settingsConfig.handleSettingChange(values);
  };
  const makeSettingModalForSetPointChanges = (readonly, isLocked) => {
    const diff: any = settingsDiff?.get(settingPrefix + localSettingsModal?.title, Map());
    const designedValue = diff?.get('designed');
    if (!(settingsModalIsOpen && settingsModalId === SettingsModalTypes[`${settingsConfig.type}_SINGLE`])) {
      return <></>;
    }
    return (
      <SettingsModal
        open={settingsModalIsOpen && settingsModalId === SettingsModalTypes[`${settingsConfig.type}_SINGLE`]}
        handleClose={closeSetting}
        handleSubmit={singleSettingModalSubmit}
        title={localSettingsModal?.title}
        settings={localSettingsModal?.settings}
        readonly={readonly}
        locked={isLocked}
        loading={settingsModalIsLoading}
        id={deviceId}
        sameCheck
        fields={
          fromJS({
            value: {
              type: 'number',
              label: '',
              errorText: 'Required',
              designedValue: temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(designedValue) : designedValue
            },
          })
        }
      />
    );
  };

  const makeSettingsModalForMultipleSetPointChanges = (readonly, isLocked) => {
    const settingsFields: any = {};
    const settingsValues: any = {};
    // set point
    if (setting !== undefined && setting !== null) {
      const diff: any = settingsDiff?.get(settingPrefix + settingsConfig.settingLabel, Map());
      const designedValue = diff?.get('designed');
      settingsFields.setting = {
        type: 'number',
        label: settingsConfig.settingLabel,
        order: 70,
        errorText: 'Required',
        designedValue: temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(designedValue) : designedValue,
        toolTip: settingsToolTips
      };
      settingsValues.setting = setting;
    }
    // min
    if (rangeMin !== undefined) {
      // setting if we are able to toggle switch or not
      const rangeMinSwitchEnabled = settingsConfig.canDisableAllAlarms ? settingsConfig.canDisableAllAlarms : enableLowAlarm;
      const diff: any = settingsDiff?.get(settingPrefix + settingsConfig.rangeMinLabel, Map());
      const designedValue = diff?.get('designed');
      settingsFields.rangeMinSwitch = {
        type: 'switch',
        label: settingsConfig.rangeMinEnableLabel,
        order: 50,
        disabled: !rangeMinSwitchEnabled,
        toolTip: rangeMinSwitchEnabled || isLocked ? null : settingsConfig.rangeMinDisabledLabel
      };
      // if switch is unable to be toggled then default switch to on/true
      settingsValues.rangeMinSwitch = rangeMinSwitchEnabled ? settingsConfig.intialLowSetpointEnabled : true;
      settingsFields.rangeMin = {
        type: 'number',
        label: settingsConfig.rangeMinLabel,
        order: 60,
        enabled: 'rangeMinSwitch',
        required: 'rangeMinSwitch',
        designedValue: temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(designedValue) : designedValue
      };
      settingsValues.rangeMin = rangeMin;
    }

    //max
    if (rangeMax !== undefined) {
      // setting if we are able to toggle switch or not
      const rangeMaxSwitchEnabled = settingsConfig.canDisableAllAlarms ? settingsConfig.canDisableAllAlarms : enableHighAlarm;
      const diff: any = settingsDiff?.get(settingPrefix + settingsConfig.rangeMaxLabel, Map());
      const designedValue = diff?.get('designed');
      settingsFields.rangeMaxSwitch = {
        type: 'switch',
        label: settingsConfig.rangeMaxEnableLabel,
        order: 20,
        disabled: !rangeMaxSwitchEnabled,
        toolTip: rangeMaxSwitchEnabled || isLocked ? null : settingsConfig.rangeMaxDisabledLabel
      };
      // if switch is unable to be toggled then default switch to on/true
      settingsValues.rangeMaxSwitch = rangeMaxSwitchEnabled ? settingsConfig.intialHighSetpointEnabled : true;
      settingsFields.rangeMax = {
        type: 'number',
        label: settingsConfig.rangeMaxLabel,
        order: 30,
        enabled: 'rangeMaxSwitch',
        required: 'rangeMaxSwitch',
        designedValue: temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(designedValue) : designedValue
      };
      settingsValues.rangeMax = rangeMax;
    }
    //trip
    if (rangeTrip !== undefined) {
      const diff: any = settingsDiff?.get(settingPrefix + settingsConfig.rangeMaxLabel, Map());
      const designedValue = diff?.get('designed');

      // if switch is unable to be toggled then default switch to on/true
      // settingsValues.rangeMaxSwitch = rangeMaxSwitchEnabled ? settingsConfig.intialHighSetpointEnabled : true;
      settingsFields.rangeTrip = {
        type: 'number',
        label: settingsConfig.rangeTripLabel,
        order: 10,
        designedValue: temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(designedValue) : designedValue
      };
      settingsValues.rangeTrip = rangeTrip;
    }
    if (!(settingsModalIsOpen && settingsModalId === SettingsModalTypes[`${settingsConfig.type}_MULTIPLE`])) {
      return <></>;
    }

    return (
      <SettingsModal
        open={settingsModalIsOpen && settingsModalId === SettingsModalTypes[`${settingsConfig.type}_MULTIPLE`]}
        handleClose={closeSetting}
        locked={isLocked}
        handleSubmit={multipleSettingsModalSubmit}
        title={title}
        settings={fromJS(settingsValues)}
        readonly={readonly}
        fields={fromJS(settingsFields)}
        loading={settingsModalIsLoading}
        id={deviceId}
        sameCheck
      />
    );
  };
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);

  let width = '210px';
  if (gaugeStyle === 'radial' && !isMobile) {
    width = '300px';
  }



  return (
    <div style={{ width: width }}>
      {gaugeStyle === 'radial' ?
        <RadialGauge
          title={title}
          value={currentVal}
          units={unit}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
          rangeTrip={rangeTrip}
          setPointValue={setting}
          theme={theme}
          settingsConfig={{
            rangeMinLabel,
            rangeMaxLabel,
            rangeTripLabel,
            settingLabel,
            type,
            canDisableAllAlarms,
            intialHighSetpointEnabled,
            intialLowSetpointEnabled,
            rangeMinDisabledLabel,
            rangeMaxDisabledLabel,
            toolTip,
          }}
          openSetting={openSetting}
          onSetPointClick={onSetPointClick}
          numberOfSettingDiffs={numDiffs}
          disableHamburger={disableHamburger}
        />
        :
        <LinearGauge
          title={title}
          value={currentVal}
          units={unit}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
          rangeTrip={rangeTrip}
          setPointValue={setting}
          theme={theme}
          settingsConfig={{
            rangeMinLabel,
            rangeMaxLabel,
            rangeTripLabel,
            settingLabel,
            type,
            canDisableAllAlarms,
            intialHighSetpointEnabled,
            intialLowSetpointEnabled,
            rangeMinDisabledLabel,
            rangeMaxDisabledLabel,
            toolTip
          }}
          openSetting={openSetting}
          onSetPointClick={onSetPointClick}
          numberOfSettingDiffs={numDiffs}
          disableHamburger={disableHamburger}
        />

      }
      {/*
      	- Feature & permission = show normal --
	      - Feature & !permission =  read only --
	      - !feature & permission = show lock --
        - !Feature & !permision = read only
      */}


      <PermissionFence can='program-device'
        //  Feature & !permission =  read only && !Feature & !permision
        noPermissionComponent={makeSettingModalForSetPointChanges(true, false)}>
        <FeatureFence can={['program-device']}
          // !feature & permission = show lock
          noPermissionComponent={makeSettingModalForSetPointChanges(true, true)}
        >
          {/* Feature & permission = show normal */}
          {makeSettingModalForSetPointChanges(false || readOnly, false)}
        </FeatureFence>
      </PermissionFence>

      {((typeof rangeMin !== 'undefined' || typeof rangeMax !== 'undefined' || typeof setting !== 'undefined') &&
        (settingsModalIsOpen && settingsModalId === SettingsModalTypes[`${settingsConfig.type}_MULTIPLE`])) && (

      /////////////////////////
        <PermissionFence can='program-device'
          //  Feature & !permission =  read only && !Feature & !permision
          noPermissionComponent={makeSettingsModalForMultipleSetPointChanges(true, false)}>
          <FeatureFence can={['program-device']}
          // !feature & permission = show lock
            noPermissionComponent={makeSettingsModalForMultipleSetPointChanges(true, true)}
          >
            {/* Feature & permission = show normal */}
            {makeSettingsModalForMultipleSetPointChanges(false || readOnly, false)}
          </FeatureFence>
        </PermissionFence>
      )}
    </div>
  );
}

GaugeContent.propTypes = {
  deviceId: PropTypes.string,
  title: PropTypes.string.isRequired,
  unit: PropTypes.string,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  rangeTrip: PropTypes.number,
  currentVal: PropTypes.number,
  setting: PropTypes.number,
  rangeMinEnableLabel: PropTypes.string,
  rangeMaxEnableLabel: PropTypes.string,
  rangeMinLabel: PropTypes.string,
  rangeMaxLabel: PropTypes.string,
  rangeTripLabel: PropTypes.string,
  settingLabel: PropTypes.string,
  handleSettingChange: PropTypes.func,
  type: PropTypes.string,
  theme: PropTypes.string,
  gaugeStyle: PropTypes.string,
  canDisableAllAlarms: PropTypes.bool,
  enableLowAlarm: PropTypes.bool,
  enableHighAlarm: PropTypes.bool,
  intialHighSetpointEnabled: PropTypes.bool,
  intialLowSetpointEnabled: PropTypes.bool,
  rangeMinDisabledLabel: PropTypes.string,
  rangeMaxDisabledLabel: PropTypes.string,
  toolTip: PropTypes.string,
  settingPrefix: PropTypes.string,
  disableHamburger: PropTypes.bool,
  readOnly: PropTypes.bool,
  settingsToolTips: PropTypes.string
};

GaugeContent.defaultProps = {
  deviceId: null,
  title: null,
  unit: 'Unit',
  currentVal: 0,
  handleSettingChange: null,
  type: null,
  theme: 'default',
  gaugeStyle: PropTypes.string,
  canDisableAllAlarms: false,
  enableLowAlarm: false,
  enableHighAlarm: false,
  intialHighSetpointEnabled: false,
  intialLowSetpointEnabled: false,
  rangeMinDisabledLabel: "",
  rangeMaxDisabledLabel: "",
  settingPrefix: "",
  disableHamburger: false,
  readOnly: false,
  settingsToolTips: null

};
