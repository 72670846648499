import './Toast.scss';
import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import CloseCircleIcon from '@mui/icons-material/HighlightOffOutlined';
import MessageIcon from '@mui/icons-material/SmsOutlined';

export default function ToastComponent (props) {
  const renderToastIcon = (toastType) => {
    switch (toastType) {
      case 'success':
        return (<CheckCircleIcon className='toast-icon' />);
      case 'warn':
        return (<WarningIcon className='toast-icon' />);
      case 'info':
        return (<MessageIcon className='toast-icon' />);
      case 'error':
        return (<CloseCircleIcon className='toast-icon' />);
      default:
        return null;
    }
  };

  const {
    type,
    message
  } = props;

  return (
    <div data-testid='toast-content' className='toast-content'>{renderToastIcon(type)} <p className='toast-message'>{message}</p></div>
  );
}

ToastComponent.propTypes = {
  type: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired
};

ToastComponent.defaultProps = {
  type: null
};
