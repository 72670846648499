import './NotesList.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Map } from 'immutable';
import moment from 'moment';
import _ from 'lodash';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import NotesIcon from '@mui/icons-material/BorderColorOutlined';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import variables from 'src/style/variable/variables.module.scss';

import EditableCell from 'src/component/UI/EditableCell';
import Button from 'src/component/UI/Button';
import DataTable from 'src/component/UI/DataTable';
import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import { getSingularWorkTicketLabel, getTablePagePreferences } from 'src/module/authentication/selector';
import { updateNote, addNote } from 'src/module/note/action';
import NoteModal  from 'src/component/UI/NoteModal';
import PermissionFence from '../PermissionFence';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import DeviceLinkCombo from 'src/component/UI/DeviceLinkCombo';
import { formatDate } from 'src/utils/utils';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          container: {
            display: "flex",
            flexWrap: "wrap"
          },
        },
      },
    },
  },
});

const noteTypeToFriendlyPlural = {
  note: 'Notes',
  loto: 'LOTO',
  nuisance: 'Nuisances'

};

const noteTypeToFriendly= {
  note: 'Note',
  loto: 'LOTO',
  nuisance: 'Nuisance'

};

export default function NotesList (props) {
  const dispatch = useDispatch();
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [page, setPage] = useState(0);

  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const [showArchived, setShowArchived] = React.useState(false);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);

  const {
    deviceId,
    hideDeviceTag,
    showNoteType,
    showExpiry,
    showDeleteIcon,
    showAlarm,
    showCreatedBy,
    showCreatedAt,
    showSubject,
    showAddNoteButton,
    showSearchBar,
    showArchivedNotes,
    showArchivedOption,
    suppressHeader,
    selectors,
    fetch,
    supressSelectedFilters,
    tableId
  } = props;
  const userPagePreference = useSelector(getTablePagePreferences(tableId));

  const [pageSize, setPageSize] = useState(userPagePreference);
  const notes = useSelector(selectors.getNotes);
  const loading: any = useSelector(selectors.getLoadingNotes);
  const numPages: any = useSelector(selectors.getNumNotesPages);
  const totalNotes: any = useSelector(selectors.getTotalNotes);

  const selectedFilters = useSelector(getDevicesSelectedFilters);

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const columns: any = [];

  let columnWidths = ['15%', '15%', '10%', '10%', '10%', '15%', '5%'];

  if (deviceId && !showArchivedNotes) {
    columnWidths = ['15%', '15%', '15%', '10%', '10%', '10%', '5%'];
  }
  if (deviceId && showArchivedNotes) {
    columnWidths = ['15%', '10%', '15%', '10%', '10%', '10%', '10%'];
  }

  if (!hideDeviceTag) {
    columns.push({
      id: 'device_tag',
      Header: 'Device Tag',
      accessor: (row: any) => row.get('device_tag'),
      maxWidth: '200px',
      Cell: (row: any) => { return (
        <DeviceLinkCombo device={row.cell.row.original}/>
      ); },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
    columnWidths = ['20%', '25%', '10%', '10%', '15%', '5%'];
  }
  if (showNoteType) {
    columns.push({
      id: 'note',
      Header: 'Note Type',
      accessor: (row: any) => noteTypeToFriendly[row.get('note')],
    });
  }
  if (showAlarm) {
    columns.push({
      id: 'alarm',
      Header: 'Alarm',
      accessor: (row: any) => row.get('alarm'),
    });
  }
  if (showSubject) {
    columns.push({
      id: 'subject',
      Header: 'Subject',
      accessor: (row: any) => row.get('subject'),
      Cell: (row: any) => {return row.cell.row.original?.get('archived_at') === null ? (
        <ThemeProvider theme={theme}>
          <EditableCell className='note-subject-cell' permission='edit-eht-note' onChange={handleUpdateRow('subject', row.cell.row.original.get('id'))} value={row.cell.value} multiLine={true} emptyCellLabel='Add note'/>
        </ThemeProvider>
      ) : row.cell.value;}
    });
  }
  if (showExpiry) {
    columns.push({
      id: 'expired_at',
      Header: 'Expiry',
      accessor: (row: any) => row.get('expired_at'),
      Cell: (row: any) => { return row.cell.row.original?.get('archived_at') === null ? (<EditableCell className='expiry-cell' permission='edit-eht-note' onChange={handleUpdateRow('expired_at', row.cell.row.original.get('id'))} type={"date"} value={row.cell.value ? moment(row.cell.value).format('YYYY-MM-DD') : null} />)
        : row.cell.value ? moment(row.cell.value).format('YYYY-MM-DD') : null;}
    });
  }
  if (showCreatedBy) {
    columns.push({
      id: 'users.first_name',
      Header: 'Created By',
      accessor: (row: any) => row.get('first_name') ? `${row.get('first_name')} ${row.get('last_name')}` : '',
    });
  }
  if (showCreatedAt) {
    columns.push({
      id: 'created_at',
      Header: 'Created At',
      accessor: (row: any) => row.get('created_at'),
      Cell: (row: any) => (row.cell.value ? (<>{formatDate(row.cell.value)}</>) : ''),
    });
  }

  if (showDeleteIcon) {
    columns.push({
      Header: ' ',
      accessor: (row: any) => row.get('id'),
      Cell: (row: any) => row.cell.row.original?.get('archived_at') != null ? "Archived" : (row.invalidated ? null : (
        <PermissionFence can='edit-eht-note'>
          <Button onClick={handleArchive(row.cell.value)} icon={<ArchiveIcon style={{ fontSize: 14 }} />} />
        </PermissionFence>
      ))
    });
  }
  if (showArchivedNotes) {
    columns.push({
      id: 'archived_at',
      Header: 'Archived At',
      accessor: (row: any) => formatDate(row.get('archived_at')),
    });
  }

  if (props.noteType == 'nuisance') {
    columns.splice( 3, 0,
      {
        id: 'alarm',
        Header: 'Designation',
        accessor: (row: any) => row.get('alarm'),
        Cell: (row: any) => { return row.cell.row.original?.get('archived_at') === null ? (<EditableCell className='alarm-cell' permission='edit-eht-note' onChange={handleUpdateRow('alarm', row.cell.row.original.get('id'))} value={row.cell.value || ''} emptyCellLabel='Add designation' options={['low load', 'low temp']}/>)
          : row.cell.value || '';}
      });
  }

  if (props.noteType == 'loto') {
    columns.splice( 2, 0,
      {
        id: 'work_ticket',
        Header: singularWorkTicketLabel,
        accessor: (row: any) =>  row.get('work_ticket'),
        Cell: (row: any) => { return row.cell.row.original?.get('archived_at') === null ? (<EditableCell className='alarm-cell' permission='edit-eht-note' onChange={handleUpdateRow('work_ticket', row.cell.row.original.get('id'))} value={row.cell.value || ''} emptyCellLabel='Add Ticket Number' />)
          : row.cell.value || ''; }
      });
  }

  if (props.noteType == 'loto' || props.noteType == 'nuisance') {
    if (isMobile) {
      columnWidths = ['30%', '20%', '10%', '10%', '10%', '10%'];
    }
    else {
      columnWidths = ['20%', '15%', '15%', '10%', '15%', '10%', '5%'];
    }
  }

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setSort(_sort);
    setFilter(_filter);
  };
  const ref_filters = useRef(null);
  const ref_others = useRef(null);

  useEffect(() => {
    const others = `${deviceId} ${showArchived} ${filter} ${page} ${pageSize} ${sort}`;
    if (!_.isEqual(ref_filters.current, selectedFilters.toJS()) || others !== ref_others.current) {
      ref_filters.current = selectedFilters.toJS();
      ref_others.current = others;
      const filters = supressSelectedFilters ? Map({}) : selectedFilters;
      const paginationDetails = { page, pageSize, filter, sort };
      const notesType = props.noteType === 'all' ? null : props.noteType;
      dispatch(fetch(notesType, filters, paginationDetails, showArchived, deviceId));
    }
  }, [dispatch, selectedFilters, deviceId, showArchived, filter, page, pageSize, sort]);

  const handleUpdateRow = (field: any, noteId: string) => {
    return (val) => {
      const rec: any = {};
      rec[field] = val;
      dispatch(updateNote(noteId, Map(rec)));
    };
  };

  const handleArchive = (noteId: string) => {
    return () => {
      dispatch(updateNote(noteId, Map({ 'archived_at': new Date() })));
    };
  };
  const handleNoteSubmit = (rec: any) => {
    dispatch(addNote(rec));
    toggleNoteModal();
  };

  const toggleNoteModal = () => {
    setNoteModalOpen(!noteModalOpen);
  };

  const handleToggleShowArchived = () => {
    setShowArchived(!showArchived);
  };

  return (
    <div data-testid='notes-list' className='notes-list'>
      <PermissionFence can='edit-eht-note'></PermissionFence>
      <NoteModal open={noteModalOpen} handleClose={() => setNoteModalOpen(false)}
        handleSubmit={handleNoteSubmit} noteType={props.noteType} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!suppressHeader && <h2>{noteTypeToFriendlyPlural[props.noteType] + (props.noteType === 'loto' ? ' (Lock-Out Tag-Out)' : '')}</h2>}
          {showRotateMessage ? <DeviceRotate/> :
            (<><DataTable
              pagination
              filterable={showSearchBar}
              filterLabel={`Search ${noteTypeToFriendlyPlural[props.noteType]}`}
              columns={columns}
              data={notes}
              numPages={numPages}
              totalRecords={totalNotes}
              loading={loading}
              fetchPage={fetchPage}
              columnWidths={columnWidths}
              tableId={tableId}
            />
            {showArchivedOption ?
              (<FormControlLabel
                control={
                  <Checkbox
                    checked={showArchived}
                    onChange={handleToggleShowArchived}
                    name="showArchived"
                    color="primary"
                  />
                }
                label="Show Archived"
              />) : null
            }</>) }

        </Grid>
        {
          showAddNoteButton ?
            <PermissionFence can='edit-eht-note'>
              <div className='button-bar'>
                <Button onClick={toggleNoteModal} icon={<NotesIcon style={{ fontSize: 14 }} sx={{ display: 'inline' }}/>} cta >Add {noteTypeToFriendly[props.noteType]}</Button>
              </div>
            </PermissionFence> : ''
        }
      </Grid>
    </div>
  );
}

NotesList.propTypes = {
  deviceId: PropTypes.string,
  hideDeviceTag: PropTypes.bool,
  showNoteType: PropTypes.bool,
  showExpiry: PropTypes.bool,
  showDeleteIcon: PropTypes.bool,
  showAlarm: PropTypes.bool,
  showSubject: PropTypes.bool,
  showCreatedBy: PropTypes.bool,
  showCreatedAt: PropTypes.bool,
  showArchivedNotes: PropTypes.bool,
  showAddNoteButton: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  noteType: PropTypes.oneOf(['note', 'loto', 'nuisance', 'allNotes', 'all']),
  showArchivedOption: PropTypes.bool,
  suppressHeader: PropTypes.bool,
  selectors: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  supressSelectedFilters: PropTypes.bool,
  tableId: PropTypes.string
};
NotesList.defaultProps = {
  deviceId: null,
  noteType: 'allNotes',
  hideDeviceTag: false,
  showNoteType: false,
  showExpiry: false,
  showDeleteIcon: false,
  showAlarm: false,
  showSubject: false,
  showCreatedBy: true,
  showCreatedAt: true,
  showArchivedNotes: false,
  showAddNoteButton: false,
  showSearchBar: false,
  showArchivedOption: false,
  suppressHeader: false,
  supressSelectedFilters: false,
  tableId: 'notesTable'
};
