import './DayForecast.scss';
import React from 'react';
import PropTypes from 'prop-types';

import Sun from '../../assets/icons/weather-icons/sun-48.svg';
import Clouds from '../../assets/icons/weather-icons/clouds-48.png';
import PartlyCloudy from '../../assets/icons/weather-icons/partly-cloudy-48.png';
import Rain from '../../assets/icons/weather-icons/rain-48.png';
import Snow from '../../assets/icons/weather-icons/snow-48.png';
import Storm from '../../assets/icons/weather-icons/storm-48.png';
import Mist from '../../assets/icons/weather-icons/mist-48.png';
import SunRotating from '../../assets/icons/weather-icons/sun-rotating.gif';
import PartlyCloudyRotating from '../../assets/icons/weather-icons/partly-cloudy-rotating.gif';
import CloudsRotating from '../../assets/icons/weather-icons/clouds-rotating.gif';
import RainRotating from '../../assets/icons/weather-icons/rain-rotating.gif';
import SnowRotating from '../../assets/icons/weather-icons/snow-rotating.gif';
import StormRotating from '../../assets/icons/weather-icons/storm-rotating.gif';
import MistRotating from '../../assets/icons/weather-icons/mist-rotating.gif';
import { Paper } from '@mui/material';

export default function DayForecast (props) {
  const {
    maxTemp,
    minTemp,
    day,
    iconCode,
    date
  } = props;

  const imgSrc = {
    'Sun': Sun,
    'Clouds': Clouds,
    'PartlyCloudy': PartlyCloudy,
    'Rain': Rain,
    'Snow': Snow,
    'Storm': Storm,
    'Mist': Mist,
    'SunRotating': SunRotating,
    'PartlyCloudyRotating': PartlyCloudyRotating,
    'CloudsRotating': CloudsRotating,
    'RainRotating': RainRotating,
    'SnowRotating': SnowRotating,
    'StormRotating': StormRotating,
    'MistRotating': MistRotating
  };
  const imgSrcFn = (e, code) => {
    e.currentTarget.src = imgSrc[code];
  };
  return (
    <Paper id='day-forecast' data-testid='day-forecast'>
      <div className='day'>
        <h4>{date}</h4>
        <h5>{day}</h5>
        <img src={imgSrc[iconCode]} alt='Weather Icon'
          onMouseOver={e => imgSrcFn(e, iconCode+'Rotating')}
          onMouseOut={e => imgSrcFn(e, iconCode)}
        />
        <div className="temperature">
          <h5 className='max-temperature'>{maxTemp}</h5>
          <h5 className='min-temperature'>{minTemp}</h5>
        </div>
      </div>
    </Paper>
  );
}

DayForecast.propTypes = {
  maxTemp: PropTypes.string,
  minTemp: PropTypes.string,
  day: PropTypes.string,
  iconCode: PropTypes.string,
  date: PropTypes.string
};

DayForecast.defaultProps = {
  maxTemp: '',
  minTemp: '',
  day: '',
  iconCode: '',
  date: ''
};
