import "./SettingsMismatchModal.scss";

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Button from 'src/component/UI/Button';
import DataTable from 'src/component/UI/DataTable';
import { langLookUpText } from "src/utils/langLookUp";
import { getTemperatureUnit } from 'src/module/authentication/selector';
import { updateDeviceDesignedSettings, updateDeviceSettings } from "src/module/device/action";
import { formatDiscrepancyValue } from "src/utils/utils";
import { getDeviceConfigFields } from "src/module/device/selector";
import ConfirmModal from 'src/component/UI/ConfirmModal';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import _ from 'lodash';
import { Map } from 'immutable';
import PermissionFence from "./PermissionFence";
import { stringToJsonObject } from "src/utils/utils";
import FeatureFence from "./FeatureFence";
import Tooltip from '@mui/material/Tooltip';
import LockIcon from '@mui/icons-material/Lock';
export default function SettingsMismatchModal (props) {
  const dispatch = useDispatch();
  const {
    open,
    deviceId,
    handleClose,
    settingsDiff,
    loading
  } = props;
  const temperatureUnit = useSelector(getTemperatureUnit);
  const deviceConfigFields = useSelector(getDeviceConfigFields(deviceId));
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [buttonClicked, setButtonClicked] = useState(null);

  const columns = [
    {
      id: 'setting',
      Header: 'Setting',
      accessor: row => langLookUpText(row.get('setting')),
      width: '500px'
    },
    {
      id: 'designed-value',
      Header: 'Designed Value',
      accessor: row => formatDiscrepancyValue(row.get('setting'), row.get('designed'), deviceConfigFields, temperatureUnit),
    },
    {
      id: 'current-value',
      Header: 'Current Value',
      accessor: row => formatDiscrepancyValue(row.get('setting'), row.get('current'), deviceConfigFields, temperatureUnit),
    },
  ];

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };
  const confirmModalSubmit = () => {
    toggleConfirmModal();
    if (buttonClicked === langLookUpText('update_designed_settings')) {
      dispatch(updateDeviceDesignedSettings(deviceId));
    }
    else {
      let settingsObj = {};
      let sensorsObj = {};
      settingsDiff?.map(rec => {
        if (rec.get('setting').includes('.')) {
          const jsonObj = stringToJsonObject(rec.get('setting'), rec.get('designed'));
          sensorsObj = _.merge({}, sensorsObj, jsonObj);
        }
        else {
          settingsObj[rec.get('setting')] = rec.get('designed');
        }
      });
      settingsObj = Object.assign({}, settingsObj, sensorsObj);
      dispatch(updateDeviceSettings(deviceId, Map({ settings: settingsObj })));
    }
  };

  const handleDesignedSettingsModalSubmit = () => {
    setButtonClicked(langLookUpText('update_designed_settings'));
    setConfirmMessage(langLookUpText('update_designed_settings_confirm'));
    toggleConfirmModal();
  };

  const handleReprogramDeviceSettingsModalSubmit = () => {
    setButtonClicked(langLookUpText('reprogram_device_settings'));
    setConfirmMessage(langLookUpText('reprogram_device_settings_confirm'));
    toggleConfirmModal();
  };

  return (
    <>
      <Modal
        data-testid='settings-mismatch-modal'
        open={open}
        onClose={handleClose}
        aria-labelledby='settings-mismatch-modal-title'
      >
        <div className='modal setting-mismatch'>
          <div className='settings-modal-top-bar'>
            <h2 className='settings-modal-title'>Settings Different Than Designed</h2>
            {loading ? <LogoSpinner size={70} /> : null}
          </div>
          <DataTable
            columns={columns}
            data={settingsDiff}
          />
          <div className='button-bar'>
            <Button onClick={handleClose}>Cancel</Button>
            <PermissionFence can={['edit-device']}>
              <Button onClick={handleDesignedSettingsModalSubmit} cta>Update Designed Settings</Button>
            </PermissionFence>
            <PermissionFence can={['program-device']}>
              <FeatureFence can={['program-device']} noPermissionComponent={ <Tooltip title={'Talk to your system administrator to gain access to reset alarms' }>
                <span style={{ position: 'relative' }}>
                  <Button disabled onClick={handleReprogramDeviceSettingsModalSubmit} cta>Reprogram Device Settings</Button>
                  <span style={{ position: 'absolute', top: '-20px', right: '5px' }}><LockIcon fontSize='medium' /></span>
                </span>
              </Tooltip>  }>
                <Button onClick={handleReprogramDeviceSettingsModalSubmit} cta>Reprogram Device Settings</Button>
              </FeatureFence>
            </PermissionFence>
          </div>
        </div>

      </Modal>
      <ConfirmModal
        title='Are you sure?'
        message={confirmMessage}
        open={confirmModalOpen}
        handleClose={() => toggleConfirmModal()}
        handleReject={() => toggleConfirmModal()}
        handleConfirm={() => confirmModalSubmit()}
      />
    </>
  );
}

SettingsMismatchModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  deviceId: PropTypes.string,
  settingsDiff: PropTypes.any
};

SettingsMismatchModal.defaultProps = {
  loading: false
};
