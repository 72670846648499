
import React from 'react';
import PropTypes from 'prop-types';

import Button from 'src/component/UI/Button';

import QRCode from 'react-qr-code';

export default function TOTPQRCode (props) {
  const {
    continueButton,
    OTP_URL,
    secret
  } = props;
  return (
    <>
      <h3>Set up MFA</h3>
      <div >Scan the QR code below using an authenticator app</div>
      <div className="qrcode"><QRCode value={OTP_URL} /></div>
      <div >
        Or enter the code in your authenticator app:
      </div>
      <i className='secret'>{secret}</i>

      <div className='otpButton'>
        <Button cta type='submit' onClick={continueButton}>{'Continue'}</Button>
      </div>
    </>
  );
}

TOTPQRCode.propTypes = {
  continueButton: PropTypes.func.isRequired,
  OTP_URL: PropTypes.string.isRequired,
  secret: PropTypes.string.isRequired
};

TOTPQRCode.defaultProps = {
};
