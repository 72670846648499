import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';

import { Box, Tabs, Tab } from '@mui/material';
import CheckSheet from 'src/screen/Protected/DeviceScreen/TestingReport/CheckSheet';
import Button from 'src/component/UI/Button';
import { getDeviceById } from 'src/module/device/selector';
import FinalizeAndSignOff from 'src/screen/Protected/DeviceScreen/TestingReport/FinalizeAndSignOff';
import { Map, fromJS } from 'immutable';
import { getAbilities } from 'src/module/authentication/selector';
import { fetchCheckSheet, saveCheckSheet, updateCheckSheet } from 'src/module/checkSheet/action';
import { getCheckSheetReport, getCheckSheetTasks, getCheckSheetTasksLoading, getSaveCheckSheetReportLoading } from 'src/module/checkSheet/selector';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';

export default function CircuitFunctionTestReport (props) {
  if (!props.open) {
    return null;
  }
  const dispatch = useDispatch();
  const {
    deviceId,
    reportId
  } = props;
  const [tabValue, setTabValue] = useState("1");
  const [passed, setPassed] = useState(false);
  const generalTasks = useSelector(getCheckSheetTasks('circuit-function-general'));
  const report = useSelector(getCheckSheetReport(reportId));
  const device = useSelector(getDeviceById(deviceId));
  const isSaveLoading = useSelector(getSaveCheckSheetReportLoading('circuit-function'));
  const isLoadingTasks = useSelector(getCheckSheetTasksLoading);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const abilities: any = useSelector(getAbilities);
  const isPhoneView = useMediaQuery(`(max-width: ${variables.phoneWidth})`);
  const canEdit = abilities.includes('edit-check-sheet-report');
  const readOnly = Boolean(reportId) && !canEdit;

  const generalTasksList: any = [];
  generalTasks.forEach(task => {
    generalTasksList.push({
      task_id: task.key,
      status: null,
      comment: ''
    });
  });

  const emptyResults = fromJS({
    info: {
      type: 'circuit-function',
      deviceId,
    },
    'circuit-function-general': generalTasksList,
    signOff: {
      recommendations: '',
    },
  }) as Map<string, any>; // Casting to Map
  const [allResults, setAllResults] = useState(reportId === null? emptyResults: Map({}));
  useEffect(() => {
    if (reportId) {
      dispatch(fetchCheckSheet(reportId, 'splice-repair-external-inspection'));
    } else {
      setAllResults(emptyResults);
    }
  }, [reportId]);

  useEffect(() => {
    if (reportId && report.get('checkSheet')) {
      setAllResults(Map(report));
    }
  }, [report, reportId]);
  useEffect(() => {
    const requiredPassTasks = ['function-test-complete', 'critical-program-deficiencies', 'outputting-per-design', 'panel-inspection', 'panel-terminations', 'trace-installed-correctly'];
    let passed = true;
    allResults?.get('circuit-function-general')?.map(task => {
      if ((requiredPassTasks.includes(task.get('task_id')) && task.get('status') !== 'pass' && passed)) {
        passed = false;
      }
    });
    setPassed(passed);
  }, [allResults, reportId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const next = () => {
    const next = parseInt(tabValue) + 1;
    setTabValue(next.toString());
  };
  const previous = () => {
    const next = parseInt(tabValue) - 1;
    setTabValue(next.toString());
  };
  const submit = () => {
    const values: any = allResults.toJS();
    const c = [...values['circuit-function-general']];
    if (reportId) {
      dispatch(updateCheckSheet( allResults.set('checkSheet', fromJS(c)), deviceId, 'circuit-function'));
    }
    else {
      dispatch(saveCheckSheet('circuit-function',  allResults.set('checkSheet', fromJS(c)), deviceId));
    }
  };
  const change = (tab, value) => {
    setAllResults(prevAllResults => {
      const updatedResults = prevAllResults.setIn(tab, value);
      return updatedResults;
    });
  };

  const nextButton = () => {
    return <Button className='next-button' onClick={next} cta>Next</Button>;
  };

  const submitButton = () => {
    return <Button onClick={submit} disabled={readOnly} loading={isSaveLoading} cta>{`${ reportId ? 'Save' : 'Submit' }`}</Button>;
  };
  if (reportId && !report.get('checkSheet')) {
    return;
  }
  return (
    <Modal open={props.open} onClose={props.handleClose} style={{ height: '90vh' }} >
      <div className={`modal testing-report-modal${isPhoneView ? ' modal-no-padding-sides' : ''}`}>
        {!isMobile ? <h2>Circuit Function Test Report <b>({device.get('tag')})</b></h2> : null}
        {isLoadingTasks? <div style={{ margin: 'auto', marginTop: '200px', width: '100px' }}><LogoSpinner size={100} /></div> :
          <>
            <Box sx={{ width: '100%', marginBottom: '20px' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor='primary'
                indicatorColor='primary'
                aria-label="testing-report-tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab value="1" label={<span className='tab-label-size'>Circuit Function Test Report </span>} />
                <Tab value="2" label={<span className='tab-label-size'>Sign Off and Comments</span>} />
              </Tabs>
            </Box>
            {tabValue === '1' ? <CheckSheet readOnly={readOnly} onChange={change} id='circuit-function-general' title='Circuit Function Test Report ' data={allResults?.get('circuit-function-general')} checkSheetType="circuit-function-general" /> : null}
            {tabValue === '2' ? <FinalizeAndSignOff readOnly={readOnly} data={allResults?.get('signOff')} passed={passed} onChange={change} showPassFail={true} /> : null}

            <div className='button-bar stay-at-bottom'>
              <Button onClick={() => props.handleClose()}>Cancel</Button>


              {tabValue !== '1' ? <Button onClick={previous} >Previous</Button> : null}
              {tabValue === '2' ?
                submitButton()
                :
                nextButton()
              }
            </div>
          </>
        }

      </div>
    </Modal>
  );
}

CircuitFunctionTestReport.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  deviceId: PropTypes.string,
  reportId: PropTypes.string,
};
