import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import { persistStore, persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { rootReducer } from '../module/rootReducer';
import { persistConfig } from './persistConfig';
import createReduxTypeSafetyMiddleware from './middleware/reduxTypeSafety';
import sentryMiddleware from './middleware/sentry';
import createActionEnhancerMiddleware from '../enhancers/actionEnhancer';
import isReachable from 'is-reachable';
import createRootReducer from './createRootReducer';
import { addToQueue, removeFromQueue, setRunningQueue, triggerQueue } from 'src/module/offline/action';
import { actionsWithOfflineCapabilities } from 'src/utils/offlineHelperFunctions';

declare global {
  interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any; }
}


export const history = createBrowserHistory();
const offlineMiddleware = (store) => (next) => async (action) => {
  const isOffline = store.getState('offline').offline.get('isOffline');
  const offlineQueue = store.getState('offline').offline.get('actionQueue').toJS();
  const isOfflineAction = actionsWithOfflineCapabilities.includes(action.type);

  if (!isOffline || isOfflineAction) {
    next(action);
  } else {
    store.dispatch(addToQueue(action));
  }
  const isOfflineAfter = store.getState('offline').offline.get('isOffline');
  const isQueueRunning = store.getState('offline').offline.get('isRunning');
  const isTriggerQueue = store.getState('offline').offline.get('triggerQueue');


  if (!isOfflineAfter && offlineQueue.length > 0 && !isQueueRunning && isTriggerQueue) {
    const status = await isReachable(document.location.origin);
    if (status && !(action.type === '@@fox/dashboard/SET_RUNNING_QUEUE')) {
      store.dispatch(setRunningQueue(true));
      for (let i=0; i< offlineQueue.length ; i++) { // executing offline queue
        const item = offlineQueue[i];
        const id= item.queueId;
        delete item.queueId;
        delete item.performed_at;

        next(item);
        store.dispatch(removeFromQueue(id));
      }
      store.dispatch(setRunningQueue(false));
      store.dispatch(triggerQueue(false));
    }
  }
};

export const configureStore =
  (initialState={}) => {
    const middleware = [
      createSagaMiddleware(),
      createActionEnhancerMiddleware(),
      createReduxWaitForMiddleware(),
      createReduxTypeSafetyMiddleware(),
      sentryMiddleware,
      routerMiddleware(history)
    ];

    const composeEnhancers = getComposeEnhancers();

    const store: any = createStore(
      persistReducer(persistConfig, rootReducer(history)),
      initialState,
      composeEnhancers(applyMiddleware(offlineMiddleware, ...middleware))
    );

    const persistor = persistStore(store);

    store.runSaga = middleware[0].run;

    store.asyncReducers = {};
    store.asyncSagas = {};

    store.injectReducer = (key, reducer) => {
      store.asyncReducers[key] = reducer;
      store.replaceReducer(createRootReducer(history, store.asyncReducers));
      store.persistor.persist();
    };

    return { store, persistor };
  };


const getComposeEnhancers = () => {
  return typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 10,
    shouldHotReload: true,
    shouldCatchErrors: true
  }) : compose;
};

export default configureStore;
