import { Map } from 'immutable';
import createTypedReducer from '../createTypedReducer';
import * as prefetchActions from './action';
import * as authenticationActions from '../../module/authentication/action';

export const initialState = Map({
  isPrefetching: false,
  hasInitialized: false,
  prefetchFailed: false
});

export const prefetchReducer = createTypedReducer(initialState, {
  [prefetchActions.PREFETCH_ALL] (state) {
    return state.withMutations((nextState) =>
      nextState.set('isPrefetching', true)
        .set('prefetchFailed', false)
    );
  },

  [prefetchActions.PREFETCH_ALL_SUCCESS] (state) {
    return state.withMutations((nextState) =>
      nextState.set('isPrefetching', false)
        .set('hasInitialized', true)
        .set('prefetchFailed', false)
    );
  },

  [prefetchActions.PREFETCH_ALL_FAILURE] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('isPrefetching', false)
        .set('error', action.error)
        .set('hasInitialized', false)
        .set('prefetchFailed', true)
    );
  },

  [authenticationActions.CLEAR] () {
    return initialState;
  }
});

export default prefetchReducer;
