import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';

import Button from 'src/component/UI/Button';
import { Table, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';

export default function DisplayInfoModal (props) {
  return (
    <Modal
      data-testid='display-info-modal'
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='display-info-modal-title'
    >
      <div className='modal'>
        <h2>{props.title || 'Information'}</h2>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{props.data.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Password</TableCell>
                <TableCell>{props.data.password}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className='button-bar'>
          {<Button variant="contained" onClick={props.handleClose}>Close</Button>}
        </div>
      </div>

    </Modal>
  );
}

DisplayInfoModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object
};

DisplayInfoModal.defaultProps = {
};
