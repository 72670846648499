import { Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as searchActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  search: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('search', Map())
  );
}

export const searchReducer = createTypedReducer(initialState, {
  [searchActions.FETCH_CONFIGURED_SEARCH_FIELDS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['search', 'loading'], true)
    );
  },

  [searchActions.FETCH_CONFIGURED_SEARCH_FIELDS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const table = action.configuredSearchFields.get('table');
      nextState.setIn(['search', 'configuredFields', table], action.configuredSearchFields.get('fields'));
      nextState.setIn(['search', 'loading'], false);
    }
    );
  },

  [searchActions.FETCH_CONFIGURED_SEARCH_FIELDS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['search', 'loading'], false)
    );
  },


  [searchActions.FETCH_ALL_SEARCHABLE_FIELDS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['search', 'allSearchableFields', 'loading'], true)
    );
  },

  [searchActions.FETCH_ALL_SEARCHABLE_FIELDS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      return nextState.setIn(['search', 'allSearchableFields', 'loading'], false)
        .setIn(['search', 'allSearchableFields', 'fields'], action.fields);
    });
  },

  [searchActions.FETCH_ALL_SEARCHABLE_FIELDS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['search', 'allSearchableFields', 'loading'], false)
    );
  },


  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default searchReducer;
