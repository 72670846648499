import React, { useEffect } from 'react';

import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';

import { useSelector, useDispatch } from 'react-redux';
import { fromJS } from 'immutable';

import { fetchIntegrations } from 'src/module/integration/action';
import { getIntegrations } from 'src/module/integration/selector';
import { setSettingsModalClose } from 'src/module/device/action';
import { getSettingsModal } from 'src/module/device/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import { addCommLoop } from 'src/module/device/action';


export default function AddCommLoopModal () {
  const dispatch = useDispatch();

  const integrations = useSelector(getIntegrations);
  const settingsModal = useSelector(getSettingsModal(null));
  useEffect(() => {
    dispatch(fetchIntegrations());
    dispatch(setSettingsModalClose(null));
  }, []);

  let frogName = null, frogId = null;
  const frogClients = [];
  integrations?.forEach(integration => {
    if (integration.get('type') === 'frog') {
      frogId = integration?.get('id');
      frogName = integration?.getIn(['settings', 'name']) || frogId.substring(0, 5);
      frogClients.push({ label: frogName, value: frogId });
    }
  });

  const commLoopFields = {
    frog_client_id: {
      type: 'select',
      label: 'Assigned Supervisor Client',
      options: frogClients,
      errorText: 'Required'
    },
    ip_address: {
      type: 'text',
      label: 'IP address',
      errorText: 'Required',
    },
    port: {
      type: 'text',
      label: 'Port',
      errorText: 'Required'
    }
  };

  const handleClose = () => {
    dispatch(setSettingsModalClose(null));
  };

  const commLoopModalSubmit = (rec: any) => {
    let port = rec.get('port', null);
    if (port) {
      port = parseInt(port);
    }
    rec = rec.set('port', port);
    dispatch(addCommLoop(rec));
  };

  return (
    <SettingsModal
      open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.ADD_COMM_LOOP}
      handleClose={handleClose}
      handleSubmit={commLoopModalSubmit}
      title={'Add Comm Loop'}
      loading={settingsModal?.get('loading')}
      fields={
        fromJS(commLoopFields)
      }
    />
  );
}

AddCommLoopModal.propTypes = {
};

AddCommLoopModal.defaultProps = {
};
