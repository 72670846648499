import './AlarmsByStatusGraph.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import variables from 'src/style/variable/variables.module.scss';

import { getAlarmStats } from 'src/module/alarm/selector';

import { formatAlarmLabel } from 'src/component/AlarmsList';
import PlaceholderPie from 'src/screen/Protected/DashboardScreen/assets/placeholder-pie.png';

export default function AlarmsByStatusGraph () {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const alarmStats = useSelector(getAlarmStats);

  const criticalAlarmsByStatus = alarmStats.get('criticalAlarmsByStatus', Map());
  let criticalAlarmsByStatusData: any = [];

  criticalAlarmsByStatus.keySeq().forEach((status) => {
    const val = criticalAlarmsByStatus.get(status);
    criticalAlarmsByStatusData.push({ x: status, y: val });
  });

  criticalAlarmsByStatusData.sort((a, b) => {
    if (a.y > b.y) {
      return -1;
    } else if (a.y < b.y) {
      return 1;
    }
    return 0;
  });

  let otherAlarmsByStatusData: any = null, othersAlarmCount = 0;
  const maxPieSlices = 8;
  if (criticalAlarmsByStatusData.length > maxPieSlices) {
    const otherAlarmsIndex = criticalAlarmsByStatusData.findIndex(rec => rec.x === 'others');
    if (otherAlarmsIndex !== -1) {
      othersAlarmCount = criticalAlarmsByStatusData[otherAlarmsIndex].y;
      criticalAlarmsByStatusData.splice(otherAlarmsIndex, 1);
    }

    otherAlarmsByStatusData = criticalAlarmsByStatusData.slice(maxPieSlices);
    criticalAlarmsByStatusData = criticalAlarmsByStatusData.slice(0, maxPieSlices);
    criticalAlarmsByStatusData.push({ x: 'other', y: othersAlarmCount });
    otherAlarmsByStatusData.forEach((item) => {
      criticalAlarmsByStatusData[maxPieSlices].y += item.y;
    });
  }

  const labels = [], dataValues = [];

  criticalAlarmsByStatusData?.map(rec => {
    labels.push(formatAlarmLabel(rec.x)+ ' '+ `(${rec.y})`);
    dataValues.push(rec.y);
  });

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Number of Alarms',
        data: dataValues,
        backgroundColor: [
          variables.primary,
          variables.secondary,
          variables.quaternary,
          variables.tertiary,
          variables.quinary,
          variables.violet,
          variables.secondaryDark,
          variables.primaryLight,
          variables.tertiaryDark,
          variables.quinaryLight
        ],
      },
    ],
  };


  return criticalAlarmsByStatusData.length ? (
    <div className='alarms-by-status-block'>
      <Pie
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.2,
          plugins: {
            legend: {
              display: true,
              position: "left",
              labels: {
                font: {
                  family: 'Montserrat',
                },
                padding: 15
              },
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
        }}
      />
    </div>
  ) : (
    <div className="placeholder-graph">
      <div className='outer placeholder-graph-overlay'>
        <div className="middle">
          <div className="inner">No Alarms for the Current Selection <span role='img' aria-label='Sweat Smile'>😅</span></div>
        </div>
      </div>
      <img width='100%' src={PlaceholderPie} alt="Placeholder Graph" />
    </div>
  );
}

AlarmsByStatusGraph.propTypes = {
};

AlarmsByStatusGraph.defaultProps = {
};
