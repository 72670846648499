import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import AlertIcon from '@mui/icons-material/ReportProblemOutlined';
import Tooltip from '@mui/material/Tooltip';

export default function DeviceLink (props) {
  const {
    circuitTag,
    controllerTag,
    rawTag
  } = props;

  let circuit, controller, label, to, tooltipTitle;
  if (typeof circuitTag === 'undefined' && typeof controllerTag !== 'undefined' && controllerTag !== null) {
    controller = controllerTag;
    label = controllerTag;
    to = `/devices/${controller}`;
  } else if (typeof circuitTag !== 'undefined') {
    circuit = circuitTag ? encodeURIComponent(circuitTag) : '';
    controller = controllerTag ? encodeURIComponent(controllerTag) : '_';
    label = props.label || circuitTag || controllerTag || '';
    to = `/devices/${controller}/${circuit}`; // formating the redirect URL
  }

  if (controllerTag === null) {
    tooltipTitle = 'Unknown Controller';
  }

  if (circuitTag === null) {
    tooltipTitle = 'Unknown Circuit';
  }

  return (controllerTag || circuitTag) ?
    (<NavLink to={to} data-testid='device-link'> {label} </NavLink>) :
    rawTag ? (<Tooltip title={`${tooltipTitle}`} className='error-text' data-testid='device-link'>
      <div><span className='icon'><AlertIcon width={16} height={16} /></span><span className='label'>{rawTag || 'N/A'}</span></div>
    </Tooltip>) : (<span data-testid='device-link'>N/A</span>);
}

DeviceLink.propTypes = {
  label: PropTypes.string,
  circuitTag: PropTypes.string,
  controllerTag: PropTypes.string,
  rawTag: PropTypes.string
};

DeviceLink.defaultProps = {
};
