import './LocationModal.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateDevice } from 'src/module/device/action';
import Button from 'src/component/UI/Button';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Modal, TextField } from '@mui/material';
import { Map } from 'immutable';
export default function LocationModal ({ deviceId, latitude, longitude, onClose }) {

  const [location, setLocation] = useState({ latitude, longitude });
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };
  const handleClose = () => {
    onClose();
  };
  const save = () => {
    dispatch(updateDevice(deviceId, Map(location)));
    onClose();
  };
  const onChange = (label, val) => {
    if (label ==='latitude') {
      setLocation((loc) => {return { latitude: val, longitude: loc.longitude };});
    } else {
      setLocation((loc) => {return { latitude: loc.latitude, longitude: val };});

    }
  };
  return (
    <Modal
      data-testid='location-modal'
      open={true}
      onClose={handleClose}
      aria-labelledby='location-modal-title'
    >
      <div className='modal location-modal'>
        <div >
          <h2 >Geo Location </h2>
        </div>
        <form onSubmit={save}>
          <div className='form-container '>
            <div className='textInput'><TextField  label="Latitude" onChange={(val) => onChange('latitude', val.target.value)} value={location.latitude}/></div>
            <div className='textInput'><TextField  label="Longitude" onChange={(val) => onChange('longitude', val.target.value)} value={location.longitude}/>
              <a className='link' href={ `https://www.google.com/maps?q=${location.latitude},${location.longitude}`} target="_blank" rel="noopener noreferrer">
                View Map
              </a>
            </div>
            <div className='icon-container' onClick={getLocation}><MyLocationIcon style={{ fontSize: 26, display: 'block', marginTop: '10px' }}/></div>
          </div>

        </form>
        {error && <div className='error-text'>{error}</div>}


        <div className='button-bar'>
          <Button onClick={handleClose}>Cancel</Button>
          <Button cta onClick={save}>Save</Button>
        </div>
      </div>
    </Modal>
  );
}

LocationModal.propTypes = {
  deviceId: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  onClose: PropTypes.func
};

LocationModal.defaultProps = {
  deviceId: null,
};
