import * as yup from 'yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/autobahn/';

export const { start, START }: any = createTypedAction(
  `${PREFIX}START`
);

export const { stop, STOP }: any = createTypedAction(
  `${PREFIX}STOP`
);

export const { ready, READY }: any = createTypedAction(
  `${PREFIX}READY`
);

export const { connected, CONNECTED }: any = createTypedAction(
  `${PREFIX}CONNECTED`
);

export const { disconnected, DISCONNECTED }: any = createTypedAction(
  `${PREFIX}DISCONNECTED`
);

export const { reconnected, RECONNECTED }: any = createTypedAction(
  `${PREFIX}RECONNECTED`
);

export const { authenticate, AUTHENTICATE }: any = createTypedAction(
  `${PREFIX}AUTHENTICATE`
);

export const { newAutobahnNotification, NEW_AUTOBAHN_NOTIFICATION }: any = createTypedAction(
  `${PREFIX}NEW_AUTOBAHN_NOTIFICATION`,
  ['notification', yup.object().nullable()]
);

export const { dismissAutobahnUpdateNotification, DISMISS_AUTOBAHN_UPDATE_NOTIFICATION }: any = createTypedAction(
  `${PREFIX}DISMISS_AUTOBAHN_UPDATE_NOTIFICATION`
);


export const { publish, PUBLISH }: any = createTypedAction(
  `${PREFIX}PUBLISH`,
  ['name', yup.string().required()],
  ['payload', yup.object().required()]
);

export const { registerChannel, REGISTER_CHANNEL }: any = createTypedAction(
  `${PREFIX}REGISTER_CHANNEL`,
  ['name', yup.string().required()],
  ['channelConsumer', yup.object().required()]
);
