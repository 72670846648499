import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as recipesApi from '../../service/api/recipes';
import * as recipeActions from './action';
import * as devicesActions from '../device/action';
import toast from '../../utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * recipeRoot () {
  yield takeLatest(recipeActions.FETCH_RECIPES, fetchRecipes);
  yield takeLatest(recipeActions.UPDATE_RECIPE, updateRecipe);
  yield takeLatest(recipeActions.ADD_RECIPE, addRecipe);
  yield takeLatest(recipeActions.DELETE_RECIPE, deleteRecipe);
  yield takeLatest(recipeActions.ADD_RECIPE_CONDITION, addRecipeCondition);
  yield takeLatest(recipeActions.UPDATE_RECIPE_CONDITION, updateRecipeCondition);
  yield takeLatest(recipeActions.FETCH_RECIPE_BY_ID, fetchRecipeById);
  yield takeLatest(recipeActions.FETCH_DEVICE_SETTING_MAP, fetchDeviceSettingMap);
}

function * fetchRecipes (action) {
  try {
    const recipes = yield call(recipesApi.getRecipes, action);

    yield put(recipeActions.fetchRecipesSuccess(recipes || Map()));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving recipes'));
    yield put(recipeActions.fetchRecipesFailed(Map(err)));
  }
}
function * fetchDeviceSettingMap (action) {
  try {
    const settingsMap = yield call(recipesApi.getDeviceSettingMap, action);
    yield put(recipeActions.fetchDeviceSettingMapSuccess(settingsMap));
  } catch (err) {
    yield put(recipeActions.fetchDeviceSettingMapFailed(Map(err)));
  }
}

function * fetchRecipeById (action) {
  try {
    const recipe = yield call(recipesApi.getRecipeById, action);

    yield put(recipeActions.fetchRecipeByIdSuccess(recipe || Map()));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving recipe By Id'), err.response.status);
    yield put(recipeActions.fetchRecipeByIdFailed(Map(err)));
  }
}

function * addRecipe (action) {
  try {
    const recipe = yield call(recipesApi.addRecipe, action);

    yield put(recipeActions.addRecipeSuccess(recipe));
    yield put(devicesActions.setSettingsModalClose(null));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to create new Recipe'), err.response.status);
    yield put(recipeActions.addRecipeFailed(err));
  }
}

function * deleteRecipe (action) {
  try {
    yield call(recipesApi.deleteRecipe, action);
    yield put(recipeActions.deleteRecipeSuccess(action.recipeId));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to delete recipe'), err.response.status);
    yield put(recipeActions.deleteRecipeFailed(err));
  }
}

function * updateRecipe (action) {
  try {
    const recipes = yield call(recipesApi.updateRecipe, action);
    yield put(recipeActions.updateRecipeSuccess(recipes));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating recipe'), err.response.status);
    yield put(recipeActions.updateRecipeFailed(Map(err)));
  }
}

function * addRecipeCondition (action) {
  try {
    const recipe = yield call(recipesApi.addRecipeCondition, action);
    yield put(recipeActions.addRecipeConditionSuccess(recipe));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to create new recipe condition'), err.response.status);
    yield put(recipeActions.addRecipeConditionFailed(err));
  }
}

function * updateRecipeCondition (action) {
  try {
    const recipe = yield call(recipesApi.updateRecipeCondition, action);

    yield put(recipeActions.updateRecipeConditionSuccess(recipe));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating recipe'), err.response.status);
    yield put(recipeActions.updateRecipeConditionFailed(Map(err)));
  }
}

export default recipeRoot;
