import React from 'react';
const serviceTerms = <div>
  <h2>SMARTTRACE SOFTWARE AS A SERVICE AGREEMENT</h2>

  {`PLEASE CAREFULLY REVIEW THE FOLLOWING TERMS AND CONDITIONS OF THIS SOFTWARE AS A SERVICE AGREEMENT (THE "AGREEMENT") BEFORE CONTINUING.
    THIS IS A LEGAL AGREEMENT BETWEEN YOU, THE END USER, AND ELECTRICAL HEAT TRACE GROUP LTD. ("EHT GROUP") AND CONTAINS IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND OBLIGATIONS,
    AS WELL AS LIMITATIONS AND EXCLUSIONS THAT MAY APPLY TO YOU. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY,
    YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THE TERMS AND CONDITIONS OF THIS AGREEMENT AND, IN SUCH EVENT,
    “YOU” AND “YOUR” AS USED IN THIS AGREEMENT SHALL REFER TO SUCH ENTITY. THE TERMS OF THIS AGREEMENT GOVERN YOUR USE OF THE SMARTRACE SOFTWARE,
    INCLUDING ANY UPDATES OR UPGRADES THEREOF, AS WELL AS ANY ACCOMPANYING WRITTEN MATERIALS OR FILES (COLLECTIVELY REFERRED TO IN THIS AGREEMENT AS THE "SOFTWARE").
    THIS AGREEMENT CONTAINS A DISPUTE RESOLUTION CLAUSE.`}
  <br/>
  <br/>
  <strong>
      BY ACCESSING, OR OTHERWISE USING THE SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT
      AND ARE AGREEING TO BE BOUND BY THE TERMS OF THIS AGREEMENT.
  </strong>
  <ol>
    <li>
      <strong>SOFTWARE AS A SERVICE.  </strong>
      {`SmartTrace™ is an electrical heat monitoring and management software as a service platform developed, owned and made available by EHT Group on a subscription basis.
         In consideration of the mutual covenants, and subject to the terms and conditions in this Agreement, EHT Group hereby grants to you a revocable, non-exclusive, non-assignable, worldwide,
         right to access and use the Software solely for your business operations and subject to the Terms and Conditions of this Agreement. You acknowledge and agree that this Agreement
         is a services agreement and EHT Group will not be selling or delivering copies of the Software to You. You agree that your use of the Software does not confer any
         title or ownership in or to the Software. EHT Group shall retain all and sole right, title and interest, including the copyright, in and to the Software.
         EHT Group reserves all rights not expressly granted herein.`}
    </li>
    <li><strong>RESTRICTIONS. </strong>You agree to use the Software only for purposes set forth herein. You further expressly agree to:
      <ol type="a">
        <li>not alter, modify, adapt, decompile, duplicate, translate, reverse engineer or otherwise create derivative works of the Software;</li>
        <li>not distribute, transfer, sell, rent, license, lease or otherwise make the Software available to any other person or entity without the prior written consent of EHT Group;</li>
        <li>not remove, disable or otherwise circumvent any security features, proprietary notices or labels contained within the Software;</li>
        <li>use reasonable efforts to protect the Software from unauthorized access or use; and</li>
        <li>promptly notify EHT Group of any known unauthorized access or use of the Software or a violation of subsections 2(a)-(d).</li>
      </ol>
    </li>
    <li><strong>SUBSCRIPTION. </strong>
      {`You agree to pay a non-refundable subscription fee for accessing and using the Software.
        You agree to pay any sales, value-added or other similar taxes imposed by applicable laws.
        Payment of the subscription fee shall be made by pre-authorized payment by credit card, debit transaction, or other electronic funds transfer.
        A method for pre-authorized payment must be selected at the time of registering for the Software.
        Any changes to your payment information must be communicated to EHT Group in a timely manner to avoid suspension of your access to and use of the Software. EHT Group reserves
        the right to suspend delivery of the Software and to suspend its obligations under Section 1 if you fail to timely pay any amounts due, but only after informing you of such failure,
        and such failure continues for fifteen (15) days. Suspension of the Software does not release you from payment of any amounts owing,
        nor does it release you from any obligations under this Agreement. You agree that EHT Group will not be liable to you or any third party for any liabilities,
        claims or expenses arising from or relating to suspension of the Software resulting from your nonpayment.`}
    </li>
    <li><strong>INTELLECTUAL PROPERTY. </strong>
      {`The Software constitutes the intellectual property of EHT Group and is protected by Canadian copyright law and international treaty provisions.
        You agree that the Software conspicuously displays a copyright notice indicating it is owned by EHT Group. The Software further contains valuable
        trade secrets and confidential information that is the sole property of EHT Group. Certain logos, product names and trademarks owned by EHT Group may be contained within the Software.
          You have no right to use or benefit from such trademarks except as set out in this Agreement.`}
    </li>
    <li><strong>DATA STORAGE AND PROTECTION. </strong>
      {`User and heat trace data (collectively, the “Data”) entered into or collected by the Software will be stored on secure servers.
        EHT Group will not sell, share, provide or otherwise disclose the Data to any third parties unless such disclosure is compelled by
        law or by order of a court of competent jurisdiction.`}
    </li>
    <li><strong>DISCLAIMER OF WARRANTY. </strong>
      {`EHT Group warrants that it is the owner and provider of the Software and that it has the right and authority to grant you access to the Software.
        Except as expressly provided above, EHT Group disclaims all warranties, either express or implied, including but not limited to,
        any warranty of merchantability or fitness for a particular purpose. No oral or written information or advise given by EHT Group
        or its dealers, distributors or agents shall create such a warranty. The Software is provided "as is" and EHT Group
        (including its affiliates, directors, officers, shareholders, employees and other representatives)
        does not warrant that the functions contained in the Software will meet your requirements, or that the operation of the
        Software will be uninterrupted or error-free or that defects will be corrected.`}
      <br/><br/>
      {`It is assumed that the user of the Software is knowledgeable in the engineering design of heat-tracing systems.
        EHT Group has not verified the results produced by the Software for all conceivable design or analysis cases,
        application scenarios, or system settings. You must judge the reasonableness of the results obtained by using your
        engineering expertise, and should independently evaluate the Software results before taking any action in reliance thereon.
        The entire risk as to the quality, accuracy, adequacy, completeness, correctness, suitability, and validity of the Software results
         rests with you. `}
    </li>
    <li><strong>LIMITATION OF LIABILITY. </strong>
      {`In no event shall EHT Group, its affiliates, directors, officers, shareholders, employees, or other representatives be liable
          for damages of any kind, including without limitation, any loss, damage, or delay, or for any lost profits, loss of use,
          loss of production, interruption of business, or for any compensatory, special, incidental, consequential and indirect damages of
          any kind arising out of or in connection with the use of, or the inability to use, the Software including any issues arising from
          heat trace controller programming modification that results in the inoperability of heat trace circuits or any operational issues or
          loss of production of any kind. The entire risk of the results and performance of the Software is assumed by you. Further, in no event
          shall EHT Group, its affiliates, directors, officers, shareholders, employees or other representatives be liable to you in an
          amount greater than the amount actually paid by you, if any, for access to and use of the Software.
          Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages so the above exclusions may not
          apply`}
    </li>
    <li><strong>INDMENITY. </strong>
      {`To the extent allowed by law, you agree to indemnify and hold EHT Group,
        its affiliates, directors, officers, shareholders, employees, or other representatives harmless from any claim,
        loss, cost, expense, demand, or damage, including reasonable attorneys' fees, arising directly or indirectly out of
        (a) your use of, or inability to use, the Software, (b) your activities in connection therewith, or (c)
        your breach of this Agreement.`}
    </li>
    <li><strong>DISPUTE RESOLUTION. </strong>
      {`Any claim, dispute or controversy pertaining to the Software or this Agreement,
        shall first attempt to be resolved by way of good faith negotiation between you and EHT Group.
        In the event the good faith negotiation fails to resolve the dispute, the dispute shall be resolved exclusively and finally by
        binding arbitration. The arbitration will be limited solely to the dispute between you and EHT Group.
        Any award of the arbitrator(s) shall be final and binding on you and EHT Group, and may be entered as a
        judgment in a court of competent jurisdiction.  `}
    </li>
    <li><strong>TERM & TERMINATION.  </strong>
      {`This Agreement is effective until terminated. This Agreement shall terminate if you fail to comply with any term or condition of the Agreement,
        if you cancel your subscription to the Software or when your subscription to the Software ends. You may terminate this Agreement upon providing thirty (30)
        days’ prior notice to EHT Group in writing. EHT Group may terminate this Agreement upon providing thirty (30) days’ prior notice to you in writing.
        Upon termination of this Agreement, you agree to (a) no longer access or use the Software; (b)
        pay any outstanding subscription fees and amounts owing withing fifteen (15) days; and (c) destroy all copies of the Software in your possession.
        Sections 2, 4-10 and 12-15 shall survive termination of this Agreement.`}
    </li>
    <li><strong>SUBMISSIONS & NOTICES.</strong>
      {`EHT Group welcomes your comments, feedback, and suggestions for improving the Software. You agree that any idea, suggestion, information, know-how, material, data,
        or any other content submitted by You (collectively, “Submissions”) becomes the property of EHT Group. You agree that EHT Group may use or act on any Submissions,
        or derivatives thereof, without any obligation to you. Should you have any Submissions concerning the Software, or if you wish to provide a notice under this Agreement,
        or if you desire to contact EHT Group for any reason, please write to:`}
      <br/><br/>
      <address>
          Electrical Heat Trace Group Ltd. <br/>
          72 St Leger St, Suite 312 <br/>
          Kitchener, ON, N2H 6R4 <br/>
      </address>

      {`[support@ehtgroup.com] `}
    </li>
    <li><strong>ASSIGNMENT. </strong>
      {`You shall not assign your rights and obligations under this Agreement to any party at any time.
        EHT Group reserves the right to assign this Agreement and its rights and obligations hereunder, upon written notice to you.`}
    </li>
    <li><strong>ENTIRE AGREEMENT & MODIFICATION. </strong>
      {`This Agreement constitutes the entire agreement between you and EHT Group. EHT Group Ltd reserves the right to change this Agreement
        at any time upon providing you written notice, and your continued use of the Software after
        any changes indicates your acceptance of the new terms and conditions. `}
    </li>
    <li><strong>SEVERABILITY.  </strong>
      {`The provisions of this Agreement are to be considered separately, and if any provision(s) hereof should be found to be invalid or unenforceable under applicable law,
        this Agreement will be deemed to have effect as if such provision(s) were severed from this Agreement. `}
    </li>
    <li><strong>GOVERNING LAW. </strong>
      {`This Agreement shall be governed by the laws of the Province of Ontario and the laws of Canada applicable therein. `}
    </li>
  </ol>
</div>;

export default serviceTerms;
