import './GroupedDevicesScreen.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GroupedDevicesMenu from './GroupedDevicesMenu';
import { Helmet } from 'react-helmet';
import { MenuItem, Paper, Select } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import useMediaQuery from '@mui/material/useMediaQuery';
import { pushHistory } from 'src/module/navigation/action';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import CircuitsList from 'src/component/UI/CircuitsList';
import UnitSelector from 'src/component/UI/UnitSelector';
import { fetchDevicesActiveAlarms, fetchPanelActiveAlarms, closeSyncPopUp } from 'src/module/device/action';
import { getActiveAlarms, getLoadingActiveAlarms, getLoadingActiveAlarmsOnPanel, getNumActiveAlarmOnPanelPage, getNumActiveAlarmPages, getPanelActiveAlarms, getTotalActiveAlarms, getTotalActiveAlarmsOnPanel } from 'src/module/device/selector';
import { fetchActiveAlarms } from 'src/module/alarm/action';
import { getAlarms, getLoadingAlarms, getNumAlarmPages, getTotalAlarms } from 'src/module/alarm/selector';
import variables from 'src/style/variable/variables.module.scss';
import Button from 'src/component/UI/Button';
import AlarmsList from 'src/component/AlarmsList';
import GroupedDevicesEditPanelView from './GroupedDevicesEditPanelView';
import { getRecipes } from 'src/module/recipe/selector';
import { getAbilities, getFeatures } from 'src/module/authentication/selector';
import { fetchDeviceSettingMap, fetchRecipes } from 'src/module/recipe/action';
import { createBatch } from 'src/module/batchSchedule/action';
import { fetchDeviceGroup } from 'src/module/groupDevices/action';

import { getLoadingBatchSchedules } from 'src/module/batchSchedule/selector';
import { isWhitelabel } from 'src/utils/utils';
import FeatureFence from 'src/component/FeatureFence';
import PermissionFence from 'src/component/PermissionFence';
import { List } from 'immutable';
import CreateGroupModal from '../DeviceGroupsScreen/CreateGroupModal';
import { getDeviceGroup } from 'src/module/groupDevices/selector';

export default function GroupedDevicesScreen () {
  const dispatch: any = useDispatch();
  const { tab, templateId, deviceGroupId } = useParams();
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [showCreateNewPanel, setShowCreateNewPanel] = useState(false);
  const [tabValue, setTabValue] = useState(tab ?? 'devices');
  const [isAllSelectedOnPage, setisAllSelectedOnPage] = useState(false);
  const [isAllSelected, setisAllSelected] = useState(false);
  const [filter, setFilter] = useState('');
  const recipes: List<any> = useSelector(getRecipes, (prev, next) => prev?.equals(next));
  const [selectedRecipe, setSelectedRecipe] = useState(recipes ? recipes.find((item: any) => item.get('id') === templateId)?.get('id') || 'none' : 'none');
  const [recipeState, setRecipeState] = useState(recipes);
  const features: List<any> = useSelector(getFeatures, (prev, next) => prev?.equals(next));
  const abilities: List<any> = useSelector(getAbilities, (prev, next) => prev?.equals(next));
  const applyingRecipeLoading: boolean = useSelector(getLoadingBatchSchedules);
  const deviceGroup = useSelector(getDeviceGroup(deviceGroupId), (prev, next) => prev?.equals(next));

  const [selected, setSelected] = useState((deviceGroupId && deviceGroup) ? deviceGroup?.get('devices').map(g => g.get('device_id')).toJS() : []);
  const [showCreateModal, setShowCreateModal] = useState(false);
  useEffect(() => {
    if (tab !== tabValue) {
      setTabValue(tab ?? 'devices');
    }
  }, [tab]);
  useEffect(() => {
    setSelected((deviceGroupId && deviceGroup) ? deviceGroup?.get('devices').map(g => g.get('device_id')).toJS() : []);
  }, [deviceGroup]);
  useEffect(() => {
    if (deviceGroupId) {
      dispatch(fetchDeviceGroup(deviceGroupId));
    }
  }, [deviceGroupId]);

  useEffect(() => {
    dispatch(closeSyncPopUp());
    dispatch(fetchDeviceSettingMap());
    if (features?.includes('batches-and-recipes') && abilities.includes('edit-device') && abilities.includes('edit-recipe')) {
      dispatch(fetchRecipes());
    }
  }, []);

  useEffect(() => {
    if (recipes) {
      setRecipeState(recipes);
    }
  }, [recipes]);


  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const allDevices: any = {
    fetch: fetchActiveAlarms,
    alarmSelector: getAlarms,
    alarmLoadingSelector: getLoadingAlarms,
    numOfAlarmPagesSelector: getNumAlarmPages,
    numOfAlarmsSelector: getTotalAlarms,
  };
  const [alarmFunctions, setAlarmFunctions] = React.useState(allDevices);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    dispatch(pushHistory(`/devices/list/${newValue}`));

  };

  const onPanelClick = useCallback((node) => {
    const panel: any = {
      fetch: fetchPanelActiveAlarms,
      alarmSelector: getPanelActiveAlarms(node.get('id')),
      alarmLoadingSelector: getLoadingActiveAlarmsOnPanel(node.get('id')),
      numOfAlarmPagesSelector: getNumActiveAlarmOnPanelPage(node.get('id')),
      numOfAlarmsSelector: getTotalActiveAlarmsOnPanel(node.get('id')),
    };

    setAlarmFunctions(panel);
    setSelectedPanel(node);
    setSelectedDevice(null);
    setShowCreateNewPanel(false);
  }, [fetchPanelActiveAlarms, setAlarmFunctions, setSelectedPanel, setSelectedDevice, setShowCreateNewPanel]);

  const onControllerClick = useCallback((node) => {
    setAlarmFunctions({
      fetch: fetchDevicesActiveAlarms,
      alarmSelector: getActiveAlarms(node.get('id')),
      alarmLoadingSelector: getLoadingActiveAlarms(node.get('id')),
      numOfAlarmPagesSelector: getNumActiveAlarmPages(node.get('id')),
      numOfAlarmsSelector: getTotalActiveAlarms(node.get('id')),
    });
    setSelectedDevice(node);
    setSelectedPanel(null);
    setShowCreateNewPanel(false);
  }, [fetchDevicesActiveAlarms, setAlarmFunctions, setSelectedDevice, setSelectedPanel, setShowCreateNewPanel]);

  useEffect(() => {
    if (!selectedDevice && !selectedPanel) {
      setAlarmFunctions(allDevices);
    }
  }, [selectedDevice, selectedPanel]);

  const updateSelected = useCallback((selected) => {
    setSelected(selected);
  }, [setSelected]);

  const handleSelectAllOnPage = useCallback((isAllSelectedOnPage) => {
    setisAllSelectedOnPage(isAllSelectedOnPage);
  }, [setisAllSelectedOnPage]);

  const handleSelectAll= useCallback((isAllSelected) => {
    setisAllSelected(isAllSelected);
  }, [setisAllSelected]);

  const handleFilterUpdated = useCallback((filter) => {
    setFilter(filter);
  }, []);

  const handleBatchSubmit = () => {
    // create batch
    dispatch(createBatch(isAllSelected ? ['all'] : selected, selectedRecipe, selectedPanel?.get("id"), filter));
  };

  const onRecipeChange = useCallback((e) => {
    setSelectedRecipe(e.target.value);
  }, [setSelectedRecipe]);

  const handleSetSelectedDevice = useCallback((device) => setSelectedDevice(device), [setSelectedDevice]);
  const handleSetSelectedPanel = useCallback((panel) => setSelectedPanel(panel), [setSelectedPanel]);
  const handleSetShowCreateNewPanel = useCallback((showCreateNewPanel) => setShowCreateNewPanel(showCreateNewPanel), [setShowCreateNewPanel]);


  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';
  return (
    <div id='devices' className='page' data-testid='grouped-devices-screen'>
      <Helmet>
        <title>{title} - Device List</title>
      </Helmet>
      <Paper>
        <UnitSelector />
      </Paper>
      <div className={classNames(['groupedDevicePage'])} >
        {isMobile ? <Paper>
          <div className='grouped-device-screen'>
            <div className='left'>
              <GroupedDevicesMenu onPanelClick={onPanelClick} onControllerClick={onControllerClick} setSelectedDevice={handleSetSelectedDevice} setSelectedPanel={handleSetSelectedPanel} setShowCreateNewPanel={handleSetShowCreateNewPanel} />
            </div>
          </div>
        </Paper>
          : null}
        <Paper>
          <div className='grouped-device-screen'>
            {!isMobile ? <div className='left'>
              <GroupedDevicesMenu onPanelClick={onPanelClick} onControllerClick={onControllerClick} setSelectedDevice={handleSetSelectedDevice} setSelectedPanel={handleSetSelectedPanel} setShowCreateNewPanel={handleSetShowCreateNewPanel} />
            </div> : null}
            <div className='right'>
              {!showCreateNewPanel && !isMobile && tabValue !== 'program' && tabValue !== 'create-group' ?
                <div className='devices-alarms-tabs'>
                  <Box sx={{ width: '100%' }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      textColor='primary'
                      indicatorColor='primary'
                      aria-label="Grouped devices/alarms tabs"
                    >
                      <Tab value="devices" label={<span className='tab-label-size'>Devices</span>} />
                      <Tab value="alarms" data-testid={`alarms-tab`} label={<span className='tab-label-size'>Alarms</span>} />
                    </Tabs>
                  </Box>
                </div>
                : ''
              }
              {/* view selected controller or panel */}
              {showCreateNewPanel ? <GroupedDevicesEditPanelView selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel} setShowCreateNewPanel={setShowCreateNewPanel} /> : ""}
              {tabValue === 'devices' && !showCreateNewPanel ?
                <CircuitsList
                  circuitId={selectedDevice?.get('type') === 'circuit' ? selectedDevice?.get('id') : null}
                  controllerIdOverride={selectedDevice?.get('type') === 'controller' ? selectedDevice?.get('id') : null}
                  panelId={selectedPanel?.get("id")}
                  showUnit={!isMobile}
                  hideCircuit={isMobile}
                  hideController={isMobile}
                  showCombinedCircuitAndController={isMobile}
                  showAlarms
                  showModel={!isMobile}
                />
                : ''}

              {tabValue === 'alarms' && !showCreateNewPanel ?
                <AlarmsList
                  fetchAlarms={alarmFunctions.fetch}
                  alarmSelector={alarmFunctions.alarmSelector}
                  alarmLoadingSelector={alarmFunctions.alarmLoadingSelector}
                  numOfAlarmPagesSelector={alarmFunctions.numOfAlarmPagesSelector}
                  numOfAlarmsSelector={alarmFunctions.numOfAlarmsSelector}
                  pagination
                  showAlarmRaisedAt
                  showAlarm
                  showActualTemp
                  showMaintainTemp
                  showActualCurrent
                  showActualGFI
                  showNote
                  showNotification
                  showSetting
                  showValue
                  showDownloadStatsOption
                  panelId={selectedPanel?.get('id')}
                  controllerIdOverride={selectedDevice?.get('type') === 'controller' ? selectedDevice?.get('id') : null}
                  header="Active Alarms"
                  deviceId={selectedDevice?.get('id')}
                  tableId='groupedAlarmsTable'
                /> : ""
              }
              {(tabValue === 'program'|| tabValue === 'create-group') && !showCreateNewPanel ?
                <FeatureFence can={tabValue === 'create-group' ? null : ['batches-and-recipes']}>
                  <PermissionFence can={['edit-device', 'edit-recipe']}>
                    <div style={{ height: '100%', width: '100%' }} >
                      {tabValue === 'program' &&   <div className='recipe-toolbar'>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectedRecipe}
                          defaultValue=''
                          onChange={onRecipeChange}
                          className='recipe-select toolbar-item'
                        >
                          <MenuItem value='none' disabled>
                            Select a Template
                          </MenuItem>
                          {recipeState.map((recipe: any, idx: number) => {
                            return (
                              <MenuItem key={idx} value={recipe.get('id')}>
                                {recipe.get('name', 'Un-Named')}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Button onClick={handleBatchSubmit} loading={applyingRecipeLoading} disabled={(selectedRecipe === 'none') || (!selected?.length)} variant="contained" cta className='apply-batch-button toolbar-item' icon={<DoneIcon style={{ fontSize: 14 }} />} >
                          Apply Template
                        </Button>
                      </div>}

                      {tabValue === 'create-group' && <div>
                        <Button cta  disabled={!selected?.length} onClick={() => setShowCreateModal(true)}>Create Group</Button>
                      </div>}

                      <CircuitsList
                        circuitId={selectedDevice?.get('type') === 'circuit' ? selectedDevice?.get('id') : null}
                        controllerIdOverride={selectedDevice?.get('type') === 'controller' ? selectedDevice?.get('id') : null}
                        panelId={selectedPanel?.get("id")}
                        showUnit={!isMobile}
                        hideCircuit={isMobile}
                        hideController={isMobile}
                        showCombinedCircuitAndController={isMobile}
                        showAlarms
                        showModel={!isMobile}
                        multiSelect
                        selected={selected}
                        updateSelected={updateSelected}
                        isAllSelectedOnPage={isAllSelectedOnPage}
                        isAllSelected={isAllSelected}
                        onSelectAllOnPage={handleSelectAllOnPage}
                        onSelectAll={handleSelectAll}
                        onUpdateFilter={handleFilterUpdated}
                        banner={!selected?.length ? null : '%num_circuits% Devices Selected'}
                      />
                    </div>
                  </PermissionFence>
                </FeatureFence>
                : ''}
            </div>
          </div>
        </Paper>
      </div>
      <CreateGroupModal open={showCreateModal} deviceIds={selected} handleClose={() => setShowCreateModal(false)}/>
    </div>

  );
}
