import './TaskScheduler.scss';
import PropTypes from 'prop-types';

import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Button from 'src/component/UI/Button';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
export default function TaskScheduler (props) {
  const {
    open,
    title,
    handleClose,
    handleSubmit,
    saveButtonText
  } = props;

  const [startDate, setStartDate] = useState(moment());

  const handleSaveScheduleTime = () => {
    handleSubmit(startDate);
    handleClose();
  };

  return (
    <Modal
      data-testid='task-scheduler-modal'
      open={open}
      onClose={handleClose}
      aria-labelledby='task-scheduler-modal-title'
    >
      <div className="modal task-scheduler">
        <h2>{title ? title : `Schedule Task`}</h2>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDateTimePicker orientation="landscape"
            onChange={(date:any) => setStartDate(date)}
            disablePast
            minutesStep={15}
            onClose={handleClose}
            onAccept={handleSaveScheduleTime}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            componentsProps={{ actionBar: { actions: [] } }}
          />
        </LocalizationProvider>
        <div className='button-bar'>
          <Button onClick={handleClose}>Cancel</Button>
          <Button cta onClick={handleSaveScheduleTime}>{saveButtonText}</Button>
        </div>
      </div>
    </Modal>
  );
}

TaskScheduler.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  saveButtonText: PropTypes.string,
};

TaskScheduler.defaultProps = {
  saveButtonText: 'Confirm Date'
};
