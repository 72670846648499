import './InitDeviceModel.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Modal, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { getDevice, getDeviceSyncProgress, getSyncedAddresses, getSyncedIsError, getSyncedTags, getSynchronizingAttemptNumber, getSynchronizingDeviceId, getSynchronizingDeviceTag } from 'src/module/device/selector';
import { closeSyncPopUp, setSettingsModalClose, syncUitDevices } from 'src/module/device/action';
import Button from 'src/component/UI/Button';
import CircularWithValueLabel from 'src/component/UI/CircularProgressWithLabel';
import { pushHistory } from 'src/module/navigation/action';
import { langLookUpText } from 'src/utils/langLookUp';

export default function InitDeviceModel () {
  const dispatch = useDispatch();
  const progress = useSelector(getDeviceSyncProgress);
  const isSyncError = useSelector(getSyncedIsError);
  const createdDevices = useSelector(getSyncedAddresses);
  const tagsOfCreatedDevices = useSelector(getSyncedTags);
  const deviceTag = useSelector(getSynchronizingDeviceTag);
  const deviceId = useSelector(getSynchronizingDeviceId);
  const attemptNumber = useSelector(getSynchronizingAttemptNumber);
  const device = useSelector(getDevice(deviceId));


  useEffect(() => {
    dispatch(setSettingsModalClose(null));
  }, []);

  const closeLoadingModal = () => {
    dispatch(closeSyncPopUp());
    if (progress === 1) {
      dispatch(setSettingsModalClose(null));
      dispatch(pushHistory(`/devices/${deviceTag}`));
    }
  };

  let syncInfoHeader = 'Loading device configuration. This could take a while...';
  if (progress === 2 / 3) {
    syncInfoHeader = 'Devices created, now loading tags';
  } else if (progress === 3 / 3) {
    syncInfoHeader = `${langLookUpText(device.get('device_factory'))} added and confirmed communication with each device. Remaining device configuration will now load in the background.`;
  }
  const errorLoadingTags = tagsOfCreatedDevices && Object.values(tagsOfCreatedDevices.toJS()).includes(null);
  if (errorLoadingTags) {
    syncInfoHeader = ' Device were added but we could not communicate with some circuits. Click finish and manually troubleshoot.';
  }
  const status = (data) => {
    if (progress !== 3 / 3) {
      return <CircularProgress size={10} />;
    }
    if (data) {
      return <CheckCircleIcon color='success' />;
    }
    return <Tooltip
      title={`Unable to communicate with circuit`}
      enterTouchDelay={0}>
      <WarningIcon className='warningStatus' />

    </Tooltip>;
  };
  return (
    <Modal
      data-testid='confirm-modal'
      open={true}
      onClose={() => { return; }}
      aria-labelledby='confirm-modal-title'
    >
      <div className='modal confirm-modal loading init-device-modal'>
        <h2 id='confirm-modal-title'>{`Initializing Device`}</h2>
        <div>
          {isSyncError ? <div style={{ color: 'red' }}>
            {attemptNumber === 0 ? 'Error! Something went wrong when initializing the device. Please try again.' : 'Error! Something went wrong.'}
          </div> : syncInfoHeader}
          {progress > 1 / 3 && createdDevices?.size > 1 ?
            <div className='tableWrap'>
              <table className="loadingDeviceTable">
                <thead>
                  <tr>
                    <th>Address</th>
                    <th>Tag</th>
                    <th>Status</th>
                  </tr>
                </thead>

                {createdDevices?.map((element, key) => {
                  return (
                    <tr key={key}>
                      <td>{element}</td>
                      {progress === 3 / 3 ? <td>{tagsOfCreatedDevices.get(`${element}`)}</td> : <td>-</td>}
                      <td>
                        {status(tagsOfCreatedDevices?.get(`${element}`))}
                      </td>
                    </tr>);
                })}

              </table>
            </div>
            : null}
        </div>
        {!isSyncError ? <CircularWithValueLabel value={progress * 100} isError={errorLoadingTags} />
          : null}

        {progress === 3 / 3 || isSyncError ?
          <div className='button-bar'>
            {errorLoadingTags ? <Button variant="contained" onClick={() => { dispatch(syncUitDevices(deviceId, deviceTag)); }}>{'Retry'}</Button> : null}

            {<Button cta variant="contained" onClick={() => closeLoadingModal()}>{isSyncError ? 'Close' : 'Finish'}</Button>}
          </div>
          : null}
      </div>
    </Modal>
  );
}

InitDeviceModel.propTypes = {
  deviceId: PropTypes.string,
  numTemperatureSensors: PropTypes.number,
  temperatureUnit: PropTypes.string
};

InitDeviceModel.defaultProps = {
  deviceId: null,
  numTemperatureSensors: 0,
  temperatureUnit: 'C'
};
