import './GroupedDevicesMenu.scss';
import { TreeView, TreeItem } from '@mui/lab';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Badge,
  Breadcrumbs,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenLinkIcon from '@mui/icons-material/OpenInNewOutlined';
import { NavLink } from 'react-router-dom';
import {
  getDeviceTree,
  getLoadingDeviceTree,
} from 'src/module/device/selector';
import {
  clearSyncDevicesLoading,
  deletePanel,
  fetchDeviceTree,
  setSettingsModalOpen,
} from 'src/module/device/action';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import EditIcon from '@mui/icons-material/Edit';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from 'src/component/UI/Button';
import { Map } from 'immutable';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddControllerModal from 'src/component/UI/AddControllerModal';
import PermissionFence from 'src/component/PermissionFence';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';
function GroupedDevicesMenu (props) {
  const {
    setSelectedDevice,
    setShowCreateNewPanel,
    setSelectedPanel,
    onControllerClick,
    onPanelClick,
  } = props;
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [optionsNode, setOptionsNode] = useState(null);
  const [panel, setPanel] = useState(null);
  const [controller, setController] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const [panelsCollapsed, setPanelsCollapsed] = useState(true);
  const selectedFilters = useSelector(getDevicesSelectedFilters, (prev, next) => prev?.equals(next));

  useEffect(() => {
    dispatch(clearSyncDevicesLoading(null));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDeviceTree(selectedFilters));
  }, [dispatch, selectedFilters]);

  const deviceTree = useSelector(getDeviceTree, (prev, next) => prev?.equals(next)) || null;
  const isLoading = useSelector(getLoadingDeviceTree);

  const setReduxAddControllerModal = useCallback(() => {
    dispatch(setSettingsModalOpen(null, SettingsModalTypes.ADD_CONTROLLER));
  }, [dispatch]);

  //-------- Click Event Handlers --------//
  const handleClickOnOptions = useCallback((event, node) => {
    event.stopPropagation();
    setOptionsNode(node);
    setAnchorEl(event.currentTarget);
    setShowOptions(true);
  }, []);

  const clickOnNewPanel = useCallback((event) => {
    setSelectedPanel(null);
    setSelectedDevice(null);
    setShowCreateNewPanel(true);
    setPanel(null);
    event.stopPropagation();
  }, [setSelectedPanel, setSelectedDevice, setShowCreateNewPanel]);

  const clickEdit = useCallback((event) => {
    setSelectedPanel(optionsNode);
    setSelectedDevice(null);
    setShowCreateNewPanel(true);
    setShowOptions(false);
    event.stopPropagation();
  }, [optionsNode, setSelectedPanel, setSelectedDevice, setShowCreateNewPanel]);

  const clickDelete = useCallback((event) => {
    setSelectedPanel(optionsNode);
    setShowOptions(false);
    dispatch(deletePanel(optionsNode.get('id')));
    event.stopPropagation();
  }, [dispatch, optionsNode]);

  const breadcrumbClick = useCallback((selectedPanel = null) => {
    setSelectedPanel(selectedPanel);
    setSelectedDevice(null);
    setPanel(selectedPanel);
    setController(null);
    setShowCreateNewPanel(false);
  }, [setSelectedPanel, setSelectedDevice, setShowCreateNewPanel]);

  //-------- Render Helper Methods --------//
  const alarm = useCallback((totalAlarms) => {
    return (
      <div className='alarm-badge' data-testid={`alarmBadge-${totalAlarms}`}>
        {totalAlarms > 0 ? (
          <Badge
            badgeContent={totalAlarms > 99 ? '99+' : totalAlarms}
            color='primary'
          >
            <NotificationsActiveIcon fontSize='large' className='bell' />
          </Badge>
        ) : (
          ''
        )}
      </div>
    );
  }, []);

  const label = useCallback((node, alarmCount) => {
    return (
      <div className='tree-item-custom-label'>
        <span>{node.get('panel_tag') || node.get('tag') || 'Others'}</span>
        {alarm(alarmCount)}
        {node.get('control_panel_id') !== null &&
          node.get('type') !== 'controller' &&
          node.get('type') !== 'circuit' ? (
            <PermissionFence can='edit-device'>
              <div
                onClick={(event) =>
                  handleClickOnOptions(
                    event,
                    Map({
                      id: node.get('control_panel_id'),
                      type: node.get('type'),
                      tag: node.get('panel_tag'),
                      children: node.get('children'),
                    })
                  )
                }
              >
                <MoreVertIcon data-testid='more-options' />
              </div>
            </PermissionFence>
          ) : null}
        {(node.get('type') === 'controller' ||
            node.get('type') === 'circuit') && (
          <NavLink
            to={
              node.get('type') === 'circuit'
                ? `/devices/_/${node.get('tag')}`
                : `/devices/${node.get('tag')}`
            }
            data-testid='device-link'
          >
            <OpenLinkIcon style={{ marginRight: '14px' }} />
          </NavLink>
        )}
      </div>
    );
  }, [alarm, handleClickOnOptions]);

  const controllerLabel = useCallback((node, alarmCount) => {
    return (
      <div className='tree-item-custom-label'>
        <span style={{ marginLeft: '20px' }}>{node.get('tag')}</span>
        {alarm(alarmCount)}
        <NavLink
          to={`/devices/${node.get('tag')}`}
          data-testid='device-link'
        >
          <OpenLinkIcon style={{ marginRight: '14px' }} />
        </NavLink>
      </div>
    );
  }, [alarm]);

  const isVisible = useCallback((tag, children, checkChildren) => {
    const searchValueWithNoSpaces = searchValue?.trim();
    const noSearchValue = searchValue === '' || !searchValueWithNoSpaces;

    const panelTagMatchesSearchInput =
      Boolean(searchValueWithNoSpaces) &&
      tag?.toUpperCase().includes(searchValueWithNoSpaces.toUpperCase());
    const deviceTagMatchesSearchInput = checkChildren
      ? children?.some((child) =>
        Boolean(searchValueWithNoSpaces) &&
        child.get('tag').toUpperCase().includes(searchValueWithNoSpaces.toUpperCase())
      )
      : false;
    const searchMatched =
      !noSearchValue &&
      (panelTagMatchesSearchInput || deviceTagMatchesSearchInput);

    return noSearchValue || searchMatched;
  }, [searchValue]);

  const formatPanelTag = useCallback((panelTag) => {
    return !panelTag || panelTag === 'null' ? 'Others' : panelTag;
  }, []);

  // Memoize the filtered deviceTree
  const deviceTreeFiltered = useMemo(() => {
    return deviceTree?.set(
      deviceTree?.get('panels')?.filter(panel => {
        return isVisible(panel.get('panel_tag'), panel.get('children'), true);
      })
    );
  }, [deviceTree, isVisible]);

  const deviceTreeFinal = useMemo(() => {
    return deviceTreeFiltered?.get('loading') ? Map() : deviceTreeFiltered;
  }, [deviceTreeFiltered]);

  const filterChildren = useCallback((children) => {
    return children.filter(child => isVisible(child.get('tag'), [], false));
  }, [isVisible]);

  const filterPanels = useCallback((panels) => {
    return panels.filter(panel => isVisible(panel.get('panel_tag'), panel.get('children'), true));
  }, [isVisible]);

  const handleChange = useCallback((event, nodeId) => {
    const [id, type, tag, panelID, panelType, panelTag] = nodeId.split("~");
    if (type === 'controller' || type === 'circuit') {
      const node = Map({ id, type, tag });
      onControllerClick?.(node);
      setController(node);
      setPanel(Map({ id: panelID, tag: panelTag, type: panelType }));
    } else if (panelType === 'controller' || panelType === 'circuit') {
      const node = Map({ id: panelID, tag: panelTag, type: panelType });
      onControllerClick?.(node);
      setController(node);
      setPanel(Map({ id: panelID, tag: panelTag, type: panelType }));
    } else if (panelType === 'panel') {
      const node = Map({ id: panelID, tag: panelTag, type: panelType });
      onPanelClick?.(node);
      setPanel(node);
      setController(null);
    }
  }, [onControllerClick, onPanelClick]);

  const renderTree = useCallback((panel, alarmCounts) => {
    const panelId = `${panel.get('control_panel_id')}-panel`;
    const noParentControllerId = panel.get('id');
    const panelIdForAlarms = panel.get('control_panel_id') ? panelId : noParentControllerId;
    return (
      <TreeItem
        key={panel.get('control_panel_id') || noParentControllerId}
        nodeId={`${panel.get('control_panel_id') ?? noParentControllerId}~${panel.get('type')}~${panel.get('panel_tag') || panel.get('tag')}`}
        label={label(panel, alarmCounts.get(panelIdForAlarms))}
      >
        {
          filterChildren(panel.get('children', Map())).map((device) =>
            <TreeItem
              key={`${device.get('id')}childview`}
              nodeId={`${device.get('id')}~${device.get('type')}~${device.get('tag')}~${panel.get('control_panel_id') ?? noParentControllerId}~${panel.get('type')}~${panel.get('panel_tag') || panel.get('tag')}`}
              label={controllerLabel(device, alarmCounts.get(device.get('id')))}
            />
          )
        }
      </TreeItem>
    );
  }, [label, controllerLabel, filterChildren]);

  return (
    <div className='device-menu-container' data-testid='grouped-devices-menu'>
      <PermissionFence can='edit-device'>
        <AddControllerModal />
      </PermissionFence>

      {isMobile ? (
        <h3
          className='expandable'
          onClick={() => setPanelsCollapsed(!panelsCollapsed)}
        >
          Panels &nbsp;
          <span className='expand-arrow'>
            {panelsCollapsed ? '▼' : '▲'}
          </span>
        </h3>
      ) : null}
      {
        (isLoading && !isMobile) ||
          (isLoading && isMobile && !panelsCollapsed) ? (
            <div className='loading'>
              <LogoSpinner size={100} />
            </div>
          ) : (
            ((!panelsCollapsed && isMobile) || !isMobile) && (
              <>
                <div className='device-menu-search'>
                  <TextField
                    data-testid='text-field-panel-search'
                    id='text-field-panel-search'
                    placeholder='Search tags'
                    variant='standard'
                    margin='dense'
                    value={searchValue || ''}
                    className='search-field'
                    sx={{ width: '30vw' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </div>
                <div className='breadcrumb-contrainer'>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize='large' />}
                    aria-label='breadcrumb'
                  >
                    <Typography
                      className='breadCrumbs'
                      onClick={() => breadcrumbClick()}
                    >
                      All
                    </Typography>
                    {panel && (
                      <Typography
                        className='breadCrumbs'
                        onClick={() => breadcrumbClick(panel)}
                      >
                        {formatPanelTag(panel.get('tag'))}
                      </Typography>
                    )}
                    {controller && (
                      <Typography className='breadCrumbs'>
                        {controller.get('tag')}
                      </Typography>
                    )}
                  </Breadcrumbs>
                </div>
                <TreeView
                  onNodeSelect={handleChange}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    maxHeight: isMobile ? '300px' : '60vh',
                    flexGrow: 1,
                    maxWidth: isMobile ? 900 : 400,
                    overflowY: 'auto',
                  }}
                >
                  {deviceTreeFinal && deviceTreeFinal.get('panels')
                    ? filterPanels(deviceTreeFinal.get('panels')).map((panel) =>
                      renderTree(
                        panel,
                        deviceTreeFinal.get('alarmCounts', Map())
                      )
                    )
                    : null}
                </TreeView>

                <PermissionFence can='edit-device'>
                  <div className='actionButtons'>
                    <Button
                      variant='contained'
                      cta
                      onClick={clickOnNewPanel}
                      icon={<AddIcon style={{ fontSize: 14 }} />}
                    >
                      Add Panel
                    </Button>
                    <Button
                      onClick={setReduxAddControllerModal}
                      variant='contained'
                      cta
                      className='addNewController'
                      icon={<AddIcon style={{ fontSize: 14 }} />}
                    >
                      Add Controller
                    </Button>
                  </div>

                  <Popover
                    open={showOptions}
                    anchorEl={anchorEl}
                    onClose={() => setShowOptions(false)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuList className='menuList'>
                      <MenuItem onClick={clickEdit}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={clickDelete}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Popover>

                </PermissionFence>
              </>
            )
          )
      }
    </div>
  );
}

GroupedDevicesMenu.propTypes = {
  setSelectedDevice: PropTypes.func,
  setShowCreateNewPanel: PropTypes.func,
  setSelectedPanel: PropTypes.func,
  onPanelClick: PropTypes.func,
  onControllerClick: PropTypes.func,
};

GroupedDevicesMenu.defaultProps = {
  setSelectedDevice: null,
  setShowCreateNewPanel: null,
  setSelectedPanel: null,
};

const MemoizedGroupedDevicesMenu = React.memo(GroupedDevicesMenu);

Object.assign(MemoizedGroupedDevicesMenu, {
  propTypes: GroupedDevicesMenu.propTypes,
  defaultProps: GroupedDevicesMenu.defaultProps,
});

// 5. Export the memoized component
export default MemoizedGroupedDevicesMenu;
