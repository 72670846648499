export const V1_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const V2_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[2][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const V3_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[3][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const V4_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const V5_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const NIL_UUID = /^[0]{8}-[0]{4}-[0]{4}-[0]{4}-[0]{12}$/i;
export const ANY_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const v1 = (uuid = '') => V1_UUID.test(uuid);
export const v2 = (uuid = '') => V2_UUID.test(uuid);
export const v3 = (uuid = '') => V3_UUID.test(uuid);
export const v4 = (uuid = '') => V4_UUID.test(uuid);
export const v5 = (uuid = '') => V5_UUID.test(uuid);
export const nil = (uuid = '') => NIL_UUID.test(uuid);
export const anyNonNil = (uuid = '') => ANY_UUID.test(uuid);

export default {
  v1,
  v2,
  v3,
  v4,
  v5,
  nil,
  anyNonNil
};
