import { Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as weatherActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  weatherForecasts: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('weatherForecasts', Map())
  );
}

export const weatherForecastReducer = createTypedReducer(initialState, {
  [weatherActions.FETCH_WEATHER_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      return nextState.setIn(['weatherForecasts', 'data'], action.weather);
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default weatherForecastReducer;
