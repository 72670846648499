import { addSelectedFiltersToQuery } from 'src/utils/utils';
import { request } from '../api';

export const getFreezeLogs = (action) => {
  const {
    selectedFilters,
    paginationDetails,
    includeResolved
  } = action;

  const {
    filter,
    sort,
  } = paginationDetails;

  const page = (isNaN(action.paginationDetails.page) ) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails.pageSize)) ? null : action.paginationDetails.pageSize;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.filter = filter;
  }


  query.includeResolved = includeResolved;

  return request(action, {
    payload: {
      url: `/freeze-logs`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const addFreezeLog = (action, freezeLog) => {
  return request(action, {
    payload: {
      url: `/freeze-logs`,
      options: {
        method: 'POST',
        data: freezeLog
      },
    }
  });
};

export const updateFreezeLog = (action, freezeLogId, freezeLog) => {
  return request(action, {
    payload: {
      url: `/freeze-logs/${freezeLogId}`,
      options: {
        method: 'PATCH',
        data: freezeLog
      },
    }
  });
};
