import { createSelector } from 'reselect';
import { Map } from 'immutable';

export const getUserState = (state) =>
  state.user;

export const getUsers = createSelector(
  [getUserState],
  (user) => {
    return user.get('users');
  }
);

export const getIsInactive = route => createSelector(
  [getUserState],
  (user) => {
    return user.get('isInactive', []).includes(route);
  }
);

export const getUserByEmail = (email) => createSelector(
  [getUserState],
  (user) => {
    const users = user.get('users');
    let userFound = Map();
    users.map(rec => {
      if (rec?.get('email') === email) {
        userFound = rec;
      }
    });
    return userFound;
  }
);
