import { request } from '../api';

export const addController = (action, controller) => {
  return request(action, {
    payload: {
      url: `/eht-controllers`,
      options: {
        method: 'POST',
        data: controller
      },
    }
  });
};

export const deleteController = (action, controllerId) => {
  return request(action, {
    payload: {
      url: `/eht-controllers/${controllerId}`,
      options: {
        method: 'DELETE',
      },
    }
  });
};

export const updateController = (action, controllerId, controller) => {
  return request(action, {
    payload: {
      url: `/eht-controllers/${controllerId}`,
      options: {
        method: 'PATCH',
        data: controller
      }
    }
  });
};
