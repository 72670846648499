import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { setSelectedUnit, SET_SELECTED_UNIT }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_UNIT`,
  ['unit', yup.string().nullable()]
);

export const { setSelectedSystem, SET_SELECTED_SYSTEM }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_SYSTEM`,
  ['system', yup.string().nullable()]
);

export const { setSelectedPriority, SET_SELECTED_PRIORITY }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_PRIORITY`,
  ['priority', yup.string().nullable()]
);

export const { setSelectedCommodity, SET_SELECTED_COMMODITY }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_COMMODITY`,
  ['commodity', yup.string().nullable()]
);

export const { setSelectedLocation, SET_SELECTED_LOCATION }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_LOCATION`,
  ['location', yup.string().nullable()]
);

export const { setSelectedController, SET_SELECTED_CONTROLLER }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_CONTROLLER`,
  ['controller', yup.string().nullable()]
);
export const { setSelectedDeadLeg, SET_SELECTED_DEAD_LEG }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_DEAD_LEG`,
  ['deadLeg', yup.string().nullable()]
);
export const { setSelectedDeviceGroup, SET_SELECTED_DEVICE_GROUP }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_DEVICE_GROUP`,
  ['deviceGroup', yup.string().nullable()]
);
export const { setSelectedSetting, SET_SELECTED_SETTING }: any = createTypedAction(
  `${PREFIX}SET_SELECTED_SETTING`,
  ['setting', yup.string().nullable()]
);

export const { fetchUnits, FETCH_UNITS }: any = createTypedAction(
  `${PREFIX}FETCH_UNITS`
);

export const { fetchUnitsSuccess, FETCH_UNITS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_UNITS_SUCCESS`,
  ['units', yup.immutable().list().required()]
);

export const { fetchUnitsFailed, FETCH_UNITS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_UNITS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchSystems, FETCH_SYSTEMS }: any = createTypedAction(
  `${PREFIX}FETCH_SYSTEMS`
);

export const { fetchSystemsSuccess, FETCH_SYSTEMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_SYSTEMS_SUCCESS`,
  ['systems', yup.immutable().list().required()]
);

export const { fetchSystemsFailed, FETCH_SYSTEMS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_SYSTEMS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchCommodities, FETCH_COMMODITIES }: any = createTypedAction(
  `${PREFIX}FETCH_COMMODITIES`
);

export const { fetchCommoditiesSuccess, FETCH_COMMODITIES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_COMMODITIES_SUCCESS`,
  ['commodities', yup.immutable().list().required()]
);

export const { fetchCommoditiesFailed, FETCH_COMMODITIES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_COMMODITIES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchLocations, FETCH_LOCATIONS }: any = createTypedAction(
  `${PREFIX}FETCH_LOCATIONS`
);

export const { fetchLocationsSuccess, FETCH_LOCATIONS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_LOCATIONS_SUCCESS`,
  ['locations', yup.immutable().list().required()]
);

export const { fetchLocationsFailed, FETCH_LOCATIONS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_LOCATIONS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchPriorities, FETCH_PRIORITIES }: any = createTypedAction(
  `${PREFIX}FETCH_PRIORITIES`
);

export const { fetchPrioritiesSuccess, FETCH_PRIORITIES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_PRIORITIES_SUCCESS`,
  ['priorities', yup.immutable().list().required()]
);
export const { fetchDeviceGroupsOptionsSuccess, FETCH_DEVICE_GROUPS_OPTIONS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUPS_OPTIONS_SUCCESS`,
  ['deviceGroups', yup.immutable().list().required()]
);

export const { fetchPrioritiesFailed, FETCH_PRIORITIES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_PRIORITIES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchControllers, FETCH_CONTROLLERS }: any = createTypedAction(
  `${PREFIX}FETCH_CONTROLLERS`
);

export const { fetchControllersSuccess, FETCH_CONTROLLERS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CONTROLLERS_SUCCESS`,
  ['controllers', yup.immutable().list().required()]
);

export const { fetchControllersFailed, FETCH_CONTROLLERS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CONTROLLERS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
