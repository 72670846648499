import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchNotes, FETCH_NOTES }: any = createTypedAction(
  `${PREFIX}FETCH_NOTES`,
  ['note', yup.string()],
  ['selectedFilters', yup.immutable().map().nullable()],
  ['paginationDetails', yup.object().nullable()],
  ['includeArchived', yup.boolean().required().default(false)],
);

export const { fetchNotesSuccess, FETCH_NOTES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_NOTES_SUCCESS`,
  ['notes', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['totalPages', yup.number().required()],
  ['noteType', yup.string()]
);

export const { fetchNotesFailed, FETCH_NOTES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_NOTES_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['noteType', yup.string()]
);

export const { updateNote, UPDATE_NOTE, updateNoteQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_NOTE`,
  (state) => {
    return `update note ${JSON.stringify(state.toJS())}`;
  },
  ['noteId', yup.string().required()],
  ['note', yup.immutable().map().required()]
);

export const { updateNoteSuccess, UPDATE_NOTE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_NOTE_SUCCESS`,
  ['note', yup.immutable().map().required()]
);

export const { updateNoteFailed, UPDATE_NOTE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_NOTE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { addNote, ADD_NOTE, addNoteQueueFormatter }: any = createTypedAction(
  `${PREFIX}ADD_NOTE`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.note.get('device_id'), 'record', 'info', 'tag']);
    const parent_id = devices?.getIn([state.note.get('device_id'), 'record', 'info', 'parent_id']);
    const parentTag = devices?.getIn([parent_id, 'record', 'info', 'tag']);
    return `${state.note.get('subject') ? `'${state.note.get('subject')}'`: `empty`} note created for ${parentTag || ''}${ parentTag ? '/' : ''}${deviceTag || ''}`;
  },
  ['note', yup.immutable().map().required()]
);

export const { addNoteSuccess, ADD_NOTE_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_NOTE_SUCCESS`,
  ['note', yup.immutable().map().required()]
);

export const { addNoteFailed, ADD_NOTE_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_NOTE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
export const { loadNotesFromDevice, LOAD_NOTES_FROM_DEVICE }: any = createTypedAction(
  `${PREFIX}LOAD_NOTES_FROM_DEVICE`,
  ['notes', yup.immutable().list().required()]
);
