import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './TestingReport.scss';
import Modal from '@mui/material/Modal';

import { Box, Tabs, Tab } from '@mui/material';
import AlarmChecksMatrix from './AlarmChecksMatrix';
import CheckSheet from './CheckSheet';
import Button from 'src/component/UI/Button';
import { getDeviceById } from 'src/module/device/selector';
import AtControlPanel from './AtControlPanel';
import FinalizeAndSignOff from './FinalizeAndSignOff';
import { Map, List, fromJS } from 'immutable';
import { getAbilities } from 'src/module/authentication/selector';
import { fetchCheckSheet, saveCheckSheet, updateCheckSheet } from 'src/module/checkSheet/action';
import { getCheckSheetReport, getCheckSheetTasks, getCheckSheetTasksLoading, getSaveCheckSheetReportLoading } from 'src/module/checkSheet/selector';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';

export default function TestingReport (props) {
  if (!props.open) {
    return null;
  }
  const dispatch = useDispatch();
  const {
    deviceId,
    reportId
  } = props;
  const [tabValue, setTabValue] = useState("1");
  const tasks = useSelector(getCheckSheetTasks('alarm-check-sheet'));
  const report = useSelector(getCheckSheetReport(reportId));
  const device = useSelector(getDeviceById(deviceId));
  const isSaveLoading = useSelector(getSaveCheckSheetReportLoading('alarm-check-sheet'));
  const isLoadingTasks = useSelector(getCheckSheetTasksLoading);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const abilities: any = useSelector(getAbilities);
  const isPhoneView = useMediaQuery(`(max-width: ${variables.phoneWidth})`);
  const canEdit = abilities.includes('edit-check-sheet-report');
  const readOnly = Boolean(reportId) && !canEdit;

  const tasksList: any = [];
  tasks.forEach(task => {
    tasksList.push({
      task_id: task.key,
      status: null,
      comment: ''
    });
  });
  const atControlPanelTable = [
    {
      title: 'EHT Cable',
      columns: ['Tag', 'Voltage(V)', 'Current(A)', 'IR', 'Resistance']
    },
    {
      title: 'RTD (If Required)',
      columns: ['RTD 1 R', 'RTD 2 R', 'RTD 3 R', 'RTD 4 R']
    },
    {
      title: 'SSR',
      columns: ['Control Voltage (ON)', 'Output Voltage (ON)', 'Control Voltage (Off)', 'Output Voltage (Off)']
    }
  ];
  const atJunctionTable = [
    {
      title: '',
      key: 'main',
      columns: ['Tag', 'Voltage(V)', 'Current(A)', 'IR', 'Capacitance (F)', 'Resistance (Ω)']
    },
    {
      title: 'At RTD 1',
      key: 'rtd1',
      columns: ['Resistance (Ω)']
    },
    {
      title: 'At RTD 2',
      key: 'rtd2',
      columns: ['Resistance (Ω)']
    },
    {
      title: 'At RTD 3',
      key: 'rtd3',
      columns: ['Resistance (Ω)']
    },
    {
      title: 'At RTD 4',
      key: 'rtd4',
      columns: ['Resistance (Ω)']
    }
  ];
  const atJunctionRowObject = {
  };
  atJunctionTable.map(x => x.columns.map(col => {
    const title = x.key === 'main' ? col : `${x.key}-${col}`;
    atJunctionRowObject[title] = '';
    return;
  }));

  const atControlPanelOutput = {};
  atControlPanelTable.forEach((section) => {
    atControlPanelOutput[section.title] = {};
    section.columns.forEach(test => {
      atControlPanelOutput[section.title][test] = '';
    });
  });
  const emptyResults = Map({
    info: {
      type: 'alarm-check-sheet',
      deviceId,
      alarms: List([])
    },
    checkSheet: fromJS(tasksList),
    extras: Map({
      atControlPanel: atControlPanelOutput,
      atJunction: fromJS([atJunctionRowObject])
    }),
    signOff: Map({
      recommendations: '',
    })
  });
  const [allResults, setAllResults] = useState(tasks.size !== 0 ? emptyResults: null);

  useEffect(() => {
    if (reportId) {
      dispatch(fetchCheckSheet(reportId, 'alarm-check-sheet'));
    } else {
      setAllResults(emptyResults);
    }
  }, [reportId]);

  useEffect(() => {
    if (reportId) {
      setAllResults(Map(report));
    }
  }, [report, reportId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const next = () => {
    const next = parseInt(tabValue) + 1;
    setTabValue(next.toString());
  };
  const previous = () => {
    const next = parseInt(tabValue) - 1;
    setTabValue(next.toString());
  };
  const submit = () => {
    if (reportId) {
      dispatch(updateCheckSheet(allResults, deviceId, 'alarm-check-sheet'));
    }
    else {
      dispatch(saveCheckSheet('alarm-check-sheet', allResults, deviceId));
    }
  };
  const change = (tab, value) => {
    setAllResults(prevAllResults => {
      const updatedResults = prevAllResults.setIn(tab, value);
      return updatedResults;
    });
  };


  const nextButton = () => {
    return <Button className='next-button' onClick={next} cta>Next</Button>;
  };

  const submitButton = () => {
    return <Button onClick={submit} disabled={readOnly} loading={isSaveLoading} cta>{`${ reportId ? 'Save' : 'Submit' }`}</Button>;
  };
  return (
    <Modal open={props.open} onClose={props.handleClose} style={{ height: '90vh' }} >
      <div className={`modal testing-report-modal${isPhoneView ? ' modal-no-padding-sides' : ''}`}>
        {!isMobile ? <h2>EHT Alarm Troubleshooting Check Sheet <b>({device.get('tag')})</b></h2> : null}
        {isLoadingTasks? <div style={{ margin: 'auto', marginTop: '200px', width: '100px' }}><LogoSpinner size={100} /></div> :
          <>
            <Box sx={{ width: '100%', marginBottom: '20px' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor='primary'
                indicatorColor='primary'
                aria-label="testing-report-tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab value="1" label={<span className='tab-label-size'>Alarm Checks Matrix</span>} />
                <Tab value="2" label={<span className='tab-label-size'>Check Sheet</span>} />
                <Tab value="3" label={<span className='tab-label-size'>At Device</span>} />
                <Tab value="4" label={<span className='tab-label-size'>Sign Off and Comments</span>} />

              </Tabs>
            </Box>
            {tabValue === '1' ? <AlarmChecksMatrix readOnly={readOnly} alarms={(allResults.getIn(['info', 'alarms'], List([])) as any)?.map(x => x)} onChange={change} /> : null}
            {tabValue === '2' ? <CheckSheet readOnly={readOnly} onChange={change} data={allResults.get('checkSheet')} checkSheetType="alarm-check-sheet" /> : null}
            {tabValue === '3' ? <AtControlPanel readOnly={readOnly} data={allResults.get('extras')} onChange={change} tableInfo={atControlPanelTable} atJunctionTable={atJunctionTable} /> : null}
            {tabValue === '4' ? <FinalizeAndSignOff readOnly={readOnly} data={allResults.get('signOff')} onChange={change} /> : null}

            <div className='button-bar stay-at-bottom'>
              <Button onClick={() => props.handleClose()}>Cancel</Button>

              {tabValue !== '1' ? <Button onClick={previous} >Previous</Button> : null}
              {tabValue === '4' ?
                submitButton()
                :
                nextButton()
              }
            </div>
          </>
        }

      </div>
    </Modal>
  );
}

TestingReport.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  deviceId: PropTypes.string,
  reportId: PropTypes.string,
};
