import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FreezeLogsList from 'src/component/UI/FreezeLogsList';
import Button from 'src/component/UI/Button';
import FreezeLogsIcon from '@mui/icons-material/AcUnitOutlined';
import { fromJS } from 'immutable';

import UnitSelector from 'src/component/UI/UnitSelector';
import { addFreezeLog } from 'src/module/freezeLog/action';
import PermissionFence from 'src/component/PermissionFence';
import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';
import { fetchDeviceTags, clearDeviceTags, setSettingsModalOpen, setSettingsModalClose } from 'src/module/device/action';
import { getCircuitTags, getSettingsModal } from 'src/module/device/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import { isWhitelabel } from 'src/utils/utils';

export default function FreezeLogScreen () {
  const [circuitId, setCircuitId] = useState(null);
  const dispatch = useDispatch();

  const settingsModal = useSelector(getSettingsModal(null));

  const makeToggleFreezeLogsModal = () => {
    return () => {
      toggleFreezeLogsModal();
    };
  };

  useEffect(() => {
    dispatch(setSettingsModalClose(null));
  }, []);

  const toggleFreezeLogsModal = () => {
    if (!settingsModal?.get('isOpen')) {
      dispatch(setSettingsModalOpen(null, SettingsModalTypes.ADD_FREEZE_LOG));
    }
    else {
      dispatch(setSettingsModalClose(null));
    }
  };

  const handleFreezeLogSubmit = (rec: any) => {
    rec = rec.set('circuit_id', circuitId);
    dispatch(addFreezeLog(rec));
    setCircuitId(null);
  };

  const fetchOptions = (e) => {
    if (e.target.value && e.target.value.length >= 3) {
      dispatch(clearDeviceTags());
      dispatch(fetchDeviceTags(e.target.value));
    }
  };
  const onValueChange = (circuit) => {
    setCircuitId(circuit.id);
  };

  const makeFreezeLogModal = () => {
    return (
      <SettingsModal
        open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.ADD_FREEZE_LOG}
        handleClose={makeToggleFreezeLogsModal()}
        handleSubmit={handleFreezeLogSubmit}
        title='Add Freeze Log'
        loading={settingsModal?.get('loading')}
        fields={
          fromJS({
            circuit_id: {
              type: 'autoCompleteSelect',
              label: 'Circuit',
              options: [],
              deviceType: 'circuit',
              optionsSelector: getCircuitTags,
              fetchOptions,
              formatOption: (option) => (
                {
                  label: `${option?.get('controller_tag') || 'N/A'}/${option?.get('tag')}`,
                  value: option?.get('id')
                }
              ),
              onValueChange,
            },
            freeze_issue: {
              type: 'text',
              label: 'Freeze Issue',
              errorText: 'Required'
            },
            equipment: {
              type: 'text',
              label: 'Equipment',
              errorText: 'Required',
            },
            description: {
              type: 'text',
              label: 'Description',
              errorText: 'Required',
            },
          })
        }
      />
    );
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='freeze-log-screen'>
      <PermissionFence can={['edit-freeze-log']}>
        {makeFreezeLogModal()}
      </PermissionFence>

      <Helmet>
        <title>{title} - Freeze Log</title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper>
            <UnitSelector />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Paper>
            <FreezeLogsList />

            <PermissionFence can='edit-freeze-log'>
              <div className='button-bar'>
                <Button onClick={toggleFreezeLogsModal} icon={<FreezeLogsIcon style={{ fontSize: 14 }} />} cta >Add Freeze Log</Button>
              </div>
            </PermissionFence>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
