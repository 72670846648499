import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as api from '../../service/api';
import * as devicesApi from '../../service/api/devices';
import * as reportSchedulesApi from '../../service/api/reportSchedules';
import * as facilityActions from './action';
import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * facilityRoot () {
  yield takeLatest(facilityActions.FETCH_INTEGRITY_CHECKS, fetchIntegrityChecks);
  yield takeLatest(facilityActions.FETCH_METRICS, fetchMetrics);
  yield takeLatest(facilityActions.SEND_REPORT, sendReport);
  yield takeLatest(facilityActions.ADD_REPORT_SCHEDULE, addReportSchedule);
  yield takeLatest(facilityActions.UPDATE_REPORT_SCHEDULE, updateReportSchedule);
  yield takeLatest(facilityActions.DELETE_REPORT_SCHEDULE, deleteReportSchedule);
  yield takeLatest(facilityActions.FETCH_REPORT_SCHEDULES, fetchReportSchedules);
}

function * fetchIntegrityChecks (action) {
  try {
    const integrityChecks = yield call(devicesApi.getIntegrityChecks, action);
    yield put(facilityActions.fetchIntegrityChecksSuccess(integrityChecks));
  } catch (err) {
    yield put(facilityActions.fetchIntegrityChecksFailed(Map(err)));
  }
}
function * fetchMetrics (action) {
  try {
    const metrics = yield call(api.getMetrics, action);
    yield put(facilityActions.fetchMetricsSuccess(metrics));
  } catch (err) {
    yield put(facilityActions.fetchMetricsFailed(Map(err)));
  }
}
function * sendReport (action) {
  try {
    const integrityChecks = yield call(api.sendReport, action, action.message);
    yield put(facilityActions.sendReportSuccess(integrityChecks));
  } catch (err) {
    yield put(facilityActions.sendReportFailed(Map(err)));
  }
}



function * fetchReportSchedules (action) {
  try {
    const reportSchedules = yield call(reportSchedulesApi.getReportSchedules, action);

    yield put(facilityActions.fetchReportSchedulesSuccess(reportSchedules.get('data')));
  } catch (err) {
    yield put(facilityActions.fetchReportSchedulesFailed(Map(err)));
  }
}

function * addReportSchedule (action) {
  try {
    const forcedOnCircuit = yield call(reportSchedulesApi.addReportSchedule, action);
    yield put(facilityActions.addReportScheduleSuccess(forcedOnCircuit));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error adding the report schedule'), err.response.status);
    yield put(facilityActions.addReportScheduleFailed(Map(err)));
  }
}

function * updateReportSchedule (action) {
  try {
    const reportSchedule = yield call(reportSchedulesApi.updateReportSchedule, action);
    yield put(facilityActions.updateReportScheduleSuccess(reportSchedule));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating report schedule'), err.response.status);
    yield put(facilityActions.updateReportScheduleFailed(Map(err)));
  }
}

function * deleteReportSchedule (action) {
  try {
    yield call(reportSchedulesApi.deleteReportSchedule, action);
    yield put(facilityActions.deleteReportScheduleSuccess(action.reportScheduleId));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error removing report schedule'), err.response.status);
    yield put(facilityActions.deleteReportScheduleFailed(Map(err)));
  }
}
