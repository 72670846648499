import React from 'react';
import PropTypes from 'prop-types';
import HelpCircleIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';

export default function ToolTip (props) {
  const {
    helpText,
  } = props;

  return <Tooltip className='helper-tool-tip' title={helpText} enterTouchDelay={0} leaveTouchDelay={5000}>
    <HelpCircleIcon className='help-icon' style={{ fontSize: 16 }} />
  </Tooltip>;
}

ToolTip.propTypes = {
  helpText: PropTypes.string,
};

ToolTip.defaultProps = {
  helpText: '',
};
