// eslint-disable-next-line
import React from 'react';
import PropTypes from 'prop-types';

import { getAbilities } from 'src/module/authentication/selector';
import { useSelector } from 'react-redux';

export default function PermissionFence (props) {
  const {
    can,
    noPermissionComponent,
    children
  } = props;

  const abilities: any = useSelector(getAbilities);

  let render = true;

  if (typeof can === 'string') {
    render = abilities.includes(can);
  } else {
    for (let i = 0; i < can.length; i++) {
      if (!abilities.includes(can[0])) {
        render = false;
        break;
      }
    }
  }

  if (render) {
    return  children;
  }
  if (noPermissionComponent) {
    return noPermissionComponent;
  }
  return null;
}

PermissionFence.propTypes = {
  can: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  noPermissionComponent: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
};

PermissionFence.defaultProps = {
};
