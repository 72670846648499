import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from 'prop-types';
import './MultipleSelect.scss';

export default function MultipleSelect (props) {
  const {
    options,
    onChangeFn,
    label,
    preSelected,
    disabled
  } = props;
  const [selected, setSelected] = useState(preSelected ?? []);
  useEffect(() => {
    setSelected(preSelected);
  }, [preSelected]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      onChangeFn(selected.length === options.length ? [] : options);

      return;
    }
    setSelected(value);
    onChangeFn(value);
  };


  return (
    <FormControl className={'formControl'}>
      <InputLabel id="mutiple-select-label">{label}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        className="multiple-select"
        multiple
        value={selected}
        onChange={handleChange}
        disabled={disabled}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          variant: "menu"
        }}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? 'selectedAll' : ""
          }}

        >
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            className={'selectAllText'}
            primary="Select All"
          />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
MultipleSelect.propTypes = {
  options: PropTypes.array,
  onChangeFn: PropTypes.func,
  label: PropTypes.string,
  preSelected: PropTypes.array,
  disabled: PropTypes.bool
};

MultipleSelect.defaultProps = {
};
