import { takeLatest, takeLeading, put, call, select } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as alarmsApi from '../../service/api/alarms';
import * as alarmActions from './action';
import * as devicesActions from '../device/action';
import * as authSelector from '../authentication/selector';
import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * alarmRoot () {
  yield takeLatest(alarmActions.FETCH_ALARM_STATS, fetchAlarmStats);
  // yield takeLatest(alarmActions.FETCH_ALARMS, fetchAlarms);
  yield takeLatest(alarmActions.FETCH_ACTIVE_ALARMS, fetchActiveAlarms);
  yield takeLatest(alarmActions.FETCH_ARCHIVED_ALARMS, fetchArchivedAlarms);

  yield takeLeading(alarmActions.FETCH_ALARM_HISTORICAL_SUMMARY, fetchAlarmHistoricalSummary);
  yield takeLatest(alarmActions.FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION, fetchDevicesInAlarmWithoutNotification);
  yield takeLatest(alarmActions.VALIDATE_ALARMS, validateAlarms);
  yield takeLatest(alarmActions.DISMISS_ALARMS, dismissAlarms);
  yield takeLatest(alarmActions.FETCH_ALARMS_EXPORT, fetchAlarmsExport);
  yield takeLatest(alarmActions.DISMISS_ALARMS_BY_GROUP, dismissAlarmsByGroup);
  yield takeLatest(alarmActions.UNDO_ALARMS, undo);
  yield takeLatest(alarmActions.ACKNOWLEDGE_ALARMS, acknowledgeAlarms);
}

function * fetchAlarmStats (action) {
  try {
    yield put(alarmActions.clearAlarmStats());
    const alarmStats = yield call(alarmsApi.getAlarmStats, action);
    yield put(alarmActions.fetchAlarmStatsSuccess(alarmStats));
  } catch (err) {
    yield put(alarmActions.fetchAlarmStatsFailed(Map(err)));
  }
}

function * fetchActiveAlarms (action) {
  try {
    const alarms = yield call(alarmsApi.getAlarms, action, true);
    yield put(alarmActions.fetchAlarmsSuccess(alarms.get('data', List()), alarms.get('total'), alarms.get('totalPages', 1)));
    yield put(alarmActions.fetchActiveAlarmsSuccess(alarms.get('data', List()), alarms.get('total'), alarms.get('totalPages', 1)));

  } catch (err) {
    yield put(alarmActions.fetchAlarmsFailed(Map(err)));
  }
}

function * fetchArchivedAlarms (action) {
  try {
    const alarms = yield call(alarmsApi.getAlarms, action, false);
    yield put(alarmActions.fetchAlarmsSuccess(alarms.get('data', List()), alarms.get('total'), alarms.get('totalPages', 1)));
    yield put(alarmActions.fetchArchivedAlarmsSuccess(alarms.get('data', List()), alarms.get('total'), alarms.get('totalPages', 1)));

  } catch (err) {
    yield put(alarmActions.fetchAlarmsFailed(Map(err)));
  }
}


function * fetchAlarmHistoricalSummary (action) {
  try {
    const alarmHistory = yield call(alarmsApi.getAlarmHistory, action);
    yield put(alarmActions.fetchAlarmHistoricalSummarySuccess(alarmHistory));
  } catch (err) {
    yield put(alarmActions.fetchAlarmHistoricalSummaryFailed(Map(err)));
  }
}

function * fetchDevicesInAlarmWithoutNotification (action) {
  try {
    const devicesInAlarmWithoutNotification = yield call(alarmsApi.getDevicesInAlarmWithoutNotification, action );
    let criticalAlarmsWithoutNotification = 0;
    devicesInAlarmWithoutNotification.forEach((device) => {
      if (device.get('criticality') > 0) {
        criticalAlarmsWithoutNotification++;
      }
    });
    yield put(alarmActions.fetchDevicesInAlarmWithoutNotificationSuccess(devicesInAlarmWithoutNotification, criticalAlarmsWithoutNotification));
  } catch (err) {
    yield put(alarmActions.fetchDevicesInAlarmWithoutNotificationFailed(Map(err)));
  }
}

function * validateAlarms (action) {
  try {
    yield call(alarmsApi.validateAlarms, action, action.notificationNumber, action.alarmIds, action.notificationDescription, action.deviceId);
    yield put(alarmActions.validateAlarmsSuccess());
    yield put(alarmActions.invalidateDeviceInCriticalAlarm(action.rowId));
    yield put(alarmActions.fetchDevicesInAlarmWithoutNotification(
      action.alarmListFilters?.get('unit'),
      action.alarmListFilters?.get('deadleg'),
      action.alarmListFilters?.get('system'),
      action.alarmListFilters?.get('commodity'),
      action.alarmListFilters?.get('priority'),
      action.alarmListFilters?.get('controllerId')
    ));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error validating alarm'), err.response.status);
    yield put(alarmActions.validateAlarmsFailed(Map(err)));
  }
}

function * dismissAlarms (action) {
  try {
    yield call(alarmsApi.dismissAlarms, action, action.note, action.parentId, action.childId, action.alarmIds, action.expiredAt, action.subject, action.deviceId);
    if (action.alarmListFilters && action.pageInfo) {
      const page = action.pageInfo;

      yield put(alarmActions.fetchActiveAlarms(action.alarmListFilters, Map({}), page.page, page.pageSize, page.filter, page.sort));
      yield put(alarmActions.fetchDevicesInAlarmWithoutNotification(
        action.alarmListFilters?.unit,
        action.alarmListFilters?.deadleg,
        action.alarmListFilters?.system,
        action.alarmListFilters?.commodity,
        action.alarmListFilters?.priority,
        action.alarmListFilters?.controllerId
      ));
    }

    yield put(alarmActions.dismissAlarmsSuccess(action.alarmIds));
    if (action.rowId >= 0) {
      yield put(alarmActions.invalidateDeviceInCriticalAlarm(action.rowId));
    }
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error resetting alarm'), err.response.status);
    yield put(alarmActions.dismissAlarmsFailed(Map(err)));
  }
}

function * acknowledgeAlarms (action) {
  try {
    yield call(alarmsApi.acknowledgeAlarms, action, action.alarmIds);
    if (action.alarmListFilters && action.pageInfo) {
      const page = action.pageInfo;
      yield put(devicesActions.fetchDevicesActiveAlarms(action));
      yield put(alarmActions.fetchActiveAlarms(action.alarmListFilters, Map({}), page.page, page.pageSize, page.filter, page.sort));
      yield put(alarmActions.fetchDevicesInAlarmWithoutNotification(
        action.alarmListFilters?.unit,
        action.alarmListFilters?.deadleg,
        action.alarmListFilters?.system,
        action.alarmListFilters?.commodity,
        action.alarmListFilters?.priority,
        action.alarmListFilters?.controllerId
      ));
    }

  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error acknowledging the alarm!'), err.response.status);
  }
}

function * undo (action) {
  try {
    yield call(alarmsApi.undo, action);
    yield put(alarmActions.validateDeviceInCriticalAlarm(action.rowId));
    toast.success('Validation/cleared alarm(s) has been undone!');
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error undoing validation/cleared alarm(s)'), err.response.status);
  }
}

function * fetchAlarmsExport (action) {
  try {
    const data = yield call(alarmsApi.getAlarmsExport, action);

    const facilityName = yield select(authSelector.getFacilityName);
    yield put(alarmActions.fetchAlarmsExportSuccess(data, facilityName));
  }
  catch (err: any) {
    yield put(alarmActions.fetchAlarmsExportFailed(Map(err)));
    toast.error("Error retrieving alarm statistics!", err.response.status);
  }
}

function * dismissAlarmsByGroup (action) {
  try {
    yield put(devicesActions.loadingSettingsModal(null));
    yield call(alarmsApi.dismissAlarmsByGroup, action);

    yield put(alarmActions.dismissAlarmsByGroupSuccess());

    yield put(alarmActions.fetchDevicesInAlarmWithoutNotification(
      action.selectedFilters?.get('unit'),
      action.selectedFilters?.get('deadleg'),
      action.selectedFilters?.get('system'),
      action.selectedFilters?.get('commodity'),
      action.selectedFilters?.get('priority'),
      action.selectedFilters?.get('controllerId')
    ));
    yield put(devicesActions.setSettingsModalClose(null));
  }
  catch (err: any) {
    yield put(alarmActions.dismissAlarmsByGroupFailed(Map(err)));
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), null));
    toast.error(createUserFriendlyErrorMessage(err, 'Error dismissing alarms by alarm group!'), err.response.status);
  }
}
