import { takeLatest, put, call, select } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as circuitsApi from 'src/service/api/circuits';
import * as forcedOnCircuitsApi from 'src/service/api/forcedOnCircuits';
import * as circuitActions from './action';
import * as devicesActions from '../device/action';
import * as systemActions from '../system/action';
import * as authSelector from '../authentication/selector';

import * as navigationActions from 'src/module/navigation/action';
import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * circuitRoot () {
  yield takeLatest(circuitActions.FETCH_CIRCUITS, fetchCircuits);
  yield takeLatest(circuitActions.UPDATE_CIRCUIT, updateCircuit);
  yield takeLatest(circuitActions.FETCH_FORCED_ON_CIRCUITS, fetchForcedOnCircuits);
  yield takeLatest(circuitActions.UPDATE_FORCED_ON_CIRCUIT, updateForcedOnCircuit);
  yield takeLatest(circuitActions.ADD_FORCED_ON_CIRCUIT, addForcedOnCircuit);
  yield takeLatest(circuitActions.ARCHIVE_FORCED_ON_CIRCUIT, archiveForcedOnCircuit);
  yield takeLatest(circuitActions.ADD_CIRCUIT, addCircuit);
  yield takeLatest(circuitActions.DELETE_CIRCUIT, deleteCircuit);
  yield takeLatest(circuitActions.FETCH_CIRCUITS_EXPORT, fetchCircuitsExport);
  yield takeLatest(circuitActions.UPDATE_CIRCUIT_SUCCESS, updateCircuitSuccess);
}

function * fetchCircuits (action) {
  try {
    const circuits = yield call(circuitsApi.getCircuits, action);

    yield put(circuitActions.fetchCircuitsSuccess(circuits.get('data', List()), circuits.get('total'), circuits.get('totalPages', 1) ));
    yield put(devicesActions.loadDevicesFromCircuitsList(circuits.get('data', List()), circuits.get('alarmSummary', Map())));
  } catch (err) {
    yield put(circuitActions.fetchCircuitsFailed(Map(err)));
  }
}

function * updateCircuit (action) {
  try {
    const circuit = yield call(circuitsApi.updateCircuit, action, action.circuitId, action.circuit);
    if (circuit.getIn(['settings', 'tagModbusUpdate']) && circuit.get('messageId')) {
      yield put(systemActions.registerMessageResponseHandler(circuit.get('messageId'),
        [
          {
            action: devicesActions.updateDeviceSuccess,
            params: [Map({ tag: action.circuit.get('tag'), id: action.circuitId })],
          },
          {
            action: circuitActions.updateCircuitSuccess,
            params: [action.circuit.get('tag'), action.controllerTag],
          }
        ],
        [
          {
            action: devicesActions.updateDeviceFailed,
            params: [null, action.circuitId],
            toast: 'Error'
          }
        ]
      ));
    }
    else {
      yield put(devicesActions.updateDeviceSuccess(circuit));
      yield put(circuitActions.updateCircuitSuccess(circuit.get('tag'), action.controllerTag));
    }
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating circuit'), err.response.status);
    yield put(circuitActions.updateCircuitFailed(Map(err)));
  }
}

function * updateCircuitSuccess (action) {
  try {
    const circuitTag = action.circuitTag;
    const controllerTag = action.controllerTag;
    if (controllerTag) {
      yield put(navigationActions.pushHistory(`/devices/${encodeURIComponent(controllerTag)}/${encodeURIComponent(circuitTag)}`));
    }
    else {
      yield put(navigationActions.pushHistory(`/devices/_/${encodeURIComponent(circuitTag)}`));
    }
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating circuit'), err.response.status);
    yield put(circuitActions.updateCircuitFailed(Map(err)));
  }
}

function * fetchCircuitsExport (action) {
  try {
    const data = yield call(circuitsApi.getCircuitsExport, action);

    const facilityName = yield select(authSelector.getFacilityName);
    yield put(circuitActions.fetchCircuitsExportSuccess(data, facilityName));
  }
  catch (err: any) {
    yield put(circuitActions.fetchCircuitsExportFailed(Map(err)));
    toast.error("Error retrieving circuit statistics!", err.response.status);
  }
}

function * fetchForcedOnCircuits (action) {
  try {
    const forcedOnCircuits = yield call(forcedOnCircuitsApi.getForcedOnCircuits, action);

    yield put(circuitActions.fetchForcedOnCircuitsSuccess(forcedOnCircuits.get('data'), forcedOnCircuits.get('total'), forcedOnCircuits.get('totalPages')));
  } catch (err) {
    yield put(circuitActions.fetchForcedOnCircuitsFailed(Map(err)));
  }
}

function * addForcedOnCircuit (action) {
  try {
    const forcedOnCircuit = yield call(forcedOnCircuitsApi.addForcedOnCircuit, action, action.forcedOnCircuit);
    yield put(circuitActions.addForcedOnCircuitSuccess(forcedOnCircuit));
    yield put(devicesActions.forcedOnProgrammingDiscrepancySuccess(forcedOnCircuit.get('circuit_id')));
    toast.success("Successfully created Forced-On record!");
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error creating Forced-On record'), err.response.status);
    yield put(circuitActions.addForcedOnCircuitFailed(Map(err)));
  }
}

function * addCircuit (action) {
  const parentId = action.circuit?.get('parent_id');
  try {
    yield put(devicesActions.loadingSettingsModal(parentId));
    const circuit = yield call(circuitsApi.addCircuit, action, action.circuit);

    const controllerId = action.circuit.get('parent_id', null);
    if (controllerId === null || typeof controllerId === 'undefined') {
      yield put(navigationActions.pushHistory(`/devices/_/${action.circuit.get('tag')}`));
    }
    // added circuit to parent object circuit list
    yield put(circuitActions.addCircuitSuccess(circuit));
    // add circuit to device list in redux
    yield put(devicesActions.loadDevicesFromCircuitsList(List([circuit])));
    yield put(devicesActions.setSettingsModalClose(parentId));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error adding new circuit'), err.response.status);
    yield put(circuitActions.addCircuitFailed(Map(err)));
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), parentId));
  }
}

function * deleteCircuit (action) {
  try {
    const response = yield call(circuitsApi.deleteCircuit, action, action.circuitId);
    if (action.controllerTag === null) {
      yield put(navigationActions.pushHistory(`/devices/list`));
    }
    if (response) {
      yield put(systemActions.registerMessageResponseHandler(response,
        [
          {
            action: circuitActions.deleteCircuitSuccess,
            params: [action.circuitId],
          },
          {
            action: devicesActions.deleteDevice,
            params: [action.circuitId],
          }
        ],
        [
          {
            action: circuitActions.deleteCircuitFailed,
            params: [null, action.circuitId],
            toast: 'Error'
          }
        ]
      ));
    } else {
      yield put(circuitActions.deleteCircuitSuccess(action.circuitId));
      yield put(devicesActions.deleteDevice(action.circuitId));
      yield put(navigationActions.pushHistory(`/devices/list`));
    }

  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to delete circuit'), err.response.status);
    yield put(circuitActions.deleteCircuitFailed(Map(err)));
  }
}

function * updateForcedOnCircuit (action) {
  try {
    const forcedOnCircuit = yield call(forcedOnCircuitsApi.updateForcedOnCircuit, action, action.forcedOnCircuitId, action.forcedOnCircuit);
    yield put(circuitActions.updateForcedOnCircuitSuccess(forcedOnCircuit));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating forced on circuit'), err.response.status);
    yield put(circuitActions.updateForcedOnCircuitFailed(Map(err)));
  }
}

function * archiveForcedOnCircuit (action) {
  try {
    const forcedOnCircuit = yield call(forcedOnCircuitsApi.archiveForcedOnCircuit, action, action.forcedOnCircuitId);
    yield put(circuitActions.archiveForcedOnCircuitSuccess(forcedOnCircuit));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error archiving forced on circuit'), err.response.status);
    yield put(circuitActions.archiveForcedOnCircuitFailed(Map(err)));
  }
}

export default circuitRoot;
