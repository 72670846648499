import { takeLatest, put, call } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as searchApi from '../../service/api/search';
import * as searchActions from './action';
import toast from '../../utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * searchRoot () {
  yield takeLatest(searchActions.FETCH_CONFIGURED_SEARCH_FIELDS, fetchConfiguredSearchFields);
  yield takeLatest(searchActions.FETCH_ALL_SEARCHABLE_FIELDS, fetchAllSearchableFields);
  yield takeLatest(searchActions.UPDATE_CONFIGURED_SEARCH_FIELDS, updateConfiguredSearchFields);
}

function * fetchConfiguredSearchFields (action) {
  try {
    const searchFields = yield call(searchApi.getConfiguredSearchFields, action);

    yield put(searchActions.fetchConfiguredSearchFieldsSuccess(searchFields || List()));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving Configured Search Fields'), err.response.status);
    yield put(searchActions.fetchConfiguredSearchFieldsFailed(Map(err)));
  }
}

function * fetchAllSearchableFields (action) {
  try {
    const fields = yield call(searchApi.getAllSearchableFields, action);
    yield put(searchActions.fetchAllSearchableFieldsSuccess(fields));
  } catch (err: any) {
    toast.error('Error retrieving Searchable Fields', err.response.status);
    yield put(searchActions.fetchConfiguredSearchFieldsFailed(Map(err)));
  }
}

function * updateConfiguredSearchFields (action) {
  try {
    yield call(searchApi.updateConfiguredSearchFields, action);
    yield put(searchActions.fetchConfiguredSearchFields(action.table));
  } catch (err: any) {
    toast.error('Error updating searchable fields', err.response.status);
  }
}

export default searchRoot;
