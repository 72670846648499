import { createMigrate } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import { createBlacklistFilter } from '@actra-development-oss/redux-persist-transform-filter-immutable';
import localForage from 'localforage';
import migrations, { latest } from './migrations';

export const persistConfig = {
  transforms: [
    createBlacklistFilter(
      'authentication',
      ['initialized']
    ),
    createBlacklistFilter(
      'integration',
      ['integrations']
    ),
    immutableTransform()
  ],
  key: 'root',
  version: latest,
  storage: localForage,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: ['search', 'router', 'internal', 'prefetch', 'liveDataNotification']
};

export default persistConfig;
