import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import WorkTicketsList from 'src/component/UI/WorkTicketsList';
import { getWorkTickets, getLoadingWorkTickets, getNumWorkTicketPages, getTotalWorkTickets } from 'src/module/workTicket/selector';
import { fetchWorkTickets } from 'src/module/workTicket/action';
import { getPluralWorkTicketLabel } from 'src/module/authentication/selector';
import UnitSelector from 'src/component/UI/UnitSelector';
import { isWhitelabel } from 'src/utils/utils';


export default function WorkTicketScreen () {
  const selectors = {
    getWorkTickets: getWorkTickets,
    getLoadingWorkTickets: getLoadingWorkTickets,
    getNumWorkTicketsPages: getNumWorkTicketPages,
    getTotalWorkTickets: getTotalWorkTickets
  };
  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='work-ticket-screen'>
      <Helmet>
        <title>{`${title} - ${pluralWorkTicketLabel}`}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper>
            <UnitSelector/>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <WorkTicketsList
              title={pluralWorkTicketLabel}
              selectors={selectors}
              fetch={fetchWorkTickets}
              showSearchBar
              showIncludeCompletedOption
              showCompletedAt
              showDeleteIcon
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

WorkTicketScreen.propTypes = {};

WorkTicketScreen.defaultProps = {};
WorkTicketScreen.whyDidYouRender = true;
