import './DeviceSensorGraph.scss';

import { Grid, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { List, Map } from 'immutable';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import variables from 'src/style/variable/variables.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceSensorHistory, fetchExport } from 'src/module/device/action';
import PropTypes from 'prop-types';
import { getDeviceSensorHistory, getDeviceSensorLoading } from 'src/module/device/selector';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import PlaceholderChart from 'src/screen/Protected/DashboardScreen/assets/placeholder-chart.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import moment from 'moment';
import { celsiusToFahrenheitConvertor } from 'src/utils/utils';
import Button from 'src/component/UI/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


export default function DeviceSensorGraph (props) {
  const {
    deviceId,
    temperatureUnit
  } = props;

  const [timeFrame, setTimeFrame] = React.useState('week');
  const [rollUp, setRollUp] = React.useState('Day');
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);
  const dispatch = useDispatch();
  const sensorData = useSelector(getDeviceSensorHistory(deviceId, timeFrame, rollUp));
  const isLoading = useSelector(getDeviceSensorLoading(deviceId));
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    dispatch(fetchDeviceSensorHistory(deviceId, timeFrame, rollUp));
  }, [deviceId, timeFrame, rollUp]);


  const [listLabels, setListLabels] = React.useState([]);
  const [numberOfUnique, setNumberOfUnique] = React.useState(listLabels?.length);
  const [sensorDataClean, setSensorDataClean] = React.useState(Map({ xAxis: null }));

  useEffect(() => {
    const dates = sensorData.get('xAxis')?.toJS();
    switch (rollUp) {
      case 'Hourly': {
        setListLabels(dates?.map(x => moment(x).format(`HH:00`).toString()));
        const set = new Set(dates?.map(x => `${moment(x).format(`DD hh`).toString()}`));
        const numUniqueLabels = (set).size;
        setNumberOfUnique(Math.max(24, numUniqueLabels));
        break;
      } case 'Day': {
        setListLabels(dates?.map(x => `${moment(x).format(`MMMM DD`).toString()}`));
        const numUniqueLabels = (new Set(listLabels)).size;
        const max= timeFrame ==='month' ? 30 : 7;
        setNumberOfUnique(Math.max(max, numUniqueLabels));
        break;
      } default : {
        setListLabels(dates?.map(x => `${moment(x).format(`MMMM DD`).toString()}`));
        const numUniqueLabels = (new Set(listLabels)).size;
        setNumberOfUnique(numUniqueLabels);
      }
    }

    if (temperatureUnit === 'F') {
      let ambientTemp = sensorData.get('ambientTemp')?.toJS();
      let rtdTemp1 = sensorData.get('rtdTemp1')?.toJS();
      let rtdTemp2 = sensorData.get('rtdTemp2')?.toJS();
      let rtdTemp3 = sensorData.get('rtdTemp3')?.toJS();
      let rtdTemp4 = sensorData.get('rtdTemp4')?.toJS();
      let rtdTemp5 = sensorData.get('rtdTemp5')?.toJS();
      let rtdTemp6 = sensorData.get('rtdTemp6')?.toJS();
      let rtdTemp7 = sensorData.get('rtdTemp7')?.toJS();
      let rtdTemp8 = sensorData.get('rtdTemp8')?.toJS();
      let controlTemperatureSetpoint = sensorData.get('controlTemperatureSetpoint')?.toJS();

      let newSensorData = sensorData;
      if (ambientTemp) {
        ambientTemp = ambientTemp.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('ambientTemp', List(ambientTemp));
      }
      if (rtdTemp1) {
        rtdTemp1 = rtdTemp1.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('rtdTemp1', List(rtdTemp1));
      }
      if (rtdTemp2) {
        rtdTemp2 = rtdTemp2.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('rtdTemp2', List(rtdTemp2));
      }
      if (rtdTemp3) {
        rtdTemp3 = rtdTemp3.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('rtdTemp3', List(rtdTemp3));
      }
      if (rtdTemp4) {
        rtdTemp4 = rtdTemp4.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('rtdTemp4', List(rtdTemp4));
      }
      if (rtdTemp5) {
        rtdTemp5 = rtdTemp5.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('rtdTemp5', List(rtdTemp5));
      }
      if (rtdTemp6) {
        rtdTemp6 = rtdTemp6.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('rtdTemp6', List(rtdTemp6));
      }
      if (rtdTemp7) {
        rtdTemp7 = rtdTemp7.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('rtdTemp7', List(rtdTemp7));
      }
      if (rtdTemp8) {
        rtdTemp8 = rtdTemp8.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('rtdTemp8', List(rtdTemp8));
      }
      if (controlTemperatureSetpoint) {
        controlTemperatureSetpoint = controlTemperatureSetpoint.map(val => celsiusToFahrenheitConvertor(val));
        newSensorData = newSensorData.set('controlTemperatureSetpoint', List(controlTemperatureSetpoint));
      }

      setSensorDataClean(newSensorData);
    } else {
      setSensorDataClean(sensorData);
    }
  }, [sensorData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    lineTension: 0.5,
    stacked: false,
    elements: {
      point: {
        radius: 0
      }
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        labels: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          text: rollUp, display: true, font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        },
        grid: {
          drawOnChartArea: false,
          display: false
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          },
          callback: (label) => `${listLabels ? listLabels[label] : ''}`,
          stepSize: 1,
          maxTicksLimit: numberOfUnique / 2
        }
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        beginAtZero: true,
        suggestedMax: 0.5,
        title: {
          text: 'Current', display: true, font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        },
        grid: {
          drawOnChartArea: false,
          display: false
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
          display: false
        },
        title: {
          text: 'Temperature', display: true, font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      },
    },
  };

  const labels = sensorDataClean.get('xAxis')?.toJS().map(x => moment(x).toString());
  const data = {
    labels,
    datasets: [
      {
        label: 'Load Current',
        data: sensorDataClean.get('load')?.toJS(),
        borderColor: variables.primary,
        backgroundColor: variables.primary,
        yAxisID: 'y',
      },
      {
        label: 'Ambient Temperature',
        data: sensorDataClean.get('ambientTemp')?.toJS(),
        borderColor: variables.quaternary,
        backgroundColor: variables.quaternary,
        yAxisID: 'y1',
      },
    ],
  };
  if (sensorDataClean.get('rtdTemp1')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Rtd 1 Average Temperature',
      data: sensorDataClean.get('rtdTemp1')?.toJS(),
      borderColor: variables.first,
      backgroundColor: variables.first,
      yAxisID: 'y1',
    });
  }
  if (sensorDataClean.get('rtdTemp2')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Rtd 2 Average Temperature',
      data: sensorDataClean.get('rtdTemp2')?.toJS(),
      borderColor: variables.secondary,
      backgroundColor: variables.secondary,
      yAxisID: 'y1',
    });
  }
  // add any values for TS > 2
  if (sensorDataClean.get('rtdTemp3')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Rtd 3 Average Temperature',
      data: sensorDataClean.get('rtdTemp3')?.toJS(),
      borderColor: variables.tertiary,
      backgroundColor: variables.tertiary,
      yAxisID: 'y1',
    });
  }

  if (sensorDataClean.get('rtdTemp4')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Rtd 4 Average Temperature',
      data: sensorDataClean.get('rtdTemp4')?.toJS(),
      borderColor: variables.nonary,
      backgroundColor: variables.nonary,
      yAxisID: 'y1',
    });
  }

  if (sensorDataClean.get('rtdTemp5')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Rtd 5 Average Temperature',
      data: sensorDataClean.get('rtdTemp5')?.toJS(),
      borderColor: variables.quinaryDark,
      backgroundColor: variables.quinaryDark,
      yAxisID: 'y1',
    });
  }

  if (sensorDataClean.get('rtdTemp6')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Rtd 6 Average Temperature',
      data: sensorDataClean.get('rtdTemp6')?.toJS(),
      borderColor: variables.senaryLight,
      backgroundColor: variables.senaryLight,
      yAxisID: 'y1',
    });
  }

  if (sensorDataClean.get('rtdTemp7')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Rtd 7 Average Temperature',
      data: sensorDataClean.get('rtdTemp7')?.toJS(),
      borderColor: variables.septenaryLight,
      backgroundColor: variables.septenaryLight,
      yAxisID: 'y1',
    });
  }

  if (sensorDataClean.get('rtdTemp8')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Rtd 8 Average Temperature',
      data: sensorDataClean.get('rtdTemp8')?.toJS(),
      borderColor: variables.octonaryLight,
      backgroundColor: variables.octonaryLight,
      yAxisID: 'y1',
    });
  }

  if (sensorDataClean.get('controlTemperatureSetpoint')?.toJS().filter(x => x !== null).length > 0) {
    data.datasets.push({
      label: 'Control Temperature Setpoint',
      data: sensorDataClean.get('controlTemperatureSetpoint')?.toJS() || [],
      borderColor: variables.nonaryLight,
      backgroundColor: variables.nonaryLight,
      yAxisID: 'y1',
    });
  }

  const timeFrameChange = (event) => {
    const val = event.target.value as string;
    setTimeFrame(val);
    setRollUp('Day');

    if (val === 'hours') {
      setRollUp('Hourly');
    }
  };

  const handleDownloadStatisticsClick = () => {
    dispatch(fetchExport(deviceId));
  };

  return (
    <Grid item xs={12} md={12} className='device-statistics'>
      <Paper data-testid='alarms-content'>

        <div className="sensor-title-container">
          <span className="sensor-history-title">Device Sensor History</span>
          <Button onClick={handleDownloadStatisticsClick} icon={<FileDownloadOutlinedIcon style={{ color: '#444', fontSize: 24 }} />}></Button>
        </div>

        {!showRotateMessage ? <div className='graph-options'>
          <div>
            <InputLabel >
              Time Frame
            </InputLabel>
            <Select
              id={'timeFrame'}
              labelId={`timeFrame`}
              name={'timeFrame'}
              label="Time Frame"
              value={timeFrame}
              onChange={timeFrameChange}
              style={{ margin: '0px' }}
            >
              <MenuItem value={'hours'}>Last 24 Hours</MenuItem>
              <MenuItem value={'week'}>Last Week</MenuItem>
              <MenuItem value={'month'}>Last Month</MenuItem>
            </Select>
          </div>
        </div> : null}

        {isLoading ?
          <div style={{ width: '300px', height: '500px', margin: 'auto', paddingTop: '200px' }}>
            <LogoSpinner size={200} />
          </div> :
          null
        }
        {!isLoading && sensorData?.get('xAxis')?.size > 0 && !showRotateMessage ?
          <div style={{ height: '500px' }}>
            <Line options={options} data={data} />
          </div> : null
        }
        {!isLoading && showRotateMessage ?
          <DeviceRotate />
          : null}

        {!isLoading && !(sensorData?.get('xAxis')?.size > 0) && !showRotateMessage ?
          <div style={{ height: '500px', position: 'relative' }} >
            <div style={{ height: '500px', width: '100%', position: 'absolute', left: '0', background: 'rgba(255, 255, 255, 0.8)', zIndex: '2' }}>
              <div style={{ marginTop: '200px', fontSize: 'x-large' }}>No History for the Current Selection</div>
            </div>
            <div >
              <img height='500px' width='100%' src={PlaceholderChart} alt="Placeholder Graph" />
            </div>
          </div> : null}
      </Paper>
    </Grid>
  );
}

DeviceSensorGraph.propTypes = {
  deviceId: PropTypes.string,
  temperatureUnit: PropTypes.string
};

DeviceSensorGraph.defaultProps = {
  deviceId: null,
  temperatureUnit: 'C'
};
