import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import yup from 'src/utils/yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { updateClient } from 'src/module/authentication/action';
import { getClient } from 'src/module/authentication/selector';
import Button from 'src/component/UI/Button';
import SwitchField from 'src/component/UI/SettingsModal/SettingsModalFields/SwitchField';
import { Map } from 'immutable';
import { InputLabel, Tooltip } from '@mui/material';
import HelpCircleIcon from '@mui/icons-material/HelpOutline';
import { fetchIntegrations } from 'src/module/integration/action';
export default function ClientPropertiesForm () {
  const client: any = useSelector(getClient);
  const dispatch = useDispatch();

  const [textMessageMFA, setTextMessageMFA] = useState(client.getIn(['preferences', 'MFAOptions', 'text'], false));
  const [emailMFA, setEmailMFA] = useState(client.getIn(['preferences', 'MFAOptions', 'email'], false));
  const [OTPMFA, setOTPMFA] = useState(client.getIn(['preferences', 'MFAOptions', 'OTPMFA'], false));


  useEffect(() => {
    dispatch(fetchIntegrations());
  }, []);
  const formik = useFormik({
    initialValues: {
      reportEmailSender: client.getIn(['preferences', 'reportEmailSender'], ''),
      name: client.get('name'),
      averageHoursPerWalkdown: client.getIn(['preferences', 'averageHoursPerWalkdown'], 1) || 1,
      averageHoursPerWorkOrder: client.getIn(['preferences', 'averageHoursPerWorkOrder'], 4) || 4,
      sessionTimeout: client.getIn(['preferences', 'sessionTimeout'], 0) || 0,
      passwordTimeout: client.getIn(['preferences', 'passwordTimeout'], 0) || 0,
    },
    onSubmit: values => {
      const { name, ...rest } = values;
      (rest as any).MFA = textMessageMFA || emailMFA || OTPMFA;
      (rest as any).MFAOptions = {};
      (rest as any).MFAOptions.text = textMessageMFA;
      (rest as any).MFAOptions.email = emailMFA;
      (rest as any).MFAOptions.OTPMFA = OTPMFA;
      dispatch(updateClient(name, Map(rest)));
    },
    validationSchema: () => {
      return yup.object().shape({
        name: yup
          .string('Facility Name')
          .required('Facility Name is Required'),
        reportEmailSender: yup
          .string('Email address report should be sent from'),
        averageHoursPerWalkdown: yup
          .number('Average Number of Hours per Walk Down'),
        averageHoursPerWorkOrder: yup
          .number('Average Number of Hours per Work Order'),
        sessionTimeout: yup
          .number('Time before user is logged out'),
        passwordTimeout: yup
          .number('Passport expiry days')
      });
    },
  });

  return (
    <div className='client-properties-form'>
      <h2>Facility Details</h2>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Facility Name"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name as string}
          variant='outlined'
          InputLabelProps={{ shrink: false }}
        />

        <TextField
          fullWidth
          id="reportEmailSender"
          name="reportEmailSender"
          label="Report Sender Email"
          value={formik.values.reportEmailSender}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.reportEmailSender)}
          helperText={formik.errors.reportEmailSender as string}
          variant='outlined'
          InputLabelProps={{ shrink: false }}
        />

        <TextField
          fullWidth
          id="averageHoursPerWalkdown"
          name="averageHoursPerWalkdown"
          label="Average Number of Hours per Walk Down"
          type="number"
          value={formik.values.averageHoursPerWalkdown}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.averageHoursPerWalkdown)}
          helperText={formik.errors.averageHoursPerWalkdown as string}
          variant='outlined'
          InputLabelProps={{ shrink: false }}
        />

        <TextField
          fullWidth
          id="averageHoursPerWorkOrder"
          name="averageHoursPerWorkOrder"
          label="Average Number of Hours per Work Order"
          type="number"
          value={formik.values.averageHoursPerWorkOrder}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.averageHoursPerWorkOrder)}
          helperText={formik.errors.averageHoursPerWorkOrder as string}
          variant='outlined'
          InputLabelProps={{ shrink: false }}
        />

        <TextField
          fullWidth
          id="sessionTimeout"
          name="sessionTimeout"
          label={
            <div>
              <InputLabel className='input-label'>Session Expiry</InputLabel>
              <Tooltip title={'Number of minutes of activity before a user must re-authenticate'} className='input-tooltip' enterTouchDelay={0}>
                <HelpCircleIcon className='help-icon' style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          }
          type="number"
          value={formik.values.sessionTimeout}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.sessionTimeout)}
          helperText={formik.errors.sessionTimeout as string}
          variant='outlined'
          InputLabelProps={{ shrink: false, style: { pointerEvents: "auto" } }}

        />

        <TextField
          fullWidth
          id="passwordTimeout"
          name="passwordTimeout"
          label={
            <div>
              <InputLabel className='input-label'>Password Expiry</InputLabel>
              <Tooltip title={'Number of days before users must change password'} className='input-tooltip' enterTouchDelay={0}>
                <HelpCircleIcon className='help-icon' style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          }
          type="number"
          value={formik.values.passwordTimeout}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.passwordTimeout)}
          helperText={formik.errors.passwordTimeout as string}
          variant='outlined'
          InputLabelProps={{ shrink: false, style: { pointerEvents: "auto" } }}
        />

        <InputLabel className='mfa-label'>Multi Factor Authentication Options</InputLabel>
        <div className='mfa-switch'><SwitchField value={textMessageMFA} field={Map({ label: 'Text Message', id: null })} handleChange={() => setTextMessageMFA(!textMessageMFA)} /></div>
        <div className='mfa-switch'><SwitchField value={emailMFA} field={Map({ label: 'Email', id: null })} handleChange={() => setEmailMFA(!emailMFA)} /></div>
        <div className='mfa-switch'><SwitchField value={OTPMFA} field={Map({ label: 'One-time password ', id: null })} handleChange={() => setOTPMFA(!OTPMFA)} /></div>

        <div className='button-bar'>
          <Button color="primary" variant="contained" fullWidth type="submit" cta>Save Details</Button>
        </div>
      </form>
    </div>
  );
}
