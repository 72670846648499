import './ClientScreen.scss';

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InviteUserIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { formatName, isWhitelabel } from 'src/utils/utils';
import { NavLink } from 'react-router-dom';
import variables from 'src/style/variable/variables.module.scss';

import DataTable from 'src/component/UI/DataTable';
import InviteUserModal from './InviteUserModal';
import ClientPropertiesForm from './ClientPropertiesForm';
import Button from 'src/component/UI/Button';
import ConfirmModal from 'src/component/UI/ConfirmModal';
import PermissionFence from 'src/component/PermissionFence';
import EditableCell from 'src/component/UI/EditableCell';
import DisplayInfoModal from 'src/component/UI/DisplayInfoModal';

import { getClient, getAuthenticatedUserId } from 'src/module/authentication/selector';
import { fetchClient } from 'src/module/authentication/action';
import { getUsers } from 'src/module/user/selector';
import { fetchUsers, inviteUser, removeUser, updateUserRole } from 'src/module/user/action';

export default function ClientScreen () {
  const dispatch = useDispatch();

  const client = useSelector(getClient);
  const users = useSelector(getUsers);
  const currentUserId = useSelector(getAuthenticatedUserId);

  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [userIdToRemove, setUserIdToRemove] = useState('');
  const [removeUserMessage, setRemoveUserMessage] = useState('');
  useEffect(() => {
    dispatch(fetchClient());
    dispatch(fetchUsers());
  }, []);

  const toggleInviteUserModal = () => {
    setInviteUserModalOpen(!inviteUserModalOpen);
  };

  const handleInviteUser = (values) => {
    dispatch(inviteUser(values.email, values.firstName, values.lastName));
    setEnteredEmail(values?.email);
    toggleInviteUserModal();
  };

  const makeToggleRemoveUserModal = (userId: string = '') => {
    return () => {
      toggleRemoveUserModal(userId);
    };
  };

  const toggleRemoveUserModal = (userId: string = '') => {
    let name = 'user';

    if (userId) {
      const index = users.findIndex((user) => user.get('id') === userId);

      if (index !== -1) {
        name = users.getIn([index, 'first_name']) || users.getIn([index, 'email']);
      }
    }

    setRemoveUserModalOpen(!removeUserModalOpen);
    setUserIdToRemove(userId);
    setRemoveUserMessage(`Are you sure you would like to remove ${name} from the ${client.get('name')} facility?`);
  };

  const handleRemoveUser = () => {
    dispatch(removeUser(userIdToRemove));
    toggleRemoveUserModal();
  };

  const roleLabels = {
    admin: 'Administrator',
    eht_specialist: 'EHT Specialist',
    maintenance_user: 'Maintenance User',
    operations: 'Operations',
    regular_user: 'Regular User'
  };

  const formatRoles = (roles) => {
    const validRoles = [];
    if (!roles) {
      return '';
    }

    roles.forEach((role) => {
      if (roleLabels[role]) {
        validRoles.push(role);
      }
    });

    return validRoles[0];
  };

  const handleUpdateRole = (userId: string) => {
    return (role) => {
      dispatch(updateUserRole(userId, role));
    };
  };

  const [userData, setUserdata] = useState({});

  useEffect(() => {
    users?.map((user) => {
      if (user.get('email') === enteredEmail && user?.get('password')) {
        if (!infoModalOpen && Object.keys(userData).length === 0) {
          setInfoModalOpen(true);
        }
        setUserdata({
          email: user.get('email'),
          password: user.get('password')
        });
      }
    });
  }, [users]);

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id="client" data-testid='client-screen'>
      <DisplayInfoModal
        open={infoModalOpen}
        title='User Credentials'
        handleClose = {() => setInfoModalOpen(false)}
        data={userData}
      />
      <InviteUserModal open={inviteUserModalOpen} handleClose={toggleInviteUserModal} handleSubmit={handleInviteUser} />
      <ConfirmModal title='Are you sure?' message={removeUserMessage} open={removeUserModalOpen} handleClose={makeToggleRemoveUserModal()} handleReject={makeToggleRemoveUserModal()} handleConfirm={handleRemoveUser} />
      <Helmet>
        <title>{title} - Client Details</title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper>
            <ClientPropertiesForm />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper>
            <h2>Users</h2>
            <DataTable
              columns={[
                {
                  id: 'name',
                  accessor: user => formatName(user.get('first_name', null), user.get('last_name', null)),
                  Header: 'Name',
                },
                {
                  id: 'email',
                  accessor: user => user.get('email'),
                  Header: 'Email',
                },
                {
                  id: 'createdAt',
                  accessor: user => moment(user.get('created_at')).format('YYYY-MM-DD'),
                  Header: 'Joined',
                },
                {
                  id: 'lastActivityAt',
                  accessor: user => user.get('last_activity_at') ? moment(user.get('last_activity_at')).fromNow() : 'Never',
                  Header: 'Last Activity',
                },
                {
                  id: 'status',
                  accessor: user => user.get('status'),
                  Header: 'Status',
                },
                {
                  id: 'roles',
                  accessor: user => formatRoles(user.get('roles')),
                  Header: (
                    <>Role
                      <NavLink exact to='/settings/permissions'>
                        <InfoIcon className="alert-icon" width={16} height={16} style={{ marginLeft: '5px', marginBottom: '-3px', cursor: 'pointer', color: variables.black }}/>
                      </NavLink>
                    </>
                  ),
                  disableSortBy: true,
                  Cell: (row: any) => {
                    if (currentUserId !== row.cell.row.original.get('id')) {
                      return (<EditableCell
                        permission='edit-user'
                        className='alarm-cell'
                        onChange={handleUpdateRole(row.cell.row.original.get('id'))}
                        value={row.cell.value || ''}
                        emptyCellLabel='Assign User Role'
                        options={[
                          { value: 'admin', label: roleLabels['admin'] },
                          { value: 'eht_specialist', label: roleLabels['eht_specialist'] },
                          { value: 'maintenance_user', label: roleLabels['maintenance_user'] },
                          { value: 'operations', label: roleLabels['operations'] },
                          { value: 'regular_user', label: roleLabels['regular_user'] }
                        ]}
                      >{roleLabels[row.cell.value]}</EditableCell>);
                    } else {
                      return (roleLabels[row.cell.value]);
                    }
                  }
                },
                {
                  id: 'id',
                  name: '',
                  compact: true,
                  width: '60px',
                  Cell: (row: any) => {
                    return (currentUserId !== row.cell.row.original.get('id')) && (
                      <PermissionFence can='edit-user'>
                        <div className='client-user-actions'>
                          <Button onClick={makeToggleRemoveUserModal(row.cell.row.original.get('id'))} icon={<DeleteIcon style={{ fontSize: 14 }} />} />
                        </div>
                      </PermissionFence>
                    );
                  }
                }
              ]}
              data={users}
              tableId='clientScreenTable'
            />
            <PermissionFence can='edit-user'>
              <div className='button-bar'>
                <Button className="invite-user-btn" onClick={toggleInviteUserModal} icon={<InviteUserIcon style={{ fontSize: 14 }} />} cta >Invite User</Button>
              </div>
            </PermissionFence>
          </Paper>
        </Grid>
      </Grid>

    </div>
  );
}
