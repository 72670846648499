import './ProfileScreen.scss';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { Helmet } from 'react-helmet';
import yup from 'src/utils/yup';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from 'src/component/UI/Button';
import PhoneNumber from 'mui-phone-number';
import { isValidNumber } from 'libphonenumber-js';

import { getAuthenticatedUser, getLoadingNotifications, getNotifications, getNotificationPreferences, getLoadingNotificationPrefences, getFeatures, getUserMustResetPassword } from 'src/module/authentication/selector';
import { updateUser } from 'src/module/user/action';
import { changePassword, updateUserNotificationPrefs, deleteUserNotificationPrefs, fetchUserNotificationPrefs, fetchUserNotifications } from 'src/module/authentication/action';
import { isPasswordSecure, isWhitelabel } from 'src/utils/utils';
import { Map } from 'immutable';
import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';
import TemperatureUnitSwitch from 'src/component/UI/TemperatureUnitSwitch';
import LabelledSwitch from 'src/component/UI/LabelledSwitch';
import PermissionFence from 'src/component/PermissionFence';
import ConfirmModal from 'src/component/UI/ConfirmModal';

export default function ProfileScreen () {
  const dispatch = useDispatch();
  const user = useSelector(getAuthenticatedUser);
  const loadingNotifications = useSelector(getLoadingNotifications);
  const loadingDisabledNotifications = useSelector(getLoadingNotificationPrefences);
  const notifications = useSelector(getNotifications);
  const disabledNotifications = useSelector(getNotificationPreferences);
  const isLoading = loadingDisabledNotifications || loadingNotifications;
  const features: any = useSelector(getFeatures);
  const userMustResetPassword: any = useSelector(getUserMustResetPassword);
  const [confirmModalOpen, setConfirmModalOpen] = useState(userMustResetPassword);

  const [fields, setFields] = useState(getFields());
  const [settings, setSettings] = useState(getSettings(notifications, disabledNotifications));
  const [contactNumber, setContactNumber] = useState(null);
  const [isFocusedContactNumber, setIsFocusedContactNumber] = useState(false);
  const gaugeValue: string = user?.getIn(['preferences', 'gaugeStyle'], 'radial');
  function getFields () {
    if (!notifications || !notifications.reduce) {
      return Map();
    }
    const notificationFeatureMap = Map({
      'critical-alarm-notification': 'alarm-notification'
    });
    return notifications.reduce((acc, notification) => {
      const userHasAccess = notificationFeatureMap.get(notification.get('key')) ? features?.includes(notificationFeatureMap.get(notification.get('key'))) : true;
      return userHasAccess ? acc.set(notification.get('key'),
        Map({
          type: 'switch',
          label: notification.get('name'),
          helperText: notification.get('description'),
        })) : acc;



    }, Map());
  }

  function getSettings (_notifications, _disabledNotifications) {
    return notifications && notifications.reduce ? _notifications?.reduce((acc, notification) => {
      const matchedDisabledNotification = _disabledNotifications?.find(
        (disabledNotification) => {
          return disabledNotification === notification.get('key');
        }
      );
      return acc.set(notification.get('key'), matchedDisabledNotification ? false : true);
    }, Map()) : Map();
  }

  function handleChangeOfNotifcation (fieldId, value) {
    setSettings(settings.set(fieldId, value));
    if (!value) {
      // unsubscribe and add to disabledNotifcation list
      dispatch(updateUserNotificationPrefs(fieldId));
    }
    else {
      // subscribe and remove from disabledNotifcation list
      dispatch(deleteUserNotificationPrefs(fieldId));
    }
  }

  useEffect(() => {
    dispatch(fetchUserNotificationPrefs());
    dispatch(fetchUserNotifications());
  }, []);

  useEffect(() => {
    setFields(getFields());
    setSettings(getSettings(notifications, disabledNotifications));
  }, [notifications, disabledNotifications]);

  useEffect(() => {
    setConfirmModalOpen(userMustResetPassword);
  }, [userMustResetPassword]);

  const handleChangeForGaugeStyle = (e) => {
    let style = 'radial';
    if (!e.target.checked) {
      style = 'linear';
    }
    dispatch(updateUser(null, Map({ gaugeStyle: style })));
  };
  const profileForm = useFormik({
    initialValues: {
      firstName: user.get('first_name'),
      lastName: user.get('last_name'),
      contactNumber: user.get('contact_number')
    },
    onSubmit: values => {
      dispatch(updateUser({
        first_name: values.firstName,
        last_name: values.lastName,
        contact_number: contactNumber
      }));
    },
    validationSchema: () => {
      return yup.object().shape({
        contactNumber: yup
          .string()
          .nullable()
          // mui-phone-number will auto-format the entered contact number
          .test('valid-contact-number', 'Invalid contact number, please double check you have entered a valid phone number. Sample format +1 (999) 999-9999', function (val = null) {
            return val ? isValidNumber(val) : true;
          })
      });
    }
  });

  const passwordForm = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: values => {
      dispatch(changePassword(values.oldPassword, values.newPassword));
    },
    validationSchema: () => {
      return yup.object().shape({
        oldPassword: yup.string()
          .required('Old Password can\'t be blank'),
        newPassword: yup.string()
          .required('New Password can\'t be blank')
          .test('password-secure', 'Must be at least 8 characters, including a number, uppercase letter, lowercase letter, and a symbol', isPasswordSecure),
        confirmPassword: yup.string()
          .oneOf([yup.ref('newPassword'), null], 'Passwords don\'t match!')
          .required('Password confirmation can\'t be blank')
      });
    },
  });

  const handleChangeContactNumber = (number) => {
    if (number.replace('+', '') === '') {
      number = '';
    }
    setContactNumber(number);
    profileForm.setFieldValue('contactNumber', number);
  };

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const confirmModalSubmit = () => {
    toggleConfirmModal();
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id="profile">
      <Helmet>
        <title>{title} - Profile</title>
      </Helmet>

      <ConfirmModal
        title='New Password Required'
        message={`Your password has expired, you must change your password`}
        open={confirmModalOpen}
        handleClose={() => toggleConfirmModal()}
        handleReject={() => toggleConfirmModal()}
        handleConfirm={() => confirmModalSubmit()}
        noCancelOption
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper>
            <h2>Profile Details</h2>

            <form onSubmit={profileForm.handleSubmit}>
              <TextField
                id='firstName'
                type='text'
                label='First Name'
                onChange={profileForm.handleChange}
                onBlur={profileForm.handleBlur}
                value={profileForm.values.firstName}
                error={Boolean(profileForm.errors.firstName) && Boolean(profileForm.touched.firstName)}
                helperText={(profileForm.touched.firstName && profileForm.errors.firstName) as string}
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: false }}
              />

              <TextField
                id='lastName'
                type='text'
                label='Last Name'
                onChange={profileForm.handleChange}
                onBlur={profileForm.handleBlur}
                value={profileForm.values.lastName}
                error={Boolean(profileForm.errors.lastName) && Boolean(profileForm.touched.lastName)}
                helperText={(profileForm.touched.lastName && profileForm.errors.lastName) as string}
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: false }}
              />

              <TextField
                id='email'
                type='email'
                label='Email'
                value={user.get('email')}
                variant='outlined'
                fullWidth
                helperText='Contact customer support to change your email'
                InputLabelProps={{ shrink: false }}
                disabled
              />

              <label htmlFor='contactNumber' className={isFocusedContactNumber ? 'contact-number-label-focused' : 'contact-number-label'}>Contact Number</label>
              <PhoneNumber
                id='contactNumber'
                defaultCountry={'ca'}
                value={profileForm.values.contactNumber}
                fullWidth
                onChange={number => handleChangeContactNumber(number)}
                onFocus={() => setIsFocusedContactNumber(true)}
                onBlur={() => setIsFocusedContactNumber(false)}
                onlyCountries={['ca', 'us']}
                error={Boolean(profileForm.errors.contactNumber) && Boolean(profileForm.touched.contactNumber)}
                helperText={(profileForm.touched.contactNumber && profileForm.errors.contactNumber) as string}
              />

              <div className='button-bar'>
                <Button type='submit' cta background>Save Details</Button>
              </div>
            </form>
          </Paper>
        </Grid>
        {
          user.get('allow_password_auth') === true ? <Grid item xs={12} md={6}>
            <Paper>
              <h2>Change Password</h2>
              <form onSubmit={passwordForm.handleSubmit}>
                <TextField
                  id='oldPassword'
                  type='password'
                  label='Old Password'
                  onChange={passwordForm.handleChange}
                  onBlur={passwordForm.handleBlur}
                  value={passwordForm.values.oldPassword}
                  error={passwordForm.errors.oldPassword && passwordForm.touched.oldPassword}
                  helperText={passwordForm.touched.oldPassword && passwordForm.errors.oldPassword}
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                />

                <TextField
                  id='newPassword'
                  type='password'
                  label='New Password'
                  onChange={passwordForm.handleChange}
                  onBlur={passwordForm.handleBlur}
                  value={passwordForm.values.newPassword}
                  error={passwordForm.errors.newPassword && passwordForm.touched.newPassword}
                  helperText={passwordForm.touched.newPassword && passwordForm.errors.newPassword}
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                />

                <TextField
                  id='confirmPassword'
                  type='password'
                  label='Confirm Password'
                  onChange={passwordForm.handleChange}
                  onBlur={passwordForm.handleBlur}
                  value={passwordForm.values.confirmPassword}
                  error={passwordForm.errors.confirmPassword && passwordForm.touched.confirmPassword}
                  helperText={passwordForm.touched.confirmPassword && passwordForm.errors.confirmPassword}
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                />

                <div className='button-bar'>
                  <Button type='submit' cta background>Update Password</Button>
                </div>

              </form>
            </Paper>
          </Grid> : null
        }

        {
          !isLoading ?
            <Grid item xs={12} md={6}>
              <Paper>
                <div >
                  <SettingsModal noModal fields={fields} title={'Notifications'} settings={settings} handleChange={handleChangeOfNotifcation} />
                </div>
              </Paper>
            </Grid> : null
        }
        <PermissionFence can='read-user'>
          <Grid item xs={12} md={6}>
            <Paper>
              <h2>Preference</h2>
              <TemperatureUnitSwitch />
              <LabelledSwitch title="Gauge Style" onChange={handleChangeForGaugeStyle} value={gaugeValue === 'radial'} onLabel='Radial' offLabel='Linear' />
            </Paper>
          </Grid>
        </PermissionFence>
      </Grid>
    </div>
  );
}
