export function calcDisplayRange (settings, val, label) {
  return settings[label] || !settings.canDisableAllAlarms ? val : null;
}
export function calcMinAndMax (displayMin, displayMax, val, setPointValue, trip) {
  const values = [displayMin, displayMax, val, trip];
  if (setPointValue || setPointValue === 0) {
    values.push(setPointValue);
  }
  const minOfAllValues = Math.min(...values);
  let maxOfAllValues = Math.max(...values);
  if (minOfAllValues === maxOfAllValues) {
    maxOfAllValues = 100;
  }
  const min = minOfAllValues;

  const max = maxOfAllValues + ((maxOfAllValues - minOfAllValues) * 0.15);
  return [max, min, values];
}

export function colorForGauge (theme, values, lowerRange ) {
  const red = '#FC6863';
  const orange = '#F1C811';
  const green = '#4EC656';
  const grey = 'grey';

  let rangeColors = {
    low: orange,
    mid: green,
    upper: orange,
    trip: red,
  };

  if (theme === 'rising') {
    rangeColors = {
      low: green,
      mid: orange,
      upper: red,
      trip: red,
    };
  } else if (theme === 'off' || (values as any).every(v => v === null)) {
    rangeColors = {
      low: grey,
      mid: grey,
      upper: grey,
      trip: grey,
    };
  } else if (theme === 'temp') {
    rangeColors = {
      low: red,
      mid: green,
      upper: orange,
      trip: red,
    };
  }

  // theme overrides
  if (!lowerRange && theme !== 'off' && !(values as any).every(v => v === null)) {
    rangeColors = {
      low: green,
      mid: green,
      upper: orange,
      trip: red,

    };
  }
  return rangeColors;
}

export function getGaugeStatusDisplayValue (val: number) {
  if (val === null) {
    return 'N/A';
  }
  return Number.isInteger(val) ? val : val.toFixed(1);
}
