import { createSelector } from 'reselect';
import { List } from 'immutable';

export const getImportState = (state) =>
  state.import;

export const getUploads = createSelector(
  [getImportState],
  (state) => {
    return state.get('uploads') || List();
  }
);
