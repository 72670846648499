import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

export const getUpgradeState = (state) =>
  state.upgrade;

export const getUpgradePaths =  (baseDevice, upgradeDevice) => createSelector(
  [getUpgradeState],
  (upgrade) => {
    const keysToCheck = List([
      `${baseDevice}Controller-${upgradeDevice}Controller`,
      `${baseDevice}Controller-${upgradeDevice}`,
      `${baseDevice}-${upgradeDevice}Controller`,
      `${baseDevice}-${upgradeDevice}`
    ]);
    let upgrades: any = List();
    keysToCheck.forEach(key => {
      const retrievedData = upgrade?.getIn(['upgrades', 'data', key], List());
      upgrades = upgrades.concat(retrievedData);
    });
    return upgrades.sort((a, b) => a.get('order') > b.get('order') ? 1 : -1);
  }
);


export const getPossibleUpgradePaths = (baseDevice) => createSelector(
  [getUpgradeState],
  (upgrade) => {
    const regex = new RegExp(`^${baseDevice.replace('Controller', '')}(?:Controller)?-(.*)`);
    const devicesFound = upgrade?.getIn(['upgrades', 'data'], Map())
      .filter((value, key) => regex.test(key))
      .mapKeys(key => {
        const matchedKey = key.match(regex)[1];
        return matchedKey.endsWith('Controller') ? matchedKey : matchedKey + 'Controller';
      })
      .keySeq()
      .toSet() // Convert to Set to remove duplicates
      .toList(); // Convert back to List
    return devicesFound;
  }
);

export const getLoadingUpgrade = createSelector(
  [getUpgradeState],
  (upgrade) => {
    return upgrade.getIn(['upgrades', 'loading'], false);
  }
);
