import './RulesScreen.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getRules } from 'src/module/rule/selector';
import Rule from './Rule';
import { addRule, fetchRules, updateRule } from 'src/module/rule/action';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Immutable, { Map } from 'immutable';
import Button from 'src/component/UI/Button';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import { Paper } from '@mui/material';
import PermissionFence from 'src/component/PermissionFence';
import { isWhitelabel } from 'src/utils/utils';
import { getIntegrations } from 'src/module/integration/selector';
import { fetchDeviceGroupsLabels } from 'src/module/groupDevices/action';
import { fetchIntegrations } from 'src/module/integration/action';

export default function RulesScreen () {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  const integrations = useSelector(getIntegrations);
  const hasPagerDutyIntegration = Boolean(integrations?.toJS().find((i) => i.type === 'pager_duty'));

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  useEffect(() => {
    dispatch(fetchDeviceGroupsLabels());
    dispatch(fetchIntegrations());
  }, []);

  const rules = useSelector(getRules);
  const [orderedRules, setOrderedRules] = useState(rules.sort((a, b) => a.get('order') > b.get('order') ? 1 : -1));

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  useEffect(() => {
    setOrderedRules(rules.sort((a, b) => a.get('order') > b.get('order') ? 1 : -1));
  }, [rules]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let newOrderedRules = orderedRules.toJS();
    const rule = newOrderedRules[result.source.index];

    if (result.destination.index > result.source.index) {
      rule.order = (result.destination.index * 10) + 15;
    } else {
      rule.order = (result.destination.index * 10) + 5;
    }

    dispatch(updateRule(rule.id, Map(rule)));

    newOrderedRules = newOrderedRules.sort((a, b) => a.order > b.order ? 1 : -1);

    newOrderedRules.forEach((rule, idx) => {
      newOrderedRules[idx].order = (idx + 1) * 10;
    });

    setOrderedRules(Immutable.fromJS(newOrderedRules));
  };

  const createRule = () => {
    dispatch(addRule(Map({ criticality: 0 })));
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';
  return (
    <div id='alarm-rules-screen' className='page'>
      <Helmet>
        <title>{title} - Rules</title>
      </Helmet>
      <h2>Alarm Criticality Rule Definitions</h2>

      {showRotateMessage ? <Paper><DeviceRotate /></Paper> :
        <PermissionFence can={['edit-eht-alarm-rule']} noPermissionComponent={(
          <div className='rule-bar'>
            {orderedRules.map((rule, index) => (
              <div key={rule.get('id')} style={{ paddingBottom: '10px' }}>
                <Rule orderNumber={index} rule={rule} />
              </div>
            ))}
          </div>
        )}>
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="rules-1">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {orderedRules.map((rule, index) => (
                      <Draggable key={rule.get('id')} draggableId={rule.get('id')} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className='rule-bar'>
                              <Rule key={rule.get('id')} orderNumber={index} rule={rule} hasPagerDutyIntegration={hasPagerDutyIntegration} />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}

              </Droppable>
            </DragDropContext>
            <div className='button-bar'>
              <Button onClick={createRule} color="primary" variant="contained" fullWidth type="submit" cta>Add Rule</Button>
            </div>
          </>
        </PermissionFence>
      }
    </div>
  );
}

RulesScreen.propTypes = {
};

RulesScreen.defaultProps = {
};
