import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as weatherForecastApi from 'src/service/api/weatherForecasts';
import * as weatherActions from './action';
import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * weatherForecastRoot () {
  yield takeLatest(weatherActions.FETCH_WEATHER, fetchWeather);
}

function * fetchWeather (action) {
  try {
    const weather = yield call(weatherForecastApi.fetchWeatherForecast, action);
    yield put(weatherActions.fetchWeatherSuccess(weather));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error getting weather forecast!'), err.response.status);
    yield put(weatherActions.fetchWeatherFailed(Map(err)));
  }
}

export default weatherForecastRoot;
