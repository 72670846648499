import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchDeviceUpgrades, FETCH_DEVICE_UPGRADES }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_UPGRADES`,
);

export const { fetchDeviceUpgradesSuccess, FETCH_DEVICE_UPGRADES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_UPGRADES_SUCCESS`,
  ['upgrades', yup.immutable().list()]
);

export const { fetchDeviceUpgradesFailed, FETCH_DEVICE_UPGRADES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_UPGRADES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { createDeviceUpgrade, CREATE_DEVICE_UPGRADE }: any = createTypedAction(
  `${PREFIX}CREATE_DEVICE_UPGRADE`,
  ['upgrade', yup.object().required()],
);

export const { createDeviceUpgradeSuccess, CREATE_DEVICE_UPGRADE_SUCCESS }: any = createTypedAction(
  `${PREFIX}CREATE_DEVICE_UPGRADE_SUCCESS`,
  ['upgrade', yup.immutable().map()]
);

export const { createDeviceUpgradeFailed, CREATE_DEVICE_UPGRADE_FAILED }: any = createTypedAction(
  `${PREFIX}CREATE_DEVICE_UPGRADE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateDeviceUpgrade, UPDATE_DEVICE_UPGRADE }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE`,
  ['deviceUpgradeId', yup.string().required()],
  ['upgrade', yup.immutable().map().required()]
);

export const { updateDeviceUpgradeSuccess, UPDATE_DEVICE_UPGRADE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE_SUCCESS`,
  ['upgradePath', yup.immutable().map().required()]
);

export const { updateDeviceUpgradeFailed, UPDATE_DEVICE_UPGRADE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteDeviceUpgrade, DELETE_DEVICE_UPGRADE }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_UPGRADE`,
  ['deviceUpgradeId', yup.string().required()]
);

export const { deleteDeviceUpgradeSuccess, DELETE_DEVICE_UPGRADE_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_UPGRADE_SUCCESS`,
  ['deviceUpgradeId', yup.string().required()]
);

export const { deleteDeviceUpgradeFailed, DELETE_DEVICE_UPGRADE_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_UPGRADE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateDeviceUpgradeCondition, UPDATE_DEVICE_UPGRADE_CONDITION }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE_CONDITION`,
  ['upgradeConditionId', yup.string().required()],
  ['upgradeCondition', yup.immutable().map().required()]
);

export const { updateDeviceUpgradeConditionSuccess, UPDATE_DEVICE_UPGRADE_CONDITION_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE_CONDITION_SUCCESS`,
  ['upgradePath', yup.immutable().map().required()]
);

export const { updateDeviceUpgradeConditionFailed, UPDATE_DEVICE_UPGRADE_CONDITION_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE_CONDITION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { addDeviceUpgradeCondition, ADD_DEVICE_UPGRADE_CONDITION }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_UPGRADE_CONDITION`,
  ['deviceConditionBranchId', yup.string().required()],
  ['upgradeCondition', yup.object().required()]
);

export const { addDeviceUpgradeConditionSuccess, ADD_DEVICE_UPGRADE_CONDITION_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_UPGRADE_CONDITION_SUCCESS`,
  ['upgradePath', yup.immutable().map().required()]
);

export const { addDeviceUpgradeConditionFailed, ADD_DEVICE_UPGRADE_CONDITION_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_UPGRADE_CONDITION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { addDeviceUpgradeConditionBranch, ADD_DEVICE_UPGRADE_CONDITION_BRANCH }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_UPGRADE_CONDITION_BRANCH`,
  ['deviceUpgradeId', yup.string().required()],
  ['upgradeConditionBranch', yup.object().required()]
);

export const { addDeviceUpgradeConditionBranchSuccess, ADD_DEVICE_UPGRADE_CONDITION_BRANCH_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_UPGRADE_CONDITION_BRANCH_SUCCESS`,
  ['upgradePath', yup.immutable().map().required()]
);

export const { addDeviceUpgradeConditionBranchFailed, ADD_DEVICE_UPGRADE_CONDITION_BRANCH_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_UPGRADE_CONDITION_BRANCH_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateDeviceUpgradeConditionBranch, UPDATE_DEVICE_UPGRADE_CONDITION_BRANCH }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE_CONDITION_BRANCH`,
  ['upgradeConditionBranchId', yup.string().required()],
  ['upgradeConditionBranch', yup.immutable().map().required()]
);

export const { updateDeviceUpgradeConditionBranchSuccess, UPDATE_DEVICE_UPGRADE_CONDITION_BRANCH_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE_CONDITION_BRANCH_SUCCESS`,
  ['upgradePath', yup.immutable().map().required()]
);

export const { updateDeviceUpgradeConditionBranchFailed, UPDATE_DEVICE_UPGRADE_CONDITION_BRANCH_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_UPGRADE_CONDITION_BRANCH_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteDeviceConditionBranch, DELETE_DEVICE_CONDITION_BRANCH }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_CONDITION_BRANCH`,
  ['upgradeConditionBranchId', yup.string().required()]
);

export const { deleteDeviceConditionBranchSuccess, DELETE_DEVICE_CONDITION_BRANCH_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_CONDITION_BRANCH_SUCCESS`,
  ['upgradeConditionBranchId', yup.string().required()]
);

export const { deleteDeviceConditionBranchFailed, DELETE_DEVICE_CONDITION_BRANCH_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_CONDITION_BRANCH_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { clearDeviceUpgradeLoading, CLEAR_DEVICE_UPGRADE_LOADING }: any = createTypedAction(
  `${PREFIX}CLEAR_DEVICE_UPGRADE_LOADING`,
);
