import './UpgradeSettingsPanel.scss';
import { TreeView, TreeItem } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { useSelector, useDispatch } from 'react-redux';
import { getEnumeratedDeviceSettingsByType } from 'src/module/device/selector';
import { clearSyncDevicesLoading } from 'src/module/device/action';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';
import { langLookUpText } from 'src/utils/langLookUp';
import AddIcon from '@mui/icons-material/Add';
import { getLoadingUpgrade } from 'src/module/upgrade/selector';

export default function UpgradeSettingsPanel (props) {
  const {
    upgradeDevice,
    settingsPaths,
    onUpdateSettingPath
  } = props;

  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const [panelsCollapsed, setPanelsCollapsed] = useState(true);
  const deviceSettings = useSelector(getEnumeratedDeviceSettingsByType);
  const loading = useSelector(getLoadingUpgrade);

  useEffect(() => {
    dispatch(clearSyncDevicesLoading(null));
  }, []);

  const buildSettingsList = (settings, device) => {
    // filter out any settings that already have an upgrade path
    const filteredSettings = settings.filter((setting) => !settingsPaths.find((settingPath) => settingPath.get('setting_path') === setting));
    return filteredSettings.map((setting, idx) =>
      <div
        key={idx}
        style={{
          padding: '4px',
        }}
        className='configure-search-row-box'
      >
        <div className='configure-search-row' onClick={() => handleSettingClick (setting, device)}>
          <div className='title-and-icon'>
            <AddIcon className='add-setting-icon' style={{ margin: '3 5 0 0', paddingTop: '2' }} />
            <p>{langLookUpText(setting)}</p>
          </div>
        </div>
      </div>
    );

  };

  const handleSettingClick = (setting, device) => {
    // early exits prevents spam-clicking the setting creating multiple upgrade paths
    if (loading) {
      return;
    }
    onUpdateSettingPath({ setting_path: setting, upgrade_device_factory: device });
  };

  const renderDeviceSettings = (device) => {
    if (!deviceSettings.get(device)) {
      return null;
    }

    return (
      <TreeItem
        key={`device-upgrade`}
        nodeId={`${device}-upgrade`}
        label={device}
      >
        {
          deviceSettings.get(device + "Controller") ?
            <div style={{ marginLeft: '20px' }}>
              <TreeItem
                key={`device-parent-upgrade`}
                nodeId={`{device}-parent-upgrade`}
                label={device + " (CONTROLLER)"}
              >
                {
                  buildSettingsList(deviceSettings.get(device + "Controller"), device + "Controller")
                }
              </TreeItem>
            </div>
            : null
        }
        {
          buildSettingsList(deviceSettings.get(device), device)
        }
      </TreeItem>
    );
  };


  return (
    <div className='settings-panel' data-testid='upgrade-settings-panel'>
      {isMobile ? <h3 className='expandable' onClick={() => setPanelsCollapsed(!panelsCollapsed)}>Panels &nbsp;<span className='expand-arrow'>{panelsCollapsed ? '▼' : '▲'}</span></h3> : null}
      {
        <>
          <TreeView
            onNodeSelect={() => { }}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ maxHeight: isMobile ? '300px' : '60vh', flexGrow: 1, maxWidth: isMobile ? 900 : 400, overflowY: 'auto' }}
          >
            {upgradeDevice && upgradeDevice !== 'null' ? renderDeviceSettings(upgradeDevice) : null}
          </TreeView>
        </>
      }
    </div>
  );
}

UpgradeSettingsPanel.propTypes = {
  setSelectedDevice: PropTypes.func,
  setShowCreateNewPanel: PropTypes.func,
  setSelectedPanel: PropTypes.func,
  onPanelClick: PropTypes.func,
  onControllerClick: PropTypes.func,
  upgradeDevice: PropTypes.string,
  onUpdateSettingPath: PropTypes.func,
  settingsPaths: ImmutablePropTypes.list,
};

UpgradeSettingsPanel.defaultProps = {
  setSelectedDevice: null,
  setShowCreateNewPanel: null,
  setSelectedPanel: null,
  upgradeDevice: null
};
