import * as Sentry from '@sentry/browser';

export const captureException = async (err) => {
  try {
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureException(err);
    });
  } catch (err: any) {
    console.error(`Unable to track error in Sentry. Error: ${err.message}`, { error: err }); // eslint-disable-line no-console
  }
};

export default {
  captureException
};
