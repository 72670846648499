import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './TestingReport.scss';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Checkbox, TextField, Paper, FormControlLabel } from '@mui/material';
import { getCheckSheetTasks } from 'src/module/checkSheet/selector';
import ImmutablePropTypes from 'react-immutable-proptypes';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';

export default function CheckSheet (props) {
  const {
    onChange,
    data,
    checkSheetType,
    readOnly,
    title,
    id
  } = props;
  const tasks = useSelector(getCheckSheetTasks(checkSheetType));
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const [results, setResults] = useState(data);

  useEffect(() => {
    setResults(data);
  }, [data]);
  const onChangeOfCheckBox = (index, col) => {
    const previous = results.getIn([index, 'status']);
    const temp = results.setIn([index, 'status'], previous === col ? null : col);
    setResults(temp);
    onChange([id], temp);
  };
  const onComment = (index, val) => {
    const temp = results.setIn([index, 'comment'], val);
    setResults(temp);
    onChange([id], temp);
  };
  const isChecked = (index, col) => {
    return results?.getIn([index, 'status']) === col;
  };

  const defaultContent = () => {
    return <Paper>
      <h3>{title}</h3>

      <TableContainer style={{ maxHeight: '55vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className='main-headers' align="center" >Item</TableCell>
              <TableCell className='main-headers' align="center" >Task</TableCell>
              <TableCell className='main-headers' align="center" size="small">Pass</TableCell>
              <TableCell className='main-headers' align="center" size="small">Fail</TableCell>
              <TableCell className='main-headers' align="center" size="small">N/A</TableCell>
              <TableCell className='main-headers' align="center" >Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => {
              return (
                <TableRow key={`task-${index}-row`} className='checkbox-rows'>
                  <TableCell style={{ width: '50px' }}>{index + 1}</TableCell>
                  <TableCell style={{ width: '500px' }}>{task.label}</TableCell>
                  <TableCell size="small" padding='checkbox'>{!task.text_input_only && <Checkbox checked={isChecked(index, 'pass')} disabled={readOnly} onChange={() => onChangeOfCheckBox(index, 'pass')} />}</TableCell>
                  <TableCell size="small" padding='checkbox'>{!task.text_input_only && <Checkbox checked={isChecked(index, 'fail')} disabled={readOnly} onChange={() => onChangeOfCheckBox(index, 'fail')} />}</TableCell>
                  <TableCell size="small" padding='checkbox'>{!task.text_input_only && <Checkbox checked={isChecked(index, 'na')} disabled={readOnly} onChange={() => onChangeOfCheckBox(index, 'na')} />}</TableCell>
                  <TableCell ><TextField fullWidth value={results?.getIn([index, 'comment'])} disabled={readOnly} onChange={(e) => { onComment(index, e.target.value); }} /></TableCell>
                </TableRow>);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>;
  };

  const mobileContent = () => {
    return <div>
      <h3>Check Sheet</h3>
      <div className='checkbox-rows-container'>
        {tasks.map((task, index) => {
          return (
            <Paper key={`task-${index}-row`} className='checkbox-rows'>
              {index + 1}
              <div className="flex-container">
                {<b className="task-label">{task.label}</b>}
                <div className="checkboxes">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked(index, 'pass')}
                        disabled={readOnly}
                        onChange={() => onChangeOfCheckBox(index, 'pass')}
                        style={{ zoom: 1.5 }}
                      />
                    }
                    label={<span style={{ fontSize: '1.5em' }}>Pass</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked(index, 'fail')}
                        disabled={readOnly}
                        onChange={() => onChangeOfCheckBox(index, 'fail')}
                        style={{ zoom: 1.5 }}
                      />
                    }
                    label={<span style={{ fontSize: '1.5em' }}>Fail</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked(index, 'na')}
                        disabled={readOnly}
                        onChange={() => onChangeOfCheckBox(index, 'na')}
                        style={{ zoom: 1.5 }}
                      />
                    }
                    label={<span style={{ fontSize: '1.5em' }}>N/A</span>}
                  />
                </div>
                <TextField
                  label="comment"
                  fullWidth
                  value={results.getIn([index, 'comment'])}
                  disabled={readOnly}
                  onChange={(e) => onComment(index, e.target.value)}
                  className="comment-field"
                />
              </div>
            </Paper>
          );
        })}

      </div>
    </div>;
  };

  return (
    <React.Fragment>
      {isMobile ? mobileContent() : defaultContent()}
    </React.Fragment>
  );
}

CheckSheet.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: ImmutablePropTypes.list.isRequired,
  checkSheetType: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
};
CheckSheet.defaultProps = {
  title: 'Check Sheet',
  id: 'checkSheet',
};
