import './Copyable.scss';

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClipboardIcon from '@mui/icons-material/AssignmentOutlined';
import Button from './Button';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import toast from 'src/utils/toast';

export default function Copyable (props) {
  const {
    className,
    children
  } = props;

  const [success, setSuccess] = useState(false);

  const contentRef: any = React.createRef();

  const copyContent = (evnt) => {
    evnt.stopPropagation();
    const textToCopy = contentRef.current.innerText;
    const richTextToCopy = contentRef.current.innerHTML;

    const copyListener = (e) => {
      e.clipboardData.setData('text/html', richTextToCopy);
      e.clipboardData.setData('text/plain', textToCopy);
      e.preventDefault();
    };

    document.addEventListener('copy', copyListener);
    if (document.execCommand('copy')) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } else {
      toast.error('Error copying. Press CTRL+C to copy.');
    }
    document.removeEventListener('copy', copyListener);
  };

  const icons = (<div className='copyable-icon-container'><div className='copyable-icon-front'><ClipboardIcon /></div><div className='copyable-icon-back'><CheckIcon /></div></div>);

  return (
    <div data-testid='copyable' className={classNames('copyable', className)} >
      <span id='copyable-content' className='copyable-content' ref={contentRef}>{ children }</span>
      <Button
        onClick={copyContent}
        icon={icons}
        className={classNames('copyable-icon', { 'copyable-icon-success': success })}
        border
        compact
        background
      />
    </div>
  );
}

Copyable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired
};

Copyable.defaultProps = {
  className: ''
};
