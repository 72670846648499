import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import NotesList from 'src/component/UI/NotesList';
import { fetchDevicesActiveNotes, fetchDevicesArchivedNotes } from 'src/module/device/action';
import { getDevicesActiveNotes, getLoadingActiveNotes, getNumActiveNotesPages, getTotalActiveNotes, getDevicesArchivedNotes, getLoadingArchivedNotes, getNumArchivedNotesPages, getTotalArchivedNotes } from 'src/module/device/selector';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';

export default function NotesContent (props) {

  const {
    title,
    deviceId,
    isExpanable,
    onlyArchived,
    showDeleteIcon
  } = props;

  const [pastNotesCollapsed, setPastNotesCollapsed] = useState(!isExpanable);

  const selectorsActive = {
    getNotes: getDevicesActiveNotes(deviceId),
    getLoadingNotes: getLoadingActiveNotes(deviceId),
    getNumNotesPages: getNumActiveNotesPages(deviceId),
    getTotalNotes: getTotalActiveNotes(deviceId)
  };
  const selectorsArchived = {
    getNotes: getDevicesArchivedNotes(deviceId),
    getLoadingNotes: getLoadingArchivedNotes(deviceId),
    getNumNotesPages: getNumArchivedNotesPages(deviceId),
    getTotalNotes: getTotalArchivedNotes(deviceId)
  };

  const makeTogglePastNotesGroup = () => {
    setPastNotesCollapsed(!pastNotesCollapsed);
  };
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  return (
    <div id="notes-content" data-testid='notes-content'>
      <Paper>
        {isExpanable ?
          <h3 className='expandable' onClick={() => makeTogglePastNotesGroup()}>{title} &nbsp;<span className='expand-arrow'>{pastNotesCollapsed ? '▲' : '▼'}</span></h3>
          :
          <h3>{title}</h3>
        }
        {!onlyArchived && !showRotateMessage? <NotesList supressSelectedFilters selectors={selectorsActive} noteType={'all'} showNoteType showSubject showAlarm showExpiry fetch={fetchDevicesActiveNotes} suppressHeader deviceId={deviceId} hideDeviceTag showDeleteIcon={showDeleteIcon} tableId='activeNotesTable'/> : ''}
        {onlyArchived && pastNotesCollapsed && !showRotateMessage? <NotesList supressSelectedFilters selectors={selectorsArchived} noteType={'all'} showNoteType showSubject showAlarm showExpiry showArchivedNotes fetch={fetchDevicesArchivedNotes} suppressHeader deviceId={deviceId} hideDeviceTag showDeleteIcon={showDeleteIcon}  tableId='pastNotesTable' /> : ''}
        {pastNotesCollapsed && showRotateMessage ?
          <DeviceRotate />
          : null}
      </Paper>
    </div>
  );
}

NotesContent.propTypes = {
  title: PropTypes.string,
  deviceId: PropTypes.string,
  isExpanable: PropTypes.bool,
  onlyArchived: PropTypes.bool,
  showDeleteIcon: PropTypes.bool,
};

NotesContent.defaultProps = {
  title: String,
  deviceId: null,
  isExpanable: false,
  onlyArchived: false,
  showDeleteIcon: false,
};
