import { createSelector } from 'reselect';
import { List } from 'immutable';

export const getOfflineState = (state) =>
  state.offline;

export const getOfflineQueue = createSelector(
  [getOfflineState],
  (offline) => {
    return offline.getIn(['actionQueue'], List());
  }
);
export const getIsOffline = createSelector(
  [getOfflineState],
  (offline) => {
    return offline.getIn(['isOffline'], true);
  }
);
export const getIsQueueRunning = createSelector(
  [getOfflineState],
  (offline) => {
    return offline.getIn(['isRunning'], false);
  }
);
