import './BitsField.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { FormHelperText, FormControl, InputLabel, Switch } from '@mui/material';

export default function BitsField (props) {
  const {
    field,
    value,
    error,
    handleChange,
    disabled,
    showLabel,
    showHelperText,
  } = props;

  const handleChangeBit = ((fieldId, bit) => {
    return (e, checked) => {
      let _value = value;
      if (checked) {
        _value |= bit;
      } else if ((value & bit) > 0) { // clear it if it's set
        _value -= bit;
      }

      handleChange(fieldId, _value);
    };
  });

  const unit = field.get('unit', null);
  const fieldId = field.get('id');

  return <FormControl key={fieldId} className='bits-field' variant='outlined' data-testid={`bits-field-${fieldId}`}>
    {
      showLabel ? <InputLabel id={`bits-field-${fieldId}`} error={Boolean(error)} disabled={disabled} data-testid={`bits-field-label-${fieldId}`}>
        {field.get('label')}
        {unit ? (<>&nbsp;<span className='unit'>({unit})</span></>) : null}
      </InputLabel> : null
    }


    <div className='bits-field-options'>
      {field.get('options', List()).reduce((acc, option) => {
        if (option && option.get) {
          const optionValue = option.get('value');
          const subFieldId =`bits-field-${fieldId}-option-${optionValue}`;
          acc.push((
            <label key={subFieldId}>
              <span className='label'>{option.get('label')}</span>
              <Switch data-testid={`bits-field-switch-${subFieldId}`}
                key={subFieldId}
                id={subFieldId}
                name={subFieldId}
                checked={(value & optionValue) > 0}
                onChange={handleChangeBit(fieldId, optionValue)}
                disabled={disabled}
                value={(value & optionValue) > 0 ? optionValue : ''}
                color="primary"
              />
            </label>
          ));
        }
        return acc;
      }, [])}
    </div>
    {
      showHelperText ?
        <FormHelperText error={Boolean(error)} >
          {(error) ?
            <>
              {error}<br />
            </> : null}
          {field.get('helperText')}
        </FormHelperText> : null
    }

  </FormControl>;

}

BitsField.propTypes = {
  field: ImmutablePropTypes.map,
  error: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
  showHelperText: PropTypes.bool,
};

BitsField.defaultProps = {
  disabled: false,
  showLabel: true,
  showHelperText: true,
};

BitsField.whyDidYouRender = true;
