import { takeLatest, put, call } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as api from '../../service/api';
import * as ruleActions from './action';
import toast from '../../utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * ruleRoot () {
  yield takeLatest(ruleActions.FETCH_RULES, fetchRules);
  yield takeLatest(ruleActions.UPDATE_RULE, updateRule);
  yield takeLatest(ruleActions.ADD_RULE, addRule);
  yield takeLatest(ruleActions.DELETE_RULE, deleteRule);
  yield takeLatest(ruleActions.UPDATE_RULE_CONDITION, updateRuleCondition);
  yield takeLatest(ruleActions.ADD_RULE_CONDITION, addRuleCondition);

}

function * fetchRules (action) {
  try {
    const rules = yield call(api.getRules, action);

    yield put(ruleActions.fetchRulesSuccess(rules || List()));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving rules'), err.response.status);
    yield put(ruleActions.fetchRulesFailed(Map(err)));
  }
}

function * addRule (action) {
  try {
    const rule = yield call(api.addRule, action);

    yield put(ruleActions.addRuleSuccess(rule));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to create new Rule for Current circuit'), err.response.status);
    yield put(ruleActions.addRuleFailed(err));
  }
}

function * deleteRule (action) {
  try {
    yield call(api.deleteRule, action);
    yield put(ruleActions.deleteRuleSuccess(action.ruleId));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to delete rule'), err.response.status);
    yield put(ruleActions.deleteRuleFailed(err));
  }
}

function * updateRule (action) {
  try {
    const rules = yield call(api.updateRule, action);
    yield put(ruleActions.updateRuleSuccess(rules));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating rule'), err.response.status);
    yield put(ruleActions.updateRuleFailed(Map(err)));
  }
}

function * addRuleCondition (action) {
  try {
    const rule = yield call(api.addRuleCondition, action);

    yield put(ruleActions.addRuleConditionSuccess(rule));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to create new rule condition'), err.response.status);
    yield put(ruleActions.addRuleConditionFailed(err));
  }
}

function * updateRuleCondition (action) {
  try {
    const rule = yield call(api.updateRuleCondition, action);
    yield put(ruleActions.updateRuleConditionSuccess(rule));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating rule'), err.response.status);
    yield put(ruleActions.updateRuleConditionFailed(Map(err)));
  }
}

export default ruleRoot;
