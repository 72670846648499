import { Map } from "immutable";

export const latest = 9;
export const migrations = {
  0: (state) => {
    return state;
  },
  1: (state) => {
    const notification = state.notification.notifications?.data;
    delete state.notification;
    return {
      ...state,
      workTicket: {
        workTickets: {
          data: workTicketMigration(notification)
        },
      }
    };
  },
  2: (state) => {
    delete state.workTicket;
    return state;
  },
  3: (state) => {
    state.alarm = state.alarm.delete('devicesInAlarmWithoutNotification');
    state.alarm = state.alarm.delete('loadingDevicesInAlarmWithoutNotification');
    state.alarm = state.alarm.delete('units');
    state.alarm = state.alarm.delete('systems');
    state.alarm = state.alarm.delete('controllers');
    state.alarm = state.alarm.delete('priorities');
    state.alarm = state.alarm.delete('commodities');
    state.alarm = state.alarm.delete('selectedUnit');
    state.alarm = state.alarm.delete('selectedSystem');
    state.alarm = state.alarm.delete('selectedController');
    state.alarm = state.alarm.delete('selectedPriority');
    state.alarm = state.alarm.delete('selectedCommodity');
    state.alarm = state.alarm.delete('metrics');
    state.alarm = state.alarm.delete('alarmHistory');
    state.alarm = state.alarm.delete('criticalAlarmsWithoutNotification');
    state.alarm = state.alarm.delete('integrityChecks');
    state.alarm = state.alarm.delete('guide');
    state.alarm = state.alarm.delete('loading');
    state.alarm = state.alarm.delete('numPages');
    state.alarm = state.alarm.delete('total');
    state.alarm = state.alarm.delete('devicesInCriticalAlarm');

    const currentAlarmsList = state.alarm.get('alarms');
    state.alarm = state.alarm.delete('alarms');
    state.alarm = state.alarm.setIn(['alarms', 'data'], listMigration(currentAlarmsList));
    return state;
  },
  4: (state) => {
    const currentNotes = state.note.getIn(['notes', 'data']);
    state.note = state.note.delete('notes');
    state.note = state.note.setIn(['notes', 'data'], listMigration(currentNotes));
    return state;
  },
  5: (state) => {
    state.device = state.device.delete('devicesWithGroupedAlarms,controller');
    state.device = state.device.delete('notes');
    state.device = state.device.delete('alarms');
    state.device = state.device.delete('deviceInfo');
    state.device = state.device.delete('notifications');
    state.device = state.device.delete('circuit');
    state.device = state.device.delete('circuits');
    state.device = state.device.delete('controller');
    state.device = state.device.delete('deviceInfo');
    state.device = state.device.delete('devicesWithGroupedAlarms');
    return state;
  },
  6: (state) => {
    const currentCircuits = state.circuit.getIn(['circuits', 'data']);
    state.circuit = state.circuit.delete('circuits');
    state.device = state.device.setIn(['devices', 'data'], listMigration(currentCircuits));
    return state;
  },
  7: (state) => {
    state.authentication = state.authentication.delete('notificationPreferences');
    return state;
  },
  8: (state) => {
    state.note = state.freezeLogs.delete('freezeLogs');
    return state;
  },
  9: (state) => {
    state.device = state.device.deleteIn(['devices', 'commLoops']);
    return state;
  }
};

export default migrations;
const workTicketMigration = (notifications) => {
  const workTickets = [];
  notifications?.forEach(notification => {
    workTickets[notification.id] = {
      record: notification,
      lastFetch: null
    };
  });
  return workTickets;
};

const listMigration = (listData) => {
  let allData = Map();
  listData?.forEach(element => {
    const object = Map({
      record: element,
      lastFetch: null
    });
    allData = allData.set(element.get('id'), object);
  });

  return allData;
};
