import { createSelector } from 'reselect';
import { List } from 'immutable';

export const getRuleState = (state) =>
  state.rule;

export const getRules = createSelector(
  [getRuleState],
  (rule) => {
    return rule.getIn(['rules', 'data']) || List();
  }
);

export const getLoadingRules = createSelector(
  [getRuleState],
  (rule) => {
    return rule.getIn(['rules', 'loading']) || false;
  }
);
