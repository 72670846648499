import * as Sentry from '@sentry/browser';

export const normalizeActionData = (action) =>
  ({
    ...(action.id && { id: action.id }),
    ...(action.userId && { userId: action.userId })
  });

export const sentry = (store) => {
  Sentry.addGlobalEventProcessor((event) => {
    const { authentication } = store.getState();

    return {
      ...(authentication && authentication.has('user') && {
        user: {
          id: authentication.getIn(['user', 'id'])
        }
      }),
      ...event
    };
  });

  return (next) => (action) => {
    Sentry.addBreadcrumb({
      category: 'redux.action',
      level: Sentry.Severity.Info,
      message: action.type,
      data: normalizeActionData(action)
    });

    return next(action);
  };
};

export default sentry;
