// import { List, Map } from 'immutable'
import { takeEvery, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as api from 'src/service/api';
import * as importActions from './action';

import toast from 'src/utils/toast';
import { getFileExtension } from 'src/utils/utils';

export function * importRoot () {
  yield takeEvery(importActions.IMPORT_DATA, importData);
}

export const getImportTypeFromImportResponse = (importType) => {
  switch (importType) {
    case 'DTS_ALARMS':
    case 'TVNE_ALARMS':
      return 'supervisorAlarmData';

    case 'SAP_NOTIFICATIONS':
      return 'erpData';
  }

  return 'otherData';
};

export const getSubTypeFromImportResponse = (importType) => {
  switch (importType) {
    case 'DTS_ALARMS':
      return 'dts';

    case 'TVNE_ALARMS':
      return 'tvne';

    case 'SAP_NOTIFICATIONS':
      return 'sap';
  }

  return null;
};

function * importData (action) {
  try {
    const ext = getFileExtension(action.importFile.name).toLowerCase();
    if (ext === 'csv' || ext === 'xml') {
      const importData = yield call(api.importData, action, action.importFile);
      toast.success(`Successfully imported ${action.importFile.name}`);
      yield put(importActions.importDataSuccess({ name: action.importFile.name, lastModified: action.importFile.lastModified }, getImportTypeFromImportResponse(importData.get('importType')), getSubTypeFromImportResponse(importData.get('importType'))));
    } else {
      toast.warn(`Invalid upload format ${action.importFile.name}`);
      yield put(importActions.importDataFailed({ name: action.importFile.name, lastModified: action.importFile.lastModified }, null, 'rejected'));
    }
  } catch (err: any) {
    toast.error(`Failed to import ${action.importFile.name}`, err.response.status);
    yield put(importActions.importDataFailed({ name: action.importFile.name, lastModified: action.importFile.lastModified }, Map(err), 'failed'));
  }
}

export default importRoot;
