import { request } from '../api';

export const fetchWeatherForecast = (action) => {
  return request(action, {
    payload: {
      url: `/weather/forecast/`,
      options: {
        method: 'GET'
      },
    }
  });
};
