import { request } from '../api';

export const getRecipes = (action) => {
  const payload: any = {
    url: `/recipes`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};
export const getDeviceSettingMap = (action) => {
  const payload: any = {
    url: `/devices/available-settings`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const getRecipeById = (action) => {
  const {
    recipeId
  } = action;

  const payload: any = {
    url: `/recipes/${recipeId}`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const addRecipe = (action) => {

  const {
    recipe
  } = action;

  return request(action, {
    payload: {
      url: `/recipes`,
      options: {
        method: 'POST',
        data: recipe
      },
    }
  });
};

export const updateRecipe = (action) => {
  const {
    recipeId,
    recipe
  } = action;

  return request(action, {
    payload: {
      url: `/recipes/${recipeId}`,
      options: {
        method: 'PATCH',
        data: recipe
      },
    }
  });
};

export const deleteRecipe = (action) => {
  const {
    recipeId
  } = action;

  return request(action, {
    payload: {
      url: `/recipes/${recipeId}`,
      options: {
        method: 'DELETE'
      },
    }
  });
};

export const addRecipeCondition = (action) => {
  const {
    recipeId,
    recipeCondition
  } = action;

  return request(action, {
    payload: {
      url: `/recipes/${recipeId}/condition`,
      options: {
        method: 'PATCH',
        data: recipeCondition
      },
    }
  });
};

export const updateRecipeCondition = (action) => {
  const {
    recipeId,
    recipeCondition
  } = action;

  return request(action, {
    payload: {
      url: `/recipes/${recipeId}/condition`,
      options: {
        method: 'PATCH',
        data: recipeCondition
      },
    }
  });
};
