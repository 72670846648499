import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';
import * as adminApi from '../../service/api/admin';
import * as adminActions from './action';

export function * adminRoot () {
  yield takeLatest(adminActions.FETCH_CLIENTS, fetchClients);
  yield takeLatest(adminActions.FETCH_TIERS, fetchTiers);
  yield takeLatest(adminActions.UPDATE_CLIENT, updateClient);
}

function * updateClient (action) {
  try {
    const client = yield call(adminApi.updateClient, action);
    yield put(adminActions.updateClientSuccess(client));
  } catch (err: any) {
    yield put(adminActions.updateClientFailed(Map(err)));
  }
}

function * fetchClients (action) {
  try {
    const clients = yield call(adminApi.fetchClients, action);
    yield put(adminActions.fetchClientsSuccess(clients));
  } catch (err: any) {
    yield put(adminActions.fetchClientsFailed(Map(err)));
  }
}

function * fetchTiers (action) {
  try {
    const tiers = yield call(adminApi.fetchTiers, action);
    yield put(adminActions.fetchTiersSuccess(tiers));
  } catch (err: any) {
    yield put(adminActions.fetchTiersFailed(Map(err)));
  }
}
