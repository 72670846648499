import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { authenticationReducer } from './authentication/reducer';
import { prefetchReducer } from './prefetch/reducer';
import { alarmReducer } from './alarm/reducer';
import { circuitReducer } from './circuit/reducer';
import { importReducer } from './import/reducer';
import { userReducer } from './user/reducer';
import { noteReducer } from './note/reducer';
import { devicesReducer } from './device/reducer';
import { lookupReducer } from './lookup/reducer';
import { ruleReducer } from './rule/reducer';
import { upgradeReducer } from './upgrade/reducer';
import { integrationReducer } from './integration/reducer';
import { controllerReducer } from './controller/reducer';
import { weatherForecastReducer } from './weather/reducer';
import { workTicketReducer } from './workTicket/reducer';
import { filterReducer } from './filter/reducer';
import { facilityReducer } from './facility/reducer';
import { freezeLogReducer } from './freezeLog/reducer';
import { offlineReducer } from './offline/reducer';
import { permissionReducer } from './permission/reducer';
import { recipeReducer } from './recipe/reducer';
import { batchScheduleReducer } from './batchSchedule/reducer';
import { progressReducer } from './progress/reducer';
import { searchReducer } from './search/reducer';
import { checkSheetReducer } from './checkSheet/reducer';
import { deviceGroupsReducer } from './groupDevices/reducer';
import { adminReducer } from './admin/reducer';


export const reducers = {
  authentication: authenticationReducer,
  prefetch: prefetchReducer,
  alarm: alarmReducer,
  circuit: circuitReducer,
  controller: controllerReducer,
  import: importReducer,
  user: userReducer,
  note: noteReducer,
  device: devicesReducer,
  lookup: lookupReducer,
  rule: ruleReducer,
  upgrade: upgradeReducer,
  integration: integrationReducer,
  weather: weatherForecastReducer,
  workTicket: workTicketReducer,
  filter: filterReducer,
  facility: facilityReducer,
  freezeLog: freezeLogReducer,
  offline: offlineReducer,
  permission: permissionReducer,
  recipe: recipeReducer,
  batchSchedule: batchScheduleReducer,
  progress: progressReducer,
  search: searchReducer,
  checkSheet: checkSheetReducer,
  groups: deviceGroupsReducer,
  admin: adminReducer

};

export const rootReducer = (history) => combineReducers({
  ...reducers,
  router: connectRouter(history)
});

export default rootReducer;
