import './RegulatoryBodyReport.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { isWhitelabel } from 'src/utils/utils';
import RegulatoryBodyReport from './RegulatoryBodyReport';
import UnitSelector from 'src/component/UI/UnitSelector';
import { Badge, Tab, Tabs, Tooltip } from '@mui/material';
import { clearTestingReport, generateTestingReport } from 'src/module/device/action';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Button from 'src/component/UI/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import moment from 'moment';
export default function GenerateReportsScreen () {
  const [tabValue, setTabValue] = useState('regulator');
  const dispatch = useDispatch();

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    dispatch(clearTestingReport(tabValue));

  };
  const selectedFilters = useSelector(getDevicesSelectedFilters);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [changes, setChanges] = useState(false);
  const [runOnce, setRunOnce] = useState(false);
  const [startDateOnRanReport, setStartDateOnRanReport] = useState(null);
  const [endDateOnRanReport, setEndDateOnRanReport] = useState(null);

  const runReport = () => {
    dispatch(clearTestingReport(tabValue));
    dispatch(generateTestingReport(tabValue, selectedFilters, null, startDate ? moment(startDate).format('YYYY-MM-DD'): null, endDate ? moment(endDate).format( 'YYYY-MM-DD'): null));
    setChanges(false);
    setRunOnce(true);
    setStartDateOnRanReport(startDate);
    setEndDateOnRanReport(endDate);
  };
  useEffect(() => {
    setChanges(false);
    dispatch(clearTestingReport(tabValue));
  }, []);
  useEffect(() => {
    if (runOnce) {
      setChanges(true);
    }
  }, [startDate, endDate, selectedFilters]);
  return (
    <div className='page'>
      <Helmet>
        <title>{title} Generate Reports</title>
      </Helmet>
      <Paper>
        <UnitSelector />
      </Paper>
      <Grid  item xs={6} style={{ marginTop: '20px' }}>
        <Paper>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabValue}
            onChange={handleChange}
            textColor='primary'
            indicatorColor='primary'
            aria-label="scheduled/completed tabs"
          >
            <Tab value="individual" label={<span className='tab-label-size'>Individual Circuit</span>} />
            <Tab value="multi" label={<span className='tab-label-size'>Multi Circuit</span>} />
            <Tab value="regulator" label={<span className='tab-label-size'>Regulatory</span>} />
          </Tabs>
          <div className='date-selector'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                disableFuture
                value={startDate}
                onChange={(date:any) => setStartDate(date)}
                maxDate={endDate}
                sx={{ width: 200 }}
                slotProps={{
                  field: { clearable: true, onClear: () => setStartDate(null) },
                }}
              />
              <DatePicker
                label="End Date"
                disableFuture
                value={endDate}
                onChange={(date:any) => setEndDate(date)}
                minDate={startDate}
                sx={{ width: 200 }}
                slotProps={{
                  field: { clearable: true, onClear: () => setEndDate(null) },
                }}
              />
            </LocalizationProvider>
            <div style={{ marginTop: 'auto', marginBottom: '5px' }}>
              <Tooltip title={`Changes were made to filters run report again to update results`} className='alarm' data-testid='device-link'>
                <Badge color="warning" badgeContent={changes ? " ": null}>
                  <Button cta onClick={runReport}>Run Report</Button>
                </Badge>
              </Tooltip>

            </div>

          </div>

        </Paper>

        {tabValue === 'regulator' && <RegulatoryBodyReport filters={{ startDate: startDateOnRanReport, endDate: endDateOnRanReport, selectedFilters }}/>}
        {(tabValue === 'multi' || tabValue === 'individual') && <Paper style={{ marginTop: '20px' }}><p style={{ textAlign: 'center' }}>  Multi Circuit Reports  & Individual Circuit Reports Coming Soon!</p></Paper>}


      </Grid>

    </div>
  );
}
