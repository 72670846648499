import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as api from 'src/service/api/checkSheet';
import * as checkSheetActions from './action';
import * as deviceActions from '../device/action';

import toast from 'src/utils/toast';

export function * checkSheetRoot () {
  yield takeEvery(checkSheetActions.FETCH_CHECK_SHEET_TASKS, fetchCheckSheetTasks);
  yield takeLatest(checkSheetActions.SAVE_CHECK_SHEET, saveCheckSheet);
  yield takeLatest(checkSheetActions.UPDATE_CHECK_SHEET, updateCheckSheet);
  yield takeLatest(checkSheetActions.FETCH_CHECK_SHEET, fetchCheckSheet);



}
function * fetchCheckSheetTasks (action) {
  try {
    const tasks = yield call(api.getCheckSheetTasks, action);
    yield put(checkSheetActions.fetchCheckSheetTasksSuccess(tasks, action.checkSheetType));
  } catch (err: any) {
    yield put(checkSheetActions.fetchCheckSheetTasksFailed(Map(err)));
  }
}

function * saveCheckSheet (action) {
  try {
    const data = yield call(api.saveCheckSheet, action);
    yield put(checkSheetActions.saveCheckSheetSuccess(action.deviceId, action.checkSheetType, data));
    // add to device
    yield put(deviceActions.addDeviceCheckSheetSuccess(action.deviceId, action.checkSheetType, data.get('id')));
  } catch (err: any) {
    yield put(checkSheetActions.saveCheckSheetFailed(Map(err), action.checkSheetType));
    toast.error('Error saving check sheet');
  }
}
function * updateCheckSheet (action) {
  try {
    const data = yield call(api.updateCheckSheet, action);
    yield put(checkSheetActions.updateCheckSheetSuccess(action.deviceId, data, action.checkSheetType));
  } catch (err: any) {
    yield put(checkSheetActions.updateCheckSheetFailed(Map(err), action.checkSheetType));
    toast.error('Error saving check sheet');
  }
}
function * fetchCheckSheet (action) {
  try {
    const report = yield call(api.fetchCheckSheet, action);
    yield put(checkSheetActions.fetchCheckSheetSuccess( action.checkSheetType, report, action.checkSheetId));
  } catch (err: any) {
    yield put(checkSheetActions.fetchCheckSheetFailed(Map(err)));
    toast.error('Error fetching check sheet');
  }
}


export default checkSheetRoot;
