import { createSelector } from 'reselect';
import { Map } from 'immutable';

export const getWeatherForecastState = (state) =>
  state.weather;

export const getWeatherForecast = createSelector(
  [getWeatherForecastState],
  (weather) => {
    return weather.getIn(['weatherForecasts', 'data'], Map()) || Map();
  }
);
