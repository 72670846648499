import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Button from 'src/component/UI/Button';
import { fromJS } from 'immutable';
import { addRecipe, fetchRecipes, fetchDeviceSettingMap } from 'src/module/recipe/action';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';
import { setSettingsModalOpen, setSettingsModalClose } from 'src/module/device/action';
import { getSettingsModal } from 'src/module/device/selector';
import { getRecipes } from 'src/module/recipe/selector';
import Recipe from './Recipe';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';
import { Paper } from '@mui/material';
import DeviceRotate from 'src/component/UI/DeviceRotate';

export default function AllRecipes () {
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  const dispatch = useDispatch();
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);
  const settingsModal = useSelector(getSettingsModal(null));
  const recipes = useSelector(getRecipes);

  useEffect(() => {
    dispatch(fetchRecipes());
    dispatch(fetchDeviceSettingMap());
    dispatch(setSettingsModalClose(null));
  }, []);

  const makeToggleCreateRecipeModal = () => {
    return () => {
      toggleCreateRecipeModal();
    };
  };

  const toggleCreateRecipeModal = () => {
    if (!settingsModal?.get('isOpen')) {
      dispatch(setSettingsModalOpen(null, SettingsModalTypes.ADD_RECIPE));
    }
    else {
      dispatch(setSettingsModalClose(null));
    }
  };

  const handleRecipeSubmit = (rec: any) => {
    dispatch(addRecipe(rec));
  };

  const makeRecipeModal = () => {
    return (
      <SettingsModal
        open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.ADD_RECIPE}
        handleClose={makeToggleCreateRecipeModal()}
        handleSubmit={handleRecipeSubmit}
        title='Add Template Name'
        loading={settingsModal?.get('loading')}
        fields={
          fromJS({
            name: {
              type: 'text',
              label: 'Template Name',
              errorText: 'Required'
            }
          })
        }
      />
    );
  };

  return (
    <div id='recipe-screen' data-testid='recipe-screen'>
      {showRotateMessage ?
        <Paper><DeviceRotate /></Paper> :
        <>
          {makeRecipeModal()}

          {recipes.map((recipe: any, index) => <div className='recipe-bar' key={index}><Recipe recipe={recipe} key={index} isLast={index === recipes.toJS().length - 1} /></div>)}

          <div className='button-bar'>
            <Button onClick={toggleCreateRecipeModal} color="primary" variant="contained" fullWidth type="submit" cta icon={<AddIcon style={{ fontSize: 14 }} />}>Add Template</Button>
          </div>
        </>
      }

    </div>
  );
}
