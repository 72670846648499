import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/user/';

// prefetch

export const { fetchUsers, FETCH_USERS }: any = createTypedAction(
  `${PREFIX}FETCH_USERS`
);

export const { fetchUsersSuccess, FETCH_USERS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_USERS_SUCCESS`,
  ['users', yup.immutable().list().required()]
);
export const { triggerActivity, TRIGGER_ACTIVITY }: any = createTypedAction(
  `${PREFIX}TRIGGER_ACTIVITY`,
  ['isInactive', yup.boolean().required()],
  ['route', yup.string().required()]
);
export const { fetchUsersFailed, FETCH_USERS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_USERS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { inviteUser, INVITE_USER, inviteUserQueueFormatter }: any = createTypedAction(
  `${PREFIX}INVITE_USER`,
  (state) => {
    return `Invite a new user: ${state.firstName} ${state.lastName} (${state.email})`;
  },
  ['email', yup.string().required()],
  ['firstName', yup.string().required()],
  ['lastName', yup.string().required()]
);

export const { inviteUserSuccess, INVITE_USER_SUCCESS }: any = createTypedAction(
  `${PREFIX}INVITE_USER_SUCCESS`,
  ['user', yup.immutable().map().required()]
);

export const { inviteUserFailed, INVITE_USER_FAILED }: any = createTypedAction(
  `${PREFIX}INVITE_USER_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['user', yup.object().nullable()]
);


export const { removeUser, REMOVE_USER, removeUserQueueFormatter }: any = createTypedAction(
  `${PREFIX}REMOVE_USER`,
  (state, devices, alarms, panels, users) => {
    const user = users.find(x => x.get('id') === state.userId);
    return `remove user ${user.get('first_name')} ${user.get('last_name')}`;
  },
  ['userId', yup.string().required()]
);

export const { removeUserSuccess, REMOVE_USER_SUCCESS }: any = createTypedAction(
  `${PREFIX}REMOVE_USER_SUCCESS`,
  ['userId', yup.string().required()]
);

export const { removeUserFailed, REMOVE_USER_FAILED }: any = createTypedAction(
  `${PREFIX}REMOVE_USER_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['userId', yup.string().nullable()]
);

export const { updateUser, UPDATE_USER }: any = createTypedAction(
  `${PREFIX}UPDATE_USER`,
  ['userObj', yup.object().nullable()],
  ['preferences', yup.immutable().map().nullable()]
);

export const { updateUserSuccess, UPDATE_USER_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_SUCCESS`,
  ['user', yup.immutable().map().required()]
);

export const { updateUserNavBarPreference, UPDATE_USER_NAV_BAR_PREF }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_NAV_BAR_PREF`,
  ['user', yup.immutable().map().required()]
);

export const { updateUserFailed, UPDATE_USER_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { updateUserRole, UPDATE_USER_ROLE, updateUserRoleQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_ROLE`,
  (state, devices, alarms, panels, users) => {
    const user = users.find(x => x.get('id') === state.userId);
    return `update user ${user.get('first_name')} ${user.get('last_name')} role to ${state.role} `;
  },
  ['userId', yup.string().required()],
  ['role', yup.string().required()]
);

export const { updateUserRoleSuccess, UPDATE_USER_ROLE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_ROLE_SUCCESS`,
  ['userId', yup.string().required()],
  ['role', yup.string().required()]
);

export const { updateUserRoleFailed, UPDATE_USER_ROLE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_ROLE_FAILED`,
  ['userId', yup.string().required()],
  ['role', yup.string().required()]
);
