import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchBatchSchedules, FETCH_BATCH_SCHEDULES }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_SCHEDULES`,
  ['paginationDetails', yup.object().nullable()],
  ['tab', yup.string().default('scheduled')]
);

export const { fetchBatchSchedulesSuccess, FETCH_BATCH_SCHEDULES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_SCHEDULES_SUCCESS`,
  ['batchSchedules', yup.immutable().map().required()],
  ['tab', yup.string().default('scheduled')]
);

export const { fetchBatchSchedulesFailed, FETCH_BATCH_SCHEDULES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_SCHEDULES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateBatchSchedule, UPDATE_BATCH_SCHEDULE }: any = createTypedAction(
  `${PREFIX}UPDATE_BATCH_SCHEDULE`,
  ['batchId', yup.string().required()],
  ['batchSchedule', yup.object().required()]
);

export const { updateBatchScheduleSuccess, UPDATE_BATCH_SCHEDULE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_BATCH_SCHEDULE_SUCCESS`,
  ['batchSchedule', yup.immutable().map()]
);

export const { updateBatchScheduleFailed, UPDATE_BATCH_SCHEDULE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_BATCH_SCHEDULE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchBatch, FETCH_BATCH }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH`,
  ['batchId', yup.boolean().default(false)]
);

export const { fetchBatchSuccess, FETCH_BATCH_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_SUCCESS`,
  ['batch', yup.immutable().map().required()]
);

export const { fetchBatchFailed, FETCH_BATCH_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { initiateRunBatch, INITIATE_RUN_BATCH }: any = createTypedAction(
  `${PREFIX}INITIATE_RUN_BATCH`,
  ['batchId', yup.string().required()],
);

export const { initiateRunBatchSuccess, INITIATE_RUN_BATCH_SUCCESS }: any = createTypedAction(
  `${PREFIX}INITIATE_RUN_BATCH_SUCCESS`,
  ['batchId', yup.string().required()],
);

export const { initiateRunBatchFailed, INITIATE_RUN_BATCH_FAILED }: any = createTypedAction(
  `${PREFIX}INITIATE_RUN_BATCH_FAILED`,
  ['batchId', yup.string().required()],
  ['error', yup.string().required()],
);

export const { createBatch, CREATE_BATCH }: any = createTypedAction(
  `${PREFIX}CREATE_BATCH`,
  ['devices', yup.array().required()],
  ['recipeId', yup.string().required()],
  ['panelId', yup.object().nullable()],
  ['filter', yup.object().nullable()],
);

export const { createBatchSuccess, CREATE_BATCH_SUCCESS }: any = createTypedAction(
  `${PREFIX}CREATE_BATCH_SUCCESS`,
  ['batchSchedule', yup.immutable().map().required()]
);

export const { createBatchFailed, CREATE_BATCH_FAILED }: any = createTypedAction(
  `${PREFIX}CREATE_BATCH_FAILED`,
  ['error', yup.immutable().map().nullable()]
);

export const { validateBatch, VALIDATE_BATCH }: any = createTypedAction(
  `${PREFIX}VALIDATE_BATCH`,
  ['batchId', yup.string().required()],
  ['paginationDetails', yup.object().nullable()],
  ['filter', yup.object().nullable()],
);

export const { validateBatchFailed, VALIDATE_BATCH_FAILED }: any = createTypedAction(
  `${PREFIX}VALIDATE_BATCH_FAILED`,
  ['error', yup.immutable().map().nullable()],
);

export const { validateBatchSuccess, VALIDATE_BATCH_SUCCESS }: any = createTypedAction(
  `${PREFIX}VALIDATE_BATCH_SUCCESS`,
  ['batchSchedule', yup.immutable().map()],
);

export const { addBatchScheduleFailed, ADD_BATCH_SCHEDULE_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_BATCH_SCHEDULE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteScheduledDevice, DELETE_SCHEDULED_DEVICE }: any = createTypedAction(
  `${PREFIX}DELETE_SCHEDULED_DEVICE`,
  ['batchId', yup.string().required()],
  ['deviceId', yup.string().required()],
  ['paginationDetails', yup.object().nullable()],
  ['filter', yup.object().nullable()],
);

export const { deleteScheduledDeviceFailed, DELETE_SCHEDULED_DEVICE_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_SCHEDULED_DEVICE_FAILED`,
  ['error', yup.immutable().map().nullable()],
);

export const { deleteScheduledDeviceSuccess, DELETE_SCHEDULED_DEVICE_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_SCHEDULED_DEVICE_SUCCESS`,
  ['batchId', yup.string().required()],
  ['deviceId', yup.string().required()],
);


export const { fetchBatchStatus, FETCH_BATCH_STATUS }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_STATUS`,
  ['batchId', yup.string().required()]
);

export const { fetchBatchStatusSuccess, FETCH_BATCH_STATUS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_STATUS_SUCCESS`,
  ['batchId', yup.string().required()],
  ['batchStatus', yup.immutable().map().required()]
);

export const { fetchBatchStatusFailed, FETCH_BATCH_STATUS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_STATUS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchBatchDevices, FETCH_BATCH_DEVICES }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_DEVICES`,
  ['batchId', yup.string().required()],
  ['paginationDetails', yup.object().nullable()],
  ['filter', yup.object().nullable()],
);

export const { fetchBatchDevicesSuccess, FETCH_BATCH_DEVICES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_DEVICES_SUCCESS`,
  ['batchId', yup.string().required()],
  ['devices', yup.immutable().list()]
);

export const { fetchBatchDevicesFailed, FETCH_BATCH_DEVICES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_BATCH_DEVICES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { createBatchDeviceGroup, CREATE_BATCH_DEVICE_GROUP }: any = createTypedAction(
  `${PREFIX}CREATE_BATCH_DEVICE_GROUP`,
  ['batchId', yup.string().required()],
  ['name', yup.string().required()],
);

export const { createBatchDeviceGroupSuccess,  CREATE_BATCH_DEVICE_GROUP_SUCCESS }: any = createTypedAction(
  `${PREFIX} CREATE_BATCH_DEVICE_GROUP_SUCCESS`,
  ['batchId', yup.string().required()],
  ['name', yup.immutable().list()]
);

export const { createBatchDeviceGroupFailed,  CREATE_BATCH_DEVICE_GROUP_FAILED }: any = createTypedAction(
  `${PREFIX} CREATE_BATCH_DEVICE_GROUP_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
export const { fetchAllDeviceGroups, FETCH_ALL_DEVICE_GROUPS }: any = createTypedAction(
  `${PREFIX}FETCH_ALL_DEVICE_GROUPS`,
  ['paginationDetails', yup.object().nullable()],
);

export const { fetchAllDeviceGroupsSuccess, FETCH_ALL_DEVICE_GROUPS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ALL_DEVICE_GROUPS_SUCCESS`,
  ['groups', yup.immutable().list()],
);

export const { fetchAllDeviceGroupsFailed, FETCH_ALL_DEVICE_GROUPS_FAILED }: any = createTypedAction(
  `${PREFIX} FETCH_ALL_DEVICE_GROUPS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteDeviceGroup, DELETE_DEVICE_GROUP }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_GROUP`,
  ['groupId', yup.string().required()],
);

export const { deleteDeviceGroupSuccess, DELETE_DEVICE_GROUP_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_GROUP_SUCCESS`,
  ['groupId', yup.immutable().map()],
);

export const { deleteDeviceGroupFailed, DELETE_DEVICE_GROUP_FAILED }: any = createTypedAction(
  `${PREFIX} DELETE_DEVICE_GROUP_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
