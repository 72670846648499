import './AuthenticationForm.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Redirect, NavLink } from 'react-router-dom';
import yup from 'src/utils/yup';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import TextField from '@mui/material/TextField';
import Button from 'src/component/UI/Button';
import { isPasswordSecure } from 'src/utils/utils';

import { getIsValidatingJwt, getPasswordResetToken } from 'src/module/authentication/selector';

export default function PasswordSetForm (props) {
  const passwordResetToken = useSelector(getPasswordResetToken);
  const isValidatingJWT = useSelector(getIsValidatingJwt);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm: ''
    },
    validationSchema: () => {
      return yup.object().shape({
        password: yup.string()
          .required('Password can\'t be blank')
          .test('password-secure', 'Must be at least 8 characters, including a number, uppercase letter, lowercase letter, and a symbol', isPasswordSecure),
        confirm: yup.string()
          .oneOf([yup.ref('password'), null], 'Passwords don\'t match!')
          .required('Password confirmation can\'t be blank')
      });
    },
    onSubmit: async (values, { resetForm, setSubmitting, setFieldError }: any) => {
      resetForm();
      setSubmitting(true);


      try {
        await props.handlePasswordReset(passwordResetToken, values.password);
      } catch (err) {
        err && setFieldError('submit', List([err]));
      }

      setSubmitting(false);
    }
  });

  const {
    redirectTo
  } = props;

  if (passwordResetToken === false || passwordResetToken === 'SUCCESS') {
    return (
      <Redirect to={redirectTo || '/login'} />
    );
  }

  const pendingContent = (
    <div className='confirmation'>
      <h2>Checking Link...</h2>
      <h3>Please wait a moment while we verify your link</h3>
      <p>If this takes longer than a minute, please double check the emailed link try again.</p>
    </div>
  );

  const formContent = (
    <form className='auth-form' onSubmit={formik.handleSubmit}>
      <TextField
        id='password'
        type='password'
        label='New Password'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.errors.password && formik.touched.password}
        helperText={formik.touched.password && formik.errors.password}
        variant='outlined'
        fullWidth
        InputLabelProps={{ shrink: false }}
      />

      <TextField
        id='confirm'
        type='password'
        label='Confirm New Password'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.confirm}
        error={formik.errors.confirm && formik.touched.confirm}
        helperText={formik.touched.confirm && formik.errors.confirm}
        variant='outlined'
        fullWidth
        InputLabelProps={{ shrink: false }}
      />

      <div className='auth-form-buttons'>
        <Button type='submit' cta background loading={formik.isSubmitting}>Save new password</Button>
        <NavLink className='float-right' exact to='/login'>Back</NavLink>
      </div>
    </form>
  );

  return isValidatingJWT ? pendingContent : formContent;
}

PasswordSetForm.propTypes = {
  redirectTo: PropTypes.string,
  handlePasswordReset: PropTypes.func.isRequired
};

PasswordSetForm.defaultProps = {
  redirectTo: '/'
};
