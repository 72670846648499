import { createSelector } from 'reselect';
import { fromJS, List, Map } from 'immutable';

export const getAlarmState = (state) =>
  state.alarm;

export const getAlarmStats = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.get('alarmStats', Map()) || Map();
  }
);
export const getAlarmStatsLoading = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.get('alarmStatsLoading', false);
  }
);
export const getAlarms = createSelector(
  [getAlarmState],
  (alarm) => {
    const allData = alarm?.getIn(['alarms', 'data'], List());
    const pageIds = alarm?.getIn(['alarmActivePage', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return pageData;
  }
);

export const getLoadingAlarms = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmActivePage', 'loading']);
  }
);

export const getNumAlarmPages = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmActivePage', 'totalPages']);
  }
);

export const getTotalAlarms = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmActivePage', 'total']);
  }
);

export const getAlarmHistory = createSelector(
  [getAlarmState],
  (alarm) => {
    const allData = alarm?.getIn(['alarms', 'data'], List());
    const pageIds = alarm?.getIn(['alarmHistoryPage', 'records'], List());
    const pageData = [];
    pageIds.map((id) => {
      pageData.push(allData.getIn([id, 'record']));
    });
    return fromJS(pageData);
  }
);

export const getAlarmHistoryLoading = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmHistoryPage', 'loading']);
  }
);
export const getAlarmHistoryTotal = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmHistoryPage', 'total']);
  }
);
export const getAlarmHistoryNumPages = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmHistoryPage', 'totalPages']);
  }
);

export const getDevicesInAlarmWithoutNotification = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['devicesInAlarm', 'withoutNotification'], List());
  }
);

export const getCriticalAlarmsWithoutNotification = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmStats', 'criticalAlarmsWithoutNotification'], null);
  }
);

export const getDevicesInAlarmLoading = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['devicesInAlarm', 'loading'], false);
  }
);


export const getAlarmHistoricalSummary = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmHistoricalSummary', 'data'], List()) || List();
  }
);

export const getAlarmHistoricalSummaryLoading = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarmHistoricalSummary', 'loading'], false);
  }
);

export const getAllAlarms = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.getIn(['alarms', 'data'], Map());
  }
);

export const getAlarmGroups = createSelector(
  [getAlarmState],
  (alarm) => {
    return alarm.get('alarmGroups', List());
  }
);
