import './Pagination.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from './Button';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import variables from 'src/style/variable/variables.module.scss';

export default function Pagination ({
  pageIndex,
  pageSize,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  totalCount,
  pageOptions,
  gotoPage,
  setPageSize,
  ...rest
}) {
  const {
    pageSizeOptions,
    canGotoPage,
    isLoading
  } = rest;

  const pageItemMax = ((pageIndex + 1) * pageSize);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);

  return (
    <div data-testid='pagination' className="pagination">
      {isLoading && (<LogoSpinner size={64} />)}
      {pageSizeOptions && (<div>
        <span>{isMobile ? 'Rows' : 'Rows per page'}:&nbsp;</span>
        <Select
          data-testid="rows-per-page"
          value={pageSize}
          variant="standard"
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeOptions.map(pageSize => (
            <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
          ))}
        </Select>
      </div>)}

      <div>
        <span>{(pageIndex * pageSize) + 1}-{pageItemMax > totalCount ? totalCount : pageItemMax} of {totalCount}</span>
      </div>

      {canGotoPage && (<div>
        <span>Go to page:&nbsp;</span>
        <Select
          disableUnderline
          data-testid="go-to-page-select"
          value={pageIndex}
          onChange={e => {
            gotoPage(Number(e.target.value));
          }}
        >
          {pageOptions.map(pageIndex => (
            <MenuItem key={pageIndex} value={pageIndex}>{pageIndex + 1}</MenuItem>
          ))}
        </Select>
      </div>)}

      <div className='pagination-button-row'>
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} compact icon={<FirstPageIcon style={{ color: '#444', fontSize: 24 }} />} />
        <Button onClick={() => previousPage()} disabled={!canPreviousPage} compact icon={<NavigateBeforeIcon style={{ color: '#444', fontSize: 24 }} />} />
        <Button onClick={() => nextPage()} disabled={!canNextPage} compact icon={<NavigateNextIcon style={{ color: '#444', fontSize: 24 }} />} />
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} compact icon={<LastPageIcon style={{ color: '#444', fontSize: 24 }} />} />
      </div>

    </div>
  );
}

Pagination.propTypes = {
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  pageOptions: PropTypes.array,
  gotoPage: PropTypes.func,
  setPageSize: PropTypes.func
};

Pagination.defaultProps = {
};
