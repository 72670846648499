import { createSelector } from 'reselect';
import { Map } from 'immutable';

export const getPermissionState = (state) =>
  state.permission;

export const getRootState = (state) =>
  state;

export const getPermissions = createSelector(
  [getPermissionState],
  (permission) => {
    return permission.getIn(['permissions', 'data'], Map());
  }
);
