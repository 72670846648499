import * as Sentry from '@sentry/browser';

export async function configureSentry () {
  if (process.env.REACT_APP_SENTRY_DSN) {
    try {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV || 'development',
        release: process.env.REACT_APP_FOX_RELEASE_HASH || 'dev',
        sampleRate: 1.0,
        maxBreadcrumbs: 100,
        attachStacktrace: true,
        // sendDefaultPii: false,
        integrations: [
          // new Sentry.Integrations.Dedupe(),
          new Sentry.Integrations.FunctionToString(),
          new Sentry.Integrations.UserAgent(),
          new Sentry.Integrations.GlobalHandlers({
            onerror: false,
            onunhandledrejection: true
          }),
          new Sentry.Integrations.Breadcrumbs({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            sentry: true,
            xhr: true
          })
        ],
        defaultIntegrations: false
      });
    } catch (err: any) {
      console.error(`Unable to init Sentry. Error: ${err.message}`, { error: err }); // eslint-disable-line no-console
    }
  }
}

export default configureSentry;
