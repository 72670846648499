import React from 'react';
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ImportForm from 'src/component/UI/ImportForm';
import UploadStatusTable from 'src/component/UI/UploadStatusTable';

import { getUploads } from 'src/module/import/selector';
import { importData } from 'src/module/import/action';
import { isWhitelabel } from 'src/utils/utils';

export default function ImportScreen () {
  const uploads = useSelector(getUploads);
  const uploadData: Array<any> = [];
  uploads.keySeq().forEach((key) => {
    uploadData.push({ name: uploads.getIn([key, 'file', 'name']), status: uploads.getIn([key, 'status']) });
  });

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div id='import' className='page'>
      <Helmet>
        <title>{title} - Import Data</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Paper>
            <Grid item xs={12} md={12}>
              <ImportForm importAction={importData}/>
              <h2>Uploads</h2>
              <UploadStatusTable uploadData={uploadData} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
