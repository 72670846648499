import './FileUpload.scss';
import './SettingsModal/SettingsModal.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/component/UI/Button';
import Modal from '@mui/material/Modal';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import ImportForm from './ImportForm';
import { uploadDeviceFile, removeDeviceFile, fetchDeviceFiles, fetchDeviceFile, setSettingsModalOpen, setSettingsModalClose, updateDeviceFilesDescription, updateDeviceFilesSubType, updateDeviceFileContents } from 'src/module/device/action';
import DataTable from 'src/component/UI/DataTable';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import UpdateIcon from '@mui/icons-material/UpdateOutlined';
import Grid from '@mui/material/Grid';
import { getDeviceFiles, getDeviceFilesLoading } from 'src/module/device/selector';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsModal } from 'src/module/device/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import PermissionFence from '../PermissionFence';
import EditableCell from './EditableCell';
import Tooltip from '@mui/material/Tooltip';
import { List } from 'immutable';
import { MenuItem, Select } from '@mui/material';

export default function FileUpload (props) {
  const {
    deviceId
  } = props;

  const files: any = useSelector(getDeviceFiles(deviceId));
  const [showUploadModal, setShowUpLoadModal] = useState(false);
  const settingsModal = useSelector(getSettingsModal(deviceId));
  const filesLoading = useSelector(getDeviceFilesLoading(deviceId));

  const settingsModalIsLoading = settingsModal?.get('loading');
  const settingsModalIsOpen = settingsModal?.get('isOpen');
  const settingsModalId = settingsModal?.get('id');
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState(null);

  useEffect(() => {
    setShowUpLoadModal(settingsModalIsOpen && settingsModalId === SettingsModalTypes.DEVICE_FILE_UPLOAD);
  }, [settingsModal]);

  useEffect(() => {
    dispatch(fetchDeviceFiles(deviceId));
  }, [deviceId]);

  const onFileClick = (file) => {
    // get file and download it
    dispatch(fetchDeviceFile(file.name, file.created_at, deviceId, file.id));
  };
  const onFileDelete = (file) => {
    // delete file
    dispatch(removeDeviceFile(file.name, file.created_at, deviceId, file.id));
  };
  const onFileUpdate = (file) => {
    setUploadFile(file);
    dispatch(setSettingsModalOpen(deviceId, SettingsModalTypes.DEVICE_FILE_UPLOAD));
  };

  const updateFileDescription = (file, description) => {
    dispatch(updateDeviceFilesDescription( description, deviceId, file.id));
  };


  const clickOnUpload = () => {
    dispatch(setSettingsModalOpen(deviceId, SettingsModalTypes.DEVICE_FILE_UPLOAD));
  };
  const clickOnClose = () => {
    setUploadFile(null);
    dispatch(setSettingsModalClose(deviceId));
  };
  const onChangeOfFileType = (file, e) => {
    dispatch(updateDeviceFilesSubType(e.target.value, deviceId, file.id));

  };

  return (
    <div className='file-upload-container'>
      {showUploadModal ?
        <Modal
          data-testid='settings-modal'
          open={showUploadModal}
          onClose={clickOnClose}
          aria-labelledby='settings-modal-title'
        >
          <div className={'modal settings-modal'}>
            <div className='settings-modal-top-bar'>
              <h2 className='settings-modal-title'>File Upload</h2>
              {settingsModalIsLoading ? <LogoSpinner size={70} /> : null}
            </div>
            <ImportForm importAction={uploadFile && uploadFile?.version ? updateDeviceFileContents : uploadDeviceFile} deviceId={deviceId} maxSize={20971520} uploadId={uploadFile?.id} version={uploadFile?.version}/>
            <div className='button-bar'>
              <Button onClick={clickOnClose}>{'Cancel'}</Button>
            </div>
          </div>

        </Modal>
        : null
      }
      <div>
        <h3>Device Attachments</h3>
        {filesLoading ? <div style={{ textAlign: 'center' }}><LogoSpinner size={70} /></div> :
          <Grid item>
            <div className='fileTable'>
              <DataTable
                columns={[

                  {
                    Header: 'File Name',
                    accessor: (row: any) => {
                      return (row.name.length > 9 ?
                        <Tooltip title={row.name}><p className='fileName' onClick={() => onFileClick(row)} >{row.name}</p></Tooltip> :
                        <p className='fileName' onClick={() => onFileClick(row)} >{row.name}</p>);
                    }
                  },
                  {
                    Header: 'Description',
                    accessor: (row: any) => row.description,
                    Cell: (row: any) => { return (
                      <EditableCell className='description-cell' onChange={(value) => updateFileDescription(row.cell.row.original, value)} value={row.cell.value} emptyCellLabel='Add Description' />
                    ); },
                  },
                  {
                    Header: 'File Type',
                    accessor: (row: any) => row.sub_type,
                    Cell: (row: any) => { return (     <Select
                      variant="standard"
                      value={row.cell.value ?? `Other`}
                      onChange={(value) => onChangeOfFileType(row.cell.row.original, value)}
                    >
                      {['Testing-Report', 'Splice-Report', 'Other', 'Drawing'].map(opt => (
                        <MenuItem key={opt} value={opt}>{opt?.replace('-', ' ')}</MenuItem>
                      )
                      )}
                    </Select>); }
                  },
                  {
                    Header: ' ',
                    disableSortBy: true,
                    Cell: (row: any) => (
                      <div className='file-actions'>
                        <PermissionFence can={['edit-device']}>
                          <Button onClick={() => onFileDelete(row.cell.row.original)} icon={<DeleteIcon style={{ fontSize: 14 }} />} />
                          {row.cell.row.original?.version && (
                            <Tooltip title={`Update '${row.cell.row.original.name}' to version ${row.cell.row.original.version + 1}`}>
                              <div>
                                <Button onClick={() => onFileUpdate(row.cell.row.original)} icon={<UpdateIcon style={{ fontSize: 14 }} />} />
                              </div>
                            </Tooltip>
                          )}
                        </PermissionFence>
                      </div>
                    )
                  }
                ]}
                data={List(files)}
                tableId='fileUploadTable'
                columnWidths={['20%', '30%', '30%', '20%']}
              />
            </div>
            <Grid item xs={12} md={12} className='button-bar'>
              <PermissionFence can={['edit-device']}>
                <Button onClick={clickOnUpload} cta>Upload File</Button>
              </PermissionFence>
            </Grid>
          </Grid>

        }
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  deviceId: PropTypes.string
};

FileUpload.defaultProps = {
  deviceId: null
};
