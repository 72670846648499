import _ from 'lodash';
export const actionsWithOfflineCapabilities = [
  '@@fox/dashboard/UPDATE_IS_OFFLINE',
  '@@fox/dashboard/SET_SETTINGS_MODAL_OPEN',
  '@@fox/dashboard/SET_SETTINGS_MODAL_CLOSE',
  '@@fox/dashboard/SET_SELECTED_UNIT',
  '@@fox/dashboard/SET_SELECTED_SYSTEM',
  '@@fox/dashboard/SET_SELECTED_PRIORITY',
  '@@fox/dashboard/SET_SELECTED_COMMODITY',
  '@@fox/dashboard/SET_SELECTED_LOCATION',
  '@@fox/dashboard/SET_SELECTED_CONTROLLER',
  '@@fox/dashboard/SET_SELECTED_DEAD_LEG',
  '@@fox/dashboard/CLEAR_CONTROL_PANEL_TAGS',
  '@@fox/dashboard/ADD_TO_QUEUE',
  '@@router/LOCATION_CHANGE',
  '@@fox/authentication/REHYDRATE',
  '@@fox/autobahn/REGISTER_CHANNEL',
  '@@INIT',
  '@@fox/authentication/REHYDRATE_SUCCESS',
  '@@fox/autobahn/START',
  '@@fox/autobahn/AUTHENTICATE',
  '@@fox/autobahn/PUBLISH',
  '@@fox/dashboard/CLEAR_DEVICE_TAGS',
  '@@fox/dashboard/LOAD_DEVICES_FROM_CIRCUITS_LIST',
  '@@fox/user/TRIGGER_ACTIVITY',
  '@@fox/dashboard/CLEAR_QUEUE',
  '@@fox/dashboard/REMOVE_FROM_QUEUE'
];

// QueueFormatter
import * as deviceActions from 'src/module/device/action';
import * as circuitActions from 'src/module/circuit/action';
import * as noteActions from 'src/module/note/action';
import * as controllerActions from 'src/module/controller/action';
import * as workTicketActions from 'src/module/workTicket/action';
import * as alarmActions from 'src/module/alarm/action';
import * as userActions from 'src/module/user/action';
import * as authenticationActions from 'src/module/authentication/action';
import * as ruleActions from 'src/module/rule/action';

const actions = _.merge(
  deviceActions,
  circuitActions,
  noteActions,
  controllerActions,
  workTicketActions,
  alarmActions,
  userActions,
  authenticationActions,
  ruleActions,
);

export const actionsFormatterMap = {};
Object.entries(actions).forEach( ([key, val]) => {
  if (key.endsWith('QueueFormatter')) {
    actionsFormatterMap[val['action']] = val['formatter'];
  }
});
