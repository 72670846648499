import { createSelector } from 'reselect';
import { List } from 'immutable';
export const getRecipeState = (state) =>
  state.recipe;


export const getRecipe = (id) => createSelector(
  [getRecipeState],
  (recipe) => {
    return recipe?.getIn(['recipes', 'data', id, 'record'], null);
  }
);

export const getRecipes = createSelector(
  [getRecipeState],
  (recipe) => {
    const allData = recipe?.getIn(['recipes', 'data'], List());
    const pageIds = recipe?.getIn(['recipes', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return List(pageData);
  }
);
export const getDeviceWithSetting = (setting) => createSelector(
  [getRecipeState],
  (recipe) => {
    if (setting.includes('.')) {
      const [temp, num, property] = setting.split('.');
      return recipe.getIn(['recipes', 'settingsMap', temp, num, property], List());
    }
    return recipe.getIn(['recipes', 'settingsMap', setting], List());
  }
);

export const getLoadingRecipes = createSelector(
  [getRecipeState],
  (recipe) => {
    return recipe.getIn(['recipes', 'loading']) || false;
  }
);
