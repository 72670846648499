import './AppBar.scss';

import React from 'react';

export default function AppBar () {
  return (
    <div data-testid='app-bar' className='app-bar app-bar-admin'>
      <div className='app-bar-container'>
        <div className='app-bar-main'>
          <span className='admin-title-text'>Administrator Portal </span>
        </div>
      </div>
    </div>
  );
}
