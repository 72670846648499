import './NoteModal.scss';

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { Map } from 'immutable';
import humps from 'humps';
import { useFormik } from 'formik';
import yup from 'src/utils/yup';
import NotesIcon from '@mui/icons-material/BorderColorOutlined';
import Button from 'src/component/UI/Button';
import DeviceSelectorField from 'src/component/UI/DeviceSelectorField';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { getSingularWorkTicketLabel } from 'src/module/authentication/selector';


const modalTitlesByNoteType = {
  note: 'Add Note',
  loto: 'Add LOTO',
  nuisance: 'Add Nuisance',
  any: 'Add Note'
};

type Note = {
  deviceId: string,
  subject: string,
  note: string,
  workTicket?: string,
  alarm?: string,
  expiredAt: Date,
  noteCreatedAt: Date,
};

export default function NoteModal (props) {
  const noteTypeRef = React.useRef(props.noteType); // persistent note type
  const initialNoteTypeRef = React.useRef(props.noteType); // initial note type set by parent
  const [noteType, setNoteType] =  useState(props.noteType); // note type selected by user
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);

  const formik = useFormik({
    initialValues: {
      deviceId: props.deviceId,
      subject: '',
      note: initialNoteTypeRef.current === 'any' ? 'note' : initialNoteTypeRef.current,
      workTicket: '',
      alarm: '',
      showAlarm: noteTypeRef.current === 'nuisance',
      expiredAt: moment().format('YYYY-MM-DD'),
      createdAt: moment().format('YYYY-MM-DD'),
    },
    onSubmit: values => {
      const note: Note = {
        deviceId: values.deviceId,
        subject: values.subject,
        note: values.note,
        expiredAt: moment(values.expiredAt, 'YYYY-MM-DD').toDate(),
        noteCreatedAt: moment(values.createdAt, 'YYYY-MM-DD').toDate(),
      };
      if (values.workTicket.trim() !== '') {
        note.workTicket = values.workTicket;
      }
      if (values.alarm.trim() !== '') {
        note.alarm = values.alarm;
      }
      props.handleSubmit(Map(humps.decamelizeKeys(note)));
      formik.resetForm();
    },
    validationSchema: () => {
      return yup.object().shape({
        deviceId: yup
          .string('Device'),
        note: yup
          .string('Description')
          .required(),
        workTicket: yup
          .string(singularWorkTicketLabel),
        showAlarm: yup
          .bool(),
        alarm: yup
          .string('Alarm')
          .when("showAlarm", {
            is: true,
            then: yup.string().required("Alarm Is Required")
          }),
        expiredAt: yup
          .string('Expiry Date'),
        createdAt: yup
          .string('Creation Date'),
      });
    },
  });

  const handleChangeDeviceId = (value) => {
    if ( value?.id && value?.id.trim() !== '' ) {
      formik.setFieldValue('deviceId', value.id, false);
    }
  };

  const handleChangeNote = (event) => {
    const val = event.target.value as string;
    formik.setFieldValue('note', val, false);
    noteTypeRef.current = val;
    setNoteType(val);
  };

  const clearValues = () => {
    formik.resetForm();
  };

  useEffect(() => {
    noteTypeRef.current = props.noteType ? props.noteType : 'note';
    formik.setFieldValue('showAlarm', noteTypeRef.current === 'nuisance' || noteType === 'nuisance', false);
  }, [props.noteType]);

  useEffect(() => {
    handleChangeDeviceId(props.deviceId);
  }, [props.deviceId]);

  useEffect(() => {
    if (noteType === 'nuisance') {
      formik.setFieldValue('showAlarm', true, false);
    }
  }, [noteType]);

  // this will clear the formik values when the component is unmounted
  // to make sure we're not persisting the state of the last note
  useEffect(() => {
    return clearValues;
  }, []);


  return (
    <Modal
      data-testid='note-modal'
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='note-modal-title'
    >
      <div id='notes-model' className='modal note-modal'>
        <h2 id='note-modal-title'>{modalTitlesByNoteType[props.noteType]}</h2>
        <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item container direction="column" spacing={3} md={6}>
              {props.hideDeviceSelector ? null : <DeviceSelectorField deviceId={props.deviceId} readonly={false}
                onChange={handleChangeDeviceId} error={Boolean(formik.errors.deviceId)} helperText={formik.errors.deviceId} />}
              <FormControl className='alarm-select-field' variant='outlined'>
                <InputLabel id={`note-input-label`} error={Boolean(formik.errors.note)}>
                  Type
                </InputLabel>
                <Select
                  id={'note'}
                  labelId={`note`}
                  name={'note'}
                  label="Type"
                  value={noteTypeRef.current == 'any' ? 'note' : noteTypeRef.current}
                  error={Boolean(formik.errors.note)}
                  onChange={handleChangeNote}
                  disabled={initialNoteTypeRef.current !== 'any'}
                >
                  <MenuItem value={'note'}>Note</MenuItem>
                  <MenuItem value={'loto'}>LOTO</MenuItem>
                  <MenuItem value={'nuisance'}>Nuisance</MenuItem>
                </Select>
                <FormHelperText error={Boolean(formik.errors.note)} >
                  {(formik.errors.note ?
                    <>
                      {formik.errors.note}<br />
                    </> : null)}
                </FormHelperText>
              </FormControl>
              {
                noteTypeRef.current === 'loto' || noteType === 'loto' ?
                  (<TextField id='workTicket' name='workTicket' value={formik.values.workTicket} label={singularWorkTicketLabel}
                    onChange={formik.handleChange} error={Boolean(formik.errors.workTicket)} helperText={formik.errors.workTicket} inputProps={{ maxLength: 255 }} InputLabelProps={{ shrink: false }} />)
                  : noteTypeRef.current === 'nuisance' || noteType === 'nuisance' ?
                    (<FormControl className='alarm-select-field' variant='outlined'>
                      <InputLabel id={`alarm-input-label`} error={Boolean(formik.errors.alarm)}>
                        Alarm
                      </InputLabel>
                      <Select
                        id={'alarm-select'}
                        labelId={`alarm-select-label`}
                        name={'alarm'}
                        label="Alarm"
                        value={formik.values.alarm}
                        error={Boolean(formik.errors.alarm)}
                        onChange={formik.handleChange}
                      >
                        <MenuItem value={'low temp'}>Low Temp</MenuItem>)
                        <MenuItem value={'low load'}>Low Load</MenuItem>)
                      </Select>
                      <FormHelperText error={Boolean(formik.errors.alarm)} >
                        {(formik.errors.alarm ?
                          <>
                            {formik.errors.alarm}<br />
                          </> : null)}
                      </FormHelperText>
                    </FormControl>) : null
              }
              <TextField id='expiredAt' name='expiredAt' value={formik.values.expiredAt} label='Expires On' type={'date'}
                onChange={formik.handleChange} error={Boolean(formik.errors.expiredAt)} helperText={formik.errors.expiredAt} inputProps={{ maxLength: 64 }} InputLabelProps={{ shrink: false }} />
              <TextField id='createdAt' name='createdAt' value={formik.values.createdAt} label='Created On' InputLabelProps={{ shrink: false }}  disabled={true} />
            </Grid>
            <Grid item container direction="column" spacing={3} md={6}>
              <TextField id='subject' name='subject' value={formik.values.subject} label='Note'
                onChange={formik.handleChange} error={Boolean(formik.errors.subject)}
                helperText={formik.errors.subject} inputProps={{ maxLength: 255 }} InputLabelProps={{ shrink: false }}
                multiline minRows={9} />
            </Grid>
          </Grid>
          <div className='button-bar'>
            <Button onClick={props.handleClose}>Cancel</Button>
            <Button type='submit' icon={<NotesIcon style={{ fontSize: 14 }} />} cta>Submit</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

NoteModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  deviceId: PropTypes.string,
  noteType: PropTypes.oneOf(['note', 'loto', 'nuisance', 'any', 'all']),
  readOnlyCircuit: PropTypes.bool,
  hideDeviceSelector: PropTypes.bool
};

NoteModal.defaultProps = {
  deviceId: '',
  noteType: 'note',
  readOnlyCircuit: false,
  hideDeviceSelector: false
};
