import './AuthenticationForm.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import TextField from '@mui/material/TextField';
import Button from 'src/component/UI/Button';
import { NavLink } from 'react-router-dom';
import yup from 'src/utils/yup';
import microsoft from '../../assets/icons/microsoft.png';
import { getIsAuthenticating } from 'src/module/authentication/selector';
import { Divider } from '@mui/material';

export default function AuthenticationForm (props) {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: values => {
      props.handleAuthentication(values.email, values.password);
    },
    validationSchema: () => {
      return yup.object().shape({
        email: yup.string()
          .email('Invalid email')
          .required('Email can\'t be blank')
      });
    },
  });

  const regularDomains = [
    `https://dashboard.staging.smarttrace.ai`,
    `https://my.smarttrace.ai`
  ];

  const isAuthenticating = useSelector(getIsAuthenticating);

  return (
    <>

      {!regularDomains.find((domain) => window.location.href.startsWith(domain)) && // assume azure authentication is available for 'vanity' domains
        <>
          <div className='other-auth-options'>
            <p>Login with your Microsoft account.</p>

            <a className='auth-option' href={`${process.env.REACT_APP_FOX_API_ADDR}/v1/auth/azure`}>
              <img src={microsoft} height={'20px'} />
              <p>Log in With Microsoft</p>
            </a>
          </div>
          <Divider>Or</Divider>
        </>}

      <div className='main-sign-in'>
        <form className='auth-form' method='post' onSubmit={e => { e.preventDefault(); formik.handleSubmit(); }}>
          <TextField
            id='email'
            type='email'
            label='Email'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            disabled={isAuthenticating}
            error={formik.errors.email && formik.touched.email}
            helperText={formik.touched.email && formik.errors.email}
            variant='outlined'
            fullWidth
            InputLabelProps={{ shrink: false }}
          />
          <TextField
            id='password'
            type='password'
            label='Password'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            name='password'
            disabled={isAuthenticating}
            variant='outlined'
            fullWidth
            InputLabelProps={{ shrink: false }}
          />
          <NavLink className='reset-link' exact to='/login/reset'>Forgot your password?</NavLink>

          <div className='auth-form-buttons'>
            <Button cta type='submit' loading={isAuthenticating}>{isAuthenticating ? 'Signing in...' : 'Sign me in'}</Button>
          </div>

        </form>
      </div>
    </>


  );
}

AuthenticationForm.propTypes = {
  handleAuthentication: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  isAuthenticating: PropTypes.bool
};

AuthenticationForm.defaultProps = {
};
