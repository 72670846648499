import moment from 'moment';
import yup from 'src/utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchCircuits, FETCH_CIRCUITS }: any = createTypedAction(
  `${PREFIX}FETCH_CIRCUITS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['optionalFilters', yup.immutable().map()], // filter by component or user
  ['page', yup.number()],
  ['pageSize', yup.number()],
  ['sort', yup.string().nullable()],
);

export const { fetchCircuitsSuccess, FETCH_CIRCUITS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CIRCUITS_SUCCESS`,
  ['circuits', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['totalPages', yup.number().required()]
);

export const { fetchCircuitsFailed, FETCH_CIRCUITS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CIRCUITS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateCircuit, UPDATE_CIRCUIT, updateCircuitQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_CIRCUIT`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.circuitId, 'record', 'info', 'tag']);
    const controller_id = devices?.getIn([state.circuitId, 'record', 'info', 'parent_id']);
    const controllerTag = devices?.getIn([controller_id, 'record', 'info', 'tag']);

    return `update ${JSON.stringify(state.circuit.toJS())} on circuit ${state.controllerTag ??controllerTag}/${deviceTag} `;
  },
  ['circuitId', yup.string().required()],
  ['circuit', yup.immutable().map().required()],
  ['controllerTag', yup.string().nullable()]
);

export const { updateCircuitSuccess, UPDATE_CIRCUIT_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_CIRCUIT_SUCCESS`,
  ['circuitTag', yup.string().required()],
  ['controllerTag', yup.string().required()]
);

export const { deleteCircuitsOfController, DELETE_CIRCUITS_OF_CONTROLLER }: any = createTypedAction(
  `${PREFIX}DELETE_CIRCUITS_OF_CONTROLLER`,
  ['circuits', yup.immutable().list().required()],
);

export const { updateCircuitFailed, UPDATE_CIRCUIT_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_CIRCUIT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { fetchForcedOnCircuits, FETCH_FORCED_ON_CIRCUITS }: any = createTypedAction(
  `${PREFIX}FETCH_FORCED_ON_CIRCUITS`,
  ['selectedFilters', yup.immutable().map().nullable()],
  ['paginationDetails', yup.object().nullable()],
  ['includeArchived', yup.boolean().required().default(false)],
  ['expiredOnly', yup.boolean().required().default(false)],
  ['deviceId', yup.string().nullable()]
);

export const { fetchForcedOnCircuitsSuccess, FETCH_FORCED_ON_CIRCUITS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_FORCED_ON_CIRCUITS_SUCCESS`,
  ['forcedOnCircuits', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['totalPages', yup.number()]
);

export const { fetchForcedOnCircuitsFailed, FETCH_FORCED_ON_CIRCUITS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_FORCED_ON_CIRCUITS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { updateForcedOnCircuit, UPDATE_FORCED_ON_CIRCUIT }: any = createTypedAction(
  `${PREFIX}UPDATE_FORCED_ON_CIRCUIT`,
  ['forcedOnCircuitId', yup.string().required()],
  ['forcedOnCircuit', yup.immutable().map().required()]
);

export const { updateForcedOnCircuitSuccess, UPDATE_FORCED_ON_CIRCUIT_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_FORCED_ON_CIRCUIT_SUCCESS`,
  ['forcedOnCircuit', yup.immutable().map().required()]
);

export const { updateForcedOnCircuitFailed, UPDATE_FORCED_ON_CIRCUIT_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_FORCED_ON_CIRCUIT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { archiveForcedOnCircuit, ARCHIVE_FORCED_ON_CIRCUIT }: any = createTypedAction(
  `${PREFIX}ARCHIVE_FORCED_ON_CIRCUIT`,
  ['forcedOnCircuitId', yup.string().required()],
);

export const { archiveForcedOnCircuitSuccess, ARCHIVE_FORCED_ON_CIRCUIT_SUCCESS }: any = createTypedAction(
  `${PREFIX}ARCHIVE_FORCED_ON_CIRCUIT_SUCCESS`,
  ['forcedOnCircuit', yup.immutable().map().required()]
);

export const { archiveForcedOnCircuitFailed, ARCHIVE_FORCED_ON_CIRCUIT_FAILED }: any = createTypedAction(
  `${PREFIX}ARCHIVE_FORCED_ON_CIRCUIT_FAILED`,
  ['forcedOnCircuit', yup.immutable().map().nullable()]
);

export const { invalidateArchivedForcedOnCircuit, INVALIDATE_ARCHIVED_FORCED_ON_CIRCUIT }: any = createTypedAction(
  `${PREFIX}INVALIDATE_ARCHIVED_FORCED_ON_CIRCUIT`,
  ['rowId', yup.number().required()]
);

export const { addForcedOnCircuit, ADD_FORCED_ON_CIRCUIT, addForcedOnCircuitQueueFormatter }: any = createTypedAction(
  `${PREFIX}ADD_FORCED_ON_CIRCUIT`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.forcedOnCircuit.get('circuit_id'), 'record', 'info', 'tag']);
    const controller_id = devices?.getIn([state.forcedOnCircuit.get('circuit_id'), 'record', 'info', 'parent_id']);
    const parentTag = devices?.getIn([controller_id, 'record', 'info', 'tag']);
    const date = moment(state.forcedOnCircuit.get('forced_on_at')).format('YYYY-MM-DD HH:mm');
    return `forced on device ${parentTag || ''}${ parentTag ? '/' : ''}${deviceTag || ''} at ${date} ${state.forcedOnCircuit.get('note') ?
      `with note ${state.forcedOnCircuit.get('note')}` : `with no note`
    }`;

  },
  ['forcedOnCircuit', yup.immutable().map().nullable()]
);

export const { addForcedOnCircuitSuccess, ADD_FORCED_ON_CIRCUIT_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_FORCED_ON_CIRCUIT_SUCCESS`,
  ['forcedOnCircuit', yup.immutable().map().required()]
);

export const { addForcedOnCircuitFailed, ADD_FORCED_ON_CIRCUIT_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_FORCED_ON_CIRCUIT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { addCircuit, ADD_CIRCUIT, addCircuitQueueFormatter }: any = createTypedAction(
  `${PREFIX}ADD_CIRCUIT`,
  (state, devices) => {
    const parentTag = devices?.getIn([state.circuit.get('parent_id'), 'record', 'info', 'tag']);
    const ipAddress = devices?.getIn([state.circuit.get('parent_id'), 'record', 'info', 'ip_address']);
    return `added new circuit ${state.circuit.get('tag')}${parentTag ? ` to controller ${parentTag}` : ``}${ipAddress ? ` IP address ${ipAddress}` : ''}`;
  },
  ['circuit', yup.immutable().map().required()]
);

export const { addCircuitSuccess, ADD_CIRCUIT_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_CIRCUIT_SUCCESS`,
  ['circuit', yup.immutable().map().required()]
);

export const { addCircuitFailed, ADD_CIRCUIT_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_CIRCUIT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteCircuit, DELETE_CIRCUIT, deleteCircuitQueueFormatter }: any = createTypedAction(
  `${PREFIX}DELETE_CIRCUIT`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.circuitId, 'record', 'info', 'tag']);
    const parent_id = devices?.getIn([state.circuitId, 'record', 'info', 'parent_id']);
    const controllerTag = devices?.getIn([parent_id, 'record', 'info', 'tag']);
    return `${state.controllerTag ?? controllerTag}/${deviceTag} circuit deleted`;
  },
  ['circuitId', yup.string().required()],
  ['controllerTag', yup.string().nullable()]
);

export const { deleteCircuitSuccess, DELETE_CIRCUIT_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_CIRCUIT_SUCCESS`,
  ['circuitId', yup.string().required()]
);

export const { deleteCircuitFailed, DELETE_CIRCUIT_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_CIRCUIT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);



export const { fetchCircuitsExport, FETCH_CIRCUITS_EXPORT }: any = createTypedAction(
  `${PREFIX}FETCH_CIRCUITS_EXPORT`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['optionalFilters', yup.immutable().map()], // filter by component or user
);

export const { fetchCircuitsExportSuccess, FETCH_CIRCUITS_EXPORT_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CIRCUITS_EXPORT_SUCCESS`,
  ['exportCircuits', yup.immutable().map().required()],
  ['facilityName', yup.string().required()]
);

export const { fetchCircuitsExportFailed, FETCH_CIRCUITS_EXPORT_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CIRCUITS_EXPORT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
