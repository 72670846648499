import './OfflineBanner.scss';
import React, { useEffect, useState } from 'react';
import isReachable from 'is-reachable';
import { updateIsOffline } from 'src/module/offline/action';
import { useDispatch, useSelector } from 'react-redux';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import Button from 'src/component/UI/Button';
import OfflineQueue from '../OfflineQueue';
import { getOfflineQueue } from 'src/module/offline/selector';
import { Badge } from '@mui/material';

export default function OfflineBanner () {
  const dispatch = useDispatch();
  const [online, setOnline] = useState(true);
  const [showQueue, setShowQueue] = useState(false);
  const offlineQueue = useSelector(getOfflineQueue);

  useEffect(() => {
    dispatch(updateIsOffline(!online));
  }, [online]);

  async function checkOnlineStatus () {
    const status = await isReachable(document.location.origin);
    setOnline(status);
  }

  useEffect(() => {
    checkOnlineStatus();
    const interval = setInterval(() => {
      checkOnlineStatus();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {!online ? <div className='offline-banner'>
        You are currently offline. Please check your internet connection.
        <Badge badgeContent={offlineQueue.size} color="error" className='alarmBadge'>
          <Button onClick={() => setShowQueue(true)} className='button' icon={<RemoveFromQueueIcon style={{ fontSize: 14 }} />} cta />
        </Badge>

      </div> : null}
      <OfflineQueue handleClose={() => setShowQueue(false)} open={showQueue || (online && offlineQueue.size > 0)} />
    </>);
}
