/* eslint-disable */
export enum SettingsModalTypes {
  DEVICE_NOTES = 0,
  CONTROLLER_NOTES = 1,
  DEVICE_ADVANCED_SETTINGS = 2,
  CONTROLLER_ADVANCED_SETTINGS = 3,
  GAUGE_MULTIPLE_SETTINGS = 4,
  GAUGE_SINGLE_SETTING = 5,
  ADD_CIRCUIT = 6,
  ADD_CONTROLLER = 7,
  GAUGE_CONTROL_TEMPERATURE_SINGLE = 8,
  GAUGE_GROUND_FAULT_CURRENT_SINGLE = 9,
  GAUGE_LOAD_CURRENT_SINGLE = 10,
  GAUGE_POWER_CONSUMPTION_SINGLE = 11,
  GAUGE_TEMPERATURE_SENSOR_1_SINGLE = 12,
  GAUGE_TEMPERATURE_SENSOR_2_SINGLE = 13,
  GAUGE_TEMPERATURE_SENSOR_3_SINGLE = 26,
  GAUGE_TEMPERATURE_SENSOR_4_SINGLE = 27,
  GAUGE_TEMPERATURE_SENSOR_5_SINGLE = 28,
  GAUGE_TEMPERATURE_SENSOR_6_SINGLE = 29,
  GAUGE_TEMPERATURE_SENSOR_7_SINGLE = 30,
  GAUGE_TEMPERATURE_SENSOR_8_SINGLE = 31,
  GAUGE_TEMPERATURE_SENSOR_9_SINGLE = 32,
  GAUGE_CONTROL_TEMPERATURE_MULTIPLE = 14,
  GAUGE_GROUND_FAULT_CURRENT_MULTIPLE = 15,
  GAUGE_LOAD_CURRENT_MULTIPLE = 16,
  GAUGE_POWER_CONSUMPTION_MULTIPLE = 17,
  GAUGE_TEMPERATURE_SENSOR_1_MULTIPLE = 18,
  GAUGE_TEMPERATURE_SENSOR_2_MULTIPLE = 19,
  GAUGE_TEMPERATURE_SENSOR_3_MULTIPLE = 33,
  GAUGE_TEMPERATURE_SENSOR_4_MULTIPLE = 34,
  GAUGE_TEMPERATURE_SENSOR_5_MULTIPLE = 35,
  GAUGE_TEMPERATURE_SENSOR_6_MULTIPLE = 36,
  GAUGE_TEMPERATURE_SENSOR_7_MULTIPLE = 37,
  GAUGE_TEMPERATURE_SENSOR_8_MULTIPLE = 38,
  GAUGE_TEMPERATURE_SENSOR_9_MULTIPLE = 39,
  DEVICE_FILE_UPLOAD = 20,
  ADD_FREEZE_LOG = 21,
  DISMISS_ALARM_GROUP = 22,
  DESIGNED_SETTINGS_MISMATCH_MODAL = 23,
  ADD_COMM_LOOP = 24,
  REPLACE_CARD_MODAL = 25,
  ADD_RECIPE = 26,
  EDIT_RECIPE_TITLE = 27
}
/* eslint-enable */
