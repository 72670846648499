import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse, Popover } from '@mui/material';

const NavBarCategoryItem = (props) => {
  const {
    isCategoryOpen,
    isNavBarOpen,
    options,
    header,
    triggerCategory,
    label,
  }: any = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    triggerCategory();
  };

  const handleClose = () => {
    setAnchorEl(null);
    triggerCategory();
  };
  return (isCategoryOpen ? (isNavBarOpen ?
    (
      <div>
        <div onClick={triggerCategory}>
          {header}
        </div>
        <Collapse in={isCategoryOpen} timeout="auto" unmountOnExit className="navbar-collapse">
          {options}
        </Collapse>
      </div>
    )
    : (
      <div style={{ position: 'relative' }}>
        <div onClick={handleClick}>
          {header}
        </div>
        <Popover
          open={isCategoryOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className="nav-popover"
        >
          <div className={classNames("navbar-item-text", "navbar-catagory-header")}>{label}</div>
          {options}
        </Popover>
      </div>
    )) : <div onClick={handleClick} >{header}</div>);

};

NavBarCategoryItem.propTypes = {
  options: PropTypes.element,
  isCategoryOpen: PropTypes.bool,
  isNavBarOpen: PropTypes.bool,
  header: PropTypes.element,
  triggerCategory: PropTypes.func,
  label: PropTypes.string,
};

NavBarCategoryItem.defaultProps = {
  options: null,
  isNavBarOpen: false,
  isCategoryOpen: false,
  header: null,
  triggerCategory: null,
  label: null
};


export default React.memo(NavBarCategoryItem);
