import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './CircularProgressWithLabel.scss';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import PropTypes from 'prop-types';
import './CircularProgressWithLabel.scss';

function CircularProgressWithLabel (
  props: CircularProgressProps & { value: number, size: number, isError: boolean, hideLabel: boolean },
) {
  const finishedIcon = () => {
    if (props.isError) {
      return <WarningIcon className='complete-with-error' />;
    }
    return (<CheckCircleOutlinedIcon className='complete' />);
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }} data-testid="circular-progress-with-label">
      {props.value === 100 ?
        finishedIcon()
        : <div className='progress-wrapper'><CircularProgress variant="determinate" size={props.size} thickness={5} isError={props.isError} value={props.value} {...props} />
          {props.hideLabel ? null : (<Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
          )}</div>
      }
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number,
  hideLabel: PropTypes.bool,
  isError: PropTypes.bool,
  notStarted: PropTypes.bool
};

CircularProgressWithLabel.defaultProps = {
  value: 0,
  hideLabel: PropTypes.bool,
};



export default function CircularWithValueLabel (props) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= props.value ? props.value : prevProgress + 1));
    }, typeof props.interval === 'undefined' ? 50 : props.interval);
    return () => {
      clearInterval(timer);
    };
  }, [props.value]);

  return (<div className='circular-loading'>
    <CircularProgressWithLabel size={props.size || 50} value={progress} isError={props.isError} hideLabel={props.hideLabel} />
  </div>);
}

CircularWithValueLabel.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number,
  interval: PropTypes.number,
  hideLabel: PropTypes.bool,
  isError: PropTypes.bool
};

CircularWithValueLabel.defaultProps = {
  value: 0,
  isError: false,
  hideLabel: false,
  notStarted: false
};
