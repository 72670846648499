import { request } from '../api';

export const updatePanel = (action, data) => {
  return request(action, {
    payload: {
      url: `/control-panels`,
      options: {
        method: 'PUT',
        data
      }
    }
  });
};

export const deletePanel = (action) => {
  const {
    panel_id,
  } = action;

  return request(action, {
    payload: {
      url: `/control-panels/${panel_id}`,
      options: {
        method: 'DELETE'
      }
    }
  });
};

export const getControlPanelTags = (action) => {
  const {
    filter,
  } = action;

  const query: any = {};

  if (filter) {
    query.filter = filter;
  }

  return request(action, {
    payload: {
      url: `/control-panels/tags`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};
