import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as controllersApi from 'src/service/api/controllers';
import * as controllerActions from './action';
import * as navigationActions from 'src/module/navigation/action';
import * as devicesActions from '../device/action';
import * as circuitActions from '../circuit/action';
import * as systemActions from '../system/action';
import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';
import * as devicesApi from 'src/service/api/devices';

export function* controllerRoot () {
  yield takeLatest(controllerActions.ADD_CONTROLLER, addController);
  yield takeLatest(controllerActions.UPDATE_CONTROLLER, updateController);
  yield takeLatest(controllerActions.DELETE_CONTROLLER, deleteController);
  yield takeLatest(controllerActions.UPDATE_CONTROLLER_SUCCESS, updateControllerSuccess);
}

function* addController (action) {
  try {
    yield put(devicesActions.loadingSettingsModal(null));
    const response = yield call(controllersApi.addController, action, action.controller);
    // A message id on the add controller response indicates a device SYNC is occuring
    // therefore we need to indicate a loading state
    if (response.get('messageId')) {
      yield put(devicesActions.syncDevices(response.getIn(['deviceRec', 'id']), action.controller.get('tag'), 0));
      yield put(systemActions.registerMessageResponseHandler(response.get('messageId'),
        [],
        [
          {
            action: devicesActions.stopLoadingSettingsModal,
            params: [null, null],
            toast: 'Error'
          },
          {
            action: devicesActions.clearSyncDevicesLoading,
            params: [null],
          },
          {
            action: devicesActions.addDeviceFailed,
            params: [response.getIn(['deviceRec', 'id'])]
          }
        ],
        action.controller.get('device_factory') === 'NVentNGC30Controller' ? 180000 : 40000
      ));
    }
    const controller = response.get('deviceRec') ?? response;
    yield put(devicesActions.fetchDeviceSuccess(controller));
    if (!response.get('messageId')) {
      yield put(devicesActions.setSettingsModalClose(null));
    }
    const deviceTree = yield call(devicesApi.getDeviceTree, action);

    yield put(devicesActions.fetchDeviceTreeSuccess(deviceTree));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error adding new controller'), err.response.status);
    yield put(controllerActions.addControllerFailed(Map(err)));
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), null));
  }
}

function* deleteController (action) {
  try {
    yield call(controllersApi.deleteController, action, action.controllerId);
    yield put(circuitActions.deleteCircuitsOfController(action.circuits));
    yield put(devicesActions.deleteDevice(action.controllerId));
    yield put(navigationActions.pushHistory(`/devices/list`));

  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error deleting controller'), err.response.status);
    yield put(controllerActions.deleteControllerFailed(Map(err)));
  }
}

function* updateController (action) {
  try {
    const controller = yield call(controllersApi.updateController, action, action.controllerId, action.controller);
    if (controller.getIn(['settings', 'tagModbusUpdate']) && controller.get('messageId')) {
      yield put(systemActions.registerMessageResponseHandler(controller.get('messageId'),
        [
          {
            action: devicesActions.updateDeviceSuccess,
            params: [Map({ tag: action.controller.get('tag'), id: action.controllerId })],
          },
          {
            action: controllerActions.updateControllerSuccess,
            params: [action.controller.get('tag')],
          }
        ],
        [
          {
            action: devicesActions.addDeviceFailed,
            params: [null, action.controllerId],
            toast: 'Error'
          }
        ]
      ));
    }
    else {
      yield put(devicesActions.updateDeviceSuccess(controller));
      yield put(controllerActions.updateControllerSuccess(controller.get('tag')));
    }
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating controller'), err.response.status);
    yield put(controllerActions.updateControllerFailed(Map(err)));
  }
}

function* updateControllerSuccess (action) {
  try {
    const controllerTag = action.controllerTag;
    if (controllerTag) {
      yield put(navigationActions.pushHistory(`/devices/${controllerTag}`));
    }
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating controller'), err.response.status);
    yield put(controllerActions.updateControllerFailed(Map(err)));
  }
}

export default controllerRoot;
