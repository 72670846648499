import './IntegrationsScreen.scss';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map, List } from 'immutable';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import moment from 'moment';

import DeviceLink from 'src/component/UI/DeviceLink';
import ConfirmModal from 'src/component/UI/ConfirmModal';
import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';
import IntegrationsModal from './IntegrationsModal';
import Button from 'src/component/UI/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/CreateOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { fetchDeviceTags, clearDeviceTags } from 'src/module/device/action';
import { fetchIntegrations, addIntegration, updateIntegration, deleteIntegration, fetchIntegrationFields, refreshIntegration } from 'src/module/integration/action';
import { getIntegrations, getIntegrationFields } from 'src/module/integration/selector';
import { getCircuitTags } from 'src/module/device/selector';
import CustomStatistics from 'src/component/UI/Statistics/CustomStatistics';
import PermissionFence from 'src/component/PermissionFence';
import { isWhitelabel } from 'src/utils/utils';

export const integrationTypes = {
  raychem: 'nVent Raychem Supervisor',
  tvne: 'Thermon TVNE',
  azure_ad: 'Azure Active Directory',
  genesis: 'Thermon Genesis',
  intellitrace: 'Chromalox Intellitrace',
  sap: 'SAP',
  maximo: 'Maximo',
  oracle: 'Oracle',
  weather: 'Local Weather',
  frog: "SmartTrace Supervisor",
  ambient_weather: 'Ambient RTD Weather',
  pager_duty: 'Pager Duty'
};

export default function IntegrationsScreen () {
  const dispatch = useDispatch();

  const [integrationId, setIntegrationId] = useState('');
  const [modalsOpen, setModalsOpen] = useState(Map({
    integrations: false,
    weather: false,
    raychem: false,
    tvne: false,
    azure_ad: false,
    genesis: false,
    intellitrace: false,
    sap: false,
    maximo: false,
    oracle: false,
    frog: false,
    ambient_weather: false,
    pager_duty: false,
  }));

  const isConnected = {
    weather: (integration) => integration.getIn(['settings', 'cityId']) ? true : false,
    raychem: (integration) => integration.getIn(['settings', 'databaseAddr']) && integration.getIn(['settings', 'databaseUser']) && integration.getIn(['settings', 'databasePassword']) ? true : false,
    tvne: (integration) => integration.getIn(['settings', 'databaseAddr']) && integration.getIn(['settings', 'databaseUser']) && integration.getIn(['settings', 'databasePassword']) ? true : false,
    azure_ad: (integration) => integration.getIn(['settings', 'appId']) && integration.getIn(['settings', 'clientSecret']) ? true : false,
    genesis: (integration) => integration.getIn(['settings', 'databaseAddr']) && integration.getIn(['settings', 'databaseUser']) && integration.getIn(['settings', 'databasePassword']) ? true : false,
    intellitrace: (integration) => integration.getIn(['settings', 'databaseAddr']) && integration.getIn(['settings', 'databaseUser']) && integration.getIn(['settings', 'databasePassword']) ? true : false,
    sap: (integration) => integration.getIn(['settings', 'sapAddr']) && integration.getIn(['settings', 'apiToken']) ? true : false,
    maximo: (integration) => integration.getIn(['settings', 'maximoAddr']) && integration.getIn(['settings', 'apiToken']) ? true : false,
    oracle: (integration) => integration.getIn(['settings', 'oracleAddr']) && integration.getIn(['settings', 'apiToken']) ? true : false,
    pager_duty: (integration) => integration.getIn(['settings', 'integrationKey']) ? true : false,
    frog: (integration) => {
      const timeDifference = moment.duration(moment().diff(moment(integration.get('last_synchronized_at'))));
      const milliSeconds = timeDifference.asMilliseconds();
      return milliSeconds < 120000 ? true : false;
    },
    ambient_weather: (integration) => integration.getIn(['settings', 'circuitId']) && integration.getIn(['settings', 'rtdNumber']) ? true : false,
  };

  const integrations = useSelector(getIntegrations);
  const fields = useSelector(getIntegrationFields) || List();
  const [tag, setTag] = useState(null);
  const [controllerTag, setControllerTag] = useState(null);
  useEffect(() => {
    dispatch(fetchIntegrations());
    dispatch(fetchIntegrationFields());
  }, []);

  const makeToggleModal = (type) => () => {
    toggleModal(type);
  };

  const toggleModal = (type) => {
    setModalsOpen(modalsOpen.set(type, !modalsOpen.get(type)));
  };

  const handleIntegrationModalSubmit = (notificationType) => {
    dispatch(addIntegration(notificationType));
    toggleModal('integrations');
  };

  const makeHandleModalSubmit = (integrationId, type) => (settings) => {
    if (type === 'ambient_weather') {
      settings = settings.set('tag', tag);
      settings = settings.set('controllerTag', controllerTag);
    }
    const settingsOnLoad = integrations.find(x => x.get('id') === integrationId)?.get('settings');
    const diff = settingsOnLoad.filter((value, key) => settings.get(key) !== value).concat(settings.filter((value, key) => settingsOnLoad.get(key) !== value));
    dispatch(updateIntegration(integrationId, Map({ settings: diff })));
    toggleModal(integrationId);
  };

  const makeDeleteIntegration = (_integrationId) => () => {
    setIntegrationId(_integrationId);
    toggleModal('confirm');
  };

  const handleRemoveIntegration = () => {
    toggleModal('confirm');
    dispatch(deleteIntegration(integrationId));
  };

  const makeEditIntegration = (integration) => () => {
    toggleModal(integration.get('id'));
  };
  const handleRestartIntegration = (integration) => () => {
    dispatch(refreshIntegration(integration.get('id')));
    setTimeout(() => {
      dispatch(fetchIntegrations());
    }, 10000);
  };
  const isModalOpen = (type) => {
    const open = modalsOpen.get(type);
    return open || false;
  };

  const rtdMap = {
    1: 'RTD 1',
    2: 'RTD 2',
    3: 'RTD 3',
    4: 'RTD 4',
    5: 'RTD 5',
    6: 'RTD 6',
    7: 'RTD 7',
    8: 'RTD 8'
  };

  const fetchOptions = (e) => {
    if (e.target.value && e.target.value.length >= 3) {
      dispatch(clearDeviceTags());
      dispatch(fetchDeviceTags(e.target.value));
    }
  };

  const onValueChange = (circuit) => {
    const [tagController, tagCircuit] = circuit['tag']?.split(' / ') || null;
    setTag(tagCircuit);
    setControllerTag(tagController);
  };

  const checkFields = (fields) => {
    let circuit = fields?.get('circuitId') || null;
    if (circuit && circuit.get('type') === 'autoCompleteSelect') {
      circuit = circuit.set('optionsSelector', getCircuitTags);

      const formatOption = (option) => ({
        label: `${option.get('controller_tag') || ''} / ${option.get('tag')}`,
        value: option.get('id')
      });
      circuit = circuit.set('formatOption', formatOption);
      circuit = circuit.set('fetchOptions', fetchOptions);
      circuit = circuit.set('onValueChange', onValueChange);
      fields = fields.set('circuitId', circuit);
    }
    return fields;
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='integrations'>
      <Helmet>
        <title>{title} - Integrations</title>
      </Helmet>

      <ConfirmModal title='Are you sure?' message='Are you sure you want to remove this integration?' open={isModalOpen('confirm')} handleClose={makeToggleModal('confirm')} handleReject={makeToggleModal('confirm')} handleConfirm={handleRemoveIntegration} />
      <IntegrationsModal open={isModalOpen('integrations')} handleClose={makeToggleModal('integrations')} handleSubmit={handleIntegrationModalSubmit} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h2>Integrations</h2>
        </Grid>

        {!integrations.size ? (
          <Grid item xs={12}>
            <Paper className='integrations-placeholder'>
              <p>You have no integrations configured yet!</p>
            </Paper>
          </Grid>
        ) : null}
        {integrations.map((integration, index) => {
          const variable = { integration: integration };
          const field = fields.find(f => f.get('type', null) === integration.get("type"));
          return field ?
            (<Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Paper className='integration'>
                <SettingsModal open={isModalOpen(integration.get('id'))} handleClose={makeToggleModal(integration.get('id'))} handleSubmit={makeHandleModalSubmit(integration.get('id'), integration.get('type'))}
                  settings={integration.get('settings', Map())} fields={checkFields(field.get('settings'))} title={integrationTypes[integration.get('type')]} />

                <PermissionFence can='edit-integration'>
                  <>
                    <Button className='integration-action' icon={<DeleteIcon style={{ color: '#444', fontSize: 20 }} onClick={makeDeleteIntegration(integration.get('id'))} />} />
                    {field.size > 0 ?
                      <Button className='integration-action' icon={<EditIcon style={{ color: '#444', fontSize: 20 }} onClick={makeEditIntegration(integration)} />} />
                      : ''
                    }
                    {integration.get('type') === 'frog' ? <Button className='integration-action' icon={<RestartAltIcon style={{ color: '#444', fontSize: 20 }} onClick={handleRestartIntegration(integration)} />}/> : null }
                  </>
                </PermissionFence>
                <h4>{integrationTypes[integration.get('type')]}</h4>
                <h5>{integration.getIn(['settings', 'name'], '')}</h5>
                <p>Connection Status: {isConnected[integration.get('type')](integration) ? (<span className='success-text'>Connected</span>) : (<span className='error-text'>Not Connected</span>)}</p>
                {integration.get('type') === 'ambient_weather' ?
                  <>
                    <div className='inline-label'>
                      <p><b>Circuit:</b> &nbsp;</p>
                      {integration.getIn(['settings', 'controllerTag']) ? <DeviceLink label={integration.getIn(['settings', 'controllerTag'])} controllerTag={integration.getIn(['settings', 'controllerTag'])}/> : ''} &nbsp;{integration.getIn(['settings', 'controllerTag']) ? '/' : ''}&nbsp;
                      <DeviceLink label={integration.getIn(['settings', 'tag'])} circuitTag={integration.getIn(['settings', 'tag'])} controllerTag={integration.getIn(['settings', 'controllerTag'])}/>
                    </div>
                    <p><b>RTD Number:</b> {rtdMap[integration.getIn(['settings', 'rtdNumber'])]}</p>
                  </> : null
                }
                {integration.get('last_synchronized_at') ? <p>Last Synchronized: {moment(integration.get('last_synchronized_at')).fromNow()}</p> : null}
                <p>{isConnected[integration.get('type')](integration) && integration.getIn(['status', 'serviceStartedAt'], null)
                  ? 'Started: ' + moment(integration.getIn(['status', 'serviceStartedAt'])).fromNow()  : ''}</p>
                {integration.get('type') == "frog" ?
                  integration.getIn(['status', 'channelStatus'], null) ?
                    integration.getIn(['status', 'channelStatus']).map((channelStatus, idx) => {
                      return <CustomStatistics
                        key={idx}
                        layoutPreferencePath=''
                        variables={variable}
                        defaultLayout={[
                          { type: 'block', title: `${channelStatus.get('name', '')} ${channelStatus.get('connectionData')}`, children: [
                            { type: 'minor', title: 'Total Messages Sent', helpText: 'Total Modbus messages Sent', value: `integration.status.channelStatus.${idx}.totalSent`, defaultValue: '0' },
                            { type: 'minor', title: 'Dropped Messages', helpText: 'Number of messages dropped/timed out', value: `integration.status.channelStatus.${idx}.totalDropped`, defaultValue: '0' },
                            { type: 'minor', title: 'Modbus Errors', helpText: 'Number of modbus exceptions thrown', value: `integration.status.channelStatus.${idx}.modbusExceptions`, defaultValue: '0' },
                            { type: 'minor', title: 'Average Message Time', helpText: 'Average time for a Modbus message to complete', value: `integration.status.channelStatus.${idx}.avgMessageTime`, defaultValue: '0', formatFunction: 'roundAverageMessageTime' },
                          ] }
                        ]}
                      />;
                    }) : ''
                  : ''
                }
              </Paper>
            </Grid>) : null;
        })}

      </Grid>

      <PermissionFence can='edit-integration'>
        <div className='button-bar'>
          <Button cta onClick={makeToggleModal('integrations')}>Add Integration</Button>
        </div>
      </PermissionFence>
    </div>
  );
}
