import { request } from '../api';

export const getPermissions = (action) => {

  return request(action, {
    payload: {
      url: `/permissions`,
      options: {
        method: 'GET'
      }
    }
  });
};
