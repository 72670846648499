import './ScheduleScreen.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Map } from 'immutable';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import { fetchUsers } from 'src/module/user/action';
import { fetchReportSchedules, addReportSchedule, deleteReportSchedule, updateReportSchedule } from 'src/module/facility/action';

import { getUsers } from 'src/module/user/selector';
import { getReportSchedules } from 'src/module/facility/selector';

import { formatName, isWhitelabel } from 'src/utils/utils';
import RecurrenceRulePicker from 'src/component/UI/RecurrenceRulePicker';
import Button from 'src/component/UI/Button';
import EditableCell from 'src/component/UI/EditableCell';


export default function ScheduleScreen () {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const reportSchedules = useSelector(getReportSchedules);

  useEffect(() => {
    dispatch(fetchReportSchedules());
    dispatch(fetchUsers());
  }, []);

  const getUpdateRecurrenceRuleHandler = (reportScheduleId) => (recurrenceRule) => {
    dispatch(updateReportSchedule(reportScheduleId, Map({ recurrence_rule: recurrenceRule })));
  };

  const getChangeHandler = (reportScheduleId, field) => (e) => {
    const value = (e && e.target) ? e.target.value : e;
    const reportSchedulePatch = {};
    reportSchedulePatch[field] = value;
    dispatch(updateReportSchedule(reportScheduleId, Map(reportSchedulePatch)));
  };

  const getDeleteReportScheduleHandler = (reportScheduleId) => () => {
    dispatch(deleteReportSchedule(reportScheduleId));
  };

  const createReportSchedule = () => {
    let description = 'Maintenance Schedule';
    if (reportSchedules.size > 0) {
      description += ` ${reportSchedules.size + 1}`;
    }
    dispatch(addReportSchedule(Map({ description, recurrence_rule: 'freq:daily;interval:1' })));
  };

  const months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'Novemer',
    12: 'December'
  };

  const monthKeys = Object.keys(months);

  const datesByMonth = {
    1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
    3: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    4: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    5: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    7: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    8: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    9: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    11: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    12: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='report-schedule'>
      <Helmet>
        <title>{title} - Report Schedule</title>
      </Helmet>

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <h2>Report Frequency</h2>
        </Grid>

        {reportSchedules.map((reportSchedule) => {
          return (<Grid key={reportSchedule.get('id')} item xs={12}>
            <Paper>
              <Button className='schedule-action' icon={<DeleteIcon style={{ color: '#444', fontSize: 20 }} onClick={getDeleteReportScheduleHandler(reportSchedule.get('id'))} />} />

              <Grid style={{ marginLeft: '-7px', minHeight: '50px' }} item xs={12} sm={12}>
                <EditableCell onChange={getChangeHandler(reportSchedule.get('id'), 'description')} value={reportSchedule.get('description') || ''} />
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>Effective From:</Grid>
                <Grid item xs={12} sm={9}>
                  <Select variant='standard' className='month-selector' value={reportSchedule.get('start_month')} onChange={getChangeHandler(reportSchedule.get('id'), 'start_month')}>
                    {monthKeys.map((value) => (
                      <MenuItem key={value} value={value}>{months[value]}</MenuItem>
                    ))}
                  </Select>

                  <Select variant='standard' value={reportSchedule.get('start_date')} onChange={getChangeHandler(reportSchedule.get('id'), 'start_date')}>
                    {datesByMonth[reportSchedule.get('start_month') || 1].map((value) => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={3}>Effective To:</Grid>
                <Grid item xs={12} sm={9}>
                  <Select variant='standard' className='month-selector' value={reportSchedule.get('end_month')} onChange={getChangeHandler(reportSchedule.get('id'), 'end_month')}>
                    {monthKeys.map((value) => (
                      <MenuItem key={value} value={value}>{months[value]}</MenuItem>
                    ))}
                  </Select>

                  <Select variant='standard' value={reportSchedule.get('end_date')} onChange={getChangeHandler(reportSchedule.get('id'), 'end_date')}>
                    {datesByMonth[reportSchedule.get('end_month') || 1].map((value) => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </Grid>


                <Grid item xs={12} sm={3}>Assigned To:</Grid>
                <Grid item xs={12} sm={9}>
                  <Select variant='standard' value={reportSchedule.get('assigned_to') || ''} onChange={getChangeHandler(reportSchedule.get('id'), 'assigned_to')}>
                    {users.map((user) => (
                      <MenuItem key={user.get('id')} value={user.get('id')}>{formatName(user.get('first_name', null), user.get('last_name', null))}</MenuItem>
                    ))}
                  </Select>
                </Grid>


                <Grid item xs={12} sm={3}>Recurrence Schedule:</Grid>
                <Grid item xs={12} sm={9}>
                  <RecurrenceRulePicker initialValue={reportSchedule.get('recurrence_rule')} onChange={getUpdateRecurrenceRuleHandler(reportSchedule.get('id'))} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>);
        })}

        <div className='button-bar'>
          <Button cta onClick={createReportSchedule}>Add Report Schedule</Button>
        </div>
      </Grid>
    </div>
  );
}
