import yup from 'src/utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchFreezeLogs, FETCH_FREEZE_LOGS }: any = createTypedAction(
  `${PREFIX}FETCH_FREEZE_LOGS`,
  ['selectedFilters', yup.object().nullable()],
  ['paginationDetails', yup.object().nullable()],
  ['includeResolved', yup.boolean().default(false)]
);

export const { fetchFreezeLogsSuccess, FETCH_FREEZE_LOGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_FREEZE_LOGS_SUCCESS`,
  ['freezeLogs', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['totalPages', yup.number()]
);

export const { fetchFreezeLogsFailed, FETCH_FREEZE_LOGS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_FREEZE_LOGS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { addFreezeLog, ADD_FREEZE_LOG }: any = createTypedAction(
  `${PREFIX}ADD_FREEZE_LOG`,
  ['freezeLog', yup.immutable().map().required()]
);

export const { addFreezeLogSuccess, ADD_FREEZE_LOG_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_FREEZE_LOG_SUCCESS`,
  ['freezeLog', yup.immutable().map().required()]
);

export const { addFreezeLogFailed, ADD_FREEZE_LOG_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_FREEZE_LOG_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateFreezeLog, UPDATE_FREEZE_LOG }: any = createTypedAction(
  `${PREFIX}UPDATE_FREEZE_LOG`,
  ['freezeLogId', yup.string().required()],
  ['freezeLog', yup.immutable().map().required()]
);

export const { updateFreezeLogSuccess, UPDATE_FREEZE_LOG_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_FREEZE_LOG_SUCCESS`,
  ['freezeLog', yup.immutable().map().required()]
);

export const { updateFreezeLogFailed, UPDATE_FREEZE_LOG_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_FREEZE_LOG_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
