import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';
import Button from 'src/component/UI/Button';
import TestingReport from './TestingReport';
import DataTable from 'src/component/UI/DataTable';
import { formatDate } from 'src/utils/utils';
import { List } from 'immutable';
import { getTablePagePreferences } from 'src/module/authentication/selector';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceCheckSheets, fetchDeviceFile } from 'src/module/device/action';
import { getAlarmCheckSheetReports, getDeviceCheckSheetLoading, getDeviceCheckSheetTotal, getDeviceCheckSheetTotalPages } from 'src/module/device/selector';
import './TestingReport.scss';
import { closeCheckSheet, fetchCheckSheetTasks, openCheckSheet } from 'src/module/checkSheet/action';
import { getCheckSheetReportIsOpen } from 'src/module/checkSheet/selector';
import PermissionFence from 'src/component/PermissionFence';

export default function TestingReportsContent (props) {
  const dispatch = useDispatch();
  const {
    deviceId,
  } = props;
  const checkSheetType = 'alarm-check-sheet';
  const [collapsed, setCollapsed] = useState(true);
  const [selected, setSelected] = useState(null);
  const showModal = useSelector(getCheckSheetReportIsOpen(checkSheetType));
  const totalPages = useSelector(getDeviceCheckSheetTotalPages(deviceId, checkSheetType));
  const total = useSelector(getDeviceCheckSheetTotal(deviceId, checkSheetType));
  const loading = useSelector(getDeviceCheckSheetLoading(deviceId, checkSheetType));

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);
  useEffect(() => {
    dispatch(closeCheckSheet(checkSheetType));
    dispatch(fetchCheckSheetTasks(checkSheetType));
  }, [deviceId]);
  function formatName (data) {
    return data.get('first_name') + " " + data.get('last_name');
  }
  const onFileClick = (report) => {
    // get file and download it
    dispatch(fetchDeviceFile(report.get('file_name'), report.get('created_at'), deviceId, report.get('file_id')));
  };
  const columns: any = [
    {
      id: 'alarm',
      Header: 'Alarm(s)',
      accessor: (row) => row.get('alarms'),
      Cell: (row: any) => row.cell.value?.join(", "),
    },
    {
      id: 'notes',
      Header: 'Notes / Recommendations',
      accessor: (row) => row.get('notes'),
      Cell: (row: any) => row.cell.value,
    },
    {
      id: 'created_by',
      Header: 'Created By',
      accessor: (row) => row.get('created_by'),
      Cell: (row: any) => formatName(row.cell.row.original)
    },
    {
      id: 'created_at',
      Header: 'Created at',
      accessor: (row) => row.get('created_at'),
      Cell: (row: any) => (row.cell.value ? (<>{formatDate(row.cell.value)}</>) : ''),
    },
    {
      Header: 'File Name',
      accessor: (row: any) => {
        return (row.get('file_name')?.length > 9 ?
          <Tooltip title={row.get('file_name')}><p className='fileName' onClick={(e) => {e.stopPropagation();onFileClick(row);}} >{row.get('file_name')}</p></Tooltip> :
          <p className='fileName' onClick={(e) => {e.stopPropagation(); onFileClick(row);}} >{row.get('file_name')}</p>);
      }
    },
  ];
  const userPagePreference = useSelector(getTablePagePreferences('device-testing-report-table'));
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(userPagePreference);
  const [sort, setSort] = useState(null);
  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setSort(_sort);
  };
  useEffect(() => {
    if (!(collapsed && showRotateMessage)) {
      const paginationDetails = {
        page,
        pageSize,
        sort
      };
      dispatch(fetchDeviceCheckSheets(checkSheetType, deviceId, paginationDetails));
    }
  }, [deviceId, page, pageSize, sort]);
  const reports = useSelector(getAlarmCheckSheetReports(deviceId, checkSheetType));
  const onRowClick = (row) => {
    if (!row.original.get('file_id')) {
      setSelected(row.original.get('id'));
      dispatch(openCheckSheet(checkSheetType));
    }
  };
  const onClickCreate = () => {
    dispatch(openCheckSheet(checkSheetType));
    setSelected(null);
  };

  return (
    <div id="testing-reports-content"  className='testing-reports-content' data-testid='testing-reports-content'>
      <Paper>
        <h3 className='expandable' onClick={() => setCollapsed(!collapsed)}>Testing Reports &nbsp;<span className='expand-arrow'>{collapsed ?  '▼': '▲' }</span></h3>

        {!collapsed ?
          <div className="content-wrapper">
            <div className='create-button'>
              <PermissionFence can='edit-device'>
                <Button className='create-report-button' cta onClick={onClickCreate}>Create Testing Report</Button>
              </PermissionFence>
            </div>
            <TestingReport open={showModal} reportId={selected} deviceId={deviceId} handleClose={() => dispatch(closeCheckSheet(checkSheetType))} />
            {collapsed && showRotateMessage ?
              <DeviceRotate />
              : <DataTable
                filterable={false}
                filterLabel='Search Alarms'
                pagination={true}
                numPages={totalPages}
                totalRecords={total}
                loading={loading}
                data={List(reports)}
                columns={columns}
                fetchPage={fetchPage}
                handleCellClick={onRowClick}
                columnWidths = {['30%', '30%', '20%', '20%']}
                placeholder={<><span>No reports to see here </span><span role='img' aria-label='Sweat Smile'>😅</span></>}
                tableId={'device-testing-report-table'}
              />}
          </div>
          : null}


      </Paper>
    </div>
  );
}

TestingReportsContent.propTypes = {
  deviceId: PropTypes.string,
};

TestingReportsContent.defaultProps = {
  deviceId: null,
};
