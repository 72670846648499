import './SettingsMapPane.scss';
import React, { useEffect, useState } from 'react';
import SettingUpgrade from './SettingUpgrade';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';

export default function SettingsMapPane (props) {
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  const {
    settingsPaths,
    baseDevice,
    upgradeDevice
  } = props;

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  useEffect(() => {
    setSettingPaths(settingsPaths);
  }, [settingsPaths]);
  const [settingPaths, setSettingPaths] = useState(List());

  return (
    <div id='settings-map-screen' className='settings-map-container'>
      {showRotateMessage ? <Paper><DeviceRotate /></Paper> :
        <>
          {settingPaths.map((path: any, index) => (
            <div className='upgrade-bar' key={`${path.get('id')}-${index}-key`}>
              <SettingUpgrade settings={path} baseDevice={baseDevice} upgradeDevice={upgradeDevice} index={index} />
            </div>
          ))}
        </>
      }
    </div>
  );
}

SettingsMapPane.propTypes = {
  upgradePath: PropTypes.object.isRequired,
  settingsPaths: ImmutablePropTypes.list,
  baseDevice: PropTypes.string.isRequired,
  upgradeDevice: PropTypes.string.isRequired
};

SettingsMapPane.defaultProps = {
  settingsPaths: List()
};
