import { createSelector } from 'reselect';

export const getProgressState = (state) =>
  state.progress;

export const getIsInProgress = createSelector(
  [getProgressState],
  (progress) => {
    return progress.getIn(['progress', 'isInProgress'], false
    );
  }
);
export const getProgress = createSelector(
  [getProgressState],
  (progress) => {
    return progress.getIn(['progress', 'progress'], 0);
  }
);
export const getProgressLabel = createSelector(
  [getProgressState],
  (progress) => {
    return progress.getIn(['progress', 'label'], '');
  }
);
export const getProgressFailed = createSelector(
  [getProgressState],
  (progress) => {
    return progress.getIn(['progress', 'failed'], false);
  }
);
export const getProgressTimeout = createSelector(
  [getProgressState],
  (progress) => {
    return progress.getIn(['progress', 'timeout'], false);
  }
);
