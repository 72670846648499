import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import AuditList from 'src/component/AuditList';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';

export default function AuditContent (props) {
  const {
    deviceId,
  } = props;
  const [collapsed, setCollapsed] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);




  return (
    <Paper data-testid='alarms-content'>
      <h3 className='expandable' onClick={() => setCollapsed(!collapsed)}>Audit Trail &nbsp;<span className='expand-arrow'>{collapsed ? '▲' : '▼'}</span></h3>
      {collapsed && !showRotateMessage ?
        <AuditList
          deviceId={deviceId}
        />
        : ''}

      {collapsed && showRotateMessage ?
        <DeviceRotate/>
        : null}
    </Paper>
  );
}

AuditContent.propTypes = {
  deviceId: PropTypes.string.isRequired
};
AuditContent.defaultProps = {

};
