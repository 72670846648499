import './CustomStatistics.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import moment from 'moment';

import { getAlarmStats, getCriticalAlarmsWithoutNotification, getAlarmHistoricalSummary, getAlarmStatsLoading } from 'src/module/alarm/selector';
import { getMetrics } from 'src/module/facility/selector';
import { getDashboardStats } from 'src/module/device/selector';

import { getNewWorkTickets } from 'src/module/workTicket/selector';
import { getClient, getSingularWorkTicketLabel, getPluralWorkTicketLabel, getCriticalityLabels } from 'src/module/authentication/selector';
import CustomStatistics from './CustomStatistics';

export default function DashboardStatistics (props) {

  const {
    defaultLayoutOverride,
  } = props;

  const client = useSelector(getClient);
  const alarmStats = useSelector(getAlarmStats);
  const metrics = useSelector(getMetrics);
  const dashboardStats = useSelector(getDashboardStats);
  const criticalAlarmsWithoutNotification = useSelector(getCriticalAlarmsWithoutNotification);
  const newWorkTickets = useSelector(getNewWorkTickets) as any;
  const alarmHistory = useSelector(getAlarmHistoricalSummary) as any;
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);
  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);
  const criticalityLabels = useSelector(getCriticalityLabels);
  const alarmStatsLoading = useSelector(getAlarmStatsLoading);
  const lastReportSentAt = client.get('last_report_sent_at');
  const currentTemp = alarmStats.getIn(['currentSiteTemperature', 'current'], null);

  let circuitsLoto = null;

  const outstandingWorkOrders = alarmStats.getIn(['outstandingNotificationsByStatus', 'wo'], 0) + alarmStats.getIn(['outstandingNotificationsByStatus', 'eng_required'], 0);
  if (alarmStats) {
    circuitsLoto = alarmStats.getIn(['notesByHtc', 'loto']);
  }
  const variables = {
    alarmStats,
    alarmStatsLoading,
    client,
    metrics,
    totalHoursSavedToday: alarmHistory.getIn([alarmHistory.size - 1, 'total_auto_handled'], 'N/A'),
    totalNewNotifications: newWorkTickets.size,
    criticalAlarmsWithoutNotification,
    circuitsLoto,
    outstandingWorkOrders,
    date: moment().format('YYYY-MM-DD'),
    lastReportSentAt: lastReportSentAt ? moment(lastReportSentAt).format('YYYY-MM-DD') : 'N/A',
    currentSiteTemperature: currentTemp ? `${currentTemp}°C` : 'N/A',
    weekNumber: moment().isoWeek(),
    dashboardStats
  };

  const interpolatedVariables = {
    '%estTotalMaintenanceHoursExplain%': {
      valuePath: 'alarmStats.estTotalMaintenaceHoursExplain',
      default: 'Total estimated maintenance hours'
    },
    '%estBasicTotalMaintenanceHoursExplain%': {
      valuePath: 'alarmStats.estBasicTotalMaintenaceHoursExplain',
      default: 'Total estimated maintenance hours'
    },
    '%mnraLabel%': {
      valuePath: 'client.preferences.notificationLabels.mnra',
      default: 'EHT Walk Downs'
    },
    '%engRequiredLabel%': {
      valuePath: 'client.preferences.notificationLabels.eng_required',
      default: `Eng Req'd`
    },
    '%workOrderLabel%': {
      valuePath: 'client.preferences.notificationLabels.wo',
      default: 'Scheduled Repairs'
    },
  };
  const criticalAlarmsWithoutNotificationArr = [];
  alarmStats?.get('devicesInAlarmWithoutNotificationByCriticality', []).forEach((value,  key) => {
    if (key > 0) {
      criticalAlarmsWithoutNotificationArr.push({
        type: 'minor',
        title: criticalityLabels?.get(key),
        helpText: `Number of priority '${criticalityLabels?.get(key)}' devices in alarm without ${pluralWorkTicketLabel}`,
        value: `alarmStats.devicesInAlarmWithoutNotificationByCriticality.${key}`,
        defaultValue: 'N/A'
      });
    }
  });

  const defaultLayout = [
    { type: 'grid', container: true, spacing: 3, children: [
      { type: 'grid', container: false, xs: 12, md: 12, children: [
        { type: 'component', feature: ['htc-stats'], componentName: 'WeatherForecast' },
      ] },

      { type: 'grid', container: false, xs: 12, md: 12, children: [
        { type: 'block', title: 'Outlook', children: [
          { type: 'major', title: `Critical Alarms w/o  ${singularWorkTicketLabel}`, helpText: `Total number of devices in critical alarm and no associated ${singularWorkTicketLabel.toLowerCase()}`, value: 'alarmStats.criticalAlarmsWithoutNotification', loading: 'alarmStatsLoading' },
          { type: 'major', title: `New ${pluralWorkTicketLabel} in Last 7 Days`, helpText: `Total ${pluralWorkTicketLabel.toLowerCase()} created in the last 7 days`, value: 'totalNewNotifications' },
          { type: 'major', feature: ['htc-stats'], title: 'Circuits Forced On', value: 'alarmStats.deviceStatusSummary.forced_on', defaultValue: 'N/A', loading: 'alarmStatsLoading' },
          { type: 'major', title: 'Outstanding Walk Downs', helpText: 'Circuits that require field assessment', value: 'alarmStats.outstandingNotificationsByStatus.mnra', defaultValue: 0, loading: 'alarmStatsLoading' },
          { type: 'major', title: 'Outstanding Work Orders', helpText: 'All open EHT related notifications not yet scheduled for repair', value: 'outstandingWorkOrders', defaultValue: 0 },
          { type: 'major', feature: ['htc-stats'], title: 'Total Estimated EHT Maintenance', helpText: `Based on site's average time to complete MNRA and WO and predictive failures: %estTotalMaintenanceHoursExplain%`, value: 'alarmStats.estTotalMaintenaceHours', defaultValue: 0, loading: 'alarmStatsLoading' },
          { type: 'major', feature: ['htc-stats'], title: 'Total kWh', helpText: 'Sum of kWh for all devices or devices that match the filter applied for the past 12 months', value: 'dashboardStats.kwh', defaultValue: '-', formatFunction: 'formatkWh', dynamicTitle: 'dynamickWhTitle', dynamicHelpText: 'dynamickWhHelpText' },
          { type: 'major', feature: ['htc-stats'], title: 'Total Wasted', helpText: 'Sum of kWh used by devices when they were forced on in the past 12 months', value: 'dashboardStats.forcedOnKwh', defaultValue: '-', formatFunction: 'formatkWh', dynamicTitle: 'dynamickWhForcedOnTitle', dynamicHelpText: 'dynamicForcedOnkWhHelpText' }
        ] }
      ] },

      { type: 'grid', feature: ['htc-stats'], container: false, xs: 12, md: 12, children: [
        { type: 'block', title: 'EHT Alarm History', children: [
          { type: 'component', componentName: 'AlarmHistory' },
        ] }
      ] },

      { type: 'grid', feature: ['htc-stats', 'advanced-metrics'], container: false, xs: 12, md: 12, children: [
        { type: 'block', title: 'EHT Sensor History', children: [
          { type: 'component', componentName: 'SensorHistory' },
        ] }
      ] },

      { type: 'grid', container: false, xs: 12, md: 6, children: [
        { type: 'block', title: 'Alarm Overview', children: [
          { type: 'minor', title: 'Total Alarms', helpText: 'Total active alarms in the system', value: 'alarmStats.totalAlarms', defaultValue: 'N/A', loading: 'alarmStatsLoading' },
          { type: 'minor', feature: ['htc-stats'], title: 'HTCs in Alarm', helpText: 'Total Heat Trace Controllers in alarm', value: 'alarmStats.htcsInAlarm', defaultValue: 'N/A', loading: 'alarmStatsLoading' },
          { type: 'minor', feature: ['htc-stats'], title: 'HTCs in Crit. Alarm', helpText: 'Total Heat Trace Controllers in critical alarm', value: 'alarmStats.criticalAlarms', defaultValue: 'N/A', loading: 'alarmStatsLoading' }
        ] }
      ] },

      { type: 'grid', container: false, xs: 12, md: 6, children: [
        { type: 'block', title: 'System Overview', children: [
          { type: 'minor', title: 'Nuisance Alarms', helpText: 'Total number of alarms determined to be a nuisance alarm', value: 'alarmStats.notesByHtc.nuisance', defaultValue: 'N/A', loading: 'alarmStatsLoading' },
          { type: 'minor', title: 'LOTO', helpText: 'Total number of alarms resulting from device being Locked Out / Tagged Out', value: 'circuitsLoto', defaultValue: 'N/A' },
          { type: 'minor', title: 'Offline Devices', helpText: 'Total number of alarms resulting from device being offline', value: 'alarmStats.deviceStatusSummary.offline', defaultValue: 'N/A', loading: 'alarmStatsLoading' }
        ] }
      ] },

      { type: 'grid', container: false, xs: 12, md: 6, children: [
        { type: 'block', title: 'ERP Overview', children: [
          { type: 'minor', feature: ['htc-stats'], title: '%mnraLabel%', helpText: 'Total open SAP tickets requiring engineering', value: 'alarmStats.outstandingNotificationsByStatus.mnra', defaultValue: 'N/A', loading: 'alarmStatsLoading' },
          { type: 'minor', title: '%engRequiredLabel%', helpText: 'Total open SAP Work Orders', value: 'alarmStats.outstandingNotificationsByStatus.eng_required', defaultValue: 'N/A', loading: 'alarmStatsLoading' },
          { type: 'minor', title: '%workOrderLabel%', helpText: 'Total open SAP notifications for an approved minor repair', value: 'alarmStats.outstandingNotificationsByStatus.wo', defaultValue: 'N/A', loading: 'alarmStatsLoading' }
        ] }
      ] },

      { type: 'grid', container: false, xs: 12, md: 6, children: [
        { type: 'block', title: `Devices in Alarm without ${pluralWorkTicketLabel}`, children: [
          ...criticalAlarmsWithoutNotificationArr
        ] }
      ] },

      { type: 'grid', container: false, xs: 12, md: 6, children: [
        { type: 'block', title: 'Work Completed', children: [
          { type: 'component', componentName: 'WorkCompletedGraph' },
        ] }
      ] },

      { type: 'grid', container: false, xs: 12, md: 6, children: [
        { type: 'block', title: 'Alarms by Status', children: [
          { type: 'component', componentName: 'AlarmsByStatusGraph' },
        ] }
      ] },

      { type: 'grid', container: false, xs: 12, md: 6, children: [
        { type: 'block', title: `New ${pluralWorkTicketLabel}`, children: [
          { type: 'component', componentName: 'NewWorkTickets' },
        ] }
      ] },

      { type: 'grid', container: false, xs: 12, md: 6, children: [
        { type: 'block', title: 'Critical Alarms', children: [
          { type: 'component', componentName: 'CriticalAlarms' },
        ] }
      ] },


    ] }

  ];

  return (
    <CustomStatistics
      layoutPreferencePath={props.layoutPreferencePath || 'client.preferences.dashboard.layout'}
      variables={variables}
      interpolatedVariables={interpolatedVariables}
      defaultLayout={defaultLayoutOverride ? defaultLayoutOverride : defaultLayout}
    />
  );
}

DashboardStatistics.propTypes = {
  layoutPreferencePath: PropTypes.string,
  defaultLayoutOverride: PropTypes.array,
};

DashboardStatistics.defaultProps = {
  layoutPreferencepPath: null,
  defaultLayoutOverride: null
};
