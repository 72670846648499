import './BatchConfirmScreen.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { getBatch } from 'src/module/batchSchedule/selector';

import PropTypes from 'prop-types';

import Status from './Status';
import Confirm from './Confirm';
import { fetchBatchStatus } from 'src/module/batchSchedule/action';
import { fetchRecipes } from 'src/module/recipe/action';
import NotFoundScreen from 'src/screen/Error/NotFoundScreen';
import { isWhitelabel } from 'src/utils/utils';

export default function BatchConfirmScreen (props) {
  const batchId = props.match.params.batch_id;
  const batch = useSelector(getBatch(batchId));
  const dispatch: any = useDispatch();
  const [statusFilter, setStatusFilter] = React.useState('all');

  // if no recipes then fetch them
  useEffect(() => {
    dispatch(fetchBatchStatus(batchId));
    dispatch(fetchRecipes());
  }, [batchId]);

  if (!batch) {
    return (<NotFoundScreen />);
  }

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div id='batch-confirm-screen' className='page' data-testid='grouped-devices-screen'>
      <Helmet>
        <title>{title} - {batch.get('started_at') || batch.get('scheduled_at') ? 'Mass Program Status' : 'Confirm Mass Program'}</title>
      </Helmet>
      <div>
        {batch.get('started_at') || batch.get('scheduled_at') ? <Status statusFilter={statusFilter} setStatusFilter={setStatusFilter}/> : <Confirm statusFilter={statusFilter} setStatusFilter={setStatusFilter}/>}
      </div>
    </div>
  );
}

BatchConfirmScreen.propTypes = {
  match: PropTypes.object
};

BatchConfirmScreen.defaultProps = {};
