import { Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as adminActions from './action';
import { CLEAR } from '../authentication/action';

export const initialState = Map({
  admin: Map(),
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState
      .set('admin', Map())
  );
}

export const adminReducer = createTypedReducer(initialState, {

  [adminActions.FETCH_CLIENTS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['admin', 'clients'], action.clients)
    );
  },

  [adminActions.FETCH_TIERS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['admin', 'tiers'], action.tiers)
    );
  },

  [adminActions.UPDATE_CLIENT_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const clientIndex = nextState
        .getIn(['admin', 'clients'])
        .findIndex((item) => item.get('id') === action.client.get('id'));

      if (clientIndex !== -1) {
        // If client is found, update it at the found index
        nextState.updateIn(['admin', 'clients', clientIndex], () => action.client);
      }
    });
  },

  [adminActions.UPDATE_CLIENT] (state, action) {
    return state.withMutations((nextState) => {
      const clientIndex = nextState
        .getIn(['admin', 'clients'])
        .findIndex((item) => item.get('id') === action.clientId);

      if (clientIndex !== -1) {
        // If client is found, merge with the current map and update it at the found index
        nextState.updateIn(['admin', 'clients', clientIndex], (currentMap) =>
          currentMap.merge(action.clientRec)
        );
      }
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },
});
export default adminReducer;
