import { takeLatest, put, call, select } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as scheduleApi from '../../service/api/batchSchedules';
import * as batchScheduleActions from './action';
import * as circuitActions from '../circuit/action';

import * as devicesActions from '../device/action';
import * as navigationActions from 'src/module/navigation/action';
import toast from '../../utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';
import * as filterSelector from '../filter/selector';
import Immutable from 'immutable';


export function* batchScheduleRoot () {
  yield takeLatest(batchScheduleActions.FETCH_BATCH_SCHEDULES, fetchBatchSchedules);
  yield takeLatest(batchScheduleActions.FETCH_BATCH, fetchBatch);
  yield takeLatest(batchScheduleActions.CREATE_BATCH, createBatch);
  yield takeLatest(batchScheduleActions.INITIATE_RUN_BATCH, initiateRunBatch);
  yield takeLatest(batchScheduleActions.VALIDATE_BATCH, validateBatch);
  yield takeLatest(batchScheduleActions.DELETE_SCHEDULED_DEVICE, deleteScheduledDevice);
  yield takeLatest(batchScheduleActions.UPDATE_BATCH_SCHEDULE, updateBatchSchedule);
  yield takeLatest(batchScheduleActions.FETCH_BATCH_STATUS, fetchBatchStatus);
  yield takeLatest(batchScheduleActions.FETCH_BATCH_DEVICES, fetchBatchDevices);
  yield takeLatest(batchScheduleActions.CREATE_BATCH_DEVICE_GROUP, createBatchDeviceGroup);
  yield takeLatest(batchScheduleActions.DELETE_DEVICE_GROUP, deleteDeviceGroup);

}

function* fetchBatchSchedules (action) {
  try {
    const batchSchedules = yield call(scheduleApi.getBatchSchedules, action);

    yield put(batchScheduleActions.fetchBatchSchedulesSuccess(batchSchedules || List(), action.tab));

    const deviceSet = new Set();
    let uniqueDevices = List([]);

    batchSchedules.get('data').forEach(batch => {
      batch.get('devices').forEach(device => {
        if (!deviceSet.has(device.get('id'))) {
          deviceSet.add(device.get('id'));
          uniqueDevices = uniqueDevices.push(device);
        }
      });
    });
    yield put(devicesActions.loadDevicesFromCircuitsList(uniqueDevices));

  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving scheduled program'));
    yield put(batchScheduleActions.fetchBatchSchedulesFailed(Map(err)));
  }
}
function* fetchBatchDevices (action) {
  try {
    const devices = yield call(scheduleApi.getBatchDevicesPaginated, action);

    yield put(batchScheduleActions.fetchBatchDevicesSuccess(action.batchId, devices));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving batch devices'));
    yield put(batchScheduleActions.fetchBatchDevicesFailed(Map(err)));
  }
}

function* fetchBatch (action) {
  try {
    const batch = yield call(scheduleApi.getBatch, action);
    yield put(batchScheduleActions.fetchBatchSuccess(batch || Map()));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving batch'), err.response.status);
    yield put(batchScheduleActions.fetchBatchFailed(Map(err)));
  }
}

function* validateBatch (action) {
  try {
    const resp = yield call(scheduleApi.validateBatch, action);
    yield put(batchScheduleActions.validateBatchSuccess(resp || Map()));
    yield put(batchScheduleActions.fetchBatchDevices(action.batchId, action.paginationDetails, action.filter));
    toast.success('Mass Program Validated Successfully!');
  } catch (err: any) {
    yield put(batchScheduleActions.fetchBatchDevices(action.batchId, action.paginationDetails, action.filter));
    if (err?.response?.status == 422) {
      toast.error(createUserFriendlyErrorMessage(err, 'Mass Program Validation Failed'), err.response.status);
      yield put(batchScheduleActions.validateBatchFailed(Immutable.fromJS(err.response.data)));
    }
    else {
      toast.error(createUserFriendlyErrorMessage(err, `Couldn't validate Mass Program`), err?.response?.status);
    }
  }
}

function* initiateRunBatch (action) {
  try {
    yield call(scheduleApi.initiateRunBatch, action);
    yield put(batchScheduleActions.initiateRunBatchSuccess(action.batchId));

  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error sending Mass Program update'), err?.response?.status);
    yield put(batchScheduleActions.initiateRunBatchFailed(Map(err)));
  }
}

function* updateBatchSchedule (action) {
  try {
    const resp = yield call(scheduleApi.updateBatchSchedule, action);
    yield put(batchScheduleActions.updateBatchScheduleSuccess(resp));
    yield put(navigationActions.pushHistory(`/program/list`));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating Mass Program'), err?.response?.status);
    yield put(batchScheduleActions.updateBatchScheduleFailed(Map(err)));
  }
}

function* deleteScheduledDevice (action) {
  try {
    yield call(scheduleApi.deleteScheduledDevice, action);
    yield put(batchScheduleActions.fetchBatchDevices(action.batchId, action.paginationDetails, action.filter));
    yield put(batchScheduleActions.deleteScheduledDeviceSuccess(action.batchId, action.deviceId));
    yield put (circuitActions.deleteCircuitSuccess(action.deviceId));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error removing device'), err?.response?.status);
    yield put(batchScheduleActions.deleteScheduledDeviceFailed(Map(err)));
  }
}

function* createBatch (action) {
  try {
    const filters = yield select(filterSelector.getDevicesSelectedFilters);
    const filtersRaw = filters.toJS();
    filtersRaw['filter'] = action.filter;
    filtersRaw['panelId'] = action.panelId;

    const schedule = yield call(scheduleApi.createBatch, action, Immutable.fromJS(filtersRaw));
    yield put(batchScheduleActions.createBatchSuccess(schedule));
    yield put(navigationActions.pushHistory(`/program/${schedule.getIn(['batch', 'id'])}`));
    yield put(devicesActions.setSettingsModalClose(null));
  }
  catch (err: any) {
    if (err?.response?.status == 422) {
      // change here - data includes the batch id
      toast.error(createUserFriendlyErrorMessage(err, 'Mass Programming Validation Failed'), err.response.status);
      yield put(navigationActions.pushHistory(`/program/${err.response.data.batch.id}`));
      yield put(batchScheduleActions.validateBatchFailed(Immutable.fromJS(err.response.data)));
    }
    else {
      toast.error(createUserFriendlyErrorMessage(err, 'Mass Programming error'), err.response.status);
      yield put(batchScheduleActions.createBatchFailed(err));
    }
  }
}

function * fetchBatchStatus (action) {
  try {
    const batchStatus = yield call(scheduleApi.getBatchStatus, action);

    yield put(batchScheduleActions.fetchBatchStatusSuccess(action.batchId, batchStatus));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving mass programming status'), err.response.status);
    yield put(batchScheduleActions.fetchBatchStatusFailed(Map(err)));
  }
}

function * createBatchDeviceGroup (action) {
  try {
    yield call(scheduleApi.createBatchDeviceGroup, action);

    yield put(batchScheduleActions.createBatchDeviceGroupSuccess(action.batchId, action.name));
    yield put(navigationActions.pushHistory("/program/list/device-groups"));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error creating mass programming device group'), err.response);
    yield put(batchScheduleActions.createBatchDeviceGroupFailed(Map(err)));
  }
}

function * deleteDeviceGroup (action) {
  try {
    yield call(scheduleApi.deleteDeviceGroupById, action);
    yield put(batchScheduleActions.deleteDeviceGroupSuccess(action.groupId));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error deleting mass programming device group'), err.response);
    yield put(batchScheduleActions.deleteDeviceGroupFailed(Map(err)));
  }
}

export default batchScheduleRoot;
