import { takeLatest, put, call } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as api from '../../service/api';
import * as upgradeActions from './action';
import toast from '../../utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * upgradeRoot () {
  yield takeLatest(upgradeActions.FETCH_DEVICE_UPGRADES, fetchDeviceUpgrades);
  yield takeLatest(upgradeActions.CREATE_DEVICE_UPGRADE, createDeviceUpgrade);
  yield takeLatest(upgradeActions.UPDATE_DEVICE_UPGRADE, updateDeviceUpgrade);
  yield takeLatest(upgradeActions.DELETE_DEVICE_UPGRADE, deleteDeviceUpgrade);
  yield takeLatest(upgradeActions.UPDATE_DEVICE_UPGRADE_CONDITION, updateDeviceUpgradeCondition);
  yield takeLatest(upgradeActions.ADD_DEVICE_UPGRADE_CONDITION, addDeviceUpgradeCondition);
  yield takeLatest(upgradeActions.UPDATE_DEVICE_UPGRADE_CONDITION_BRANCH, updateDeviceUpgradeConditionBranch);
  yield takeLatest(upgradeActions.ADD_DEVICE_UPGRADE_CONDITION_BRANCH, addDeviceUpgradeConditionBranch);
  yield takeLatest(upgradeActions.DELETE_DEVICE_CONDITION_BRANCH, deleteDeviceConditionBranch);

}

function * createDeviceUpgrade (action) {
  try {
    const upgrade = yield call(api.createUpgrade, action);
    yield put(upgradeActions.createDeviceUpgradeSuccess(upgrade));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error creating upgrade path'), err.response.status);
    yield put(upgradeActions.createDeviceUpgradeFailed(Map(err)));
  }
}

function * fetchDeviceUpgrades (action) {
  try {
    const upgrades = yield call(api.fetchDeviceUpgrades, action);
    yield put(upgradeActions.fetchDeviceUpgradesSuccess(upgrades || List()));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error retrieving upgrade paths'), err);
    yield put(upgradeActions.fetchDeviceUpgradesFailed(Map(err)));
  }
}

function * deleteDeviceUpgrade (action) {
  try {
    yield call(api.deleteDeviceUpgrade, action);
    yield put(upgradeActions.deleteDeviceUpgradeSuccess(action.deviceUpgradeId));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to delete upgrade paths'), err.response.status);
    yield put(upgradeActions.deleteDeviceUpgradeFailed(err));
  }
}

function * deleteDeviceConditionBranch (action) {
  try {
    yield call(api.deleteDeviceConditionBranch, action);
    yield put(upgradeActions.deleteDeviceConditionBranchSuccess(action.upgradeConditionBranchId));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to delete upgrade path condition'), err.response.status);
    yield put(upgradeActions.deleteDeviceConditionBranchFailed(err));
  }
}

function * updateDeviceUpgrade (action) {
  try {
    const upgrade = yield call(api.updateDeviceUpgrade, action);
    yield put(upgradeActions.updateDeviceUpgradeSuccess(upgrade));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating Upgrade Path'), err.response.status);
    yield put(upgradeActions.updateDeviceUpgradeFailed(Map(err)));
  }
}

function * addDeviceUpgradeCondition (action) {
  try {
    const upgrade = yield call(api.addDeviceUpgradeCondition, action);
    yield put(upgradeActions.addDeviceUpgradeConditionSuccess(upgrade));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to create new upgrade path condition'), err.response.status);
    yield put(upgradeActions.addDeviceUpgradeConditionFailed(err));
  }
}

function * updateDeviceUpgradeCondition (action) {
  try {
    const upgradePath = yield call(api.updateDeviceUpgradeCondition, action);
    yield put(upgradeActions.updateDeviceUpgradeConditionSuccess(upgradePath));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating condition'), err.response.status);
    yield put(upgradeActions.updateDeviceUpgradeConditionFailed(Map(err)));
  }
}

function * addDeviceUpgradeConditionBranch (action) {
  try {
    const upgrade = yield call(api.addDeviceUpgradeConditionBranch, action);
    yield put(upgradeActions.addDeviceUpgradeConditionBranchSuccess(upgrade));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to create new upgrade path condition'), err);
    yield put(upgradeActions.addDeviceUpgradeConditionBranchFailed(err));
  }
}

function * updateDeviceUpgradeConditionBranch (action) {
  try {
    const upgradePath = yield call(api.updateDeviceUpgradeConditionBranch, action);
    yield put(upgradeActions.updateDeviceUpgradeConditionBranchSuccess(upgradePath));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating condition'), err.response.status);
    yield put(upgradeActions.updateDeviceUpgradeConditionBranchFailed(Map(err)));
  }
}

export default upgradeRoot;
