import 'src/screen/Protected/Wizards/Wizards.scss';

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircleOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Button from 'src/component/UI/Button';
import ImportForm from 'src/component/UI/ImportForm';

import { fetchIntegrations } from 'src/module/integration/action';
import { fetchDevicesInAlarmWithoutNotification, fetchAlarmStats } from 'src/module/alarm/action';
import { getIntegrations } from 'src/module/integration/selector';
import { getCriticalAlarmsWithoutNotification, getAlarmHistoricalSummary } from 'src/module/alarm/selector';
import { getPluralWorkTicketLabel } from 'src/module/authentication/selector';
import { getGuide } from 'src/module/facility/selector';
import { importData } from 'src/module/import/action';

import UploadStatusTable from 'src/component/UI/UploadStatusTable';
import { isWhitelabel } from 'src/utils/utils';


export default function GuideScreen () {
  const guide = useSelector(getGuide);
  const criticalAlarmsWithoutNotification = useSelector(getCriticalAlarmsWithoutNotification);
  const alarmHistory = useSelector(getAlarmHistoricalSummary) as any;
  const integrations = useSelector(getIntegrations);
  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDevicesInAlarmWithoutNotification(null));
    dispatch(fetchAlarmStats(null));
  }, [guide]);

  useEffect(() => {
    dispatch(fetchIntegrations());
  }, []);


  const totalAlarmsAutoHandled = alarmHistory.getIn([alarmHistory.size - 1, 'total_auto_handled'], 'N/A');

  const [sectionCollapsed, setSectionCollapsed] = useState(Map({
    supervisorAlarmData: false,
    erpData: true,
    otherData: true,
    validateAlarms: true,
    sendReport: criticalAlarmsWithoutNotification !== 0,
  }));

  const uploadData: any = {
    supervisorAlarmData: [],
    erpData: [],
    otherData: []
  };

  const uploads = guide.get('uploads', Map());

  uploads.keySeq().forEach((key) => {
    uploadData[uploads.getIn([key, 'importType'], 'otherData')].push({ name: uploads.getIn([key, 'file', 'name']), status: uploads.getIn([key, 'status']) });
  });

  const allUploads = [];
  Object.values(uploadData)?.map((data: any) => data?.map(rec => allUploads.push(rec)));

  const makeToggleSection = (section) => {
    return () => {
      setSectionCollapsed(sectionCollapsed.set(section, !sectionCollapsed.get(section)));
    };
  };
  useEffect(() => {
    if (criticalAlarmsWithoutNotification === 0  && sectionCollapsed.get('sendReport')) {
      setSectionCollapsed(sectionCollapsed.set('sendReport', false));
    } else if (criticalAlarmsWithoutNotification !== 0 && !sectionCollapsed.get('sendReport')) {
      setSectionCollapsed(sectionCollapsed.set('sendReport', true));
    }
  }, [criticalAlarmsWithoutNotification]);

  const isUploadStepComplete = (importType, subType=null) => {
    let completed = false;

    uploads.keySeq().forEach((key) => {
      if (importType === uploads.getIn([key, 'importType'])) {
        if ((subType && subType === uploads.getIn([key, 'subType'])) || !subType) {
          if (uploads.getIn([key, 'status']) === 'success') {
            completed = true;
          }
        }
      }
    });

    return completed;
  };

  const isSupervisorDataComplete = () => {
    const integrationTypes = integrations.reduce((acc, integration) => {
      acc.push(integration.get('type'));
      return acc;
    }, []);

    const checks = [];

    if (integrationTypes.indexOf('raychem') !== -1 || integrationTypes.indexOf('dts') !== -1) {
      checks.push(isUploadStepComplete('supervisorAlarmData', 'dts'));
    }

    if (integrationTypes.indexOf('tvne') !== -1) {
      checks.push(isUploadStepComplete('supervisorAlarmData', 'tvne'));
    }

    return checks.reduce((acc, check) => check && acc, true);
  };

  const completedSteps = {
    supervisorAlarmData: isSupervisorDataComplete(),
    erpData: isUploadStepComplete('erpData'),
    otherData: isUploadStepComplete('otherData'),
    validateAlarms: (criticalAlarmsWithoutNotification === 0),
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page guide-wizard' id='guide'>
      <Helmet>
        <title>{title} - Build an Alarm Report</title>
      </Helmet>

      <Grid container spacing={2}>

        <Grid item xs={12}>
          <h2>Build an Alarm Report</h2>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('supervisorAlarmData') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('supervisorAlarmData')}>
              {completedSteps['supervisorAlarmData'] ? (<CheckCircleOutlinedIcon className='complete' />) : (<CircleOutlinedIcon />)}
              <span>Step 1 - Upload Facility Data&nbsp;
                <Tooltip title={`Upload facility data which may include; alarm lists, active ${pluralWorkTicketLabel}, device information, etc.`}>
                  <QuestionMarkIcon className="questionMarkIcon" />
                </Tooltip>
              </span>
              <span className='expand-arrow'>{sectionCollapsed.get('supervisorAlarmData') ? '▲' : '▼'}</span>
            </h2>
            { !sectionCollapsed.get('supervisorAlarmData') ? <>
              <ImportForm  importType='supervisorAlarmData' importAction={importData} />
              <UploadStatusTable uploadData={allUploads} />
            </> : null }
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('validateAlarms') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('validateAlarms')}>
              {completedSteps['validateAlarms'] ? (<CheckCircleOutlinedIcon className='complete' />) : (<CircleOutlinedIcon />)}
              <span>Step 2 - Validate Alarms&nbsp;</span>
              <span className='expand-arrow'>{sectionCollapsed.get('validateAlarms') ? '▲' : '▼'}</span>
            </h2>
            { !sectionCollapsed.get('validateAlarms') ? (
              <div className='inner-section'>
                <p>There are currently {criticalAlarmsWithoutNotification} device(s) that require your attention.</p>
                <Button cta to='/maintenance'>Validate Alarms</Button>
              </div>
            ) : null }
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('sendReport') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('sendReport')}>
              <CircleOutlinedIcon />
              <span>Step 3 - Send Report!&nbsp;</span>
              <span className='expand-arrow'>{sectionCollapsed.get('sendReport') ? '▲' : '▼'}</span>
            </h2>
            { !sectionCollapsed.get('sendReport') ? (
              <div className='inner-section'>
                <p>Your report is ready to be sent! SmartTrace has automatically handled <b>{totalAlarmsAutoHandled}</b> alarms for you today, or about <b>{totalAlarmsAutoHandled} hours</b> of time!</p>
                <p>All that&apos;s left is to send the report. Click below to preview the report you have built, and send it out.</p>
                <Button cta to='/report/send'>Preview Report and Send</Button>
              </div>
            ) : null }
          </Paper>
        </Grid>
      </Grid>

    </div>
  );
}
