import { createSelector } from 'reselect';
import { Map, List } from 'immutable';

import { userAvatarUrl } from 'src/utils/utils';

export const getAuthenticationState = (state) =>
  state.authentication;

export const getAuthCtx = state =>
  state.authentication.get('authCtx', Map());

export const getAuthenticatedUser = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('user', Map());
  }
);

export const getAuthenticatedUserId = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['user', 'id']);
  }
);

export const getAbilities = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('abilities', []);
  }
);

export const getFeatures = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('features', List());
  }
);

export const getHsIdentificationAccessToken = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('hsIdentificationAccessToken', []);
  }
);

export const getAuthenticatedUserAvatar = createSelector(
  [getAuthenticatedUser],
  (user) => userAvatarUrl(user)
);

export const getAuthenticatedUserEmail = createSelector(
  [getAuthenticatedUser],
  (user) => {
    try {
      return user.getIn(['email'], null);
    } catch (err) {
      return null;
    }
  }
);

export const getPasswordExpiryDate = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['authCtx', 'passwordExpiry']);
  }
);

export const getClient = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('client', Map());
  }
);

export const getCriticalityLabels = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['client', 'preferences', 'criticalityLabels'], List(['None', 'Critical']));
  }
);

export const getTemperatureUnit = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['user', 'preferences', 'temperatureUnit'], 'C');
  }
);

export const getAcceptedTerms = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['user', 'preferences', 'acceptedTerms'], false);
  }
);

export const getUserMustResetPassword = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['user', 'userMustResetPassword'], false);
  }
);

export const getTrainingURL = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['client', 'preferences', 'trainingURL'], null);
  }
);
export const getTrainingPassword = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['client', 'preferences', 'trainingPassword'], null);
  }
);
export const getTablePagePreferences = (tableId) => createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['user', 'preferences', 'tablePagePreferences', tableId], 10);
  }
);
export const getClientId = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['client', 'id'], null);
  }
);

export const getClientsForUser = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('clients', Map());
  }
);

export const getErrors = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('errors', List());
  }
);

export const getMessages = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('messages', List());
  }
);

export const getIsAuthenticated = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('isAuthenticated', List());
  }
);

export const getIsAuthenticating = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('isAuthenticating', List());
  }
);

export const getIsInitialized = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('initialized', List());
  }
);

export const getIsMfaRequired = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('isMFARequired', false);
  }
);
export const getMfaOptions= createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('MFAOptions', []);
  }
);

export const getOTP_URL= createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('OTP_URL', '');
  }
);

export const getIsValidatingJwt = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('isValidatingJwt', false);
  }
);

export const getPasswordResetToken = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('passwordResetToken');
  }
);

export const getNotifications = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['notificationPreferences', 'notifications']);
  }
);

export const getLoadingNotifications = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['notificationPreferences', 'notifications', 'loading'], false);
  }
);

export const getLoadingNotificationPrefences = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['notificationPreferences', 'loading'], false);
  }
);

export const getNotificationPreferences = createSelector(
  [getAuthenticationState],
  (authentication) => {
    const clientId = authentication.getIn(['client', 'id']);
    return authentication.getIn(['notificationPreferences', 'disabledNotifications', clientId], null);
  }
);

export const getSingularWorkTicketLabel = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['client', 'preferences', 'workTicketLabel', 'singular'], 'Work Ticket');
  }
);

export const getPluralWorkTicketLabel = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['client', 'preferences', 'workTicketLabel', 'plural'], 'Work Tickets');
  }
);
export const getFacilityName = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.getIn(['client', 'name'], '');
  }
);

export const getJwtUser = createSelector(
  [getAuthenticationState],
  (authentication) => {
    return authentication.get('jwtUser');
  }
);
