
import './ProgrammingDiscrepanciesScreen.scss';

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import UnitSelector from 'src/component/UI/UnitSelector';
import Paper from '@mui/material/Paper';
import DataTable from 'src/component/UI/DataTable';
import { Helmet } from 'react-helmet';
import { List, Map } from 'immutable';
import _ from 'lodash';
import ForcedOnIcon from '@mui/icons-material/OfflineBoltOutlined';
import DeviceLinkCombo from 'src/component/UI/DeviceLinkCombo';
import { acknowledgeProgrammingDiscrepancy, fetchDevicesProgrammingDiscrepancies, fetchEnumeratedDeviceSettings, updateDeviceDesignedSettings, updateDeviceSettings } from 'src/module/device/action';
import { getDevicesSelectedFilters, getSelectedSetting } from 'src/module/filter/selector';
import { getAllProgrammingDiscrepancies, getDeviceConfigFields, getEnumeratedDeviceSettings, getProgrammingDiscrepanciesPageInfo } from 'src/module/device/selector';
import lookUp, { langLookUpText } from 'src/utils/langLookUp';
import { formatDate, formatDiscrepancyValue, isWhitelabel } from 'src/utils/utils';
import { getTemperatureUnit } from 'src/module/authentication/selector';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import PermissionFence from 'src/component/PermissionFence';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { ModelTraining } from '@mui/icons-material';
import Button from 'src/component/UI/Button';
import { stringToJsonObject } from 'src/utils/utils';
import ConfirmModal from 'src/component/UI/ConfirmModal';
import Tooltip from '@mui/material/Tooltip';
import { setSelectedSetting } from 'src/module/filter/action';
import AutoCompleteSelect from 'src/component/UI/AutoCompleteSelect';
import FeatureFence from 'src/component/FeatureFence';
import { addForcedOnCircuit } from 'src/module/circuit/action';
import ForcedOnModal from 'src/component/UI/ForcedOnCircuits/ForcedOnModal';

export default function ProgrammingDiscrepancies () {
  const dispatch = useDispatch();
  const filters = useSelector(getDevicesSelectedFilters);
  const data: any = useSelector(getAllProgrammingDiscrepancies);
  const cleanedData: any = data?.filter(obj => obj.diffArray && obj?.diffArray?.toJS()?.length !== 0) || List();
  const pageInfo = useSelector(getProgrammingDiscrepanciesPageInfo);
  const temperatureUnit = useSelector(getTemperatureUnit);
  const [page, setPage] = useState(pageInfo.get('page', 0));
  const [pageSize, setPageSize] = useState(pageInfo.get('pageSize', 10));
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [buttonClicked, setButtonClicked] = useState(null);
  const [selectedDeviceId, setselectedDeviceId] = useState(null);
  const [selectedForcedOnDeviceId, setSelectedForcedOnDeviceId] = useState(null);
  const [forcedOnModalOpen, setForcedOnModalOpen] = useState(false);
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);


  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);
  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setSort(_sort);
    setFilter(_filter);
  };
  const selectedSetting = useSelector(getSelectedSetting);
  const enumeratedDeviceSettings = useSelector(getEnumeratedDeviceSettings);
  const [value, setValue] = useState(selectedSetting?.label ? { label: selectedSetting.label, value: selectedSetting?.value } : null);
  const settings = enumeratedDeviceSettings?.reduce((acc, setting) => {
    acc.push({ label: lookUp[setting]?.en || 'N/A', value: setting });
    return acc;
  }, []) || [];
  const ref_filters = useRef(null);
  const ref_others = useRef(null);

  useEffect(() => {
    const others = `${temperatureUnit} ${page} ${pageSize} ${filter} ${sort} ${selectedSetting?.value}`;
    if (!_.isEqual(ref_filters.current, filters.toJS()) || others !== ref_others.current) {
      ref_filters.current = filters.toJS();
      ref_others.current = others;
      const paginationDetails = { page, pageSize, filter, sort };
      dispatch(fetchDevicesProgrammingDiscrepancies(filters, paginationDetails, selectedSetting?.value));
    }
  }, [filters, temperatureUnit, page, pageSize, filter, sort, selectedSetting]);



  useEffect(() => {
    if (!confirmModalOpen) {
      setselectedDeviceId(null);
    }
  }, [confirmModalOpen]);

  const confirmModalSubmit = () => {
    toggleConfirmModal();
    const settingsDiff = cleanedData?.find(obj => obj.device_id === selectedDeviceId)?.diffArray;

    if (buttonClicked === langLookUpText('update_designed_settings')) {
      dispatch(updateDeviceDesignedSettings(selectedDeviceId));
    }
    else {
      let settingsObj = {};
      let sensorsObj = {};
      settingsDiff?.map(rec => {
        if (rec.get('setting').includes('.')) {
          const jsonObj = stringToJsonObject(rec.get('setting'), rec.get('designed'));
          sensorsObj = _.merge({}, sensorsObj, jsonObj);
        }
        else {
          settingsObj[rec.get('setting')] = rec.get('designed');
        }
      });
      settingsObj = Object.assign({}, settingsObj, sensorsObj);
      dispatch(updateDeviceSettings(selectedDeviceId, Map({ settings: settingsObj })));
    }
  };

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const handleDesignedSettingsModalSubmit = (deviceId) => {
    setselectedDeviceId(deviceId);
    setButtonClicked(langLookUpText('update_designed_settings'));
    setConfirmMessage(langLookUpText('update_designed_settings_confirm'));
    toggleConfirmModal();
  };

  const handleReprogramDeviceSettingsModalSubmit = (deviceId) => {
    setselectedDeviceId(deviceId);
    setButtonClicked(langLookUpText('reprogram_device_settings'));
    setConfirmMessage(langLookUpText('reprogram_device_settings_confirm'));
    toggleConfirmModal();
  };

  const buttonToUpdateDevice = (device_id, locked) => {
    const toolTip = locked ? 'Contact your system administrator to gain access to reprogram device settings' : "Reprogram Device Settings";
    return (
      <Tooltip title={toolTip} placement="top">
        <div>
          <Button onClick={() => handleReprogramDeviceSettingsModalSubmit(device_id)} disabled={locked}><ManageHistoryIcon style={{ fontSize: 24 }} /></Button>
        </div>
      </Tooltip>
    );
  };

  const renderStatus  = (device) => {
    return (
      <div className='discrepancy-status'>
        {
          device?.get('discrepancy_acknowledged') ?
            <Tooltip title='Toggle acknowledged status' placement="top">
              <div onClick={() => handleAcknowledgeDiscrepancy(device?.get('id'), device?.get('discrepancy_id'))} className='acknowledge-indication make-unselectable'>ack</div>
            </Tooltip>
            :
            <PermissionFence can={['edit-device']} noPermissionComponent={<div className='not-acknowledge-indication make-unselectable'>new</div>}>
              <Tooltip title='Toggle acknowledged status' placement="top">
                <div className='not-acknowledge-indication make-unselectable' onClick={() => handleAcknowledgeDiscrepancy(device?.get('id'), device?.get('discrepancy_id'))}>new</div>
              </Tooltip>
            </PermissionFence>
        }
        {
          device?.get('has_forced_on_record') ?
            <div className='device-forced-on-indication'>
              <Tooltip title='Device is Forced-On and has record' placement="top">
                <div><ForcedOnIcon style={{ fontSize: 28 }} /></div>
              </Tooltip>
            </div> : device?.get('settings')?.get('forcedOn') ?
              <Tooltip title='Create record for Forced-On device' placement="top">
                <div className='device-not-forced-on-indication'>
                  <div onClick={() => toggleForcedOnModal(device.get('id'))}><ForcedOnIcon style={{ fontSize: 28 }} /></div>
                </div>
              </Tooltip>
              : null
        }
      </div>
    );
  };

  const columns = [
    {
      id: 'device_tag',
      Header: 'Device Tag',
      width: '80px',
      accessor: (row: any) => row.device,
      Cell: (row: any) => { return (<DeviceLinkCombo device={row.cell.row.original.device} />); },
    },
    {
      id: 'setting',
      Header: 'Setting',
      accessor: (row: any) => row.diffArray,
      sortable: true,
      Cell: (row: any) => row.cell.value.map((settingDiff, index) => (<div key={index} className='alarm-list'>{langLookUpText(settingDiff.get('setting'))}</div>)),
    },
    {
      id: 'designed',
      Header: 'Designed',
      accessor: (row: any) => row.diffArray,
      sortable: true,
      Cell: (row: any) => row.cell.value.map((settingDiff, index) => (<div key={index} className='alarm-list'>{formatDiscrepancyValue(settingDiff.get('setting'), settingDiff.get('designed'), useSelector(getDeviceConfigFields(settingDiff.get('device_id'))), temperatureUnit)}</div>)),
    },
    {
      id: 'current',
      Header: 'Current',
      accessor: (row: any) => row.diffArray,
      sortable: true,
      Cell: (row: any) => row.cell.value.map((settingDiff, index) => (<div key={index} className='alarm-list'>{formatDiscrepancyValue(settingDiff.get('setting'), settingDiff.get('current'), useSelector(getDeviceConfigFields(settingDiff.get('device_id'))), temperatureUnit)}</div>)),
    },
    {
      id: 'created_at',
      Header: 'First Discovered',
      accessor: (row: any) => row.device,
      Cell: (row: any) => (row.cell.value ? (<>{formatDate(row.cell.value.get('discrepancy_created_at'))}</>) : ''),
    },
    {
      id: 'acknowledged',
      Header: 'Status',
      accessor: (row: any) => row?.cell?.row?.original?.device?.get('discrepancy_acknowledged'),
      Cell: (row: any) => renderStatus(row?.cell?.row?.original?.device),
    },
    {
      Header: ' ',
      accessor: (row: any) => row.index,
      disableSortBy: true,
      Cell: (row: any) =>
        <div className='programming-actions'>
          <PermissionFence can={['edit-device']}>
            <Tooltip title="Update Designed Settings" placement="top">
              <div>
                <Button onClick={() => handleDesignedSettingsModalSubmit(row.cell.row.original.device_id)}><ModelTraining style={{ fontSize: 24 }} /></Button>
              </div>
            </Tooltip>
          </PermissionFence>
          <PermissionFence can={['program-device']}>
            <FeatureFence can={['program-device']} noPermissionComponent={buttonToUpdateDevice(row.cell.row.original.device_id, true)}>
              {buttonToUpdateDevice(row.cell.row.original.device_id, false)}

            </FeatureFence>

          </PermissionFence>
        </div>
    }
  ];

  const handleAcknowledgeDiscrepancy = (deviceId, discrepancyId) => {
    dispatch(acknowledgeProgrammingDiscrepancy(discrepancyId, deviceId));
  };

  useEffect(() => {
    dispatch(fetchEnumeratedDeviceSettings());
  }, []);
  const handleChange = (value, title) => {
    if ((value === null) && (title === null)) {
      return;
    } else if ((value === null || value === '')) {
      setValue(null);
      dispatch(setSelectedSetting(null));
      return;
    } else {

      const labelInput = value.label;
      setValue({ label: labelInput, value: labelInput });
      dispatch(setSelectedSetting(value));
    }
  };
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  const toggleForcedOnModal = (deviceId) => {
    setSelectedForcedOnDeviceId(deviceId);
    setForcedOnModalOpen(prevState => !prevState);
  };


  const makeHandleAddForcedOnCircuit = (deviceId) => {
    return (rec: any) => {
      dispatch(addForcedOnCircuit(rec.set('circuit_id', deviceId)));
      toggleForcedOnModal(null);
    };
  };

  return (
    <div className='page' id='programming-discrepancies' data-testid='programming-discrepancies-screen'>
      <Helmet>
        <title>{title} - Programming Audit</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper>
            <UnitSelector page='programmingDiscrepancies' />
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Paper>
        <h3>
          Programming Audits
          <div style={{ width: '250px', float: 'right', marginBottom: '30px' }}>

            <AutoCompleteSelect
              onChangeFn={handleChange}
              options={settings}
              id={`${selectedSetting?.toString() || 'none'}`}
              initialValues={value}
              placeholder="Search settings"
            />
          </div>
        </h3>
        {
          showRotateMessage ?
            <DeviceRotate /> :
            <DataTable
              pagination
              columns={columns}
              data={cleanedData}
              numPages={pageInfo.get('totalPages', 0)}
              totalRecords={pageInfo.get('total', 0)}
              loading={pageInfo.get('loading', false)}
              fetchPage={fetchPage}
              columnWidths={['auto', '40%', 'auto', 'auto', 'auto']}
              tableId='programmingDiscrepanciesTable'
            />
        }
      </Paper>
      <ConfirmModal
        title='Are you sure?'
        message={confirmMessage}
        open={confirmModalOpen}
        handleClose={() => toggleConfirmModal()}
        handleReject={() => toggleConfirmModal()}
        handleConfirm={() => confirmModalSubmit()}
      />
      <ForcedOnModal key={'forced-on-modal'} open={forcedOnModalOpen} circuitId={selectedForcedOnDeviceId} readOnlyCircuit={true}
        modalTitle={`Add Forced-on for Circuit ${'test'}`} handleClose={() => toggleForcedOnModal(null)} handleSubmit={makeHandleAddForcedOnCircuit(selectedForcedOnDeviceId)} />
    </div>
  );
}
