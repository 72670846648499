import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/prefetch/';

export const { prefetchAll, PREFETCH_ALL }: any = createTypedAction(
  `${PREFIX}PREFETCH_ALL`
);

export const { prefetchAllSuccess, PREFETCH_ALL_SUCCESS }: any = createTypedAction(
  `${PREFIX}PREFETCH_ALL_SUCCESS`
);

export const { prefetchAllFailure, PREFETCH_ALL_FAILURE }: any = createTypedAction(
  `${PREFIX}PREFETCH_ALL_FAILURE`,
  ['error', yup.object().nullable()]
);
