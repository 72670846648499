import './CriticalAlarms.scss';
import React from 'react';
import variables from 'src/style/variable/variables.module.scss';
import { useSelector } from 'react-redux';

import { getAlarmHistoricalSummary } from 'src/module/alarm/selector';
import CustomStatistics from 'src/component/UI/Statistics/CustomStatistics';

export default function CriticalAlarms () {

  const alarmHistory = useSelector(getAlarmHistoricalSummary) as any;
  let count, style;
  if (alarmHistory) {
    count = alarmHistory.getIn([alarmHistory.size - 1, 'new_critical_alarms'], 'N/A') - alarmHistory.getIn([alarmHistory.size - 2, 'new_critical_alarms'], 'N/A');
    if (count > 0) {
      count = '+'+count;
      style = { major: { stat: { color: variables.error } } };
    }
    else if (count < 0) {
      style = { major: { stat: { color: variables.primary } } };
    }
    else {
      style = { major: { stat: { color: variables.black } } };
    }
  }

  const variable = {
    criticalAlarms: alarmHistory.getIn([alarmHistory.size - 1, 'new_critical_alarms'], 'N/A'),
    comparedCriticalAlarms: alarmHistory.getIn([alarmHistory.size - 1, 'new_critical_alarms'], 'N/A') === 'N/A' ? 'N/A' : count
  };

  const defaultLayout = [
    { type: 'major', title: 'New Critical Alarms Today', helpText: 'Total number of critical alarms today', value: 'criticalAlarms', defaultValue: 0 },
    { type: 'major', title: 'Alarms Compared To Yesterday', helpText: 'More or less number of critical alarms as compared to yesterday', value: 'comparedCriticalAlarms', defaultValue: 0 },
  ];

  return (
    <div id="critical-alarms">
      <CustomStatistics
        variables={variable}
        defaultLayout={defaultLayout}
        styleOverrides={style}
      />
    </div>
  );
}
