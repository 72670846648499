import yup from '../utils/yup';
import humps from 'humps';

declare global {
  interface Window { __REDUX_ACTIONS_TYPED__: any; }
}

if (process.env.NODE_ENV !== 'production') {
  window.__REDUX_ACTIONS_TYPED__ = {};
}

export const createTypedAction = (actionType, ...actionArgs) => {
  const actionParts = actionType.match(/^(@@[a-zA-Z]+\/[a-zA-Z]+\/)(.+)/);

  const actionPrefix = actionParts[1];
  const actionName = actionParts[2].toUpperCase();

  let formatter;
  if (typeof actionArgs[0] === 'function') {
    formatter = actionArgs.shift();
  }

  if (process.env.NODE_ENV !== 'production') {
    window.__REDUX_ACTIONS_TYPED__[`${actionPrefix}${actionName}`] = yup.object().shape(
      actionArgs.reduce((schema, arg) => {
        schema[arg[0]] = arg[1];
        return schema;
      }, {})
    );
  }

  const actionDispatchName = humps.camelize(actionName.toLowerCase());

  const typedAction = {
    [actionName]: `${actionPrefix}${actionName}`,
    [actionDispatchName] (...args) {
      const action = { type: `${actionPrefix}${actionName}` };

      actionArgs.forEach((arg, index) => {
        action[actionArgs[index][0]] = args[index];
      });

      return action;
    }
  };

  if (formatter) {
    Object.defineProperty(typedAction, `${actionDispatchName}QueueFormatter`, {
      value: { action: actionType, formatter },
      configurable: false
    });
  }

  return typedAction;
};

export default createTypedAction;
