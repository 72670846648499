import './AppBar.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { getClient } from 'src/module/authentication/selector';
import ehtLogoColored from 'src/assets/EHTG_Logo_SmartTrace_FullColour_RGB.png';
import genericLogo from 'src/assets/generic-company-logo.svg';
import Tooltip from '@mui/material/Tooltip';
import SupportIcon from '@mui/icons-material/Support';
import { isWhitelabel } from 'src/utils/utils';

export default function AppBar () {
  const client = useSelector(getClient);

  const avatarUrl = client.get('avatar_url');
  const clientName = client.get('name');
  const clientAvatarAlt = `${clientName} Avatar Url`;
  const whitelabel = isWhitelabel();

  const img  =() => {
    if (avatarUrl) {
      return  <img src={avatarUrl} alt={clientAvatarAlt} />;
    }
    if (whitelabel) {
      return  <img src={genericLogo} alt={clientAvatarAlt} />;
    }
    return  <img src={ehtLogoColored} alt={clientAvatarAlt} />;
  };

  return (
    <div data-testid='app-bar' className='app-bar'>
      <div className='app-bar-container'>
        <div className='app-bar-main'>
          {img()}
          <span>{clientName}</span>
          {
            !whitelabel ? <div className='app-bar-right'>
              <a href='https://app.ehtgroup.com/knowledge' target='_blank' rel='noopener noreferrer' className='knowledge-base-link'>
                <Tooltip title='Knowledge Base'>
                  <SupportIcon className="knowledge-icon" />
                </Tooltip>
                <span className='knowledge-base-label'>Knowledge Base</span>
              </a>
            </div> : null
          }
        </div>
      </div>
    </div>
  );
}
