import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/browser';
import ErrorScreen from 'src/screen/Error/ErrorScreen';

export class ErrorBoundary extends Component {
  public state: any;

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ])
  };

  constructor (props) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidCatch (err, info) {
    this.setState({
      error: err
    });

    console.error(`ErrorBoundary caught an error! Error: ${err.message}`, { error: err, info }); // eslint-disable-line no-console

    if (process.env.REACT_APP_SENTRY_DSN) {
      try {
        Sentry.withScope(scope => {
          Object.keys(info).forEach(key =>
            scope.setExtra(key, info[key])
          );

          scope.setLevel(Sentry.Severity.Error);

          Sentry.captureException(err);
        });
      } catch (err) {
        console.error(`Unable to track error in Sentry. Error: ${err['message']}`, { error: err, ...info }); // eslint-disable-line no-console
      }
    }
  }

  render () {
    if (this.state.error !== null) {
      return <ErrorScreen />;
    }

    return this.props['children'];
  }
}

export default ErrorBoundary;
