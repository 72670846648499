import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ResponsiveToggleGroup from 'src/component/UI/ResponsiveToggleGroup';

import { getUnits } from 'src/module/filter/selector';

export default function UnitSelector (props) {
  const units = useSelector(getUnits);
  const { page } = props;

  return (
    <div data-testid='unit-selector'>
      <ResponsiveToggleGroup
        buttons={units}
        mainLabel='All'
        page={page} />
    </div>
  );
}

UnitSelector.propTypes = {
  page: PropTypes.string
};

UnitSelector.defaultProps = {
  page: ''
};
