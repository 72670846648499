import './ErrorScreen.scss';

import React from 'react';

export default function NotFoundScreen () {
  return (
    <div className='error-screen' data-testid='not-found-screen'>
      <div className='error-screen-text'>
        <h1>It looks like you&apos;re lost</h1>
        <p>The page you are looking does not exist <span role='img' aria-label='crying face emoji'>😢</span></p>
      </div>
    </div>
  );
}
