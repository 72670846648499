import { createSelector } from 'reselect';
import { List } from 'immutable';
export const getSearchState = (state) =>
  state.search;

export const getConfiguredSearchFields = createSelector(
  [getSearchState],
  (search) => {
    const records = search?.getIn(['search', 'configuredFields'], List());
    return { data: records };
  }
);

export const getAllSearchableFields = createSelector(
  [getSearchState],
  (search) => {
    return search.getIn(['search', 'allSearchableFields', 'fields'], List());
  }
);
