import { takeLatest, put, call } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import _ from 'lodash';

import toast from 'src/utils/toast';
import * as api from 'src/service/api';
import * as userActions from './action';

export function * userRoot () {
  yield takeLatest(userActions.FETCH_USERS, fetchUsers);
  yield takeLatest(userActions.INVITE_USER, inviteUser);
  yield takeLatest(userActions.REMOVE_USER, removeUser);
  yield takeLatest(userActions.UPDATE_USER, updateUser);
  yield takeLatest(userActions.UPDATE_USER_ROLE, updateUserRole);
  yield takeLatest(userActions.UPDATE_USER_NAV_BAR_PREF, updateUserNavBarPreference);

}

function * fetchUsers (action) {
  try {
    const users = yield call(api.getUsers, action);
    yield put(userActions.fetchUsersSuccess(users.get('data', List())));
  } catch (err) {
    yield put(userActions.fetchUsersFailed(Map(err)));
  }
}

function * inviteUser (action) {
  const name = action.firstName || action.email;

  try {
    const user = yield call(api.inviteUser, action, action.email, action.firstName, action.lastName);

    yield put(userActions.inviteUserSuccess(user));
  } catch (err) {
    const status = _.get(err, 'response.status');

    if (status === 409) {
      toast.warn(`${name} already exists in this facility`);
    } else {
      toast.error(`Error inviting ${name} to the facility`);
    }

    yield put(userActions.inviteUserFailed(Map(err), Map({ email: action.email, firstName: action.firstName, lastName: action.lastName })));
  }
}

function * removeUser (action) {
  try {
    yield call(api.removeUser, action, action.userId);

    yield put(userActions.removeUserSuccess(action.userId));
  } catch (err) {
    yield put(userActions.removeUserFailed(Map(err), action.userId));
  }
}

function * updateUserNavBarPreference (action) {
  yield put(userActions.updateUserNavBarPreference(action.preferences));
}

function * updateUser (action) {
  try {
    const userValues: any = { ...action.userObj };

    if (action.preferences) {
      userValues.preferences = action.preferences;
    }

    const user = yield call(api.updateOwnUser, action, userValues);
    yield put(userActions.updateUserSuccess(user));

  } catch (err: any) {
    if (!action.suppressToast) {
      toast.error(`Error updating user`);
    }
    yield put(userActions.updateUserFailed(Map(err), action.userId), err.response.status);
  }
}

function * updateUserRole (action) {
  try {
    yield call(api.updateUserRole, action, action.userId, action.role);
    yield put(userActions.updateUserRoleSuccess(action.userId, action.role));
  } catch (err: any) {
    toast.error(`Error updating user`, err.response.status);
    yield put(userActions.updateUserRoleFailed(Map(err), action.userId, action.roleId));
  }
}

export default userRoot;
