import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchIntegrityChecks, FETCH_INTEGRITY_CHECKS }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRITY_CHECKS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
);

export const { fetchIntegrityChecksSuccess, FETCH_INTEGRITY_CHECKS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRITY_CHECKS_SUCCESS`,
  ['integrityChecks', yup.immutable().map().required()]
);

export const { fetchIntegrityChecksFailed, FETCH_INTEGRITY_CHECKS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRITY_CHECKS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { sendReport, SEND_REPORT }: any = createTypedAction(
  `${PREFIX}SEND_REPORT`,
  ['message', yup.string().nullable()]
);

export const { sendReportSuccess, SEND_REPORT_SUCCESS }: any = createTypedAction(
  `${PREFIX}SEND_REPORT_SUCCESS`
);

export const { sendReportFailed, SEND_REPORT_FAILED }: any = createTypedAction(
  `${PREFIX}SEND_REPORT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { fetchReportSchedules, FETCH_REPORT_SCHEDULES }: any = createTypedAction(
  `${PREFIX}FETCH_REPORT_SCHEDULES`
);

export const { fetchReportSchedulesSuccess, FETCH_REPORT_SCHEDULES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_REPORT_SCHEDULES_SUCCESS`,
  ['reportSchedules', yup.immutable().list().required()]
);

export const { fetchReportSchedulesFailed, FETCH_REPORT_SCHEDULES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_REPORT_SCHEDULES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { addReportSchedule, ADD_REPORT_SCHEDULE }: any = createTypedAction(
  `${PREFIX}ADD_REPORT_SCHEDULE`,
  ['reportSchedule', yup.immutable().map().required()]
);

export const { addReportScheduleSuccess, ADD_REPORT_SCHEDULE_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_REPORT_SCHEDULE_SUCCESS`,
  ['reportSchedule', yup.immutable().map().required()]
);

export const { addReportScheduleFailed, ADD_REPORT_SCHEDULE_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_REPORT_SCHEDULE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { updateReportSchedule, UPDATE_REPORT_SCHEDULE }: any = createTypedAction(
  `${PREFIX}UPDATE_REPORT_SCHEDULE`,
  ['reportScheduleId', yup.string().required()],
  ['reportSchedule', yup.immutable().map().required()]
);

export const { updateReportScheduleSuccess, UPDATE_REPORT_SCHEDULE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_REPORT_SCHEDULE_SUCCESS`,
  ['reportSchedule', yup.immutable().map().required()]
);

export const { updateReportScheduleFailed, UPDATE_REPORT_SCHEDULE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_REPORT_SCHEDULE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { deleteReportSchedule, DELETE_REPORT_SCHEDULE }: any = createTypedAction(
  `${PREFIX}DELETE_REPORT_SCHEDULE`,
  ['reportScheduleId', yup.string().required()]
);

export const { deleteReportScheduleSuccess, DELETE_REPORT_SCHEDULE_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_REPORT_SCHEDULE_SUCCESS`,
  ['reportScheduleId', yup.string().required()]
);

export const { deleteReportScheduleFailed, DELETE_REPORT_SCHEDULE_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_REPORT_SCHEDULE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchMetrics, FETCH_METRICS }: any = createTypedAction(
  `${PREFIX}FETCH_METRICS`
);

export const { fetchMetricsSuccess, FETCH_METRICS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_METRICS_SUCCESS`,
  ['metrics', yup.immutable().map().required()]
);

export const { fetchMetricsFailed, FETCH_METRICS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_METRICS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
