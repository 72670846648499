import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as deviceGroupsApi from 'src/service/api/deviceGroups';
import * as navigationActions from 'src/module/navigation/action';
import * as filterActions from 'src/module/filter/action';
import * as deviceGroupsActions from './action';

import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * groupDevicesRoot () {
  yield takeLatest(deviceGroupsActions.FETCH_DEVICE_GROUPS, fetchDeviceGroups);
  yield takeLatest(deviceGroupsActions.DELETE_DEVICE_GROUP, deleteDeviceGroup);
  yield takeLatest(deviceGroupsActions.CREATE_DEVICE_GROUP, createDeviceGroup);
  yield takeLatest(deviceGroupsActions.FETCH_DEVICE_GROUP, fetchDeviceGroup);
  yield takeLatest(deviceGroupsActions.FETCH_DEVICE_GROUPS_LABELS, fetchDeviceGroupsLabels);
}

function * fetchDeviceGroups (action) {
  try {
    const deviceGroups = yield call(deviceGroupsApi.getDeviceGroups, action);
    yield put(deviceGroupsActions.fetchDeviceGroupsSuccess(deviceGroups.get('data'), deviceGroups.get('total'), deviceGroups.get('totalPages')));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device groups!'), err.response.status);
    yield put(deviceGroupsActions.fetchDeviceGroupsFailed(Map(err)));
  }
}
function * fetchDeviceGroupsLabels (action) {
  try {
    const deviceGroups = yield call(deviceGroupsApi.getDeviceGroupsLabels, action);
    yield put(deviceGroupsActions.fetchDeviceGroupsLabelsSuccess(deviceGroups));
    yield put(filterActions.fetchDeviceGroupsOptionsSuccess(deviceGroups.map(x => {return Map({ device_group: x.get('name'), id: x.get('id') });})));

  } catch (err: any) {
    yield put(deviceGroupsActions.fetchDeviceGroupsLabelsFailed(Map(err)));
  }
}
function * deleteDeviceGroup (action) {
  try {
    yield call(deviceGroupsApi.deleteDeviceGroupById, action);
    yield put(deviceGroupsActions.deleteDeviceGroupSuccess(action.groupId));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error deleting device group'), err.response);
    yield put(deviceGroupsActions.deleteDeviceGroupFailed(Map(err)));
  }
}
function * createDeviceGroup (action) {
  try {
    yield call(deviceGroupsApi.createDeviceGroup, action);
    yield put(deviceGroupsActions.createDeviceGroupSuccess(action.groupId));
    yield put(navigationActions.pushHistory("/devices/groups"));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error creating device group'), err.response);
    yield put(deviceGroupsActions.createDeviceGroupFailed(Map(err)));
  }
}


function * fetchDeviceGroup (action) {
  try {
    const group = yield call(deviceGroupsApi.getDeviceGroup, action);
    yield put(deviceGroupsActions.fetchDeviceGroupSuccess(group));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device group'), err.response);
    yield put(deviceGroupsActions.fetchDeviceGroupFailed(Map(err)));
  }
}


export default fetchDeviceGroups;
