import './ViewportLayoutPane.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ViewportLayoutPane = (props) => {
  const {
    style,
    position,
    className,
    children
  } = props;

  return (
    <section
      style={style}
      className={classNames(className, 'viewport-layout-pane', `viewport-layout-pane-${position}`)}
    >{children}</section>
  );
};

ViewportLayoutPane.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.oneOf(['top', 'left', 'bottom', 'right', 'middle']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
};

ViewportLayoutPane.defaultProps = {
  style: {}
};

ViewportLayoutPane.whyDidYouRender = true;

export default React.memo(ViewportLayoutPane);
