import { request } from '../api';

export const getIntegrations = (action) => {
  const payload: any = {
    url: `/integrations`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const getIntegrationFields = (action) => {
  const payload: any = {
    url: `/integrations/fields`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const addIntegration = (action) => {
  const {
    integrationType
  } = action;

  return request(action, {
    payload: {
      url: `/integrations`,
      options: {
        method: 'POST',
        data: { type: integrationType }
      },
    }
  });
};

export const updateIntegration = (action) => {
  const {
    integrationId,
    integration
  } = action;

  return request(action, {
    payload: {
      url: `/integrations/${integrationId}`,
      options: {
        method: 'PATCH',
        data: integration
      },
    }
  });
};

export const deleteIntegration = (action) => {
  const {
    integrationId
  } = action;

  return request(action, {
    payload: {
      url: `/integrations/${integrationId}`,
      options: {
        method: 'DELETE'
      },
    }
  });
};

export const refreshIntegration = (action) => {
  const {
    integrationId
  } = action;

  return request(action, {
    payload: {
      url: `/integrations/${integrationId}/restart`,
      options: {
        method: 'PUT'
      },
    }
  });
};
