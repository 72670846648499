import { List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as scheduleActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  batches: Map(),
  'device-groups': Map(),
  completed: Map(),
  pending: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('batches', Map())
      .set('device-groups', Map())
      .set('completed', Map())
      .set('pending', Map())
      .set('loading', false)


  );
}

export const batchScheduleReducer = createTypedReducer(initialState, {
  [scheduleActions.FETCH_BATCH_SCHEDULES] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['loading'], true)
    );
  },

  [scheduleActions.INITIATE_RUN_BATCH_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['batches', 'data', action.batchId, 'record', 'started_at'], new Date())
    );
  },

  [scheduleActions.FETCH_BATCH_SCHEDULES_SUCCESS] (state, action) {

    return state.withMutations((nextState) => {
      let newDataIds = List();
      const fetchDate = new Date();
      action.batchSchedules.get('data').map((schedule) => {
        newDataIds = newDataIds.push(schedule.get('id'));
        nextState.setIn(['batches', 'data', schedule.get('id')], {
          record: schedule.remove('recipe'),
          lastFetch: fetchDate,
        });
      });
      nextState.setIn([action.tab, 'records'], newDataIds);
      nextState.setIn([action.tab, 'total'], action.batchSchedules.get('total'));
      nextState.setIn([action.tab, 'totalPages'], action.batchSchedules.get('totalPages'));
      nextState.setIn(['loading'], false);
    }
    );
  },

  [scheduleActions.FETCH_BATCH_SCHEDULES_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['loading'], false)
    );
  },

  [scheduleActions.CREATE_BATCH] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['loading'], true)
    );
  },

  [scheduleActions.CREATE_BATCH_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const batchSchedulesList = state.getIn(['batches', 'records'], List()).push(action.batchSchedule.getIn(['batch', 'id']));
      nextState = nextState.setIn(['batches', 'data', action.batchSchedule.getIn(['batch', 'id']), 'record'], action.batchSchedule.get('batch'));
      nextState = nextState.setIn(['batches', 'data', action.batchSchedule.getIn(['batch', 'id']), 'record', 'devicesInError'], action.batchSchedule.get('deviceErrors'));
      return nextState.setIn(['loading'], false)
        .setIn(['batches', 'records'], batchSchedulesList);

    });
  },
  [scheduleActions.CREATE_BATCH_FAILED] (state) {
    return state.withMutations((nextState) => {
      return nextState.setIn(['loading'], false);
    });
  },
  [scheduleActions.FETCH_BATCH_STATUS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.batchStatus.get('deviceInError')) {
        const deviceIndex = nextState.getIn(['batches', 'data', action.batchId, 'record', 'devices']).indexOf(x => x.get('device_id') === action.batch.getIn(['device_id']));
        nextState.setIn(['batches', 'data', action.batchId, 'record', 'devices', deviceIndex, 'status'], action.batchStatus.getIn(['deviceInError', 'status']));
        const numErrors = nextState.getIn(['batches', 'data', action.batchId, 'record', 'devices']).toJS().filter(x => x.status?.hasErrors === true)?.length;
        nextState.setIn(['batches', 'data', action.batchId, 'record', 'numErrors'], numErrors);
      }
      if (action.batchStatus.getIn(['deviceInError', 'isSingleUpdate'])) {
        action.batchStatus.deleteIn(['deviceInError', 'isSingleUpdate']);

        nextState.mergeIn(['batches', 'data', action.batchId, 'record', 'status'], action.batchStatus.getIn(['status']));
        nextState.setIn(['batches', 'data', action.batchId, 'record', 'status', 'completed_number_devices'], nextState.getIn(['batches', 'data', action.batchId, 'record', 'status', 'completed_number_devices']) + 1);
        nextState.setIn(['batches', 'data', action.batchId, 'record', 'completed_at'], action.batchStatus.get('completed_at'));

      } else {
        if (!action.batchStatus.get('started')) {
          const status = action.batchStatus.remove('devicesInError');
          nextState.mergeIn(['batches', 'data', action.batchId, 'record'], status);
        } else {
          nextState.mergeIn(['batches', 'data', action.batchId, 'record'], action.batchStatus);
        }
      }
    });
  },

  [scheduleActions.ADD_BATCH_SCHEDULE_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['loading'], false)
    );
  },

  [scheduleActions.VALIDATE_BATCH_FAILED] (state) {
    return state.withMutations((nextState) => {
      return nextState;
    });
  },

  [scheduleActions.UPDATE_BATCH_SCHEDULE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const batchId = action.batchSchedule.getIn(['batch', 'id']);
      const batchSchedulesList = state.getIn(['batches', 'records'], List());
      if (!batchSchedulesList.includes(batchId)) {
        nextState = nextState.setIn(['batches', 'records'], batchSchedulesList);
      }
      return nextState
        .setIn(['batches', 'data', batchId, 'record'], action.batchSchedule);
    });
  },
  [scheduleActions.DELETE_SCHEDULED_DEVICE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const currDevices = nextState.getIn(['batches', 'data', action.batchId, 'record', 'devices']);
      const after = currDevices.filter(map => map.get('id') !== action.deviceId);
      nextState = nextState.setIn(['batches', 'data', action.batchId, 'record', 'devices'], after);

      return nextState;
    });
  },
  [scheduleActions.VALIDATE_BATCH_SUCCESS] (state) {
    return state.withMutations((nextState) => {

      return nextState;
    });
  },
  [scheduleActions.FETCH_BATCH_DEVICES_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState = nextState.setIn(['batches', 'data', action.batchId, 'record', 'devices'], action.devices.get('data'));
      nextState = nextState.setIn(['batches', 'data', action.batchId, 'record', 'numErrors'], action.devices.get('numErrors'));
      nextState = nextState.setIn(['batches', 'data', action.batchId, 'record', 'status', 'number_devices'], action.devices.get('total'));
      nextState.setIn(['loading'], false);

      return nextState;
    });
  },

  [scheduleActions.DELETE_DEVICE_GROUP_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState = nextState.deleteIn(['batches', 'data',  action.groupId]);
      const newIds = nextState.getIn(['device-groups',  'records'], []);
      nextState = nextState.setIn(['device-groups',  'records'], newIds.filter(x => x !== action.groupId));
      nextState.setIn(['loading'], false);

      return nextState;
    });
  },



  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default batchScheduleReducer;
