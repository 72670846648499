import { Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as permissionsActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  permissions: Map({
    data: Map()
  }),
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('permissions', Map({
      data: Map(),
    }))
  );
}

export const permissionReducer = createTypedReducer(initialState, {

  [permissionsActions.FETCH_PERMISSIONS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['permissions', 'loading'], true)
    );
  },

  [permissionsActions.FETCH_PERMISSIONS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['permissions', 'data'], action.permissions);
      nextState.setIn(['permissions', 'loading'], false);
    });
  },

  [permissionsActions.FETCH_PERMISSIONS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['permissions', 'loading'], false)
    );
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default permissionReducer;
