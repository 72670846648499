import { addSelectedFiltersToQuery } from 'src/utils/utils';
import { request } from '../api';

export const getForcedOnCircuits = (action) => {
  const {
    selectedFilters,
    paginationDetails,
    includeArchived,
    expiredOnly,
    deviceId
  } = action;
  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);

  const {
    filter,
    sort,
  } = paginationDetails;

  const page = (isNaN(action.paginationDetails.page) ) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails.pageSize)) ? null : action.paginationDetails.pageSize;

  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.search = filter;
  }

  if (deviceId) {
    query.deviceId = deviceId;
  }

  query.includeArchived = includeArchived;
  query.expiredOnly = expiredOnly;

  return request(action, {
    payload: {
      url: `/forced-on`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const addForcedOnCircuit = (action, forcedOnCircuit) => {
  return request(action, {
    payload: {
      url: `/forced-on`,
      options: {
        method: 'POST',
        data: forcedOnCircuit
      },
    }
  });
};

export const updateForcedOnCircuit = (action, forcedOnCircuitId, forcedOnCircuit) => {
  return request(action, {
    payload: {
      url: `/forced-on/${forcedOnCircuitId}`,
      options: {
        method: 'PATCH',
        data: forcedOnCircuit
      },
    }
  });
};

export const archiveForcedOnCircuit = (action, forcedOnCircuitId) => {
  return request(action, {
    payload: {
      url: `/forced-on/archive`,
      options: {
        method: 'POST',
        data: { forcedOnCircuitId }
      },
    }
  });
};
