import './ForcedOnScreen.scss';

import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ForcedOnCircuitsList from 'src/component/UI/ForcedOnCircuits/ForcedOnCircuitsList';
import ForcedOnModal from 'src/component/UI/ForcedOnCircuits/ForcedOnModal';
import ForcedOnIcon from '@mui/icons-material/OfflineBolt';
import Button from 'src/component/UI/Button';

import UnitSelector from 'src/component/UI/UnitSelector';
import { addForcedOnCircuit, updateForcedOnCircuit } from 'src/module/circuit/action';
import PermissionFence from 'src/component/PermissionFence';
import { isWhitelabel } from 'src/utils/utils';

export default function ForcedOnScreen () {
  const [forcedOnModalOpen, setForcedOnModalOpen] = useState(false);
  const selectedCircuitRef = useRef(null);
  const dispatch = useDispatch();

  const toggleForcedOnModal = () => {
    // clear selected circuit when closing modal
    if (forcedOnModalOpen) {
      selectedCircuitRef.current = null;
    }
    setForcedOnModalOpen(!forcedOnModalOpen);
  };

  const handleForcedOnSubmit = (rec: any) => {
    // updating existing forced on record
    if (selectedCircuitRef.current) {
      const forcedOnId = selectedCircuitRef.current.get('id');
      // if we're changing circuits we need to set the original circuit to normal
      dispatch(updateForcedOnCircuit(forcedOnId, rec));
    }
    else {
      dispatch(addForcedOnCircuit(rec));
    }
    toggleForcedOnModal();
  };

  const handleCircuitRowClick = (circuit: any) => {
    selectedCircuitRef.current = circuit;
    toggleForcedOnModal();
  };

  const makeForcedOnModal = (readonly: boolean) => {
    if (!forcedOnModalOpen) {
      return;
    }
    return (
      <ForcedOnModal
        forcedOnCircuit={selectedCircuitRef.current}
        open={forcedOnModalOpen}
        handleClose={toggleForcedOnModal}
        handleSubmit={handleForcedOnSubmit}
        modalTitle={`${selectedCircuitRef.current ? 'Edit' : 'Add'} Forced-on for Circuit`}
        readonly={readonly}
      />
    );
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='forced-on-screen'>
      <PermissionFence can={['edit-forced-on-circuit', 'edit-device']} noPermissionComponent={makeForcedOnModal(true)}>
        {makeForcedOnModal(false)}
      </PermissionFence>

      <Helmet>
        <title>{title} - Forced-on Circuits</title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper>
            <UnitSelector />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Paper>
            <ForcedOnCircuitsList
              showNotificationNumber
              showCircuitUnit
              showForcedOnControlMode
              showArchivedAt
              showDeleteOption
              toggleRefresh={forcedOnModalOpen} handleCircuitRowClick={handleCircuitRowClick} />

            <PermissionFence can='edit-forced-on-circuit'>
              <div className='button-bar'>
                <Button onClick={toggleForcedOnModal} icon={<ForcedOnIcon style={{ fontSize: 14 }} />} cta >Add Forced On Log</Button>
              </div>
            </PermissionFence>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
