import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { getActiveAlarms, getArchivedAlarms, getLoadingActiveAlarms, getLoadingArchivedAlarms, getNumActiveAlarmPages, getNumArchivedAlarmPages, getTotalActiveAlarms, getTotalArchivedAlarms } from 'src/module/device/selector';
import AlarmsList from 'src/component/AlarmsList';
import { acknowledgeAlarms } from 'src/module/alarm/action';
import { fetchDevicesActiveAlarms, fetchDevicesArchivedAlarms, dismissDeviceAlarms } from 'src/module/device/action';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';

export default function AlarmsContent (props) {
  const {
    title,
    deviceId,
    isExpanable,
    onlyArchived,
    onlyActive,
    refetchInterval
  } = props;
  const [alarmsCollapsed, setAlarmsCollapsed] = useState(!isExpanable);
  const [alarmFunctions, setAlarmFunctions] = useState({
    fetch: fetchDevicesActiveAlarms,
    alarmSelector: getActiveAlarms(deviceId),
    alarmLoadingSelector: getLoadingActiveAlarms(deviceId),
    numOfAlarmPagesSelector: getNumActiveAlarmPages(deviceId),
    numOfAlarmsSelector: getTotalActiveAlarms(deviceId),
    dismissAlarm: dismissDeviceAlarms
  });
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  useEffect(() => {
    if (onlyActive) {
      setAlarmFunctions({
        fetch: fetchDevicesActiveAlarms,
        alarmSelector: getActiveAlarms(deviceId),
        alarmLoadingSelector: getLoadingActiveAlarms(deviceId),
        numOfAlarmPagesSelector: getNumActiveAlarmPages(deviceId),
        numOfAlarmsSelector: getTotalActiveAlarms(deviceId),
        dismissAlarm: dismissDeviceAlarms
      });
    } else if (onlyArchived) {
      setAlarmFunctions({
        fetch: fetchDevicesArchivedAlarms,
        alarmSelector: getArchivedAlarms(deviceId),
        alarmLoadingSelector: getLoadingArchivedAlarms(deviceId),
        numOfAlarmPagesSelector: getNumArchivedAlarmPages(deviceId),
        numOfAlarmsSelector: getTotalArchivedAlarms(deviceId),
        dismissAlarm: null
      });
    }
  }, [onlyActive, onlyArchived, deviceId]);
  const makeTogglePastAlarmsGroup = () => {
    setAlarmsCollapsed(!alarmsCollapsed);
  };

  return (
    <Paper data-testid='alarms-content'>
      {isExpanable ?
        <h3 className='expandable' onClick={() => makeTogglePastAlarmsGroup()}>{title} &nbsp;<span className='expand-arrow'>{alarmsCollapsed ? '▲' : '▼'}</span></h3>
        :
        <h3>{title}</h3>
      }
      {alarmsCollapsed && !showRotateMessage ?
        <AlarmsList
          fetchAlarms={alarmFunctions.fetch}
          alarmSelector={alarmFunctions.alarmSelector}
          alarmLoadingSelector={alarmFunctions.alarmLoadingSelector}
          numOfAlarmPagesSelector={alarmFunctions.numOfAlarmPagesSelector}
          numOfAlarmsSelector={alarmFunctions.numOfAlarmsSelector}
          dismissAlarm={alarmFunctions.dismissAlarm}
          showSetting
          showValue
          showDescription
          showNotification
          showAlarm
          showNote
          showAlarmRaisedAt
          showAcknowledgeAlarmOption={onlyActive}
          acknowledgeAlarm={acknowledgeAlarms}
          showAlarmResolvedAt={onlyArchived}
          showClearAlarmOption={onlyActive}
          hideDeviceTag
          suppressHeader
          deviceId={deviceId}
          pagination
          supressSelectedFilters
          refetchInterval={refetchInterval}
          tableId={onlyActive ? 'currentAlarmsTable' : 'pastAlarmsTable'}
        />
        : ''}

      {alarmsCollapsed && showRotateMessage ?
        <DeviceRotate />
        : null}
    </Paper>
  );
}

AlarmsContent.propTypes = {
  title: PropTypes.string,
  deviceId: PropTypes.string,
  isExpanable: PropTypes.bool,
  onlyArchived: PropTypes.bool,
  onlyActive: PropTypes.bool,
  refetchInterval: PropTypes.number
};
AlarmsContent.defaultProps = {
  title: String,
  isExpanable: false,
  onlyArchived: false,
  onlyActive: false,
  refetchInterval: 0
};
