import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

export const getFacilityState = (state) =>
  state.facility;



export const getIntegrityChecks = createSelector(
  [getFacilityState],
  (facility) => {
    return facility.getIn(['integrityChecks', 'data'], Map());
  }
);

export const getGuide = createSelector(
  [getFacilityState],
  (facility) => {
    return facility.get('guide', Map());
  }
);

export const getMetrics = createSelector(
  [getFacilityState],
  (alarm) => {
    return alarm.getIn(['metrics', 'data']);
  }
);

export const getReportSchedules = createSelector(
  [getFacilityState],
  (rule) => {
    return rule.getIn(['reportSchedules', 'data']) || List();
  }
);

export const getLoadingReportSchedules = createSelector(
  [getFacilityState],
  (rule) => {
    return rule.getIn(['reportSchedules', 'loading']) || false;
  }
);
