import { List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as offlineAction from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import { v4 as uuid } from 'uuid';
import toast from 'src/utils/toast';
import { actionsFormatterMap } from 'src/utils/offlineHelperFunctions';

export const initialState = Map({
  actionQueue: List(),
  isOffline: false,
  isRunning: false,
  triggerQueue: false
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('actionQueue', List())
      .set('isOffline', false)
      .set('isRunning', false)
      .set('triggerQueue', false)
  );
}


export const offlineReducer = createTypedReducer(initialState, {
  [offlineAction.ADD_TO_QUEUE] (state, action) {
    if (!actionsFormatterMap[action.action.type]) {
      return state;
    }
    const updateList = state.getIn(['actionQueue'], List());
    toast.warn("Your change will be added to a queue you can run when you're back online");
    return state.withMutations((nextState) =>
      nextState.setIn(['actionQueue'],  updateList.push({ ...action.action, queueId: uuid(), performed_at: new Date() }))
    );
  },

  [offlineAction.UPDATE_IS_OFFLINE] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['isOffline'], action.isOffline)
    );
  },
  [offlineAction.REMOVE_FROM_QUEUE] (state, action) {
    const updateList = state.getIn(['actionQueue'], List());
    return state.withMutations((nextState) =>
      nextState.setIn(['actionQueue'], updateList.filter(x => x.queueId !== action.id ))
    );
  },
  [offlineAction.SET_RUNNING_QUEUE] (state, action) {

    return state.withMutations((nextState) =>
      nextState.setIn(['isRunning'], action.isRunning)
    );
  },
  [offlineAction.CLEAR_QUEUE] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['isRunning'], false)
        .setIn(['actionQueue'], List())
    );
  },
  [offlineAction.TRIGGER_QUEUE] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['triggerQueue'], action.trigger)
    );
  },




  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default offlineReducer;
