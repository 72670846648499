import './LoadingPlaceholder.scss';

import React from 'react';
import PropTypes from 'prop-types';

import LogoSpinner from 'src/component/UI/LogoSpinner';

export default function LoadingPlaceholder (props) {
  return (
    <div data-testid='loading-placeholder' className='loading-placeholder' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <LogoSpinner size={props.size} />
      <p>{props.loadingText}</p>
    </div>
  );
}


LoadingPlaceholder.propTypes = {
  size: PropTypes.number,
  loadingText: PropTypes.string
};

LoadingPlaceholder.defaultProps = {
  size: 180,
  loadingText: 'Loading…'
};
