import './IntegrationsModal.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import Button from 'src/component/UI/Button';

export default function IntegrationsModal (props) {
  const handleSubmit = (integrationType) => {
    return () => {
      props.handleSubmit(integrationType);
    };
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='integration-modal-title'
    >
      <div className='modal integration-modal'>
        <h2 id='integration-modal-title'>Choose your integration</h2>
        <div className='integration-modal-content'>
          <Button cta onClick={handleSubmit('weather')} >Local<br />Weather</Button>
          <Button cta onClick={handleSubmit('ambient_weather')} >Ambient RTD<br />Weather</Button>
          <Button cta onClick={handleSubmit('azure_ad')} >Azure</Button>
          <Button cta onClick={handleSubmit('pager_duty')} >Pager Duty</Button>
          <Button cta onClick={handleSubmit('raychem')} >nVent<br />Raychem</Button>
          <Button cta onClick={handleSubmit('tvne')} >Thermon<br />TVNE</Button>
          <Button cta onClick={handleSubmit('intellitrace')} >Chromalox<br />Intellitrace</Button>
          <Button cta onClick={handleSubmit('genesis')} >Thermon<br />Genesis</Button>
          <Button cta onClick={handleSubmit('sap')} >SAP</Button>
          <Button cta onClick={handleSubmit('oracle')} >Oracle</Button>
          <Button cta onClick={handleSubmit('maximo')} >Maximo</Button>
        </div>
        <div className='button-bar'>
          <Button onClick={props.handleClose} >Cancel</Button>
        </div>
      </div>

    </Modal>
  );
}

IntegrationsModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

IntegrationsModal.defaultProps = {
};
