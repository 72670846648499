const controllerDeleteMessage = (tag) => {
  return `Controller ${tag} will be deleted. All circuits under ${tag} will also be deleted.`;
};
const circuitDeleteMessage = (tag) => {
  return `Circuit ${tag} will be deleted.`;
};
const commLoopDeleteMessage = (tag) => {
  return `Devices attached to ${tag} will no longer be monitored.`;
};
// keys as lang look up in properties

export const deviceTypeConfig = {
  circuit: {
    title: 'Circuit',
    properties: {
      EHTInformation: {
        circuitTag: { selector: ['tag'] },
        controllerTag: { selector: ['parent_tag'], overrideElement: 'deviceLink' },
        model: { selector: ['model'], overrideElement: 'readOnly'  },
        description: { selector: ['description'] },
        heatTraceCircuitNumber: { selector: ['heat_trace_circuit_num'] },
        distributionPanel: { selector: ['distribution_panel'] },
        circuitBreaker: { selector: ['circuit_breaker'] },
        RTDTag: { selector: ['rtd_tag'] }
      },
      designedSettings: {
        deviceOperatingStatus: {
          selector: ['designed_values', 'deviceOperatingStatus'],
          overrideElement: 'selector',
          options: 'designedControlModes'
        },
        rtdConfig: {
          selector: ['designed_values', 'rtdConfig'],
          overrideElement: 'selector',
          options: 'designedRtdConfigOptions'
        },
        maintainTemperature: {
          selector: ['designed_values', 'controlTemperatureSetpoint'],
          overrideElement: 'editTemperatureValueSelector'
        },
        lowTemperatureAlarm: {
          selector: ['designed_values', 'lowControlTemperatureAlarmSetpoint'],
          overrideElement: 'editTemperatureValueSelector'
        },
        highTemperatureAlarm: {
          selector: ['designed_values', 'highControlTemperatureAlarmSetpoint'],
          overrideElement: 'editTemperatureValueSelector'
        },
        lowLoadAlarm: { selector: ['designed_values', 'lowLoadCurrentAlarmSetpoint'], overrideElement: 'numericSelector' },
        highLoadAlarm: { selector: ['designed_values', 'highLoadCurrentAlarmSetpoint'], overrideElement: 'numericSelector' },
        groundFaultTripAlarmMask: { selector: ['designed_values', 'groundFaultTripAlarmMask'], overrideElement: 'booleanSelector' },
        gfTrip: { selector: ['designed_values', 'groundFaultTripCurrentSetpoint'], overrideElement: 'numericSelector' },
      },
      additionalData: {
        unit: { selector: ['unit'] },
        system: { selector: ['system'] },
        lineDescription: { selector: ['line_description'] },
        lineSize: { selector: ['line_size'] },
        lineNumber: { selector: ['line'] },
        commodity: { selector: ['commodity'] },
        pID: { selector: ['pnid'] },
        heaterType: { selector: ['heater_type'] },
        deadLeg: { selector: ['dead_legs'], overrideElement: 'booleanSelector' },
        priority: { selector: ['priority'] },
        voltage: { selector: ['voltage'] },
        location: { selector: ['location'] },
        locationUrl: { selector: ['latitude', 'longitude'], overrideElement: 'googleLink' },
        offline: { selector: ['offline'], overrideElement: 'offlineSelector' },
      },
      properties: {
        ipAddress: { selector: ['ip_address'], overrideElement: 'readOnly' },
        port: { selector: ['port'], overrideElement: 'readOnly' },
        htcAddress: { selector: ['device_address'], overrideElement: 'formatHtcAddress' },
        modbusAddress: { selector: ['address'] },
        subaddressCircuitNumber: { selector: ['subaddress'] },
        commLoop: {
          selector: ['comm_loop_id'],
          overrideElement: 'selector',
          options: 'commLoops'
        },
        firmwareVersion: { selector: ['firmware_version'], overrideElement: 'readOnly' }
      }
    },
    currentAlarms: true,
    gauges: true,
    showPrimaryGaugesByDefault: true,
    statistics: true,
    sensorGraph: true,
    currentNotifications: true,
    currentNotes: true,
    pastAlarms: true,
    pastNotifications: true,
    pastNotes: true,
    auditTrail: true,
    forcedOnLogs: true,
    fetchDeviceConfigFields: true,
    fetchDeviceActions: true,
    fetchDeviceStatus: true,
    activelyMonitored: true,
    testingReports: true,
    spliceReports: true,
    circuitFunctionTestReports: true,
    insulationInspectionTestReports: true,
    actionButtons: {
      createForcedOnLogs: true,
      advancedSettings: true,
      createNote: true,
      customActionButton: true,
      fileUpload: true,
      deleteDevice: {
        getMessage: circuitDeleteMessage,
        onSubmit: 'deleteCircuit',
        label: 'Delete Circuit'
      }
    }

  },
  controller: {
    title: 'Controller',
    properties: {
      EHTInformation: {
        controllerTag: { selector: ['tag'] },
        comm_Loop: { selector: ['parent_tag'], overrideElement: 'deviceLink' },
        Model: { selector: ['device_factory'], overrideElement: 'readOnly' },
      },
      additionalData: {
        unit: { selector: ['unit'] },
        location: { selector: ['location'] },
        locationUrl: { selector: ['latitude', 'longitude'], overrideElement: 'googleLink' },
        offline: { selector: ['offline'], overrideElement: 'offlineSelector' },
      },
      properties: {
        ipAddress: { selector: ['ip_address'],  overrideElement: 'readOnly' },
        port: { selector: ['port'],  overrideElement: 'readOnly' },
        modbusAddress: { selector: ['address'] },
        commLoop: {
          selector: ['comm_loop_id'],
          overrideElement: 'selector',
          options: 'commLoops'
        },
        firmwareVersion: { selector: ['firmware_version'], overrideElement: 'readOnly' },
      }
    },
    children: {
      title: 'Circuits',
      columnTagLabel: 'Circuit Tag',
      deleteMessage: 'circuit',
    },
    gauges: true,
    currentAlarms: true,
    currentNotifications: true,
    currentNotes: true,
    pastAlarms: true,
    pastNotifications: true,
    pastNotes: true,
    auditTrail: true,
    fetchDeviceStatus: true,
    fetchDeviceConfigFields: true,
    fetchDeviceActions: true,
    actionButtons: {
      advancedSettings: true,
      createNote: true,
      customActionButton: true,
      addCircuit: true,
      fileUpload: true,
      deleteDevice: {
        getMessage: controllerDeleteMessage,
        onSubmit: 'deleteController',
        label: 'Delete Controller'
      }
    }
  },
  commLoop: {
    title: 'Comm Loop',
    properties: {
      EHTInformation: { commLoopTag: { selector: ['tag'] } },
      properties: {
        ipAddress: { selector: ['ip_address'] },
        port: { selector: ['port'] },
        assignedSupervisorClient: {
          selector: ['frog_client_id'],
          overrideElement: 'selector',
          options: 'frogClients'
        }
      }
    },
    children: {
      title: 'Devices',
      columnTagLabel: 'Device Tag',
      deleteMessage: 'device',
      selector: []
    },
    currentAlarms: true,
    currentNotifications: true,
    currentNotes: true,
    pastAlarms: true,
    pastNotifications: true,
    pastNotes: true,
    actionButtons: {
      createNote: true,
      fileUpload: true,
      deleteDevice: {
        getMessage: commLoopDeleteMessage,
        onSubmit: 'deleteCommLoop',
        label: 'Delete Comm Loop'
      }
    }
  }
};
