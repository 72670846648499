import './ReportScreen.scss';

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Helmet } from 'react-helmet';
import DataTable from 'src/component/UI/DataTable';
import { Map, List } from 'immutable';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SendReportIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

import Button from 'src/component/UI/Button';
import EditableCell from 'src/component/UI/EditableCell';

import { fetchAlarmStats } from 'src/module/alarm/action';
import { sendReport } from 'src/module/facility/action';

import { fetchNewWorkTickets, updateWorkTicket } from 'src/module/workTicket/action';

import { getNewWorkTickets } from 'src/module/workTicket/selector';
import { fetchClient } from 'src/module/authentication/action';
import ForcedOnCircuitsList from 'src/component/UI/ForcedOnCircuits/ForcedOnCircuitsList';
import DashboardStatistics from 'src/component/UI/Statistics/DashboardStatistics';
import { getAlarmStats } from 'src/module/alarm/selector';
import { getClient, getCriticalityLabels } from 'src/module/authentication/selector';
import { getPluralWorkTicketLabel, getSingularWorkTicketLabel } from 'src/module/authentication/selector';
import DeviceLinkCombo from 'src/component/UI/DeviceLinkCombo';
import FeatureFence from 'src/component/FeatureFence';
import { isWhitelabel } from 'src/utils/utils';


export default function ReportScreen () {
  const dispatch = useDispatch();

  const [reportSent, setReportSent] = useState(false);
  const [message, setMessage] = useState('');

  const newWorkTickets = useSelector(getNewWorkTickets) as any;
  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);


  const alarmStats = useSelector(getAlarmStats);
  const criticalityLabels = useSelector(getCriticalityLabels);
  const client = useSelector(getClient);
  const lastReportSentAt = client.get('last_report_sent_at');

  useEffect(() => {
    dispatch(fetchClient());
    dispatch(fetchAlarmStats(Map(), Map()));
    dispatch(fetchNewWorkTickets(lastReportSentAt));
  }, [lastReportSentAt]);

  const handleUpdateRow = (field: string, sapNotificationId: string) => {
    return (val) => {
      const rec: any = {};
      rec[field] = val;
      dispatch(updateWorkTicket(sapNotificationId, Map(rec), null));

    };
  };

  const handleSendReport = () => {
    if (!reportSent) {
      setReportSent(true);

      dispatch(sendReport(message));
    }
  };

  const criticalAlarmsWithoutNotificationArr = [];

  alarmStats?.get('devicesInAlarmWithoutNotificationByCriticality', []).forEach((value,  key) => {
    if (key > 0) {
      criticalAlarmsWithoutNotificationArr.push({
        type: 'minor',
        title: criticalityLabels?.get(key),
        helpText: `Number of priority '${criticalityLabels?.get(key)}' devices in alarm without ${pluralWorkTicketLabel}`,
        value: `alarmStats.devicesInAlarmWithoutNotificationByCriticality.${key}`,
        defaultValue: 'N/A'
      });
    }
  });

  const notificationData = newWorkTickets.reduce((acc, notification) => {
    acc = acc.push(Map({
      sapNotificationNum: notification.get('sap_notification_id'),
      device_tag: notification.get('device_tag'),
      parent_tag: notification.get('parent_tag'),
      device_type: notification.get('device_type'),
      parent_type: notification.get('parent_type'),
      note: notification.get('note') || '',
      description: notification.get('description') || '',
      revision: notification.get('revision') || '',
      sapNotificationId: notification.get('id') || 'N/A'
    }));
    return acc;
  }, List());

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='report'>
      <Helmet>
        <title>{title} - Send Report</title>
      </Helmet>

      <h3>Send Report</h3>


      <DashboardStatistics layoutPreferencePath='client.preferences.report.layout' defaultLayoutOverride={[
        { type: 'grid', container: true, spacing: 3, children: [
          { type: 'grid', container: false, xs: 12, md: 6, children: [
            { type: 'block', title: 'Report Information', children: [
              { type: 'minor', title: 'Date', value: 'date' },
              { type: 'minor', title: 'Last Report Date', value: 'lastReportSentAt' },
              { type: 'minor', title: 'Week', value: 'weekNumber' },
              { type: 'minor', feature: ['htc-stats'], title: 'Temperature', value: 'currentSiteTemperature', defaultValue: 'N/A' },
            ] }
          ] },
          { type: 'grid', container: false, xs: 12, md: 6, children: [
            { type: 'block', title: 'Alarm Overview', children: [
              { type: 'minor', title: 'Total Alarms', helpText: 'Total active alarms in the system', value: 'alarmStats.totalAlarms', defaultValue: 'N/A' },
              { type: 'minor', feature: ['htc-stats'], title: 'HTCs in Crit. Alarm', helpText: 'Total Heat Trace Controllers in critical alarm', value: 'alarmStats.criticalAlarms', defaultValue: 'N/A' },
              { type: 'minor', title: '%mnraLabel%', helpText: 'Total open SAP tickets requiring engineering', value: 'alarmStats.outstandingNotificationsByStatus.mnra', defaultValue: 'N/A' },
              { type: 'minor', title: '%workOrderLabel%', helpText: `Total open ${pluralWorkTicketLabel} for an approved minor repair`, value: 'alarmStats.outstandingNotificationsByStatus.wo', defaultValue: 'N/A' }
            ] }
          ] },
          { type: 'grid', container: false, xs: 12, md: 6, children: [
            { type: 'block', title: 'System Overview', children: [
              { type: 'minor', feature: ['htc-stats'], title: 'Total Forced On', value: 'alarmStats.forcedOnStats.totalForcedOnCircuits', defaultValue: 'N/A' },
              { type: 'minor', title: 'Total LOTO', helpText: 'Total number of alarms resulting from device being Locked Out / Tagged Out', value: 'circuitsLoto', defaultValue: 'N/A' },
              { type: 'minor', title: 'Total Nuisance', helpText: 'Total number of alarms determined to be a nuisance alarm', value: 'alarmStats.notesByHtc.nuisance', defaultValue: 'N/A' },

            ] }
          ] },
          { type: 'grid', container: false, xs: 12, md: 6, children: [
            { type: 'block', title: `Devices in Alarm without ${pluralWorkTicketLabel}`, children: [
              ...criticalAlarmsWithoutNotificationArr
            ] }
          ] },
        ] }
      ]} />

      <Grid container spacing={2} >
        <Grid item xs={12} md={12} sx={{ marginTop: '16px' }}>
          <Paper>
            <h3>Message</h3>
            <TextField
              name='message'
              helperText='Optionally enter a message to be included in the report'
              variant='outlined'
              fullWidth
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper>
            <h2>{`New ${pluralWorkTicketLabel} Created`}</h2>
            <DataTable
              columns={[
                {
                  Header: `${singularWorkTicketLabel} No.`,
                  accessor: (row: any) => row.get('sapNotificationNum'),
                },
                {
                  Header: 'Tag',
                  accessor: (row: any) => row.get('device_tag'),
                  Cell: (row: any) => { return (<DeviceLinkCombo device={row.cell.row.original} /> );},
                },
                {
                  Header: 'Description',
                  accessor: (row: any) => row.get('description'),
                  Cell: (row: any) => { return (<EditableCell className='description-cell' onChange={handleUpdateRow('description', row.cell.row.original.get('sapNotificationId'))} value={row.cell.value} emptyCellLabel='Add Description' />); },
                },
                {
                  Header: 'Revision',
                  accessor: (row: any) => row.get('revision'),
                  Cell: (row: any) => { return (<EditableCell className='revision-cell' onChange={handleUpdateRow('revision', row.cell.row.original.get('sapNotificationId'))} value={row.cell.value} emptyCellLabel='Add Revision' />); },
                },
                {
                  Header: 'Notes',
                  accessor: (row: any) => row.get('notes'),
                  Cell: (row: any) => { return (<EditableCell className='note-cell' onChange={handleUpdateRow('note', row.cell.row.original.get('sapNotificationId'))} value={row.cell.value} emptyCellLabel='Add Note' />); },
                }
              ]}
              data={notificationData}
              tableId='reportScreenTable'
            />
          </Paper>
        </Grid>

        <FeatureFence can={['htc-stats']}>
          <Grid item xs={12} md={12}>
            <Paper>
              <ForcedOnCircuitsList
                title='Forced-on Circuits Past Due'
                filterable={false}
                pagination={false}
                showArchivedOption={false}
                supressSelectedFilters
                expiredOnly />
            </Paper>
          </Grid>
        </FeatureFence>
      </Grid>

      <Grid item xs={12} md={12}>
        <div className='button-bar'>
          <Button disabled={reportSent} cta onClick={handleSendReport} icon={<SendReportIcon style={{ fontSize: 14 }} />} >Send Report</Button>
        </div>
      </Grid>

    </div>
  );
}
