import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchAlarmStats, FETCH_ALARM_STATS }: any = createTypedAction(
  `${PREFIX}FETCH_ALARM_STATS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['optionalFilters', yup.immutable().map()]
);

export const { fetchAlarmStatsSuccess, FETCH_ALARM_STATS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ALARM_STATS_SUCCESS`,
  ['alarmStats', yup.immutable().map().required()]
);



export const { clearAlarmStats, CLEAR_ALARM_STATS }: any = createTypedAction(
  `${PREFIX}CLEAR_ALARM_STATS`,
);

export const { fetchAlarmStatsFailed, FETCH_ALARM_STATS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_ALARM_STATS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchAlarms, FETCH_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_ALARMS`,
  ['showActive', yup.boolean()],
  ['selectedFilters', yup.object().nullable()],
  ['page', yup.number()],
  ['pageSize', yup.number()],
  ['filter', yup.string().nullable()],
  ['sort', yup.string().nullable()]
);

export const { fetchActiveAlarms, FETCH_ACTIVE_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_ACTIVE_ALARMS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['optionalFilters', yup.immutable().map()], // filter by component or user
  ['page', yup.number()],
  ['pageSize', yup.number()],
);

export const { fetchArchivedAlarms, FETCH_ARCHIVED_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_ARCHIVED_ALARMS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['optionalFilters', yup.immutable().map()], // filter by component or user
  ['page', yup.number()],
  ['pageSize', yup.number()],
);

export const { fetchAlarmsSuccess, FETCH_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ALARMS_SUCCESS`,
  ['alarms', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()],
);
export const { fetchActiveAlarmsSuccess, FETCH_ACTIVE_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ACTIVE_ALARMS_SUCCESS`,
  ['alarms', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()],
);
export const { fetchArchivedAlarmsSuccess, FETCH_ARCHIVED_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ARCHIVED_ALARMS_SUCCESS`,
  ['alarms', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()],
);

export const { fetchAlarmsFailed, FETCH_ALARMS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_ALARMS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { fetchDevicesInAlarmWithoutNotification, FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar,
  ['optionalFilters', yup.immutable().map()]
);

export const { fetchDevicesInAlarmWithoutNotificationSuccess, FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION_SUCCESS`,
  ['devicesInAlarmWithoutNotification', yup.immutable().list().required()],
  ['criticalAlarmsWithoutNotification', yup.number().required()]
);

export const { fetchDevicesInAlarmWithoutNotificationFailed, FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { validateAlarms, VALIDATE_ALARMS }: any = createTypedAction(
  `${PREFIX}VALIDATE_ALARMS`,
  ['notificationNumber', yup.string()],
  ['alarmIds', yup.array().of(yup.string())],
  ['notificationDescription', yup.string().nullable()],
  ['rowId', yup.number()],
  ['selectedFilters', yup.object().nullable()],
  ['deviceId', yup.string()],
);

export const { validateAlarmsSuccess, VALIDATE_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}VALIDATE_ALARMS_SUCCESS`
);

export const { validateAlarmsFailed, VALIDATE_ALARMS_FAILED }: any = createTypedAction(
  `${PREFIX}VALIDATE_ALARMS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { dismissAlarms, DISMISS_ALARMS, dismissAlarmsQueueFormatter }: any = createTypedAction(
  `${PREFIX}DISMISS_ALARMS`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.childId, 'record', 'info', 'tag']);
    const parentTag = devices?.getIn([state.parentId, 'record', 'info', 'tag']);
    return `alarms dismissed on device ${parentTag || ''}${ parentTag ? '/' : ''}${deviceTag || ''}`;
  },
  ['note', yup.string()],
  ['parentId', yup.string().nullable()],
  ['childId', yup.string().nullable()],
  ['alarmIds', yup.array().of(yup.string())],
  ['expiredAt', yup.string().nullable()],
  ['subject', yup.string().nullable()],
  ['alarmListFilters', yup.object().nullable()],
  ['pageInfo', yup.object().nullable()],
  ['rowId', yup.number()],
  ['deviceId', yup.string().nullable()]
);

export const { acknowledgeAlarms, ACKNOWLEDGE_ALARMS, acknowledgeAlarmsQueueFormatter }: any = createTypedAction(
  `${PREFIX}ACKNOWLEDGE_ALARMS`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.childId, 'record', 'info', 'tag']);
    return `alarms dismissed on device ${deviceTag || ''}`;
  },
  ['parentId', yup.string().nullable()],
  ['childId', yup.string().nullable()],
  ['alarmIds', yup.array().of(yup.string())],
  ['alarmListFilters', yup.object().nullable()],
  ['pageInfo', yup.object().nullable()]
);
export const { undoAlarms, UNDO_ALARMS }: any = createTypedAction(
  `${PREFIX}UNDO_ALARMS`,
  ['controllerId', yup.string().nullable()],
  ['circuitId', yup.string().nullable()],
  ['alarmIds', yup.array().of(yup.string())],
  ['rowId', yup.number()]
);

export const { fetchAlarmHistoricalSummary, FETCH_ALARM_HISTORICAL_SUMMARY }: any = createTypedAction(
  `${PREFIX}FETCH_ALARM_HISTORICAL_SUMMARY`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['start', yup.string().required()],
  ['end', yup.string()]
);

export const { fetchAlarmHistoricalSummarySuccess, FETCH_ALARM_HISTORICAL_SUMMARY_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ALARM_HISTORICAL_SUMMARY_SUCCESS`,
  ['alarms', yup.immutable().list().required()]
);

export const { fetchAlarmHistoricalSummaryFailed, FETCH_ALARM_HISTORICAL_SUMMARY_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_ALARM_HISTORICAL_SUMMARY_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { dismissAlarmsSuccess, DISMISS_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}DISMISS_ALARMS_SUCCESS`,
  ['alarmIds', yup.array().of(yup.string())]
);

export const { dismissAlarmsFailed, DISMISS_ALARMS_FAILED }: any = createTypedAction(
  `${PREFIX}DISMISS_ALARMS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { invalidateDeviceInCriticalAlarm, INVALIDATE_DEVICE_IN_CRITICAL_ALARM }: any = createTypedAction(
  `${PREFIX}INVALIDATE_DEVICE_IN_CRITICAL_ALARM`,
  ['rowId', yup.number()]
);
export const { validateDeviceInCriticalAlarm, VALIDATE_DEVICE_IN_CRITICAL_ALARM }: any = createTypedAction(
  `${PREFIX}VALIDATE_DEVICE_IN_CRITICAL_ALARM`,
  ['rowId', yup.number()]
);

export const { loadAlarmsFromDevice, LOAD_ALARMS_FROM_DEVICE }: any = createTypedAction(
  `${PREFIX}LOAD_ALARMS_FROM_DEVICE`,
  ['alarms', yup.immutable().list().required()]
);

export const { fetchAlarmsExport, FETCH_ALARMS_EXPORT }: any = createTypedAction(
  `${PREFIX}FETCH_ALARMS_EXPORT`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['panelId', yup.string().nullable()]
);

export const { fetchAlarmsExportSuccess, FETCH_ALARMS_EXPORT_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ALARMS_EXPORT_SUCCESS`,
  ['exportAlarms', yup.immutable().map().required()],
  ['facilityName', yup.string().required()]
);

export const { fetchAlarmsExportFailed, FETCH_ALARMS_EXPORT_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_ALARMS_EXPORT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { dismissAlarmsByGroup, DISMISS_ALARMS_BY_GROUP, dismissAlarmsByGroupQueueFormatter }: any = createTypedAction(
  `${PREFIX}DISMISS_ALARMS_BY_GROUP`,
  (state) => {
    return `dismiss alarm with criticality of ${state?.deviceCriticality} and alarm group of ${state?.alarmGroup}`;
  },
  ['alarmGroup', yup.string().required()],
  ['deviceCriticality', yup.number().required()],
  ['selectedFilters', yup.object().nullable()],
);

export const { dismissAlarmsByGroupSuccess, DISMISS_ALARMS_BY_GROUP_SUCCESS }: any = createTypedAction(
  `${PREFIX}DISMISS_ALARMS_BY_GROUP_SUCCESS`,
);

export const { dismissAlarmsByGroupFailed, DISMISS_ALARMS_BY_GROUP_FAILED }: any = createTypedAction(
  `${PREFIX}DISMISS_ALARMS_BY_GROUP_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
