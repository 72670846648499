import './LaunchScreen.scss';

import React from 'react';

import LogoSpinner from 'src/component/UI/LogoSpinner';

export default function LaunchScreen () {
  return (
    <div id='launch-screen' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <LogoSpinner size={180} />
      <p>Loading…</p>
    </div>
  );
}
