import { request } from '../api';

export const getBatchSchedules = (action) => {
  const {
    paginationDetails,
    tab
  } = action;
  const {
    filter,
    sort,
  } = paginationDetails;

  const page = (isNaN(action.paginationDetails.page)) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails.pageSize)) ? null : action.paginationDetails.pageSize;

  const query: any = {};
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.filter = filter;
  }
  if (tab === 'completed') {
    query.showCompleted = true;
  } else if (tab === 'pending') {
    query.showPending = true;
  }
  const payload: any = {
    url: `/batch-schedules`,
    query,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const getBatch = (action) => {
  const { batchId } =  action;
  return request(action, {
    payload: {
      url: `/batch-schedules/${batchId}`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const initiateRunBatch = (action) => {
  const { batchId } = action;
  return request(action, {
    payload: {
      url: `/batch-schedules/run/${batchId}`,
      options: {
        method: 'PUT',
      },
    }
  });
};

export const updateBatchSchedule = (action) => {
  const { batchId } = action;
  return request(action, {
    payload: {
      url: `/batch-schedules/${batchId}`,
      options: {
        method: 'PATCH',
        data: action.batchSchedule
      },
    }
  });
};

export const validateBatch = (action) => {
  const { batchId } = action;
  return request(action, {
    payload: {
      url: `/batch-schedules/validate/`,
      options: {
        method: 'POST',
        data: {
          batchId,
        }
      },
    }
  });
};

export const createBatch = (action, filters) => {
  const { devices, recipeId } = action;
  return request(action, {
    payload: {
      url: `/batch-schedules`,
      options: {
        method: 'POST',
        data: {
          devices,
          recipeId,
          filters
        }
      },
    }
  });
};

export const deleteScheduledDevice = (action) => {
  const { batchId, deviceId } = action;
  return request(action, {
    payload: {
      url: `/batch-schedules/${batchId}/devices/${deviceId}`,
      options: {
        method: 'DELETE'
      },
    }
  });
};

export const getBatchStatus = (action) => {

  return request(action, {
    payload: {
      url: `/batch-status`,
      query: { id: action.batchId },
      options: {
        method: 'GET'
      }
    }
  });
};

export const createBatchDeviceGroup = (action) => {

  const { deviceIds, name } = action;
  return request(action, {
    payload: {
      url: `/batch-schedules/device-group`,
      options: {
        method: 'POST',
        data: {
          deviceIds,
          name
        }
      },
    }
  });
};

export const fetchDeviceGroupById = (action) => {
  return request(action, {
    payload: {
      url: `/batch-schedules/device-group/${action.groupId}`,
      options: {
        method: 'get',
      },
    }
  });
};

export const deleteDeviceGroupById = (action) => {
  return request(action, {
    payload: {
      url: `/batch-schedules/${action.groupId}`,
      options: {
        method: 'delete',
      },
    }
  });
};
export const getBatchDevicesPaginated = (action) => {
  const page = (isNaN(action.paginationDetails.page)) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails.pageSize)) ? null : action.paginationDetails.pageSize;
  const query: any = {};
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }
  if (action.filter ==='errors') {
    query.filter = 'onlyErrors';
  }
  if (action.filter ==='success') {
    query.filter = 'onlySuccess';
  }
  return request(action, {
    payload: {
      url: `/batch-schedules/devices/${action.batchId}/paginated`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};
