import './PermissionsScreen.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import variables from 'src/style/variable/variables.module.scss';
import { langLookUpText } from 'src/utils/langLookUp';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import DashIcon from '@mui/icons-material/HorizontalRuleOutlined';

import PermissionFence from 'src/component/PermissionFence';
import { fetchPermissions } from 'src/module/permission/action';
import { getPermissions } from 'src/module/permission/selector';
import { List } from 'immutable';

export default function PermissionsScreen () {
  const dispatch = useDispatch();
  const permissions = useSelector(getPermissions);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  const allRoles = [], allGroups = [];

  let formattedPermissions = permissions?.filter(obj => obj.get('functionality_group') !== null) || List();

  // format permissions by most granted at the top of each functionality group
  formattedPermissions = formattedPermissions?.sort((a, b) => {
    if (a.get('functionality_group') < b.get('functionality_group')) return -1;
    if (a.get('functionality_group') > b.get('functionality_group')) return 1;

    return b.get('role').size - a.get('role').size;
  });

  // extract all roles and groups from formatted permissons
  formattedPermissions?.map((perm) => {
    const roles = perm.get('role');
    const group = perm.get('functionality_group');
    !allGroups.includes(group) && group ? allGroups.push(group) : null;
    roles?.map(role => {
      if (!allRoles.includes(role)) {
        allRoles.push(role);
      }
    });
  });

  return (
    <div className='page' id='permissions-screen'>
      <Helmet>
        <title>Permissions</title>
      </Helmet>

      <PermissionFence can='read-acl'>
        <h2>Permissions</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className='table-row' key='table-row'>
                <TableCell className='table-header'></TableCell>
                {allRoles?.map(role => {
                  // User roles at the top row
                  return <TableCell key={role} className='table-header' align="center">
                    {role}
                  </TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allGroups?.map( group => {
                let groupIndex = 0;
                return <>
                  <TableRow className='table-row'  key='table-row'>
                    <TableCell className='table-cell-group'>{langLookUpText(group)}</TableCell>
                  </TableRow>

                  {formattedPermissions?.map((perm) => {
                    return perm.get('functionality_group') === group ? <TableRow
                      key={perm.get('key')}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{ backgroundColor: groupIndex % 2 === 0 ? variables.greyFaint : variables.white }}
                    >
                      <TableCell component="th" scope="row" className='table-cell table-cell-description'>
                        {perm.get('description')}
                      </TableCell>
                      {allRoles?.map(role => {
                        groupIndex++;
                        return perm.get('role')?.includes(role) ? <TableCell align="center" className='table-cell'>
                          <CheckIcon style={{ fontSize: 20, stroke: variables.primary, strokeWidth: '1', color: variables.primary }} />
                        </TableCell> : <TableCell className='table-cell' align="center">
                          <DashIcon fontSize='small' style={{ color: variables.grey, stroke: variables.grey, strokeWidth: '1' }}/>
                        </TableCell>;
                      })}
                    </TableRow> : null;
                  })}
                </>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </PermissionFence>
    </div>
  );
}
