import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchLookupItems, FETCH_LOOKUP_ITEMS }: any = createTypedAction(
  `${PREFIX}FETCH_LOOKUP_ITEMS`,
  ['lookupType', yup.string().required()]
);

export const { fetchLookupItemsSuccess, FETCH_LOOKUP_ITEMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_LOOKUP_ITEMS_SUCCESS`,
  ['lookupType', yup.string().required()],
  ['items', yup.immutable().list().required()]
);

export const { fetchLookupItemsFailed, FETCH_LOOKUP_ITEMS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_LOOKUP_ITEMS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { updateLookupItem, UPDATE_LOOKUP_ITEM }: any = createTypedAction(
  `${PREFIX}UPDATE_LOOKUP_ITEM`,
  ['lookupType', yup.string().required()],
  ['key', yup.string().required()],
  ['value', yup.string().required()]
);

export const { updateLookupItemSuccess, UPDATE_LOOKUP_ITEM_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_LOOKUP_ITEM_SUCCESS`,
  ['lookupType', yup.string().required()],
  ['lookupItem', yup.immutable().map().required()]
);

export const { updateLookupItemFailed, UPDATE_LOOKUP_ITEM_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_LOOKUP_ITEM_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteLookupItem, DELETE_LOOKUP_ITEM }: any = createTypedAction(
  `${PREFIX}DELETE_LOOKUP_ITEM`,
  ['lookupType', yup.string().required()],
  ['key', yup.string().required()]

);

export const { deleteLookupItemSuccess, DELETE_LOOKUP_ITEM_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_LOOKUP_ITEM_SUCCESS`,
  ['lookupType', yup.string().required()],
  ['key', yup.string().required()]
);

export const { deleteLookupItemFailed, DELETE_LOOKUP_ITEM_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_LOOKUP_ITEM_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { addLookupItem, ADD_LOOKUP_ITEM }: any = createTypedAction(
  `${PREFIX}ADD_LOOKUP_ITEM`,
  ['lookupType', yup.string().required()],
  ['key', yup.string().required()],
  ['value', yup.string().required()]
);

export const { addLookupItemSuccess, ADD_LOOKUP_ITEM_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_LOOKUP_ITEM_SUCCESS`,
  ['lookupType', yup.string().required()],
  ['lookupItem', yup.immutable().map().required()]
);

export const { addLookupItemFailed, ADD_LOOKUP_ITEM_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_LOOKUP_ITEM_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
