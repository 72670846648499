import './AutoCompleteSelect.scss';

import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AutoCompleteSelect (props) {
  const {
    onChangeFn,
    options,
    id,
    initialValues,
    placeholder
  } = props;
  const handleChange = (e, value) => {
    onChangeFn(value, id);
  };

  return (
    <Autocomplete
      disablePortal
      id={`auto-complete-select-${id}`}
      value={initialValues}
      popupIcon={<ExpandMoreIcon />}
      options={options}
      onBlur={onChangeFn(null, null)}
      blurOnSelect={true}
      openOnFocus={true}
      renderInput={(params) =>
        <TextField
          InputLabelProps={{ shrink: false }}
          size="small"
          {...params}
          placeholder={placeholder}
        />
      }
      onChange={handleChange}
    />
  );
}

AutoCompleteSelect.propTypes = {
  options: PropTypes.array,
  onChangeFn: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  initialValues: PropTypes.object,
  filterOptions: PropTypes.func
};
