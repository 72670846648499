
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './MFAForm.scss';

import Button from 'src/component/UI/Button';
import VerificationInput from "react-verification-input";
export default function CodeAuth (props) {
  const {
    submitCode,
  } = props;
  const [code, setCode] = useState(null);
  return (
    <>
      <p >Please enter the 6-digit authentication code displayed in your authenticator app.</p>
      <div className='codeArea'>
        <VerificationInput autoFocus={true} validChars="0-9" placeholder=" " onChange={(e) => setCode(e)} classNames={{ container: "character" }} inputProps={{ inputMode: "numeric", autoComplete: "one-time-code" }} onComplete={(e) => { submitCode(e); }} />
      </div>
      <div className='otpButton'>
        <Button cta type='submit' onClick={() => submitCode(code)}>{'Submit'}</Button>
      </div>
    </>
  );
}

CodeAuth.propTypes = {
  submitCode: PropTypes.func.isRequired,
};

CodeAuth.defaultProps = {
};
