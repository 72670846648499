import React from 'react';
import { Draggable } from "react-beautiful-dnd";
import PropTypes from 'prop-types';
import Button from 'src/component/UI/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

import { langLookUpText } from 'src/utils/langLookUp';

export default function RowComponent (props) {
  const {
    row,
    table,
    index
  } = props;

  const handleDelete = (table, field) => {
    props.handleDeleteSearchField(table, field);
  };

  return (
    <Draggable draggableId={row.field} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            padding: '4px',
            ...provided.draggableProps.style,
          }}
        >
          <div className='configure-search-row'>
            <div className='title-and-icon'>
              <DragIndicatorOutlinedIcon style={{ margin: '3 5 0 0', paddingTop: '2' }}/>
              <p>{langLookUpText(row.field)}</p>
            </div>
            <Button onClick={() => handleDelete(table, row.field)} icon={<DeleteIcon/>} />
          </div>
        </div>
      )}
    </Draggable>
  );
}

RowComponent.propTypes = {
  row: PropTypes.object,
  table: PropTypes.string,
  index: PropTypes.number,
  handleDeleteSearchField: PropTypes.func
};

RowComponent.defaultProps = {
};
