import './AuthenticationForm.scss';

import React, { useState } from 'react';
import { useFormik } from 'formik';

import yup from 'src/utils/yup';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Button from 'src/component/UI/Button';
import { NavLink } from 'react-router-dom';

export default function PasswordResetForm (props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: values => {
      setIsSubmitting(true);
      props.handlePasswordResetRequest(values.email);
    },
    validationSchema: () => {
      return yup.object().shape({
        email: yup.string()
          .email('Invalid email')
          .required('Email can\'t be blank')
      });
    },
  });

  const renderResetForm = (
    <form className='auth-form' onSubmit={formik.handleSubmit}>
      <TextField
        id='email'
        type='email'
        label='Email'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.errors.email && formik.touched.email}
        helperText={formik.touched.email && formik.errors.email}
        variant='outlined'
        fullWidth
        InputLabelProps={{ shrink: false }}
      />

      <div className='auth-form-buttons'>
        <Button type='submit' cta background>Send password reset email</Button>
        <NavLink className='float-right' exact to='/login'>Back</NavLink>
      </div>

    </form>
  );

  const renderConfirmation = (
    <div className='confirmation'>
      <h2>Please check your inbox!</h2>
      <h3>We&apos;ve sent a password reset link to the email you provided.</h3>
      <p>If it&apos;s missing, please double check your email address and try again.</p>
    </div>
  );

  return isSubmitting ? renderConfirmation : renderResetForm;
}

PasswordResetForm.propTypes = {
  handlePasswordResetRequest: PropTypes.func.isRequired
};

PasswordResetForm.defaultProps = {
};
