import { createSelector } from 'reselect';
import { List } from 'immutable';

export const getCircuitState = (state) =>
  state.circuit;


export const getRootState = (state) =>
  state;

export const getCircuits = createSelector(
  [getRootState],
  (root) => {
    let list = List();
    root.circuit.getIn(['circuits', 'data'], List()).map( id => {
      const deviceInfo = root.device.getIn(['devices', 'data', id, 'record', 'info'], null);
      if (deviceInfo) {
        list = list.push(deviceInfo);
      }
    });
    return list;
  }
);

export const getLoadingCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['circuits', 'loading'], false);
  }
);


export const getNumCircuitPages = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['circuits', 'totalPages'], 0);
  }
);

export const getTotalCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['circuits', 'total'], 0);
  }
);


export const getForcedOnCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['forcedOnCircuits', 'data'], List());
  }
);

export const getLoadingForcedOnCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['forcedOnCircuits', 'loading'], false);
  }
);

export const getNumForcedOnCircuitPages = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['forcedOnCircuits', 'totalPages'], 0);
  }
);

export const getTotalForcedOnCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['forcedOnCircuits', 'total'], 0);
  }
);
