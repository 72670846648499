import { all, put, spawn, take, call, join } from 'redux-saga/effects';

import * as prefetchActions from 'src/module/prefetch/action';

export function * prefetch (action) { // eslint-disable-line
  try {
    let prefetchFailed = false;

    try {
      const prefetch = yield all([
      ]);
      yield join(prefetch);
    } catch (err) {
      prefetchFailed = true;
    } finally {
      if (prefetchFailed) {
        yield put(prefetchActions.prefetchAllFailure());
      } else {
        yield put(prefetchActions.prefetchAllSuccess());
      }
    }
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    yield put(prefetchActions.prefetchAllFailure(err));
  }
}

export function * watchPrefetch () {
  while (true) {
    const action = yield take(prefetchActions.PREFETCH_ALL);
    yield call(prefetch, action);
  }
}

export function * prefetchRoot () {
  yield all([
    spawn(watchPrefetch)
  ]);
}

export default prefetchRoot;
