import { Map, List } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as filterActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  units: List(),
  systems: List(),
  commodities: List(),
  priorities: List(),
  controllers: List(),
  selectedUnit: '',
  selectedSystem: '',
  selectedCommodity: '',
  selectedLocation: '',
  selectedPriority: '',
  selectedController: '',
  selectedDeadLeg: '',
  selectedDeviceGroup: ''
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState
      .set('units', List())
      .set('systems', List())
      .set('commodities', List())
      .set('priorities', List())
      .set('controllers', List())
      .set('selectedUnit', '')
      .set('selectedSystem', '')
      .set('selectedCommodity', '')
      .set('selectedLocation', '')
      .set('selectedPriority', '')
      .set('selectedController', {})
      .set('selectedDeadLeg', '')
  );
}

export const filterReducer = createTypedReducer(initialState, {
  [filterActions.SET_SELECTED_UNIT] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedUnit', action.unit || '')
    );
  },

  [filterActions.SET_SELECTED_SYSTEM] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedSystem', action.system || '')
    );
  },

  [filterActions.SET_SELECTED_COMMODITY] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedCommodity', action.commodity || '')
    );
  },

  [filterActions.SET_SELECTED_LOCATION] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedLocation', action.location || '')
    );
  },

  [filterActions.SET_SELECTED_PRIORITY] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedPriority', action.priority || '')
    );
  },

  [filterActions.SET_SELECTED_CONTROLLER] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedController', action.controller || '')
    );
  },
  [filterActions.SET_SELECTED_DEVICE_GROUP] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedDeviceGroup', action.deviceGroup|| '')
    );
  },
  [filterActions.SET_SELECTED_DEAD_LEG] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedDeadLeg', action.deadLeg || '')
    );
  },

  [filterActions.SET_SELECTED_SETTING] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('selectedSetting', action.setting || '')
    );
  },

  [filterActions.FETCH_UNITS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('units', action.units)
    );
  },

  [filterActions.FETCH_SYSTEMS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('systems', action.systems)
    );
  },

  [filterActions.FETCH_COMMODITIES_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('commodities', action.commodities)
    );
  },

  [filterActions.FETCH_LOCATIONS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('locations', action.locations)
    );
  },

  [filterActions.FETCH_PRIORITIES_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('priorities', action.priorities)
    );
  },

  [filterActions.FETCH_DEVICE_GROUPS_OPTIONS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.set('deviceGroups', action.deviceGroups || '');
    });
  },
  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default filterReducer;
