import './MajorStat.scss';

import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import ToolTip from '../ToolTip';

export default function MajorStat (props) {
  const {
    className,
    title,
    stat,
    helpText,
    style
  } = props;

  const statDisplay = (stat === null) ? 'N/A' : stat;
  let styleFlag = 1;
  if (title === 'New Critical Alarms Today') {
    styleFlag = 0;
  }

  return (
    <div data-testid='major-stat' className={classNames('major-stat', className)} >
      <div style={(style && style.title) ? style.title : null} className='stat-title'>
        <div>{title}</div>
        {helpText ? (<ToolTip helpText={helpText} ></ToolTip>) : null}
      </div>
      <div className='stat' style={styleFlag ? ((style && style.stat ) ? style.stat : null) : null}>{statDisplay}</div>
    </div>
  );
}

MajorStat.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  stat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]),
  helpText: PropTypes.string,
  style: PropTypes.object
};

MajorStat.defaultProps = {
  className: '',
  helpText: '',
  stat: 'N/A',
  style: null
};
