import { Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as importActions from './action';

export const initialState = Map({
  uploads: Map()
});

export const importReducer = createTypedReducer(initialState, {
  [importActions.IMPORT_DATA] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['uploads', `${action.importFile.name}-${action.importFile.lastModified}`], Map({
        file: { name: action.importFile.name, lastModified: action.importFile.lastModified },
        fileObject: action.importFile,
        status: 'pending'
      }))
    );
  },

  [importActions.IMPORT_DATA_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['uploads', `${action.importFile.name}-${action.importFile.lastModified}`, 'status'], 'success')
    );
  },

  [importActions.IMPORT_DATA_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['uploads', `${action.importFile.name}-${action.importFile.lastModified}`, 'status'], action.status)
    );
  },

  [importActions.CLEAR_UPLOADS] (state) {
    return state.withMutations((nextState) => {
      const uploads = {};
      nextState.get('uploads').keySeq().forEach((key) => {
        const fileObj = nextState.getIn(['uploads', key, 'fileObject']);
        if (nextState.getIn(['uploads', key, 'status']) === 'pending' && fileObj && fileObj.name) {
          uploads[key] = nextState.getIn(['uploads', key]);
        }
      });
      return nextState.set('uploads', Map(uploads));
    });
  }
});

export default importReducer;
