export const isExternalURL = (url = '') => {
  const host = window.location.hostname;
  const linkHost = ((url) => {
    if (/^https?:\/\//.test(url)) {
      const parser = document.createElement('a');
      parser.href = url;
      return parser.hostname;
    }

    return window.location.hostname;
  })(url);

  return host !== linkHost;
};

export default isExternalURL;
