import './ImportForm.scss';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';

import { clearUploads } from 'src/module/import/action';

import toast from 'src/utils/toast';
import { formatBytes } from 'src/utils/utils';

export default function ImportForm (props) {
  const {
    importAction,
    deviceId,
    maxSize,
    importType,
    uploadId,
    version
  } = props;
  const dispatch = useDispatch();



  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    maxSize: maxSize,
    onDrop: (acceptedFiles: Array<File>, e) => {
      if (acceptedFiles.length < 1 && e.length > 0 && maxSize) {
        toast.error(`${e[0].errors[0].message}`);
        return;
      } else if (acceptedFiles.length < 1) {
        toast.error('No File dropped/uploaded');
        return;
      } else if (acceptedFiles.length !== 1 && version) {
        toast.error('Updates to existing device documents may only be done one file at a time.');
        return;
      }

      acceptedFiles.forEach(async (file) => {
        dispatch(importAction(file, importType, deviceId, uploadId, version));
      });
    }
  });

  useEffect(() => {
    dispatch(clearUploads());
  }, [dispatch]);

  const activeStyles = {
    'dropzone-active': isDragActive,
    'dropzone-accept': isDragAccept,
    'dropzone-reject': isDragReject
  };

  return (
    <Grid data-testid='import-form' className='importForm' container spacing={3}>
      <Grid item xs={12} md={12}>
        <div className={classNames('dropzone', activeStyles)} {...getRootProps()}>
          <p>Drag and drop some files here, or click to select files</p>
          {maxSize ? <p>(File size must be less then {formatBytes(maxSize, 2)})</p>: null}
          <input {...getInputProps()} />
        </div>
      </Grid>
    </Grid>
  );
}

ImportForm.propTypes = {
  importAction: PropTypes.func,
  deviceId: PropTypes.string,
  maxSize: PropTypes.number,
  importType: PropTypes.string,
  uploadId: PropTypes.string,
  version: PropTypes.number
};

ImportForm.defaultProps = {
  deviceId: null,
  maxSize: null,
  uploadId: null,
  version: null
};
