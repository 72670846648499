import { getClientId } from '../module/authentication/selector';

export default function createActionEnhancerMiddleware () {
  return ({ getState }) => next => action => {
    const state = getState();

    action.currentClientId = getClientId(state) || null;

    return next(action);
  };
}
