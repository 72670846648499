import { request } from '../api';

export const getReportSchedules = (action) => {
  const payload: any = {
    url: `/report/schedule`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const addReportSchedule = (action) => {
  const {
    reportSchedule
  } = action;

  return request(action, {
    payload: {
      url: `/report/schedule`,
      options: {
        method: 'POST',
        data: reportSchedule
      },
    }
  });
};

export const updateReportSchedule = (action) => {
  const {
    reportScheduleId,
    reportSchedule
  } = action;

  return request(action, {
    payload: {
      url: `/report/schedule/${reportScheduleId}`,
      options: {
        method: 'PATCH',
        data: reportSchedule
      },
    }
  });
};

export const deleteReportSchedule = (action) => {
  const {
    reportScheduleId
  } = action;

  return request(action, {
    payload: {
      url: `/report/schedule/${reportScheduleId}`,
      options: {
        method: 'DELETE'
      },
    }
  });
};
