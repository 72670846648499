import { takeLatest, put, call } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as api from '../../service/api';
import * as noteActions from './action';
import * as devicesActions from '../device/action';
import toast from '../../utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * noteRoot () {
  yield takeLatest(noteActions.FETCH_NOTES, fetchNotes);
  yield takeLatest(noteActions.UPDATE_NOTE, updateNote);
  yield takeLatest(noteActions.ADD_NOTE, addNote);
}

function * fetchNotes (action) {
  try {
    const notes = yield call(api.getNotes, action);
    yield put(noteActions.fetchNotesSuccess(notes.get('data', List()), notes.get('total', 0), notes.get('totalPages', 0), action.note));
  } catch (err: any) {
    yield put(noteActions.fetchNotesFailed(Map(err), action.note));
    toast.error("Error retrieving notes", err.response.status);
  }
}

function * addNote (action) {
  const deviceId = action.note.get('device_id', null);
  try {
    yield put(devicesActions.loadingSettingsModal(deviceId));
    const note = yield call(api.addNote, action, action.note);

    yield put(devicesActions.setSettingsModalClose(deviceId));
    yield put(noteActions.addNoteSuccess(note));
    if (deviceId) {
      yield put(devicesActions.fetchDevicesActiveNotes(null, Map(), {}, false, deviceId));
    }
  }
  catch (err: any) {
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), deviceId));
    yield put(noteActions.addNoteFailed(err));
    toast.error("Failed to create new Note", err.response.status);
  }
}

function * updateNote (action) {
  try {
    const note = yield call(api.updateNote, action, action.noteId, action.note);
    yield put(noteActions.updateNoteSuccess(note));
    yield put(devicesActions.updateDeviceNoteSuccess(note));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating note'), err.response.status);
    yield put(noteActions.updateNoteFailed(Map(err)));
  }
}

export default noteRoot;
