import React from 'react';
import Toast from '../component/UI/Toast';
import { toast } from 'react-toastify';

export default {
  success (message, options = {}) {
    return toast(<Toast type='success' message={message} />, {
      ...options,
      hideProgressBar: true,
      className: 'toast success'
    });
  },
  info (message, options = {}) {
    return toast(<Toast type='info' message={message} />, {
      ...options,
      hideProgressBar: true,
      className: 'toast info'
    });
  },
  warn (message, options = {}) {
    return toast(<Toast type='warn' message={message} />, {
      ...options,
      hideProgressBar: true,
      className: 'toast warn'
    });
  },
  update (id, options = {}) {
    return toast.update(id, options);
  },
  error (message, errCode = 400, options = {}) {
    if (errCode === 401 && message !== 'Your session has expired. Please login again.') {
      return;
    }
    return toast(<Toast type='error' message={message} />, {
      ...options,
      hideProgressBar: true,
      className: 'toast error'
    });
  },
  liveDataNotify (message: any, options: any = {}) {
    if (options.toastId && toast(options.toastId)) {
      return null;
    }
    return toast(<Toast message={message} />, {
      className: 'toast live__data',
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: false,
      draggable: false,
      hideProgressBar: true,
      closeOnClick: false,
      ...options
    });
  }
};
