import './ServiceAgreementScreen.scss';
import '../../../component/UI/FooterBar.scss';

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';

import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import { Redirect } from 'react-router-dom';
import { Modal, Paper } from '@mui/material';

import Button from 'src/component/UI/Button';
import { updateUser } from 'src/module/user/action';
import { getAcceptedTerms } from 'src/module/authentication/selector';
import serviceTerms from 'src/component/UI/ServiceTerms';
import { isWhitelabel } from 'src/utils/utils';

export default function ServiceAgreementScreen () {
  const dispatch: any = useDispatch();
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const acceptedTerms = useSelector(getAcceptedTerms);
  const [hasAccepted, setHasAccepted] = useState(false);

  const handleScroll = (event) => {
    const target = event.target;
    if (Math.abs(target.scrollHeight - (target.scrollTop + target.clientHeight)) <= 1) {
      setScrolledToBottom(true);
    }
  };

  const onAcceptedTerms = () => {
    dispatch(updateUser(null, Map({ acceptedTerms: true })));
    setHasAccepted(true);
  };

  if (hasAccepted) {
    return (<Redirect to={{ pathname: '/' }} />);
  }

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    !acceptedTerms ?
      <Modal
        data-testid='service-agreement-modal'
        open={true}
        aria-labelledby='settings-modal-title'
      >
        <div className={'modal settings-modal service-agreement-modal'} onScroll={handleScroll}>
          <Helmet>
            <title>About {title}</title>
          </Helmet>
          <Grid item xs={6} className='service-agreement-page'>
            <div className={'service-agreement-container'}>
              {serviceTerms}
            </div>
          </Grid>
          <div data-testid='footer-bar' className='footer-bar'>
            <div className='footer-bar-container'>
              <div className='footer-bar-main'>
                <div className='footer-bar-button '>
                  <Tooltip title={!scrolledToBottom ? "Read to end to accept" : ""}>
                    <div>
                      <Button onClick={onAcceptedTerms} cta disabled={!scrolledToBottom} >Accept</Button>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      :  <div className='page'>
        <Helmet>
          <title>About {title}</title>
        </Helmet>
        <Grid item xs={6} className='service-agreement-page'>
          <Paper className='terms-container-paper'>
            {serviceTerms}
          </Paper>
        </Grid>
      </div>
  );
}
