import React from 'react';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import DeviceGroupsList from './DeviceGroupsList';
import { pushHistory } from 'src/module/navigation/action';
import { useDispatch } from 'react-redux';
import Button from 'src/component/UI/Button';
import { isWhitelabel } from 'src/utils/utils';
import PermissionFence from 'src/component/PermissionFence';

export default function DeviceGroupScreen () {
  const dispatch = useDispatch();

  const createGroup = () => {
    dispatch(pushHistory(`/devices/list/create-group`));
  };
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';
  return (
    <div className='page' id='loto-screen'>
      <Helmet>
        <title>{title} - Device Groups</title>
      </Helmet>
      <Paper>
        <h2>Device Groups</h2>
        <div style={{ display: 'flex', float: 'right' }}>
          <PermissionFence can={['edit-device']} >
            <Button cta onClick={createGroup}>Select Devices for a New Group</Button>
          </PermissionFence>

        </div>
        <DeviceGroupsList />

      </Paper>
    </div>
  );
}
