import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { addToQueue, ADD_TO_QUEUE }: any = createTypedAction(
  `${PREFIX}ADD_TO_QUEUE`,
  ['action', yup.object()]
);

export const { updateIsOffline, UPDATE_IS_OFFLINE }: any = createTypedAction(
  `${PREFIX}UPDATE_IS_OFFLINE`,
  ['isOffline', yup.boolean()]
);

export const { removeFromQueue, REMOVE_FROM_QUEUE }: any = createTypedAction(
  `${PREFIX}REMOVE_FROM_QUEUE`,
  ['id', yup.number()]
);
export const { setRunningQueue, SET_RUNNING_QUEUE }: any = createTypedAction(
  `${PREFIX}SET_RUNNING_QUEUE`,
  ['isRunning', yup.boolean()]
);

export const { clearQueue, CLEAR_QUEUE }: any = createTypedAction(
  `${PREFIX}CLEAR_QUEUE`
);

export const { triggerQueue, TRIGGER_QUEUE }: any = createTypedAction(
  `${PREFIX}TRIGGER_QUEUE`,
  ['trigger', yup.boolean()]
);
