import { List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as upgradeActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  upgrade: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('upgrades', Map())
  );
}

export const upgradeReducer = createTypedReducer(initialState, {
  [upgradeActions.FETCH_DEVICE_UPGRADES_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const upgradesMap = action.upgrades.reduce(
        (acc, cur) => {
          const key = `${cur.get('base_device_factory', '').replace('Controller', '')}-${cur.get('upgrade_device_factory', '').replace('Controller', '')}`;
          const upgradesForModelPair = acc.get(key, List());
          return acc.set(key, upgradesForModelPair.push(cur));
        }, Map());
      return nextState.setIn(['upgrades', 'data'], upgradesMap);
    });
  },

  [upgradeActions.UPDATE_DEVICE_UPGRADE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const key = `${action.upgradePath.get('base_device_factory', '').replace('Controller', '')}-${action.upgradePath.get('upgrade_device_factory', '').replace('Controller', '')}`;
      const index = nextState.getIn(['upgrades', 'data', key]).findIndex(obj => obj.get('id') === action.upgradePath.get('id'));
      if (index !== -1) {
        return nextState.setIn(['upgrades', 'data', key], nextState.getIn(['upgrades', 'data', key], List()).set(index, action.upgradePath));
      }
      return nextState;
    });
  },

  [upgradeActions.CREATE_DEVICE_UPGRADE] (state) {
    return state.withMutations((nextState) => {
      return nextState.setIn(['upgrades', 'loading'], true);
    }
    );
  },

  [upgradeActions.CREATE_DEVICE_UPGRADE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const key = `${action.upgrade.get('base_device_factory', '').replace('Controller', '')}-${action.upgrade.get('upgrade_device_factory', '').replace('Controller', '')}`;
      return nextState.setIn(['upgrades', 'data', key], nextState.getIn(['upgrades', 'data', key], List()).push(action.upgrade))
        .setIn(['upgrades', 'loading'], false);
    }
    );
  },

  [upgradeActions.DELETE_DEVICE_UPGRADE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const upgrades = nextState.getIn(['upgrades', 'data'], Map());
      const allUpgrades = upgrades.valueSeq()
        .flatten(true)
        .toList();
      const foundUpgrade = allUpgrades.find(obj => obj.get('id') === action.deviceUpgradeId);
      if (foundUpgrade) {
        const key = `${foundUpgrade.get('base_device_factory', '').replace('Controller', '')}-${foundUpgrade.get('upgrade_device_factory', '').replace('Controller', '')}`;
        const index = nextState.getIn(['upgrades', 'data', key], List()).findIndex( (item) => item.get('id') === action.deviceUpgradeId );
        return (index !== -1) ? nextState.deleteIn(['upgrades', 'data', key, index])  : nextState;
      }
      return nextState;
    });
  },

  [upgradeActions.UPDATE_DEVICE_UPGRADE_CONDITION_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const key = `${action.upgradePath.get('base_device_factory', '').replace('Controller', '')}-${action.upgradePath.get('upgrade_device_factory', '').replace('Controller', '')}`;
      const index = nextState.getIn(['upgrades', 'data', key], List()).findIndex(obj => obj.get('id') === action.upgradePath.get('id'));
      if (index !== -1) {
        return nextState.setIn(['upgrades', 'data', key], nextState.getIn(['upgrades', 'data', key]).set(index, action.upgradePath));
      }
    });
  },

  [upgradeActions.ADD_DEVICE_UPGRADE_CONDITION_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const key = `${action.upgradePath.get('base_device_factory', '').replace('Controller', '')}-${action.upgradePath.get('upgrade_device_factory', '').replace('Controller', '')}`;
      const index = nextState.getIn(['upgrades', 'data', key], List()).findIndex(obj => obj.get('id') === action.upgradePath.get('id'));
      if (index !== -1) {
        return nextState.setIn(['upgrades', 'data', key], nextState.getIn(['upgrades', 'data', key]).set(index, action.upgradePath));
      }
    });
  },

  [upgradeActions.UPDATE_DEVICE_UPGRADE_CONDITION_BRANCH_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const key = `${action.upgradePath.get('base_device_factory', '').replace('Controller', '')}-${action.upgradePath.get('upgrade_device_factory', '').replace('Controller', '')}`;
      const index = nextState.getIn(['upgrades', 'data', key], List()).findIndex(obj => obj.get('id') === action.upgradePath.get('id'));
      if (index !== -1) {
        return nextState.setIn(['upgrades', 'data', key], nextState.getIn(['upgrades', 'data', key]).set(index, action.upgradePath));
      }
    });
  },

  [upgradeActions.ADD_DEVICE_UPGRADE_CONDITION_BRANCH_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const key = `${action.upgradePath.get('base_device_factory', '').replace('Controller', '')}-${action.upgradePath.get('upgrade_device_factory', '').replace('Controller', '')}`;
      const index = nextState.getIn(['upgrades', 'data', key], List()).findIndex(obj => obj.get('id') === action.upgradePath.get('id'));
      if (index !== -1) {
        return nextState.setIn(['upgrades', 'data', key], nextState.getIn(['upgrades', 'data', key]).set(index, action.upgradePath));
      }
    });
  },


  [upgradeActions.DELETE_DEVICE_CONDITION_BRANCH_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {

      const upgradeData = nextState.getIn(['upgrades', 'data'], Map());

      // 1 find index of upgrade path for branch
      // 2 if exists, set the 'upgradeConditions' list of the upgrade path with the branch removed
      // 3 update store with the updated upgrade path
      upgradeData.keySeq().forEach((key) => {
        const list = upgradeData.get(key, List());
        const index = list.findIndex(
          (item) => item.get('upgradeConditions').find((branch) => branch.get('id') === action.upgradeConditionBranchId)
        );
        if (index !== -1) {
          const oldUpgradePath =  list.get(index, Map());
          const upgradePathBranchConditions = oldUpgradePath.get('upgradeConditions', List()).filter((branch) => branch.get('id') !== action.upgradeConditionBranchId);
          const newUpgradePath = oldUpgradePath.set('upgradeConditions', upgradePathBranchConditions);
          nextState.setIn(
            ['upgrades', 'data', key],
            list.set(index, newUpgradePath)
          );
        }
      });

      ///
    });
  },

  [upgradeActions.CLEAR_DEVICE_UPGRADE_LOADING] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['upgrades', 'loading'], false);
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default upgradeReducer;
