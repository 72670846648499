import './ChatIntegration.scss';
import React from 'react';
import FreshChat from 'react-freshchat';
import { useSelector } from 'react-redux';
import EhtGroupLogo from '../assets/logo-color-square.png';
import { Helmet } from 'react-helmet';

export default function ChatIntegration () {
  const user: any = useSelector((state: any) => state.authentication.get('user'));
  const client: any = useSelector((state: any) => state.authentication.get('client'));
  const clients: any = useSelector((state: any) => state.authentication.get('clients'));
  const hsIdentificationAccessToken: any = useSelector((state: any) => state.authentication.get('hsIdentificationAccessToken'));
  const [widget, setWidget] = React.useState(null);

  if (widget) {
    widget.user.setProperties({
      environment: process.env.NODE_ENV,
      appVersion: process.env.REACT_APP_FOX_RELEASE_HASH
    });

    if (client) {
      widget.user.setProperties({
        currentClientId: client.get('id'),
        currentClientName: client.get('name')
      });
    }

    if (clients) {
      const clientIds = [];
      clients.map((client) => {
        if (client && client.get) {
          clientIds.push(client.get('id'));
        }
        return client;
      });

      widget.user.setProperties({
        clients: clientIds.join(', ')
      });
    }

    if (user) {
      widget.setExternalId(user.get('id', null));
      widget.user.setProperties({
        userId: user.get('id', null),
        email: user.get('email', null),
        firstName: user.get('first_name', null),
        lastName: user.get('last_name', null),
      });
    }
  }

  function renderFreshchat () {
    return process.env.REACT_APP_FRESHCHAT_TOKEN ? (<FreshChat
      token={process.env.REACT_APP_FRESHCHAT_TOKEN}
      config={{
        headerProperty: {
          appLogo: EhtGroupLogo,
        },
        agent: {
          hideBio: true
        }
      }}
      onInit={widget => {
        setWidget(widget);
      }}
    />) : null;
  }

  function renderHubspotChat () {
    if (process.env.REACT_APP_HUBSPOTCHAT_SCRIPT) {
      if (hsIdentificationAccessToken) {
        window['hsConversationsSettings'] = {
          identificationEmail: user.get('email'),
          identificationToken: hsIdentificationAccessToken
        };
      }

      return (<Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src={process.env.REACT_APP_HUBSPOTCHAT_SCRIPT}></script>
      </Helmet>);
    }

    return null;
  }

  return (<>
    {renderHubspotChat()}
    {renderFreshchat()}
  </>);
}
