import { getBatch, getLoadingBatchSchedules } from 'src/module/batchSchedule/selector';
import './BatchConfirmScreen.scss';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import React, { useEffect, useState } from 'react';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import { langLookUpValidationError } from 'src/utils/langLookUp';
import DeviceLink from 'src/component/UI/DeviceLink';
import Button from 'src/component/UI/Button';
import { deleteScheduledDevice, fetchBatchDevices } from 'src/module/batchSchedule/action';
import { useSelector, useDispatch } from 'react-redux';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getTablePagePreferences } from 'src/module/authentication/selector';
import DataTable from 'src/component/UI/DataTable';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
export default function BatchDeviceList (props) {
  const {
    batchId,
    hideDelete,
    statusFilter,
    setStatusFilter
  } = props;
  const batch = useSelector(getBatch(batchId));
  const dispatch: any = useDispatch();
  const batchLoading = useSelector(getLoadingBatchSchedules);
  const userPagePreference = useSelector(getTablePagePreferences('batch-confirm'));
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(userPagePreference);
  const fetchPage = (_page, _pageSize) => {
    setPage(_page);
    setPageSize(_pageSize);
  };
  useEffect(() => {
    dispatch(fetchBatchDevices(batchId, { pageSize: pageSize, page: page }, statusFilter));
  }, [batchId, page, pageSize, statusFilter]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setStatusFilter(newAlignment);
  };
  const getControllerTag = (value) => {
    return value.get('parent_type') === 'commLoop' ? null : value.get('parent_tag');
  };
  const getClass = (rec) => {
    if ( rec?.getIn(['validation_errors', 'hasErrors']) || rec?.getIn(['status', 'hasErrors'])) {
      return 'row-error';
    }
    if ( rec?.getIn(['validation_errors', 'isWarning']) || rec?.getIn(['status', 'isWarning'])) {
      return 'row-error-warn';
    }

    return '';
  };
  const columnOptions: any = [{
    id: 'controller.tag',
    Header: 'Controller',
    accessor: (row: any) => {
      if (row && row.get) {
        return row?.get('parent_tag');
      }
      return 'N/A';
    },
    sortable: true,
    ClassName: (row: any) => {
      return getClass(row.original);
    },
    Cell: (row: any) => {
      return (
        <DeviceLink label={row.cell.value} controllerTag={getControllerTag(row.cell.row.original)} />
      );
    }
  },
  {
    id: 'devices.tag',
    Header: 'Circuit',
    accessor: (row: any) => {
      if (row && row.get) {
        return row.get('device_tag');
      }
      return '';
    },
    ClassName: (row: any) => {
      return getClass(row.original);
    },
    sortable: true,
    Cell: (row: any) => {
      return (
        <DeviceLink label={row.cell.value} circuitTag={row.cell.value} rawTag={row.cell.row.original.get('raw_tag')} controllerTag={getControllerTag(row.cell.row.original)} />
      );
    }
  },
  {
    id: 'devices.model',
    Header: 'Model',
    accessor: (row: any) => row?.get('model'),
    sortable: true,
    ClassName: (row: any) => {
      return getClass(row.original);
    },
    Cell: (row: any) => { return row.cell.value ? row.cell.value : ''; }
  },
  {
    id: 'devices.validation_errors',
    Header: 'Info',
    sortable: false,
    accessor: (row: any) => row.get('validation_errors') || row.get('status'),
    ClassName: (row: any) => {
      return getClass(row.original);
    },
    Cell: (row: any) => {
      const errs = row.cell.value?.get('errors');
      const warnings = row.cell.value?.get('warnings');
      if (List.isList(errs)) {
        return errs.map(err => langLookUpValidationError(err)).toArray();
      }
      else if (List.isList(warnings)) {
        return warnings.map(warning => langLookUpValidationError(warning)).toArray();
      }
      return errs;
    }
  }
  ];
  if (!hideDelete) {
    columnOptions.push( {
      id: 'remove_all',
      disableSortBy: true,
      ClassName: (row: any) => {
        return getClass(row.original);
      },
      accessor: (row: any) => {
        return <div className='error-actions'><Button onClick={() => { onRemoveRow(row.get('id')); }} icon={<RemoveCircleOutlineIcon style={{ fontSize: 14 }} />} /></div>;
      },
    });
  }
  const onRemoveRow = (deviceId) => {
    dispatch(deleteScheduledDevice(batchId, deviceId, { pageSize: pageSize, page: page }, statusFilter));
  };

  return (
    <div className='BatchDevices'>
      <div className='filters'>
        <ToggleButtonGroup
          color="primary"
          value={statusFilter}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="all" className='ToggleButton-text'>All ({batch.getIn(['status', 'number_devices'])})</ToggleButton>
          <ToggleButton value="success" className='ToggleButton-text'>Only Device Without Errors ({batch.getIn(['status', 'number_devices'])-batch.get('numErrors')})</ToggleButton>
          <ToggleButton value="errors" className='ToggleButton-text'>Only Device with Errors ({batch.get('numErrors')})</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {
        batchLoading ?
          <div className="div-container">
            <LogoSpinner size={100} />
          </div> :
          <>
            <h3>Circuits</h3>
            <DataTable
              columns={columnOptions}
              data={batch.get('devices', List([]))}
              pagination={true}
              numPages={ batch.get('devices') ? Math. ceil(batch.getIn(['status', 'number_devices'])/pageSize) : 0}
              totalRecords={batch.get('devices') ?batch.getIn(['status', 'number_devices']): 0}
              loading={false}
              fetchPage={fetchPage}
              tableId='batch-confirm'
              columnWidths={['auto', 'auto', 'auto', '400px', 'auto']}
            />
          </>

      }
    </div>
  );
}

BatchDeviceList.propTypes = {
  batchId: PropTypes.string,
  hideDelete: PropTypes.bool,
  statusFilter: PropTypes.string,
  setStatusFilter: PropTypes.func
};

BatchDeviceList.defaultProps = {};
