import { List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as lookupActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  alarm_simplification: List()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set(Map())
  );
}

export const lookupReducer = createTypedReducer(initialState, {

  [lookupActions.FETCH_LOOKUP_ITEMS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set(action.lookupType, action.items)
    );
  },

  [lookupActions.DELETE_LOOKUP_ITEM_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      return nextState.set(action.lookupType, nextState.get(action.lookupType)
        .filter((item) => item.get('key') !== action.key));
    });
  },

  [lookupActions.UPDATE_LOOKUP_ITEM_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.get(action.lookupType, List())
        .findIndex( (item) => item.get('key') === action.lookupItem.get('key') );
      return (index !== -1) ? nextState.setIn([action.lookupType, index], action.lookupItem) : nextState;
    });
  },

  [lookupActions.ADD_LOOKUP_ITEM_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const items = nextState.get(action.lookupType).push(action.lookupItem);
      nextState.set(action.lookupType, items);
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default lookupReducer;
