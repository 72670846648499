import yup from 'src/utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchPermissions, FETCH_PERMISSIONS }: any = createTypedAction(
  `${PREFIX}FETCH_PERMISSIONS`,
);

export const { fetchPermissionsSuccess, FETCH_PERMISSIONS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_PERMISSIONS_SUCCESS`,
  ['permissions', yup.immutable().list().required()],
);

export const { fetchPermissionsFailed, FETCH_PERMISSIONS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_PERMISSIONS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
