import { createTheme } from '@mui/material/styles';
import variables from './variable/variables.module.scss';

export default createTheme({
  typography: {
    fontFamily: `'Montserrat', 'Trebuchet MS', 'Arial', 'sans-serif'`
  },

  palette: {
    primary: { main: variables.primary },
    secondary: { main: variables.secondary },
    error: { main: variables.error }
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: 'Montserrat',
          fontWeight: 600,
        }
      }
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textAlign: 'right'
        },
        contained: {
          marginRight: 0
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&$marginDense': {
            marginTop: 0
          }
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          overflow: 'visible',
          textOverflow: 'visible',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          whiteSpace: 'normal',
          position: 'relative',
          transform: 'scale(0.8)',
          marginTop: '20px',
          textTransform: 'uppercase',
          fontWeight: 600,
          left: '0',
          top: '0',
          '&$shrink': {
            transform: 'scale(0.8)',
            marginTop: '10px'
          },
          '&$marginDense': {
            transform: 'scale(1)',
            textTransform: 'none',
            fontWeight: 400,
            marginTop: 0,
          },
          '&$shrink&$marginDense': {
            transform: 'scale(0.75)',
            textTransform: 'none',
            fontWeight: 400,
            marginTop: 0,
          }
        }
      }
    }
  }
});
