import './AuthenticationForm.scss';

import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import yup from 'src/utils/yup';
import PropTypes from 'prop-types';
import Button from 'src/component/UI/Button';
import { NavLink } from 'react-router-dom';
import PhoneNumber from 'mui-phone-number';
import { isValidNumber } from 'libphonenumber-js';
import { resendMultiFactorAuthenticate, setUnverifiedContactNumber } from 'src/module/authentication/action';
import { getUserByEmail } from 'src/module/user/selector';

export default function ContactNumberForm (props) {
  const dispatch = useDispatch();
  const [contactNumber, setContactNumber] = useState(null);
  const [isFocusedContactNumber, setIsFocusedContactNumber] = useState(false);
  const user = useSelector(getUserByEmail(props.email));

  const contactForm = useFormik({
    initialValues: {},
    onSubmit: () => {
      if (user?.get('id')) {
        dispatch(setUnverifiedContactNumber(user.get('id'), contactNumber));
      }
      dispatch(resendMultiFactorAuthenticate('text'));
      props.handleInputContactNumber(contactNumber);
    },
    validationSchema: () => {
      return yup.object().shape({
        contactNumber: yup
          .string()
          .nullable()
          // mui-phone-number will auto-format the entered contact number
          .test('valid-contact-number', 'Invalid contact number, please double check you have entered a valid phone number. Sample format +1 (999) 999-9999', function (val = null) {
            return val ? isValidNumber(val) : true;
          })
      });
    }
  });

  const handleChangeContactNumber = (number) => {
    setContactNumber(number);
    contactForm.setFieldValue('contactNumber', number);
  };

  return (
    <div>
      <h4>Your facility requires text message authentication. Please enter your Contact Number and verify it to login into your account</h4><br/>
      <form className='auth-form' onSubmit={contactForm.handleSubmit}>
        <label htmlFor='contactNumber' className={isFocusedContactNumber ? 'contact-number-label-focused' : 'contact-number-label'}>Contact Number</label>
        <PhoneNumber
          id='contactNumber'
          defaultCountry={'ca'}
          value={contactForm.values['contactNumber']}
          fullWidth
          onChange={number => handleChangeContactNumber(number)}
          onFocus={() => setIsFocusedContactNumber(true)}
          onBlur={() => setIsFocusedContactNumber(false)}
          onlyCountries={['ca', 'us']}
          error={Boolean(contactForm.errors['contactNumber']) && Boolean(contactForm.touched['contactNumber'])}
          helperText={(contactForm.touched['contactNumber'] && contactForm.errors['contactNumber']) as string}
        />

        <div className='auth-form-buttons'>
          <Button type='submit' cta background>Save And Send Code</Button>
          <NavLink className='float-right' exact to='/login'>Back</NavLink>
        </div>

      </form>
    </div>
  );

}

ContactNumberForm.propTypes = {
  handleInputContactNumber: PropTypes.func.isRequired,
  email: PropTypes.string
};

ContactNumberForm.defaultProps = {
};
