import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { TextField as MuiTextField } from '@mui/material';

export default function TextField (props) {
  const {
    field,
    value,
    error,
    handleChange,
    disabled,
    showLabel,
    showHelperText,
  } = props;

  const fieldId = field.get('id');

  const unit = field.get('unit', null);
  const label = (<>
    {field.get('label')}
    {unit ? (<>&nbsp;<span className='unit'>({unit})</span></>) : null}
  </>);

  return <MuiTextField
    data-testid={`date-field-${fieldId}`}
    fullWidth
    key={`date-field-${fieldId}`}
    id={`date-field-${fieldId}`}
    name={`date-field-${fieldId}`}
    label={showLabel ? label : ''}
    type='date'
    value={value || ''}
    onChange={(e) => handleChange(fieldId, e.target.value)}
    error={Boolean(error)}
    disabled={disabled}
    helperText={
      showHelperText ?
        <span data-testid={`date-field-helper-text-${fieldId}`}>
          {(error) ?
            <>
              {error}<br />
            </> : null}
          {field.get('helperText')}
        </span> : null
    }
    variant='outlined'
    InputLabelProps={{ shrink: false }}
  />;
}

TextField.propTypes = {
  field: ImmutablePropTypes.map,
  error: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
  showHelperText: PropTypes.bool,
};

TextField.defaultProps = {
  disabled: false,
  showLabel: true,
  showHelperText: true,
};

TextField.whyDidYouRender = true;
