import './Filters.scss';

import React, { useEffect } from 'react';
import Filter from './Filter';
import { fetchUnits, fetchSystems, fetchCommodities, fetchLocations, fetchPriorities, setSelectedUnit, setSelectedDeviceGroup, setSelectedSystem, setSelectedCommodity, setSelectedLocation, setSelectedPriority, setSelectedController, setSelectedDeadLeg } from 'src/module/filter/action';
import { getUnits, getSystems, getCommodities, getLocations, getPriorities, getSelectedDeviceGroup, getDeviceGroups } from 'src/module/filter/selector';
import { getSelectedUnit, getSelectedSystem, getSelectedCommodity, getSelectedLocation, getSelectedPriority, getSelectedController, getSelectedDeadLeg } from 'src/module/filter/selector';
import { fromJS } from 'immutable';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDeviceGroupsLabels } from 'src/module/groupDevices/action';

export default function Filters (props) {
  const {
    showUnit
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUnits());
    dispatch(fetchSystems());
    dispatch(fetchCommodities());
    dispatch(fetchLocations());
    dispatch(fetchPriorities());
    dispatch(fetchDeviceGroupsLabels());
  }, []);

  const units = useSelector(getUnits);
  const systems = useSelector(getSystems);
  const commodities = useSelector(getCommodities);
  const locations = useSelector(getLocations);
  const priorities = useSelector(getPriorities);
  const groups: any = useSelector(getDeviceGroups);

  const selectedUnit = useSelector(getSelectedUnit);
  const selectedSystem = useSelector(getSelectedSystem);
  const selectedCommodity = useSelector(getSelectedCommodity);
  const selectedLocation = useSelector(getSelectedLocation);
  const selectedPriority = useSelector(getSelectedPriority);
  const selectedController = useSelector(getSelectedController);
  const selectedDeadLeg = useSelector(getSelectedDeadLeg);
  const selectedDeviceGroup = useSelector(getSelectedDeviceGroup);

  const deadLegs = fromJS([{ 'dead leg': 'Yes' }, { 'dead leg': 'No' }, { 'dead leg': null }]);

  return (
    <div className='filters-container'>
      <Filter type='priority' value={selectedPriority} options={priorities} onChange={setSelectedPriority}/>
      <Filter type='commodity' value={selectedCommodity} options={commodities} onChange={setSelectedCommodity}/>
      <Filter type='location' value={selectedLocation} options={locations} onChange={setSelectedLocation}/>
      {showUnit ? <Filter type='unit' value={selectedUnit} options={units} onChange={setSelectedUnit} /> : ''}
      <Filter type='system' value={selectedSystem} options={systems} onChange={setSelectedSystem}/>
      <Filter type='controller' value={selectedController} options={[]} onChange={setSelectedController} />
      <Filter type='dead leg' value={selectedDeadLeg} options={deadLegs} onChange={setSelectedDeadLeg} />
      <Filter type='device_group' value={selectedDeviceGroup} options={groups} onChange={setSelectedDeviceGroup} />

    </div>
  );
}


Filters.propTypes = {
  showUnit: PropTypes.bool,
  page: PropTypes.string
};

Filters.defaultProps = {
  showUnit: true,
  page: ''
};
