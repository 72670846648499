
import React, { useEffect, useState } from 'react';
import DataTable from 'src/component/UI/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import EditableCell from 'src/component/UI/EditableCell';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';

import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import { getFreezeLogs, getLoadingFreezeLogs, getNumFreezeLogsPages, getTotalFreezeLogs } from 'src/module/freezeLog/selector';
import { fetchFreezeLogs, updateFreezeLog  } from 'src/module/freezeLog/action';

import DeviceLink from 'src/component/UI/DeviceLink';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          container: {
            display: "flex",
            flexWrap: "wrap"
          },
        },
      },
    },
  },
});

export default function FreezeLogsList (props) {
  const selectedFilters = useSelector(getDevicesSelectedFilters);

  const freezeLogs: any = useSelector(getFreezeLogs) || [];
  const loading: any = useSelector(getLoadingFreezeLogs);
  const numPages: any = useSelector(getNumFreezeLogsPages);
  const totalFreezeLogs: any = useSelector(getTotalFreezeLogs);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filter, setFilter] = React.useState(null);
  const [sort, setSort] = React.useState(null);
  const [showResolved, setShowResolved] = React.useState(false);

  const {
    pagination,
    filterable,
    showResolvedAt,
    showResolvedOption,
  } = props;

  const dispatch: any = useDispatch();

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setFilter(_filter);
    setSort(_sort);
  };

  const handleToggleShowResolved = () => {
    setShowResolved(!showResolved);
  };

  useEffect(() => {
    const paginationDetails = pagination ? { page, pageSize, filter, sort } : {};
    dispatch(fetchFreezeLogs(selectedFilters, paginationDetails, showResolved));
  }, [dispatch, selectedFilters, showResolved, page, pageSize, filter, sort]);


  const handleUpdateRow = (field: any, freezeLogId: string) => {
    return (val) => {
      const rec: any = {};
      rec[field] = val;
      dispatch(updateFreezeLog(freezeLogId, Map(rec)));
    };
  };

  const columnOptions: any = [
    {
      id: 'circuit.tag',
      Header: 'Circuit',
      accessor: (row: any) => row?.getIn(['circuit_tag']),
      sortable: true,
      Cell: (row: any) => {return (<DeviceLink label={row.cell.value} circuitTag={row.cell.value} controllerTag={row.cell.row.original.getIn(['controller_tag'])} />); }
    },
    {
      id: 'freeze_issue',
      Header: `Freeze Issue`,
      accessor: (row: any) => row?.getIn(['freeze_issue']),
      sortable: true,
      Cell: (row: any) => {return row.cell.row.original?.get('resolved_at') === null ? (
        <ThemeProvider theme={theme}>
          <EditableCell className='freeze-log-cell' permission='edit-freeze-log' onChange={handleUpdateRow('freeze_issue', row.cell.row.original.get('id'))} value={row.cell.value} emptyCellLabel='Add freeze issue'/>
        </ThemeProvider>
      ) : row.cell.value;}
    },
    {
      id: 'equipment',
      Header: 'Equipment',
      accessor: (row: any) => row?.getIn(['equipment']),
      sortable: true,
      Cell: (row: any) => {return row.cell.row.original?.get('resolved_at') === null ? (
        <ThemeProvider theme={theme}>
          <EditableCell className='freeze-log-cell' permission='edit-freeze-log' onChange={handleUpdateRow('equipment', row.cell.row.original.get('id'))} value={row.cell.value} emptyCellLabel='Add equipment'/>
        </ThemeProvider>
      ) : row.cell.value;}
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: (row: any) => row?.getIn(['description']),
      Cell: (row: any) => {return row.cell.row.original?.get('resolved_at') === null ? (
        <ThemeProvider theme={theme}>
          <EditableCell className='freeze-log-cell' permission='edit-freeze-log' onChange={handleUpdateRow('description', row.cell.row.original.get('id'))} value={row.cell.value} multiLine={true} emptyCellLabel='Add description'/>
        </ThemeProvider>
      ) : row.cell.value;}
    },
  ];

  if (showResolvedAt) {
    columnOptions.push({
      id: 'resloved_at',
      Header: 'Resolved At',
      accessor: (row: any) => row?.get('resolved_at') ? moment(row.get('resolved_at')).format('YYYY-MM-DD') : '',
    });
  }

  return (
    <div data-testid='freeze-logs--list' className='freeze-logs-list'>
      <h2>{'Freeze Logs'}</h2>
      {showRotateMessage ? <DeviceRotate/> :
        <DataTable
          filterable={filterable}
          filterLabel='Filter by Circuit Tag'
          columns={columnOptions}
          data={freezeLogs}
          pagination={pagination}
          numPages={numPages}
          totalRecords={totalFreezeLogs}
          loading={loading}
          fetchPage={fetchPage}
          tableId='freezeLogsTable'
        />
      }

      {showResolvedOption && !showRotateMessage ?
        (<FormControlLabel
          control={
            <Checkbox
              checked={showResolved}
              onChange={handleToggleShowResolved}
              name="showResolved"
              color="primary"
            />
          }
          label="Show Resolved"
        />) : null
      }

    </div>
  );
}

FreezeLogsList.propTypes = {
  filterable: PropTypes.bool,
  pagination: PropTypes.bool,
  showResolvedAt: PropTypes.bool,
  showResolvedOption: PropTypes.bool,
};

FreezeLogsList.defaultProps = {
  filterable: true,
  pagination: true,
  showResolvedAt: false,
  showResolvedOption: false,
};
