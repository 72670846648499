import yup from 'src/utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchDeviceGroups, FETCH_DEVICE_GROUPS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUPS`,
  ['paginationDetails', yup.object().nullable()],
);

export const { fetchDeviceGroupsSuccess, FETCH_DEVICE_GROUPS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUPS_SUCCESS`,
  ['groups', yup.immutable().list().required()],
  ['total', yup.number().required()],
  ['totalPages', yup.number()]
);

export const { fetchDeviceGroupsFailed, FETCH_DEVICE_GROUPS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUPS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchDeviceGroupsLabels, FETCH_DEVICE_GROUPS_LABELS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUPS_LABELS`
);

export const { fetchDeviceGroupsLabelsSuccess, FETCH_DEVICE_GROUPS_LABELS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUPS_LABELS_SUCCESS`,
  ['groups', yup.immutable().list().required()],
);

export const { fetchDeviceGroupsLabelsFailed, FETCH_DEVICE_GROUPS_LABELS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUPS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteDeviceGroup, DELETE_DEVICE_GROUP }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_GROUP`,
  ['groupId', yup.string().required()],
);

export const { deleteDeviceGroupSuccess, DELETE_DEVICE_GROUP_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE_GROUP_SUCCESS`,
  ['groupId', yup.immutable().map()],
);

export const { deleteDeviceGroupFailed, DELETE_DEVICE_GROUP_FAILED }: any = createTypedAction(
  `${PREFIX} DELETE_DEVICE_GROUP_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { createDeviceGroup, CREATE_DEVICE_GROUP }: any = createTypedAction(
  `${PREFIX}CREATE_DEVICE_GROUP`,
  ['groupName', yup.string().required()],
  ['deviceIds', yup.immutable().list()],
);

export const { createDeviceGroupSuccess, CREATE_DEVICE_GROUP_SUCCESS }: any = createTypedAction(
  `${PREFIX}CREATE_DEVICE_GROUP_SUCCESS`,
  ['groupName', yup.string().required()],
  ['deviceIds', yup.immutable().list()],
);

export const { createDeviceGroupFailed, CREATE_DEVICE_GROUP_FAILED }: any = createTypedAction(
  `${PREFIX} CREATE_DEVICE_GROUP_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { fetchDeviceGroup, FETCH_DEVICE_GROUP }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUP`,
  ['groupId', yup.string().required()],
);

export const { fetchDeviceGroupSuccess, FETCH_DEVICE_GROUP_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_GROUP_SUCCESS`,
  ['group', yup.immutable().map()],
);

export const { fetchDeviceGroupFailed, FETCH_DEVICE_GROUP_FAILED }: any = createTypedAction(
  `${PREFIX} FETCH_DEVICE_GROUP_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
