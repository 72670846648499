import { List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as integrationActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  integrations: Map(),
  fields: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('integrations', Map())
  );
}

export const integrationReducer = createTypedReducer(initialState, {
  [integrationActions.FETCH_INTEGRATIONS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['integrations', 'loading'], true)
    );
  },

  [integrationActions.FETCH_INTEGRATIONS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('integrations', Map({
        loading: false,
        data: action.integrations
      }))
    );
  },

  [integrationActions.FETCH_INTEGRATIONS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['integrations', 'loading'], false)
    );
  },

  [integrationActions.FETCH_INTEGRATION_FIELDS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['fields', 'loading'], true)
    );
  },

  [integrationActions.FETCH_INTEGRATION_FIELDS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('fields', Map({
        loading: false,
        data: action.integrations
      }))
    );
  },

  [integrationActions.FETCH_INTEGRATION_FIELDS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['fields', 'loading'], false)
    );
  },


  [integrationActions.ADD_INTEGRATION_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['integrations', 'data'], nextState.getIn(['integrations', 'data'], List()).push(action.integration))
    );
  },

  [integrationActions.UPDATE_INTEGRATION_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['integrations', 'data'], List())
        .findIndex( (item) => item.get('id') === action.integration.get('id') );
      return (index !== -1) ? nextState.setIn(['integrations', 'data', index], action.integration) : nextState;
    });
  },

  [integrationActions.DELETE_INTEGRATION_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['integrations', 'data'], List())
        .findIndex( (item) => item.get('id') === action.integrationId );
      return (index !== -1) ? nextState.deleteIn(['integrations', 'data', index]) : nextState;
    });
  },


  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default integrationReducer;
