import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const getWorkTicketState = (state) =>
  state.workTicket;

export const getNewWorkTickets = createSelector(
  [getWorkTicketState],
  (workTicket) => {
    if (!workTicket || !workTicket.getIn) {
      return List();
    }
    const allData = workTicket?.getIn(['workTickets', 'data'], List());
    const newWorkTicketIds = workTicket?.getIn(['newWorkTickets', 'records'], List());
    const newWorkTicketData = [];
    newWorkTicketIds.map((id) => {
      newWorkTicketData.push(allData.getIn([id, 'record']));
    });
    return fromJS(newWorkTicketData);
  }
);

export const getWorkTickets = createSelector(
  [getWorkTicketState],
  (workTicket) => {
    if (!workTicket || !workTicket.getIn) {
      return List();
    }

    const allData = workTicket?.getIn(['workTickets', 'data'], List());
    const pageIds = workTicket?.getIn(['page', 'records'], List());
    const pageData = [];
    pageIds.map((id) => {
      pageData.push(allData.getIn([id, 'record']));
    });
    return fromJS(pageData);
  }
);

export const getLoadingWorkTickets = createSelector(
  [getWorkTicketState],
  (workTicket) => {
    if (!workTicket || !workTicket.getIn) {
      return false;
    }
    return workTicket.getIn(['page', 'loading'], false);
  }
);

export const getNumWorkTicketPages = createSelector(
  [getWorkTicketState],
  (workTicket) => {
    if (!workTicket || !workTicket.getIn) {
      return 0;
    }
    return workTicket.getIn(['page', 'totalPages'], 0); /// ??
  }
);

export const getTotalWorkTickets = createSelector(
  [getWorkTicketState],
  (workTicket) => {
    if (!workTicket || !workTicket.getIn) {
      return 0;
    }

    return workTicket.getIn(['page', 'total'], 0);
  }
);
export const getAllWorkTickets = createSelector(
  [getWorkTicketState],
  (workTicket) => {
    return workTicket.getIn(['workTickets', 'data'], 0);
  }
);
