import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';

import Button from 'src/component/UI/Button';
import AcceptIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TextField from '@mui/material/TextField';

export default function ConfirmModal (props) {

  const {
    handleConfirm,
    handleReject,
    handleClose,
    open,
    message,
    title,
    confirmPhrase,
    noCancelOption
  } = props;

  const [userConfirmInput, setUserConfirmInput] = useState('');

  const onHandleAccept = (values) => {
    setUserConfirmInput('');
    if (handleConfirm) {
      handleConfirm(values);
    }
  };

  const onHandleReject = (values) => {
    setUserConfirmInput('');
    if (handleReject) {
      handleReject(values);
    }
  };

  const onUserConfirmPhraseUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserConfirmInput(event.target.value);
  };

  return (
    <Modal
      data-testid='confirm-modal'
      open={open}
      onClose={handleClose}
      aria-labelledby='confirm-modal-title'
    >
      <div className='modal confirm-modal'>
        <h2 id='confirm-modal-title'>{title || 'Confirmation'}</h2>
        <p>{message || 'Are you sure?'}</p>
        {confirmPhrase ?
          <TextField id='confirmPhraseInput' name='confirmPhraseInput' label={`Type "${confirmPhrase}" below to confirm`}  type={'input'}
            onChange={onUserConfirmPhraseUpdated} value={userConfirmInput} InputLabelProps={{ shrink: false }} />
          : null
        }
        <div className='button-bar'>
          {noCancelOption ? null : <Button variant="contained" onClick={onHandleReject}>Cancel</Button>}
          <Button
            variant="contained"
            onClick={onHandleAccept}
            icon={<AcceptIcon style={{ fontSize: 14 }} />}
            disabled={confirmPhrase && userConfirmInput !== confirmPhrase}
            cta>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  confirmPhrase: PropTypes.string,
  noCancelOption: PropTypes.bool
};

ConfirmModal.defaultProps = {
  noCancelOption: false
};
