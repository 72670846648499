import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/navigation/';

export const { pushHistory, PUSH_HISTORY }: any = createTypedAction(
  `${PREFIX}PUSH_HISTORY`,
  ['destination', yup.string().required()]
);

export const { navigate, NAVIGATE }: any = createTypedAction(
  `${PREFIX}NAVIGATE`,
  ['destination', yup.string().required()]
);

export const { replaceHistory, REPLACE_HISTORY }: any = createTypedAction(
  `${PREFIX}REPLACE_HISTORY`,
  ['destination', yup.string().required()]
);


export const { goBackInHistory, GO_BACK_IN_HISTORY }: any = createTypedAction(
  `${PREFIX}GO_BACK_IN_HISTORY`,
);
