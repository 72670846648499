import { List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as ruleActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({
  rules: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('rules', Map())
  );
}

export const ruleReducer = createTypedReducer(initialState, {
  [ruleActions.FETCH_RULES] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['rules', 'loading'], true)
    );
  },

  [ruleActions.FETCH_RULES_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('rules', Map({
        loading: false,
        data: action.rules
      }))
    );
  },

  [ruleActions.FETCH_RULES_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['rules', 'loading'], false)
    );
  },

  [ruleActions.UPDATE_RULE_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['rules', 'data'], action.rules)
    );
  },

  [ruleActions.UPDATE_RULE] (state, action) {
    return state.withMutations((nextState) => {
      const updatedRules = nextState.getIn(['rules', 'data']).map(rule => {
        if (rule.get('id') === action.ruleId) {
          // merge with updated rule
          return rule.merge(action.rule);
        }
        return rule;
      });
      return nextState.setIn(['rules', 'data'], updatedRules);
    });
  },

  [ruleActions.ADD_RULE_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['rules', 'data'], nextState.getIn(['rules', 'data'], List()).push(action.rule))
    );
  },

  [ruleActions.DELETE_RULE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['rules', 'data'], List())
        .findIndex( (item) => item.get('id') === action.ruleId );
      return (index !== -1) ? nextState.deleteIn(['rules', 'data', index]) : nextState;
    });
  },

  [ruleActions.UPDATE_RULE_CONDITION_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['rules', 'data'], List())
        .findIndex( (item) => item.get('id') === action.rule.get('id') );
      return (index !== -1) ? nextState.setIn(['rules', 'data', index], action.rule) : nextState;
    });
  },

  [ruleActions.ADD_RULE_CONDITION_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['rules', 'data'], List())
        .findIndex( (item) => item.get('id') === action.rule.get('id') );
      return (index !== -1) ? nextState.setIn(['rules', 'data', index], action.rule) : nextState;
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default ruleReducer;
