import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as permissionsApi from 'src/service/api/permissions';
import * as permissionsActions from './action';

import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * permissionRoot () {
  yield takeLatest(permissionsActions.FETCH_PERMISSIONS, fetchPermissions);

  function * fetchPermissions (action) {
    try {
      const permissions = yield call(permissionsApi.getPermissions, action);

      yield put(permissionsActions.fetchPermissionsSuccess(permissions));
    } catch (err: any) {
      toast.error(createUserFriendlyErrorMessage(err, 'Error fetching Permissions!'), err.response.status);
      yield put(permissionsActions.fetchPermissionsFailed(Map(err)));
    }
  }
}

export default permissionRoot;
