import React from 'react';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import NotesList  from 'src/component/UI/NotesList';
import UnitSelector from 'src/component/UI/UnitSelector';
import { fetchNotes } from 'src/module/note/action';
import { getNotes, getLoadingNotes, getNumNotesPages, getTotalNotes } from 'src/module/note/selector';
import { isWhitelabel } from 'src/utils/utils';

export default function NoteScreen () {
  const selectors = {
    getNotes: getNotes,
    getLoadingNotes: getLoadingNotes,
    getNumNotesPages: getNumNotesPages,
    getTotalNotes: getTotalNotes
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='loto-screen'>
      <Helmet>
        <title>{title} - Notes</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper>
            <UnitSelector/>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Paper>
        <NotesList
          noteType={'note'}
          selectors={selectors}
          showSubject
          showExpiry
          showAddNoteButton
          showSearchBar
          showArchivedOption
          showDeleteIcon
          fetch={fetchNotes}
        />
      </Paper>
    </div>
  );
}
