import './AboutScreen.scss';

import React from 'react';
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Copyable from 'src/component/UI/Copyable';
import { FormControl, FormLabel } from '@mui/material';
import { Link } from 'react-router-dom';

import { getClient } from 'src/module/authentication/selector';
import { isWhitelabel } from 'src/utils/utils';


export default function AboutScreen () {
  const client = useSelector(getClient);
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page'>
      <Helmet>
        <title>About {title}</title>
      </Helmet>

      <Grid className='settings-pane' item xs={6}>
        <Paper>
          <h2>Your Site</h2>
          <div className='description'>These details may be required by our customer success team to assist with your support requests.</div>
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <div className='value'><Copyable>{client.get('name')}</Copyable></div>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Facility ID</FormLabel>
            <div className='value'><Copyable>{client.get('id')}</Copyable></div>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Release</FormLabel>
            <div className='value'><Copyable>{process.env.REACT_APP_FOX_RELEASE_HASH || 'N/A'}</Copyable></div>
          </FormControl>
        </Paper>
      </Grid>

      <Grid className='settings-pane' item xs={6}>
        <Paper>
          <h2>Legal</h2>
          <div className='description'>
            <span>&copy; 2021-{(new Date().getFullYear())} EHT Group Ltd.</span><br/>
            <span>All rights reserved; Patent Pending</span><br/>
            <a href='https://ehtgroup.com/legal/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a><br/>
            <a href='https://ehtgroup.com/legal/terms' target='_blank' rel='noopener noreferrer'>Terms of Use</a><br/>
            <Link to='/service-agreement'>Service Agreement</Link>
          </div>
        </Paper>
      </Grid>
    </div>
  );
}
