import { take, put, takeEvery, delay } from 'redux-saga/effects';

import toast from 'src/utils/toast';
import * as systemActions from 'src/module/system/action';
import * as devicesActions from 'src/module/device/action';

import * as autobahnActions from 'src/module/autobahn/action';
import * as authenticationActions from 'src/module/authentication/action';

import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export const systemRoot = function* () {
  yield put(autobahnActions.registerChannel('system', channelConsumer));

  yield takeEvery(systemActions.REGISTER_MESSAGE_RESPONSE_HANDLER, registerMessageResponseHandler);
  yield takeEvery(systemActions.REMOVE_MESSAGE_FROM_HANDLERS, removeMessageFromHandlers);
};

const handlers = {};

function* channelHandler (channel) {
  try {
    const { autobahnEvent } = yield take(channel);
    let messageId = null;
    let msgHandlers = null;
    const type = autobahnEvent.get('type');
    switch (type) {
      case 'authenticate':
        yield put(autobahnActions.authenticate());
        break;

      case 'dashboard/subscribed':
        break;

      case 'dashboard/reauthenticate':
        yield put(authenticationActions.refreshAuthToken());
        break;

      case 'system/new-version-available':
        toast.success(`We're constantly improving SmartTrace! Refresh your page to get the latest SmartTrace updates.`);
        break;

      case 'system/logout':
        yield put(authenticationActions.clear());
        toast.error(`Your session has expired, please log in again.`);
        break;

      case 'system/frog-message-success':
      case 'system/frog-message-failed':
        messageId = autobahnEvent.get('messageId');
        if (handlers[messageId]) {
          msgHandlers = (type === 'system/frog-message-success') ? handlers[messageId].successHandlers : handlers[messageId].failureHandlers;
          if (msgHandlers) {
            for (let i = 0; i < msgHandlers.length; i++) {
              const handler = msgHandlers[i];
              if (handler.toast) {
                if (type === 'system/frog-message-success') {
                  toast.success(handler.toast);
                } else {
                  toast.error(createUserFriendlyErrorMessage(autobahnEvent.get('error'), handler.toast), autobahnEvent.get('error')?.response?.status);
                }
              }
              if (handler.params && Array.isArray(handler.params)) {
                yield put(handler.action(...handler.params));
              } else {
                yield put(handler.action());
              }
            }
          }
        }
        delete handlers[messageId];
        break;

      case 'system/update-device-settings-failed':
        messageId = autobahnEvent.getIn(['error', 'response', 'data', 'messageId']);
        if (handlers[messageId]) {
          yield put(devicesActions.updateDeviceSettingsFailed(
            autobahnEvent.get('deviceId'),
            autobahnEvent.getIn(['error', 'response', 'data', 'message']),
            autobahnEvent.getIn(['error', 'response', 'data', 'messageId'])
          ));
          yield put(devicesActions.upgradeDeviceFailed(
            autobahnEvent.get('deviceId')
          ));

          delete handlers[messageId];
        }
        break;

      case 'system/replace-card-failed':
        messageId = autobahnEvent.getIn(['error', 'response', 'data', 'messageId']);

        if (handlers[messageId]) {
          toast.error(autobahnEvent.getIn(['error', 'response', 'data', 'message']), autobahnEvent.getIn(['error', 'response', 'status']));
          yield put(devicesActions.stopLoadingSettingsModal(null, autobahnEvent.get('deviceId')));
          delete handlers[messageId];
        }
        break;

      case 'system/message':
        toast.success(autobahnEvent.getIn(['message', 'message']));
        break;

      default:
        console.warn(`Received an unhandled event ${autobahnEvent.get('type')}`, { autobahnEvent: autobahnEvent.toJS() }); // eslint-disable-line no-console
        break;
    }
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
}

function* channelConsumer (channel) {
  while (true) {
    yield channelHandler(channel);
  }
}

function removeMessageFromHandlers (action) {
  delete handlers[action.messageId];
}

function* registerMessageResponseHandler (action) {
  try {
    handlers[action.messageId] = {
      successHandlers: action.successHandlers,
      failureHandlers: action.failureHandlers
    };
    yield delay(action.timeout || 15000);

    // show timeout error if its been too long, this is fail safe since api should send a message for timeouts
    if (typeof handlers[action.messageId] !== 'undefined') {
      const handles = handlers[action.messageId].failureHandlers;
      for (let i = 0; i < handles.length; i++) {
        const handler = handles[i];

        if (handler.params && Array.isArray(handler.params)) {
          yield put(handler.action(...handler.params));
        } else {
          yield put(handler.action());
        }
      }
      if (handles?.length) {
        toast.error(`Unable to receive a response from the SmartTrace Supervisor in a timely manner`);
      }
    }

    // ensure we clean up the handler, if it still exists
    delete handlers[action.messageId];
  }
  catch (err) {
    console.error('Unable to register message response handler', err); // eslint-disable-line no-console
  }
}

export default systemRoot;
