import './GroupedDevicesEditPanelView.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { DataGrid, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import { getDeviceTree } from 'src/module/device/selector';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import Button from 'src/component/UI/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';

import { updatePanel } from 'src/module/device/action';
export default function GroupedDevicesEditPanelView (props) {
  const dispatch = useDispatch();
  const {
    selectedPanel,
    setSelectedPanel,
    setShowCreateNewPanel
  } = props;
  const deviceTree: Map<any, any> = useSelector(getDeviceTree) || Map();
  const deviceTreeData = deviceTree?.get('loading') ? [] : deviceTree?.get('panels') || [];
  const devicesNotInAPanelArray = (deviceTreeData?.filter(x => x.get('panel_tag') === null || typeof x.get('panel_tag') === 'undefined') || Map()).toJS();
  const initalTag = selectedPanel ? selectedPanel.get('tag') : '';
  const [panelTag, setPanelTag] = React.useState(initalTag);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const selected = [];
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const createCleanDeviceInfo = (device) => {
    return {
      id: device.get('id'),
      tag: device.get('tag'),
      type: device.get('type')
    };
  };
  selectedPanel?.get("children", []).forEach(device => {
    devicesNotInAPanelArray.push(createCleanDeviceInfo(device));
    selected.push(device.get('id'));
  });
  useEffect(() => {
    setPanelTag(initalTag);
    setSelectionModel(selected);
  }, [selectedPanel]);



  const save = () => {
    // call api with array and panel info
    dispatch(updatePanel({
      id: selectedPanel ? selectedPanel.get('id') : null,
      tag: panelTag,
      children: selectionModel
    }));

    const children = devicesNotInAPanelArray.filter(x => selectionModel.includes(x.id));
    const childrenMap = children.map(child =>
      Map(Object.entries(child))
    );
    const panel = Map(Object.entries({
      tag: panelTag,
      children: childrenMap,
      type: 'panel'
    }));
    setSelectedPanel(panel);
    // redirect to panel view
    setShowCreateNewPanel(false);
  };
  const updatePanelTag = (value) => {
    setPanelTag(value);
  };
  return (
    <>
      <div className='add-panel'>
        <div className='panel-info'>
          <TextField value={panelTag || ''} onChange={panelTag !== null ? (e) => { updatePanelTag(e.target.value); } : null} label='Panel Tag' />
        </div>
        <div className='table'>
          <DataGrid
            rows={devicesNotInAPanelArray}
            columns={[
              {
                field: 'Tag',
                valueGetter: (params: GridValueGetterParams) =>
                  `${params.row.tag}`,
                width: isMobile ? 150 : 350,
              },
              {
                field: 'Type',
                valueGetter: (params: GridValueGetterParams) =>
                  `${params.row.type}`,
                width: isMobile ? 100 : 250,
              }
            ]}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
        </div>
        <div className='button-row'>
          <Button onClick={() => { setSelectedPanel(null), setShowCreateNewPanel(false); }}>Cancel</Button>
          <Button onClick={save} cta>Save</Button>
        </div>
      </div>
    </>
  );
}
GroupedDevicesEditPanelView.propTypes = {
  selectedPanel: PropTypes.object,
  setSelectedPanel: PropTypes.func,
  setShowCreateNewPanel: PropTypes.func
};

GroupedDevicesEditPanelView.defaultProps = {
  selectedPanel: null,
  setSelectedPanel: null,
  setShowCreateNewPanel: null
};
