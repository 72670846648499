import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchConfiguredSearchFields, FETCH_CONFIGURED_SEARCH_FIELDS }: any = createTypedAction(
  `${PREFIX}FETCH_CONFIGURED_SEARCH_FIELDS`,
  ['table', yup.string().required()]
);

export const { fetchConfiguredSearchFieldsSuccess, FETCH_CONFIGURED_SEARCH_FIELDS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CONFIGURED_SEARCH_FIELDS_SUCCESS`,
  ['configuredSearchFields', yup.immutable().list().required()]
);

export const { fetchConfiguredSearchFieldsFailed, FETCH_CONFIGURED_SEARCH_FIELDS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CONFIGURED_SEARCH_FIELDS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchAllSearchableFields, FETCH_ALL_SEARCHABLE_FIELDS }: any = createTypedAction(
  `${PREFIX}FETCH_ALL_SEARCHABLE_FIELDS`,
  ['table', yup.string().required()]
);

export const { fetchAllSearchableFieldsSuccess, FETCH_ALL_SEARCHABLE_FIELDS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ALL_SEARCHABLE_FIELDS_SUCCESS`,
  ['fields', yup.immutable().map().required()]
);

export const { fetchAllSearchableFieldsFailed, FETCH_ALL_SEARCHABLE_FIELDS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_ALL_SEARCHABLE_FIELDS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateConfiguredSearchFields, UPDATE_CONFIGURED_SEARCH_FIELDS }: any = createTypedAction(
  `${PREFIX}UPDATE_CONFIGURED_SEARCH_FIELDS`,
  ['table', yup.string().required()],
  ['fields', yup.array().of(yup.string())]
);
