import { persistCombineReducers } from 'redux-persist';
import { connectRouter } from 'connected-react-router';

import { reducers } from '../module/rootReducer';
import persistConfig from './persistConfig';

export const createRootReducer = (history, asyncReducers = {}) => {
  const router: any = connectRouter(history);
  return persistCombineReducers(persistConfig, {
    ...reducers,
    ...asyncReducers,
    router: router
  });
};

export default createRootReducer;
