import './UploadStatusTable.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import DataTable from 'src/component/UI/DataTable';
import LinearProgress from '@mui/material/LinearProgress';

export default function UploadStatusTable (props) {
  const { uploadData } = props;

  return (
    <div data-testid='upload-status-table' className='upload-status-table'>
      <DataTable
        columns={[
          {
            id: 'file-upload-name',
            Header: 'File',
            sortable: false,
            accessor: row => row.name
          },
          {
            id: 'file-upload-status',
            Header: 'Status',
            sortable: false,
            accessor: (row: any) => row.status === 'pending' ?
              (<LinearProgress className={row.status} />) :
              row.status === 'success' ?
                (<LinearProgress className={row.status} variant='determinate' value={100} />) :
                row.status === 'rejected' ?
                  'Invalid file type' :
                  row.status === 'failed' ?
                    'Error uploading file' :
                    'Unknown error occurred uploading file'
          }
        ]}
        data={List(uploadData)}
      />
    </div>
  );
}

UploadStatusTable.propTypes = {
  uploadData: PropTypes.array
};

UploadStatusTable.defaultProps = {
  uploadData: []
};
