import { takeLatest, put, call } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as api from 'src/service/api/workTickets';
import * as workTicketActions from './action';
import toast from 'src/utils/toast';
import moment from 'moment';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';


export function * workTicketRoot () {
  yield takeLatest(workTicketActions.FETCH_NEW_WORK_TICKETS, fetchNewWorkTickets);
  yield takeLatest(workTicketActions.FETCH_WORK_TICKETS, fetchWorkTickets);
  yield takeLatest(workTicketActions.UPDATE_WORK_TICKET, updateWorkTicket);
}

function * fetchNewWorkTickets (action) {
  try {
    const workTickets = yield call(api.getWorkTickets, action, moment(action.since).utc().format(), null, true);
    yield put(workTicketActions.fetchNewWorkTicketsSuccess(workTickets.get('data', List())));
  } catch (err) {
    yield put(workTicketActions.fetchNewWorkTicketsFailed(Map(err)));
  }
}

function * fetchWorkTickets (action) {
  try {
    const workTickets = yield call(api.getWorkTickets, action, null, null, action.includeCompleted);
    yield put(workTicketActions.fetchWorkTicketsSuccess(workTickets.get('data', List()), workTickets.get('total'), workTickets.get('totalPages', 1)));
  } catch (err) {
    yield put(workTicketActions.fetchWorkTicketsFailed(Map(err)));
  }
}

function * updateWorkTicket (action) {
  try {
    const newWorkTickets = yield call(api.updateWorkTicket, action, action.id, action.workTicket);
    yield put(workTicketActions.updateWorkTicketSuccess(newWorkTickets));

    if (action.workTicketFetchObj) {
      const includeCompleted = action.workTicketFetchObj.includeCompleted;
      const unit = action.workTicketFetchObj.unit;
      const page = action.workTicketFetchObj.page;
      const pageSize = action.workTicketFetchObj.pageSize;
      const filter = action.workTicketFetchObj.filter;
      const sort = action.workTicketFetchObj.sort;

      yield put(workTicketActions.fetchWorkTickets(includeCompleted, unit, page, pageSize, filter, sort));
    }
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating workTicket'), err.response.status);
    yield put(workTicketActions.updateWorkTicketFailed(Map(err)));
  }
}

export default workTicketRoot;
