import './NavBar.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { getAuthenticatedUser } from 'src/module/authentication/selector';
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from 'src/component/UI/Button';
import MobileDrawer from "@mui/material/Drawer";
import PropTypes from 'prop-types';
import AdminNavBar from 'src/component/UI/NavBar/AdminNavBar';
import { useState } from 'react';
import variables from 'src/style/variable/variables.module.scss';
import SideNavBar from './SideNavBar';

export default function NavBar (props) {
  const {
    adminBar
  } = props;
  const user = useSelector(getAuthenticatedUser);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);

  const [openDesktop, setOpenDesktop] = useState(user.getIn(['preferences', 'navbarOpen'], true));
  const [openMobile, setOpenMobile] = useState(false);

  const handleDrawerToggleDesktop = () => {
    setOpenDesktop(!openDesktop);
  };
  const handleDrawerToggleMobile = () => {
    setOpenMobile(!openMobile);
  };

  return (
    <Box id='navbar' sx={{ display: 'flex' }}  data-testid='navbar'>
      {isMobile ? (
        <>
          <Button className='button-medium' onClick={handleDrawerToggleMobile} color={adminBar ? 'white' : 'primary'} icon={<MenuIcon style={{ fontSize: 32 }} />}></Button>
          <MobileDrawer
            anchor='left'
            open={openMobile}
            onClose={handleDrawerToggleMobile}
            variant='temporary'
            sx={{
              '& .MuiDrawer-paper': {
                padding: 0,
                backgroundColor: variables.primaryFlat
              }
            }}
          >
            {adminBar ? <AdminNavBar onToggleDrawer={handleDrawerToggleMobile} /> : <SideNavBar onToggleDrawer={handleDrawerToggleMobile} />}
          </MobileDrawer>
        </>
      ) : (
        <MuiDrawer
          anchor={"left"}
          open={openDesktop}
          variant="permanent"
          className={openDesktop ? 'navbar-drawer open' : 'navbar-drawer closed'}
        >
          {adminBar ? <AdminNavBar onToggleDrawer={handleDrawerToggleDesktop} /> : <SideNavBar onToggleDrawer={handleDrawerToggleDesktop} />}
        </MuiDrawer> )}
    </Box>
  );
}

NavBar.propTypes = {
  adminBar: PropTypes.bool
};

NavBar.defaultProps = {
  adminBar: false
};
