import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchRules, FETCH_RULES }: any = createTypedAction(
  `${PREFIX}FETCH_RULES`
);

export const { fetchRulesSuccess, FETCH_RULES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_RULES_SUCCESS`,
  ['rules', yup.immutable().list().required()]
);

export const { fetchRulesFailed, FETCH_RULES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_RULES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);



export const { updateRule, UPDATE_RULE, updateRuleQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_RULE`,
  (state) => {
    const label = state.rule.get('alarm_group') ? 'alarm group' : 'criticality';
    return `update rule ${label} = ${state.rule.get('alarm_group') ?? state.rule.get('criticality')} `;
  },
  ['ruleId', yup.string().required()],
  ['rule', yup.immutable().map().required()]
);

export const { updateRuleSuccess, UPDATE_RULE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_RULE_SUCCESS`,
  ['rules', yup.immutable().list().required()]
);

export const { updateRuleFailed, UPDATE_RULE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_RULE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);



export const { addRule, ADD_RULE }: any = createTypedAction(
  `${PREFIX}ADD_RULE`,
  ['rule', yup.immutable().map().required()]
);

export const { addRuleSuccess, ADD_RULE_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_RULE_SUCCESS`,
  ['rule', yup.immutable().map().required()]
);

export const { addRuleFailed, ADD_RULE_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_RULE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { deleteRule, DELETE_RULE, deleteRuleQueueFormatter }: any = createTypedAction(
  `${PREFIX}DELETE_RULE`,
  () => {
    return `delete rule`;
  },
  ['ruleId', yup.immutable().map().required()]
);

export const { deleteRuleSuccess, DELETE_RULE_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_RULE_SUCCESS`,
  ['ruleId', yup.immutable().map().required()]
);

export const { deleteRuleFailed, DELETE_RULE_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_RULE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { updateRuleCondition, UPDATE_RULE_CONDITION, updateRuleConditionQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_RULE_CONDITION`,
  (state) => {
    return `update condition ${state.ruleCondition.get('property')} to  '${state.ruleCondition.get('value') ?? state.ruleCondition.get('comparison')}'`;
  },
  ['ruleId', yup.string().required()],
  ['ruleConditionId', yup.string().required()],
  ['ruleCondition', yup.immutable().map().required()]
);

export const { updateRuleConditionSuccess, UPDATE_RULE_CONDITION_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_RULE_CONDITION_SUCCESS`,
  ['rule', yup.immutable().map().required()]
);

export const { updateRuleConditionFailed, UPDATE_RULE_CONDITION_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_RULE_CONDITION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);



export const { addRuleCondition, ADD_RULE_CONDITION, addRuleConditionQueueFormatter }: any = createTypedAction(
  `${PREFIX}ADD_RULE_CONDITION`,
  (state) => {
    return `add new condition: ${state.ruleCondition.get('property')} = '${state.ruleCondition.get('value')}'`;
  },
  ['ruleId', yup.string().required()],
  ['ruleCondition', yup.immutable().map().required()]
);

export const { addRuleConditionSuccess, ADD_RULE_CONDITION_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_RULE_CONDITION_SUCCESS`,
  ['rule', yup.immutable().map().required()]
);

export const { addRuleConditionFailed, ADD_RULE_CONDITION_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_RULE_CONDITION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
