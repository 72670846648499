export const MODEL = {
  USER: 'User',
  CLIENT: 'Client'
};

export const USER_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated'
};
