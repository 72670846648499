import { request } from '../api';

export const getCheckSheetTasks = (action) => {
  const payload: any = {
    url: `/check-sheets/tasks/${action.checkSheetType}`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const saveCheckSheet = (action) => {
  const payload: any = {
    url: `/check-sheets/report`,
    options: {
      method: 'POST',
      data: action.data
    }
  };

  return request(action, { payload });
};

export const updateCheckSheet = (action) => {
  const payload: any = {
    url: `/check-sheets/report`,
    options: {
      method: 'PATCH',
      data: action.data
    }
  };

  return request(action, { payload });
};

export const fetchCheckSheet = (action) => {
  const payload: any = {
    url: `/check-sheets/report/${action.checkSheetId}`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const fetchDeviceCheckSheets = (action) => {
  const paginationDetails = action.paginationDetails;
  const {
    page,
    pageSize,
    sort
  } = paginationDetails;
  const query: any = {};
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }

  const payload: any = {
    url: `/check-sheets/reports/${action.checkSheetType}/${action.deviceId}`,
    query,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};
