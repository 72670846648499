import './MinorStat.scss';

import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import ToolTip from '../ToolTip';
export default function MinorStat (props) {
  const {
    className,
    title,
    stat,
    helpText,
    style
  } = props;


  return (
    <div data-testid='minor-stat' className={classNames('minor-stat', className)} >
      <div className='stat-title-container' style={(style && style.container) ? style.container : null}>
        <div className='stat-title' style={(style && style.title) ? style.title : null} >{title}</div>
        {helpText ? (<>
          &nbsp;
          {helpText ? (<ToolTip helpText={helpText} ></ToolTip>) : null}
        </>) : null}
      </div>
      <div className='stat' style={(style && style.stat ) ? style.stat : null}>{stat}</div>
    </div>
  );
}

MinorStat.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  stat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]).isRequired,
  helpText: PropTypes.string,
  style: PropTypes.object
};

MinorStat.defaultProps = {
  className: '',
  helpText: '',
  stat: 'N/A',
  style: null
};
