import { Map, List } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as circuitActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import moment from 'moment';

export const initialState = Map({
  circuits: Map(),
  forcedOnCircuits: Map({
    data: List()
  }),
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('circuits', Map())
      .set('forcedOnCircuits', Map({
        data: List()
      }))
  );
}

export const circuitReducer = createTypedReducer(initialState, {
  [circuitActions.FETCH_CIRCUITS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['circuits', 'loading'], true)
    );
  },

  [circuitActions.FETCH_CIRCUITS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const ids = action.circuits ? action.circuits.map(x => x.get('id')) : List();
      nextState.set('circuits', Map({
        data: ids,
        total: action.total,
        totalPages: action.totalPages,
        loading: false
      }));
    });
  },

  [circuitActions.FETCH_CIRCUITS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['circuits', 'loading'], false)
    );
  },

  [circuitActions.FETCH_FORCED_ON_CIRCUITS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['forcedOnCircuits', 'loading'], true)
    );
  },

  [circuitActions.FETCH_FORCED_ON_CIRCUITS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('forcedOnCircuits', Map({
        data: action.forcedOnCircuits,
        total: action.total,
        totalPages: action.totalPages,
        loading: false
      }))
    );
  },

  [circuitActions.FETCH_CIRCUITS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['forcedOnCircuits', 'loading'], false)
    );
  },

  [circuitActions.UPDATE_FORCED_ON_CIRCUIT_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const forcedOnCircuitId = action.forcedOnCircuit.get('id');
      const index = nextState.getIn(['forcedOnCircuits', 'data'], List()).findIndex(item => item.get('id') === forcedOnCircuitId);
      return nextState.setIn(['forcedOnCircuits', 'data', index], action.forcedOnCircuit);
    });
  },

  [circuitActions.ADD_FORCED_ON_CIRCUIT_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      return nextState.updateIn(['forcedOnCircuits', 'data'], arr => arr.push(action.forcedOnCircuit));
    });
  },

  [circuitActions.ADD_CIRCUIT_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.circuit && action.circuit.get('id')) {
        nextState = nextState.updateIn(['circuits', 'data'], arr => arr.push(action.circuit.get('id')));
      }
      return nextState;
    });
  },

  [circuitActions.DELETE_CIRCUIT_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['circuits', 'data'], List())
        .findIndex( (item) => item === action.circuitId );
      const total = nextState.getIn(['circuits', 'total']);
      nextState = (index !== -1) ? nextState.deleteIn(['circuits', 'data', index]) : nextState;
      return nextState.setIn(['circuits', 'total'], (total - 1) > 0 ? total - 1 : 0);
    });
  },

  [circuitActions.DELETE_CIRCUITS_OF_CONTROLLER] (state, action) {
    return state.withMutations((nextState) => {
      const circuitIds = action.circuits?.map(x => x.get('id'));
      const newList = nextState.getIn(['circuits', 'data'], List()).filter(x => !circuitIds?.includes(x));
      nextState.setIn(['circuits', 'data'], newList);
      return nextState;
    });
  },

  [circuitActions.INVALIDATE_ARCHIVED_FORCED_ON_CIRCUIT] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['forcedOnCircuits', 'data',  action.rowId, 'invalidated'], true)
    );
  },

  [circuitActions.FETCH_CIRCUITS_EXPORT] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['exportCircuits', 'loading'], true);
    });
  },

  [circuitActions.FETCH_CIRCUITS_EXPORT_SUCCESS] (state, action) {
    const circuits = action.exportCircuits;

    return state.withMutations((nextState) => {
      const now = moment();
      const today = now.format('YYYY-MM-DD');

      const blob = new Blob([circuits], { type: 'text/csv;charset=utf-8,' });
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', objUrl);
      link.setAttribute('download', `${today}-${action.facilityName}-circuits.csv`);
      link.click();
      return nextState.setIn(['exportCircuits', 'loading'], false)
        .setIn(['exportCircuits', 'data'], circuits);
    });
  },

  [circuitActions.FETCH_CIRCUITS_EXPORT_FAILED] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['exportCircuits', 'loading'], false);
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default circuitReducer;
