import './ResponsiveToggleGroup.scss';

import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import { ToggleButtonGroup, ToggleButton, Badge } from '@mui/material';
import variables from 'src/style/variable/variables.module.scss';
import PropTypes from 'prop-types';
import Button from 'src/component/UI/Button';
import FilterAlt from '@mui/icons-material/FilterAlt';
import Filters from './Filters';
import { useDispatch, useSelector } from 'react-redux';
import { getNumberOfFiltersApplied, getSelectedUnit } from 'src/module/filter/selector';
import { fetchUnits, setSelectedUnit } from 'src/module/filter/action';
import { getClient } from 'src/module/authentication/selector';

export default function ResponsiveToggleGroup (props) {
  const {
    mainLabel,
    buttons,
    page
  } = props;
  const dispatch = useDispatch();
  const client = useSelector(getClient);
  useEffect(() => {
    dispatch(fetchUnits());
  }, [client.get('id')]);
  const [width, setWidth] = useState(0);
  const [openFilters, setOpenFilters] = useState(false);

  const selectedUnit = useSelector(getSelectedUnit);
  const numberOfFiltersApplied = useSelector(getNumberOfFiltersApplied);
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  const renderUnitToggleButton = (button) => {
    if (button === 'All') {
      return (<ToggleButton key={button} value=''>{button}</ToggleButton>);
    } else if (button !== null) {
      return (<ToggleButton key={button} value={button}>{button}</ToggleButton>);
    }
  };


  const toggleFilterOptions = () => {
    setOpenFilters(!openFilters);
  };

  const buttonGroups: any = [[mainLabel]];

  const maxPerLine = Math.ceil((width - 307) / 50);
  let i = 1, index = 0;
  const totalButtons = buttons.size + 1;
  const numLines = (maxPerLine > 0) ? Math.ceil(totalButtons / maxPerLine) : totalButtons;
  const groupSize = Math.ceil(totalButtons / numLines);

  buttons.forEach((val) => {
    if ((i % groupSize) === 0) {
      index++;
      buttonGroups[index] = [];
    }

    buttonGroups[index].push(val.get('unit'));

    i++;
  });

  const badgeStyle = {
    ".MuiBadge-badge": {
      color: variables.primarySolid,
      backgroundColor: variables.warning,
      fontSize: '14px',
      fontWeight: 'bold'
    }
  };
  const handleUnitChange = (event, unit) => {
    dispatch(setSelectedUnit(unit));
  };

  return (<><div data-testid='responsive-toggle-group' className='responsive-toggle-group'>
    <div className="unit-buttons">
      {buttonGroups.map((unitGroup) => (
        <ToggleButtonGroup key={unitGroup} size='medium' value={selectedUnit} exclusive onChange={handleUnitChange}>
          {unitGroup.map((val) => renderUnitToggleButton(val))}
        </ToggleButtonGroup>
      ))}
    </div>
    <div className='filter-button'>
      <Badge className='responsive-toggle-group-badge' badgeContent={numberOfFiltersApplied} sx={badgeStyle}>
        <Button className='responsive-toggle-group-filter-button' variant="contained" cta onClick={toggleFilterOptions} icon={<FilterAlt style={{ fontSize: 14 }} />}></Button>
      </Badge>
    </div>


  </div>
  <div className="filter-group">
    {openFilters ? <Filters showUnit={false} page={page} /> : ''}
  </div>
  </>
  );
}

ResponsiveToggleGroup.propTypes = {
  buttons: PropTypes.object,
  mainLabel: PropTypes.string,
  page: PropTypes.string
};

ResponsiveToggleGroup.defaultProps = {
  buttons: List(),
  mainLabel: 'All',
  page: ''
};
