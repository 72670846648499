import './ViewportLayout.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ViewportLayout = (props) => {
  const {
    style,
    className,
    children
  } = props;

  return (
    <main
      style={Object.assign({}, style)}
      className={classNames(className, 'viewport-layout')}
    >{children}</main>
  );
};

ViewportLayout.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
};

ViewportLayout.defaultProps = {
  style: {}
};

ViewportLayout.whyDidYouRender = true;

export default React.memo(ViewportLayout);
