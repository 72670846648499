import './BatchSchedulesList.scss';
import React, { useEffect, useState } from 'react';
import DataTable from 'src/component/UI/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import { getBatchSchedules, getLoadingBatchSchedules, getNumBatchSchedulesPages, getTotalBatchSchedules } from 'src/module/batchSchedule/selector';
import { fetchBatchSchedules } from 'src/module/batchSchedule/action';
import CategoryIcon from '@mui/icons-material/Category';

import DeviceLinkCombo from 'src/component/UI/DeviceLinkCombo';
import { formatDate } from 'src/utils/utils';
import { Box, Paper, Tab, Tabs, Tooltip } from '@mui/material';
import { pushHistory } from 'src/module/navigation/action';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useParams } from 'react-router';
import AllRecipes from './Recipes/AllRecipes';
import CreateGroupModal from 'src/screen/Protected/DeviceGroupsScreen/CreateGroupModal';
import DeviceGroupsList from 'src/screen/Protected/DeviceGroupsScreen/DeviceGroupsList';
export default function BatchSchedulesList (props) {
  const {
    pagination
  } = props;
  const params = useParams();
  const dispatch: any = useDispatch();
  const [tabValue, setTabValue] = useState(params.tab ?? 'scheduled');

  const batchSchedules: any = useSelector(getBatchSchedules(tabValue)) || [];
  const loading: any = useSelector(getLoadingBatchSchedules);
  const numPages: any = useSelector(getNumBatchSchedulesPages(tabValue));
  const totalBatchSchedules: any = useSelector(getTotalBatchSchedules(tabValue));
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filter, setFilter] = React.useState(null);
  const [sort, setSort] = React.useState(null);

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  useEffect(() => {
    if (params.tab !== tabValue) {
      setTabValue(params.tab ?? 'scheduled');
    }
  }, [params.tab]);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  useEffect(() => {
    if (tabValue === 'pending' || tabValue === 'completed' || tabValue === 'scheduled') {
      const paginationDetails = { page, pageSize, filter, sort };
      dispatch(fetchBatchSchedules(paginationDetails, tabValue));
    }

  }, [dispatch, page, pageSize, filter, sort, tabValue]);

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setFilter(_filter);
    setSort(_sort);
  };
  const overallStatusStyle = (batchStatus) => {
    if (!batchStatus) {
      return '-';
    }
    if (!batchStatus.completed_at) {
      return <Tooltip title='Running...'><PendingActionsIcon color="primary" style={{ fontSize: 25 }}/></Tooltip>;
    } else {
      if (batchStatus.status.get('hasErrors')) {
        return <Tooltip title='Completed with Errors'>
          <ErrorIcon color="error" style={{ fontSize: 25 }}/>
        </Tooltip>;
      } else {
        return <Tooltip title='Completed Successfully'>
          <CheckCircleOutlineIcon color="success" style={{ fontSize: 25 }}/>
        </Tooltip>;
      }
    }

  };

  const devicesCol = (devices, showAll = false) => {
    if (!devices || !devices.size) {
      return [];
    }
    if (devices.size > 10  && !showAll) {
      const first10 = devices.slice(0, 10);
      return <>
        {first10.map((device, index) => (<div key={index} className='alarm-list'><DeviceLinkCombo device={device} /></div>))}
        <>... plus {devices.size-10} more</>
      </>;
    }
    return devices.map((device, index) => (<div key={index} className='alarm-list'><DeviceLinkCombo device={device} /></div>));
  };
  const onClickOpenModelToCreateGroup = (event, index) => {
    event.stopPropagation();
    setSelectedRowIndex(index);
    setShowCreateGroupModal(true);
  };

  const columnOptions: any = [
    {
      id: 'devices',
      Header: 'Devices',
      accessor: (row: any) => row.get('devices'),
      Cell: (row: any) => devicesCol(row.cell.value),
      disableSortBy: true
    },
    {
      id: 'recipes.name',
      Header: 'Template',
      accessor: (row: any) => row?.getIn(['recipe_name']) || '',
      sortable: true
    },
    {
      id: 'scheduled_at',
      Header: 'Scheduled At',
      accessor: (row: any) => row?.getIn(['scheduled_at']),
      Cell: (row: any) => (row.cell.value ? (<>{formatDate(row.cell.value)}</>) : 'N/A'),
      sortable: true,
    }

  ];
  if (tabValue === 'pending') {
    columnOptions.push({
      id: 'started_at',
      Header: 'Started At',
      accessor: (row: any) => row?.get('started_at') ? moment(row.get('started_at')).format('YYYY-MM-DD') : '',
    });
  }

  if (tabValue === 'completed') {
    columnOptions.push({
      id: 'completed_at',
      Header: 'Completed At',
      accessor: (row: any) => row?.get('completed_at') ? moment(row.get('completed_at')).format('YYYY-MM-DD') : '',
    });
    columnOptions.push({
      id: 'status',
      Header: 'Status',
      accessor: (row: any) => row?.get('status') ?? '',
      Cell: (row: any) => (row.cell.value ? overallStatusStyle(row.cell.row.values) : ''),
    });
  }
  columnOptions.push({
    id: 'actions',
    accessor: (row: any) => row?.index ?? '',
    Cell: (row: any) => (<Tooltip title='Create a group for these devices'><div onClick={(e) => onClickOpenModelToCreateGroup(e, row.row.index)}><CategoryIcon color="primary" style={{ fontSize: 25 }} /></div></Tooltip>),
  });

  const handleCellClick = (row) => {
    const record = row.original;
    dispatch(pushHistory(`/program/${record.get('id')}`));
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    dispatch(pushHistory(`/program/list/${newValue}`));

  };
  return (
    <div className='batch-schedule-list' data-testid='batch-schedules-list'>
      <Paper>
        <h2>{'Mass Programming Overview'}</h2>
        <Box sx={{ width: '100%', marginBottom: '50px' }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            textColor='primary'
            indicatorColor='primary'
            aria-label="scheduled/completed tabs"
          >
            <Tab value="scheduled" label={<span className='tab-label-size'>Scheduled</span>} />
            <Tab value="pending" label={<span className='tab-label-size'>In Progress</span>} />
            <Tab value="completed" label={<span className='tab-label-size'>Completed</span>} />
            <Tab value="templates" label={<span className='tab-label-size'>Templates</span>} />
            <Tab value="device-groups" label={<span className='tab-label-size'>Devices Groups</span>} />
          </Tabs>
        </Box>
        {
          tabValue === 'templates' && <AllRecipes />
        }
        { (tabValue === 'pending' || tabValue === 'completed' || tabValue === 'scheduled') &&   <>
          {showRotateMessage ? <DeviceRotate /> :
            <DataTable
              handleCellClick={handleCellClick}
              columns={columnOptions}
              data={batchSchedules}
              pagination={pagination}
              numPages={numPages}
              totalRecords={totalBatchSchedules}
              loading={loading}
              fetchPage={fetchPage}
              tableId='batchScheduleTable'
            />
          }
        </>}
        { tabValue === 'device-groups'  && <DeviceGroupsList showMassProgramButton/>}


      </Paper>
      {<CreateGroupModal handleClose={() => setShowCreateGroupModal(false)} open={showCreateGroupModal} deviceIds={selectedRowIndex  !== null ? batchSchedules.getIn([selectedRowIndex, 'devices'])?.map(x => x.get('id')).toJS(): []}/> }
    </div>
  );
}

BatchSchedulesList.propTypes = {
  filterable: PropTypes.bool,
  pagination: PropTypes.bool,
};

BatchSchedulesList.defaultProps = {
  filterable: true,
  pagination: true,
};
