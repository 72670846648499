import './Button.scss';
import React from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import LoadingSpinner from './LoadingSpinner';
import variables from 'src/style/variable/variables.module.scss';

import { pushHistory } from 'src/module/navigation/action';

export default function Button (props) {
  const {
    className,
    type,
    border,
    children,
    icon,
    medium,
    large,
    onClick,
    compact,
    wide,
    disabled,
    ariaLabel,
    loading,
    color,
    backgroundColor,
    cta
  } = props;

  const iconOnly = !React.Children.count(children);
  const background = border ? props.background : false;
  const displayedIcon = loading ? (<LoadingSpinner />) : icon;
  const displayDisabled = disabled || loading;

  const dispatch = useDispatch();
  const renderButtonIcon = () => (displayedIcon !== null)
    ? (<span className={classNames('button-icon', { 'button-icon-icon-only': iconOnly, 'button-icon-loading': loading })}>{displayedIcon}</span>)
    : null;

  const renderButtonLabel = () => React.Children.count(children)
    ? (<span>{children}</span>)
    : null;

  let clickHandler = null;

  if (props.to) {
    clickHandler = () => {
      dispatch(pushHistory(props.to));
    };
  } else if (onClick) {
    clickHandler = onClick;
  }
  const testId = () => typeof children === 'string' ? `-${children}` : '';

  return (
    <button
      data-testid={`button${testId()}`}
      type={type}
      className={classNames({
        'button-border': border,
        'button-background': background,
        'button-compact': compact,
        'button-large': large,
        'button-medium': medium,
        'button-wide': wide,
        'button-icon-only': iconOnly,
        'button-cta': cta },
      'button',
      className)}
      disabled={displayDisabled}
      aria-label={ariaLabel}
      style={{
        backgroundColor: variables[backgroundColor],
        color: variables[color]
      }}
      onClick={(evnt) => (clickHandler) ? clickHandler(evnt) : null}
    >{renderButtonIcon()}{renderButtonLabel()}</button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  background: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  compact: PropTypes.bool,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  loading: PropTypes.bool,
  cta: PropTypes.bool,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  plain: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]),
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  ref: PropTypes.object,
  to: PropTypes.string
};

Button.defaultProps = {
  className: '',
  type: 'button',
  color: null,
  backgroundColor: null,
  border: false,
  background: true,
  icon: null,
  onClick: null,
  compact: false,
  wide: false,
  disabled: false,
  ariaLabel: '',
  loading: false,
  cta: false,
  medium: false,
  large: false,
  plain: false,
  to: null
};
