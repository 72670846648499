import './ConfigureSearchScreen.scss';

import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Button from 'src/component/UI/Button';
import { langLookUpText } from 'src/utils/langLookUp';
import RowComponent from './RowComponent';

import { fetchConfiguredSearchFields, fetchAllSearchableFields, updateConfiguredSearchFields } from 'src/module/search/action';
import { getConfiguredSearchFields, getAllSearchableFields } from 'src/module/search/selector';
import { isWhitelabel } from 'src/utils/utils';

export default function ConfigureSearchScreen () {

  const dispatch = useDispatch();
  const allSearchableFields = useSelector(getAllSearchableFields);
  const configuredSearchFields = useSelector(getConfiguredSearchFields);

  const [selectedOption, setSelectedOption] = useState('');
  const [rows, setRows] = useState([]);

  const tableLookup = {
    'devices': 'Devices'
  };

  useEffect(() => {
    dispatch(fetchAllSearchableFields('devices'));
    dispatch(fetchConfiguredSearchFields('devices'));
  }, []);

  const updateLabel = (e) => {
    setSelectedOption(e.target.value);
  };

  const addFieldToSearch = (rec) => {
    const table = rec?.get('table_name');
    const field = rec.get('field_name');
    let updatedFields;
    configuredSearchFields.data?.map((values) => {
      updatedFields = values.push(field);
    });
    dispatch(updateConfiguredSearchFields(table, updatedFields));
    setSelectedOption('');
  };

  const handleDeleteSearchField = (table, field) => {
    let updatedFields;
    configuredSearchFields.data?.map((values) => {
      updatedFields = values.filter(val => val !== field);
    });
    dispatch(updateConfiguredSearchFields(table, updatedFields));
  };

  useEffect(() => {
    configuredSearchFields?.data?.map(fields => {
      const tempArray = [];
      fields.map(field => {
        tempArray.push({ field });
      });
      setRows(tempArray);
    });
  }, [configuredSearchFields?.data]);


  const handleDragEnd = (result, table) => {
    if (!result.destination) return;

    const updatedRows = Array.from(rows);
    const [reorderedItem] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, reorderedItem);
    setRows(updatedRows);

    // update ranking of fields
    const sortedFields = [];
    updatedRows?.map(row => {
      sortedFields.push(row.field);
    });
    dispatch(updateConfiguredSearchFields(table, sortedFields));
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='configure-search-screen' data-testid='configure-search-screen'>
      <Helmet>
        <title>{title} - Configure Search</title>
      </Helmet>
      <h2>Configure Search</h2>

      <Grid container spacing={3}>
        {configuredSearchFields?.data?.reduce((acc, values, key) => {
          acc.push(
            <Grid item md={6} key={key}>
              <Paper>
                <h3>{`List of fields included in ${tableLookup[key]} search`}</h3>
                <DragDropContext onDragEnd={(e) => handleDragEnd(e, key)}>
                  <Droppable droppableId="rows">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {
                          rows.length > 0 ? rows.map((row, index) => (
                            <RowComponent key={row.field} row={row} table={key} index={index} handleDeleteSearchField={handleDeleteSearchField}/>
                          )) :
                            <p className='no-data-in-table'>No fields added yet!</p>

                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <br/>
                <h3>{`Add more fields to ${tableLookup[key]} search`}</h3>
                <FormControl className='add-search-field-form'>
                  <InputLabel>
                    Add Field
                  </InputLabel>
                  <div>
                    <Select
                      value={selectedOption}
                      variant="standard"
                      autoWidth={true}
                      sx={{ width: '200px', marginRight: '30px' }}
                      onChange={updateLabel}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                    >
                      {allSearchableFields.filter(allFields => !values.includes(allFields.get('field_name'))).map(field => (
                        <MenuItem key={field} value={field}>{langLookUpText(field.get('field_name'))}</MenuItem>
                      ))}
                    </Select>
                    <Button onClick={() => addFieldToSearch(selectedOption)} disabled={selectedOption === ''} cta>Save</Button>
                  </div>
                </FormControl>
              </Paper>
            </Grid>
          );
          return acc;
        }, [])}
      </Grid>
    </div>
  );
}
