import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'src/style/base.scss';
import 'src/utils/yup';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConnectedRouter as Router } from 'connected-react-router';
import { Provider, ReactReduxContext } from 'react-redux';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';

import rootSaga from 'src/module/rootSaga';
import { getConfiguredStore, history } from 'src/store';
import App from 'src/App';
import LaunchScreen from 'src/screen/Launch/LaunchScreen';
import ErrorBoundary from 'src/ErrorBoundary';
import sentryService from 'src/service/sentry';
import * as authService from 'src/service/auth';
import * as authenticationActions from 'src/module/authentication/action';
import MainTheme from 'src/style/MainTheme';

import configureSentry from 'src/configureSentry';
import { isWhitelabel } from './utils/utils';

(async (container: any) => {
  await Promise.all([
    configureSentry()
  ]);

  const root = createRoot(container);

  const { store, persistor } = await getConfiguredStore();
  const onBeforeLift = async function () {
    try {
      const authToken = await authService.getAccessToken();

      const params = new URLSearchParams(_.get(document, 'location.search', ''));
      if (params.get('jwt') === authToken) {
        return;
      }
      if (authToken === null) {
        return;
      }

      await store.dispatch({
        type: authenticationActions.REHYDRATE,
        [WAIT_FOR_ACTION]: authenticationActions.REHYDRATE_SUCCESS,
        // [ERROR_ACTION]: authenticationActions.REHYDRATE_FAILURE
      });

    } catch (err) {
      sentryService.captureException(err).then(() => {});

      store.dispatch({ type: authenticationActions.CLEAR });
      persistor.purge();
    }
  };

  const injectFavicon = (elementType, rel, href) => {
    const link = document.createElement(elementType);
    if (rel === 'msapplication-TileImage') {
      link.name = rel;
      link.content = href;
    } else {
      link.rel = rel;
      link.href = href;
    }
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  store.runSaga(rootSaga);

  if (process.env.NODE_ENV !== 'production') {
    /* eslint-disable  @typescript-eslint/no-var-requires */
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      // https://github.com/welldone-software/why-did-you-render#options
      onlyLogs: false,
      collapseGroups: false,
      hotReloadBufferMs: 500
    });
  }

  const whitelabel = isWhitelabel();
  if (whitelabel) {
    injectFavicon('link', 'icon', '/favicon-at.ico');
    injectFavicon('link', 'icon', '/atlogo32.png');
    injectFavicon('link', 'icon', '/atlogo192.png');
    injectFavicon('link', 'icon', '/atlogo512.png');
    injectFavicon('link', 'apple-touch-icon-precomposed', '/atlogo192.png');
    injectFavicon('link', 'msapplication-TileImage', '/apple-touch-icon-st.png');
  } else {
    injectFavicon('link', 'icon', '/favicon.ico');
    injectFavicon('link', 'icon', '/logo32.png');
    injectFavicon('link', 'icon', '/logo192.png');
    injectFavicon('link', 'icon', '/logo512.png');
    injectFavicon('link', 'apple-touch-icon-precomposed', '/logo192.png');
    injectFavicon('meta', 'msapplication-TileImage', '/logo192.png');
  }

  root.render(
    <ThemeProvider theme={MainTheme}>
      <Provider store={store}>
        <PersistGate
          loading={<LaunchScreen />}
          persistor={persistor}
          onBeforeLift={onBeforeLift}
        >
          <ErrorBoundary>
            <Router history={history} context={ReactReduxContext}>
              <App />
            </Router>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
})(document.getElementById('root'));
