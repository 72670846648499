import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import ForcedOnCircuitsList from 'src/component/UI/ForcedOnCircuits/ForcedOnCircuitsList';
import variables from 'src/style/variable/variables.module.scss';

export default function ForcedOnContent (props) {
  const {
    deviceId,
  } = props;
  const [collapsed, setCollapsed] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);


  return (
    <Paper data-testid='forcedOn-content'>
      <h3 className='expandable' onClick={() => setCollapsed(!collapsed)}>Forced-on Logs &nbsp;<span className='expand-arrow'>{collapsed ? '▲' : '▼'}</span></h3>
      {collapsed && !showRotateMessage ?
        <ForcedOnCircuitsList
          deviceId={deviceId}
          showTitle={false}
          filterable={false}
          showControllerTag={false}
          showCircuitTag={false}
          showArchivedOption={false}
          showNotificationNumber
          showForcedOnControlMode
          showArchivedAt
          includeArchived
          showDeleteOption
        />

        : ''}

      {collapsed && showRotateMessage ?
        <DeviceRotate/>
        : null}
    </Paper>
  );
}
ForcedOnContent.propTypes = {
  deviceId: PropTypes.string.isRequired
};
ForcedOnContent.defaultProps = {

};
