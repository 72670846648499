import './AddLookupItemModal.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckOutlined';
import Button from 'src/component/UI/Button';

export default function AddLookupItemModal (props) {
  const [key, setKey] = React.useState('');
  const [value, setvalue] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const lookupItem = {
      key,
      value
    };

    props.handleSubmit(lookupItem);

    setvalue('');
    setKey('');
  };

  const {
    title
  } = props;

  return (
    <Modal
      data-testid='add-lookup-item-modal'
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='add-lookup-item-modal-title'
    >
      <div className='modal add-lookup-item-modal'>
        <h2 id='add-lookup-item-modal-title'>Add {title}</h2>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <TextField fullWidth value={key} onChange={(e) => { setKey(e.target.value); }} label='Key' />
          <TextField fullWidth value={value} onChange={(e) => { setvalue(e.target.value); }} label='Value' />
          <div className='button-bar'>
            <Button onClick={props.handleClose} >Cancel</Button>
            <Button type='submit' icon={<CheckCircleIcon style={{ fontSize: 14 }} />} cta>Add Data</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

AddLookupItemModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

AddLookupItemModal.defaultProps = {
};
