import '../AboutScreen/AboutScreen.scss';

import React from 'react';
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Copyable from 'src/component/UI/Copyable';
import { FormControl, FormLabel } from '@mui/material';
import { getTrainingURL, getTrainingPassword } from 'src/module/authentication/selector';
import { isWhitelabel } from 'src/utils/utils';

export default function TrainingScreen () {
  const trainingURL = useSelector(getTrainingURL);
  const trainingPassword = useSelector(getTrainingPassword);

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page'>
      <Helmet>
        <title>{title} Training</title>
      </Helmet>

      <Grid className='settings-pane' item xs={6}>
        <Paper>
          <h2>Your Personalized Training</h2>
          <div className='description'>Use the link and password below to access your {title} training.</div>
          <FormControl fullWidth>
            <FormLabel>Training URL</FormLabel>
            <a className='value' target='_blank' rel='noopener noreferrer' href={trainingURL}>{trainingURL}</a>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Training Password</FormLabel>
            <div className='value'><Copyable>{trainingPassword}</Copyable></div>
          </FormControl>
        </Paper>
      </Grid>

    </div>
  );
}
