import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

export const getFreezeLogState = (state) =>
  state.freezeLog;

export const getRootState = (state) =>
  state;

export const getFreezeLogs = createSelector(
  [getFreezeLogState],
  (freezeLog) => {
    const allData = freezeLog?.getIn(['freezeLogs', 'data'], Map());
    const pageIds = freezeLog?.getIn(['freezeLogs', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return pageData;
  }
);

export const getLoadingFreezeLogs = createSelector(
  [getFreezeLogState],
  (freezeLog) => {
    return freezeLog.getIn(['freezeLogs', 'loading'], false);
  }
);

export const getNumFreezeLogsPages = createSelector(
  [getFreezeLogState],
  (freezeLog) => {
    return freezeLog.getIn(['freezeLogs', 'totalPages'], 0);
  }
);

export const getTotalFreezeLogs = createSelector(
  [getFreezeLogState],
  (freezeLog) => {
    return freezeLog.getIn(['freezeLogs', 'total'], 0);
  }
);
