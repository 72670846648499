
import React, { useState } from 'react';
import './SubscriptionManagmentScreen.scss';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Modal } from '@mui/material';
import classNames from 'classnames';
import HubspotForm from 'react-hubspot-form';
import { isWhitelabel } from 'src/utils/utils';
import moment from 'moment';
import Button from 'src/component/UI/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import { getClient } from 'src/module/authentication/selector';
import { useSelector } from 'react-redux';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import { langLookUpText } from 'src/utils/langLookUp';
import PermissionFence from 'src/component/PermissionFence';

export default function SubscriptionManagementScreen () {
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const whitelabel = isWhitelabel();
  const [open, setOpen] = useState(false);
  const [contactState, setContactState] = useState(0);
  const client = useSelector(getClient);

  return (
    <div className='page'>
      <Helmet>
        <title>Manage License</title>
      </Helmet>



      <Grid item xs={4}>
        <h2>Manage License</h2>

        <Paper>

          <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <p style={{ margin: 0 }}>Current Plan</p>
            <p style={{ fontWeight: 'bolder', fontSize: '30px', margin: 0 }}>{langLookUpText(whitelabel? client.get('tier').replace('smarttrace', 'atcom') : client.get('tier'))}</p>
            {/* if no exipr given put unknown */}
            <p style={{ fontWeight: 'bold' }}>Expiration Date: {client.get('subscription_expiry') ? moment(client.get('subscription_expiry')).format('YYYY-MM-DD'): 'Unknown'}</p>
            {/* status options: trail, expired, active */}
            <p style={{ fontWeight: 'bold' }}>Status: {client.get('subscription_status')}</p>
            {whitelabel ?
              <p>Use the chat in the corner to update your License</p>:
              <>
                <p>View SmartTrace feature list <a style={{ marginTop: '5px' }} target="_blank" href='https://ehtgroup.com/smarttrace/features/' rel="noreferrer"><OpenInNewIcon/></a></p>
                <PermissionFence can={['upgrade-plan']} >
                  <Button onClick={() => setOpen(true)}  cta>Manage License</Button>
                </PermissionFence>
              </>}

          </div>

        </Paper>
      </Grid>

      <Modal
        data-testid='upgade-modal'
        open={open}
        onClose={() => {setOpen(false); setContactState(0);}}
        aria-labelledby='upgrade-modal-title'
      >
        <div  className={classNames('modal', contactState ===0 ? 'change-sub-modal': 'change-sub-modal-after')} >
          {contactState ===0 &&
          <div className='options'>
            <div onClick={() => setContactState(1)}><CalendarMonthIcon style={{ fontSize: 45 }}/><p>Schedule a meeting</p></div>
            <div onClick={() => setContactState(2)}><EmailIcon style={{ fontSize: 45 }}/><p>Send an email</p></div>
            <div onClick={() => setContactState(3)}><CallIcon style={{ fontSize: 45 }}/><p>Get a Call</p></div>
          </div>}
          {contactState ===1 &&
         <div>
           <h2>Schedule a Meeting</h2>
           <iframe
             src="https://app.ehtgroup.com/meetings/randy-hendriks-eht/randy?uuid=b7057cba-a498-4886-be36-193e703095f3"
             width="100%"
             height={isMobile ? "450px":"500px"}
             frameBorder="0"
             allowFullScreen
           ></iframe>
         </div>
          }
          {contactState ===2 &&
            <HubspotForm
              portalId='20172905'
              formId='2dbfaac6-a96b-4e71-b4a1-8c040e8484fe'
              loading={<div>Loading...</div>}
            />
          }
          {contactState ===3 &&
            <HubspotForm
              portalId='20172905'
              formId='b9442913-6083-4ecc-99fa-96820db4b6f2'
              loading={<div>Loading...</div>}
            />
          }

        </div>

      </Modal>

    </div>
  );
}
