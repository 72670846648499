import { put, takeLatest } from 'redux-saga/effects';
import { push, replace, go, goBack } from 'connected-react-router';
import * as navigationActions from '../../module/navigation/action';

const navigate = function * (action) {
  yield put(go(action.destination));
};

const pushHistory = function * (action) {
  yield put(push(action.destination));
};

const replaceHistory = function * (action) {
  yield put(replace(action.destination));
};
const goBackInHistory = function * () {
  yield put(goBack());
};


export const navigationRoot = function * () {
  yield takeLatest(navigationActions.PUSH_HISTORY, pushHistory);
  yield takeLatest(navigationActions.REPLACE_HISTORY, replaceHistory);
  yield takeLatest(navigationActions.NAVIGATE, navigate);
  yield takeLatest(navigationActions.GO_BACK_IN_HISTORY, goBackInHistory);

};
