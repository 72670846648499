import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Modal, Paper, TextField } from "@mui/material";
import PermissionFence from "src/component/PermissionFence";
import Button from 'src/component/UI/Button';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import './DeviceScreenActionButtons.scss';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ForcedOnIcon from '@mui/icons-material/OfflineBolt';
import _ from 'lodash';
import Immutable, { Map, fromJS, List } from 'immutable';

import FileUpload from 'src/component/UI/FileUpload';
import { getSettingsDiffMap, getNumberOfCircuitsOfController, getSettingsModal, getHiddenCircuits, getChildDevices } from 'src/module/device/selector';
import { addCircuit } from 'src/module/circuit/action';
import { unhideCircuit } from 'src/module/device/action';

import { setSettingsModalClose, setSettingsModalOpen, updateDeviceSettings, dispatchDeviceAction, replaceCircuitCard, deleteCommLoop } from 'src/module/device/action';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';
import { getAbilities, getFeatures, getTemperatureUnit } from 'src/module/authentication/selector';
import ConfirmModal from 'src/component/UI/ConfirmModal';
import ForcedOnModal from 'src/component/UI/ForcedOnCircuits/ForcedOnModal';
import NoteModal from 'src/component/UI/NoteModal';
import { addForcedOnCircuit, deleteCircuit } from 'src/module/circuit/action';
import { addNote } from 'src/module/note/action';
import { Badge } from '@mui/material';
import { isLangATemperature, isLangBand, isTextValueAOption } from 'src/utils/langLookUp';
import { deviceTypeConfig } from 'src/utils/deviceTypeConfig';
import { deleteController } from 'src/module/controller/action';
import { useFormik } from 'formik';
import yup from 'src/utils/yup';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import { celsiusToFahrenheitConvertor, convertBand, fahrenheitToCelsiusConvertor, defaultAllowedCircuitTypes } from 'src/utils/utils';

export default function DeviceScreenActionButtons (props) {
  const {
    device,
    deviceConfigFields,
    deviceActions,
    loading
  } = props;
  const dispatch = useDispatch();
  const badgeStyle = {
    "& .MuiBadge-badge": {
      backgroundColor: '#fcd54c',
    }
  };
  const settingsDiff = useSelector(getSettingsDiffMap(device.get('id')));

  const settingsModal = useSelector(getSettingsModal(device.get('id')));
  const hiddenCircuits = useSelector(getHiddenCircuits(device.get('id')));
  const children: any = useSelector(getChildDevices(device.get('id')));


  const temperatureUnit = useSelector(getTemperatureUnit);
  // modal logic
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      circuitAddress: '',
    },
    onSubmit: values => {
      dispatch(replaceCircuitCard(device.get('id'), values.circuitAddress));
    },
    validationSchema: () => {
      return yup.object().shape({
        circuitAddress: yup
          .string('Circuit Address')
          .required('Circuit Address is Required')
      });
    },
  });
  let numDiff = 0;

  const [addCircuitModalOpen, setAddCircuitModalOpen] = useState(false);
  const [forcedOnModalOpen, setForcedOnModalOpen] = useState(false);
  const [initialAdvancedConfigValues, setInitialAdvancedConfigValues] = useState(Map());
  const fileUpload = useMemo(() => device.get('id') ? <FileUpload deviceId={device.get('id')} /> : null, [device.get('id')]);
  const getAllowedCircuitTypes = () => {
    const types = device?.getIn(['settings', 'allowedCircuitTypes']);
    if (types) {
      return types;
    }
    else if (defaultAllowedCircuitTypes[device?.get('device_factory')]) {
      return Immutable.fromJS(defaultAllowedCircuitTypes[device?.get('device_factory')]);
    }
    else {
      return List();
    }
  };

  const allowedCircuitTypes = getAllowedCircuitTypes();


  // forced on functionality
  const toggleForcedOnModal = () => {
    setForcedOnModalOpen(!forcedOnModalOpen);
  };

  const makeHandleAddForcedOnCircuit = () => {
    return (rec: any) => {
      dispatch(addForcedOnCircuit(rec.set('circuit_id', device.get('id'))));
      toggleForcedOnModal();
    };
  };

  // notes functionality
  const toggleNoteModal = () => {
    setNoteModalOpen(!noteModalOpen);
  };
  const handleAddNoteSubmit = (rec: any) => {
    dispatch(addNote(rec.set('device_id', device.get('id'))));
    toggleNoteModal();
  };

  //  advanced settings functionality
  useEffect(() => {
    if (!loading) {
      const advancedConfigSettingsValues = deviceConfigFields.get('settings').map((field, key) => {
        let path = field.get('path', null);
        path = path?.split('.') || [key];
        const pathArray = ['settings'].concat(path);
        if (field?.get('type') === 'switch') {
          if (typeof device.getIn(pathArray) !== 'boolean') {
            return device.getIn(pathArray) === 0 ? false : true;
          }
          return device.getIn(pathArray);
        } else if (isLangBand(key)) {
          return convertBand(device.getIn(pathArray, null), temperatureUnit);
        } else if (isLangATemperature(key) && !field.get('hidden')) {
          return temperatureUnit === 'F' && device.getIn(pathArray) !== 'null' ? celsiusToFahrenheitConvertor(device.getIn(pathArray)) : device.getIn(pathArray);
        }
        else {
          return field.get('hidden') ? '' : (device.getIn(pathArray));
        }
      });
      setInitialAdvancedConfigValues(advancedConfigSettingsValues);
    }
  }, [deviceConfigFields.get('settings'), device]);

  const [deleteDeviceModalOpen, setDeleteDeviceModalOpen] = useState(false);

  const numberOfCircuitsOfController: any = useSelector(getNumberOfCircuitsOfController) || 0;
  const maxNumCircuits = device?.getIn(['settings', 'maxNumCircuits']);

  const toggleAdvancedSettingsModal = () => {
    if (!settingsModal?.get('isOpen')) {
      dispatch(setSettingsModalOpen(device.get('id'), SettingsModalTypes.DEVICE_ADVANCED_SETTINGS));
    }
    else {
      dispatch(setSettingsModalClose(device.get('id')));
    }
  };

  const handleAdvancedSettingsSubmit = (rec: any, disabledFields: any) => {
    const obj = {};
    const switchLookup = {
      autocycleEnabledCoil: 'autocycleEnabledCoil',
      tsFailModeCoil: 'tsFailModeCoil',
      ts1FailureAlarmMask: 'ts1FailureAlarmMask',
      ts2FailureAlarmMask: 'ts2FailureAlarmMask',
      ts3FailureAlarmMask: 'ts3FailureAlarmMask',
      ts4FailureAlarmMask: 'ts4FailureAlarmMask',
      highTS1CutoffAlarmMask: 'highTS1CutoffAlarmMask',
      highTS2CutoffAlarmMask: 'highTS2CutoffAlarmMask',
      highLimitTempEnable: 'highLimitTempEnable',
      highTemperatureTripMode: 'highTemperatureTripMode',
      highCurrentTripMode: 'highCurrentTripMode',
      circuitBreakerLimitingAlarmMask: 'circuitBreakerLimitingAlarmMask',
      switchLimitingAlarmMask: 'switchLimitingAlarmMask',
      highLimitCutoutAlarmMask: 'highLimitCutoutAlarmMask',
      highVoltageAlarmMask: 'highVoltageAlarmMask',
      lowVoltageAlarmMask: 'lowVoltageAlarmMask',
      forcedOn: 'forcedOn',
      loadCurrentTrip: 'loadCurrentTrip',
      highTemperatureTrip: 'highTemperatureTrip',
      groundFaultTripAlarmMask: 'groundFaultTripAlarmMask',
      highTemperatureRTD1Trip: 'highTemperatureRTD1Trip',
      highTemperatureRTD2Trip: 'highTemperatureRTD2Trip',
      highLoadCurrentTripMask: 'highLoadCurrentTripMask',
      highTS1TempTripMask: 'highTS1TempTripMask',
      highTS2TempTripMask: 'highTS2TempTripMask'
    };

    let fetchSettingsOnUpdate = false;
    for (const [key, value] of Object.entries(rec.toJS())) {
      // check if there's a path for setting (for nested objects)
      const finalKey = deviceConfigFields.getIn(['settings', key, 'path'], key);
      // set fetch settings flag if one of the settings requires it
      if (deviceConfigFields.getIn(['settings', key, 'fetchSettingsOnUpdate'], false)) {
        fetchSettingsOnUpdate = deviceConfigFields.getIn(['settings', key, 'fetchSettingsOnUpdate'], false);
      }
      if (!disabledFields.get(finalKey, false)) {
        if ((finalKey === switchLookup[finalKey] && value === false) || ((finalKey === 'autocycleIntervalFormatCoil' || finalKey === 'autocycleIntervalTime') && (value === null || value === '0'))) {
          _.setWith(obj, finalKey, 0, Object);
        }
        else if ((finalKey === switchLookup[finalKey] && value === true) || (finalKey === 'autocycleIntervalFormatCoil' && value === '1')) {
          _.setWith(obj, finalKey, 1, Object);
        }
        else if (isLangBand(finalKey) && value) {
          let num = parseFloat(value as string);
          if (temperatureUnit === 'F' && num) {
            // converting it here to C value without adding/subtracting 32 number and then rounding it to have only one decimal place
            num = num * 5 / 9;
          }
          const roundedNum = Math.round(num*10) / 10;
          _.setWith(obj, finalKey, roundedNum, Object);

        }
        else if (isLangATemperature(finalKey) && value) {
          _.setWith(obj, finalKey, temperatureUnit === 'F' ? fahrenheitToCelsiusConvertor(value) : value, Object);
        }
        else {
          _.setWith(obj, finalKey, parseFloat(value as string), Object);
        }
      }
    }
    rec = {
      settings: Map(obj)
    };

    dispatch(updateDeviceSettings(device.get('id'), Map(rec), fetchSettingsOnUpdate));
  };

  const toggleAddCircuitModal = () => {
    setAddCircuitModalOpen(!addCircuitModalOpen);
    if (!settingsModal?.get('isOpen')) {
      dispatch(setSettingsModalOpen(device.get('id'), SettingsModalTypes.ADD_CIRCUIT));
    }
    else {
      dispatch(setSettingsModalClose(device.get('id')));
    }
  };

  const labelLookup = {
    'NVentCM2000P': 'NVent CM2000+',
    'ThermonTC101': 'Thermon TC101',
    'ThermonTC201': 'Thermon TC201',
    'ThermonTC202': 'Thermon TC202',
    'ThermonTC1818': 'Thermon TC1818',
    'ThermonTCM2': 'Thermon TCM2',
    'ThermonTCM18': 'Thermon TCM18',
    'MSCP': 'MSCP Modular EHT Circuit',
    'NGC30': 'NVent NGC30',
    'NVentNGC40': 'NVent NGC40 HTC',
    'NVentNGC40IO': 'NVent NGC40 IO Module',
    'SmartTrace': 'SmartTrace',
    'SmartTraceMP': 'SmartTrace Monitored Panel'
  };

  let addCircuitFields: any = {
    tag: {
      type: 'text',
      label: 'Circuit Tag',
      errorText: 'Required',
    }
  };

  if (allowedCircuitTypes.size > 1) {
    const optionsArr = [];
    allowedCircuitTypes.map(circuit => optionsArr.push({ label: labelLookup[circuit], value: circuit }));
    addCircuitFields['device_factory'] = {
      type: 'select',
      label: 'Device Type',
      options: optionsArr,
      errorText: 'Required',
    };
  }

  if (device?.get('device_factory') === 'NVentAC2000P') {
    addCircuitFields['device_address'] = {
      type: 'number',
      label: 'Device Address',
      errorText: 'Required',
    };
  } else if (device?.get('device_factory') === 'NVentNGC40Controller') {
    const optionsArr = [];
    hiddenCircuits.map(circuit => optionsArr.push({ label: circuit.get('device_address').toString(16).toUpperCase().padStart(6, '0'), value: circuit.get('id') }));
    addCircuitFields = {
      circuitId: {
        type: 'select',
        label: 'Device',
        options: optionsArr,
        errorText: 'Required',
      }
    };

  } else {
    addCircuitFields['subaddress'] = {
      type: 'number',
      label: 'Subaddress',
      errorText: 'Required',
    };
  }
  const addCircuitModalSubmit = (rec: any) => {
    const controllerId = device ? device.get('id', null) : null;
    const deviceModel = device ? device.get('model', null) : null;
    if (allowedCircuitTypes.size === 1) {
      rec = rec.set('device_factory', allowedCircuitTypes.get(0));
    }

    rec = rec.set('parent_id', controllerId);
    rec = rec.set('model', deviceModel);
    const modelLookup = {
      'NVentCM2000P': 'CM2000P',
      'NVentNGC30': 'NGC30',
      'NVentNGC40': 'NGC40',
      'ThermonTC101': 'TC101',
      'ThermonTC201': 'TC201',
      'ThermonTC202': 'TC202',
      'ThermonTC1818': 'TC1818',
      'ThermonTCM2': 'TCM2',
      'ThermonTCM18': 'TCM18',
      'MSCP': 'MSCP',
      'MasterTraceMR100': 'MR100',
      'SmartTraceMP': 'SmartTraceMP',
      'SmartTrace': 'SmartTrace',
    };

    const model = modelLookup[rec.get('device_factory')];
    rec = rec.set('model', model);
    if (device.get('device_factory') === "NVentNGC40Controller") {
      dispatch(unhideCircuit(rec.get('circuitId')));
    } else {
      dispatch(addCircuit(rec));
    }
  };
  const fieldSetsOrder = deviceConfigFields.get('fieldSetsOrder', []);

  const settingsLabelOverride = {
    'High Temp RTD 2': 'High Temperature RTD2 Trip',
    'High Temp RTD 1': 'High Temperature RTD1 Trip',
    'Ground Current Trip': 'Gf Trip Enabled'
  };

  const settingsModalFields = deviceConfigFields.get('settings').reduce((acc, field, key) => {
    // diff logic to to set the info icon on right hand side of input
    // to inform user of the difference between the current value and the designed value
    if (!field?.get('designedSettingOnly', false)) {
      const label = settingsLabelOverride[field?.get('label')] || field?.get('label');
      const diff: any = settingsDiff?.get(label, null);

      if (diff) {
        const isTextAOption = isTextValueAOption(diff.get('setting'));
        let value = diff?.get('designed');
        // some setting are dropdowns and we need to find their correct label
        if (isTextAOption) {
          const options = field.get('options');
          value = options?.find(x => x.get('value').toString() === value.toString() )?.get('label') || 'Invalid value';
        }
        field = field.set('designedValue', value);
        numDiff++;
      }
      return acc.set(key, field);
    }
    else {
      return acc;
    }
  }, Map());
  const abilities = useSelector(getAbilities);
  const features: any = useSelector(getFeatures);
  const hasPermissions = (requiredFeatures, requiredAbilities) => {
    if (!abilities || !features) {
      return false;
    }

    if (!requiredAbilities) {
      return true;
    }

    const hasAllFeatures = requiredFeatures ? requiredFeatures.every(feature => {
      return features.includes(feature);
    }) : true;
    const hasAllAbilities = requiredAbilities.every(ability => {
      return abilities.includes(ability);
    });
    return hasAllAbilities && hasAllFeatures;
  };

  const makeAdvancedSettingsModal = (readonly) => {
    if (!settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.DEVICE_ADVANCED_SETTINGS) {
      return <></>;
    }
    const isLocked = !hasPermissions(['program-device'], ['program-device']);
    return (
      <SettingsModal
        open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.DEVICE_ADVANCED_SETTINGS}
        handleClose={() => toggleAdvancedSettingsModal()}
        handleSubmit={handleAdvancedSettingsSubmit}
        title='Advanced Settings'
        loading={settingsModal?.get('loading')}
        settings={initialAdvancedConfigValues}
        fieldSetsOrder={fieldSetsOrder}
        locked={isLocked}
        readonly={readonly || isLocked}
        layout={deviceConfigFields.get('settings').size > 6 ? 6 : 12}
        id={device.get('id')}
        sameCheck
        fields={
          settingsModalFields
        }
        dataTestId='advanced-settings-modal'
      />
    );
  };

  const handleDispatchDeviceAction = (key, action) => {
    if (key === 'REPLACE_CARD') {
      dispatch(setSettingsModalOpen(device.get('id'), SettingsModalTypes.REPLACE_CARD_MODAL));
    } else {
      dispatch(dispatchDeviceAction(device.get('id'), key, action));
    }
  };

  const mappedFunctions = {
    deleteCircuit,
    deleteCommLoop
  };


  const deleteDeviceModalSubmit = () => {
    if (device.get('type') === 'controller') {
      dispatch(deleteController(device.get('id'), children));
    }
    else {
      const functionName = deviceTypeConfig[device.get('type')]?.actionButtons.deleteDevice.onSubmit;
      dispatch(mappedFunctions[functionName](device.get('id')));
    }
    setDeleteDeviceModalOpen(!deleteDeviceModalOpen);
  };

  return (
    <>
      {deviceTypeConfig[device.get('type')]?.actionButtons?.fileUpload ?
        <div className='file-upload'>
          <Paper>
            <div id='device-properties' data-testid='device-properties'>
              {fileUpload}
            </div>
          </Paper>
        </div>
        : null
      }

      <Grid item container spacing={3}>
        { deviceTypeConfig[device.get('type')]?.actionButtons.createForcedOnLogs ? (
          <PermissionFence can={['edit-forced-on-circuit', 'edit-device']}>
            <Grid item>
              <Button onClick={() => toggleForcedOnModal()} icon={<ForcedOnIcon style={{ fontSize: 14 }} />} cta disabled={device.get('status') === 'forced_on'}>Add Forced On Log</Button>
            </Grid>
          </PermissionFence>
        ) : null }

        { deviceTypeConfig[device.get('type')]?.actionButtons.advancedSettings && deviceConfigFields.get('settings')?.size > 0 ? (
          <Grid item>
            <Badge sx={badgeStyle} badgeContent={numDiff} className='alarmBadge' >
              <Button onClick={() => toggleAdvancedSettingsModal()} cta>Advanced Settings</Button>
            </Badge>
          </Grid>
        ) : null }


        <PermissionFence can='edit-device'>
          <>
            { deviceTypeConfig[device.get('type')]?.actionButtons.createNote ? (
              <Grid item>
                <Button onClick={() => toggleNoteModal()} cta>Add New Note</Button>
              </Grid>
            ) : null }

            { deviceTypeConfig[device.get('type')]?.actionButtons.addCircuit ? (
              <Grid item>
                <Button
                  onClick={() => toggleAddCircuitModal()}
                  disabled={numberOfCircuitsOfController >= maxNumCircuits}
                  cta
                >Add New Circuit</Button>
              </Grid>
            ) : null }

            { deviceActions ? deviceActions.reduce((acc, action, key) => {
              // build action buttons
              acc.push((
                <PermissionFence can='edit-device' key={key}>
                  <Grid item>
                    <Button key={key} onClick={() => { handleDispatchDeviceAction(key, action); }} cta>{action.get('friendlyName')}</Button>
                  </Grid>
                </PermissionFence>
              ));
              return acc;
            }, []) : null }

            { deviceTypeConfig[device.get('type')]?.actionButtons.deleteDevice ? (
              <Grid item>
                <Button onClick={() => { setDeleteDeviceModalOpen(!deleteDeviceModalOpen); }} backgroundColor={'error'} color={'white'} icon={<DeleteIcon style={{ fontSize: 14 }} />}>{deviceTypeConfig[device.get('type')]?.actionButtons.deleteDevice.label}</Button>
              </Grid>
            ) : null }
          </>


        </PermissionFence>

        {/* modals */}
        {deviceConfigFields.get('settings').size > 0  && initialAdvancedConfigValues.size > 0? (
          <PermissionFence can='edit-device' noPermissionComponent={makeAdvancedSettingsModal(true)}>
            {makeAdvancedSettingsModal(false)}
          </PermissionFence>
        ) : null}
        <PermissionFence can='edit-device'>
          <>
            <ForcedOnModal key={'forced-on-modal'} open={forcedOnModalOpen} circuitId={device.get('id')} readOnlyCircuit={true}
              modalTitle={`Add Forced-on for Circuit ${device.get('tag')}`} handleClose={() => toggleForcedOnModal()} handleSubmit={makeHandleAddForcedOnCircuit()} />

            <SettingsModal
              open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.ADD_CIRCUIT}
              handleClose={() => toggleAddCircuitModal()}
              handleSubmit={addCircuitModalSubmit}
              title={'Add New Circuit'}
              loading={settingsModal?.get('loading')}
              id={device?.get('id')}
              fields={
                fromJS(addCircuitFields)
              }
            />

            <NoteModal
              key={'note-modal'}
              open={noteModalOpen}
              handleClose={() => setNoteModalOpen(false)}
              deviceId={device.get('id')}
              handleSubmit={handleAddNoteSubmit}
              hideDeviceSelector
              readOnlyCircuit={true}
              noteType={'any'}
            />

            <ConfirmModal
              title='Are you sure?'
              message={deviceTypeConfig[device.get('type')]?.actionButtons.deleteDevice?.getMessage(device.get('tag'))}
              open={deleteDeviceModalOpen}
              handleClose={() => setDeleteDeviceModalOpen(false)}
              handleReject={() => setDeleteDeviceModalOpen(false)}
              handleConfirm={deleteDeviceModalSubmit}
              confirmPhrase='DELETE'
            />

          </>
        </PermissionFence>
      </Grid>

      <Modal
        data-testid='replace-card-modal'
        open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.REPLACE_CARD_MODAL}
        onClose={() => dispatch(setSettingsModalClose(device.get('id')))}
        aria-labelledby='replace-card-modal-title'
      >
        <div className='modal replace-card-modal'>
          <h2 id='replace-card-modal-title'>
            Replace Card
            {settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.REPLACE_CARD_MODAL && settingsModal?.get('loading') ?
              <div className='spinner'>
                <LogoSpinner size={50} />
              </div>

              : null}

          </h2>
          <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>


            <TextField  id='circuitAddress' name='circuitAddress' value={formik.values.circuitAddress} label={`New Circuit Address`}
              onChange={formik.handleChange} error={Boolean(formik.errors.circuitAddress)} helperText={formik.errors.circuitAddress} inputProps={{ maxLength: 255 }} InputLabelProps={{ shrink: false }} />

            <div className='button-bar'>
              <PermissionFence can={['edit-forced-on-circuit', 'edit-device']} noPermissionComponent={<Button onClick={() => dispatch(setSettingsModalClose(device.get('id')))}>Close</Button>}>
                <Button onClick={() => dispatch(setSettingsModalClose(device.get('id')))}>Cancel</Button>
                <Button type='submit' cta>Submit</Button>
              </PermissionFence>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}


DeviceScreenActionButtons.propTypes = {
  device: ImmutablePropTypes.map,
  deviceConfigFields: ImmutablePropTypes.map,
  deviceActions: ImmutablePropTypes.map,
  loading: PropTypes.bool
};

DeviceScreenActionButtons.defaultProps = {
  device: null,
  deviceConfigFields: null,
  deviceActions: null,
  loading: false
};
