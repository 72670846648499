import { request } from '../api';


export const getDeviceGroups = (action) => {
  const query: any = {};

  const {
    paginationDetails,
  } = action;

  const {
    filter,
    sort,
  } = paginationDetails;
  if (filter) {
    query.filter = filter;
  }
  const page = (isNaN(action.paginationDetails.page) ) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails.pageSize)) ? null : action.paginationDetails.pageSize;

  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }
  if (sort) {
    query.order = sort;
  }

  return request(action, {
    payload: {
      url: `/device_groups`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDeviceGroupsLabels = (action) => {
  return request(action, {
    payload: {
      url: `/device_groups/labels`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const deleteDeviceGroupById = (action) => {
  return request(action, {
    payload: {
      url: `/device_groups/${action.groupId}`,
      options: {
        method: 'delete',
      },
    }
  });
};
export const createDeviceGroup = (action) => {

  const { groupName, deviceIds } = action;
  return request(action, {
    payload: {
      url: `/device_groups`,
      options: {
        method: 'POST',
        data: {
          deviceIds,
          name: groupName
        }
      },
    }
  });
};

export const getDeviceGroup = (action) => {

  return request(action, {
    payload: {
      url: `/device_groups/${action.groupId}`,
      options: {
        method: 'GET'
      }
    }
  });
};
