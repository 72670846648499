import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import WorkTicketsList from 'src/component/UI/WorkTicketsList';
import { fetchDevicesActiveWorkTickets, fetchDevicesArchivedWorkTickets } from 'src/module/device/action';
import { getDevicesActiveWorkTickets, getLoadingActiveWorkTickets, getNumActiveWorkTicketsPages, getTotalActiveWorkTickets, getDevicesArchivedWorkTickets, getLoadingArchivedWorkTickets, getNumArchivedWorkTicketsPages, getTotalArchivedWorkTickets } from 'src/module/device/selector';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';

export default function NotificationsContent (props) {
  const {
    title,
    isExpanable,
    deviceId,
    onlyArchived
  } = props;
  const [notificationsCollapsed, setNotificationsCollapsed] = useState(!isExpanable);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);
  const selectorsActive = {
    getWorkTickets: getDevicesActiveWorkTickets(deviceId),
    getLoadingWorkTickets: getLoadingActiveWorkTickets(deviceId),
    getNumWorkTicketsPages: getNumActiveWorkTicketsPages(deviceId),
    getTotalWorkTickets: getTotalActiveWorkTickets(deviceId)
  };
  const selectorsArchived = {
    getWorkTickets: getDevicesArchivedWorkTickets(deviceId),
    getLoadingWorkTickets: getLoadingArchivedWorkTickets(deviceId),
    getNumWorkTicketsPages: getNumArchivedWorkTicketsPages(deviceId),
    getTotalWorkTickets: getTotalArchivedWorkTickets(deviceId)
  };

  const makeToggleNotificationsGroup = () => {
    notificationsCollapsed ? setNotificationsCollapsed(false) : setNotificationsCollapsed(true);
  };

  return (
    <div id="notifications-content" data-testid='notifications-content'>
      <Paper>
        {isExpanable ?
          <h3 className='expandable' onClick={() => makeToggleNotificationsGroup()}>{title} &nbsp;<span className='expand-arrow'>{notificationsCollapsed ? '▲' : '▼'}</span></h3>
          :
          <h3>{title}</h3>
        }
        {!onlyArchived && !showRotateMessage ? <WorkTicketsList deviceId={deviceId} selectors={selectorsActive} fetch={fetchDevicesActiveWorkTickets} tableId='activeWorkTicketsTable' hideDeviceTag suppressHeader/> : ''}
        {onlyArchived && notificationsCollapsed && !showRotateMessage? <WorkTicketsList deviceId={deviceId} selectors={selectorsArchived} fetch={fetchDevicesArchivedWorkTickets} tableId='pastWorkTicketsTable' hideDeviceTag showCompletedAt suppressHeader/> : ''}

        {notificationsCollapsed && showRotateMessage ?
          <DeviceRotate />
          : null}
      </Paper>
    </div>
  );
}

NotificationsContent.propTypes = {
  title: PropTypes.string,
  deviceId: PropTypes.string,
  isExpanable: PropTypes.bool,
  onlyArchived: PropTypes.bool
};

NotificationsContent.defaultProps = {
  title: String,
  deviceId: null,
  isExpanable: false,
  onlyArchived: false
};
