import React from 'react';
import PropTypes from 'prop-types';

export default function LogoSpinner (props) {
  const {
    size,
    thickness,
    square
  } = props;

  return square ? (
    <svg data-testid='logo' className='st-logo-sq' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 154.78 154.78' shapeRendering='geometricPrecision' width={size} height={size}>
      <g className='st-logo-sq-container'>
        <path className='st-logo-sq-line' d="M 149.70246,149.71238 149.70497,14.526227 86.41,95.12 67.93,80.39 10.644036,149.72057 Z" fill='none' stroke='#000' strokeWidth={thickness} strokeMiterlimit={thickness} />
      </g>
    </svg>
  ) : (
    <svg data-testid='logo' version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={size} height={100} viewBox="0 0 1008.000000 385.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,385.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M7630 1917 l0 -1777 1095 0 c720 0 1095 3 1095 10 0 11 -2156 3507
   -2176 3530 -12 12 -14 -269 -14 -1763z"/>
        <path d="M6766 2727 c-325 -523 -590 -959 -590 -968 -1 -20 1173 -1381 1185
   -1373 11 6 12 3281 1 3287 -4 3 -272 -423 -596 -946z"/>
        <path d="M4164 1903 c-340 -394 -619 -720 -619 -724 0 -7 1211 -888 1233 -896
   9 -4 12 233 12 1166 0 644 -2 1171 -3 1171 -2 0 -282 -323 -623 -717z"/>
        <path d="M5060 1555 c0 -612 4 -1065 9 -1059 5 5 178 283 386 619 321 519 375
   612 366 627 -12 19 -693 812 -737 858 l-24 25 0 -1070z"/>
        <path d="M1195 872 l-1000 -727 1000 -3 c550 -1 1006 0 1013 3 9 3 12 159 12
   730 0 574 -3 725 -12 724 -7 0 -463 -328 -1013 -727z"/>
        <path d="M2480 991 c0 -359 4 -611 9 -611 10 0 651 739 651 751 0 10 -625 463
   -645 467 -13 3 -15 -68 -15 -607z"/>
        <path d="M5998 1488 c-66 -103 -828 -1337 -828 -1342 0 -3 455 -6 1010 -6 602
   0 1010 4 1010 9 0 11 -1156 1355 -1169 1359 -4 1 -15 -8 -23 -20z"/>
        <path d="M3005 563 c-192 -223 -351 -410 -353 -415 -2 -4 413 -8 922 -8 509 0
   926 3 926 8 -1 9 -1116 816 -1133 819 -7 1 -169 -181 -362 -404z"/>
      </g>
    </svg>

  );
}

LogoSpinner.propTypes = {
  size: PropTypes.number.isRequired,
  thickness: PropTypes.number,
  square: PropTypes.bool
};

LogoSpinner.defaultProps = {
  thickness: 8,
  square: false
};
