import React from 'react';
import PropTypes from 'prop-types';

export default function AtcomLogo (props) {
  const {
    size,
    square,
    textOnly,
    color
  } = props;

  return square ? (
    <svg data-testid='atcom-logo-sq'className='atcom-logo-sq' id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 52.83 45.04">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect fill={color} strokeWidth='0px' width="24.54" height="20.65"/>
        <rect fill={color} strokeWidth='0px' x="28.29" width="24.54" height="20.65"/>
        <rect fill={color} strokeWidth='0px' y="24.39" width="24.54" height="20.65"/>
        <rect fill={color} strokeWidth='0px' x="28.29" y="24.39" width="24.54" height="20.65"/>
      </g>
    </svg>
  ) : textOnly ? (
    <svg data-testid='atcom-logo-text' className='atcom-logo-text' data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width={size} viewBox="70 22 300 50">
      <g data-name="Layer 1">
        <path fill={color} strokeWidth='0px' d="M296.95,19.92c6.02,0,11.86-.09,17.69.11.79.03,1.88,1.34,2.25,2.29,3.16,8.18,6.19,16.4,9.26,24.61.35.93.7,1.86,1.29,3.4,2.23-5.87,4.27-11.2,6.27-16.54,1.46-3.9,2.78-7.85,4.37-11.69.37-.9,1.55-2.06,2.38-2.09,5.72-.18,11.46-.1,17.39-.1v53.73h-13.68v-33.52c-.2-.06-.41-.12-.61-.18-.36.68-.81,1.33-1.08,2.05-3.64,9.55-7.29,19.1-10.85,28.68-.76,2.04-1.46,3.34-4.16,3.32-2.76-.01-3.59-1.26-4.4-3.46-3.54-9.59-7.21-19.13-10.83-28.69-.3-.79-.61-1.59-1.47-2.29v34.08h-13.81V19.92Z"/>
        <path fill={color} strokeWidth='0px' d="M260.02,74.68c-16.78.02-28.94-11.71-28.86-27.84.08-16.01,12.17-27.65,28.73-27.68,16.78-.03,28.88,11.7,28.77,27.89-.11,16.21-11.93,27.61-28.64,27.63ZM259.9,62.6c8.63.04,14.98-6.69,14.89-15.78-.08-9.03-6.29-15.56-14.78-15.58-8.54-.01-14.75,6.44-14.88,15.46-.13,9.1,6.15,15.85,14.77,15.89Z"/>
        <path fill={color} strokeWidth='0px' d="M73.97,73.86c2.86-7.63,5.59-14.97,8.35-22.3,3.65-9.67,7.39-19.31,10.92-29.03.75-2.08,1.75-2.74,3.87-2.66,4.16.16,8.34-.04,12.5.12.87.03,2.21.75,2.49,1.47,6.67,17.28,13.22,34.61,19.97,52.39-4.8,0-9.24.14-13.67-.12-.83-.05-1.91-1.59-2.3-2.65-1.96-5.33-1.89-5.36-7.57-5.36-4.73,0-9.47.11-14.19-.05-2.05-.07-3.14.62-3.55,2.58-.19.88-.63,1.7-.79,2.58-.44,2.39-1.72,3.25-4.22,3.1-3.8-.23-7.63-.06-11.82-.06ZM95.51,53.67h14.99c-2.48-7.05-4.83-13.71-7.18-20.38-.26.03-.53.06-.79.09-2.32,6.7-4.64,13.41-7.02,20.29Z"/>
        <path fill={color} strokeWidth='0px' d="M227.59,33.57c-3.9,1.85-7.62,3.61-11.26,5.33-7.11-8.78-15.59-10.08-22.4-3.66-6.41,6.06-6.28,17.74.27,23.67,6.74,6.11,15.4,4.63,22.09-3.95,3.66,1.74,7.38,3.5,11.08,5.25-3.8,10.22-15.74,16.11-28.32,14.2-13.54-2.06-23.07-12.31-23.7-25.48-.68-14.35,7.14-25.37,20.44-28.8,13.51-3.48,26.04,1.67,31.81,13.42Z"/>
        <path fill={color} strokeWidth='0px' d="M157.18,73.7h-14.04v-41.64h-14.84v-11.94h43.39v11.73h-14.51v41.85Z"/>
      </g>
    </svg>
  ) : (
    <svg data-testid='atcom-logo' className='atcom-logo' data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 431.55 136.57">
      <g data-name="Layer 1">
        <path fill={color} strokeWidth='0px' d="M0,136.57c1.96-3.45,3.7-6.54,5.47-9.62,9.19-16.04,18.34-32.1,27.63-48.07,1.14-1.97.95-3.29-.11-5.13C22.28,55.23,11.67,36.66,1.05,18.11c-.28-.49-.51-1.01-.97-1.93,2.65,0,5.01,0,7.37,0,4.92,0,9.84-.08,14.75.07.93.03,2.25.69,2.7,1.46,11.01,18.81,21.95,37.67,32.81,56.57.43.74.42,2.14,0,2.89-10.9,19.37-21.87,38.7-32.87,58.02-.32.57-1,1.32-1.52,1.33-7.63.09-15.27.06-23.33.06Z"/>
        <path fill='#000' strokeWidth='0px' d="M296.95,19.92c6.02,0,11.86-.09,17.69.11.79.03,1.88,1.34,2.25,2.29,3.16,8.18,6.19,16.4,9.26,24.61.35.93.7,1.86,1.29,3.4,2.23-5.87,4.27-11.2,6.27-16.54,1.46-3.9,2.78-7.85,4.37-11.69.37-.9,1.55-2.06,2.38-2.09,5.72-.18,11.46-.1,17.39-.1v53.73h-13.68v-33.52c-.2-.06-.41-.12-.61-.18-.36.68-.81,1.33-1.08,2.05-3.64,9.55-7.29,19.1-10.85,28.68-.76,2.04-1.46,3.34-4.16,3.32-2.76-.01-3.59-1.26-4.4-3.46-3.54-9.59-7.21-19.13-10.83-28.69-.3-.79-.61-1.59-1.47-2.29v34.08h-13.81V19.92Z"/>
        <path fill='#000' strokeWidth='0px' d="M260.02,74.68c-16.78.02-28.94-11.71-28.86-27.84.08-16.01,12.17-27.65,28.73-27.68,16.78-.03,28.88,11.7,28.77,27.89-.11,16.21-11.93,27.61-28.64,27.63ZM259.9,62.6c8.63.04,14.98-6.69,14.89-15.78-.08-9.03-6.29-15.56-14.78-15.58-8.54-.01-14.75,6.44-14.88,15.46-.13,9.1,6.15,15.85,14.77,15.89Z"/>
        <path fill='#000' strokeWidth='0px' d="M73.97,73.86c2.86-7.63,5.59-14.97,8.35-22.3,3.65-9.67,7.39-19.31,10.92-29.03.75-2.08,1.75-2.74,3.87-2.66,4.16.16,8.34-.04,12.5.12.87.03,2.21.75,2.49,1.47,6.67,17.28,13.22,34.61,19.97,52.39-4.8,0-9.24.14-13.67-.12-.83-.05-1.91-1.59-2.3-2.65-1.96-5.33-1.89-5.36-7.57-5.36-4.73,0-9.47.11-14.19-.05-2.05-.07-3.14.62-3.55,2.58-.19.88-.63,1.7-.79,2.58-.44,2.39-1.72,3.25-4.22,3.1-3.8-.23-7.63-.06-11.82-.06ZM95.51,53.67h14.99c-2.48-7.05-4.83-13.71-7.18-20.38-.26.03-.53.06-.79.09-2.32,6.7-4.64,13.41-7.02,20.29Z"/>
        <path fill='#000' strokeWidth='0px' d="M227.59,33.57c-3.9,1.85-7.62,3.61-11.26,5.33-7.11-8.78-15.59-10.08-22.4-3.66-6.41,6.06-6.28,17.74.27,23.67,6.74,6.11,15.4,4.63,22.09-3.95,3.66,1.74,7.38,3.5,11.08,5.25-3.8,10.22-15.74,16.11-28.32,14.2-13.54-2.06-23.07-12.31-23.7-25.48-.68-14.35,7.14-25.37,20.44-28.8,13.51-3.48,26.04,1.67,31.81,13.42Z"/>
        <path fill='#000' strokeWidth='0px' d="M157.18,73.7h-14.04v-41.64h-14.84v-11.94h43.39v11.73h-14.51v41.85Z"/>
        <rect fill={color} strokeWidth='0px' x="378.72" width="24.54" height="20.65"/>
        <rect fill={color} strokeWidth='0px' x="407.01" width="24.54" height="20.65"/>
        <rect fill={color} strokeWidth='0px' x="378.72" y="24.39" width="24.54" height="20.65"/>
        <rect fill={color} strokeWidth='0px' x="407.01" y="24.39" width="24.54" height="20.65"/>
        <text fill='#000' fontFamily={`'MyriadPro', 'Trebuchet MS', 'Arial', 'sans-serif'`} fontSize='34px' transform="translate(73.97 121.49)"><tspan x="0" y="0">Heat Trace Monitoring</tspan></text>
      </g>
    </svg>
  );
}

AtcomLogo.propTypes = {
  size: PropTypes.number.isRequired,
  square: PropTypes.bool,
  textOnly: PropTypes.bool,
  color: PropTypes.string
};

AtcomLogo.defaultProps = {
  square: false,
  textOnly: false,
  color: '#8b4243'
};
