import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ImmutablePropTypes from 'react-immutable-proptypes';
import { TextField, FormControl, Autocomplete } from '@mui/material';

import LogoSpinner from '../../LogoSpinner';

export default function AutoCompleteSelectField (props) {
  const {
    field,
    handleChange,
    disabled,
    showLabel,
  } = props;

  const fieldId = field.get('id');
  const formatOption = field.get('formatOption');

  const loading = false;
  const required = field.get('required') || false;
  const optionsSelector = field.get('optionsSelector');
  const onValueChange = field.get('onValueChange');

  const [selectedOption, setSelectedOption] = useState(null);
  const label = field.get('label');

  const opts: any = useSelector(optionsSelector);
  const optsArray = [];

  if (opts) {
    opts?.map((optionRaw) => {
      const option = formatOption(optionRaw);
      const optionObj = { label: option.label, value: option.value, key: option.value };

      optsArray.push(optionObj);
    });
  }

  const filterOptions = (e) => {
    const fetchOptions = field.get('fetchOptions');
    if (fetchOptions) {
      fetchOptions(e);
    }
  };

  const selectOption = (e, _selectedOption) => {
    const tag = _selectedOption.label;
    if (tag.includes('Add ')) {
      _selectedOption.label = tag.replace('Add "', "").replace("\"", "");
    }
    setSelectedOption(_selectedOption);
    if (_selectedOption) {
      onValueChange({ id: _selectedOption.value, tag: tag });
    } else {
      onValueChange('');
    }

    handleChange(fieldId, _selectedOption?.value);
  };
  return <FormControl key={fieldId} className='select-field' variant='outlined'>
    <Autocomplete
      data-testid={`autocomplete-select-field-${fieldId}`}
      id={`autocomplete-select-field-${fieldId}`}
      value={selectedOption}
      options={optsArray}
      loading={loading}
      isOptionEqualToValue={(option, value) => option && option.id === value.id}
      getOptionLabel={(option) => option['label'] || ''}
      onChange={selectOption}
      disabled={disabled}
      freeSolo={field.get('freeSolo')}
      clearOnBlur
      selectOnFocus
      filterOptions={(options, params) => {
        const filtered: any = options.filter(x => x.label.toLowerCase().includes(params.inputValue.toLowerCase()));

        if (!field.get('fetchOptions')) {
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue?.toLowerCase() === option.label?.toLowerCase());
          if (inputValue !== '' && !isExisting && field.get('freeSolo')) {
            filtered.push({
              value: `new-user-entry~${inputValue}`,
              label: `Add "${inputValue}"`,
              key: 'new-user-entry'
            });
          }

        }
        return filtered;
      }}
      renderOption={(props, option) => {
        props['aria-selected'] = selectedOption?.key === option?.key;
        return (<li {...props}>{option.label}</li>);
      }}
      renderInput={(params) => <TextField {...params} onChange={filterOptions} error={false}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading && showLabel ? <LogoSpinner size={32} /> : null}
              {params.InputProps.endAdornment}
            </>
          )
        }}
        required={required}
        label={showLabel ? label : ''}
      />}
    />
  </FormControl>;
}

AutoCompleteSelectField.propTypes = {
  field: ImmutablePropTypes.map,
  error: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool
};

AutoCompleteSelectField.defaultProps = {
  disabled: false,
  showLabel: true
};

AutoCompleteSelectField.whyDidYouRender = true;
