import './ChangeClientModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { ListItemText, ListItemButton, List } from '@mui/material';
import classNames from 'classnames';

export default function ChangeClientModal (props) {
  const onClick = (id) => {
    props.handleClose();
    if (id === props.currentClientId) {
      return;
    }
    props.handleSubmit(id);
  };

  return (
    <div data-testid="change-client-modal">
      <List className="change-facility">
        {props.clients.keySeq().toArray().map((clientId) => (
          <ListItemButton key={clientId} onClick={() => onClick(clientId)}>
            <ListItemText className={classNames('change-facility-text', props.currentClientId === clientId ? 'active' : '')}> {props.clients.getIn([clientId, 'name'])}</ListItemText>
          </ListItemButton>
        ))}
      </List>
    </div>
  );
}

ChangeClientModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentClientId: PropTypes.string,
  clients: ImmutablePropTypes.map.isRequired
};

ChangeClientModal.defaultProps = {
};
