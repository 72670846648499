import './ErrorMessage.scss';

import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = (props) => {
  const {
    message,
    handleRetry,
    isRetriable
  } = props;

  return (
    <div className='error-message' data-testid='error-message'>
      {message} {(isRetriable)
        ? <button onClick={(evnt) => handleRetry(evnt)}>Try that again?</button>
        : null}
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  handleRetry: PropTypes.func,
  isRetriable: PropTypes.bool
};

ErrorMessage.defaultProps = {
  message: '',
  handleRetry: null,
  isRetriable: false
};

ErrorMessage.whyDidYouRender = true;

export default React.memo(ErrorMessage);
