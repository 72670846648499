import './SubNavBarItem.scss';

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { NavLink } from 'react-router-dom';
import isExternalURL from 'src/utils/isExternalURL';

export const SubNavBarItem = ({
  to,
  label,
  icon,
  onClick,
  className,
  ...rest
}: any) => {
  const handleClick = (evnt) => {
    if (!to || to === '#') {
      evnt.preventDefault();
    }

    if (onClick) {
      onClick(evnt);
    }
  };

  if (isExternalURL(to)) {
    return (
      <a data-testid='subnavbar-item' href={to} target='_blank' rel='noopener noreferrer' className={classNames('subnavbar-item', className)} onClick={handleClick}>{icon} {label}</a>
    );
  }

  return (
    <NavLink data-testid='subnavbar-item' to={to} className={classNames('subnavbar-item', className)} onClick={handleClick} {...rest}>{icon} {label}</NavLink>
  );
};


SubNavBarItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string
};

SubNavBarItem.defaultProps = {};

export default SubNavBarItem;
