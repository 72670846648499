import './LabelledSwitch.scss';
import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

export default function LabelledSwitch (props) {
  const {
    title,
    onLabel,
    offLabel,
    value,
    onChange
  } = props;

  return (
    <div className='labelledSwitch'>
      <h4>{title}</h4>
      <FormGroup>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{offLabel}</Typography>
          <Switch checked={value} inputProps={{ 'aria-label': 'ant design' }}   onChange={(e) => onChange(e)}/>
          <Typography>{onLabel}</Typography>
        </Stack>
      </FormGroup>
    </div>
  );
}
LabelledSwitch.propTypes = {
  title: PropTypes.string,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
};
