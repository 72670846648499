import './NavBar.scss';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useState } from 'react';
import PropTypes from 'prop-types';
import LogOutIcon from '@mui/icons-material/ExitToApp';
import SwitchClientIcon from '@mui/icons-material/ShuffleOutlined';
import EhtGroupLogo from '@mui/icons-material/FilterHdrOutlined';
import { List, Map } from 'immutable';
import NavBarItem from 'src/component/UI/NavBar/NavBarItem';
import ChangeClientModal from 'src/component/UI/ChangeClientModal';
import { getClientsForUser, getAuthenticatedUser } from 'src/module/authentication/selector';
import { changeClient } from 'src/module/authentication/action';
import { updateUser } from 'src/module/user/action';
import MenuIcon from '@mui/icons-material/Menu';
import { Popover } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ExpandMore } from '@mui/icons-material';
import variables from 'src/style/variable/variables.module.scss';
import FactoryIcon from '@mui/icons-material/Factory';
import smarttrace from 'src/assets/smarttrace.svg';

export default function AdminNavBar (props) {
  const dispatch: any = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [openSettingsPopOver, setOpenSettingsPopOver] = useState(false);
  const [changeFacilityPopOver, setChangeFacilityPopOver] = useState(false);
  const [anchorElForFacility, setAnchorElForFacility] = useState<HTMLDivElement | null>(null);

  const clients: any = useSelector(getClientsForUser);
  const user = useSelector(getAuthenticatedUser);

  const [open, setOpen] = useState(user.getIn(['preferences', 'navbarOpen'], true));
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);

  const handleClickOnHeader = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSettingsPopOver(!openSettingsPopOver);
  };

  const toggleDrawer = () => {
    dispatch(updateUser(null, Map({ navbarOpen: !open })));

    setOpen(!open);
    props.onToggleDrawer(!open);
  };

  const handleChangeClient = (clientId) => {
    handleCloseSettingsPopOver();
    dispatch(changeClient(clientId));
    if (isMobile) {
      toggleDrawer();
    }
  };

  const handleCloseSettingsPopOver = () => {
    setAnchorEl(null);
    setOpenSettingsPopOver(!openSettingsPopOver);
  };
  const handleClickOnChangeFacility = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElForFacility(changeFacilityPopOver ? null : event.currentTarget);
    setChangeFacilityPopOver(!changeFacilityPopOver);
  };
  const handleCloseChangeFacility = () => {
    setAnchorElForFacility(null);
    setChangeFacilityPopOver(!changeFacilityPopOver);
  };
  const handleMobileDrawerItemClick = () => {
    if (!isMobile) {
      return;
    }

    toggleDrawer();
  };

  function menuHeader () {
    const regLogo = <EhtGroupLogo style={{ fontSize: 32 }} />;
    const regName = <><span style={{ marginLeft: '-10px' }}><img src={smarttrace} width={'150px'} /> </span><span className="chevron" style={{ lineHeight: '40px' }}><ExpandMore /></span></>;

    return <NavBarItem onClick={handleClickOnHeader} className='main' icon={regLogo} label={regName} />;
  }

  return (
    <>
      <div id='navbar-header' data-testid='navbar-header'>
        {menuHeader()}
      </div>
      <div id='navbar-upper' data-testid='navbar-upper'>
        <div onClick={handleMobileDrawerItemClick}>
          <NavBarItem to='/admin' icon={<FactoryIcon style={{ fontSize: 20 }} />} label='Clients' />
        </div>
      </div>
      <div id='navbar-lower' data-testid='navbar-lower' >
        {isMobile ? null : <NavBarItem className='collapse' onClick={toggleDrawer} icon={<MenuIcon style={{ fontSize: 24 }} />} label='Collapse' />}
      </div>
      <Popover
        open={openSettingsPopOver}
        anchorEl={anchorEl}
        onClose={handleCloseSettingsPopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="nav-popover-settings"
      >
        <div className='setting-popover-header'>
          <span>Administrator Menu</span>
        </div>
        <div onClick={handleMobileDrawerItemClick}>
          <div className={classNames('setting-popover-header', 'user-settings')}>
            <div className='user-initals'>{user.get('first_name')?.toUpperCase()[0]}{user.get('last_name')?.toUpperCase()[0]}</div>
            <div className='user-details'>
              <div>{user.get('first_name')} {user.get('last_name')}</div>
              <div className="user-email">{user.get('email')}</div>
            </div>
          </div>
        </div>
        {clients.size > 1 ?
          (<NavBarItem label='Return to Facility' to='#' isFlyOut onClick={handleClickOnChangeFacility} icon={<SwitchClientIcon style={{ fontSize: 20 }} />} />)
          : <NavBarItem label='Return to Facility' to='#' onClick={() => handleChangeClient(List(clients.keys()).first())} icon={<SwitchClientIcon style={{ fontSize: 20 }} />} />
        }
        <NavBarItem label='Sign Out' to='/logout' icon={<LogOutIcon style={{ fontSize: 20 }} />} />
      </Popover>
      {/* popover / flyout for change facility, triggeted by click on change facility */}
      <Popover
        disableRestoreFocus
        open={changeFacilityPopOver}
        anchorEl={anchorElForFacility}
        onClose={handleCloseChangeFacility}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="nav-popover-settings"
      >
        <ChangeClientModal clients={clients} currentClientId={null} handleClose={handleCloseChangeFacility} handleSubmit={handleChangeClient} />
      </Popover>
    </>
  );
}
AdminNavBar.propTypes = {
  onToggleDrawer: PropTypes.func
};

AdminNavBar.defaultProps = null;
