import './RadialGauge.scss';
import React from 'react';
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { calcDisplayRange, calcMinAndMax, colorForGauge, getGaugeStatusDisplayValue } from 'src/utils/gaugeHelperFunctions';
import { Badge } from '@mui/material';
export default function Gauge (props) {
  const {
    title,
    units,
    theme,
    settingsConfig,
    openSetting,
    onSetPointClick,
    numberOfSettingDiffs,
    disableHamburger
  } = props;
  const calcTextOffSet = (values, adjust = 0) => {
    const lengthOfText = values?.toString().length;
    let offSetText = 0.025 + adjust;
    if (lengthOfText === 2) {
      offSetText = 0.04 + adjust;
    } else if (lengthOfText === 3) {
      offSetText = 0.05 + adjust;
    } else if (lengthOfText === 4) {
      offSetText = 0.06 + adjust;
    }
    return offSetText;
  };

  let {
    value,
    rangeMin,
    rangeMax,
    rangeTrip,
    setPointValue
  } = props;

  if (value > 99) {
    value = Math.ceil(value);
  }
  if (rangeMax > 99) {
    rangeMax = Math.ceil(rangeMax);
  }
  if (rangeMin > 99) {
    rangeMin = Math.ceil(rangeMin);
  }
  if (rangeTrip > 99) {
    rangeTrip = Math.ceil(rangeTrip);
  }
  if (setPointValue > 99) {
    setPointValue = Math.ceil(setPointValue);
  }

  // values used for displaying gauge
  // so we dont show setpoints if they're disabled (and disabling is something permissible for device)
  // a value of NULL causes the setpoints not to appear
  const displayRangeMin = calcDisplayRange(settingsConfig, rangeMin, 'intialLowSetpointEnabled');
  const displayRangeMax = calcDisplayRange(settingsConfig, rangeMax, 'intialHighSetpointEnabled');
  const displayTripMax = calcDisplayRange(settingsConfig, rangeTrip, 'intialTripSetpointEnabled');

  // calculate max and mins to the graph always add 10% to the max
  // subtract 10% from min if  value is greater then min set point
  const [max, min, values] = calcMinAndMax(displayRangeMin, displayRangeMax, value, setPointValue, rangeTrip);
  //////////////// calculate percentage based on values ///////////////////
  // each calculation need to points that way when we can create the small black dash
  // the first is the actual values the second is 0.01 percent more
  const percentScale = scaleLinear()
    .domain([min, max])
    .range([0, 1]);

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 12])
    .clamp(true);


  // Lower Range Calculations
  const lowPercent = percentScale(displayRangeMin);
  const lowerRange = angleScale(lowPercent);
  const lowerRangeTextPercentLeft = percentScale(displayRangeMin );
  const lowerRangeTextPercentRight = percentScale(displayRangeMin + (((max as any) - (min as any)) * 0.15));
  const lowerRangeTextRangeLeft = angleScale(lowerRangeTextPercentLeft);
  const lowerRangeTextRangeRight = angleScale(lowerRangeTextPercentRight);

  // Upper Range Calculations
  const upperPoint = displayRangeMax || displayRangeMax === 0 ? displayRangeMax : max;
  const upperPercent = percentScale(upperPoint);
  const upperRange = angleScale(upperPercent);
  const upperRangeTextPercentLeft = percentScale(displayRangeMax + (((max as any) - (min as any)) * 0.03));
  const upperRangeTextPercentRight = percentScale(displayRangeMax + (((max as any) - (min as any)) * 0.15));
  const upperRangeTextRangeLeft = angleScale(upperRangeTextPercentLeft);
  const upperRangeTextRangeRight = angleScale(upperRangeTextPercentRight);


  // Trip Range Calculations
  const tripPercent = percentScale(rangeTrip);
  const tripRange = angleScale(tripPercent);
  const tripRangeTextPercentLeft = percentScale(displayTripMax + (((max as any) - (min as any)) * 0.03));
  const tripRangeTextPercentRight = percentScale(displayTripMax + (((max as any) - (min as any)) * 0.15));
  const tripRangeTextRangeLeft = angleScale(tripRangeTextPercentLeft);
  const tripRangeTextRangeRight = angleScale(tripRangeTextPercentRight);


  // Setpoint Range Calculations
  const setPointPercent = percentScale(setPointValue - (((max as any) - (min as any)) * 0.01));


  // SetPoint Text Range Calculations
  const setPointTextPercentLeft = percentScale(setPointValue - (((max as any) - (min as any)) * calcTextOffSet(setPointValue)));
  const setPointTextPercentRight = percentScale(setPointValue + (((max as any) - (min as any)) * 0.1));
  const setPointTextRangeLeft = angleScale(setPointTextPercentLeft);
  const setPointTextRangeRight = angleScale(setPointTextPercentRight);


  // Current Value Calculations
  const currentPercent = value ? percentScale(value) : 0;

  const rangeColors = colorForGauge(theme, values, lowerRange);

  const pinAngle = (setPointPercent * 105) - 89;
  const pinAngleBackground = ((setPointPercent-0.05) * 105) - 89;

  const upperRangePinAngle = (upperPercent * 105) - 90;
  const lowerRangePinAngle = (lowPercent * 105) - 90;
  const tripRangePinAngle = (tripPercent * 105) - 90;

  const needleAngle = (currentPercent * 105) - 90;

  //////////////  create arcs /////////////////////
  const borderArc = arc()
    .innerRadius(0.75)
    .outerRadius(1)
    .startAngle((-Math.PI / 2) - 0.007)
    .endAngle((Math.PI / 12) + 0.007)
    ();

  const lowerRangefilledArc = arc()
    .innerRadius(0.75)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 12)
    ();

  const lowerRangefilledArcForText = arc()
    .innerRadius(1.07)
    .outerRadius(1)
    .startAngle(lowerRangeTextRangeLeft)
    .endAngle(lowerRangeTextRangeRight)
    ();

  const upperRangefilledArcForText = arc()
    .innerRadius(1.07)
    .outerRadius(1)
    .startAngle(upperRangeTextRangeLeft)
    .endAngle(upperRangeTextRangeRight)
    ();

  const tripRangefilledArcForText = arc()
    .innerRadius(1.07)
    .outerRadius(1)
    .startAngle(tripRangeTextRangeLeft)
    .endAngle(tripRangeTextRangeRight)
    ();

  const middleRangefilledArc = arc()
    .innerRadius(0.75)
    .outerRadius(1)
    .startAngle(lowerRange)
    .endAngle(Math.PI / 12)
    ();

  const upperRangefilledArc = arc()
    .innerRadius(0.75)
    .outerRadius(1)
    .startAngle(upperRange)
    .endAngle(Math.PI / 12)
    ();

  const tripRangefilledArc = arc()
    .innerRadius(0.75)
    .outerRadius(1)
    .startAngle(tripRange)
    .endAngle(Math.PI / 12)
    ();

  const dialArc = arc()
    .innerRadius(0.34)
    .outerRadius(0.36)
    .startAngle((-Math.PI / 2) - 0.05)
    .endAngle((Math.PI / 12) + 0.05)
    ();

  const setPointRangefilledArcForText = arc()
    .innerRadius(1.1)
    .outerRadius(1.32)
    .startAngle(setPointTextRangeLeft)
    .endAngle(setPointTextRangeRight)
    ();

  const id = title.toLowerCase().replace(/ /, '-', 'g');
  const badgeStyle = {
    "& .MuiBadge-badge": {
      backgroundColor: '#fcd54c',
    }
  };

  const getDisplayValue = (val) => {
    return Number.isInteger(val) ? val : val.toFixed(1);
  };
  return (
    <div className='radial-gauge-container' data-testid={`gauge-${title}`}>
      {( !disableHamburger && (displayRangeMin !== null || displayRangeMax !== null || setPointValue !== null || settingsConfig.canDisableAllAlarms)) ?
        <span
          className='radial-gauge-hamburger'
          onClick={() => openSetting(SettingsModalTypes[`${settingsConfig.type}_MULTIPLE`])}>
          {numberOfSettingDiffs ? <Badge sx={badgeStyle} badgeContent={numberOfSettingDiffs} className='alarmBadge' >
            <div style={{ padding: '2px' }}>
              <MoreVertIcon fontSize='large' />
            </div>
          </Badge> :
            <MoreVertIcon fontSize='large' />
          }

        </span>
        :
        null}
      {settingsConfig.toolTip ? <div className='gauge-info' > <Tooltip title={settingsConfig.toolTip} enterTouchDelay={0}><InfoIcon style={{ fontSize: 20 }}/></Tooltip></div> : null}
      <p className='radial-gauge-title'>{title}</p>

      {/* D3 Gauge Drawing Starts Here */}
      <svg className="radial-gauge-svg" viewBox="-1 -1.15 1.3 1.2">

        {/* ------------ Arcs -------------- */}
        {/* Lower Range  */}
        <path
          d={lowerRangefilledArc}
          fill={rangeColors.low}
        />

        {/* middle range */}
        {displayRangeMin || displayRangeMin === 0 ?
          <>
            <path
              id={`middle-${id}`}
              d={middleRangefilledArc}
              fill={rangeColors.mid}
            />
          </>
          : null}

        {/* upper range */}
        {displayRangeMax || displayRangeMax === 0 ?
          <>
            <path
              id={`upper-${id}`}
              d={upperRangefilledArc}
              fill={rangeColors.upper}
            />
          </>
          : null}

        {/* Trip upper range */}
        {displayTripMax || displayTripMax === 0 ?
          <>
            <path
              id={`trip-${id}`}
              d={tripRangefilledArc}
              fill={rangeColors.trip}
            />
          </>
          : null}


        {/* set point */}
        {setPointValue || setPointValue === 0 ?
          <>
            <g  onClick={() => onSetPointClick(settingsConfig.settingLabel, setPointValue, 'setting')} style={{ cursor: 'pointer' }}>
              <rect  width={'0.15px'} height={'0.15px'} transform={`rotate(${pinAngleBackground}) translate(0.05, -1.25)`}  style={{ cursor: 'pointer', opacity: 0 }} >
                <title>{settingsConfig.settingLabel}</title>
              </rect>
              <path
                onClick={() => onSetPointClick(settingsConfig.settingLabel, setPointValue, 'setting')}
                d={`m 0 0 c -0.06214,0 -0.112522,0.0504 -0.112522,0.11243 0,0.0277 0.01011,0.0531 0.02673,0.0728 l 0.08579,0.10109 0.08579,-0.10109 c 0.01665,-0.0196 0.02673,-0.045 0.02673,-0.0728 0,-0.0622 -0.05038,-0.11243 -0.112523,-0.11243 z m 0,0.15491 c -0.02555,0 -0.04627,-0.0207 -0.04627,-0.0463 0,-0.0256 0.02071,-0.0463 0.04627,-0.0463 0.02557,0 0.04628,0.0207 0.04628,0.0463 0,0.0256 -0.02072,0.0463 -0.04628,0.0463 z`}
                style={{ cursor: 'pointer' }}
                className='gauge-set-point'
                strokeWidth={0.005}
                transform={`rotate(${pinAngle}) translate(-0.0, -1.3)`}
              >
                <title>{settingsConfig.settingLabel}</title>
              </path>
            </g>
            <text x="0" y="0" dy="-0.05" dominantBaseline="middle" style={{ fontSize: '0.1px', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => onSetPointClick(settingsConfig.settingLabel, setPointValue, 'setting')} >
              <textPath href={`#set-point-${id}`} fill="black">{`${getDisplayValue(setPointValue)}`}</textPath>
              <title>{settingsConfig.settingLabel}</title>
            </text>
            <path
              d={setPointRangefilledArcForText}
              fill="none"
              id={`set-point-${id}`}
            />
          </>
          : null}

        {/* ------------- text and labels  -----------------*/}
        {/* lower range text */}
        {displayRangeMin || displayRangeMin === 0 ?
          <>
            <path
              onClick={() => onSetPointClick(settingsConfig.rangeMinLabel, displayRangeMin, 'low')}
              d="m 0 -1.07 h -0.0717 c 0.0464 0.0404 0.0462 0.0405 0.0721 0.1028 z"
              style={{ cursor: 'pointer' }}
              strokeWidth={0.003}
              transform={`rotate(${lowerRangePinAngle}) `}
            >
              <title>{settingsConfig.rangeMinLabel}</title>
            </path>
            <text y="-1.1" x="-0.1" transform={`rotate(${lowerRangePinAngle}) `} style={{ fontSize: '0.1px', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => onSetPointClick(settingsConfig.rangeMinLabel, displayRangeMin, 'low')} >
              {getDisplayValue(displayRangeMin)}
              <title>{settingsConfig.rangeMinLabel}</title>
            </text>
            <path
              id={`lowerText-${id}`}
              d={lowerRangefilledArcForText}
              fill='none'
            />
          </>
          : null}

        {/* upper range text*/}
        {displayRangeMax || displayRangeMax === 0 ?
          <>
            <path
              onClick={() => onSetPointClick(settingsConfig.rangeMaxLabel, rangeMax, 'high')}
              d="m 0 -1.07 h 0.0717 c -0.0464 0.0404 -0.0462 0.0405 -0.0721 0.1028 z"
              fill={'black'}
              style={{ cursor: 'pointer' }}
              strokeWidth={0.003}
              transform={`rotate(${upperRangePinAngle}) `}
            >
              <title>{settingsConfig.rangeMaxLabel}</title>
            </path>
            <text x="0" y="0" dy="-0.05" style={{ fontSize: '0.1px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSetPointClick(settingsConfig.rangeMaxLabel, displayRangeMax, 'high')}>
              <textPath href={`#upperText-${id}`} >{getDisplayValue(displayRangeMax)}</textPath>
              <title>{settingsConfig.rangeMaxLabel}</title>
            </text>

            <path
              id={`upperText-${id}`}
              d={upperRangefilledArcForText}
              fill='none'
            />
          </>
          : null}
        {/* trip range text*/}
        {displayTripMax || displayTripMax === 0 ?
          <>
            <path
              onClick={() => onSetPointClick(settingsConfig.rangeTripLabel, rangeTrip, 'high')}
              d="m 0 -1.07 h 0.0717 c -0.0464 0.0404 -0.0462 0.0405 -0.0721 0.1028 z"
              fill={'black'}
              style={{ cursor: 'pointer' }}
              strokeWidth={0.003}
              transform={`rotate(${tripRangePinAngle}) `}
            >
              <title>{settingsConfig.rangeTripLabel}</title>
            </path>
            <text x="0" y="0" dy="-0.05" style={{ fontSize: '0.1px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSetPointClick(settingsConfig.rangeTripLabel, displayTripMax, 'trip')}>
              <textPath href={`#tripText-${id}`} >{getDisplayValue(displayTripMax)}</textPath>
              <title>{settingsConfig.rangeTripLabel}</title>
            </text>

            <path
              id={`tripText-${id}`}
              d={tripRangefilledArcForText}
              fill='none'
            />
          </>
          : null}
        {/* Border  */}
        <path
          d={borderArc}
          className='gauge-background'
          strokeOpacity={0.5}
          strokeWidth={0.008}
          fill="transparent"
        />

        {/* Needle Arrow */}
        <path
          d="m -0.02 -0.35 a 0.9,0.9 0 0 0 0.036,0 l -0.012,-0.5799999 a 0.32,0.32 0 0 1 -0.012,0 z"
          className='gauge-set-point'
          strokeWidth={0.003}
          style={{
            transform: `rotate(${needleAngle}deg)`,
            transition: `transform 0.5s ease-in-out`
          }}
        />

        {/* Dial Arc  */}
        <path
          d={dialArc}
          strokeOpacity={0.5}
          className='gauge-dial-arc'
        />

        <text x="-0.05" y="0" fontSize='0.2px' textAnchor="middle" fontWeight="bold">
          {getGaugeStatusDisplayValue(value)}
        </text>
        <text x="0" y="0.15" fontSize='0.12px' textAnchor="middle" fontWeight="bold">
          {value !== null ? units : null}
        </text>
      </svg>
    </div>

  );
}
Gauge.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  units: PropTypes.string,
  disableHamburger: PropTypes.bool,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  rangeTrip: PropTypes.number,
  setPointValue: PropTypes.number,
  theme: PropTypes.string,
  settingsConfig: PropTypes.object,
  openSetting: PropTypes.func,
  onSetPointClick: PropTypes.func,
  numberOfSettingDiffs: PropTypes.number
};

Gauge.defaultProps = {
  title: "",
  value: null,
  units: "℃",
  theme: "default",
  disableHamburger: false,
  rangeMin: null,
  rangeMax: null,
  rangeTrip: null,
  settingsConfig: {
    canDisableAllAlarms: false,
    intialHighSetpointEnabled: true,
    intialLowSetpointEnabled: true,
    intialTripSetpointEnabled: true,
    rangeMinDisabledLabel: "",
    rangeMaxDisabledLabel: "",
  },
  numberOfSettingDiffs: 0
};
