import './ChildDevices.scss';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import DataTable from 'src/component/UI/DataTable';
import { Map } from 'immutable';
import { Badge } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from 'src/component/UI/Button';

import { getChildDevices } from 'src/module/device/selector';
import { deleteChildDevice } from 'src/module/device/action';

import ConfirmModal from 'src/component/UI/ConfirmModal';
import PermissionFence from 'src/component/PermissionFence';
import { deviceTypeConfig } from 'src/utils/deviceTypeConfig';
import DeviceLink from 'src/component/UI/DeviceLink';


export default function ChildDevices (props) {
  const {
    device
  } = props;
  const deviceId = device?.get('id');

  const [deleteChildModalOpen, setDeleteChildModalOpen] = useState(false);
  const [childId, setChildId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState('');
  const dispatch: any = useDispatch();
  const children: any = useSelector(getChildDevices(deviceId));
  const makeToggleDeleteChildModal = () => {
    return () => {
      toggleDeleteChildModal();
    };
  };
  const toggleDeleteChildModal = () => {
    setDeleteChildModalOpen(!deleteChildModalOpen);
  };
  const deleteChildModalSubmit = () => {
    dispatch(deleteChildDevice(childId, deviceId));
    toggleDeleteChildModal();
  };

  const alarm = (alarms) => {
    if (!alarms) {
      return null;
    }

    if (alarms.length < 3) {
      return alarms.join(", ");
    }
    //  icon with badge (#) with the amount of alarms
    return (
      <div className='alarm-badge'>
        <Badge badgeContent={alarms.length} color="primary">
          <NotificationsActiveIcon fontSize='large' className='bell' />
        </Badge>
      </div>

    );
  };

  const link = (device) => {
    let controllerTag = device.get('parent_type') === 'commLoop' ? undefined : device.get('parent_tag');
    if (device.get('type') === 'controller') {
      controllerTag = device.get('tag');
    }
    return (
      <DeviceLink
        label={device.get('tag')}
        controllerTag={controllerTag}
        circuitTag={device.get('type') === 'circuit' ? device.get('tag') : undefined}
        rawTag={device.get('raw_tag')} />
    );
  };

  return (
    <div className='child-devices'>
      <PermissionFence can='edit-device'>
        <ConfirmModal
          title='Are you sure?'
          message={deleteMessage}
          open={deleteChildModalOpen}
          handleClose={makeToggleDeleteChildModal()}
          handleReject={makeToggleDeleteChildModal()}
          handleConfirm={deleteChildModalSubmit}
          confirmPhrase='DELETE'
        />
      </PermissionFence>

      <div className="child-devices-table">
        <h3>{deviceTypeConfig[device.get('type')]?.children.title}</h3>
        {
          <DataTable
            columns={[
              {
                id: 'devices.tag',
                Header: `${deviceTypeConfig[device.get('type')]?.children.columnTagLabel}`,
                accessor: (row: any) => row.get('tag'),
                sortable: true,
                Cell: (row: any) => {
                  return (
                    link(row.cell.row.original)
                  );
                }
              },
              {
                id: 'devices.model',
                Header: 'Model',
                accessor: (row: any) => row.get('model'),
                sortable: true,
                Cell: (row: any) => { return row.cell.value || ''; }
              },
              {
                id: 'devices.alarm',
                Header: 'Alarms',
                accessor: (row: any) => row.get('alarms'),
                sortable: true,
                Cell: (row: any) => { return alarm(row.cell.value?.toArray()) || ''; }
              },
              {
                Header: ' ',
                accessor: (row: any) => row.get('index'),
                disableSortBy: true,
                Cell: (row: any) => row.cell.row.original.get('invalidated') ? null : (
                  <PermissionFence can='edit-device'>
                    <div className='delete-actions'>
                      <Button
                        onClick={
                          () => {
                            const type = deviceTypeConfig[device.get('type')]?.children.deleteMessage;
                            const typeStr = type ? type?.charAt(0).toUpperCase() + type?.slice(1) : '';
                            toggleDeleteChildModal();
                            setChildId(row.cell.row.original.get('id'));
                            setDeleteMessage(`${typeStr} ${row.cell.row.original.get('tag')} will be deleted`);
                          }
                        }
                        icon={<DeleteIcon style={{ fontSize: 14 }} />}
                      />
                    </div>
                  </PermissionFence>
                )
              },
            ]}
            data={children}
            tableId='childDevicesTable'
          />
        }
      </div>
    </div>
  );
}

ChildDevices.propTypes = {
  device: ImmutablePropTypes.map
};

ChildDevices.defaultProps = {
  device: Map()
};
