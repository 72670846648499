import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchClients, FETCH_CLIENTS }: any = createTypedAction(
  `${PREFIX}FETCH_CLIENTS`,
);

export const { fetchClientsSuccess, FETCH_CLIENTS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CLIENTS_SUCCESS`,
  ['clients', yup.immutable().list().required()]
);

export const { fetchClientsFailed, FETCH_CLIENTS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CLIENTS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchTiers, FETCH_TIERS }: any = createTypedAction(
  `${PREFIX}FETCH_TIERS`,
);

export const { fetchTiersSuccess, FETCH_TIERS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_TIERS_SUCCESS`,
  ['tiers', yup.immutable().list().required()]
);

export const { fetchTiersFailed, FETCH_TIERS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_TIERS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateClient, UPDATE_CLIENT }: any = createTypedAction(
  `${PREFIX}UPDATE_CLIENT`,
  ['clientId', yup.string().required()],
  ['clientRec', yup.immutable().map().required()]
);

export const { updateClientSuccess, UPDATE_CLIENT_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_CLIENT_SUCCESS`,
  ['client', yup.immutable().list().required()]
);

export const { updateClientFailed, UPDATE_CLIENT_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_CLIENT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
