import { createSelector } from 'reselect';

export const getLookupState = (state) =>
  state.lookup;

export const getAlarmSimplification = createSelector(
  [getLookupState],
  (lookup) => {
    return lookup.get('alarm_simplification');
  }
);
