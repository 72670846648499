import { request } from '../api';

export const getConfiguredSearchFields = (action) => {
  const { table } = action;
  const query: any = {};
  if (table) {
    query.table = table;
  }
  const payload: any = {
    url: `/search/meta`,
    query,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const getAllSearchableFields = (action) => {
  const { table } = action;
  const query: any = {};
  if (table) {
    query.table = table;
  }
  const payload: any = {
    url: `/search/meta-fields`,
    query,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const updateConfiguredSearchFields = (action) => {
  const { table, fields } = action;
  const payload: any = {
    url: `/search/meta`,
    options: {
      method: 'PATCH',
      data: {
        table,
        fields
      }
    }
  };

  return request(action, { payload });
};
