import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DataTable from '../component/UI/DataTable';

import { getNewWorkTickets } from 'src/module/workTicket/selector';
import DeviceLink from './UI/DeviceLink';
import { getSingularWorkTicketLabel } from 'src/module/authentication/selector';
import Tooltip from '@mui/material/Tooltip';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { formatAlarmLabel } from 'src/component/AlarmsList';
import { Badge } from '@mui/material';
import { List } from 'immutable';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';

export default function NewWorkTickets () {
  const newWorkTickets = useSelector(getNewWorkTickets);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);
  const newSapNotificationData: any = [];
  newWorkTickets.forEach((alarm:any ) => {
    newSapNotificationData.push({
      sapNotification: alarm.get('sap_notification_id'),
      alarms: alarm.get('alarm'),
      controllerTag: alarm.get('controller_tag', 'N/A'),
      circuitTag: alarm.get('circuit_tag', 'N/A')
    });
  });

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const alarm = (alarms) => {
    if ( !alarms || alarms==="") {
      return "";
    }

    const numAlarms = (alarms.match(/,/g) || []).length + 1;
    if (numAlarms < 3) {
      return formatAlarmLabel(alarms);
    }

    return (
      <Tooltip title={`${formatAlarmLabel(alarms)}`} className='alarm' data-testid='device-link'>
        <div className='alarm-badge'>
          <Badge badgeContent={numAlarms} color="primary" className='alarmBadge'>
            <NotificationsActiveIcon fontSize='large' className='bell' />
          </Badge>
        </div>
      </Tooltip>
    );
  };

  return (
    showRotateMessage ? <DeviceRotate /> :
      <DataTable
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 20, 50]}
        columns={[
          {
            id: 'sap_notifications.work_ticket_number',
            Header: `${singularWorkTicketLabel} No.`,
            disableSortBy: true,
            accessor: (row: any) => row.sapNotification,
          },
          {
            id: 'sap_notifications.circuit',
            Header: 'Circuit',
            width: '80px',
            accessor: (row: any) => {
              const displayTag = !row.circuitTag ? 'N/A' :
                <DeviceLink label={row.circuitTag} circuitTag={row.circuitTag} controllerTag={row.controllerTag}/>;
              return displayTag;
            }
          },
          {
            id: 'controller',
            Header: 'Controller',
            width: '80px',
            accessor: (row: any) => {
              const displayTag = !row.controllerTag ? 'N/A' :
                <DeviceLink label={row.controllerTag} controllerTag={row.controllerTag}/>;
              return displayTag;
            }
          },
          {
            id: 'active_alarms',
            Header: 'Active Alarms',
            accessor: (row: any) => row.alarms,
            Cell: (row: any) => alarm(row.cell.value) || '',

          },
        ]}
        data={List(newSapNotificationData)}
        tableId='newWorkTicketsTable'
      />

  );
}

NewWorkTickets.propTypes = {
};

NewWorkTickets.defaultProps = {
};
