import './AlarmsList.scss';
import _ from 'lodash';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Tooltip from '@mui/material/Tooltip';
import AlertIcon from '@mui/icons-material/ReportProblemOutlined';
import ResetIcon from '@mui/icons-material/CallMissedOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import DataTable from '../component/UI/DataTable';
import { fetchAlarmsExport } from 'src/module/alarm/action';
import { getDevicesSelectedFilters } from '../module/filter/selector';
import { ucwords } from '../utils/utils';
import Button from 'src/component/UI/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import variables from 'src/style/variable/variables.module.scss';
import PermissionFence from 'src/component/PermissionFence';
import { getSingularWorkTicketLabel, getTablePagePreferences, getTemperatureUnit } from 'src/module/authentication/selector';
import { celsiusToFahrenheitConvertor, formatDate } from 'src/utils/utils';
import DeviceLinkCombo from './UI/DeviceLinkCombo';
import FeatureFence from './FeatureFence';
import LockIcon from '@mui/icons-material/Lock';
export const alarmsByStatusLegendLabels = Map({
  'low temp': { label: 'Low Temp' },
  'comm fail': { label: 'Comm Fail' },
  'low load': { label: `Low Load` },
  'hi load': { label: `High Load` },
  'rtd fail': { label: `RTD Fail` },
  'gfi': { label: 'GFI' },
  'controller comm fail': { label: `Controller Comm Fail` },
  'switch fail': { label: `Switch Fail` },
  'reset': { label: `Reset` },
  'i/o fail': { label: `I/O Fail` },
  'gf ct failure': { label: `GF CT Failure` },
  'other': { label: `Other` }
});

export function formatAlarmLabel (alarm) {
  let label: any = '';

  if (alarm) {
    label = alarmsByStatusLegendLabels.getIn([alarm, 'label']);
    if (!label) {
      label = ucwords(alarm);
    }
  } else {
    label = 'N/A';
  }

  return label;
}

export default function AlarmsList (props) {
  const {
    alarmSelector,
    alarmLoadingSelector,
    showSetting,
    showValue,
    showAlarmRaisedAt,
    showAlarmResolvedAt,
    showAlarm,
    showNote,
    showNotification,
    showDescription,
    showActualTemp,
    showMaintainTemp,
    showActualCurrent,
    showActualGFI,
    pagination,
    suppressHeader,
    numOfAlarmsSelector,
    numOfAlarmPagesSelector,
    panelId,
    controllerIdOverride,
    fetchAlarms,
    dismissAlarm,
    acknowledgeAlarm,
    deviceId,
    supressSelectedFilters,
    showDownloadStatsOption,
    showClearAlarmOption,
    showAcknowledgeAlarmOption,
    refetchInterval,
    hideDeviceTag,
    tableId,
    deviceFilters
  } = props;
  const userPagePreference = useSelector(getTablePagePreferences(tableId));
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(userPagePreference);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const selectedFilters = useSelector(getDevicesSelectedFilters);
  const alarmData: any = useSelector(alarmSelector) || [];
  const loading: any = useSelector(alarmLoadingSelector);
  const numPages: any = useSelector(numOfAlarmPagesSelector);
  const totalAlarms: any = useSelector(numOfAlarmsSelector);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);

  const temperatureUnit = useSelector(getTemperatureUnit);
  const dispatch = useDispatch();

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setSort(_sort);
    setFilter(_filter);
  };

  const getSelectedFilters = () => {
    return supressSelectedFilters ? Map({}) : selectedFilters;
  };
  const ref_filters = useRef(null);
  const ref_others = useRef(null);
  useEffect(() => {
    const others = `${controllerIdOverride} ${panelId} ${deviceId} ${filter} ${page} ${pageSize} ${sort} ${deviceFilters}`;
    if (!_.isEqual(ref_filters.current, selectedFilters.toJS()) || others !== ref_others.current) {
      ref_filters.current = selectedFilters.toJS();
      ref_others.current = others;
      const optionalFilters: any = {
        controllerId: controllerIdOverride,
        panelId,
        deviceId,
        sort,
        filter
      };
      if (deviceFilters) {
        optionalFilters.deviceFilters = deviceFilters;
      }

      dispatch(fetchAlarms(getSelectedFilters(), Map(optionalFilters), page, pageSize));
    }
  }, [selectedFilters, controllerIdOverride, panelId, deviceId, filter, page, pageSize, sort, deviceFilters]);

  useEffect(() => {
    if (deviceId && refetchInterval && refetchInterval > 0) {
      const interval = setInterval(() => {
        // last parameter false (showLoadingIndicator) is a flag for fetching device's active alarms every 10 seconds
        const optionalFilters = {
          controllerId: controllerIdOverride,
          panelId,
          deviceId,
          sort,
          filter
        };
        dispatch(fetchAlarms(getSelectedFilters(), Map(optionalFilters), page, pageSize));
      }, refetchInterval);
      return () => {
        clearInterval(interval);
      };

    }
  }, []);

  const columns: any = [];

  columns.push({
    id: 'critical',
    Header: (<Tooltip title='Is Critical?'><AlertIcon className="alert-icon" width={16} height={16} /></Tooltip>),
    accessor: (alarm) => (alarm.get('critical')) ? 1 : 0,
    Cell: (row: any) => (row.cell.value ? (<AlertIcon className="alert-icon" style={{ fontSize: 16 }} />) : ''),
    width: '20px',
    ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
  });

  if (!hideDeviceTag) {
    columns.push({
      id: 'device_tag',
      Header: 'Device Tag',
      disableSortBy: !pagination,
      accessor: (row: any) => row.get('device_tag'),
      maxWidth: '200px',
      Cell: (row: any) => {
        return (
          <DeviceLinkCombo device={row.cell.row.original} />
        );
      },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  if (showNote) {
    columns.push({
      id: 'notes',
      Header: 'Notes',
      disableSortBy: true,
      accessor: (row: any) => row.get('notes', []).join(', '),
      Cell: (row: any) => (formatAlarmLabel(row.cell.value)),
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  if (showAlarm) {
    columns.push({
      id: 'alarm_group',
      Header: 'Alarm',
      disableSortBy: !pagination,
      accessor: (row: any) => row.get('alarm_group'),
      Cell: (row: any) => (formatAlarmLabel(row.cell.value)),
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  if (showNotification) {
    columns.push({
      id: 'sap_notifications.sap_notification_id',
      Header: singularWorkTicketLabel,
      disableSortBy: !pagination,
      accessor: (row: any) => row.get('notifications', []).join(', '),
      maxWidth: '140px',
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  if (showDescription) {
    columns.push({
      id: 'description',
      Header: 'Description',
      disableSortBy: !pagination,
      accessor: (row: any) => row.get('description'),
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  if (showValue) {
    columns.push({
      id: 'value',
      Header: 'Alarm Value',
      disableSortBy: true,
      accessor: (row: any) => {
        const alarmGroup = row.get('alarm_group');
        const actualTemp = row.get('actual_temperature');
        const actualCurrent = row.get('actual_current');
        const actualGroundCurrent = row.get('actual_gnd_current');

        if (!alarmGroup) {
          return 'N/A';
        } else if (alarmGroup.indexOf('temp') !== -1) {
          return actualTemp && temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(actualTemp) : actualTemp;
        } else if (alarmGroup === 'gfi') {
          return actualGroundCurrent;
        } else {
          return actualCurrent;
        }
      },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  if (showActualTemp) {
    columns.push({
      id: 'real_temperature',
      Header: 'Actual Temp',
      disableSortBy: true,
      accessor: (row: any) => {
        const alarm = row.get('alarm');
        const realTemp = row.get('real_temperature');

        if (!alarm) {
          return 'N/A';
        } else {
          return realTemp && temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(realTemp) : realTemp;
        }
      },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }
  if (showMaintainTemp) {
    columns.push({
      id: 'maintain_temp',
      Header: 'Maintain Temp',
      disableSortBy: true,
      accessor: (row: any) => {
        const alarm = row.get('alarm');
        const realMaintainTemp = row.get('maintain_temp');

        if (!alarm) {
          return 'N/A';
        } else {
          return realMaintainTemp && temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(realMaintainTemp) : realMaintainTemp;
        }
      },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }
  if (showActualCurrent) {
    columns.push({
      id: 'real_current',
      Header: 'Actual Current',
      disableSortBy: true,
      accessor: (row: any) => {
        const alarm = row.get('alarm');
        const realCurrent = row.get('real_current');

        if (!alarm) {
          return 'N/A';
        } else {
          return realCurrent;
        }
      },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }
  if (showActualGFI) {
    columns.push({
      id: 'real_ground_current',
      Header: 'Actual GFI',
      disableSortBy: true,
      accessor: (row: any) => {
        const alarm = row.get('alarm');
        const realGFI = row.get('real_ground_current');

        if (!alarm) {
          return 'N/A';
        } else {
          return realGFI;
        }
      },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }
  if (showSetting) {
    columns.push({
      id: 'setting',
      Header: 'Setting',
      disableSortBy: !pagination,
      accessor: (row: any) => {
        const alarm = row.get('alarm');
        if (alarm?.includes('temp')) {
          return temperatureUnit === 'F' && row.get('setting') ? celsiusToFahrenheitConvertor(row.get('setting')) : row.get('setting');
        }
        return row.get('setting');
      },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  if (showAlarmRaisedAt) {
    columns.push({
      id: 'alarm_raised_at',
      Header: 'Raised At',
      disableSortBy: !pagination,
      accessor: (row: any) => row.get('alarm_raised_at'),
      Cell: (row: any) => (row.cell.value ? (<>{formatDate(row.cell.value)}</>) : ''),
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  if (showAlarmResolvedAt) {
    columns.push({
      id: 'archived_at',
      Header: 'Resolved At',
      disableSortBy: !pagination,
      accessor: (row: any) => row.get('archived_at'),
      Cell: (row: any) => (row.cell.value ? (<>{formatDate(row.cell.value)}</>) : ''),
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  const pageInfo = { page, pageSize, filter, sort, deviceId };

  const handleResetAlarm = (row) => {
    let childId = null, parentId = null;
    const childTag = row.get('device_tag');
    const parentTag = row.get('parent_tag');
    if (parentTag && !childTag) {
      parentId = row.get('device_id');
    }
    else if (childTag) {
      childId = row.get('device_id');
    }
    const alarmId = row.get('id');
    dispatch(dismissAlarm('dismiss', parentId, childId, [alarmId], null, null, getSelectedFilters(), pageInfo, null));
  };

  const handleAcknowledgeAlarm = (row) => {
    let childId = null, parentId = null;
    const childTag = row.get('device_tag');
    const parentTag = row.get('parent_tag');
    if (parentTag && !childTag) {
      parentId = row.get('device_id');
    }
    else if (childTag) {
      childId = row.get('device_id');
    }
    const alarmId = row.get('id');
    dispatch(acknowledgeAlarm(parentId, childId, [alarmId], getSelectedFilters(), pageInfo));
  };
  if (showAcknowledgeAlarmOption) {
    columns.push({
      id: 'acknowledged',
      Header: (<Tooltip title='Alarm acknowledgement status'><div style={{ display: 'flex' }}>Status<InfoIcon className="alert-icon" width={16} height={16} style={{ marginLeft: '5px' }} /></div></Tooltip>),
      disableSortBy: !pagination,
      accessor: (row: any) => row.get('acknowledged'),
      Cell: (row: any) => (row.cell.row.original?.get('acknowledged') ? <div className='acknowledge-indication make-unselectable'>ack</div> :
        <div className='alarm-actions'>
          <PermissionFence can={['edit-eht-alarm']} noPermissionComponent={<div className='not-acknowledge-indication make-unselectable'>new</div>}>
            <div className='not-acknowledge-indication make-unselectable' onClick={() => { handleAcknowledgeAlarm(row.cell.row.original); }}>new</div>
          </PermissionFence>
        </div>
      ),
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }
  const restAlarmComponent = (disabled, alarm) => (
    <div className='alarm-actions'>
      <Button compact disabled={disabled} onClick={() => { handleResetAlarm(alarm); }} icon={<ResetIcon style={{ fontSize: 14 }} />}></Button>
    </div>
  );

  if (showClearAlarmOption) {
    columns.push({
      Header: ' ',
      disableSortBy: !pagination,
      Cell: (row: any) => (

        <PermissionFence can='reset-alarms'>
          <FeatureFence can={['program-device']}
            // !feature & permission = show lock
            noPermissionComponent={
              <Tooltip title={'Talk to your system administrator to gain access to reset alarms' }>
                <div style={{ position: 'relative' }}>
                  {restAlarmComponent(true, row.cell.row.original)}
                  <span style={{ position: 'absolute', top: '-6px', right: '-2px' }}><LockIcon fontSize='medium' /></span>
                </div>
              </Tooltip>
            }
          >
            {/* Feature & permission = show normal */}
            <Tooltip title='Reset Alarm'>{restAlarmComponent(false, row.cell.row.original)}</Tooltip>
          </FeatureFence>
        </PermissionFence>
      ),
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }

  const handleClick = () => {
    dispatch(fetchAlarmsExport(getSelectedFilters(), panelId));
  };

  const iconStyles = { color: variables.primarySolid };
  return (
    <div className='alarms-list'>
      {!suppressHeader && (<h3>{props.header} {showDownloadStatsOption ? <Button onClick={() => handleClick()} icon={<FileDownloadOutlinedIcon style={iconStyles} />}></Button> : null} </h3>)}
      <DataTable
        filterable={!suppressHeader}
        filterLabel='Search Alarms'
        pagination={pagination}
        unit={selectedFilters.get('unit')}
        numPages={numPages}
        totalRecords={totalAlarms}
        loading={loading}
        data={alarmData}
        columns={columns}
        fetchPage={fetchPage}
        placeholder={<><span>No alarms to see here </span><span role='img' aria-label='Sweat Smile'>😅</span></>}
        tableId={tableId}
      />
    </div>
  );
}

AlarmsList.propTypes = {
  alarmSelector: PropTypes.func,
  alarmLoadingSelector: PropTypes.func,
  pagination: PropTypes.bool,
  showActive: PropTypes.bool,
  showSetting: PropTypes.bool,
  showValue: PropTypes.bool,
  showAlarm: PropTypes.bool,
  showNote: PropTypes.bool,
  showNotification: PropTypes.bool,
  showDescription: PropTypes.bool,
  showAlarmRaisedAt: PropTypes.bool,
  showActualTemp: PropTypes.bool,
  showMaintainTemp: PropTypes.bool,
  showActualCurrent: PropTypes.bool,
  showActualGFI: PropTypes.bool,
  hideCircuit: PropTypes.bool,
  hideController: PropTypes.bool,
  suppressHeader: PropTypes.bool,
  header: PropTypes.string,
  numOfAlarmsSelector: PropTypes.func,
  numOfAlarmPagesSelector: PropTypes.func,
  panelId: PropTypes.string,
  controllerIdOverride: PropTypes.string,
  fetchAlarms: PropTypes.func,
  dismissAlarm: PropTypes.func,
  acknowledgeAlarm: PropTypes.func,
  deviceId: PropTypes.string,
  showAlarmResolvedAt: PropTypes.bool,
  supressSelectedFilters: PropTypes.bool,
  showDownloadStatsOption: PropTypes.bool,
  showClearAlarmOption: PropTypes.bool,
  showAcknowledgeAlarmOption: PropTypes.bool,
  showCombinedCircuitAndController: PropTypes.bool,
  hideDeviceTag: PropTypes.bool,
  refetchInterval: PropTypes.number,
  tableId: PropTypes.string,
  deviceFilters: PropTypes.array
};

AlarmsList.defaultProps = {
  pagination: false,
  showAlarm: false,
  showNotification: false,
  showDescription: false,
  showActive: false,
  showSetting: false,
  showValue: false,
  showNote: false,
  showAlarmRaisedAt: false,
  showActualTemp: false,
  showMaintainTemp: false,
  showActualCurrent: false,
  showActualGFI: false,
  suppressHeader: false,
  header: 'Alarms',
  showAlarmResolvedAt: false,
  deviceId: null,
  panelId: null,
  supressSelectedFilters: false,
  showDownloadStatsOption: false,
  showClearAlarmOption: false,
  showAcknowledgeAlarmOption: false,
  hideDeviceTag: false,
  refetchInterval: 0,
  tableId: 'default',
  deviceFilters: null
};
