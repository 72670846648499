import { createSelector } from 'reselect';

export const getPrefetchFromState = (state) => {
  return state.prefetch;
};

export const getIsPrefetching = createSelector(
  [getPrefetchFromState],
  (prefetch) => {
    return prefetch.get('isPrefetching', false);
  }
);

export const getHasInitialized = createSelector(
  [getPrefetchFromState],
  (prefetch) => {
    return prefetch.get('hasInitialized', false);
  }
);

export const prefetchFailed = createSelector(
  [getPrefetchFromState],
  (prefetch) => {
    return prefetch.get('prefetchFailed', false);
  }
);
