import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

export const getNoteState = (state) =>
  state.note;

export const getNotes = createSelector(
  [getNoteState],
  (note) => {
    const allData = note?.getIn(['notes', 'data'], List());
    const pageIds = note?.getIn(['notePage', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return pageData;
  }
);

export const getLoadingNotes = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['notePage', 'loading']) || false;
  }
);

export const getNumNotesPages = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['notePage', 'totalPages']) || 0;
  }
);

export const getTotalNotes = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['notePage', 'total']) || 0;
  }
);

export const getLotoNotes = createSelector(
  [getNoteState],
  (note) => {
    const allData = note?.getIn(['notes', 'data'], List());
    const pageIds = note?.getIn(['lotoPage', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return pageData;
  }
);
export const getLoadingLotoNotes = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['lotoPage', 'loading']) || false;
  }
);

export const getNumLotoNotesPages = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['lotoPage', 'totalPages']) || 0;
  }
);

export const getTotalLotoNotes = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['lotoPage', 'total']) || 0;
  }
);

export const getNuisanceNotes = createSelector(
  [getNoteState],
  (note) => {
    const allData = note?.getIn(['notes', 'data'], List());
    const pageIds = note?.getIn(['nuisancePage', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return pageData;
  }
);

export const getLoadingNuisanceNotes = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['nuisancePage', 'loading']) || false;
  }
);

export const getNumNotesNuisancePages = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['nuisancePage', 'totalPages']) || 0;
  }
);

export const getTotalNuisanceNotes = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['nuisancePage', 'total']) || 0;
  }
);
export const getAllNotes = createSelector(
  [getNoteState],
  (note) => {
    return note.getIn(['notes', 'data'], Map());
  }
);
