import './GlobalProgressIndicator.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsInProgress, getProgressFailed, getProgressLabel, getProgressTimeout } from 'src/module/progress/selector';
import { CircularProgress, Collapse, Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { setProgressFailed } from 'src/module/progress/action';

export default function GlobalProgressIndicator () {
  const dispatch = useDispatch();
  const isInProgress: any = useSelector(getIsInProgress);
  const progressFailed: any = useSelector(getProgressFailed);
  //const progress: any = useSelector(getProgress);
  const label: any = useSelector(getProgressLabel);
  const timeout: any = useSelector(getProgressTimeout) || 5000;
  const [isInProgressState, setIsInProgressState] = useState(false);

  useEffect(() => {
    if (isInProgress === true) {
      // set timeout to clear progress after x amount of time as a backup
      setIsInProgressState(isInProgress);
      const timeoutId = setTimeout(() => {
        dispatch(setProgressFailed(100, "Action Failed"));
      }, timeout);
      return () => clearTimeout(timeoutId);
    }
    else if (isInProgress === false) {
      // timeout so progress bar wont disappear immediately
      const timeoutId = setTimeout(() => {
        setIsInProgressState(false);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [isInProgress]);


  return (
    isInProgressState ?
      <Paper
        className='progress-container'
      >
        <Collapse in={!isInProgress && !progressFailed} timeout={500} unmountOnExit>
          <CheckCircleIcon sx={{ fontSize: 96, color: '#97cd4e' }} />
        </Collapse>
        <Collapse in={!isInProgress && progressFailed} timeout={500} unmountOnExit>
          {/* TODO use variable instead of hardcode */}
          <CancelIcon sx={{ fontSize: 96, color: '#e81e4f' }} />
        </Collapse>
        <Collapse in={isInProgress} timeout={500} unmountOnExit>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress size={80} />
            <Typography variant="body1" sx={{ mt: 2, fontSize: 18, fontWeight: 'bold' }}>{label}</Typography>
          </div>
        </Collapse>
        <Collapse in={!isInProgress} timeout={500} unmountOnExit>
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 18 }}>
            {label}
          </Typography>
        </Collapse>
      </Paper>
      : null
  );
}
