import './ErrorScreen.scss';

import React from 'react';
import Button from 'src/component/UI/Button';

export default function ErrorScreen () {

  const handleRefresh = () => {
    window.location.href='/';
  };
  return (
    <div className='error-screen'>
      <div className='error-screen-text'>
        <h1>Houston, we have a problem!</h1>
        <p>Our engineering team is already aware of the issue and is looking into it.</p>
        <Button onClick={() => handleRefresh()} color="primary" variant="contained" fullWidth type="submit" cta>Return To Homepage</Button>
      </div>
    </div>
  );
}
