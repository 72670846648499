import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './TestingReport.scss';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import MultipleSelect from 'src/component/MultipleSelect';
import variables from 'src/style/variable/variables.module.scss';

export default function AlarmChecksMatrix (props) {
  const {
    alarms,
    onChange,
    readOnly
  } = props;
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);

  const alarmsTestMatrix =
  [
    // [null      ,'Visual Inspection','Pt. to Pt.','RTD Fail Test', 'Voltage Test','Current Test','IR'  , 'Resistance', 'RTD Resistance','SSR Test', 'Voltage Test', 'Current Test', 'IR'  ,'Resistance'],
    ['Forced On', true, true, true, false, true, false, false, false, false, false, true, false, false],
    ['Ops Request', true, true, true, false, true, false, false, false, false, false, true, false, false],
    ['Low Temp', true, true, true, false, true, false, false, true, false, false, true, false, false],
    ['RTD Fail', true, false, false, false, true, false, false, true, false, false, true, false, false],
    ['GFI', true, false, false, true, true, true, true, false, false, true, true, true, true],
    ['Switch Fail', true, false, false, true, true, false, false, false, true, false, true, false, false],
    ['Low Load', true, false, false, true, true, true, true, false, true, true, true, true, true],
  ];
  const dropDownList = alarmsTestMatrix.map(x => x[0]);

  const [filteredAlarmTestMatrix, setFilteredAlarmTestMatrix] = useState(alarmsTestMatrix.filter(x => alarms?.includes(x[0])));

  useEffect(() => {
    setFilteredAlarmTestMatrix(alarmsTestMatrix.filter(x => alarms?.includes(x[0] )));
  }, [alarms]);


  const onAlarmSelectChange = (selected) => {
    onChange(['info', 'alarms'], List(selected));
  };

  const testGroupings = {
    sections: [
      {
        title: 'General Tests',
        tests: ['Visual Inspection', 'Pt. to Pt.', 'RTD Fail Test'],
      },
      {
        title: 'At Control Panel',
        tests: ['Voltage Test', 'Current Test', 'IR', 'Resistance', 'RTD Resistance', 'SSR Test']
      },
      {
        title: 'At Power & All Splice Boxes',
        tests: ['Voltage Test', 'Current Test', 'IR', 'Resistance']
      }
    ]
  };


  const output = {};
  testGroupings.sections.forEach(section => {
    output[section.title] = {};
    section.tests.forEach(test => {
      output[section.title][test] = false;
    });
  });

  return (
    <Paper className='alarms-check-matrix-container'>
      <h3>Alarm Checks Matrix</h3>
      <MultipleSelect
        options={dropDownList}
        disabled={readOnly}
        preSelected={alarms?.toJS()}
        onChangeFn={onAlarmSelectChange}
        label={'Alarm Type'} />
      <TableContainer style={{ maxHeight: '90vh', marginTop: '10px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className="main-header-row">
              <TableCell align="center" colSpan={1} rowSpan={2} className='main-header-cell sticky-column sticky-column-override-z'>Alarm</TableCell>

              {testGroupings.sections.map(element => {
                return (<TableCell align="center" key={element.title} colSpan={element.tests.length} className={`main-header-cell`}>{element.title}</TableCell>);
              })}
            </TableRow>
            <TableRow className='matrix-header-row'>
              {testGroupings.sections.map(element => {
                return element.tests.map(test => {
                  return (<TableCell className={`${isMobile ? 'matrix-header-cell-remove-padding' : '' }`} key={test} >{test}</TableCell>);
                });
              })}
            </TableRow>

          </TableHead>
          <TableBody>
            {
              filteredAlarmTestMatrix.map((alarm) => {
                return <TableRow key={`${alarm}-row`} className='checkbox-rows'>
                  {
                    alarm.map((cell, index) => {
                      return index === 0 ? <TableCell key={`${cell}-cell`} className='sticky-column'>{cell}</TableCell> :
                        <TableCell align='center' key={`${index}-cell`}>
                          {cell ? <CloseIcon/> : null}
                        </TableCell>;
                    })
                  }

                </TableRow>;
              })

            }
          </TableBody>
        </Table>

      </TableContainer>
      <p><i>Minimum recommended values for IR: 20MΩ at 1000V megger for M.I trace 1000MΩ at 2500V megger for S.R trace (apply 1000V megger in panel for S.R
        trace, 2500V megger in JB/SB).</i></p>
    </Paper>
  );
}

AlarmChecksMatrix.propTypes = {
  deviceId: PropTypes.string,
  alarms: ImmutablePropTypes.list,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};
