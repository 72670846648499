import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import AlarmsList from 'src/component/AlarmsList';
import UnitSelector from 'src/component/UI/UnitSelector';
import { getAlarmHistory, getAlarmHistoryLoading, getAlarmHistoryNumPages, getAlarmHistoryTotal } from 'src/module/alarm/selector';
import { fetchArchivedAlarms } from 'src/module/alarm/action';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';
import { isWhitelabel } from 'src/utils/utils';

export default function AlarmHistoryScreen () {

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div id='alarms-history' className='page'>
      <Helmet>
        <title>{title} - Alarm History</title>
      </Helmet>

      <Grid container spacing={3}>

        <Grid item xs={12} md={12}>
          <Paper>
            <UnitSelector />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper>
            {showRotateMessage ? <><h3>Alarm Historian</h3><DeviceRotate/></> :
              <AlarmsList
                fetchAlarms={fetchArchivedAlarms}
                alarmSelector = {getAlarmHistory}
                numOfAlarmsSelector={getAlarmHistoryTotal}
                numOfAlarmPagesSelector={getAlarmHistoryNumPages}
                alarmLoadingSelector={getAlarmHistoryLoading}
                pagination
                showAlarmRaisedAt
                showAlarm
                showActualTemp
                showMaintainTemp
                showActualCurrent
                showActualGFI
                showNote={!isMobile}
                showNotification={!isMobile}
                showSetting
                showValue
                header="Alarm Historian"
                showAlarmResolvedAt
                tableId='alarmsHistoryTable'
              />
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
