import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import yup from 'src/utils/yup';
import { useFormik } from 'formik';

import Button from 'src/component/UI/Button';

export default function InviteUserModal (props) {
  const handleSubmit = (values) => {
    props.handleSubmit(values);
    formik.resetForm();
  };

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    firstName: yup
      .string('First Name')
      .required('First Name is required'),
    lastName: yup
      .string('Last Name')
      .required('Last Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='invite-user-modal-title'
    >
      <div className='modal invite-user-modal'>
        <h2 id='invite-user-modal-title'>Invite User to Facility</h2>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="First Name"
            type="text"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            label="Last Name"
            type="text"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <div className='button-bar'>
            <Button variant="contained" fullWidth onClick={props.handleClose} >Cancel</Button>
            <Button color="primary" variant="contained" fullWidth type="submit" cta>Invite User</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

InviteUserModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

InviteUserModal.defaultProps = {
};
