import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

export const getAdminState = (state) =>
  state.admin;

export const getRootState = (state) =>
  state;

export const getClients = createSelector(
  [getAdminState],
  (admin) => {
    return admin.getIn(['admin', 'clients'], List());
  }
);

export const getTiers = createSelector(
  [getAdminState],
  (admin) => {
    return admin.getIn(['admin', 'tiers'], List());
  }
);


export const getClient = (id) => createSelector(
  [getAdminState],
  (admin) => {
    const clients = admin.getIn(['admin', 'clients'], List());
    return clients.find(client => client.get('id') === id) || Map();
  }
);
