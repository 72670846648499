import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import PlaceholderChart from 'src/screen/Protected/DashboardScreen/assets/placeholder-chart.png';
import { getAlarmHistoricalSummary } from 'src/module/alarm/selector';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';

export default function AlarmHistory () {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const alarmHistory = useSelector(getAlarmHistoricalSummary);
  const WeekTickValues: Array<any> = [];
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const currDate = moment().add(-11, 'weeks');
  const today = moment();

  do {
    WeekTickValues.push(moment(currDate.startOf('isoWeek')).toDate());
    currDate.add(7, 'days');
  } while (currDate.isBefore(today));
  const alarmHistoryData = alarmHistory.toJS();
  const listLabels =  alarmHistoryData.map(x => `${moment(x.date).week().toString()}|${x.date}`);
  const numberOfUniquie = new Set(alarmHistoryData.map(x => moment(x.date).week().toString()));
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    lineTension: 0.5,
    stacked: false,
    elements: {
      point: {
        radius: 0
      }
    },
    plugins: {
      title: {
        display: false,
        font: {
          size: 54
        }
      },
      legend: {
        labels: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          text: 'Weeks', display: true, font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        },
        grid: {
          drawOnChartArea: false,
          display: false
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          },
          callback: (label) => `${listLabels[label].split("|")[0]}`,
          stepSize: 1,
          maxRotation: 0,
          maxTicksLimit: numberOfUniquie.size
        }

      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          text: 'Temperature', display: true, font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        },
        grid: {
          drawOnChartArea: false,
          display: false
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
          display: false
        },
        title: {
          text: 'Total Alarms', display: true, font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      },
    },
  };
  const allData = {
    labels: [],
    temperature: [],
    totalAlarms: [],
    totalCriticalAlarm: [],
    totalByHtc: []
  };
  alarmHistoryData.forEach(element => {

    allData.labels.push(moment(element.date).format('YYYY-MM-DD'));

    allData.temperature.push(element.low);
    allData.totalAlarms.push(element.total);
    allData.totalCriticalAlarm.push(element.total_critical);
    allData.totalByHtc.push(element.total_by_htc);
  });
  const data = {
    labels: allData.labels,
    datasets: [
      {
        label: 'Ambient Temperature',
        data: allData.temperature,
        borderColor: variables.quaternary,
        backgroundColor: variables.quaternary,
        yAxisID: 'y',
      },
      {
        label: 'Total Alarms',
        data: allData.totalAlarms,
        borderColor: variables.primary,
        backgroundColor: variables.primary,
        yAxisID: 'y1',
      },
      {
        label: 'Total Critical Alarms',
        data: allData.totalCriticalAlarm,
        borderColor: variables.tertiary,
        backgroundColor: variables.tertiary,
        yAxisID: 'y1',
      },
      {
        label: 'Total By HTC',
        data: allData.totalByHtc,
        borderColor: variables.secondary,
        backgroundColor: variables.secondary,
        yAxisID: 'y1',
      },
    ],
  };
  return showRotateMessage ? <DeviceRotate /> : (alarmHistoryData.length ? (
    <div style={{ height: '400px', margin: 'auto' }}>
      <Line options={options} data={data} />
    </div>
  ) : (
    <div className="placeholder-graph">
      <div className='outer placeholder-graph-overlay'>
        <div className="middle">
          <div className="inner">No History for the Current Selection</div>
        </div>
      </div>
      <img width='100%' src={PlaceholderChart} alt="Placeholder Graph" />
    </div>
  ));
}
