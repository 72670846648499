import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './TestingReport.scss';
import { Grid, Paper, TextField } from '@mui/material';
import { formatDate } from 'src/utils/utils';
import variables from 'src/style/variable/variables.module.scss';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
export default function FinalizeAndSignOff ({
  onChange,
  data,
  readOnly,
  passed,
  showPassFail
} ) {


  const user: any = useSelector((state: any) => state.authentication.get('user'));
  const onComment = (field, val) => {
    onChange(['signOff', field], val);
  };
  return (
    <Paper className='finalize-container'>
      <Grid container>
        <Grid item xs={12} sm={showPassFail ? 4 : 8}><b>Verified By:</b> {user.get('first_name', null)} {user.get('last_name', null)}</Grid>
        <Grid item xs={12} sm={4}><b>Created At:</b> {formatDate(new Date())}</Grid>
        {showPassFail  &&<Grid item xs={12} sm={4}>
          {
            passed ? <>
              <CheckCircleIcon style={{ color: variables.success, fontSize: 24, position: 'absolute' }} />
              <b style={{ marginLeft: '30px' }}>Passed </b></>
              :
              <>
                <CancelIcon style={{ color: variables.error, fontSize: 24, position: 'absolute' }} />
                <b style={{ marginLeft: '30px' }}>Failed </b>
              </>
          }


        </Grid>}

        <Grid item xs={12}>
          <TextField
            multiline
            rows={8}
            fullWidth
            value={data?.get('recommendations')}
            disabled={readOnly}
            label={'Recommendations/Notes'}
            onChange={(e) => { onComment('recommendations', e.target.value); }}
          />
        </Grid>

      </Grid>
    </Paper>

  );
}

FinalizeAndSignOff.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  passed: PropTypes.bool,
  showPassFail: PropTypes.bool

};
FinalizeAndSignOff.defaultProps = {
  showPassFail: false,
  passed: false,

};
