import './Filter.scss';

import React from 'react';
import AutoCompleteSelect from './AutoCompleteSelect';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux';
import DeviceSelectorField from './DeviceSelectorField';
import { ClickAwayListener } from '@mui/material';
import { langLookUpText } from 'src/utils/langLookUp';

export default function  Filter (props) {
  const {
    type,
    options,
    onChange,
  } = props;
  const label = langLookUpText(type.replace(/\s+/g, '_') + '_filter_label');
  const [edit, setEdit] = React.useState(false);
  let valueSet = { label: props.value, value: props.value };

  if (props.value  === ''  || props.value === null) {
    valueSet = { label: 'All', value: 'all' };
  } else if (type === "controller") {
    valueSet =  { label: props.value.tag, value: props.value.tag };
  }

  const [value, setValue] = React.useState(valueSet);
  const optionsArray = [];
  const dispatch = useDispatch();
  if (type !== 'controller') {
    if (options && options.forEach) {
      options?.forEach(opt => (opt.get(type.toLowerCase()) === null ||
        opt.get(type.toLowerCase()) === '') ? '' : optionsArray.push(opt.get(type.toLowerCase())));
    }
  }


  const onClick = () => {
    setEdit(true);
  };

  const handleChange = (value, title) => {
    if ((value === null) && (title === null)) {
      return;
    } else if ((value === null || value === '')) {
      setValue(null);
      dispatch(onChange(null));
      return;
    } else {
      let labelInput = value;
      if (type === 'controller') {
        labelInput = value.id;
      }
      setValue({ label: labelInput, value: labelInput });
      dispatch(onChange(value));
    }
    setEdit(false);
  };

  if (optionsArray.length === 0 && (type !== 'controller' && type !== 'settings')) {
    return (<></>);
  }

  const onClickAwayHandler = () => {
    setEdit(false);
    if (value === null || (value as any) === '') {
      setValue({ label: 'All', value: 'all' });
      dispatch(onChange(null));
    }
  };
  return (
    <div>
      <div className='filter-container'>
        <div className='label'>
          {label}
        </div>
        <div className='value'>
          {edit ?
            <ClickAwayListener onClickAway={onClickAwayHandler}>
              <div>
                {type === 'controller' ?
                  <DeviceSelectorField deviceId={props.value.id} value={value} hideLabel={true} onChange={handleChange} error={false} helperText='' type='controller' />
                  :
                  <AutoCompleteSelect onChangeFn={handleChange} options={optionsArray} id={type} initialValues={value} />
                }
              </div>
            </ClickAwayListener>
            :
            <span>
              <span className='text'>
                {valueSet.label}
              </span>
              <EditIcon className='icon' onClick={onClick} />
            </span>
          }
        </div>
      </div>
    </div>


  );
}

Filter.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.any,
  onChange: PropTypes.func
};

Filter.defaultProps = {
  type: '',
  value: null,
  options: [],
  onChange: null
};
