import './TemperatureUnitSwitch.scss';
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Map } from 'immutable';
import { updateUser } from 'src/module/user/action';
import { getTemperatureUnit } from 'src/module/authentication/selector';

export default function TemperatureUnitSwitch () {
  const dispatch = useDispatch();
  const temperatureUnit = useSelector(getTemperatureUnit);

  const handleChange = (e) => {
    let unit = 'C';
    if (!e.target.checked) {
      unit = 'F';
    }
    dispatch(updateUser(null, Map({ temperatureUnit: unit })));
  };
  return (
    <div className='temperature-unit-switch'>
      <h4>Temperature Unit</h4>
      <FormGroup>
        <FormControlLabel
          label=''
          control={<Switch sx={{ m: 1 }} checked={temperatureUnit === 'F' ? false : true} />}
          onChange={(e) => handleChange(e)}
        />
      </FormGroup>
    </div>
  );
}
