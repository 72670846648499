import { takeLatest, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

import * as devicesApi from '../../service/api/devices';
import * as alarmActions from './action';

export function * filterRoot () {
  yield takeLatest(alarmActions.FETCH_UNITS, fetchUnits);
  yield takeLatest(alarmActions.FETCH_SYSTEMS, fetchSystems);
  yield takeLatest(alarmActions.FETCH_COMMODITIES, fetchCommodities);
  yield takeLatest(alarmActions.FETCH_LOCATIONS, fetchLocations);
  yield takeLatest(alarmActions.FETCH_PRIORITIES, fetchPriorities);
}



function * fetchUnits (action) {
  try {
    const units = yield call(devicesApi.getUnits, action);
    yield put(alarmActions.fetchUnitsSuccess(units));
  } catch (err) {
    yield put(alarmActions.fetchUnitsFailed(Map(err)));
  }
}

function * fetchSystems (action) {
  try {
    const systems = yield call(devicesApi.getSystems, action);
    yield put(alarmActions.fetchSystemsSuccess(systems));
  } catch (err) {
    yield put(alarmActions.fetchSystemsFailed(Map(err)));
  }
}

function * fetchCommodities (action) {
  try {
    const commodities = yield call(devicesApi.getCommodities, action);
    yield put(alarmActions.fetchCommoditiesSuccess(commodities));
  } catch (err) {
    yield put(alarmActions.fetchCommoditiesFailed(Map(err)));
  }
}

function * fetchLocations (action) {
  try {
    const locations = yield call(devicesApi.getLocations, action);
    yield put(alarmActions.fetchLocationsSuccess(locations));
  } catch (err) {
    yield put(alarmActions.fetchLocationsFailed(Map(err)));
  }
}

function * fetchPriorities (action) {
  try {
    const priorities = yield call(devicesApi.getPriorities, action);
    yield put(alarmActions.fetchPrioritiesSuccess(priorities));
  } catch (err) {
    yield put(alarmActions.fetchPrioritiesFailed(Map(err)));
  }
}
