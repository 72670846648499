
import './LookupTable.scss';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';

import PropTypes from 'prop-types';

import DataTable from './UI/DataTable';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from 'src/component/UI/Button';
import EditableCell from './UI/EditableCell';
import AddLookupItemModal from 'src/component/UI/AddLookupItemModal';

import { getAlarmSimplification } from 'src/module/lookup/selector';
import { deleteLookupItem, fetchLookupItems, updateLookupItem } from 'src/module/lookup/action';
import { addLookupItem }  from 'src/module/lookup/action';

export default function LookupTable (this: any, props) {
  const alarm_simplefication_settings: any = useSelector(getAlarmSimplification) || [];
  const dispatch = useDispatch();
  const {
    type
  } = props;

  let title: string = null;

  if ( type === 'alarm_simplification') {
    title = 'Alarm Specifications';
  }

  const [addLookupItemModalOpen, setAddLookupItemModalOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchLookupItems(type));
  }, [dispatch, type]);

  const handleLookupItemUpdate = (row) => {
    return (value) => {
      dispatch(updateLookupItem(type, row.key, value));
    };
  };

  const handleLookupItemDelete = (row) => {
    return () => {
      dispatch(deleteLookupItem(type, row.key));
    };
  };

  const toggleAddLookupItemModal = () => {
    setAddLookupItemModalOpen(!addLookupItemModalOpen);
  };

  const handleAddLookupItemSubmit = (item) => {
    dispatch(addLookupItem(type, item.key, item.value));
    toggleAddLookupItemModal();
  };

  const data: any = [];
  if (type === 'alarm_simplification') {
    if (alarm_simplefication_settings) {
      alarm_simplefication_settings.forEach(sa => {
        if (sa.get('key')) {
          data.push({
            id: sa.get('id'),
            key: sa.get('key'),
            value: sa.get('value'),
            client_id: sa.get('client_id')
          });
        }
      });
    }
  }

  const columns: any = [
    {
      id: 'key',
      Header: "Key",
      accessor: row => row.key,
      width: 'auto',
      disableSortBy: true,
    },
    {
      id: 'value',
      Header: "Value",
      accessor: (row: any) => (<EditableCell className='description-cell' onChange={handleLookupItemUpdate(row)} value={row.value} emptyCellLabel='Add Description' />),
      width: 'auto',
      disableSortBy: true,
    },
    {
      id: 'delete',
      Header: "",
      accessor: (row: any) => (
        (row.client_id) ? (
          <Button onClick={handleLookupItemDelete(row)} compact icon={<DeleteIcon style={{ color: '#444', fontSize: 24 }} />} />
        ) : null
      ),
      width: '64px',
    }
  ];

  return (
    <div id="lookup-table" className='alarms-list'>
      <AddLookupItemModal title={title} open={addLookupItemModalOpen} handleClose={toggleAddLookupItemModal} handleSubmit={handleAddLookupItemSubmit}/>

      <h2>{title}</h2>
      <DataTable
        columns = {columns}
        data = {List(data)}
      />
      <div className='button-bar'>
        <Button onClick={toggleAddLookupItemModal} color="primary" variant="contained" fullWidth type="submit" cta>Add {title}</Button>
      </div>
    </div>
  );
}

LookupTable.propTypes = {
  type: PropTypes.string
};

LookupTable.defaultProps = {
};
