import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import ViewportLayout from 'src/component/Layout/ViewportLayout';
import ViewportLayoutPane from 'src/component/Layout/ViewportLayoutPane';
import AppBar from 'src/component/UI/AppBar';
import BottomNavBar from 'src/component/UI/NavBar/BottomNavBar';
import NavBar from 'src/component/UI/NavBar/NavBar';
import NotFoundScreen from 'src/screen/Error/NotFoundScreen';
import ServiceAgreementScreen from 'src/screen/Protected/ServiceAgreementScreen/ServiceAgreementScreen';
import ProfileScreen from 'src/screen/Protected/ProfileScreen/ProfileScreen';
import OfflineBanner from 'src/component/UI/OfflineBanner';

import { prefetchAll } from 'src/module/prefetch/action';
import { getAbilities, getIsAuthenticated, getAcceptedTerms, getUserMustResetPassword, getFeatures } from 'src/module/authentication/selector';
import { getIsPrefetching, getHasInitialized } from 'src/module/prefetch/selector';
import GlobalProgressIndicator from './UI/GlobalProgressIndicator';
import AdminAppBar from './UI/AdminAppBar';


export default function ProtectedRoute (props) {
  const dispatch = useDispatch();

  const {
    adminRoute,
    location,
    component: InnerComponent,
    can,
    requiredFeatures,
    requiredClientPreferences,
    ...rest
  } = props;

  const abilities = useSelector(getAbilities);
  const features: any = useSelector(getFeatures);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isPrefetching = useSelector(getIsPrefetching);
  const hasInitialized = useSelector(getHasInitialized);
  const acceptedTerms = useSelector(getAcceptedTerms);
  const mustResetPassword = useSelector(getUserMustResetPassword);

  useEffect(() => {
    if (isAuthenticated && !isPrefetching && !hasInitialized) {
      dispatch(prefetchAll());
    }
  }, [dispatch, isAuthenticated, hasInitialized, isPrefetching]);


  const hasPermissions = () => {
    if (!abilities || !features) {
      return false;
    }

    if (!can) {
      return true;
    }
    if (!requiredClientPreferences) {
      return false;
    }

    const hasAllFeatures = requiredFeatures ? requiredFeatures.every(feature => {
      return features.includes(feature);
    }) : true;
    const hasAllAbilities = can.every(ability => {
      return abilities.includes(ability);
    });

    return hasAllAbilities && hasAllFeatures;
  };

  const getProtectedContent = (props) => {
    if (!hasPermissions()) {
      return (<NotFoundScreen />);
    }
    else if (!acceptedTerms) {
      return (<ServiceAgreementScreen />);
    }
    else if (mustResetPassword) {
      return (<ProfileScreen />);
    }
    else {
      return (<InnerComponent {...props} />);
    }
  };

  const { jwt, invite } = queryString.parse((location.search || ''));
  const redirectPath = ((invite && jwt) ? `/accept` : '/login');
  return (

    <Route {...rest}
      render={
        (props) => {
          if (!isAuthenticated) {
            return (<Redirect to={{ pathname: redirectPath, search: location.search || '', state: { from: location } }} />);
          } else {
            return (<ViewportLayout>
              <ViewportLayoutPane position='left' className='viewport-layout-main-pane-left'><NavBar adminBar={hasPermissions() && adminRoute} /></ViewportLayoutPane>
              <ViewportLayoutPane position='right' className='viewport-layout-row'>
                <ViewportLayoutPane position='top'>
                  <OfflineBanner />
                  {!adminRoute || !hasPermissions() ? <AppBar /> : <AdminAppBar />}
                  <GlobalProgressIndicator />
                </ViewportLayoutPane>
                <ViewportLayoutPane position='middle' className='viewport-layout-pane-standard-background'>
                  {getProtectedContent(props)}
                  <ViewportLayoutPane><BottomNavBar adminBar={ adminRoute ? true : false} /></ViewportLayoutPane>
                </ViewportLayoutPane>
              </ViewportLayoutPane>
            </ViewportLayout>);
          }
        }
      }
    />

  );
}

ProtectedRoute.propTypes = {
  can: PropTypes.array,
  requiredFeatures: PropTypes.array,
  component: PropTypes.func.isRequired,
  path: PropTypes.string,
  location: PropTypes.object,
  requiredClientPreferences: PropTypes.bool,
  adminRoute: PropTypes.bool
};

ProtectedRoute.defaultProps = {
  exact: false,
  requiredClientPreferences: true,
  adminRoute: false
};
