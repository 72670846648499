import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

export default function SelectField (props) {
  const {
    field,
    value,
    error,
    disabled,
    handleChange,
    designedValue,
    showLabel,
    showHelperText,
  } = props;

  const fieldId = field.get('id');

  // TODO: why do we care about the unit in the select field?
  const unit = field.get('unit', null);

  // if the current option selected is a readonly option, let's make sure its the only option availble
  const options = field.get('options', List());
  let isReadonlyOptionSelected = false;
  for (let i = 0; i < options.size; i++) { // for loop so we can break early
    if (options.getIn([i, 'value']) === value && options.getIn([i, 'readonlyField'], false)) {
      isReadonlyOptionSelected = true;
      break;
    }
  }

  const menuOptions = options.reduce((acc, option) => {
    const val = option.get('value');
    const label = option.get('label');

    if (isReadonlyOptionSelected) {
      if (val === value) {
        acc.push((<MenuItem data-testid={`select-field-${fieldId}-option-${val}`} key={`${val}-${fieldId}`} value={val}>{label}</MenuItem>));
      }
    } else {
      if (!option.get('readonlyField', false)) {
        acc.push((<MenuItem data-testid={`select-field-${fieldId}-option-${val}`} key={`${val}-${fieldId}`} value={val}>{label}</MenuItem>));
      }
    }

    return acc;
  }, []);

  return <FormControl key={fieldId} className='select-field' variant='outlined' sx={{ mb: 0.8 }}>
    {
      showLabel ?
        <InputLabel id={`select-field-label-${fieldId}`} data-testid={`select-field-label-${fieldId}`} error={Boolean(error)} disabled={disabled}>
          {field.get('label') ?? null}
          {unit ? (<>&nbsp;<span className='unit'>{unit}</span></>) : null}
        </InputLabel> : null
    }


    <Select
      data-testid={`select-field-${fieldId}`}
      id={`select-field-${fieldId}`}
      labelId={`${fieldId}-label`}
      className='select-field'
      name={fieldId}
      value={value?.toString() || ''}
      error={Boolean(error)}
      disabled={isReadonlyOptionSelected || disabled}
      onChange={(e) => handleChange(fieldId, e.target.value)}
      endAdornment={
        <>
          {designedValue ?
            <Tooltip
              title={`Designed value is ${designedValue}`}
              enterTouchDelay={0}>
              <ErrorIcon style={{ fontSize: 20, marginRight: '5px', cursor: 'pointer', color: '#fcd54c' }} />
            </Tooltip>
            : null}
        </>
      }
    >{menuOptions}</Select>
    {
      showHelperText ?
        <FormHelperText id={`helper-text-${fieldId}`} data-testid={`helper-text-${fieldId}`} error={Boolean(error)} >
          {(error) ?
            <>
              {error}<br />
            </> : null}
          {field.get('helperText')}
        </FormHelperText> : null
    }

  </FormControl>;
}

SelectField.propTypes = {
  field: ImmutablePropTypes.map,
  error: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
  showHelperText: PropTypes.bool,
  designedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

SelectField.defaultProps = {
  disabled: false,
  showLabel: true,
  showHelperText: true,
  designedValue: null
};

SelectField.whyDidYouRender = true;
