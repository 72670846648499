import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, Input, Switch } from '@mui/material';

import PropTypes from 'prop-types';
import { getCriticalityLabels } from 'src/module/authentication/selector';
import { updateRule } from 'src/module/rule/action';
import { Map } from 'immutable';
import ToolTip from '../../../component/UI/ToolTip';


export default function Result (props) {
  const {
    label,
    value,
    ruleId,
    disabled,
    tooltip
  } = props;
  const dispatch = useDispatch();
  const [valueA, setValue] = React.useState(label === 'pager_duty_notification' ? Boolean(value) : value);

  const criticalityLabels = useSelector(getCriticalityLabels);
  const criticalityOptions = criticalityLabels.reduce((acc, label, value) => {
    acc.push({ label, value: value.toString() });
    return acc;
  }, []);
  const resultOptions = [
    { value: 'alarm_group', label: 'Alarm Group', type: 'input' },
    { value: 'criticality', label: 'Criticality', type: 'select', options: criticalityOptions },
    { value: 'pager_duty_notification', label: 'Pager Duty', type: 'boolean' },
  ];
  const selectedOption = resultOptions.find(opt => opt.value === label);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const updateValue = (event) => {
    let newValue = event.target.value;

    // Handle the switch's boolean value explicitly
    if (selectedOption.type === 'boolean') {
      newValue = event.target.checked;
    }

    setValue(newValue);

    if (newValue !== value) {
      const rule = {};
      let cleanValue = newValue;

      if (label === 'criticality') {
        cleanValue = parseInt(newValue);
      }

      if (label === 'pager_duty_notification') {
        cleanValue = Boolean(newValue);
      }

      rule[label] = cleanValue;
      dispatch(updateRule(ruleId, Map(rule)));
    }
  };


  return (
    <div style={{ display: 'flex' }}>
      <p style={{ width: '150px' }}>{resultOptions.find(x => x.value === label).label}{tooltip ? <ToolTip helpText={tooltip} ></ToolTip> : null}</p>
      {selectedOption.type === 'input' ?
        <Input
          id='input'
          type='text'
          onChange={updateValue}
          value={valueA}
          fullWidth
          size="small"
          margin="none"
          style={{ width: '500px', marginTop: '22px', height: '20px' }}
          disabled={disabled}
        />
        : null}
      {selectedOption.type === 'select' ?
        <Select
          value={valueA}
          variant="standard"
          onChange={updateValue}
          style={{ width: '500px', height: 'fit-content', marginTop: '15px' }}
          disabled={disabled}
        >
          {selectedOption.options.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
          ))}
        </Select>
        : null}
      {selectedOption.type === 'boolean' ?
        <Switch
          checked={valueA}
          onChange={updateValue}
          value={value || ''}
          color="primary"
          disabled={disabled}
        />
        : null}
    </div>
  );
}

Result.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  ruleId: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string
};

Result.defaultProps = {
  disabled: false,
  tooltip: ''
};
