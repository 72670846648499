import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchIntegrations, FETCH_INTEGRATIONS }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRATIONS`
);

export const { fetchIntegrationsSuccess, FETCH_INTEGRATIONS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRATIONS_SUCCESS`,
  ['integrations', yup.immutable().list().required()]
);

export const { fetchIntegrationsFailed, FETCH_INTEGRATIONS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRATIONS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchIntegrationFields, FETCH_INTEGRATION_FIELDS }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRATION_FIELDS`
);

export const { fetchIntegrationFieldsSuccess, FETCH_INTEGRATION_FIELDS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRATION_FIELDS_SUCCESS`,
  ['integrations', 'fields', yup.immutable().list().required()]
);

export const { fetchIntegrationFieldsFailed, FETCH_INTEGRATION_FIELDS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_INTEGRATION_FIELDS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { addIntegration, ADD_INTEGRATION }: any = createTypedAction(
  `${PREFIX}ADD_INTEGRATION`,
  ['integrationType', yup.string().required()]
);

export const { addIntegrationSuccess, ADD_INTEGRATION_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_INTEGRATION_SUCCESS`,
  ['integration', yup.immutable().map().required()]
);

export const { addIntegrationFailed, ADD_INTEGRATION_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_INTEGRATION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { updateIntegration, UPDATE_INTEGRATION }: any = createTypedAction(
  `${PREFIX}UPDATE_INTEGRATION`,
  ['integrationId', yup.string().required()],
  ['integration', yup.immutable().map().required()]
);

export const { updateIntegrationSuccess, UPDATE_INTEGRATION_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_INTEGRATION_SUCCESS`,
  ['integration', yup.immutable().map().required()]
);

export const { updateIntegrationFailed, UPDATE_INTEGRATION_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_INTEGRATION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);



export const { deleteIntegration, DELETE_INTEGRATION }: any = createTypedAction(
  `${PREFIX}DELETE_INTEGRATION`,
  ['integrationId', yup.string().required()]
);

export const { deleteIntegrationSuccess, DELETE_INTEGRATION_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_INTEGRATION_SUCCESS`,
  ['integrationId', yup.string().required()]
);

export const { deleteIntegrationFailed, DELETE_INTEGRATION_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_INTEGRATION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { refreshIntegration, REFRESH_INTEGRATION }: any = createTypedAction(
  `${PREFIX}REFRESH_INTEGRATION`,
  ['integrationId', yup.string().required()]
);

export const { refreshIntegrationSuccess, REFRESH_INTEGRATION_SUCCESS }: any = createTypedAction(
  `${PREFIX}REFRESH_INTEGRATION_SUCCESS`,
  ['integrationId', yup.string().required()]
);

export const { refreshIntegrationFailed, REFRESH_INTEGRATION_FAILED }: any = createTypedAction(
  `${PREFIX}REFRESH_INTEGRATION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
