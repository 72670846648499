import './RecurrenceRulePicker.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Map } from 'immutable';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function RecurrenceRulePicker (props) {
  const [frequency, setFrequency] = useState('daily');
  const [dailyInterval, setDailyInterval] = useState(1);
  const [weeklyWeekdays, setWeeklyWeekdays] = useState(Map({
    su: false,
    mo: false,
    tu: false,
    we: false,
    th: false,
    fr: false,
    sa: false
  }));
  const [weeklyInterval, setWeeklyInterval] = useState(1);
  const [monthlyInterval, setMonthlyInterval] = useState(1);
  const [monthlySpecificDay, setMonthlySpecificDay] = useState(true);
  const [monthlyDay, setMonthlyDay] = useState(1);
  const [monthlyRelativeDayType, setMonthlyRelativeDayType] = useState('mo');
  const [monthlyRelativeQualifier, setMonthlyRelativeQualifier] = useState(1);
  const [recurrenceRule, setRecurrenceRule] = useState(props.initialValue);

  const parseRecurrenceRuleString = (recurrenceRule) => {
    const pairs = recurrenceRule.split(';');
    return pairs.reduce((acc, pair) => {
      const [key, val] = pair.split(':');
      acc[key] = val;
      return acc;
    }, {});
  };

  const buildRecurranceRule = () => {
    let rRule = null;
    let byDay = null;

    switch (frequency) {
      case 'daily':
        rRule = `freq:daily;interval:${dailyInterval}`;
        break;

      case 'weekly':
        rRule = `freq:weekly;interval:${weeklyInterval}`;

        byDay = weeklyWeekdays.reduce((acc, val, day) => {
          if (val) {
            acc.push(day);
          }
          return acc;
        }, []).join(',');

        if (byDay) {
          rRule += `;byDay:${byDay}`;
        }

        break;

      case 'monthly':
        rRule = `freq:monthly;interval:${monthlyInterval}`;
        if (monthlySpecificDay) {
          rRule += `;specificDay:${monthlyDay}`;
        } else {
          rRule += `;relativeDay:${monthlyRelativeDayType};qualifier:${monthlyRelativeQualifier}`;
        }
        break;
    }

    return rRule;
  };


  useEffect(() => {
    if (props.initialValue) {
      const recurrenceRule = parseRecurrenceRuleString(props.initialValue);
      switch (recurrenceRule.freq) {
        case 'daily':
          setFrequency('daily');
          setDailyInterval(recurrenceRule.interval);
          break;

        case 'weekly':
          setFrequency('weekly');
          setWeeklyInterval(recurrenceRule.interval);
          if (recurrenceRule.byDay) {
            const days = { su: false, mo: false, tu: false, we: false, th: false, fr: false, sa: false };
            const byDaySplit = recurrenceRule.byDay.split(',');
            byDaySplit.map((day) => {
              days[day] = true;
            });
            setWeeklyWeekdays(Map(days));
          }
          break;

        case 'monthly':
          setFrequency('monthly');
          setMonthlyInterval(recurrenceRule.interval);
          if (recurrenceRule.relativeDay) {
            setMonthlySpecificDay(false);
            setMonthlyRelativeDayType(recurrenceRule.relativeDay);
            setMonthlyRelativeQualifier(recurrenceRule.qualifier);
          } else {
            setMonthlySpecificDay(true);
            setMonthlyDay(recurrenceRule.specificDay);
          }
          break;
      }
    }
  }, []);

  useEffect(() => {
    const newRecurrnceRule = buildRecurranceRule();
    if (recurrenceRule !== newRecurrnceRule) {
      setRecurrenceRule(newRecurrnceRule);
      props.onChange(newRecurrnceRule);
    }
  }, [frequency, dailyInterval, weeklyWeekdays, weeklyInterval, monthlyInterval, monthlySpecificDay, monthlyDay, monthlyRelativeDayType, monthlyRelativeQualifier]);


  return (
    <Grid data-testid='recurrence-rule-picker' id='recurrence-rule-picker' container spacing={2}>

      <Grid item xs={12}>
        <Select variant='standard' value={frequency} onChange={(e: any) => setFrequency(e.target.value)}>
          <MenuItem value='daily'>Daily</MenuItem>
          <MenuItem value='weekly'>Weekly</MenuItem>
          <MenuItem value='monthly'>Monthly</MenuItem>
        </Select>
      </Grid>


      {(frequency === 'daily') ? (
        <Grid item xs={12}>
          <div>
            <span>Every </span>
            <TextField variant='standard' value={dailyInterval} type='number' onChange={e => setDailyInterval(parseInt(e.target.value))} />
            <span> day(s)</span>
          </div>
        </Grid>
      ) : null}


      {(frequency === 'weekly') ? (
        <Grid item xs={12}>
          <div className='option-line'>
            <span>Recur every</span>
            <TextField value={weeklyInterval} type='number' onChange={e => setWeeklyInterval(parseInt(e.target.value))} />
            <span> week(s) on:</span>
          </div>
          <FormGroup>
            <FormControlLabel className='option-line'
              control={<Checkbox checked={weeklyWeekdays.get('su')} onChange={() => setWeeklyWeekdays(weeklyWeekdays.set('su', !weeklyWeekdays.get('su'))) } name='sunday' />}
              label='Sunday'
            />
            <FormControlLabel className='option-line'
              control={<Checkbox checked={weeklyWeekdays.get('mo')} onChange={() => setWeeklyWeekdays(weeklyWeekdays.set('mo', !weeklyWeekdays.get('mo'))) } name='monday' />}
              label='Monday'
            />
            <FormControlLabel className='option-line'
              control={<Checkbox checked={weeklyWeekdays.get('tu')} onChange={() => setWeeklyWeekdays(weeklyWeekdays.set('tu', !weeklyWeekdays.get('tu'))) } name='tuesday' />}
              label='Tuesday'
            />
            <FormControlLabel className='option-line'
              control={<Checkbox checked={weeklyWeekdays.get('we')} onChange={() => setWeeklyWeekdays(weeklyWeekdays.set('we', !weeklyWeekdays.get('we'))) } name='wednesday' />}
              label='Wednesday'
            />
            <FormControlLabel className='option-line'
              control={<Checkbox checked={weeklyWeekdays.get('th')} onChange={() => setWeeklyWeekdays(weeklyWeekdays.set('th', !weeklyWeekdays.get('th'))) } name='thursday' />}
              label='Thursday'
            />
            <FormControlLabel className='option-line'
              control={<Checkbox checked={weeklyWeekdays.get('fr')} onChange={() => setWeeklyWeekdays(weeklyWeekdays.set('fr', !weeklyWeekdays.get('fr'))) } name='friday' />}
              label='Friday'
            />
            <FormControlLabel className='option-line'
              control={<Checkbox checked={weeklyWeekdays.get('sa')} onChange={() => setWeeklyWeekdays(weeklyWeekdays.set('sa', !weeklyWeekdays.get('sa'))) } name='saturday' />}
              label='Saturday'
            />
          </FormGroup>
        </Grid>
      ) : null}


      {(frequency === 'monthly') ? (
        <Grid item xs={12}>
          <div className='option-line'>
            <span>Every </span>
            <TextField value={monthlyInterval} type='number' onChange={e => setMonthlyInterval(parseInt(e.target.value))} />
            <span> month(s)</span>
          </div>

          <RadioGroup aria-label='monthly-frequency' name='monthly-frequency' value='' onChange={e => setMonthlySpecificDay(e.target.value === 'specific-day-of-month') }>
            <FormControlLabel className='option-line' value='specific-day-of-month' control={<Radio checked={monthlySpecificDay} />}
              label={
                <div>
                  <span>on day </span>
                  <TextField value={monthlyDay} type='number' onChange={e => setMonthlyDay(parseInt(e.target.value))} />
                </div>
              }
            />

            <FormControlLabel className='option-line' value='relative-day-of-month' control={<Radio checked={!monthlySpecificDay} />}
              label={
                <div>
                  <span>on the </span>
                  <Select value={monthlyRelativeQualifier} onChange={(e: any) => setMonthlyRelativeQualifier(e.target.value)}>
                    <MenuItem value='1'>First</MenuItem>
                    <MenuItem value='2'>Second</MenuItem>
                    <MenuItem value='3'>Third</MenuItem>
                    <MenuItem value='4'>Fourth</MenuItem>
                    <MenuItem value='5'>Last</MenuItem>
                  </Select>
                  <span className='spacer' />
                  <Select value={monthlyRelativeDayType} onChange={(e: any) => setMonthlyRelativeDayType(e.target.value)}>
                    <MenuItem value='su'>Sunday</MenuItem>
                    <MenuItem value='mo'>Monday</MenuItem>
                    <MenuItem value='tu'>Tuesday</MenuItem>
                    <MenuItem value='we'>Wednesday</MenuItem>
                    <MenuItem value='th'>Thursday</MenuItem>
                    <MenuItem value='fr'>Friday</MenuItem>
                    <MenuItem value='sa'>Saturday</MenuItem>
                  </Select>
                </div>
              }
            />
          </RadioGroup>
        </Grid>
      ) : null}
    </Grid>
  );
}

RecurrenceRulePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string
};

RecurrenceRulePicker.defaultProps = {
};
