export const createReduxTypeSafetyMiddleware = () => {
  return () => (next) => (action) => {
    if (process.env.NODE_ENV !== 'production') {
      if (/^@@fox\//.test(action.type)) {
        if (!Object.prototype.hasOwnProperty.call(window.__REDUX_ACTIONS_TYPED__, action.type)) {
          throw new TypeError(`Refusing to dispatch untyped redux action ${action.type}, please define it using createTypedAction!`);
        }

        try {
          window.__REDUX_ACTIONS_TYPED__[action.type].validateSync(action);
        } catch (err) {
          console.warn(err); // eslint-disable-line no-console
        }
      }
    }

    return next(action);
  };
};

export default createReduxTypeSafetyMiddleware;
