import '../CircuitsList.scss';

import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'src/component/UI/DataTable';
import Button from 'src/component/UI/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import _ from 'lodash';
import { getForcedOnCircuits, getLoadingForcedOnCircuits, getNumForcedOnCircuitPages, getTotalForcedOnCircuits } from 'src/module/circuit/selector';
import { fetchForcedOnCircuits, archiveForcedOnCircuit, invalidateArchivedForcedOnCircuit } from 'src/module/circuit/action';
import { Map } from 'immutable';

import DeviceLink from 'src/component/UI/DeviceLink';
import PermissionFence from 'src/component/PermissionFence';
import { getSingularWorkTicketLabel, getTablePagePreferences } from 'src/module/authentication/selector';

export default function ForcedOnCircuitsList (props) {

  const selectedFilters = useSelector(getDevicesSelectedFilters);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);

  const forcedOnCircuits: any = useSelector(getForcedOnCircuits) || [];
  const loading: any = useSelector(getLoadingForcedOnCircuits);
  const numPages: any = useSelector(getNumForcedOnCircuitPages);
  const totalForcedOnCircuits: any = useSelector(getTotalForcedOnCircuits);
  const [page, setPage] = React.useState(0);
  const userPagePreference = useSelector(getTablePagePreferences('forcedOnCircuitsTable'));
  const [pageSize, setPageSize] = React.useState(userPagePreference);
  const [filter, setFilter] = React.useState(null);
  const [sort, setSort] = React.useState(null);
  const [showArchived, setShowArchived] = React.useState(false);

  const {
    title,
    deviceId,
    pagination,
    filterable,
    showArchivedOption,
    showTitle,
    showControllerTag,
    showCircuitTag,
    showLineNumber,
    showNotificationNumber,
    showCircuitUnit,
    showForcedOnControlMode,
    showArchivedAt,
    showDeleteOption,
    expiredOnly,
    includeArchived,
    supressSelectedFilters
  } = props;

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const handleCircuitRowClick: any = props.handleCircuitRowClick;

  const dispatch: any = useDispatch();

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setFilter(_filter);
    setSort(_sort);
  };

  const handleToggleShowArchived = () => {
    setShowArchived(!showArchived);
  };

  const handleArchiveForcedOnCircuit = (circuitId: string, rowIdx: number) => {
    dispatch(archiveForcedOnCircuit(circuitId));
    dispatch(invalidateArchivedForcedOnCircuit(rowIdx));
  };

  const handleCellClick = (row) => {
    if (handleCircuitRowClick) {
      handleCircuitRowClick(row.original);
    }
  };
  const ref_filters = useRef(null);
  const ref_others = useRef(null);
  useEffect(() => {
    const others = `${props.toggleRefresh} ${showArchived} ${page} ${pageSize} ${filter} ${sort}`;
    if (!_.isEqual(ref_filters.current, selectedFilters.toJS()) || others !== ref_others.current) {
      ref_filters.current = selectedFilters.toJS();
      ref_others.current = others;
      const filters = supressSelectedFilters ? Map({}) : selectedFilters;
      const paginationDetails = pagination ? { page, pageSize, filter, sort } : {};
      // showArchived is a state var here
      // whereas includeArchived is a boolean prop (optional) for this component
      dispatch(fetchForcedOnCircuits(filters, paginationDetails, showArchived || includeArchived, expiredOnly, deviceId));
    }
  }, [dispatch, selectedFilters, props.toggleRefresh, showArchived, includeArchived, page, pageSize, filter, sort]);

  const columnOptions = [];
  if (!isMobile) {
    if (showControllerTag) {
      columnOptions.push({
        id: 'controller.tag',
        Header: 'Controller',
        accessor: (row: any) => row.getIn(['controller_tag']),
        ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : 'ignore-on-row-click',
        sortable: true,
        Cell: (row: any) => {
          if (row.cell.row.original.get('type') !== 'controller') {
            return 'N/A';
          }
          else if (row.cell.row.original.getIn(['circuit_deleted_at']) !== null) {
            return row.cell.row.original.getIn(['controller_tag']);
          } else {
            return (<DeviceLink label={row.cell.value}  controllerTag={row.cell.row.original.getIn(['controller_tag'])} />);
          }
        }
      });
    }
    if (showCircuitTag) {
      columnOptions.push({
        id: 'circuit.tag',
        Header: 'Circuit',
        accessor: (row: any) => row.getIn(['circuit_tag']),
        ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : 'ignore-on-row-click',
        sortable: true,
        Cell: (row: any) => {
          if (row.cell.row.original.getIn(['circuit_deleted_at']) !== null) {
            return row.cell.row.original.getIn(['circuit_tag']);
          } else {
            return (<DeviceLink label={row.cell.value} circuitTag={row.cell.value} controllerTag={row.cell.row.original.getIn(['controller_tag'])} />);

          }
        }
      });
    }
  }
  else {
    columnOptions.push({
      id: 'circuit.controller.tag',
      Header: 'Controller / Circuit',
      accessor: (row: any) => row.getIn(['circuit_tag']),
      Cell: (row: any) => {
        if (row.cell.row.original.getIn(['circuit_deleted_at']) !== null) {
          return `${row.cell.row.original.getIn(['controller_tag'])} /  ${row.cell.row.original.getIn(['circuit_tag'])}`;
        } else {
          return (
            <>
              <DeviceLink label={row.cell.value} controllerTag={row.cell.row.original.getIn(['controller_tag'])} /> /
              {row.cell.value ? (<DeviceLink label={row.cell.value} circuitTag={row.cell.value} controllerTag={row.cell.row.original.getIn(['controller_tag'])} rawTag={row.cell.row.original.get('raw_tag')} />) : 'N/A'}
            </>
          );
        }



      },
      ClassName: (row: any) => row.original.get('critical') === 1 ? 'critical' : ''
    });
  }


  if (showNotificationNumber) {
    columnOptions.push({
      id: 'notification_number',
      Header: `${singularWorkTicketLabel} Number`,
      accessor: (row: any) => row.getIn(['notification_number']),
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : '',
      sortable: true
    });
  }
  if (showLineNumber) {
    columnOptions.push({
      id: 'circuit.line',
      Header: 'Line Number',
      accessor: (row: any) => row.getIn(['circuit_line']),
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : '',
      sortable: true,
    });
  }
  if (showCircuitUnit) {
    columnOptions.push({
      id: 'circuit.unit',
      Header: 'Unit',
      accessor: (row: any) => row.getIn(['circuit_unit']),
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : '',
    });
  }
  if (showForcedOnControlMode) {
    columnOptions.push({
      id: 'forced_on_control_mode',
      Header: 'Force On Control Mode',
      accessor: (row: any) => row.get('forced_on_control_mode'),
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : '',
      sortable: true,
    });
  }

  columnOptions.push(
    {
      id: 'person_requesting_change',
      Header: 'Person Requesting Change',
      accessor: (row: any) => row.get('person_requesting_change'),
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : '',
      sortable: true,
    },
    {
      id: 'forced_on_set_point',
      Header: 'Forced On Set Point',
      accessor: (row: any) => row.get('forced_on_set_point'),
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : '',
      sortable: true,
    },
    {
      id: 'forced_on_at',
      Header: 'Date Change Made',
      accessor: (row: any) => row.get('forced_on_at') ? moment(row.get('forced_on_at')).format('YYYY-MM-DD') : 'N/A',
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : '',
      sortable: true,
    },
    {
      id: 'expected_removal_at',
      Header: 'Expected Removal Date',
      accessor: (row: any) => row.get('expected_removal_at') ? moment(row.get('expected_removal_at')).format('YYYY-MM-DD') : 'N/A',
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through ignore-on-row-click' : '',
      sortable: true,
    }
  );

  if (showArchivedAt) {
    columnOptions.push({
      id: 'archived_at',
      Header: 'Date Returned to Original Design',
      accessor: (row: any) => row.get('archived_at') ? moment(row.get('archived_at')).format('YYYY-MM-DD') : 'N/A',
      ClassName: (row: any) => row.original.get('invalidated') && !showArchived ? 'line-through' : '',
      sortable: true,
    });
  }
  if (showDeleteOption) {
    columnOptions.push({
      Header: ' ',
      accessor: (row: any) => row.get('index'),
      maxWidth: '60px',
      disableSortBy: true,
      ClassName: 'ignore-on-row-click',
      Cell: (row: any) => {
        return row.cell.row.original.get('invalidated') || row.row.values['archived_at'] != 'N/A' ? "Archived" : (
          <div className='circuit-actions'>
            <PermissionFence can={['edit-forced-on-circuit', 'edit-device']}>
              <Button onClick={() => { handleArchiveForcedOnCircuit(row.cell.row.original.get('id'), row.row.index); }} icon={<DeleteIcon style={{ fontSize: 14 }} />} />
            </PermissionFence>
          </div>
        );
      }
    });
  }


  return (
    <div data-testid='forced-on-circuits-list'>
      {showTitle ? <h2>{title}</h2> : null }
      {showRotateMessage ? <DeviceRotate /> :
        <DataTable
          filterable={filterable}
          filterLabel='Filter by Circuit Tag'
          handleCellClick={handleCellClick}
          columns={columnOptions}
          data={forcedOnCircuits}
          unit={selectedFilters.get('unit')}
          pagination={pagination}
          numPages={numPages}
          totalRecords={totalForcedOnCircuits}
          loading={loading}
          fetchPage={fetchPage}
          tableId='forcedOnCircuitsTable'
        />
      }
      {showArchivedOption && !showRotateMessage ?
        (<FormControlLabel
          control={
            <Checkbox
              checked={showArchived}
              onChange={handleToggleShowArchived}
              name="showArchived"
              color="primary"
            />
          }
          label="Show Archived"
        />) : null
      }

    </div>
  );
}

ForcedOnCircuitsList.propTypes = {
  handleCircuitRowClick: PropTypes.func,
  toggleRefresh: PropTypes.bool,
  title: PropTypes.string,
  deviceId: PropTypes.string,
  expiredOnly: PropTypes.bool,
  filterable: PropTypes.bool,
  pagination: PropTypes.bool,
  showArchivedOption: PropTypes.bool,
  showLineNumber: PropTypes.bool,
  showNotificationNumber: PropTypes.bool,
  showTitle: PropTypes.bool,
  showControllerTag: PropTypes.bool,
  showCircuitTag: PropTypes.bool,
  showCircuitUnit: PropTypes.bool,
  showForcedOnControlMode: PropTypes.bool,
  showArchivedAt: PropTypes.bool,
  showDeleteOption: PropTypes.bool,
  includeArchived: PropTypes.bool,
  supressSelectedFilters: PropTypes.bool
};

ForcedOnCircuitsList.defaultProps = {
  handleCircuitRowClick: null,
  toggleRefresh: false,
  title: 'Force-on Circuits',
  deviceId: null,
  expiredOnly: false,
  filterable: true,
  pagination: true,
  showArchivedOption: true,
  showTitle: true,
  showControllerTag: true,
  showCircuitTag: true,
  supressSelectedFilters: false,
  showLineNumber: false,
  showNotificationNumber: false,
  showCircuitUnit: false,
  showForcedOnControlMode: false,
  showArchivedAt: false,
  includeArchived: false,
  showDeleteOption: false
};
