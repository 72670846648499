import yup from 'src/utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { addController, ADD_CONTROLLER, addControllerQueueFormatter }: any = createTypedAction(
  `${PREFIX}ADD_CONTROLLER`,
  (state) => {
    return `add controller tag : ${state.controller.get('tag')}, device factory: ${state.controller.get('device_factory')} , ip address: ${state.controller.get('ip_address')}, port: ${state.controller.get('port')},  `;
  },
  ['controller', yup.immutable().map().required()]
);

export const { addControllerSuccess, ADD_CONTROLLER_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_CONTROLLER_SUCCESS`,
  ['controller', yup.immutable().map().required()]
);

export const { addControllerFailed, ADD_CONTROLLER_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_CONTROLLER_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteController, DELETE_CONTROLLER, deleteControllerQueueFormatter }: any = createTypedAction(
  `${PREFIX}DELETE_CONTROLLER`,
  (state, devices) => {
    const controllerTag = devices?.getIn([state.controllerId, 'record', 'info', 'tag']);
    return `delete controller ${controllerTag} `;
  },
  ['controllerId', yup.immutable().map().required()],
  ['circuits', yup.immutable().list().required()]
);

export const { deleteControllerSuccess, DELETE_CONTROLLER_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_CONTROLLER_SUCCESS`,
  ['controllerId', yup.immutable().map().required()]
);

export const { deleteControllerFailed, DELETE_CONTROLLER_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_CONTROLLER_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateController, UPDATE_CONTROLLER, updateControllerQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_CONTROLLER`,
  (state, devices) => {
    const controllerTag = devices?.getIn([state.controllerId, 'record', 'info', 'tag']);
    return `update ${JSON.stringify(state.controller.toJS())} on controller ${state.controllerTag ?? controllerTag} `;
  },
  ['controllerId', yup.string().required()],
  ['controller', yup.immutable().map().required()]
);

export const { updateControllerSuccess, UPDATE_CONTROLLER_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_CONTROLLER_SUCCESS`,
  ['controllerTag', yup.immutable().map().required()]
);

export const { updateControllerFailed, UPDATE_CONTROLLER_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_CONTROLLER_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
