import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './AuditList.scss';
import DataTable from './UI/DataTable';
import { List, Map } from 'immutable';
import { isLangATemperature, isTextValueAOption, isTextValueASwitch, langLookUpText } from 'src/utils/langLookUp';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchDevicesAuditTrail } from 'src/module/device/action';
import { getDeviceAuditTrail, getDeviceConfigFields } from 'src/module/device/selector';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { celsiusToFahrenheitConvertor, formatDate } from 'src/utils/utils';
import { getTemperatureUnit } from 'src/module/authentication/selector';

export default function AuditList (props) {
  const {
    deviceId
  } = props;
  const dispatch = useDispatch();
  const auditTrailPage: any = useSelector(getDeviceAuditTrail(deviceId));
  const temperatureUnit = useSelector(getTemperatureUnit);

  const [pageInfo, setPageInfo] = useState(Map({
    page: 0,
    pageSize: 10,
    pageFilter: null,
    pageSort: null,
  }));
  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPageInfo(Map({
      page: _page,
      pageSize: _pageSize,
      pageFilter: _filter,
      pageSort: _sort,
    }));
  };
  useEffect(() => {
    dispatch(fetchDevicesAuditTrail(deviceId, pageInfo));
  }, [deviceId, pageInfo]);

  function formatSettings (object, setting, getter) {
    const isTextAOption = isTextValueAOption(setting);
    const isSwitch = isTextValueASwitch(setting);
    const isTemp = isLangATemperature(setting);
    const deviceConfigFields = useSelector(getDeviceConfigFields(deviceId));
    let value = _.get(object, [setting, getter], getter === '__new' ? object[setting] : null);
    value = temperatureUnit === 'F' && isTemp && value !== 'null' ? celsiusToFahrenheitConvertor(value) : value;
    if (isTextAOption) {
      const options = deviceConfigFields.getIn(['settings', setting.replaceAll('.', ''), 'options']);
      value = options?.find(x => x.get('value').toString() === value?.toString())?.get('label') || 'Invalid value';
    } else if (isSwitch) {
      if (value === null || typeof value === 'undefined') {
        value = 'N/A';
      } else if (value == 0) {
        value = 'Off';
      } else if (value == 1) {
        value = 'On';
      }
    }
    return value?.toString();
  }

  function formatName (data) {
    if (data.get('type_of_user') === 'user') {
      return <b>{data.get('first_name') + " " + data.get('last_name')}</b>;
    } else if (data.get('type_of_user') === 'device') {
      return <b>Physical Device</b>;
    } else if (data.get('type_of_user') === 'api') {
      return <b>API</b>;
    }
    return <b>Unknown</b>;
  }

  function formatStatus (data) {
    if (data === null) {
      return 'Pending';
    }
    return data ? 'Success' : 'Failed';
  }

  const columns: any = [
    {
      id: 'changed_by',
      Header: 'Changed By',
      accessor: (row: any) => row.get('type_of_user'),
      Cell: (row: any) => formatName(row.cell.row.original)
    },
    {
      id: 'settingLabel',
      Header: 'Setting',
      accessor: (row: any) => row.get('changes'),
      Cell: (row: any) => Object.keys(row.cell.value.toJS()).map((setting, index) => (<div key={index} className='audit-list-item'>{langLookUpText(setting)}</div>)),
    },
    {
      id: 'settingBefore',
      Header: 'Before',
      accessor: (row: any) => row.get('changes'),
      Cell: (row: any) => Object.keys(row.cell.value.toJS()).map((setting, index) => (<div key={index} className='audit-list-item'>{formatSettings(row.cell.value.toJS(), setting, '__old')}</div>)),

    },
    {
      id: 'settingAfter',
      Header: 'After',
      accessor: (row: any) => row.get('changes'),
      Cell: (row: any) => Object.keys(row.cell.value.toJS()).map((setting, index) => (<div key={index} className='audit-list-item'>{formatSettings(row.cell.value.toJS(), setting, '__new')}</div>)),

    },
    {
      id: 'status',
      Header: (<Tooltip title='Requests done though SmartTrace could be still processing and we are waiting to find out if the change was successful or not'><div style={{ display: 'flex' }}>Status<InfoIcon className="alert-icon" width={16} height={16} style={{ marginLeft: '5px' }} /></div></Tooltip>),
      accessor: (row: any) => formatStatus(row.get('isSuccess')),
    },
    {
      id: 'changed_at',
      Header: 'Changed At',
      accessor: (row: any) => row.get('created_at'),
      Cell: (row: any) => (row.cell.value ? (<>{formatDate(row.cell.value)}</>) : ''),
    },
  ];

  return (
    <div className='alarms-list'>
      <DataTable
        pagination
        numPages={auditTrailPage.get('totalPages')}
        totalRecords={auditTrailPage.get('total')}
        loading={auditTrailPage.get('loading')}
        data={auditTrailPage.get('data', List())}
        columns={columns}
        fetchPage={fetchPage}
        placeholder={<><span>No tracked changes to see here </span><span role='img' aria-label='Sweat Smile'>😅</span></>}
        tableId='auditTable'
      />
    </div>
  );
}

AuditList.propTypes = {
  deviceId: PropTypes.string.isRequired
};

AuditList.defaultProps = {
};
