import React from 'react';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';

import { useDispatch } from 'react-redux';
import CommLoopsList from 'src/component/UI/CommLoopsList';
import AddCommLoopModal from 'src/component/UI/AddCommLoopModal';
import AddIcon from '@mui/icons-material/Add';
import Button from 'src/component/UI/Button';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import { setSettingsModalOpen } from 'src/module/device/action';
import { isWhitelabel } from 'src/utils/utils';

export default function AllCommLoopsScreen () {

  const dispatch = useDispatch();
  const showAddCommLoopModal = () => {
    dispatch(setSettingsModalOpen(null, SettingsModalTypes.ADD_COMM_LOOP));
  };

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page'>
      <Helmet>
        <title>{title} - Comm Loops</title>
      </Helmet>
      <br />
      <Paper>
        <CommLoopsList/>
        <div className='button-bar'>
          <Button onClick={() => showAddCommLoopModal()} variant="contained" cta icon={<AddIcon style={{ fontSize: 14 }} />} >
            Add Comm Loop
          </Button>
        </div>
        <AddCommLoopModal/>
      </Paper>
    </div>
  );
}
