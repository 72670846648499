import './WeatherForecast.scss';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchWeather } from 'src/module/weather/action';
import { getClient } from 'src/module/authentication/selector';
import { getWeatherForecast } from 'src/module/weather/selector';
import { celsiusToFahrenheitConvertor } from 'src/utils/utils';
import { getTemperatureUnit } from 'src/module/authentication/selector';

import DayForecast from './DayForecast';

export default function WeatherForecast () {
  const dispatch = useDispatch();

  const temperatureUnit = useSelector(getTemperatureUnit);
  const weatherForecast = useSelector(getWeatherForecast);
  const client = useSelector(getClient);

  useEffect(() => {
    dispatch(fetchWeather());
  }, [client.get('id')]);

  const cityName = weatherForecast.get('city') || null;
  const forecast = weatherForecast.get('forecast') || null;

  return cityName ? (
    <div id='weather-forecast' data-testid='weather-forecast'>
      <h3>{cityName} 5 Day Forecast</h3>

      <div className='weather'>
        {forecast && forecast.reduce((days: any, currentDay: any, index: any) => {
          if (currentDay && days.length < 5) {
            days.push(<DayForecast
              maxTemp={temperatureUnit === 'C' ? currentDay.get('high') : celsiusToFahrenheitConvertor(parseFloat(currentDay.get('high').replace('°C', ''))) + '°F'}
              minTemp={temperatureUnit === 'C' ? currentDay.get('low') : celsiusToFahrenheitConvertor(parseFloat(currentDay.get('low').replace('°C', ''))) + '°F'}
              day={currentDay.get('day')}
              iconCode={currentDay.get('code')}
              date={currentDay.get('date')}
              key={index}
            />);
          }
          return days;
        }, [])}
      </div>
    </div>
  ) : null;
}

WeatherForecast.propTypes = {
};

WeatherForecast.defaultProps = {
};
