import { takeLatest, put, call } from 'redux-saga/effects';
import { Map, List } from 'immutable';

import * as api from 'src/service/api/integrations';
import * as integrationActions from './action';
import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function * integrationRoot () {
  yield takeLatest(integrationActions.FETCH_INTEGRATIONS, fetchIntegrations);
  yield takeLatest(integrationActions.FETCH_INTEGRATION_FIELDS, fetchIntegrationFields);
  yield takeLatest(integrationActions.ADD_INTEGRATION, addIntegration);
  yield takeLatest(integrationActions.UPDATE_INTEGRATION, updateIntegration);
  yield takeLatest(integrationActions.DELETE_INTEGRATION, deleteIntegration);
  yield takeLatest(integrationActions.REFRESH_INTEGRATION, refreshIntegration);

}

function * fetchIntegrations (action) {
  try {
    const integrations = yield call(api.getIntegrations, action);
    yield put(integrationActions.fetchIntegrationsSuccess(integrations.get('data') || List()));
  } catch (err: any) {
    yield put(integrationActions.fetchIntegrationsFailed(Map(err)));
    toast.error('Error retrieving integrations', err.response.status);
  }
}

function * fetchIntegrationFields (action) {
  try {
    const integrationFields = yield call(api.getIntegrationFields, action);
    yield put(integrationActions.fetchIntegrationFieldsSuccess(integrationFields || List()));
  } catch (err: any) {
    yield put(integrationActions.fetchIntegrationFieldsFailed(Map(err)));
    toast.error('Error retrieving integration information', err.response.status);
  }
}

function * addIntegration (action) {
  try {
    const integration = yield call(api.addIntegration, action);

    yield put(integrationActions.addIntegrationSuccess(integration));
  }
  catch (err: any) {
    yield put(integrationActions.addIntegrationFailed(err));
    toast.error('Failed to create new integration', err.response.status);
  }
}

function * updateIntegration (action) {
  try {
    const integration = yield call(api.updateIntegration, action);
    yield put(integrationActions.updateIntegrationSuccess(integration));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating integration'), err.response.status);
    yield put(integrationActions.updateIntegrationFailed(Map(err)));
  }
}

function * deleteIntegration (action) {
  try {
    yield call(api.deleteIntegration, action);
    yield put(integrationActions.deleteIntegrationSuccess(action.integrationId));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to delete integration'), err.response.status);
    yield put(integrationActions.deleteIntegrationFailed(err));
  }
}

function * refreshIntegration (action) {
  try {
    yield call(api.refreshIntegration, action);
    yield put(integrationActions.refreshIntegrationSuccess(action.integrationId));
    toast.success('SmartTrace Supervisor is restarting...');
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to restart integration'), err.response.status);
    yield put(integrationActions.refreshIntegrationFailed(err));
  }
}

export default integrationRoot;
