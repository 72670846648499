import './ActiveAlarmScreen.scss';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import FilterListIcon from '@mui/icons-material/FilterList';
import AlarmsList from 'src/component/AlarmsList';
import CustomStatistics from '../../../component/UI/Statistics/CustomStatistics';
import UnitSelector from 'src/component/UI/UnitSelector';
import { fetchDevicesInAlarmWithoutNotification, fetchAlarmStats, fetchAlarmHistoricalSummary, fetchActiveAlarms, dismissAlarms, acknowledgeAlarms } from 'src/module/alarm/action';
import { getAlarms, getAlarmStats, getCriticalAlarmsWithoutNotification, getNumAlarmPages, getTotalAlarms } from 'src/module/alarm/selector';
import { celsiusToFahrenheitConvertor, isWhitelabel } from 'src/utils/utils';
import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getClientId, getSingularWorkTicketLabel, getTemperatureUnit } from 'src/module/authentication/selector';
import { getLoadingAlarms } from 'src/module/alarm/selector';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';
import Button from 'src/component/UI/Button';
import Tooltip from '@mui/material/Tooltip';
export default function ActiveAlarmScreen () {
  const dispatch = useDispatch();

  const alarmStats = useSelector(getAlarmStats);
  const currentTemp = alarmStats.getIn(['currentSiteTemperature', 'current'], null);
  const criticalAlarmsWithoutNotification = useSelector(getCriticalAlarmsWithoutNotification);

  const clientId = useSelector(getClientId);
  const selectedFilters = useSelector(getDevicesSelectedFilters);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);
  const temperatureUnit = useSelector(getTemperatureUnit);

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  useEffect(() => {
    dispatch(fetchAlarmStats(selectedFilters));
    dispatch(fetchDevicesInAlarmWithoutNotification(selectedFilters));
    dispatch(fetchAlarmHistoricalSummary(selectedFilters, moment().add(-1, 'weeks').format('YYYY-MM-DD')));
  }, [dispatch, clientId, selectedFilters]);

  const variable = {
    alarmStats,
    criticalAlarmsWithoutNotification,
    currentSiteTemperature: currentTemp ? temperatureUnit === 'C' ? `${currentTemp}°C` : celsiusToFahrenheitConvertor(currentTemp) + '°F' : 'N/A',
  };

  const defaultLayout = [
    {
      type: 'grid', container: true, spacing: 3, children: [
        {
          type: 'grid', container: false, xs: 12, md: 4, children: [
            {
              type: 'block', title: 'Alarm Overview', children: [
                { type: 'minor', title: 'Total Alarms', helpText: 'Total active alarms in the system', value: 'alarmStats.totalAlarms', defaultValue: 'N/A' },
                { type: 'minor', feature: ['htc-stats'], title: 'HTCs in Alarm', helpText: 'Total Heat Trace Controllers in alarm', value: 'alarmStats.htcsInAlarm', defaultValue: 'N/A' },
                { type: 'minor', feature: ['htc-stats'], title: 'HTCs in Crit. Alarm', helpText: 'Total Heat Trace Controllers in critical alarm', value: 'alarmStats.criticalAlarms', defaultValue: 'N/A' },
                { type: 'minor', title: `Critical Alarms w/o ${singularWorkTicketLabel}`, helpText: `Total number of devices in critical alarm and no associated ${singularWorkTicketLabel}`, value: 'alarmStats.criticalAlarmsWithoutNotification' },
                { type: 'minor', feature: ['htc-stats'], title: 'Temperature', value: 'currentSiteTemperature', defaultValue: 'N/A' },
              ]
            }
          ]
        },
        {
          type: 'grid', container: false, xs: 12, md: 4, children: [
            {
              type: 'block', title: 'Critical Alarms', children: [
                { type: 'component', componentName: 'CriticalAlarms' },
              ]
            }
          ]
        },
        showRotateMessage ?
          {
            type: 'grid', container: false, xs: 12, md: 4, children: [
              {
                type: 'block', title: 'Alarms by Status', children: [
                  { type: 'component', componentName: 'DeviceRotate' },
                ]
              }
            ]
          }
          : {
            type: 'grid', container: false, xs: 12, md: 4, children: [
              {
                type: 'block', title: 'Alarms by Status', children: [
                  { type: 'component', componentName: 'AlarmsByStatusGraph' },
                ]
              }
            ]
          },
      ]
    }

  ];
  const [deviceFilters, setDeviceFilters] = React.useState(() => []);
  const [showFilters, setShowFilters] = React.useState(() => false);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newDeviceFilters: string[],
  ) => {
    setDeviceFilters(newDeviceFilters);
  };
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';



  return (
    <div id='alarms-active' className='page'>
      <Helmet>
        <title>{title} - Active Alarms</title>
      </Helmet>

      <Grid container spacing={3}>

        <Grid item xs={12} md={12}>
          <Paper>
            <UnitSelector />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomStatistics
            variables={variable}
            defaultLayout={defaultLayout}
          />
        </Grid>


        <Grid item xs={12} md={12}>
          <Paper>
            <div style={{ display: 'flex', height: '45px' }}>
              {/* title={`Devices that don't have ${deviceFilters.length !== 0 ? deviceFilters.join(' or ') : 'any type of notes or work tickets'}`} */}
              <Tooltip title={`Current Filter:  ${deviceFilters.length !== 0 ? `active alarms where the device does not have ${deviceFilters.join(' or ')}` : 'all active alarms'}`} >

                <div>
                  <Button onClick={() => setShowFilters(!showFilters)} className='filterButton'>
                    <FilterListIcon style={{ fontSize: 30, }} />
                  </Button>
                </div>
              </Tooltip>
              {showFilters ?<div>
                <h5>{ 'Devices that don\'t have...'}</h5>
                <ToggleButtonGroup
                  value={deviceFilters}
                  onChange={handleFormat}
                  aria-label="alarm filters"
                  className='alarmFilterButtons'
                  color="primary"
                >
                  <ToggleButton className='ToggleButton-text' value="loto" aria-label="loto">
                LOTO
                  </ToggleButton>
                  <ToggleButton className='ToggleButton-text' value="nuisance" aria-label="nuisance">
                Nuisance
                  </ToggleButton>
                  <ToggleButton className='ToggleButton-text' value="note" aria-label="note">
                Note
                  </ToggleButton>
                  <ToggleButton className='ToggleButton-text' value="workTickets" aria-label="workTickets">
                    {singularWorkTicketLabel}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>: null }

            </div>
            {showRotateMessage ? <><h3>Active Alarms</h3><DeviceRotate /></> :
              <AlarmsList
                fetchAlarms={fetchActiveAlarms}
                alarmSelector={getAlarms}
                alarmLoadingSelector={getLoadingAlarms}
                numOfAlarmPagesSelector={getNumAlarmPages}
                numOfAlarmsSelector={getTotalAlarms}
                dismissAlarm={dismissAlarms}
                acknowledgeAlarm={acknowledgeAlarms}
                pagination
                showAlarmRaisedAt
                showSetting
                showValue
                showAlarm
                showActualTemp
                showMaintainTemp
                showActualCurrent
                showActualGFI
                showNote={!isMobile}
                showNotification={!isMobile}
                showDownloadStatsOption
                showClearAlarmOption
                showAcknowledgeAlarmOption
                header="Active Alarms"
                tableId='activeAlarmsTable'
                deviceFilters={deviceFilters}
              />
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
