import React from 'react';
import { Helmet } from 'react-helmet';
import BatchSchedulesList from 'src/component/UI/BatchSchedulesList';
import { isWhitelabel } from 'src/utils/utils';

export default function BatchSchedulesScreen () {
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' data-testid='batch-schedules-screen'>
      <Helmet>
        <title>{title} - Batch Schedules</title>
      </Helmet>
      <BatchSchedulesList/>
    </div>
  );
}
