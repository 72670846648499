import { addSelectedFiltersToQuery, addOptionalFilters } from 'src/utils/utils';
import { request } from '../api';

export const getCircuits = (action) => {
  const {
    selectedFilters,
    optionalFilters

  } = action;

  const page = (isNaN(action.page)) ? null : action.page;
  const pageSize = (isNaN(action.pageSize)) ? null : action.pageSize;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);
  query = addOptionalFilters(optionalFilters, query);

  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  return request(action, {
    payload: {
      url: `/eht-circuits`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const updateCircuit = (action, circuitId, circuit) => {
  return request(action, {
    payload: {
      url: `/eht-circuits/${circuitId}`,
      options: {
        method: 'PATCH',
        data: circuit
      },
    }
  });
};

export const addCircuit = (action, circuit) => {
  return request(action, {
    payload: {
      url: `/eht-circuits`,
      options: {
        method: 'POST',
        data: circuit
      },
    }
  });
};

export const deleteCircuit = (action, circuitId) => {
  return request(action, {
    payload: {
      url: `/eht-circuits/${circuitId}`,
      options: {
        method: 'DELETE',
      },
    }
  });
};

export const getCircuitsExport = (action) => {
  const {
    selectedFilters,
    optionalFilters
  } = action;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);
  query = addOptionalFilters(optionalFilters, query);

  return request(action, {
    payload: {
      url: `/eht-circuits/export`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};
