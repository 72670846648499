import './DeviceRotate.scss';

import React from 'react';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export default function DeviceRotate () {

  return (
    <>
      <div className='deviceRotateImg'>
        <ScreenRotationIcon style={{ fontSize: 40 }} />
      </div>
      <p className='deviceRotateText'>Rotate your device to view.</p>
    </>
  );
}
