import axios, { AxiosRequestConfig } from 'axios';
import { Map, List, fromJS } from 'immutable';
import * as Sentry from '@sentry/browser';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { getConfiguredStore } from 'src/store';
import * as authenticationActions from 'src/module/authentication/action';
import * as navigationActions from 'src/module/navigation/action';
import toast from 'src/utils/toast';
import * as authService from 'src/service/auth';
import _ from 'lodash';
import { addSelectedFiltersToQuery } from 'src/utils/utils';

export const normalize = (target, key, descriptor) => {
  return {
    normalize: true,
    ...descriptor
  };
};

/**
 * Capture all failed API responses via Sentry
 */
axios.interceptors.response.use(
  (response) => response,
  (err) => {
    try {
      Sentry.withScope(scope => {
        if (typeof err.response !== 'undefined') {
          if (err.response.status === 401) {
            return; // refresh token logic will handle this and throw errors as necessary, no need to throw here
          }

          Object.keys(err.response).forEach(key =>
            scope.setExtra(key, err.response[key])
          );
        }

        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(err);
      });
    } catch (err) {
      console.error(`Unable to track error in Sentry. Error: ${err['message']}`, { error: err }); // eslint-disable-line no-console
    }

    return Promise.reject(err);
  }
);

/**
 * Dynamically inject authorization tokens on request
 */
axios.interceptors.request.use(async config => {
  const accessToken = await authService.getAccessToken();
  const sessionId = await authService.getSessionId();


  config.headers['Authorization'] = `Bearer ${accessToken}`;
  config.headers['session-id'] = `${sessionId}`;



  return config;
}, async err => {
  throw err;
});

/**
 * Triggers a token refresh using the previously expired token from the failed request
 * For use with `axios-auth-refresh`
 * @param {*} failedRequest The failed axios request
 */
const refreshAuthToken = async failedRequest => {
  const { store } = await getConfiguredStore();

  const refreshToken = await authService.getRefreshToken();
  const sessionId = await authService.getSessionId();
  const url = `${process.env.REACT_APP_FOX_API_ADDR}/v1/auth/refresh`;

  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: failedRequest.response.config.headers['Authorization'],
      'X-Fox-Client-Id': failedRequest.config.headers['X-Fox-Client-Id'],
      'X-Fox-Platform': failedRequest.config.headers['X-Fox-Platform']
    },
    data: {
      refreshToken,
      sessionId
    }
  };

  // create new axios instance to prevent conflict with our existing interceptors
  const axiosRefresh = axios.create();
  let refreshResponse;
  try {
    refreshResponse = await axiosRefresh(url, options);
  }
  catch (e) {
    if (e['response'].status === 401) {
      await store.dispatch({
        type: navigationActions.PUSH_HISTORY,
        destination: '/login'
      });
      toast.error('Your session has expired. Please login again.', e['response'].status );
    }
    return;
  }

  const newToken = refreshResponse.data.accessToken;
  const newRefreshTokenExpiry = refreshResponse.data.refreshTokenExpiry;
  const hsIdentificationAccessToken = refreshResponse.data.hsIdentificationAccessToken;
  const abilities = refreshResponse.data.abilities;
  const features = refreshResponse.data.features;
  const clients = refreshResponse.data.clients;

  await authService.setAccessToken(newToken);
  if (newRefreshTokenExpiry) {
    authService.setRefreshTokenExpiry(newRefreshTokenExpiry);
  }
  await store.dispatch({
    type: authenticationActions.REFRESH_AUTH_SUCCESS,
    clients: Map(clients),
    abilities: List(abilities),
    features: List(features),
    hsIdentificationAccessToken
  });

  failedRequest.response.config.headers['Authorization'] = `Bearer ${newToken}`;
  return;
};

createAuthRefreshInterceptor(axios, refreshAuthToken, { skipWhileRefreshing: false });

export const request = async (action, reqOptions) => {
  const payload: any = reqOptions.payload;
  const url = `${process.env.REACT_APP_FOX_API_ADDR}/v1${payload.url}`;
  const query = payload.query ?
    Object.entries(payload.query)
      .map(([key, param]) => `${window.encodeURIComponent(key)}=${window.encodeURIComponent(param as string)}`).join('&') : '';
  let options = Map({
    credentials: 'include',
    headers: Map({
      'Accept': 'application/json; charset=utf-8',
      'Content-Type': 'application/json; charset=utf-8',
      'Pragma': 'no-cache',
      'X-Fox-Platform': 'dashboard'
    })
  }).mergeDeep(payload.options);

  const clientId = action.currentClientId || action.clientId;

  if (!reqOptions.noClientRequest && clientId !== null && typeof clientId !== 'undefined') {
    options = options.setIn(['headers', 'X-Fox-Client-Id'], clientId);
  }

  let response = null;
  let respStatus;
  let error = null;

  try {
    response = await axios(`${url}${query.length ? '?' + query : ''}`, options.toJS());
    respStatus = response.status;
  } catch (err) {
    error = err;
    response = err;
    respStatus = _.get(err, 'response.status');
  }

  if (respStatus >= 200 && respStatus < 300 && response.data && !response.data.error) {
    const data = fromJS(response.data);
    return (reqOptions.normalize) ? reqOptions.normalize(data) : data;
  } else if (respStatus === 204) {
    return null;
  } else if (respStatus >= 300 && respStatus < 400) {
    return { location: response.headers.get('Location') };
  } else if (error) {
    throw error;
  } else {
    error = new Error (response.statusText);
    error.response = response;

    throw error;
  }
};

export const getUsers = (action) => {
  return request(action, {
    payload: {
      url: '/users',
      options: { method: 'GET' }
    }
  });
};

export const getClient = (action, clientId?: string) => {
  if (!clientId) {
    clientId = action.currentClientId;
  }

  return request(action, {
    payload: {
      url: `/clients/${clientId}`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const updateClient = (action, name: string, preferences: any) => {
  const clientId = action.currentClientId;

  return request(action, {
    payload: {
      url: `/clients/${clientId}`,
      options: {
        method: 'PATCH',
        data: {
          name,
          preferences
        }
      }
    }
  });
};

export const getLookupItems = (action, type: string) => {
  return request(action, {
    payload: {
      url: `/lookups/${type}/items`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const updateLookupItem = (action, type: string, key: string, value: string) => {
  return request(action, {
    payload: {
      url: `/lookups/${type}/items/${key}`,
      options: {
        method: 'PUT',
        data: {
          value
        }
      }
    }
  });
};

export const deleteLookupItem = (action, type: string, key: string) => {
  return request(action, {
    payload: {
      url: `/lookups/${type}/items/${key}`,
      options: {
        method: 'DELETE',
      }
    }
  });
};

export const addLookupItem = (action, type: string, key: string, value: string) => {
  return request(action, {
    payload: {
      url: `/lookups/${type}/items/${key}`,
      options: {
        method: 'POST',
        data: {
          value: value
        }
      },
    }
  });
};


export const inviteUser = (action, email, firstName, lastName) => {
  const user: any = { email };

  if (firstName) {
    user.firstName = firstName;
  }

  if (lastName) {
    user.lastName = lastName;
  }

  return request(action, {
    payload: {
      url: '/users/invite',
      options: {
        method: 'POST',
        data: user
      }
    }
  });
};

export const removeUser = (action, userId) => {
  return request(action, {
    payload: {
      url: `/users/${userId}`,
      options: {
        method: 'DELETE'
      }
    }
  });
};

export const updateOwnUser = (action, userValues) => {
  return request(action, {
    payload: {
      url: `/users`,
      options: {
        method: 'PATCH',
        data: userValues
      }
    }
  });
};


export const updateUserRole = (action, userId, role) => {
  return request(action, {
    payload: {
      url: `/users/${userId}/role`,
      options: {
        method: 'PATCH',
        data: { role }
      }
    }
  });
};

export const updateUserStatus = (action, userId, clientId, status, reassignOwnershipUserId = null) => {
  return request(action, {
    payload: {
      url: `/users/${userId}/status`,
      options: {
        method: 'PUT',
        data: {
          clientId: clientId,
          status: status,
          reassignOwnershipUserId: reassignOwnershipUserId
        }
      }
    }
  });
};

export const getMetrics = (action) => {
  const payload: any = {
    url: `/report/metrics/facility`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const getRules = (action) => {
  const payload: any = {
    url: `/eht-alarm-rules`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const addRule = (action) => {
  const {
    rule
  } = action;

  return request(action, {
    payload: {
      url: `/eht-alarm-rules`,
      options: {
        method: 'POST',
        data: rule
      },
    }
  });
};

export const updateRule = (action) => {
  const {
    ruleId,
    rule
  } = action;

  return request(action, {
    payload: {
      url: `/eht-alarm-rules/${ruleId}`,
      options: {
        method: 'PATCH',
        data: rule
      },
    }
  });
};

export const deleteRule = (action) => {
  const {
    ruleId
  } = action;

  return request(action, {
    payload: {
      url: `/eht-alarm-rules/${ruleId}`,
      options: {
        method: 'DELETE'
      },
    }
  });
};

export const addRuleCondition = (action) => {
  const {
    ruleId,
    ruleCondition
  } = action;

  return request(action, {
    payload: {
      url: `/eht-alarm-rules/${ruleId}/condition`,
      options: {
        method: 'POST',
        data: ruleCondition
      },
    }
  });
};

export const updateRuleCondition = (action) => {
  const {
    ruleId,
    ruleConditionId,
    ruleCondition
  } = action;

  return request(action, {
    payload: {
      url: `/eht-alarm-rules/${ruleId}/condition/${ruleConditionId}`,
      options: {
        method: 'PATCH',
        data: ruleCondition
      },
    }
  });
};

// -------------------------------------- start

export const createUpgrade = (action) => {
  const {
    upgrade
  } = action;
  return request(action, {
    payload: {
      url: `/upgrades`,
      options: {
        method: 'POST',
        data: upgrade
      },
    }
  });
};

export const fetchDeviceUpgrades = (action) => {
  const payload: any = {
    url: `/upgrades`,
    options: {
      method: 'get'
    }
  };
  return request(action, { payload });
};

export const updateDeviceUpgrade = (action) => {
  const {
    deviceUpgradeId,
    upgrade
  } = action;
  return request(action, {
    payload: {
      url: `/upgrades/${deviceUpgradeId}`,
      options: {
        method: 'PATCH',
        data: upgrade
      },
    }
  });
};

export const deleteDeviceUpgrade = (action) => {
  const {
    deviceUpgradeId
  } = action;

  return request(action, {
    payload: {
      url: `/upgrades/${deviceUpgradeId}`,
      options: {
        method: 'DELETE'
      },
    }
  });
};

export const addDeviceUpgradeCondition = (action) => {
  const {
    deviceConditionBranchId,
    upgradeCondition
  } = action;

  return request(action, {
    payload: {
      url: `/upgrades/${deviceConditionBranchId}/condition`,
      options: {
        method: 'POST',
        data: upgradeCondition
      },
    }
  });
};

export const updateDeviceUpgradeCondition = (action) => {
  const {
    upgradeConditionId,
    upgradeCondition,
  } = action;

  return request(action, {
    payload: {
      url: `/upgrades/${upgradeConditionId}/condition/`,
      options: {
        method: 'PATCH',
        data: upgradeCondition
      },
    }
  });
};

export const addDeviceUpgradeConditionBranch = (action) => {
  const {
    deviceUpgradeId,
    upgradeConditionBranch
  } = action;

  return request(action, {
    payload: {
      url: `/upgrades/${deviceUpgradeId}/branch`,
      options: {
        method: 'POST',
        data: upgradeConditionBranch
      },
    }
  });
};

export const updateDeviceUpgradeConditionBranch = (action) => {
  const {
    upgradeConditionBranchId,
    upgradeConditionBranch,
  } = action;

  return request(action, {
    payload: {
      url: `/upgrades/${upgradeConditionBranchId}/branch/`,
      options: {
        method: 'PATCH',
        data: upgradeConditionBranch
      },
    }
  });
};

export const deleteDeviceConditionBranch = (action) => {
  const {
    upgradeConditionBranchId
  } = action;

  return request(action, {
    payload: {
      url: `/upgrades/${upgradeConditionBranchId}/branch/`,
      options: {
        method: 'DELETE'
      },
    }
  });
};

export const getNotes = (action) => {
  const {
    paginationDetails,
    note,
    selectedFilters,
    includeArchived
  } = action;

  const {
    filter,
    sort,
  } = paginationDetails;

  const page = (isNaN(action.paginationDetails.page)) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails.pageSize)) ? null : action.paginationDetails.pageSize;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.filter = filter;
  }

  if (note) {
    query.note = note;
  }

  query.onlyActive = true;
  query.onlyArchived = false;
  if (includeArchived) {
    query.onlyActive = false;
  }

  const payload: any = {
    url: `/eht-notes`,
    query,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};

export const updateNote = (action, noteId, note) => {
  return request(action, {
    payload: {
      url: `/eht-notes/${noteId}`,
      options: {
        method: 'PATCH',
        data: note
      },
    }
  });
};

export const addNote = (action, note) => {
  return request(action, {
    payload: {
      url: `/eht-notes`,
      options: {
        method: 'POST',
        data: note
      },
    }
  });
};

export const importData = (action, importFile) => {
  try {
    const options: any = {
      method: 'POST'
    };

    const formData = new FormData();
    formData.append('file', importFile, importFile.name);
    action.file = {
      name: importFile.name,
      lastModified: importFile.lastModified
    };

    options.data = formData;
    options.headers = {
      'Content-Type': 'multipart/form-data'
    };

    return request(action, {
      payload: {
        url: `/import`,
        options
      }
    });
  } catch (error) {
    console.error(`Failed to upload file: ${error}`); // eslint-disable-line no-console
  }
};


export const updateNotification = (action, id, notification) => {
  return request(action, {
    payload: {
      url: `/sap-notifications/${id}`,
      options: {
        method: 'PATCH',
        data: notification
      },
    }
  });
};

export const sendReport = (action, message) => {
  return request(action, {
    payload: {
      url: `/report/send`,
      options: {
        method: 'POST',
        data: {
          message: message
        }
      },
    }
  });
};

export const getNotificationsByDevice = (action, onlyActive=true, onlyArchived=false, page=null, pageSize=null, filter=null, order=null) => {
  const {
    deviceId,
    controllerId
  } = action;
  const url = (onlyActive) ? `/sap-notifications/active`: `/sap-notifications/archived`;

  const query: any = {};

  if (controllerId) {
    query.controller_id = controllerId;
  }

  if (deviceId) {
    query.device_id = deviceId;
  }
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (order !== null) {
    query.order = order;
  }

  if (filter !== null) {
    query.filter = filter;
  }
  if (onlyActive) {
    query.onlyActive = true;
  }
  if (onlyArchived) {
    query.onlyArchived = true;
  }

  return request(action, {
    payload: {
      url,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getNotesByDevice = (action, onlyActive=true, onlyArchived=false) => {
  const {
    note,
    selectedFilters,
    deviceId,
    paginationDetails
  } = action;

  const {
    page, pageSize, filter, sort
  } = paginationDetails;
  const url = (onlyActive) ? `/eht-notes/active`: `/eht-notes/archived`;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);
  if (page > -1 && pageSize) {
    query.page = page;
    query.pageSize = pageSize;
  }
  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.filter = filter;
  }

  if (deviceId !== null && typeof(deviceId) !== 'undefined') {
    query.device_id = deviceId;
  }

  if (note) {
    query.note = note;
  }


  if (onlyActive) {
    query.onlyActive = true;
  }
  if (onlyArchived) {
    query.onlyArchived = true;
  }

  return request(action, {
    payload: {
      url,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};
